/**
 * Labstep
 *
 * @module components/Experiment/Form/ShowEdit/State/EmptyState
 * @desc Empty State for State
 */

import Can from 'labstep-web/components/Entity/Can';
import { Action } from 'labstep-web/components/Entity/Can/types';
import Button from 'labstep-web/core/Button';
import EmptyState from 'labstep-web/core/Card/EmptyState';
import React from 'react';
import { ExperimentFormShowEditStateEmptyStateProps } from './types';

export const ExperimentFormShowEditStateEmptyState: React.FC<
  ExperimentFormShowEditStateEmptyStateProps
> = ({ experiment, onClick }) => (
  <EmptyState
    title={
      experiment.experiment_workflow_is_template
        ? 'Blank entry template'
        : 'Blank entry'
    }
    explanation={
      experiment.experiment_workflow_is_template
        ? 'Write a template for your entries here.'
        : 'Write up the details of your experiment here.'
    }
    src="/img/empty-state/experiments.svg"
    action={
      <Can
        entityName={experiment.entityName}
        id={experiment.id}
        action={Action.edit}
      >
        <Button primary onClick={onClick}>
          Edit
        </Button>
      </Can>
    }
  />
);

export default ExperimentFormShowEditStateEmptyState;
