/**
 * Labstep
 *
 * @deprecated
 */

import { APICallOptions } from 'labstep-web/models';
import * as sfApi from 'labstep-web/services/sf-api.service';

export const transferOwnershipActionType = 'transfer_ownership';

export const transferOwnershipAction = (
  entityName: any,
  entityId: any,
  groupId: any,
  options: APICallOptions,
): any =>
  sfApi.post({
    type: 'TRANSFER_OWNERSHIP',
    route: {
      custom: `app_api_${entityName.replace(
        '_',
        '',
      )}_transferownership`,
    },
    meta: {
      action_type: transferOwnershipActionType,
      customReducer: 1,
      normalize: entityName,
    },
    body: { entity_id: entityId, group_id: groupId },
    ...options,
  });
