/**
 * Labstep
 *
 * @module components/Resource/List
 * @desc Resource list
 */

import Item from 'labstep-web/components/Resource/Item';
import React from 'react';
import styles from './styles.module.scss';
import { IResourceListProps } from './types';

const ResourceList: React.FC<IResourceListProps> = ({
  resources,
  ...rest
}) => (
  <div id="resource-list" role="list">
    {resources.map((resource) => (
      <div key={resource.id} className={styles.card}>
        <Item key={resource.id} resource={resource} {...rest} />
      </div>
    ))}
  </div>
);

export default ResourceList;
