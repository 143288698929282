/**
 * Labstep
 *
 * @module components/Layout/GlobalSearch/Content/EntityNameSelector
 * @desc Component to select entityName
 */

import classNames from 'classnames';
import { ICONS } from 'labstep-web/constants/icons';
import Icon from 'labstep-web/core/Icon';
import PremiumFeatureLockIcon from 'labstep-web/core/PremiumFeature/LockIcon';
import PremiumFeatureModal from 'labstep-web/core/PremiumFeature/Modal';
import { usePremiumFeatureStatus } from 'labstep-web/hooks/premium-feature';
import { PremiumFeature } from 'labstep-web/models/organization.model';
import { getHumanReadableEntityName } from 'labstep-web/services/i18n.service';
import React from 'react';
import { ENTITY_NAME_OPTIONS, EntityNameOptions } from './constants';
import styles from './styles.module.scss';
import { LayoutGlobalSearchEntityNameSelectorProps } from './types';

const LayoutGlobalSearchEntityNameSelector: React.FC<
  LayoutGlobalSearchEntityNameSelectorProps
> = ({ searchParams, setParams }) => {
  const options = ENTITY_NAME_OPTIONS.map((e) => ({ entityName: e }));
  const [openModal, setOpenModal] = React.useState(false);
  const [premiumFeature, setPremiumFeature] = React.useState<
    PremiumFeature | undefined
  >(undefined);
  const premiumEntities = usePremiumFeatureStatus(
    options,
    'entityName',
    'global_search',
  );
  return (
    <>
      {premiumFeature && (
        <PremiumFeatureModal
          open={openModal}
          premiumFeature={premiumFeature}
          viewComponent={<></>}
          onClose={() => {
            setOpenModal(false);
            setPremiumFeature(undefined);
          }}
        />
      )}
      <div
        className={styles.container}
        data-testid="entity-name-selector"
      >
        {premiumEntities.map((e) => (
          <div
            key={e.entityName}
            className={classNames({
              [styles.active]:
                searchParams.entityName === e.entityName,
            })}
            onClick={() => {
              if (e.premiumFeature && !e.premiumFeatureEnabled) {
                setPremiumFeature(e.premiumFeature);
                setOpenModal(true);
                return;
              }
              setParams({ entityName: e.entityName });
            }}
          >
            <Icon
              name={ICONS[e.entityName as EntityNameOptions].primary}
            />
            {getHumanReadableEntityName(e.entityName, true, true)}
            {e.premiumFeature && !e.premiumFeatureEnabled && (
              <PremiumFeatureLockIcon
                premiumFeature={e.premiumFeature}
              />
            )}
          </div>
        ))}
      </div>
    </>
  );
};

export default LayoutGlobalSearchEntityNameSelector;
