/**
 * Labstep
 *
 * @module components/Resource/Item
 * @desc Resource Item
 */

import ActionMenu from 'labstep-web/components/Resource/Action/Menu';
import ResourceImageUploadable from 'labstep-web/components/Resource/Image/Uploadable';
import { CardHeader } from 'labstep-web/core/Card/Generic';
import React from 'react';
import styles from './styles.module.scss';
import { IResourceItemProps } from './types';

const ResourceItem: React.FC<IResourceItemProps> = ({
  resource,
  actions,
  mainAction,
  detailsSecondary,
  linkable,
}) => {
  const title = {
    to: 'resource_show',
    params: { id: resource.id },
    title: resource.name,
    disabled: !linkable,
  };

  return (
    <div className={styles.top}>
      <ResourceImageUploadable resource={resource} mobile />
      <div className={styles.header}>
        <CardHeader
          title={title}
          detailsSecondary={
            detailsSecondary && detailsSecondary({ resource })
          }
          actionMenu={
            <ActionMenu actions={actions} resource={resource} />
          }
          mainAction={mainAction && mainAction({ resource })}
        />
      </div>
    </div>
  );
};

ResourceItem.defaultProps = {
  linkable: true,
};

export default ResourceItem;
