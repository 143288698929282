/**
 * Labstep
 *
 * @module components/Filter/Metadata/Form
 * @desc Filter Metadata form
 */

import ReusableFormGroup from 'labstep-web/core/Form/Reusable/Group';
import {
  MetadataType,
  MetadataTypeValues,
} from 'labstep-web/models/metadata/types';
import {
  getPostFilterMetadatasDate,
  getPostFilterMetadatasDefault,
  getPostFilterMetadatasNumeric,
  getPostFilterMetadatasOptions,
} from 'labstep-web/services/postFilter/filters/metadatas';
import { PostFilterComparison } from 'labstep-web/services/query-parameter.service';
import React, { useCallback, useMemo, useState } from 'react';
import { fieldsGeneric, fieldsPerMetadataType } from './fields';
import { FilterMetadataFormProps, FilterMetadataType } from './types';

export const PostFilterMetadatasByPathAndType = {
  metadatas: {
    [MetadataType.default]:
      getPostFilterMetadatasDefault('metadatas'),
    [MetadataType.numeric]:
      getPostFilterMetadatasNumeric('metadatas'),
    [MetadataType.date]: getPostFilterMetadatasDate('metadatas'),
    [MetadataType.options]:
      getPostFilterMetadatasOptions('metadatas'),
  },
  'resource.metadatas': {
    [MetadataType.default]: getPostFilterMetadatasDefault(
      'resource.metadatas',
    ),
    [MetadataType.numeric]: getPostFilterMetadatasNumeric(
      'resource.metadatas',
    ),
    [MetadataType.date]: getPostFilterMetadatasDate(
      'resource.metadatas',
    ),
    [MetadataType.options]: getPostFilterMetadatasOptions(
      'resource.metadatas',
    ),
  },
};
const FilterMetadataForm: React.FC<FilterMetadataFormProps> = ({
  addPostFilter,
  onDone,
  defaultValues: defaultValuesProps,
  replace,
  path = 'metadatas',
  addAnother = false,
}) => {
  const [metadataType, setMetadataType] =
    useState<FilterMetadataType>(MetadataType.default);
  const defaultValues = useMemo(
    () => ({
      type: {
        value: MetadataType.default,
        label: MetadataTypeValues[MetadataType.default],
      },
      ...defaultValuesProps,
    }),
    [],
  );
  const fields = useMemo(
    () => [
      ...fieldsGeneric,
      ...fieldsPerMetadataType(!!defaultValuesProps)[metadataType]
        .fields,
    ],
    [metadataType],
  );

  const onSubmit = useCallback(
    (values): void => {
      const { type, label, value, comparison } = values;
      const typeValue = type.value as
        | MetadataType.default
        | MetadataType.numeric
        | MetadataType.date
        | MetadataType.options;
      const filter =
        PostFilterMetadatasByPathAndType[path][typeValue];
      addPostFilter(
        filter.getNode([
          label,
          [value, comparison?.value || PostFilterComparison.eq],
        ]),
        true,
      );
      onDone();
    },
    [path, addPostFilter],
  );
  const onFormChange = useCallback((values): void => {
    setMetadataType(values.type.value);
  }, []);
  return (
    <ReusableFormGroup
      fields={fields}
      defaultValues={defaultValues}
      onSubmit={onSubmit}
      onFormChange={onFormChange}
      onDone={onDone}
      addAnother={addAnother}
    />
  );
};

export default FilterMetadataForm;
