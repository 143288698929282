/**
 * Labstep
 *
 * @module components/Filter/Device/Active
 * @desc Active filter component for device
 */

import { ICONS } from 'labstep-web/constants/icons';
import { EntityReadEntityContainer } from 'labstep-web/containers/Entity/Read/Entity';
import RemoveLabel from 'labstep-web/core/Label/Remove';
import { Device } from 'labstep-web/models/device.model';
import React from 'react';
import {
  DeviceChildrenProps,
  FilterDeviceActiveProps,
} from './types';

const FilterDeviceActive: React.FC<FilterDeviceActiveProps> = ({
  clearParams,
  searchParams,
}) => {
  return (
    <>
      {'device_id' in searchParams && (
        <EntityReadEntityContainer
          entityName={Device.entityName}
          id={searchParams.device_id as number}
        >
          {({ entity: device }: DeviceChildrenProps) => {
            return device ? (
              <RemoveLabel
                icon={ICONS.device.primary}
                name={`${device.name}`}
                onRemoveClick={
                  clearParams && (() => clearParams(['device_id']))
                }
              />
            ) : null;
          }}
        </EntityReadEntityContainer>
      )}
    </>
  );
};

export default FilterDeviceActive;
