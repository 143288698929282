/**
 * Labstep
 *
 * @module components/Metadata/DataGrid/coldefs/value
 * @desc Column definition for metadata value
 */

import { ICONS } from 'labstep-web/constants/icons';
import {
  suppressKeyboardEventClearCells,
  suppressKeyboardEventDisableEnterOnEdit,
} from 'labstep-web/core/DataGrid/CellEditor/utils';
import {
  getEditable as getEditableDefault,
  getPropOrFallback,
} from 'labstep-web/core/DataGrid/utils';
import { MetadataType } from 'labstep-web/models/metadata/types';
import { getValueFields } from 'labstep-web/models/metadata/utils';
import { colDefValueType } from './types';
import {
  getCellEditorSelector,
  getCellRenderer,
  getEditable,
  getValueGetter,
  getValueSetter,
} from './utils';

/**
 * Column definition for Metadata value
 * @param options Options
 * @returns Column definition
 */
const colDefValue: colDefValueType = (options = {}) => {
  const { getNestedEntity, columnProps, getNestedParentEntity } =
    options;
  const editable = getEditableDefault(
    getNestedEntity,
    getValueFields,
  );
  /** Helper fn so we can return null if the entity is not found */
  const getProp = getPropOrFallback(getNestedEntity);
  return {
    cellRenderer: getCellRenderer(
      getProp,
      editable,
      getNestedParentEntity,
    ),
    cellEditorSelector: getCellEditorSelector(
      getProp,
      getNestedParentEntity,
    ),
    suppressKeyboardEvent: (params: any): boolean =>
      !!getProp(params, (entity) =>
        entity.type === MetadataType.options || entity.protocol_device
          ? // for SearchSelect, "Enter" selects option
            suppressKeyboardEventDisableEnterOnEdit(params) ||
            suppressKeyboardEventClearCells(params)
          : suppressKeyboardEventClearCells(params),
      ),
    editable: (params) =>
      editable(params) && getEditable(getProp)(params),
    headerComponentParams: {
      icon: ICONS.metadata.primary,
    },
    valueClearer: (params): string | null =>
      getProp(params, (entity) =>
        entity.type === MetadataType.options
          ? JSON.stringify(entity.emptyOptions)
          : null,
      ),
    valueGetter: getValueGetter(getProp),
    valueSetter: getValueSetter(getProp, getNestedParentEntity),
    ...columnProps,
  };
};

export default colDefValue;
