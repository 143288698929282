/**
 * Labstep
 *
 * @module components/ProtocolCondition/Action/Reporting/ProtocolCollection
 * @desc Open modal to compare data
 */

import ProtocolCollectionSearchSelect from 'labstep-web/components/ProtocolCollection/SearchSelect';
import ProtocolConditionExplorer from 'labstep-web/components/ProtocolCondition/Explorer';
import { ICONS } from 'labstep-web/constants/icons';
import { ActionComponent } from 'labstep-web/core/Action/Component';
import Flex from 'labstep-web/core/Flex';
import Header from 'labstep-web/core/Header';
import ModalDefault from 'labstep-web/core/Modal/Default';
import { ReadOnMountHOC } from 'labstep-web/hoc/ReadOnMount';
import { Entity } from 'labstep-web/models/entity.model';
import { ProtocolCollection } from 'labstep-web/models/protocol-collection.model';
import React from 'react';
import {
  ProtocolConditionActionCompareDataProtocolCollectionProps,
  ProtocolConditionProtocolCollectionExplorerProps,
} from './types';

export const ProtocolConditionProtocolCollectionExplorer: React.FC<
  ProtocolConditionProtocolCollectionExplorerProps
> = ({ group, protocolCollection }) => {
  const [selectedProtocolCollection, setProtocolCollection] =
    React.useState<any>({
      guid: protocolCollection.id,
      label: protocolCollection.name,
    });
  return (
    <Flex column grow>
      <Flex vAlignContent="center">
        <Header size="mini" noMargin>
          Showing data for runs of:{' '}
        </Header>
        <ProtocolCollectionSearchSelect
          params={{
            group_id: group.id,
          }}
          returnProtocolCollection
          value={selectedProtocolCollection}
          onChange={(option: any) => {
            setProtocolCollection({ ...option });
          }}
        />
      </Flex>

      <ReadOnMountHOC
        type="entities"
        entityName={ProtocolCollection.entityName}
        params={{
          id: selectedProtocolCollection.guid,
          get_single: 1,
          serializerGroups: 'protocol_collection_reporting,paginator',
        }}
        loading={{ loader: 'full_screen' }}
        children={({ entity }: { entity: Entity }) => (
          <ProtocolConditionExplorer
            group={group}
            protocolCollection={entity as ProtocolCollection}
          />
        )}
      />
    </Flex>
  );
};

export const ProtocolConditionActionCompareDataProtocolCollection: React.FC<
  ProtocolConditionActionCompareDataProtocolCollectionProps
> = ({ group, protocolCollection, actionComponentType }) => {
  return (
    <ModalDefault
      header="Data Explorer"
      size="fullscreen"
      viewComponent={({ toggleModal }) => (
        <ActionComponent
          onClick={toggleModal}
          text="Compare Data"
          type={actionComponentType}
          icon={ICONS.protocol_condition.secondary}
          showIcon
          elementProps={{
            border: 'fancy',
          }}
        />
      )}
      content={() => (
        <ReadOnMountHOC
          type="entities"
          entityName={ProtocolCollection.entityName}
          params={{
            id: protocolCollection.guid,
            get_single: 1,
            serializerGroups:
              'protocol_collection_reporting,paginator',
          }}
          loading={{ loader: 'full_screen' }}
          children={({ entity }: { entity: Entity }) => (
            <ProtocolConditionExplorer
              group={group}
              protocolCollection={entity as ProtocolCollection}
            />
          )}
        />
      )}
    />
  );
};

export default ProtocolConditionActionCompareDataProtocolCollection;
