/**
 * Labstep
 *
 * @module prosemirror/components/Menu/Commands/Menu/Item/Label
 * @desc A label for element menu items
 */

import Icon from 'labstep-web/core/Icon';
import Label from 'labstep-web/core/Label';
import React from 'react';
import { IMenuCommandsMenuItemLabelProps } from './types';

const MenuCommandsMenuItemLabel: React.FC<
  IMenuCommandsMenuItemLabelProps
> = () => (
  <Label size="mini" color="yellow">
    <Icon name="star" />
    New
  </Label>
);

export default MenuCommandsMenuItemLabel;
