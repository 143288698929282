/**
 * Labstep
 *
 * @module core/Button/WithDropdown
 * @desc Button with dropdown
 */

import Button from 'labstep-web/core/Button';
import Dropdown from 'labstep-web/core/Dropdown';
import React from 'react';
import { IButtonWithDropdownProps } from './types';

export const ButtonWithDropdown: React.FC<
  IButtonWithDropdownProps
> = ({ primaryAction, extraOptions }) => (
  <Button.Group color="blue">
    {primaryAction}
    <Dropdown.Dropdown
      className="button icon"
      floating
      style={{ maxWidth: 'fit-content' }}
    >
      <Dropdown.Menu>{extraOptions}</Dropdown.Menu>
    </Dropdown.Dropdown>
  </Button.Group>
);

export default ButtonWithDropdown;
