/**
 * Labstep
 *
 * @module containers/ProseMirror
 * @desc Container to manage prosemirror instance
 */

import {
  forceRefreshProseMirrorAction,
  selectProseMirrorConflict,
  selectProseMirrorKey,
  selectProseMirrorStatus,
  setProseMirrorStatusAction,
} from 'labstep-web/state/new/prosemirror';
import { LabstepReduxState } from 'labstep-web/state/types';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import {
  ProseMirrorStatus,
  WithProseMirrorInjectedProps,
  WithProseMirrorStatusMapDispatchToProps,
  WithProseMirrorStatusMapStateToProps,
} from './types';

const mapStateToProps = (
  state: LabstepReduxState,
): WithProseMirrorStatusMapDispatchToProps => ({
  proseMirrorKey: selectProseMirrorKey(state),
  proseMirrorStatus: selectProseMirrorStatus(state),
  conflict: selectProseMirrorConflict(state),
});

const mapDispatchToProps = (
  dispatch: Dispatch,
): WithProseMirrorStatusMapStateToProps => ({
  setProseMirrorStatus(proseMirrorStatus: ProseMirrorStatus): void {
    dispatch(setProseMirrorStatusAction(proseMirrorStatus));
  },
  forceRefresh(): void {
    dispatch(forceRefreshProseMirrorAction());
  },
});

export const withProseMirror = <
  P extends WithProseMirrorInjectedProps,
  S,
>(
  Component: React.FC<P> | React.ComponentClass<P, S>,
): React.FC<
  Pick<P, Exclude<keyof P, keyof WithProseMirrorInjectedProps>>
> => connect(mapStateToProps, mapDispatchToProps)(Component as any);
