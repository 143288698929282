/**
 * Labstep
 *
 * @module components/PurchaseOrder/Form/ShowEdit/TaxRate
 * @desc ShowEdit form for order request currency and handling
 */

import { fieldPurchaseOrderTaxRate } from 'labstep-web/components/PurchaseOrder/Form/fields';
import ShowEditText from 'labstep-web/core/Form/ShowEdit/Text';
import React from 'react';
import { IPurchaseOrderFormShowEditTaxRateProps } from './types';

export const PurchaseOrderFormShowEditTaxRate: React.FC<
  IPurchaseOrderFormShowEditTaxRateProps
> = ({ purchaseOrder }) => (
  <ShowEditText
    entity={purchaseOrder}
    entityName={purchaseOrder.entityName}
    field={fieldPurchaseOrderTaxRate}
  >
    {purchaseOrder.printVat}
  </ShowEditText>
);

export default PurchaseOrderFormShowEditTaxRate;
