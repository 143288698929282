/**
 * Labstep
 *
 * @module components/ExperimentWorkflow/Action/Menu
 * @desc Action Menu for ExperimentWorkflow
 */

import EntityActionRevoke from 'labstep-web/components/Entity/Action/Revoke';
import EntityActionTag from 'labstep-web/components/Entity/Action/Tag';
import EntityActionTransferOwnership from 'labstep-web/components/Entity/Action/TransferOwnership';
import EntityExportActionCreate from 'labstep-web/components/EntityExport/Action/Create';
import EntityUserActionManage from 'labstep-web/components/EntityUser/Action/Manage';
import ExperimentWorkflowActionDelete from 'labstep-web/components/ExperimentWorkflow/Action/Delete';
import ExperimentWorkflowActionDuplicate from 'labstep-web/components/ExperimentWorkflow/Action/Duplicate';
import ExperimentWorkflowActionLock from 'labstep-web/components/ExperimentWorkflow/Action/Lock';
import ExperimentWorkflowActionPrint from 'labstep-web/components/ExperimentWorkflow/Action/Print';
import ExperimentWorkflowActionShare from 'labstep-web/components/ExperimentWorkflow/Action/Share';
import ExperimentWorkflowActionTemplate from 'labstep-web/components/ExperimentWorkflow/Action/Template';
import ExperimentWorkflowActionView from 'labstep-web/components/ExperimentWorkflow/Action/View';
import FolderActionAdd from 'labstep-web/components/Folder/Action/Add';
import FolderActionRemove from 'labstep-web/components/Folder/Action/Remove';
import ActionMenu from 'labstep-web/core/Action/Menu';
import React from 'react';
import { Action, ExperimentWorkflowActionMenuProps } from './types';

export const ExperimentWorkflowActionMenu: React.FC<
  ExperimentWorkflowActionMenuProps
> = ({ experimentWorkflow, type, actions = [] }) => (
  <ActionMenu type={type}>
    {actions.includes(Action.view) && (
      <ExperimentWorkflowActionView
        experimentWorkflow={experimentWorkflow}
      />
    )}
    {actions.includes(Action.share) && (
      <ExperimentWorkflowActionShare
        experimentWorkflow={experimentWorkflow}
      />
    )}
    {actions.includes(Action.duplicate) && (
      <ExperimentWorkflowActionDuplicate
        experimentWorkflow={experimentWorkflow}
      />
    )}
    {actions.includes(Action.add_to_folder) && (
      <FolderActionAdd parentEntity={experimentWorkflow} />
    )}
    {actions.includes(Action.remove_from_folder) && (
      <FolderActionRemove
        parentId={experimentWorkflow.id}
        parentName={experimentWorkflow.entityName}
      />
    )}
    {actions.includes(Action.duplicate) && (
      <ExperimentWorkflowActionTemplate
        experimentWorkflow={experimentWorkflow}
      />
    )}
    {actions.includes(Action.export) && (
      <EntityExportActionCreate entity={experimentWorkflow} />
    )}
    {actions.includes(Action.print) && (
      <ExperimentWorkflowActionPrint
        experimentWorkflow={experimentWorkflow}
      />
    )}
    {actions.includes(Action.lock) && (
      <ExperimentWorkflowActionLock
        experimentWorkflow={experimentWorkflow}
      />
    )}
    {actions.includes(Action.assign) && (
      <EntityUserActionManage entity={experimentWorkflow} />
    )}
    {actions.includes(Action.tag) && (
      <EntityActionTag parentEntity={experimentWorkflow} />
    )}
    {actions.includes(Action.revoke) && (
      <EntityActionRevoke entity={experimentWorkflow} />
    )}
    {actions.includes(Action.transfer_ownership) && (
      <EntityActionTransferOwnership entity={experimentWorkflow} />
    )}
    {actions.includes(Action.delete) && (
      <ExperimentWorkflowActionDelete
        experimentWorkflow={experimentWorkflow}
      />
    )}
  </ActionMenu>
);

ExperimentWorkflowActionMenu.defaultProps = {
  actions: [],
  type: 'icon',
};

export default ExperimentWorkflowActionMenu;
