/**
 * Labstep
 *
 * @module components/ResourceLocation/List/StockCheck
 * @desc ResourceLocation StockCheck list
 */

import ResourceLocationItemStockCheck from 'labstep-web/components/ResourceLocation/Item/StockCheck';
import List from 'labstep-web/core/List';
import React from 'react';
import { IResourceLocationListStockCheckProps } from './types';

export const ResourceLocationListStockCheck: React.FC<
  IResourceLocationListStockCheckProps
> = ({ resourceItems }) => {
  return (
    <List id="stock-check-list">
      {resourceItems.map((resourceItem) => (
        <ResourceLocationItemStockCheck
          key={resourceItem.guid}
          resourceItem={resourceItem}
        />
      ))}
    </List>
  );
};

export default ResourceLocationListStockCheck;
