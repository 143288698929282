/**
 * Labstep
 *
 * @module components/OrderRequest/Action/Create/Wizard/Trigger
 * @desc OrderRequest create wizard trigger
 */

import { useHasAccessCreate } from 'labstep-web/components/Entity/Can';
import ActionComponent from 'labstep-web/core/Action/Component';
import { useModalRoute } from 'labstep-web/hooks/modalRoute';
import { usePremiumFeatureStatus } from 'labstep-web/hooks/premium-feature';
import { OrderRequest } from 'labstep-web/models/order-request.model';
import React from 'react';
import { ORDER_REQUEST_CREATE_ROUTE_ID } from '..';
import { OrderRequestActionCreateWizardTriggerProps } from './types';

export const OrderRequestActionCreateWizardTrigger: React.FC<
  OrderRequestActionCreateWizardTriggerProps
> = ({ resource, purchaseOrder, actionComponentProps }) => {
  const { toggleModal } = useModalRoute(
    ORDER_REQUEST_CREATE_ROUTE_ID,
  );

  const canCreate = useHasAccessCreate('order_request');
  const keys = [
    {
      name: 'order_request',
    },
  ];

  const premiumActions = usePremiumFeatureStatus(
    keys,
    'name',
    'order_request_action',
  );

  if (!canCreate || premiumActions.length === 0) {
    return null;
  }

  if (resource) {
    return (
      <ActionComponent
        dataTestId="order-request-create-wizard-trigger"
        text="Request Items"
        type="button"
        onClick={() =>
          toggleModal({
            resourceId: resource.id,
          })
        }
        {...actionComponentProps}
        premiumFeature="order_management"
      />
    );
  }
  if (purchaseOrder) {
    return (
      <ActionComponent
        dataTestId="order-request-create-wizard-trigger"
        text={`Add ${OrderRequest.getHumanReadableEntityName(
          false,
          true,
        )}`}
        type="button"
        onClick={() =>
          toggleModal({
            purchaseOrderId: purchaseOrder.id,
          })
        }
        {...actionComponentProps}
        premiumFeature="order_management"
      />
    );
  }

  return (
    <ActionComponent
      dataTestId="order-request-create-wizard-trigger"
      text={`New ${OrderRequest.getHumanReadableEntityName(
        false,
        true,
      )}`}
      type="button"
      onClick={() => toggleModal()}
      {...actionComponentProps}
      premiumFeature="order_management"
    />
  );
};

export default OrderRequestActionCreateWizardTrigger;
