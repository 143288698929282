/**
 * Labstep
 *
 * @module components/ProtocolValue/Detail/ResourceItem
 * @desc ProtocolValue Detail ResourceItem
 */

import Container from 'labstep-web/components/Layout/Container';
import ProtocolValueDetailResourceItemInput from 'labstep-web/components/ProtocolValue/Detail/ResourceItem/Input';
import ProtocolValueDetailResourceItemOutput from 'labstep-web/components/ProtocolValue/Detail/ResourceItem/Output';
import ProtocolValueShowEditResource from 'labstep-web/components/ProtocolValue/Form/ShowEdit/Resource';
import Flex from 'labstep-web/core/Flex';
import React from 'react';
import styles from './styles.module.scss';
import { IProtocolValueDetailResourceItemProps } from './types';

export const ProtocolValueDetailResourceItem: React.FC<
  IProtocolValueDetailResourceItemProps
> = ({ protocolValue }) => (
  <Flex
    className={styles.container}
    column
    data-testid="protocol-value-resource-item"
    grow
    shrink={false}
  >
    {protocolValue.resource && !protocolValue.resourceItem && (
      <Container>
        <ProtocolValueShowEditResource
          protocolValue={protocolValue}
          showMetadata
          showIcon
        />
      </Container>
    )}
    {protocolValue.is_experiment_child &&
      !protocolValue.experiment?.experiment_workflow_is_template &&
      (protocolValue.is_input ? (
        <ProtocolValueDetailResourceItemInput
          protocolValue={protocolValue}
        />
      ) : (
        <ProtocolValueDetailResourceItemOutput
          protocolValue={protocolValue}
        />
      ))}
  </Flex>
);

export default ProtocolValueDetailResourceItem;
