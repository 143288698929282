/**
 * Labstep
 *
 * @module components/CustomIdentifierSet/Form/Create
 * @desc Create Form for Custom Identifier Set
 */

import {
  fieldPrefix,
  fieldPreview,
  fieldZeroPadding,
} from 'labstep-web/components/CustomIdentifierSet/Form/fields';
import FormCreate from 'labstep-web/core/Form/Create';
import { CustomIdentifierSet } from 'labstep-web/models/custom-identifier-set.model';
import { Group } from 'labstep-web/models/group.model';
import React from 'react';
import { CustomIdentifierSetFormCreateProps } from './types';

export const CustomIdentifierSetFormCreate: React.FC<
  CustomIdentifierSetFormCreateProps
> = ({ groupId, options, parentClass }) => (
  <FormCreate
    autoFocus
    entityName={CustomIdentifierSet.entityName}
    parentName={Group.entityName}
    parentId={groupId}
    fields={[fieldPrefix, fieldZeroPadding, fieldPreview]}
    options={options}
    preSubmit={(settings) => ({
      settings: {
        ...settings,
        zero_padding:
          settings.zero_padding ||
          CustomIdentifierSet.defaultZeroPadding,
      },
      parent_class: parentClass,
    })}
    submitButtonText="Save"
  />
);

export default CustomIdentifierSetFormCreate;
