/**
 * Labstep
 *
 * @module components/EntityUser/Card
 * @desc   EntityUser Card
 */

import Can from 'labstep-web/components/Entity/Can';
import { Action } from 'labstep-web/components/Entity/Can/types';
import EntityUserActionManage from 'labstep-web/components/EntityUser/Action/Manage';
import EntityUserList from 'labstep-web/components/EntityUser/List';
import Button from 'labstep-web/core/Button';
import Flex from 'labstep-web/core/Flex';
import Popup from 'labstep-web/core/Popup';
import React from 'react';
import { EntityUserCardProps } from './types';

const EntityUserCard: React.FC<EntityUserCardProps> = ({
  entity,
}) => (
  <EntityUserActionManage
    entity={entity}
    viewComponent={({ toggleModal }) => (
      <Flex>
        <EntityUserList
          onPlusCountClick={toggleModal}
          entity={entity}
        />
        <Can
          entityName={entity.entityName}
          id={entity.idAttr}
          action={Action.assign}
        >
          <Popup
            trigger={
              <Button
                onClick={toggleModal}
                icon="user plus"
                circular
              />
            }
            content="Manage Collaborators"
            size="mini"
          />
        </Can>
      </Flex>
    )}
  />
);

export default EntityUserCard;
