/**
 * Labstep
 *
 * @module components/Entity/Action/Assign/Bulk
 * @desc Action to tag entity. Opens modal showing tag manager.
 */

import { useHasAccessMultiple } from 'labstep-web/components/Entity/Can/hooks';
import { Action } from 'labstep-web/components/Entity/Can/types';
import { ICONS } from 'labstep-web/constants/icons';
import { withActiveGroup } from 'labstep-web/containers/ActiveGroup';
import { EntityToggleContainer } from 'labstep-web/containers/Entity/Toggle';
import BulkActionComponent from 'labstep-web/core/Action/Component/Bulk';
import ModalDefault from 'labstep-web/core/Modal/Default';
import SearchSelect from 'labstep-web/core/Select/Search';
import { TopLevelPermissionEntity } from 'labstep-web/models';
import { User } from 'labstep-web/models/user.model';
import React from 'react';
import { EntityActionAssignBulkProps } from './types';

export const EntityActionAssignBulk: React.FC<
  EntityActionAssignBulkProps
> = ({ parentEntities, options, activeGroup, parentEntityName }) => {
  const parentEntityIdsAccessObject = useHasAccessMultiple(
    parentEntityName,
    parentEntities.map((e: TopLevelPermissionEntity) => e.id),
    Action.assign,
  );

  if (parentEntities.length === 0) {
    return null;
  }

  const parentEntitiesWithPermission = parentEntities.filter(
    (e: TopLevelPermissionEntity) =>
      parentEntityIdsAccessObject[e.id],
  );

  return (
    <ModalDefault
      header={'Assign to'}
      content={({ toggleModal }) => (
        <EntityToggleContainer
          action="add"
          childKeyName="assignee"
          entityName={User.entityName}
          parentName={parentEntityName}
          parentId={parentEntitiesWithPermission.map((e) => e.id)}
        >
          {({ toggle }) => (
            <SearchSelect
              labelKey="name"
              autoFocus
              params={{ group_id: activeGroup?.id }}
              entityName={User.entityName}
              value={null}
              onChange={(selectedOption) => {
                toggle(undefined, selectedOption.id, options);
                toggleModal();
              }}
            />
          )}
        </EntityToggleContainer>
      )}
      viewComponent={({ toggleModal }) => (
        <BulkActionComponent
          entitiesWithPermissionCount={
            parentEntitiesWithPermission.length
          }
          entitiesSelectedCount={parentEntities.length}
          onClick={toggleModal}
          content={'Assign to'}
          icon={ICONS.entity.actions.assign}
        />
      )}
    />
  );
};

export default withActiveGroup(EntityActionAssignBulk);
