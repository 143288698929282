/**
 * Labstep
 *
 * @module core/Form/ReactForm/DateTimePicker
 * @desc React-form field for DateTimePicker component
 */

import FileActionCreate from 'labstep-web/components/File/Action/Create';
import Input from 'labstep-web/core/Input';
import { File as FileModel } from 'labstep-web/models/file.model';
import React from 'react';
import { Controller } from 'react-hook-form';
import { IFileFieldProps } from './types';

export const FileField: React.FC<IFileFieldProps> = (props) => (
  <Controller
    name={props.name}
    control={props.control}
    render={({ field }): React.ReactElement => {
      const { name } = props;

      const { onChange } = field;
      const value = field.value as FileModel[];

      return (
        <FileActionCreate
          onFolderWatcherSelect={({ file }): void => {
            onChange([file] as any);
          }}
          options={{
            onSuccess: ({ response }) => {
              if (!response) {
                return;
              }
              const file =
                response.entities.file[response.result as any];
              onChange([file] as any);
            },
          }}
        >
          {({ toggleModal }): React.ReactElement => (
            <Input
              name={name}
              onClick={toggleModal}
              size="small"
              value={
                value && value.length > 0
                  ? value.map((file) => file.name).join(', ')
                  : undefined
              }
              placeholder={
                value && value.length > 0
                  ? undefined
                  : 'Click here to upload'
              }
            />
          )}
        </FileActionCreate>
      );
    }}
  />
);

export default FileField;
