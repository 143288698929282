/**
 * Labstep
 *
 * @module components/OrderRequest/Action/Menu
 * @desc Action Menu for orderRequest
 */

import EntityActionRevoke from 'labstep-web/components/Entity/Action/Revoke';
import EntityActionTag from 'labstep-web/components/Entity/Action/Tag';
import EntityActionTransferOwnership from 'labstep-web/components/Entity/Action/TransferOwnership';
import EntityUserActionManage from 'labstep-web/components/EntityUser/Action/Manage';
import OrderRequestActionDelete from 'labstep-web/components/OrderRequest/Action/Delete';
import OrderRequestActionDuplicate from 'labstep-web/components/OrderRequest/Action/Duplicate';
import { Action } from 'labstep-web/components/OrderRequest/Action/Menu/types';
import OrderRequestActionRemovePurchaseOrder from 'labstep-web/components/OrderRequest/Action/RemovePurchaseOrder';
import OrderRequestActionSetPurchaseOrder from 'labstep-web/components/OrderRequest/Action/SetPurchaseOrder';
import OrderRequestActionShare from 'labstep-web/components/OrderRequest/Action/Share';
import OrderRequestActionView from 'labstep-web/components/OrderRequest/Action/View';
import ActionMenu from 'labstep-web/core/Action/Menu';
import React from 'react';
import { OrderRequestActionMenuProps } from './types';

export const OrderRequestActionMenu: React.FC<
  OrderRequestActionMenuProps
> = ({ orderRequest, type, actions = [] }) => (
  <ActionMenu type={type}>
    {actions.includes(Action.view) && (
      <OrderRequestActionView orderRequest={orderRequest} />
    )}
    {actions.includes(Action.set_purchase_order) && (
      <OrderRequestActionSetPurchaseOrder
        orderRequest={orderRequest}
        currency={orderRequest.currency}
      />
    )}
    {actions.includes(Action.remove_purchase_order) && (
      <OrderRequestActionRemovePurchaseOrder
        orderRequest={orderRequest}
      />
    )}
    {actions.includes(Action.share) && (
      <OrderRequestActionShare orderRequest={orderRequest} />
    )}
    {actions.includes(Action.duplicate) && (
      <OrderRequestActionDuplicate orderRequest={orderRequest} />
    )}
    {actions.includes(Action.assign) && (
      <EntityUserActionManage entity={orderRequest} />
    )}
    {actions.includes(Action.tag) && (
      <EntityActionTag parentEntity={orderRequest} />
    )}
    {actions.includes(Action.revoke) && (
      <EntityActionRevoke entity={orderRequest} />
    )}
    {actions.includes(Action.transfer_ownership) && (
      <EntityActionTransferOwnership entity={orderRequest} />
    )}
    {actions.includes(Action.delete) && (
      <OrderRequestActionDelete orderRequest={orderRequest} />
    )}
  </ActionMenu>
);

OrderRequestActionMenu.defaultProps = {
  actions: [],
  type: 'icon',
};

export default OrderRequestActionMenu;
