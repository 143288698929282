/**
 * Labstep
 *
 * @module grid/Index/coldefs/status
 * @desc Column Definition for status
 */

import { ValueGetterParams } from 'ag-grid-community';
import FilterResourceItemStatus from 'labstep-web/components/Filter/ResourceItemStatus';
import { ResourceItemStatusFilterProps } from 'labstep-web/components/Filter/ResourceItemStatus/types';
import DataGrid from 'labstep-web/core/DataGrid';
import { suppressKeyboardEventDisableEnterOnEdit } from 'labstep-web/core/DataGrid/CellEditor/utils';
import TextWithMetadata from 'labstep-web/core/DataGrid/Text/WithMetadata';
import {
  CellEditorParams,
  CellRendererParams,
  ColDef,
  ColDefParams,
} from 'labstep-web/core/DataGrid/types';
import {
  getEditable,
  setValue,
} from 'labstep-web/core/DataGrid/utils';
import Flex from 'labstep-web/core/Flex';
import Icon from 'labstep-web/core/Icon';
import TextWithIcon from 'labstep-web/core/Text/WithIcon';
import {
  OrderRequest,
  OrderRequestStatus,
  OrderRequestStatusValues,
} from 'labstep-web/models/order-request.model';
import {
  ResourceItem,
  ResourceItemStatus,
  ResourceItemStatusValues,
} from 'labstep-web/models/resource-item.model';
import { objectOrFunction } from 'labstep-web/services/react.service';
import React from 'react';
import styles from './styles.module.scss';

/*
 * Column definition for ResourceItem status
 */
const colDefStatus: <T extends ResourceItem | OrderRequest>(
  entityName: T['entityName'],
  metadata?:
    | React.ReactNode
    | ((params: ColDefParams<T>) => React.ReactNode),
  onChange?: (
    entity: ResourceItem | OrderRequest,
    option: ResourceItemStatus | OrderRequestStatus,
  ) => void,
) => ColDef<T> = (entityName, metadata, onChange) => {
  const values =
    entityName === ResourceItem.entityName
      ? ResourceItemStatusValues
      : OrderRequestStatusValues;
  return {
    colId: 'status',
    headerName:
      entityName === ResourceItem.entityName
        ? 'Availability'
        : 'Status',
    headerComponentParams: {
      icon: 'info circle',
      filter: (props: ResourceItemStatusFilterProps) => (
        <FilterResourceItemStatus
          {...props}
          isOrderRequest={entityName === OrderRequest.entityName}
        />
      ),
    },
    cellRenderer: (
      params: CellRendererParams<ResourceItem | OrderRequest>,
    ) => {
      if (!params.data) {
        return '';
      }
      return (
        <TextWithMetadata
          text={
            <Flex className={styles.status}>
              <TextWithIcon
                icon="circle"
                iconProps={{ className: styles[params.data.status] }}
                text={
                  values[params.data.status as keyof typeof values]
                }
              />
              <Icon
                name="sort down"
                onClick={() =>
                  params.api.startEditingCell({
                    rowIndex: params.rowIndex,
                    colKey: 'status',
                  })
                }
              />
            </Flex>
          }
          metadata={objectOrFunction(metadata, params)}
        />
      );
    },
    cellEditor: DataGrid.CellEditor.Select,
    cellEditorParams: (
      params: CellEditorParams<ResourceItem | OrderRequest>,
    ) => ({
      props: {
        value: {
          label: values[params.data.status as keyof typeof values],
          value: params.data.status,
        },
        options: Object.keys(values).map((status) => ({
          label: values[status as keyof typeof values],
          value: status,
        })),
        onChange: (option: {
          value: ResourceItemStatus | OrderRequestStatus;
        }) => {
          setValue('status', option.value, {
            entityName: params.data.entityName,
            id: params.data.id,
          });
          onChange?.(params.data, option.value);
        },
      },
    }),
    suppressKeyboardEvent: suppressKeyboardEventDisableEnterOnEdit,
    cellEditorPopup: true,
    editable: getEditable(),
    valueGetter: (params: ValueGetterParams) => params.data.status,
    valueSetter: (params) =>
      setValue('status', params.newValue, {
        entityName: params.data.entityName,
        id: params.data.id,
      }),
    autoHeight: !!metadata,
  };
};

export default colDefStatus;
