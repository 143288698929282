/**
 * Labstep
 *
 * @module grid/Index/coldefs/entityLink
 * @desc Column Definition for a link to an entity
 */

import { ValueGetterParams } from 'ag-grid-community';
import { EntityViewContextColumnOptionsConsumer } from 'labstep-web/components/EntityView/Context';
import MetadataPreview from 'labstep-web/components/Metadata/Preview';
import { EntityReadEntityContainer } from 'labstep-web/containers/Entity/Read/Entity';
import DataGridPlaceholder from 'labstep-web/core/DataGrid/Placeholder';
import TextLink from 'labstep-web/core/DataGrid/Text/Link';
import { ColDef } from 'labstep-web/core/DataGrid/types';
import {
  getEditable,
  getEntityDefault,
  setValue,
} from 'labstep-web/core/DataGrid/utils';
import GridIndexActionToggleColumnOption from 'labstep-web/grid/Index/components/ToggleColumnOption';
import { EntityView } from 'labstep-web/models/entity-view.model';
import { Entity } from 'labstep-web/models/entity.model';
import styles from './styles.module.scss';
import { colDefEntityLinkType } from './types';

/**
 * Column definition for entity link
 * @param getNestedEntity Custom fn to get entity as nested field
 * @returns Column definition
 */
const colDefEntityLink: colDefEntityLinkType = (options = {}) => {
  const {
    getNestedEntity,
    getEntityPreviewProps,
    placeholder,
    noLink,
    getMetadataPreviewEntity,
  } = options;
  const getEntity = getNestedEntity || getEntityDefault;
  const cellRenderer: ColDef<Entity>['cellRenderer'] = (params) => {
    const entity = getEntity(params);
    if (!entity) {
      return placeholder ? (
        <DataGridPlaceholder
          params={params}
          editable={getEditable(getNestedEntity)}
          children={placeholder}
        />
      ) : null;
    }
    const { entityName, idAttr } = entity;
    const children = (showMetadataPreview: boolean) => (
      <EntityReadEntityContainer entityName={entityName} id={idAttr}>
        {({ entity: entityRead }): JSX.Element => (
          <div className={styles.cellRenderer}>
            <TextLink
              entity={entityRead}
              disabled={noLink}
              entityPreviewProps={getEntityPreviewProps?.(entityRead)}
            />
            {showMetadataPreview && (
              <small>
                <MetadataPreview
                  entity={
                    getMetadataPreviewEntity?.(entityRead) ||
                    entityRead
                  }
                />
              </small>
            )}
          </div>
        )}
      </EntityReadEntityContainer>
    );
    if (
      !EntityView.doesColIdHaveColumnOptions(params.column.getColId())
    ) {
      return children(false);
    }
    return (
      <EntityViewContextColumnOptionsConsumer
        colId={params.column.getColId() as 'name' | 'resource_name'}
      >
        {({ columnOptions: { showMetadataPreview } }) =>
          children(showMetadataPreview)
        }
      </EntityViewContextColumnOptionsConsumer>
    );
  };

  return {
    colId: 'entityLink',
    cellRenderer,
    editable: getEditable(getNestedEntity),
    headerComponentParams: (params) => {
      const colId = params.column.getColId();
      if (!EntityView.doesColIdHaveColumnOptions(colId)) {
        return {};
      }
      return {
        columnOptions: (
          <GridIndexActionToggleColumnOption
            colId={colId as 'name' | 'resource_name'}
            columnOption="showMetadataPreview"
            columnName="Metadata Preview"
          />
        ),
      };
    },
    valueGetter: (params: ValueGetterParams): string | null =>
      getEntity(params)?.name || null,
    valueSetter: (params: any): boolean => {
      const entity = getEntity(params);
      if (!entity) {
        return false;
      }
      return setValue('name', params.newValue, {
        entityName: entity.entityName,
        id: entity.id,
      });
    },
    valueClearer: () => undefined,
    autoHeight: true,
  };
};

export default colDefEntityLink;
