/**
 * Labstep
 *
 * @module components/ResourceItem/Manager/Template
 * @desc Manager for ResourceItem template
 */

import ResourceItemCardEmptyStateTemplate from 'labstep-web/components/ResourceItem/Card/EmptyState/Template';
import ResourceItemPreviewTemplate from 'labstep-web/components/ResourceItem/Preview/Template';
import React from 'react';
import { IResourceItemManagerTemplateProps } from './types';

const ResourceItemManagerTemplate: React.FC<
  IResourceItemManagerTemplateProps
> = ({ resource }) =>
  resource.hasNonDeletedResourceItemTemplate ? (
    <ResourceItemPreviewTemplate
      resourceItem={resource.resource_item_template!}
    />
  ) : (
    <ResourceItemCardEmptyStateTemplate resource={resource} />
  );

export default ResourceItemManagerTemplate;
