/**
 * Labstep
 *
 * @module components/EntityUser/Table
 * @desc   EntityUser Table
 */

import EntityUserActionMenu from 'labstep-web/components/EntityUser/Action/Menu';
import { Action } from 'labstep-web/components/EntityUser/Action/Menu/types';
import EntityUserActionToggleAssign from 'labstep-web/components/EntityUser/Action/ToggleAssign';
import EntityUserAvatar from 'labstep-web/components/EntityUser/Avatar';
import EntityUserFormShowEditEntityUserRole from 'labstep-web/components/EntityUser/Form/ShowEdit/EntityUserRole';
import Header from 'labstep-web/core/Header';
import TableSimple from 'labstep-web/core/Table/Simple';
import { EntityUser } from 'labstep-web/models/entity-user.model';
import React from 'react';
import styles from './styles.module.scss';
import { EntityUserTableProps } from './types';

const EntityUserTable: React.FC<EntityUserTableProps> = ({
  entity,
  entityUsers,
  withEntityUserRole,
}) => {
  const content = (entityUser: EntityUser) => {
    if (withEntityUserRole) {
      return (
        <div
          data-testid="entity-user-table-role"
          className={styles.container}
        >
          <EntityUserFormShowEditEntityUserRole
            entityUser={entityUser}
          />

          <EntityUserActionMenu
            actions={[Action.toggle_assign]}
            entity={entity}
            entityUser={entityUser}
          />
        </div>
      );
    }

    return (
      <EntityUserActionToggleAssign
        entity={entity}
        entityUser={entityUser}
      />
    );
  };
  const columns = [
    {
      header: '',
      content: (entityUser: EntityUser) => (
        <EntityUserAvatar entityUser={entityUser} />
      ),
      cellProps: { style: { width: 50 } },
    },
    {
      header: '',
      content: (entityUser: EntityUser) => (
        <div>
          <Header isDataPublic={false} noMargin size="tiny">
            {entityUser.user.name}
          </Header>
          <div>
            <span>
              {entityUser.getIsAuthor(entity.author)
                ? 'Creator'
                : entityUser.is_contributor && 'Contributor'}
            </span>
            {!withEntityUserRole && entityUser.is_assigned && (
              <>
                {(entityUser.getIsAuthor(entity.author) ||
                  entityUser.is_contributor) && <span> - </span>}
                <span className={styles.assigned}>Assigned</span>
              </>
            )}
          </div>
        </div>
      ),
      cellProps: { width: 8 },
    },
    {
      header: '',
      content,
      cellProps: { width: 8 },
    },
  ];
  return (
    <div data-testid="entity-user-table">
      <TableSimple
        hideHeader
        rows={entityUsers.filter(
          (entityUser) =>
            entityUser.is_contributor || entityUser.is_assigned,
        )}
        columns={columns}
      />
    </div>
  );
};

export default EntityUserTable;
