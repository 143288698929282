/**
 * Labstep
 */

import { PostFilter } from 'labstep-web/services/postFilter';
import { PostFilterComparison } from 'labstep-web/services/query-parameter.service';

export const PostFilterStatus = new PostFilter([
  ['status', PostFilterComparison.eq],
]);
