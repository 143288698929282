/**
 * Labstep
 *
 * @module core/Popup
 * @desc Wrapper around semantic-ui-react Popup
 */

import React from 'react';
import { isMobile, isTablet } from 'react-device-detect';
import Popup from 'semantic-ui-react/dist/commonjs/modules/Popup';
import { IPopupProps } from './types';

export const PopupContent = Popup.Content;

export const PopupContainer: React.FC<IPopupProps> = (props) => {
  if ((isMobile || isTablet) && props.trigger) {
    return <>{props.trigger}</>;
  }
  return <Popup position="top center" basic inverted {...props} />;
};

export default PopupContainer;
