/**
 * Labstep
 *
 * @module components/ExperimentWorkflow/Status
 * @desc Displays the status of an experiment workflow
 */

import { ICONS } from 'labstep-web/constants/icons';
import Icon from 'labstep-web/core/Icon';
import { IconProps } from 'labstep-web/core/Icon/types';
import Popup from 'labstep-web/core/Popup';
import { SignedTypes } from 'labstep-web/models/experiment-workflow.model';
import { humanReadableDate } from 'labstep-web/services/date.service';
import React from 'react';
import { ExperimentWorkflowStatusProps } from './types';

export const ExperimentWorkflowStatus: React.FC<
  ExperimentWorkflowStatusProps
> = ({ experimentWorkflow }) => {
  let color: IconProps['color'];
  let content = 'Unstarted';

  if (experimentWorkflow.statusType === 'completed') {
    color = 'green';
    content = `Completed on ${humanReadableDate(
      experimentWorkflow.ended_at,
    )}`;
  } else if (experimentWorkflow.statusType === 'started') {
    color = 'yellow';
    content = 'Started';
  }
  return (
    <>
      <Popup
        trigger={
          <Icon
            name={
              ICONS.experiment_workflow.info[
                experimentWorkflow.statusType
              ]
            }
            size="small"
            color={color}
          />
        }
        content={content}
        size="tiny"
      />
      {experimentWorkflow.isSigned && (
        <Popup
          trigger={
            <Icon
              name={SignedTypes.signed.icon}
              size="small"
              color="grey"
            />
          }
          content="Signed"
          size="tiny"
        />
      )}
      {experimentWorkflow.locked_at && (
        <Popup
          trigger={
            <Icon
              name={ICONS.experiment_workflow.actions?.lock}
              size="small"
              color="grey"
            />
          }
          content="Locked"
          size="tiny"
        />
      )}
    </>
  );
};

export default ExperimentWorkflowStatus;
