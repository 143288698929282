import Table from 'labstep-web/components/Entity/Action/Update/AllowedActionsLock/Table';
import Message from 'labstep-web/core/Message';
import HorizontalTable from 'labstep-web/core/Table/Horizontal';
import { Group } from 'labstep-web/models/group.model';
import { arrayify } from 'labstep-web/state/reducers/helpers';
import React from 'react';
import EntityActionUpdate from '../..';
import { AllowedActionsLockSections } from '../Table/constants';
import { EntityActionUpdateAllowedActionsLockContentProps } from './types';

export const EntityActionUpdateAllowedActionsLockContent: React.FC<
  EntityActionUpdateAllowedActionsLockContentProps
> = ({ entity }) => (
  <>
    {entity instanceof Group && (
      <Message warning>
        These settings can be overriden for specific protocols by
        modifying the lock settings for that protocol.
      </Message>
    )}

    <HorizontalTable
      data-cy="components/Entity/Action/Update/AllowedActionsLock/Content"
      padded
      headerProps={{ width: 3, verticalAlign: 'top' }}
      rows={[
        {
          header: 'Lock when running protocol',
          content: () => {
            return (
              <>
                <EntityActionUpdate
                  entityName={entity.entityName}
                  id={entity.id}
                  actionComponentProps={{
                    dataCy:
                      'components/Entity/Action/Update/AllowedActionsLock/Content:lock',
                    type: 'checkbox',
                    elementProps: {
                      checked:
                        !!entity.default_experiment_allowed_actions_lock,
                      toggle: true,
                    },
                  }}
                  body={{
                    default_experiment_allowed_actions_lock:
                      entity.default_experiment_allowed_actions_lock ===
                      null
                        ? Object.values(
                            AllowedActionsLockSections,
                          ).reduce((result, section) => {
                            return [
                              ...result,
                              ...section
                                .filter((action) => action.default)
                                .map((action) =>
                                  arrayify(action.value),
                                )
                                .flat(),
                            ];
                          }, [])
                        : null,
                  }}
                />
                {entity.default_experiment_allowed_actions_lock ? (
                  <>
                    <p>
                      Select which actions users should be allowed to
                      do when running the locked protocol
                    </p>
                    <Table entity={entity} />
                  </>
                ) : (
                  <p>
                    Enable to restrict which actions a user can do
                    when running the protocol
                  </p>
                )}
              </>
            );
          },
        },
      ]}
      columns={[entity]}
    />
  </>
);

export default EntityActionUpdateAllowedActionsLockContent;
