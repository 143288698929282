/**
 * Labstep
 *
 * @module components/ProtocolValue/Form/ShowEdit/Unit
 * @desc ProtocolValue Unit
 */

import classnames from 'classnames';
import { getProtocolValueFieldUnit } from 'labstep-web/components/ProtocolValue/Form/fields';
import FormShowEditSelect from 'labstep-web/core/Form/ShowEdit/Select';
import stylesShowEdit from 'labstep-web/core/Form/ShowEdit/styles.module.scss';
import React from 'react';
import { IProtocolValueShowEditUnitProps } from './types';

export const ProtocolValueShowEditUnit: React.FC<
  IProtocolValueShowEditUnitProps
> = ({ protocolValue, placeholder }) => (
  <FormShowEditSelect
    dataCy="components/ProtocolValue/Form/ShowEdit/Unit"
    creatable={{
      formatCreateLabel: (input): string => `Custom unit "${input}"`,
    }}
    entity={protocolValue}
    field={getProtocolValueFieldUnit(
      protocolValue.has_chemical,
      protocolValue.unit,
    )}
    placeholder={placeholder}
    customShow={({ toggle }): React.ReactElement => (
      <div
        data-cy="components/ProtocolValue/Form/ShowEdit/Unit:Toggle"
        data-testid="show-edit-toggle"
        onClick={toggle}
        className={classnames(
          stylesShowEdit.toggleContainer,
          'toggle',
        )}
      >
        {protocolValue.unitDisplayValue}
      </div>
    )}
  >
    {protocolValue.unitDisplayValue}
  </FormShowEditSelect>
);

export default ProtocolValueShowEditUnit;
