/**
 * Labstep
 *
 * @module screens/Protocol/Show
 * @desc Protocol show
 */

import LayoutLoadingContent from 'labstep-web/components/Layout/LoadingContent';
import ProtocolConditionModal from 'labstep-web/components/ProtocolCondition/Modal/Container';
import { ProtocolConditionModalProvider } from 'labstep-web/components/ProtocolCondition/Modal/context';
import { withAuthenticatedUser } from 'labstep-web/containers/AuthenticatedUser';
import DocumentHead from 'labstep-web/core/DocumentHead';
import { ReadOnMountHOC } from 'labstep-web/hoc/ReadOnMount';
import { ProtocolCollection } from 'labstep-web/models/protocol-collection.model';
import MasterShow from 'labstep-web/screens/Master/Show';
import React from 'react';
import Center from './Center';
import Right from './Right';
import {
  IScreensProtocolCollectionShowProps,
  IShowProps,
} from './types';

// eslint-disable-next-line react/prefer-stateless-function
export class Show extends React.Component<IShowProps> {
  componentDidUpdate(prevProps: Readonly<IShowProps>): void {
    if (
      prevProps.protocolCollection.all_versions_count !==
        this.props.protocolCollection.all_versions_count ||
      this.props.protocolCollection?.draft_version?.id !==
        prevProps.protocolCollection?.draft_version?.id
    ) {
      this.props.setCount(this.props.count + 1);
    }
  }

  public render() {
    const { protocolCollection } = this.props;
    const protocol = protocolCollection.last_or_draft_version;

    if (!protocol) {
      return (
        <LayoutLoadingContent
          loader="main_content"
          status={{ isFetching: true, error: null }}
          children={null}
        />
      );
    }

    return (
      <>
        <DocumentHead title={protocolCollection.name} />
        <ProtocolConditionModalProvider
          entityName={ProtocolCollection.entityName}
        >
          <ProtocolConditionModal
            protocol={protocolCollection.last_or_draft_version}
          />
          <MasterShow
            entity={protocolCollection}
            center={
              <Center protocolCollection={protocolCollection} />
            }
            right={<Right protocolCollection={protocolCollection} />}
          />
        </ProtocolConditionModalProvider>
      </>
    );
  }
}

const ScreensProtocolCollectionShow: React.FC<
  IScreensProtocolCollectionShowProps
> = (props) => {
  const [count, setCount] = React.useState(0);

  return (
    <ReadOnMountHOC
      key={count}
      type="entities"
      loading={{ loader: 'show' }}
      entityName="protocol_collection"
      params={{
        get_single: 1,
        is_deleted: 'both',
        id: props.match.params.id,
      }}
      children={({ entity: protocolCollection }) => (
        <Show
          protocolCollection={protocolCollection}
          setCount={setCount}
          count={count}
        />
      )}
    />
  );
};

export default withAuthenticatedUser(ScreensProtocolCollectionShow);
