/**
 * Labstep
 *
 * @module core/Input/Async
 * @desc Input that saves its value async
 */

import Input from 'labstep-web/core/Input';
import React from 'react';
import { IAsyncInputProps } from './types';

let timeout: NodeJS.Timeout | null = null;
const debounceTime = 500;

export const async = <TValue extends string>(
  func: (value: TValue) => void,
  value: TValue,
) => {
  if (timeout) {
    clearTimeout(timeout);
  }

  timeout = setTimeout(() => {
    func(value);
  }, debounceTime);
};

class AsyncInput extends React.Component<IAsyncInputProps> {
  constructor(props: IAsyncInputProps) {
    super(props);
    this.onChange = this.onChange.bind(this);
  }

  onChange(evt: React.ChangeEvent<HTMLInputElement>) {
    const { value } = evt.target;
    async(this.props.onChange, value);
  }

  render() {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { onChange, inputRef, ...rest } = this.props;

    return (
      <Input
        ref={inputRef}
        fluid
        {...rest}
        onChange={this.onChange}
      />
    );
  }
}

export default AsyncInput;
