/**
 * Labstep
 *
 * @module components/Organization/PremiumFeatureToggle
 * @desc Clicking on a checkbox will display a modal to explain premium feature is locked
 */

import Checkbox from 'labstep-web/core/Checkbox';
import PremiumFeatureLockIcon from 'labstep-web/core/PremiumFeature/LockIcon';
import PremiumFeatureModal from 'labstep-web/core/PremiumFeature/Modal';
import React from 'react';
import styles from './styles.module.scss';
import { IPremiumFeatureToggleProps } from './types';

export const PremiumFeatureToggle: React.FC<
  IPremiumFeatureToggleProps
> = ({ premiumFeature }) => (
  <div className={styles.container}>
    <PremiumFeatureModal
      premiumFeature={premiumFeature}
      viewComponent={<Checkbox toggle checked={false} />}
    />
    <PremiumFeatureLockIcon premiumFeature={premiumFeature} />
  </div>
);

export default PremiumFeatureToggle;
