/**
 * Labstep
 *
 * @module components/EntityImport/Action/Delete
 * @desc Action to delete
 */

import EntityActionDelete from 'labstep-web/components/Entity/Action/Delete';
import React from 'react';
import { EntityImportActionDeleteProps } from './types';

export const EntityImportActionDelete: React.FC<
  EntityImportActionDeleteProps
> = ({ entity }) => <EntityActionDelete entity={entity} />;

export default EntityImportActionDelete;
