/**
 * Labstep
 *
 * @module components/OrderRequest/Action/SetPurchaseOrder/Bulk
 * @desc Bulk set purchase order
 */

import { Currency as DineroCurrency } from 'dinero.js';
import { useHasAccessMultiple } from 'labstep-web/components/Entity/Can/hooks';
import { Action } from 'labstep-web/components/Entity/Can/types';
import { OrderRequestActionUpdatePurchaseOrderComponent } from 'labstep-web/components/OrderRequest/Action/SetPurchaseOrder';
import { ICONS } from 'labstep-web/constants/icons';
import BulkActionComponent from 'labstep-web/core/Action/Component/Bulk';
import { OrderRequest } from 'labstep-web/models/order-request.model';
import React from 'react';
import { OrderRequestActionUpdatePurchaseOrderBulkProps } from './types';

export const OrderRequestActionUpdatePurchaseOrderBulk: React.FC<
  OrderRequestActionUpdatePurchaseOrderBulkProps
> = ({ entities, options = {} }) => {
  const entityIdsAccessObject = useHasAccessMultiple(
    OrderRequest.entityName,
    entities ? entities.map((e) => e.id) : [],
    Action.edit,
  );

  const entitiesWithPermission = entities
    ? entities.filter(
        (e) => entityIdsAccessObject[e.id] && e.canSetPurchaseOrder,
      )
    : [];

  if (
    !entities ||
    entities.length === 0 ||
    entitiesWithPermission.length === 0
  ) {
    return null;
  }

  let currency: DineroCurrency = 'USD';
  let disabled = false;

  if (entitiesWithPermission.length > 0) {
    currency = entitiesWithPermission[0].currency;
    const allEqual = entitiesWithPermission.every(
      (entity) => entity.currency === currency,
    );
    if (!allEqual) {
      disabled = true;
    }
  }

  return (
    <OrderRequestActionUpdatePurchaseOrderComponent
      currency={currency}
      options={options}
      orderRequestId={entitiesWithPermission.map((p) => p.id)}
      viewComponent={({ toggleModal }) => (
        <BulkActionComponent
          entitiesWithPermissionCount={entitiesWithPermission.length}
          entitiesSelectedCount={entities.length}
          onClick={toggleModal}
          disabled={disabled}
          disabledReason="Only requests with matching currencies can be added to an order"
          content="Add to Order"
          icon={ICONS.purchase_order.primary}
        />
      )}
    />
  );
};

export default OrderRequestActionUpdatePurchaseOrderBulk;
