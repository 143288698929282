/**
 * Labstep
 */

import { ParamsHOCChildrenProps } from 'labstep-web/hoc/Params/types';
import { Tag } from 'labstep-web/models/tag.model';

export const onTagChange = (
  selectedOption: Tag,
  setParams: ParamsHOCChildrenProps['setParams'],
  searchParams: ParamsHOCChildrenProps['searchParams'],
) => {
  const { tag_id: ids } = searchParams;
  const currentIdsArray = ids ? (ids as string).split(',') : [];
  const tags = [...currentIdsArray, selectedOption.id];
  setParams({ tag_id: tags.join() });
};

export const onTagClear = (
  tagIdToBeRemoved: number,
  searchParams: ParamsHOCChildrenProps['searchParams'],
  setParams: ParamsHOCChildrenProps['setParams'],
) => {
  const { tag_id: ids } = searchParams;
  const currentTagIdsArray = (ids as string).split(',');
  const newTagIds = currentTagIdsArray.filter(
    (id) => String(id) !== String(tagIdToBeRemoved),
  );
  const param = newTagIds.length === 0 ? undefined : newTagIds.join();
  setParams({ tag_id: param });
};
