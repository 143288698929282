/**
 * Labstep
 *
 * @module components/Group/Form/ShowEdit/Description
 * @desc Edit group description
 */

import { fieldDescription } from 'labstep-web/components/Group/Form/fields';
import ShowEditText from 'labstep-web/core/Form/ShowEdit/Text';
import Linkify from 'labstep-web/core/Linkify';
import Pre from 'labstep-web/core/Pre';
import React from 'react';
import { GroupFormShowEditDescriptionProps } from './types';

export const GroupFormShowEditDescription: React.FC<
  GroupFormShowEditDescriptionProps
> = ({ group }) => (
  <ShowEditText
    entityName={group.entityName}
    entity={group}
    field={fieldDescription}
    placeholder="Add description"
  >
    <Pre>
      <Linkify>{group.description}</Linkify>
    </Pre>
  </ShowEditText>
);

export default GroupFormShowEditDescription;
