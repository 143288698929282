/**
 * Labstep
 *
 * @module prosemirror/nodes/horizontal-rule
 * @desc Horizontal rule node
 */

import { createHorizontalRuleCmd } from 'labstep-web/prosemirror/nodes/horizontal-rule/commands';
import { LabstepNode } from 'labstep-web/prosemirror/nodes/types';

const NodeHorizontalRule: LabstepNode = {
  key: 'horizontal_rule',
  spec: {
    group: 'block',
    parseDOM: [{ tag: 'hr' }],
    toDOM() {
      return ['hr'];
    },
  },
  keymap: {
    'Mod-_': (state, dispatch) =>
      createHorizontalRuleCmd(state, dispatch),
  },
};

export default NodeHorizontalRule;
