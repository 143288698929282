/**
 * Labstep
 *
 * @module core/Form/ReactForm/SelectOptions
 * @desc Input with tokenized items
 */

import { arrayify } from 'labstep-web/state/reducers/helpers';
import { union } from 'lodash';
import React from 'react';
import { Controller } from 'react-hook-form';
import { Dropdown, DropdownProps } from 'semantic-ui-react';
import {
  ISelectOptionsDropdownProps,
  ISelectOptionsProps,
} from './types';

export const convertKeysToOptions = (
  keys: string[],
): { text: string; value: string }[] =>
  keys.map((value: string) => ({
    text: value,
    value,
  }));

export class SelectOptionsDropdown extends React.Component<
  ISelectOptionsDropdownProps,
  { blurred: boolean }
> {
  public constructor(props: ISelectOptionsDropdownProps) {
    super(props);
    this.state = {
      blurred: false,
    };
  }

  public componentWillUnmount(): void {
    if (
      this.props.elementProps?.blurOnUnmount &&
      !this.state.blurred
    ) {
      this.props.onBlur();
    }
  }

  public handleChange: DropdownProps['onChange'] = (
    e,
    dropdownProps,
  ) => {
    const { value } = this.props;
    const dropdownValues = arrayify(dropdownProps.value) as string[];

    const keys = union(value.keys, dropdownValues);
    const values = dropdownValues;
    this.props.setValue({ keys, values });
  };

  public onClose: DropdownProps['onClose'] = () => {
    this.props.onBlur();
    // make sure we don't call onBlur twice if onClose also called
    this.setState({ blurred: true });
  };

  public render(): React.ReactElement {
    const { value, elementProps, autoFocus, disabled, placeholder } =
      this.props;

    const multiple = elementProps?.multiple;
    const allowAdd = elementProps?.allowAdd;

    return (
      <Dropdown
        fluid
        placeholder={placeholder}
        clearable={!multiple}
        multiple={multiple}
        // This so that user can't change text/input while disabled
        search={!disabled}
        selection
        icon={null}
        options={convertKeysToOptions(value.keys)}
        allowAdditions={allowAdd}
        defaultValue={value.values}
        defaultOpen={autoFocus}
        onChange={this.handleChange}
        noResultsMessage="No options found"
        selectOnBlur={false}
        onClose={this.onClose}
        disabled={disabled}
      />
    );
  }
}

export const SelectOptions: React.FC<ISelectOptionsProps> = ({
  name,
  control,
  ...rest
}) => (
  <Controller
    name={name}
    control={control}
    render={({ field: { value, onChange } }): React.ReactElement => {
      return (
        <SelectOptionsDropdown
          value={value}
          setValue={onChange}
          {...rest}
        />
      );
    }}
  />
);

export default SelectOptions;
