/**
 * Labstep
 *
 * @module components/ResourceLocation/MapModalCreate
 * @desc Component to show MapModal or a Create action for Map Modal
 * On creation it automatically opens modal
 */

import ResourceLocationMapModal from 'labstep-web/components/ResourceLocation/MapModal';
import React, { useState } from 'react';
import { IResourceLocationMapModalCreateProps } from './types';

export const ResourceLocationMapModalCreate: React.FC<
  IResourceLocationMapModalCreateProps
> = ({
  resourceLocation,
  initialSelectedState,
  viewComponent,
  createAction,
  initialSelectionInfo,
}) => {
  const [shouldOpenModal] = useState(!resourceLocation.map_data);
  return (
    <>
      {resourceLocation.map_data ? (
        <ResourceLocationMapModal
          initialModalState={shouldOpenModal}
          initialSelectedState={initialSelectedState}
          resourceLocation={resourceLocation}
          viewComponent={viewComponent}
          initialSelectionInfo={initialSelectionInfo}
        />
      ) : (
        createAction
      )}
    </>
  );
};

export default ResourceLocationMapModalCreate;
