/**
 * Labstep
 *
 * @module grid/Report/components/ToolPanel/AddColumn
 * @desc Wizard to add a column
 */

import EntitySearchMulti from 'labstep-web/components/Entity/Search/Multi';
import { ActionComponent } from 'labstep-web/core/Action/Component';
import Flex from 'labstep-web/core/Flex';
import Header, { SectionHeader } from 'labstep-web/core/Header';
import { getMetadataExperimentColDef } from 'labstep-web/grid/Report/coldefs';
import { ToolPanelState } from 'labstep-web/grid/Report/components/ToolPanel';
import EntityDataGridToolPanelExperiment from 'labstep-web/grid/Report/components/ToolPanel/Experiment';
import EntityDataGridToolPanelListItem from 'labstep-web/grid/Report/components/ToolPanel/ListItem';
import EntityDataGridToolPanelProtocol from 'labstep-web/grid/Report/components/ToolPanel/Protocol';
import { Metadata } from 'labstep-web/models/metadata';
import { ProtocolValue } from 'labstep-web/models/protocol-value.model';
import React from 'react';
import { IEntityDataGridToolPanelAddColumnProps } from './types';

export const EntityDataGridToolPanelAddColumn: React.FC<
  IEntityDataGridToolPanelAddColumnProps
> = ({
  authenticatedUserEntity,
  colIds,
  entityView,
  gridReportService,
  setToolPanelState,
  setProtocolValue,
}) => (
  <div>
    <Flex vAlignContent="center" spacing="gap" shrink={0}>
      <ActionComponent
        type="icon"
        icon="arrow left"
        text="Go Back"
        onClick={() => setToolPanelState(ToolPanelState.default)}
      />
      <Header size="tiny" noMargin>
        Add Column
      </Header>
    </Flex>
    <small>
      First you must select the field that you wish to add as a column
    </small>
    <EntitySearchMulti
      staticLists={(searchQuery) => [
        <EntityDataGridToolPanelExperiment
          authenticatedUserEntity={authenticatedUserEntity}
          colIds={colIds}
          key="experiment"
          searchQuery={searchQuery}
          gridReportService={gridReportService}
        />,
        <EntityDataGridToolPanelProtocol
          colIds={colIds}
          key="protocol"
          searchQuery={searchQuery}
          gridReportService={gridReportService}
        />,
      ]}
      lists={[
        {
          key: Metadata.entityName,
          entityName: Metadata.entityName,
          searchField: 'label_like',
          params: {
            permission_class: 'experiment',
            group_id: entityView.group.id,
            group_by_label: 1,
            sort: 'label',
            count: 3,
            serializerGroups:
              'protocol_condition_reporting,paginator',
          },
          header: <SectionHeader label="Data Fields" />,
          listItem: (entity: object) => (
            <EntityDataGridToolPanelListItem
              icon="database"
              actionIcon="eye"
              text={
                (entity as Metadata).label || 'Untitled data field'
              }
              onClick={() => {
                const colDef = getMetadataExperimentColDef(
                  (entity as Metadata).type,
                  (entity as Metadata).label || '',
                );
                gridReportService.addColumnDef(colDef);
              }}
              disabled={colIds.includes((entity as Metadata).colId)}
            />
          ),
        },
        {
          key: ProtocolValue.entityName,
          entityName: ProtocolValue.entityName,
          searchField: 'name_like',
          params: {
            group_id: entityView.group.id,
            count: 3,
            group_by_name: 1,
            sort: 'name',
            serializerGroups:
              'protocol_condition_reporting,paginator',
          },
          header: <SectionHeader label="Inventory Fields" />,
          listItem: (entity: object) => (
            <EntityDataGridToolPanelListItem
              icon="flask"
              actionIcon="arrow right"
              text={(entity as ProtocolValue).label}
              onClick={() => {
                setProtocolValue(entity as ProtocolValue);
                setToolPanelState(ToolPanelState.protocol_value);
              }}
            />
          ),
        },
      ]}
    />
  </div>
);

export default EntityDataGridToolPanelExperiment;
