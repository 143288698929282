/**
 * Labstep
 */

import { Action } from 'labstep-web/models/action.model';
import { mergeWithArray } from 'labstep-web/state/reducers/helpers/utils';
import isEqual from 'lodash/isEqual';
import union from 'lodash/union';
import uniqWith from 'lodash/uniqWith';

/**
 * Returns the state when requesting a cursor-based page
 *
 * @function
 * @param  {object} state - Redux state (only the portion for this reducer)
 * @param  {string} action - Redux action
 * @param  {string} identifier - Identifier
 * @return {object}
 */
export const requestCursor = (
  state: any,
  action: Action,
  identifier: string,
) => {
  if (
    action.meta &&
    action.meta.cursorOptions &&
    action.meta.cursorOptions.before
  ) {
    return state;
  }
  return {
    ...state,
    [identifier]: {
      ...state[identifier],
      current_cursor:
        action.meta.cursorOptions && action.meta.cursorOptions.before
          ? state[identifier].current_cursor
          : action.meta.cursor,
      status: {
        ...(state[identifier] ? state[identifier].status : {}),
        isFetching: true,
        error: null,
      },
    },
  };
};

/**
 * Returns the state when receiving a cursor-based page
 *
 * @function
 * @param  {object} state - Redux state (only the portion for this reducer)
 * @param  {string} action - Redux action
 * @param  {string} identifier - Identifier
 * @return {object}
 */
export const receiveCursor = (
  state: any,
  action: Action,
  identifier: string,
) => {
  const { result } = action.payload && action.payload.items;
  const { refresh, mount, before } =
    action.meta && action.meta.cursorOptions;

  if (before) {
    return {
      ...state,
      [identifier]: {
        ...state[identifier],
        total: action.payload.total,
        items: union(result, state[identifier].items),
      },
    };
  }
  const items =
    refresh || mount
      ? result
      : mergeWithArray(state[identifier].items, result);

  // We need to run this in order to get only the unique items
  // for the multi entities case (e.g. experiment_post)
  const uniqueItems = uniqWith(items, isEqual);

  return {
    ...state,
    [identifier]: {
      ...state[identifier],
      current_cursor: action.meta.cursor,
      next_cursor: action.payload.next_cursor,
      count: action.payload.count,
      total: action.payload.total,
      status: {
        isFetching: false,
        error: null,
        cached: new Date(),
      },
      items: uniqueItems,
    },
  };
};

/**
 * Returns the state when requesting a cursor-based page failed
 *
 * @function
 * @param  {object} state - Redux state (only the portion for this reducer)
 * @param  {string} action - Redux action
 * @param  {string} identifier - Identifier
 * @return {object}
 */
export const failCursor = (
  state: any,
  action: Action,
  identifier: string,
) => ({
  ...state,
  [identifier]: {
    ...state[identifier],
    status: {
      isFetching: false,
      error: action.error,
    },
  },
});
