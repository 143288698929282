/**
 * Labstep
 *
 * @module components/File/CollapsibleCard
 * @desc Component to show a collapsible file card
 */

import FilePreview from 'labstep-web/components/File/Preview';
import FilePreviewDocument from 'labstep-web/components/File/Preview/Document';
import { ICONS } from 'labstep-web/constants/icons';
import CollapsibleCard from 'labstep-web/core/Collapsible/Card';
import Icon from 'labstep-web/core/Icon';
import { isEqual } from 'lodash';
import React from 'react';
import styles from './styles.module.scss';
import { FileCollapsibleCardProps } from './types';

export class FileCollapsibleCard extends React.Component<FileCollapsibleCardProps> {
  shouldComponentUpdate(prevProps: FileCollapsibleCardProps) {
    return !isEqual(prevProps.file, this.props.file);
  }

  render() {
    const { file, initialExpandedState } = this.props;
    return (
      <CollapsibleCard>
        <CollapsibleCard.Item
          icon={ICONS.file.primary}
          name={file.name}
          actions={
            <FilePreview
              noDiv
              file={file}
              viewComponent={({ toggle }) => (
                <Icon
                  name={ICONS.file.actions.preview}
                  onClick={toggle}
                />
              )}
            />
          }
          content={
            <div className={styles.contentContainer}>
              <FilePreviewDocument
                file={file}
                isModal={false}
                containerId={`pdfObject_${file.id}`}
              />
            </div>
          }
          initialExpandedState={initialExpandedState}
        />
      </CollapsibleCard>
    );
  }
}

export default FileCollapsibleCard;
