/**
 * Labstep
 *
 * @module components/File/Action/Menu
 * @desc Action Menu for file
 */

import FileActionDelete from 'labstep-web/components/File/Action/Delete';
import FileActionDownload from 'labstep-web/components/File/Action/Download';
import FilePreview from 'labstep-web/components/File/Preview';
import { ICONS } from 'labstep-web/constants/icons';
import ActionComponent from 'labstep-web/core/Action/Component';
import ActionsMenu from 'labstep-web/core/Action/Menu';
import React from 'react';
import { Action, FileActionMenuProps } from './types';

const FileActionMenu: React.FC<FileActionMenuProps> = ({
  file,
  actions,
  type,
}) => (
  <ActionsMenu type={type}>
    {actions.includes(Action.download) && (
      <FileActionDownload
        actionComponentProps={{ type: 'option' }}
        file={file}
      />
    )}
    {actions.includes(Action.preview) && (
      <FilePreview
        noDiv
        file={file}
        viewComponent={({ toggle }): React.ReactElement => (
          <ActionComponent
            type="option"
            text="Preview"
            icon={ICONS.file.actions.preview}
            onClick={toggle}
          />
        )}
      />
    )}
    {actions.includes(Action.delete) && (
      <FileActionDelete file={file} />
    )}
  </ActionsMenu>
);

FileActionMenu.defaultProps = {
  actions: [],
  type: 'icon',
};

export default FileActionMenu;
