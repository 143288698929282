/**
 * Labstep
 *
 * @module prosemirror/components/Toolbar/Button
 * @desc Default toolbar button
 */

import cx from 'classnames';
import actionComponentStyles from 'labstep-web/core/Action/Icon/styles.module.scss';
import Icon from 'labstep-web/core/Icon';
import Popup from 'labstep-web/core/Popup';
import React from 'react';
import styles from './styles.module.scss';
import { IToolbarButtonProps } from './types';

const ToolbarButton: React.FC<IToolbarButtonProps> = ({
  onClick,
  disabled,
  icon,
  text,
  textStyle,
  displayColorBar,
  color,
  className,
  popup,
  onMouseDown,
  isActive,
  preventDefault = true,
}) => (
  <Popup
    trigger={
      <div
        data-test-id="toolbar-button"
        className={cx(styles.button, className, {
          [styles.active]: isActive,
        })}
      >
        <div
          className={actionComponentStyles.iconContainer}
          onClick={(evt) => {
            if (preventDefault) {
              evt.preventDefault();
            }
            if (onClick) {
              onClick();
            }
          }}
          onMouseDown={(evt) => {
            evt.preventDefault();
            if (onMouseDown) {
              onMouseDown();
            }
          }}
        >
          {icon && <Icon disabled={disabled} name={icon} link />}
          {text && <span style={textStyle}>{text}</span>}
          {displayColorBar && (
            <div
              className={styles.colorBar}
              style={{ backgroundColor: color }}
            />
          )}
        </div>
      </div>
    }
    content={popup}
    size="mini"
  />
);
export default ToolbarButton;
