/**
 * Labstep
 *
 * @module core/Print
 * @desc Display printable version of an entity
 */

import { withActiveGroup } from 'labstep-web/containers/ActiveGroup';
import { withPrint } from 'labstep-web/containers/Print';
import { PrintContainer } from 'labstep-web/containers/Ui/Print';
import DangerousDiv from 'labstep-web/core/DangerousDiv';
import LoaderLogo from 'labstep-web/core/Loader/Logo';
import Websocket from 'labstep-web/core/Websocket';
import { EntityExport } from 'labstep-web/models/entity-export.model';
import { fileService } from 'labstep-web/services/file.service';
import React, { useEffect, useState } from 'react';
import styles from './styles.module.scss';
import {
  IEntityPrintProps,
  IEntityPrintWebsocketContainerProps,
  IPrintProps,
} from './types';
import { getProgressMessage } from './utils';

export const Print: React.FC<IPrintProps> = (props) => {
  const [html, updateHtml] = React.useState<string | null>(null);

  useEffect(() => {
    async function loadLink() {
      const { link } = props;
      if (link) {
        const fileContentHtml =
          await fileService.getFileContent(link);
        updateHtml(fileContentHtml);
      }
    }
    loadLink();
  }, [props]);

  if (!html) {
    return (
      <div className={styles.loading}>
        <LoaderLogo />
        {getProgressMessage(props.progress)}
      </div>
    );
  }

  return (
    <DangerousDiv
      className={styles.print}
      html={html}
      domPurifyParams={{ WHOLE_DOCUMENT: true }}
    />
  );
};

export const EntityPrint: React.FC<IEntityPrintProps> = ({
  create,
  status,
  activeGroup,
  id,
  entityName,
  progress,
}) => {
  const [entityExportId, setEntityExportId] = useState<number | null>(
    null,
  );

  useEffect(() => {
    if (!status && !entityExportId) {
      create(
        {
          type: EntityExport.TYPE_PRINT,
          group_id: activeGroup?.id,
          query_entity_name: entityName,
          query_parameters: { id },
        },
        {
          onSuccess: ({ response }) => {
            if (!response) {
              return;
            }
            setEntityExportId(response.result as number);
          },
        },
      );
    }
  }, [entityExportId, status]);

  if (!entityExportId) {
    return (
      <div className={styles.loading}>
        <LoaderLogo />
        Requesting print from server... please wait
      </div>
    );
  }

  return (
    <PrintContainer id={entityExportId}>
      {({ link }) => <Print link={link} progress={progress} />}
    </PrintContainer>
  );
};

const EntityPrintWithActiveGroup = withActiveGroup(EntityPrint);

export const EntityPrintWebsocketContainer: React.FC<
  IEntityPrintWebsocketContainerProps
> = (props) => (
  <Websocket>
    <EntityPrintWithActiveGroup {...props} />
  </Websocket>
);

export default withPrint(EntityPrintWebsocketContainer);
