/**
 * Labstep
 *
 * @module prosemirror/components/Menu/Cursor
 * @desc A menu to be placed by the cursor
 */

import { getScrollTop } from 'labstep-web/services/html-element.service';
import React, {
  CSSProperties,
  useEffect,
  useRef,
  useState,
} from 'react';
import styles from './styles.module.scss';
import { IMenuCursorProps } from './types';
import { getMenuPlacement } from './utils';

export const MenuCursor: React.FC<IMenuCursorProps> = ({
  cursorPosition,
  isOpen,
  children,
}) => {
  const menuContainerRef = useRef(null);
  const menuRef = useRef(null);
  const activeItemRef = useRef(null);
  const [menuPlacement, setMenuPlacement] = useState(null);
  const [visibility, setVisibility] = useState('hidden');

  useEffect(() => {
    if (isOpen) {
      if (menuRef.current) {
        // need to get initial menu to calculate placement
        // only show when menuPlacement has been calculated before
        if (menuPlacement) {
          setVisibility('visible');
        }
        setMenuPlacement(
          getMenuPlacement(menuRef.current, cursorPosition),
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, menuRef.current]);

  useEffect(() => {
    if (activeItemRef.current && menuContainerRef.current) {
      const scrollTop = getScrollTop(
        menuContainerRef.current,
        activeItemRef.current,
      );
      if (scrollTop) {
        menuContainerRef.current.scrollTop = scrollTop;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeItemRef.current]);

  if (!isOpen) {
    return null;
  }

  return (
    <div
      className={styles.container}
      style={
        {
          visibility,
          overflowY: 'auto',
          ...menuPlacement,
        } as CSSProperties
      }
      ref={menuContainerRef}
    >
      {children({ menuRef, activeItemRef })}
    </div>
  );
};

export default MenuCursor;
