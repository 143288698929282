/**
 * Labstep
 *
 * @module components/Entity/Action/TransferOwnership/Bulk
 * @desc Bulk TransferOwnership
 */

import ActionComponentBulk from 'labstep-web/core/Action/Component/Bulk';
import React from 'react';
import EntityActionTransferOwnership from '..';
import { EntityActionTransferOwnershipProps } from './types';

export const EntityActionTransferOwnershipBulk: React.FC<
  EntityActionTransferOwnershipProps
> = (props) => {
  const { entities, options = {} } = props;

  const entitiesWithPermission = entities
    ? entities.filter(
        (entity) => entity, // FIXME entity.canTransferOwnership,
      )
    : [];

  if (
    !entities ||
    entities.length === 0 ||
    entitiesWithPermission.length === 0
  ) {
    return null;
  }

  const id = entitiesWithPermission.map((e) => e.id);
  const firstEntity = entitiesWithPermission[0];

  return (
    <EntityActionTransferOwnership
      entity={firstEntity}
      bulkIds={id}
      actionComponentProps={{
        type: 'children',
        children: ({ onClick }) => (
          <ActionComponentBulk
            entitiesWithPermissionCount={
              entitiesWithPermission.length
            }
            entitiesSelectedCount={entities.length}
            content="Transfer Ownership"
            icon="exchange"
            onClick={onClick}
          />
        ),
      }}
      options={options}
    />
  );
};

export default EntityActionTransferOwnershipBulk;
