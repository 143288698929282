/**
 * Labstep
 *
 * @module prosemirror/nodes/experiment-workflow-link
 * @desc ExperimentWorkflowLink node
 */

import { getAttrsForId } from 'labstep-web/prosemirror/nodes/utils';
import { Node as ProseMirrorNode } from 'prosemirror-model';
import { LabstepNode } from '../types';

const key = 'experiment_workflow_link';

const NodeExperimentWorkflowLink: LabstepNode = {
  key,
  spec: {
    attrs: {
      guid: { default: null },
    },
    inline: true,
    group: 'inline',
    marks: '',
    // atom: true,
    draggable: true,
    parseDOM: [{ tag: 'span', getAttrs: getAttrsForId(key) }],
    toDOM(node: ProseMirrorNode) {
      const { guid } = node.attrs;
      return ['span', { [`data-${key}-guid`]: guid }];
    },
  },
};

export default NodeExperimentWorkflowLink;
