/**
 * Labstep
 *
 * @module components/Layout/Menu/Content
 * @desc Left side bar. Shows navigation options. Shown only on mobile.
 */

import Container from 'labstep-web/components/Layout/Container';
import OrganizationContext from 'labstep-web/components/Layout/Menu/TopBar/Breadcrumb/Context/Organization';
import { withAuthenticatedUserEntity } from 'labstep-web/containers/AuthenticatedUser';
import ActionComponent from 'labstep-web/core/Action/Component';
import { HorizontalDivider } from 'labstep-web/core/Divider';
import Link from 'labstep-web/core/Link';
import Logo from 'labstep-web/core/Logo';
import React from 'react';
import UserGroups from './UserGroups';
import styles from './styles.module.scss';
import { LayoutMenuLeftContentProps } from './types';

export const LayoutMenuLeftContent: React.FC<
  LayoutMenuLeftContentProps
> = ({ authenticatedUserEntity }) => (
  <div className={styles.container}>
    <div className={styles.logoContainer}>
      <Logo blue />
    </div>
    {authenticatedUserEntity.userOrganization && (
      <div className={styles.logoContainer}>
        <OrganizationContext
          organization={
            authenticatedUserEntity.userOrganization.organization
          }
        />
      </div>
    )}
    <HorizontalDivider />
    <UserGroups />
    <Container>
      <HorizontalDivider />
    </Container>
    <Link to="group_index">
      <ActionComponent
        type="text"
        text="View All Workspaces"
        elementProps={{ underline: true }}
      />
    </Link>
  </div>
);

export default withAuthenticatedUserEntity(LayoutMenuLeftContent);
