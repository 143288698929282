/**
 * Labstep
 *
 * @module state/utils
 * @description This is to be the file where all utils for state (redux) will be held
 *
 * TODO Refactor
 */

import sortBy from 'lodash/sortBy';

export const orderObjectByKey = (object: Record<string, unknown>) =>
  sortBy(Object.keys(object)).reduce(
    (result, key) => ({ ...result, [key]: object[key] }),
    {},
  );

export const stringifyParamsInOrder = (
  object: Record<string, unknown>,
) => JSON.stringify(orderObjectByKey(object));
