/**
 * Labstep
 *
 * @module components/ResourceLocation/Pane/List
 * @desc List for Resource Location pane
 */

import ResourceLocationActionCreateSub from 'labstep-web/components/ResourceLocation/Action/CreateSub';
import ResourceLocationActionMenu from 'labstep-web/components/ResourceLocation/Action/Menu';
import { Action } from 'labstep-web/components/ResourceLocation/Action/Menu/types';
import { ICONS } from 'labstep-web/constants/icons';
import Tree from 'labstep-web/core/Tree';
import { ResourceLocation } from 'labstep-web/models/resource-location.model';
import { PostFilterResourceLocation } from 'labstep-web/services/postFilter/filters/resourceLocation';
import React from 'react';
import { IResourceLocationPaneListProps } from './types';

export const ResourceLocationPaneList: React.FC<
  IResourceLocationPaneListProps
> = ({ action, searchBarProps, usePostFilter }) => (
  <Tree
    useGuid
    entityName={ResourceLocation.entityName}
    childKeyName="outer_location"
    icon={ICONS.resource_location.primary}
    createSubAction={(entity) => (
      <ResourceLocationActionCreateSub
        actionComponentProps={{ type: 'text' }}
        resourceLocation={entity as ResourceLocation}
      />
    )}
    actionMenu={(entity, options) => (
      <ResourceLocationActionMenu
        resourceLocation={entity as ResourceLocation}
        options={options}
        actions={[
          Action.view,
          Action.edit,
          Action.update_outer,
          Action.delete,
          Action.create_sub,
        ]}
      />
    )}
    action={action}
    searchBarProps={searchBarProps}
    postFilter={
      usePostFilter ? PostFilterResourceLocation : undefined
    }
  />
);

export default ResourceLocationPaneList;
