/**
 * Labstep
 *
 * @module components/ExperimentWorkflow/Action/Print
 * @desc Action to print an experiment workflow
 */

import PrintAction from 'labstep-web/components/Entity/Action/Print';
import React from 'react';
import { PurchaseOrderActionPrintProps } from './types';

export const PurchaseOrderActionPrint: React.FC<
  PurchaseOrderActionPrintProps
> = ({ purchaseOrder }) => <PrintAction entity={purchaseOrder} />;

export default PurchaseOrderActionPrint;
