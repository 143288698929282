/**
 * Labstep
 *
 * @module components/ExperimentWorkflow/Action/AddProtocol
 * @desc Add protocol to an experiment
 */

import classnames from 'classnames';
import Can from 'labstep-web/components/Entity/Can';
import { Action } from 'labstep-web/components/Entity/Can/types';
import { fieldProtocolName } from 'labstep-web/components/Protocol/Form/fields';
import ProtocolCollectionSearchSelect from 'labstep-web/components/ProtocolCollection/SearchSelect';
import { EntityCreateContainer } from 'labstep-web/containers/Entity/Create';
import { ToggleContainer } from 'labstep-web/containers/Toggle';
import ActionComponent from 'labstep-web/core/Action/Component';
import Divider from 'labstep-web/core/Divider';
import Dropdown from 'labstep-web/core/Dropdown';
import ReusableForm from 'labstep-web/core/Form/Reusable';
import Modal from 'labstep-web/core/Modal/Default';
import { APICallOptions } from 'labstep-web/models';
import { ProtocolCollection } from 'labstep-web/models/protocol-collection.model';
import { navigation } from 'labstep-web/services/navigation';
import React from 'react';
import { withRouter } from 'react-router';
import styles from './styles.module.scss';
import {
  ExperimentWorkflowActionAddProtocolContainerProps,
  ExperimentWorkflowActionAddProtocolProps,
} from './types';

export const ExperimentWorkflowActionAddProtocol: React.FC<
  ExperimentWorkflowActionAddProtocolProps
> = ({ experimentWorkflow, create, history }) => (
  <ToggleContainer>
    {({ toggle, toggled }) => (
      <div
        className={classnames('toggle-add', {
          [styles.toggled]: toggled,
        })}
      >
        {!toggled ? (
          <Dropdown
            pointing="top left"
            icon={null}
            trigger={
              <ActionComponent type="button" text="Add Protocol" />
            }
          >
            <Dropdown.Item
              onClick={() => {
                create(
                  {},
                  {
                    onSuccess: (({ response }) => {
                      history.push(
                        navigation.get('experiment_show', {
                          id: response!.result,
                        }),
                      );
                    }) as APICallOptions['onSuccess'],
                  },
                );
              }}
            >
              New Protocol
            </Dropdown.Item>
            <Dropdown.Item onClick={toggle}>
              From Protocol Library
            </Dropdown.Item>
          </Dropdown>
        ) : (
          <ProtocolCollectionSearchSelect
            labelKey="label"
            openMenuOnFocus
            autoFocus
            onBlur={toggle}
            onChange={(option: ProtocolCollection) => {
              toggle();
              create({
                protocol_id: option.id,
                experiment_workflow_id: experimentWorkflow.id,
              });
            }}
            value={null}
            params={{}}
          />
        )}
      </div>
    )}
  </ToggleContainer>
);

export const ExperimentWorkflowActionAddProtocolModal: React.FC<
  ExperimentWorkflowActionAddProtocolProps
> = ({
  experimentWorkflow,
  viewComponent,
  create,
  status,
  onSuccess,
  modalProps,
}) => (
  <Modal
    header="Add Protocol"
    viewComponent={
      viewComponent || (
        <ActionComponent type="button" text="Add Protocol" />
      )
    }
    content={({ toggleModal }) => (
      <>
        <ProtocolCollectionSearchSelect
          labelKey="label"
          autoFocus
          onChange={(option: ProtocolCollection) => {
            create(
              {
                protocol_id: option.id,
                experiment_workflow_id: experimentWorkflow.id,
              },
              {
                onSuccess: (({ response }) => {
                  onSuccess?.({ response });
                  toggleModal();
                }) as APICallOptions['onSuccess'],
              },
            );
          }}
          value={null}
          params={{}}
        />
        <Divider horizontal>OR</Divider>
        <ReusableForm
          onSubmit={(options) => {
            create(
              {
                name: options.name,
              },
              {
                onSuccess: (({ response }) => {
                  onSuccess?.({ response });
                  toggleModal();
                }) as APICallOptions['onSuccess'],
              },
            );
          }}
          submitButtonText="New Protocol"
          fields={[fieldProtocolName]}
          status={status}
        />
      </>
    )}
    {...modalProps}
  />
);

export const ExperimentWorkflowActionAddProtocolContainer: React.FC<
  ExperimentWorkflowActionAddProtocolContainerProps
> = ({ experimentWorkflow, isModal, ...rest }) => (
  <Can
    entityName={experimentWorkflow.entityName}
    id={experimentWorkflow.id}
    action={Action.experiment_create}
  >
    <EntityCreateContainer
      entityName="experiment"
      parentName="experiment_workflow"
      parentId={experimentWorkflow.id}
      showLoaderWhileCreating
    >
      {({ create, status }) =>
        isModal ? (
          <ExperimentWorkflowActionAddProtocolModal
            experimentWorkflow={experimentWorkflow}
            create={create}
            status={status}
            {...rest}
          />
        ) : (
          <ExperimentWorkflowActionAddProtocol
            experimentWorkflow={experimentWorkflow}
            create={create}
            status={status}
            {...rest}
          />
        )
      }
    </EntityCreateContainer>
  </Can>
);

export default withRouter(
  ExperimentWorkflowActionAddProtocolContainer,
);
