/**
 * Labstep
 *
 * @module components/Filter/Tag
 * @desc Tag filter
 */

import ActionComponent from 'labstep-web/core/Action/Component';
import Modal from 'labstep-web/core/Modal/Default';
import ReactSelect from 'labstep-web/core/Select';
import { ReactSelectOption } from 'labstep-web/core/Select/types';
import {
  OrderRequest,
  OrderRequestStatusValues,
} from 'labstep-web/models/order-request.model';
import { ResourceItemStatusValues } from 'labstep-web/models/resource-item.model';
import { PostFilterStatus } from 'labstep-web/services/postFilter/filters/status';
import React from 'react';
import { ResourceItemStatusFilterProps } from './types';

const ResourceItemStatusFilter: React.FC<
  ResourceItemStatusFilterProps
> = ({
  setParams,
  searchParams,
  isOrderRequest,
  addPostFilter,
  actionComponentProps,
}) => {
  const statuses = isOrderRequest
    ? OrderRequestStatusValues
    : ResourceItemStatusValues;

  const placeholder = isOrderRequest
    ? `Select ${OrderRequest.getHumanReadableEntityName()} status`
    : `Select availability status`;

  return (
    <Modal
      header={isOrderRequest ? 'Status' : 'Availability'}
      content={({ toggleModal }) => (
        <ReactSelect
          defaultValue={
            searchParams.status
              ? {
                  value: searchParams.status,
                  label:
                    statuses[
                      searchParams.status as keyof typeof statuses
                    ],
                }
              : null
          }
          options={Object.keys(statuses).map((key) => ({
            value: key,
            label: statuses[key as keyof typeof statuses],
          }))}
          isClearable
          onChange={(selectedOption: ReactSelectOption) => {
            const value = selectedOption
              ? selectedOption.value
              : undefined;
            if (addPostFilter) {
              addPostFilter(PostFilterStatus.getNode([value]));
            } else if (setParams) {
              setParams({
                status: value,
              });
            }
            toggleModal();
          }}
          placeholder={placeholder}
        />
      )}
      viewComponent={({ toggleModal }) => (
        <ActionComponent
          type="option"
          icon={isOrderRequest ? 'info' : 'check'}
          text={isOrderRequest ? 'Status' : 'Availability'}
          onClick={toggleModal}
          {...actionComponentProps}
        />
      )}
    />
  );
};

export default ResourceItemStatusFilter;
