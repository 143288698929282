/**
 * Labstep
 *
 * @module components/EntityView/Table
 * @desc EntityView Table
 */

import EntityTable from 'labstep-web/components/Entity/Table';
import React from 'react';
import EntityViewActionMenu from '../Action/Menu';
import { EntityViewTableProps } from './types';

const EntityViewTable: React.FC<EntityViewTableProps> = ({
  entityViews,
  actions,
  group,
}) => (
  <EntityTable
    id="entity-view-table"
    entities={entityViews}
    editableName
    actionMenu={({ entity }) => (
      <EntityViewActionMenu entityView={entity} actions={actions} />
    )}
    linkParams={(entity) => ({
      id: group.id,
      entityViewId: entity.id,
    })}
  />
);

export default EntityViewTable;
