/**
 * Labstep
 *
 * @module components/Comment/Action/Comment
 * @desc FIXME
 */

import EntityActionEdit from 'labstep-web/components/Entity/Action/Edit';
import { ICONS } from 'labstep-web/constants/icons';
import React from 'react';
import { CommentActionCommentProps } from './types';

const CommentActionComment: React.FC<CommentActionCommentProps> = ({
  comment,
  onClick,
}) => (
  <EntityActionEdit
    entity={comment}
    actionComponentProps={{
      type: 'option',
      onClick,
      text: 'Comment',
      icon: ICONS.thread.primary,
    }}
  />
);

export default CommentActionComment;
