/**
 * Labstep
 *
 * @module core/Pre
 * @desc Custom pre tag component
 * White space is set to pre
 */

import React from 'react';
import styles from './styles.module.scss';
import { IPreProps } from './types';

const Pre: React.FC<IPreProps> = ({ children }) => (
  <div className={styles.container}>{children}</div>
);

export default Pre;
