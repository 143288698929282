/**
 * Labstep
 *
 * @module components/Experiment/Action/Delete
 * @desc Action to delete experiment
 */

import EntityActionDelete from 'labstep-web/components/Entity/Action/Delete';
import React from 'react';
import { ExperimentActionDeleteProps } from './types';

export const ExperimentActionDelete: React.FC<
  ExperimentActionDeleteProps
> = ({ experiment }) => (
  <EntityActionDelete
    actionComponentProps={{
      type: 'option',
      icon: 'times',
      text: 'Remove',
    }}
    header="Remove protocol"
    confirmButtonContent="Remove"
    confirmMessage="Are you sure you want to remove this protocol?"
    entity={experiment}
  />
);

export default ExperimentActionDelete;
