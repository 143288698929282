/**
 * Labstep
 *
 * @module Layout/Topbar/Help
 * @desc Help dropdown
 */

import { intercomService } from 'labstep-web/services/intercom.service';
import ActionComponent from '../Action/Component';

export const Help = () => (
  <ActionComponent
    type="icon"
    icon="question"
    elementProps={{
      color: 'blue',
      popup: { content: 'Help' },
    }}
    onClick={intercomService.open}
  />
);

export default Help;
