/**
 * Labstep
 *
 * @module components/Layout/Menu/TopBar/Breadcrumb/Title
 * @desc Custom page title that can be added to the breadcrumb
 */

import Header from 'labstep-web/core/Header';
import Icon from 'labstep-web/core/Icon';
import React from 'react';
import { LayoutMenuTopBarBreadcrumbTitleProps } from './types';

export const LayoutMenuTopbarBreadcrumbTitle: React.FC<
  LayoutMenuTopBarBreadcrumbTitleProps
> = ({ title }) => (
  <Header size="tiny" noMargin>
    <Icon name={title.icon} />
    {title.text}
  </Header>
);

export default LayoutMenuTopbarBreadcrumbTitle;
