/**
 * Labstep
 */

import { useContext } from 'react';
import { ParamsStateContext } from '.';
import { ParamsStateContextType } from './types';

export const useParamsStateContext = ():
  | ParamsStateContextType
  | undefined => {
  const context = useContext(ParamsStateContext);
  return context;
};
