/**
 * Labstep
 *
 * @module components/Comment/Thread/Detail
 * @desc Comment Thread Detail
 */

import CommentItem from 'labstep-web/components/Comment/Item';
import ThreadModal from 'labstep-web/components/Thread/Modal';
import { pluralize } from 'labstep-web/services/i18n.service';
import React from 'react';
import { ICommentThreadDetailProps } from './types';

const CommentThreadDetail: React.FC<ICommentThreadDetailProps> = ({
  comment,
}) =>
  comment.thread ? (
    <ThreadModal
      threadId={comment.thread.id}
      top={<CommentItem readOnly comment={comment} />}
      dividerChildren={`${comment.thread.comment_count} ${
        comment.thread.comment_count > 1
          ? pluralize('comment')
          : 'comment'
      }`}
      noCommentsMessage="No comments"
    />
  ) : null;

export default CommentThreadDetail;
