/**
 * Labstep
 *
 * @module components/Signature/List
 * @desc Signature list
 */

import EntityLink from 'labstep-web/components/Entity/Link';
import Button from 'labstep-web/core/Button';
import Flex from 'labstep-web/core/Flex';
import { ExperimentWorkflow } from 'labstep-web/models/experiment-workflow.model';
import React from 'react';
import SignatureEmptyState from '../EmptyState';
import SignatureItem from '../Item';
import { SignatureListTimeline } from './Timeline';
import styles from './styles.module.scss';
import { ISignatureListProps } from './types';

const SignatureList: React.FC<ISignatureListProps> = ({
  experimentWorkflow,
}) => {
  if (!experimentWorkflow.entity_state_workflow) {
    return (
      <div className={styles.list} data-testid="signature-list">
        {experimentWorkflow.signatures.map((signature) => (
          <SignatureItem
            key={signature.id}
            signature={signature}
            experimentWorkflow={experimentWorkflow}
          />
        ))}
      </div>
    );
  }

  const showEmptyState =
    experimentWorkflow.getNumberOfSignaturesRequiredLeft(
      experimentWorkflow.entity_state,
    ) === 0 && experimentWorkflow.signatures.length === 0;

  return (
    <Flex column data-testid="workflow-signature-list">
      {showEmptyState ? (
        <SignatureEmptyState
          explanationText={`The workflow for this ${ExperimentWorkflow.getHumanReadableEntityName()} does not require any signatures at this stage. `}
          action={
            <EntityLink
              entity={experimentWorkflow.entity_state_workflow}
            >
              <Button>Go to Workflow</Button>
            </EntityLink>
          }
        />
      ) : (
        <SignatureListTimeline
          experimentWorkflow={experimentWorkflow}
        />
      )}
    </Flex>
  );
};

export default SignatureList;
