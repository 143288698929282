/**
 * Labstep
 */

import { ColDef } from 'labstep-web/core/DataGrid/types';
import colDefEntityUser from 'labstep-web/grid/Index/coldefs/Entity/entityUser';
import colDefAvailableItems from 'labstep-web/grid/IndexResource/coldefs/availableItems';
import { getResourceMetadataColDefs } from 'labstep-web/grid/IndexResource/coldefs/metadata';
import { colDefResourceNameNoLink } from 'labstep-web/grid/IndexResource/coldefs/name/noLink';
import { colDefResourceTemplate } from 'labstep-web/grid/IndexResource/coldefs/template';
import { Resource } from 'labstep-web/models/resource.model';

export default (
  resourceTemplate?: Resource | null,
): ColDef<Resource>[] => {
  const colDefs = resourceTemplate
    ? [
        colDefResourceNameNoLink,
        colDefAvailableItems,
        ...getResourceMetadataColDefs(resourceTemplate),
        colDefEntityUser,
      ]
    : [
        colDefResourceNameNoLink,
        colDefResourceTemplate,
        colDefAvailableItems,
        colDefEntityUser,
      ];
  return colDefs;
};
