/**
 * Labstep
 *
 * @module core/Card
 * @desc Card
 */

import cx from 'classnames';
import Header from 'labstep-web/core/Header';
import React from 'react';
import styles from './styles.module.scss';
import { ICardProps } from './types';

/**
 * A simple card component. It will render the chldren inside a white
 * background with slightly round border edges
 */
const Card: React.FC<ICardProps> = ({
  children,
  className,
  header,
  size = 'small',
  ...rest
}) => {
  const classes = cx(styles.card, className);

  return (
    <div {...rest} className={classes}>
      {header && <Header size={size}>{header}</Header>}
      {children}
    </div>
  );
};

export default Card;
