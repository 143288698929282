/**
 * Labstep
 *
 * @module components/Group/EmptyState
 * @desc Empty state for group
 */

import Button from 'labstep-web/core/Button';
import EmptyState from 'labstep-web/core/Card/EmptyState';
import { Group } from 'labstep-web/models/group.model';
import React from 'react';
import { GroupEmptyStateProps } from './types';

export const GroupEmptyState: React.FC<GroupEmptyStateProps> = ({
  isActionAllowed = true,
}) => (
  <EmptyState
    src="/img/empty-state/workspaces.svg"
    explanation="Workspaces are the collaborative spaces where work happens on Labstep. Collectively manage the shared experiments, protocols and inventory within each workspace and control the permissions of the different members."
    action={
      isActionAllowed && (
        <Button primary route={{ to: 'group_create' }}>
          New {Group.getHumanReadableEntityName(false, true)}
        </Button>
      )
    }
  />
);

export default GroupEmptyState;
