/**
 * Labstep
 *
 * @module components/Layout/Menu/Content/UserGroups/Collapsible
 * @desc Left side bar user groups collapsible item
 */

import GroupItem from 'labstep-web/components/Group/Item';
import { Links } from 'labstep-web/components/Layout/FullScreen/Links';
import Collapsible from 'labstep-web/core/Collapsible';
import React from 'react';
import { LayoutMenuLeftContentUserGroupsCollapsibleProps } from './types';

export const LayoutMenuLeftContentUserGroupsCollapsible: React.FC<
  LayoutMenuLeftContentUserGroupsCollapsibleProps
> = ({ group, initialExpandedState }) => (
  <Collapsible
    label={<GroupItem group={group} />}
    component={<Links group={group} showOverview />}
    initialExpandedState={initialExpandedState}
  />
);

export default LayoutMenuLeftContentUserGroupsCollapsible;
