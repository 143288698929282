/**
 * Labstep
 *
 * @module state/epics/chemistry
 * @desc Stoichiometry table calculations
 */

import { Action } from 'labstep-web/models/action.model';
import { StateObservable } from 'redux-observable';
import { Observable, concat, of } from 'rxjs';
import { catchError, filter, map } from 'rxjs/operators';
import {
  calcStoichiometryTable,
  isChemicalUpdateAction,
  isMoleculeLimitingChemicalUpdateAction,
  isProtocolValueWithChemicalUpdateAction,
} from './utils';

/**
 * Update stoichiometry table on success update
 *
 * @function
 * @param  {Observable<Action>} action$
 * @param  {StateObservable<LabstepReduxState>} state$
 * @return {Observable<Action>}
 */
export const recalcStoichiometryTable = (
  action$: Observable<Action>,
  state$: StateObservable<any>,
): Observable<Action> =>
  action$.pipe(
    filter(
      (action: Action) =>
        isMoleculeLimitingChemicalUpdateAction(action) ||
        isChemicalUpdateAction(action) ||
        isProtocolValueWithChemicalUpdateAction(state$, action),
    ),
    map((action: Action) => {
      const payload = calcStoichiometryTable(state$, action);
      return {
        type: 'RECALC_STOICHIOMETRY_TABLE',
        payload,
      };
    }),
    catchError((err, source$: Observable<Action>) =>
      concat(
        of({
          type: 'EPIC_FAIL_RECALC_STOICHIOMETRY_TABLE',
          payload: err,
        }),
        source$,
      ),
    ),
  );
