/**
 * Labstep
 */

import { createBatch } from 'labstep-web/core/DataGrid/utils';
import Icon from 'labstep-web/core/Icon';
import { AmountUpdate } from 'labstep-web/models/amount-update.model';
import { ProtocolValue } from 'labstep-web/models/protocol-value.model';
import {
  canUpdateAmount,
  checkUnitBase,
  getAmounts,
  isAmountUpdated,
} from 'labstep-web/services/amount-unit.service';
import React from 'react';
import { StrictIconProps } from 'semantic-ui-react/dist/commonjs/elements/Icon';
import styles from './styles.module.scss';
import { ResourceItemHeaderProps } from './types';

export const evaluateProtocolValue = (
  protocolValue: ProtocolValue,
) => {
  const errorMessages = {
    noItem: 'Please select item',
    noAmount: 'Amount used is not specified',
    noItemAmount: 'Item Amount Remaining Unspecified',
    noItemUnit: 'Item Unit Unspecified',
    noUnit: 'Amount used Unit Unspecified',
    mismatchingUnits:
      'Mismatching units between the item and the inventory field',
    insufficientAmount: 'Insufficient Amount Remaining',
    updatedAmount: 'Amount used deducted from item',
    readyToUpdate: 'Click to update amount remaining',
  };

  let canUpdate = false;
  let content = '';
  let iconName: StrictIconProps['name'] = 'warning sign';
  let iconColor: StrictIconProps['color'] = 'orange';

  if (!protocolValue.resourceItem) {
    content = errorMessages.noItem;
  } else if (!protocolValue.amount) {
    content = errorMessages.noAmount;
  } else if (!protocolValue.resourceItem.amount) {
    content = errorMessages.noItemAmount;
  } else if (!protocolValue.resourceItem.unit) {
    content = errorMessages.noItemUnit;
  } else if (!protocolValue.unit) {
    content = errorMessages.noUnit;
  } else if (
    !checkUnitBase(
      protocolValue.unit || '',
      protocolValue.resourceItem.unit || '',
    )
  ) {
    content = errorMessages.mismatchingUnits;
  } else if (isAmountUpdated(protocolValue)) {
    iconName = 'check circle';
    iconColor = 'green';
    content = errorMessages.updatedAmount;
  } else if (!canUpdateAmount(protocolValue)) {
    content = errorMessages.insufficientAmount;
  } else {
    canUpdate = true;
    iconName = 'check circle';
    iconColor = 'yellow';
    content = errorMessages.readyToUpdate;
  }

  return {
    canUpdate,
    content,
    iconName,
    iconColor,
  };
};

export const getValidProtocolValues = (
  protocolValues: ProtocolValue[],
) => {
  const values = protocolValues
    .map((value) => {
      const { amountTobeUpdated, resourceItemAmount } =
        getAmounts(value);
      if (amountTobeUpdated !== null && resourceItemAmount !== null) {
        return {
          protcolValueId: value.guid,
          resourceItemId: value.resourceItem!.guid,
          amountTobeUpdated,
          resourceItemAmount,
        };
      }
    })
    .filter((value) => value !== undefined);

  const resourceItemAmounts = values.reduce((map, item) => {
    if (!map.has(item.resourceItemId)) {
      map.set(item.resourceItemId, item.resourceItemAmount);
    }
    return map;
  }, new Map<string, number>());
  // Filter the values based on the remaining amounts in resourceItem
  const result = values.filter((item) => {
    const remainingAmount =
      resourceItemAmounts.get(item.resourceItemId) || 0;

    if (item.amountTobeUpdated <= remainingAmount) {
      // Deduct the item's amount from the remaining resourceItem amount
      resourceItemAmounts.set(
        item.resourceItemId,
        remainingAmount - item.amountTobeUpdated,
      );
      return true; // Keep the item
    }

    // Exclude the item if there's not enough remaining resourceItem amount
    return false;
  });

  return result;
};

export const getProtocolValues = (props: ResourceItemHeaderProps) => {
  const { getNestedEntity, api } = props;
  const protocolValues: ProtocolValue[] = [];
  api.forEachNode((node) => {
    const entity = getNestedEntity
      ? getNestedEntity({
          ...props,
          data: node.data,
        })
      : undefined;
    if (entity) {
      if (entity.amount === null && entity.resourceItem === null) {
        return;
      }
      protocolValues.push(entity);
    }
  });
  return protocolValues;
};

export const HeaderComponent: React.FC<ResourceItemHeaderProps> = (
  props,
) => {
  const { displayName, icon, isInput, refresh } = props;

  const protocolValues = getProtocolValues(props);

  const amountsNotUpdated = protocolValues.some(
    (p) => !isAmountUpdated(p),
  );

  const circleIcon = (
    <Icon
      color={amountsNotUpdated ? 'yellow' : 'green'}
      name="check circle"
      data-testid="conditions_table_item_input"
      popup={{
        content: amountsNotUpdated
          ? 'Update all amounts'
          : 'All Amounts Updated',
        size: 'small',
      }}
      onClick={
        amountsNotUpdated
          ? () => {
              const filteredProtocolValues: ProtocolValue[] = [];
              protocolValues.forEach((protocolValue) => {
                const { canUpdate } =
                  evaluateProtocolValue(protocolValue);
                if (canUpdate) {
                  filteredProtocolValues.push(protocolValue);
                }
              });
              const validProtocolValues = getValidProtocolValues(
                filteredProtocolValues,
              );

              const items = validProtocolValues.map((value) => ({
                protocol_value_id: value.protcolValueId,
              }));

              if (items.length > 0) {
                createBatch({
                  entityName: AmountUpdate.entityName,
                  body: items,
                  options: {
                    onSuccess: () => {
                      refresh();
                    },
                  },
                });
              } else {
                refresh();
              }
            }
          : undefined
      }
    />
  );

  return (
    <span className={styles.columnHeader}>
      <span>
        <Icon name={icon} /> {displayName}
      </span>
      {isInput && protocolValues.length > 0 && circleIcon}
    </span>
  );
};
