/**
 * Labstep
 *
 * @module constants/time-machine
 * @desc Constants for the time machine feature
 *
 */

import { Comment } from 'labstep-web/models/comment.model';
import { Experiment } from 'labstep-web/models/experiment.model';
import { ProtocolTable } from 'labstep-web/models/protocol-table.model';

/**
 * List of nested entities that can be selected in the time machine
 */
export const TIME_MACHINE_ENTITIES = [
  Experiment.entityName,
  ProtocolTable.entityName,
  Comment.entityName,
];
