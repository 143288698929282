/**
 * Labstep
 *
 * @module components/File/ListOrTable
 * @desc List or table of files
 */

import FileList from 'labstep-web/components/File/List';
import FileTable from 'labstep-web/components/File/Table';
import MasterIndexCenterListOrTable from 'labstep-web/screens/Master/Index/Center/ListOrTable';
import React from 'react';
import { actions } from './constants';
import { FileListOrTableProps } from './types';

export const FileListOrTable: React.FC<FileListOrTableProps> = ({
  files,
  ...rest
}) => (
  <MasterIndexCenterListOrTable
    tableComponent={
      <FileTable files={files} actions={actions} {...rest} />
    }
    listComponent={<FileList files={files} actions={actions} />}
  />
);

export default FileListOrTable;
