/**
 * Labstep
 */

import { ICheckboxProps } from 'labstep-web/core/Checkbox/types';
import { IDateTimePickerProps } from 'labstep-web/core/DateTimePicker/types';
import { IInputProps } from 'labstep-web/core/Input/types';
import { ISelectMultiProps } from 'labstep-web/core/Select/Multi/types';
import { SearchSelectContainerProps } from 'labstep-web/core/Select/Search/types';
import { APICallStatus } from 'labstep-web/models';
import React from 'react';
import { UseFormReturn } from 'react-hook-form';
import { Props as SelectProps } from 'react-select';
import { TextareaAutosizeProps } from 'react-textarea-autosize';
import { AnySchema } from 'yup';
import { ISelectOptionsDropdownBaseProps } from '../ReactForm/SelectOptions/types';
import { IFormReusableActionsProps } from './Actions/types';

// eslint-disable-next-line no-shadow
export enum FieldType {
  Text = 'Text',
  ProseMirrorHtml = 'ProseMirrorHtml',
  ReactSelect = 'ReactSelect',
  File = 'File',
  DateTimePicker = 'DateTimePicker',
  TextArea = 'TextArea',
  SearchSelect = 'SearchSelect',
  SelectMulti = 'SelectMulti',
  SelectOptions = 'SelectOptions',
  Checkbox = 'Checkbox',
  action = 'action',
}

export type IValues = Record<string, unknown>;

export type preSubmitType = (values: IValues) => IValues;

export interface IReusableFormProps {
  fields: IFieldProps[];
  status?: APICallStatus;
  onCancel?: () => any;
  defaultValues?: IValues;
  onSubmit: (
    body: Record<string, unknown>,
    reset: UseFormReturn<any>['reset'],
  ) => void;
  submitButton?: IFormReusableActionsProps['submitButton'];
  submitButtonText?: string;
  error?: string | React.ReactElement;
  cancelButton?: any;
  onFormChange?: (...args: any) => any;
  /* Used to manipulate values before submit */
  preSubmit?: preSubmitType;
  /* If true passes autoFocus to first field */
  autoFocus?: boolean;
  color?: 'red';
  /* If true, form will be layout horizontally (inline) */
  inline?: boolean;
  /* Submits form on blur or calls toggle if validation fails */
  blurToggle?: VoidFunction;
  formRef?: React.RefObject<{ submit: VoidFunction }>;
}

export type IFieldBaseProps = {
  name: string;
  validation: AnySchema;
  placeholder?: string;
  /** If set component shows a label on top of input */
  fieldLabel?: string;
  /** If set component shows a tooltip for a more thorough explanation */
  fieldExplainer?: string;
  /** If true renders with header font size */
  header?: boolean;
  /** If true skips transformation in preSubmit */
  skipTransform?: boolean;
  /** If true the field will be disabled. Useful when you want to show information but don't want user to change the value */
  disabled?: boolean;
  /** If true the field will be autofocused */
  autoFocus?: boolean;
};

export type FieldTypeText = {
  fieldType: FieldType.Text;
  elementProps?: IInputProps & { withSymbols?: boolean };
};

export type FieldTypeProseMirrorHtml = {
  fieldType: FieldType.ProseMirrorHtml;
  // eslint-disable-next-line @typescript-eslint/ban-types
  elementProps?: {};
};

export type FieldTypeReactSelect = {
  fieldType: FieldType.ReactSelect;
  elementProps: SelectProps & { creatable?: boolean };
};

export type FieldTypeFile = {
  fieldType: FieldType.File;
  elementProps: {
    multiple?: boolean;
  };
};

export type FieldTypeDateTimePicker = {
  fieldType: FieldType.DateTimePicker;
  elementProps?: IDateTimePickerProps['options'];
};

export type FieldTypeTextArea = {
  fieldType: FieldType.TextArea;
  elementProps?: TextareaAutosizeProps & { withSymbols?: boolean };
};

export type FieldTypeSearchSelect = {
  fieldType: FieldType.SearchSelect;
  elementProps: SearchSelectContainerProps;
};

export type FieldTypeSelectMulti = {
  fieldType: FieldType.SelectMulti;
  elementProps?: Omit<ISelectMultiProps, 'onChange'>;
};

export type FieldTypeSelectSelectOptions = {
  fieldType: FieldType.SelectOptions;
  elementProps?: ISelectOptionsDropdownBaseProps;
};

export type FieldTypeCheckbox = {
  fieldType: FieldType.Checkbox;
  elementProps?: ICheckboxProps;
};

export type FieldTypeAction = {
  fieldType: FieldType.action;
  component: ({
    values,
    reset,
  }: {
    values: Record<string, unknown>;
    reset: UseFormReturn<any>['reset'];
  }) => React.ReactElement;
};

export type IFieldTypeTextProps = IFieldBaseProps & FieldTypeText;
export type IFieldTypeProseMirrorHtmlProps = IFieldBaseProps &
  FieldTypeProseMirrorHtml;
export type IFieldTypeReactSelectProps = IFieldBaseProps &
  FieldTypeReactSelect;
export type IFieldTypeFileProps = IFieldBaseProps & FieldTypeFile;
export type IFieldTypeDateTimePickerProps = IFieldBaseProps &
  FieldTypeDateTimePicker;
export type IFieldTypeTextAreaProps = IFieldBaseProps &
  FieldTypeTextArea;
export type IFieldTypeSearchSelectProps = IFieldBaseProps &
  FieldTypeSearchSelect;
export type IFieldTypeSelectMultiProps = IFieldBaseProps &
  FieldTypeSelectMulti;
export type IFieldTypeSelectSelectOptionsProps = IFieldBaseProps &
  FieldTypeSelectSelectOptions;
export type IFieldTypeCheckboxProps = IFieldBaseProps &
  FieldTypeCheckbox;
export type IFieldTypeActionProps = FieldTypeAction;

export type FieldWithoutActionProps = IFieldBaseProps &
  (
    | FieldTypeText
    | FieldTypeProseMirrorHtml
    | FieldTypeReactSelect
    | FieldTypeFile
    | FieldTypeDateTimePicker
    | FieldTypeTextArea
    | FieldTypeSearchSelect
    | FieldTypeSelectMulti
    | FieldTypeSelectSelectOptions
    | FieldTypeCheckbox
  );

export type IFieldProps = FieldWithoutActionProps | FieldTypeAction;
