/**
 * Labstep
 *
 * @module components/EntityUser/Action/Manage
 * @desc   Manage collaborators
 */

import Can from 'labstep-web/components/Entity/Can';
import { Action } from 'labstep-web/components/Entity/Can/types';
import EntityUserManager from 'labstep-web/components/EntityUser/Manager';
import { ICONS } from 'labstep-web/constants/icons';
import ActionComponent from 'labstep-web/core/Action/Component';
import ModalDefault from 'labstep-web/core/Modal/Default';
import React from 'react';
import { EntityUserActionManageProps } from './types';

const EntityUserActionManage: React.FC<
  EntityUserActionManageProps
> = ({ entity, ...rest }) => (
  <ModalDefault
    viewComponent={({ toggleModal }) => (
      <Can
        entityName={entity.entityName}
        id={entity.idAttr}
        action={Action.assign}
      >
        <ActionComponent
          type="option"
          text={'Assign to'}
          icon={ICONS.entity.actions.assign}
          onClick={toggleModal}
        />
      </Can>
    )}
    {...rest}
    showDone
    header="Manage Collaborators"
    content={<EntityUserManager entity={entity} />}
  />
);

export default EntityUserActionManage;
