/**
 * Labstep
 *
 * @module components/ProtocolCollection/FilterPane
 * @desc Filter pane for protocol collection
 */

import { FilterList } from 'labstep-web/components/Filter/List';
import FilterPane from 'labstep-web/components/Filter/Pane';
import { ICONS } from 'labstep-web/constants/icons';
import Icon from 'labstep-web/core/Icon';
import { ProtocolCollection } from 'labstep-web/models/protocol-collection.model';
import React from 'react';
import styles from './styles.module.scss';

const ProtocolCollectionFilterPane: React.FC = () => (
  <FilterPane
    extraFilters={
      <>
        <FilterList
          filters={[
            {
              component: (
                <Icon
                  name="edit outline"
                  className={styles.draftIcon}
                />
              ),
              text: 'Pending Drafts',
              params: {
                has_draft_version: 'true',
                has_last_version: undefined,
              },
            },
            {
              component: (
                <Icon name="check" className={styles.liveIcon} />
              ),
              text: 'Live Protocols',
              params: {
                has_last_version: 'true',
                has_draft_version: undefined,
              },
            },
          ]}
        />
      </>
    }
    entityName={ProtocolCollection.entityName}
    icon={ICONS.protocol_collection.primary}
    text={'Protocols'}
  />
);

export default ProtocolCollectionFilterPane;
