/**
 * Labstep
 *
 * @module components/ResourceItem/ResourceLocationModal
 * @desc Modal to view and edit resource location modal
 */

import EntityActionEditModal from 'labstep-web/components/Entity/Action/Edit/Modal';
import ResourceItemResourceLocationModalContent from 'labstep-web/components/ResourceItem/ResourceLocationModal/Content';
import ResourceLocationLabelBasic from 'labstep-web/components/ResourceLocation/Label/Basic';
import ActionComponent from 'labstep-web/core/Action/Component';
import React from 'react';
import { IResourceItemResourceLocationModalProps } from './types';

export const ResourceItemResourceLocationModal: React.FC<
  IResourceItemResourceLocationModalProps
> = ({ resourceItem, hideMap, disabled, modalProps }) => {
  const fullScreen = !!resourceItem.resource_location && !hideMap;
  return (
    <EntityActionEditModal
      entity={resourceItem}
      header={null}
      showDone={!fullScreen}
      closeIcon={fullScreen}
      renderIfNoEditPermissions
      viewComponent={({ toggleModal, canEdit }) => (
        <div
          onClick={disabled ? undefined : toggleModal}
          style={{ cursor: 'pointer' }}
        >
          {resourceItem.resource_location ? (
            <ResourceLocationLabelBasic
              withIcon
              withPopup
              resourceLocation={resourceItem.resource_location}
            />
          ) : (
            <ActionComponent
              type="text"
              disabled={disabled}
              text={
                canEdit && !disabled
                  ? 'Specify location'
                  : 'No location'
              }
              elementProps={{ placeholder: true }}
            />
          )}
        </div>
      )}
      content={
        <ResourceItemResourceLocationModalContent
          resourceItem={resourceItem}
          hideMap={hideMap}
        />
      }
      size={fullScreen ? 'fullscreen' : 'small'}
      {...modalProps}
    />
  );
};

export default ResourceItemResourceLocationModal;
