/**
 * Labstep
 *
 * @module components/Metadata/Value/Options
 * @desc Metadata Value
 */

import Flex from 'labstep-web/core/Flex';
import Label from 'labstep-web/core/Label';
import React from 'react';
import { MetadataValueOptionsProps } from './types';

export const MetadataValueOptions: React.FC<
  MetadataValueOptionsProps
> = ({ metadata }) => (
  <Flex wrap>
    {metadata.optionsSelectedValues.map((option) => (
      <Label key={option} circular>
        {option}
      </Label>
    ))}
  </Flex>
);

export default MetadataValueOptions;
