/**
 * Labstep
 *
 * @module components/DataTable/Action/Edit
 * @desc Edit a DataTable
 */

import EntityActionEdit from 'labstep-web/components/Entity/Action/Edit';
import TableModal from 'labstep-web/components/Table/Modal';
import React from 'react';
import { DataTableActionEditProps } from './types';

export const DataTableActionEdit: React.FC<
  DataTableActionEditProps
> = ({ comment }) => (
  <TableModal
    table={comment.data_table}
    allowLocking
    viewComponent={({ toggleModal }) => (
      <EntityActionEdit
        entity={comment}
        actionComponentProps={{
          type: 'option',
          onClick: toggleModal,
        }}
      />
    )}
  />
);

export default DataTableActionEdit;
