/**
 * Labstep
 *
 * @module screens/EntityView/Index
 * @desc Experiment index
 */

import EntityViewActionCreate from 'labstep-web/components/EntityView/Action/Create';
import { Action } from 'labstep-web/components/EntityView/Action/Menu/types';
import EntityViewCardEmptyState from 'labstep-web/components/EntityView/Card/EmptyState';
import EntityViewTable from 'labstep-web/components/EntityView/Table';
import { EntityView } from 'labstep-web/models/entity-view.model';
import EntityViewIndexBreadcrumb from 'labstep-web/screens/EntityView/Index/Breadcrumb';
import EntityViewIndexLeftPane from 'labstep-web/screens/EntityView/Index/LeftPane';
import MasterIndex from 'labstep-web/screens/Master/Index';
import React from 'react';
import { bulkActions, filters } from './constants';
import { EntityViewIndexProps } from './types';

export const EntityViewIndex: React.FC<EntityViewIndexProps> = ({
  group,
}) => {
  const params = {
    group_id: group.id,
    serializerGroups: 'entity_view_reporting,paginator',
  };

  return (
    <MasterIndex
      breadcrumb={<EntityViewIndexBreadcrumb />}
      params={params}
      noResultsMessage={<EntityViewCardEmptyState />}
      action={<EntityViewActionCreate group={group} />}
      filters={filters}
      entityName={EntityView.entityName}
      bulkActions={bulkActions}
      leftPane={<EntityViewIndexLeftPane />}
      hideAction
      tableFormat={false}
    >
      {({ entities, ...rest }): React.ReactElement => (
        <EntityViewTable
          actions={[
            Action.delete,
            Action.tag,
            Action.add_to_folder,
            Action.remove_from_folder,
          ]}
          entityViews={entities}
          group={group}
          {...rest}
        />
      )}
    </MasterIndex>
  );
};

export default EntityViewIndex;
