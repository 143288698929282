/**
 * Labstep
 *
 * @module components/UserGroup/List
 * @desc A multilist listing user groups
 */

import UserGroupCard from 'labstep-web/components/UserGroup/Card/Preview';
import List from 'labstep-web/core/List';
import React from 'react';
import { IUserGroupListProps } from './types';

const UserGroupList: React.FC<IUserGroupListProps> = ({
  userGroups,
}) => (
  <List id="group-list">
    {userGroups.map((userGroup) => (
      <div key={userGroup.id}>
        <UserGroupCard userGroup={userGroup} />
      </div>
    ))}
  </List>
);

export default UserGroupList;
