/**
 * Labstep
 *
 * @module components/Entity/Search/BulkActions
 * @desc Components to render bulk actions
 */

import EntityActionAssignBulk from 'labstep-web/components/Entity/Action/Assign/Bulk';
import EntityActionDeleteBulk from 'labstep-web/components/Entity/Action/Delete/Bulk';
import EntityActionDisableUserBulk from 'labstep-web/components/Entity/Action/DisableUser/Bulk';
import EntityActionShareBulk from 'labstep-web/components/Entity/Action/Share/Bulk';
import EntityActionTagBulk from 'labstep-web/components/Entity/Action/Tag/Bulk';
import EntityActionTransferOwnershipBulk from 'labstep-web/components/Entity/Action/TransferOwnership/Bulk';
import EntityActionUpdateTemplateBulk from 'labstep-web/components/Entity/Action/UpdateTemplate/Bulk';
import ExperimentWorkflowActionFinishBulk from 'labstep-web/components/ExperimentWorkflow/Action/Finish/Bulk';
import ExperimentWorkflowActionLockBulk from 'labstep-web/components/ExperimentWorkflow/Action/Lock/Bulk';
import FolderActionAddBulk from 'labstep-web/components/Folder/Action/Add/Bulk';
import FolderActionRemoveBulk from 'labstep-web/components/Folder/Action/Remove/Bulk';
import OrderRequestActionCreateBulk from 'labstep-web/components/OrderRequest/Action/Create/Bulk';
import OrderRequestActionRemovePurchaseOrderBulk from 'labstep-web/components/OrderRequest/Action/RemovePurchaseOrder/Bulk';
import OrderRequestActionSetPurchaseOrderBulk from 'labstep-web/components/OrderRequest/Action/SetPurchaseOrder/Bulk';
import OrderRequestActionUpdateStatusBulk from 'labstep-web/components/OrderRequest/Action/UpdateStatus/Bulk';
import OrderRequestActionUpdateUrgentBulk from 'labstep-web/components/OrderRequest/Action/UpdateUrgent/Bulk';
import { OrganizationActionUpdateUserRoleBulk } from 'labstep-web/components/Organization/Action/UpdateUserRolesBulk';
import ResourceItemActionUpdateAmountBulk from 'labstep-web/components/ResourceItem/Action/UpdateAmount/Bulk';
import ResourceItemActionUpdateResourceLocationBulk from 'labstep-web/components/ResourceItem/Action/UpdateResourceLocation/Bulk';
import ResourceItemActionUpdateStatusBulk from 'labstep-web/components/ResourceItem/Action/UpdateStatus/Bulk';
import SignatureRequestActionCreateBulk from 'labstep-web/components/SignatureRequest/Action/Create/Bulk';
import { UserGroupActionCreateBulk } from 'labstep-web/components/UserGroup/Action/Create/Bulk';
import { useBulkSelectContext } from 'labstep-web/contexts/bulk-select/hook';
import { useDataGridContext } from 'labstep-web/contexts/grid/hook';
import {
  FolderParent,
  PermissionParent,
  TagParent,
  TopLevelPermissionEntity,
} from 'labstep-web/models';
import { Device } from 'labstep-web/models/device.model';
import { Entity } from 'labstep-web/models/entity.model';
import { ExperimentWorkflow } from 'labstep-web/models/experiment-workflow.model';
import { OrderRequest } from 'labstep-web/models/order-request.model';
import { ResourceItem } from 'labstep-web/models/resource-item.model';
import { Resource } from 'labstep-web/models/resource.model';
import { UserGroup } from 'labstep-web/models/user-group.model';
import { UserOrganization } from 'labstep-web/models/user-organization.model';
import React, { useCallback } from 'react';
import styles from './styles.module.scss';
import { BulkAction, EntitySearchBulkActionsProps } from './types';

const EntitySearchBulkActions: React.FC<
  EntitySearchBulkActionsProps
> = ({ bulkActions = [], entityName }) => {
  const { entities, unselectAll, isAtLeastOneSelected } =
    useBulkSelectContext();
  const context = useDataGridContext();
  const unselectAllWithGrid = useCallback((): void => {
    if (context?.agGrid) {
      context.agGrid.api.deselectAll();
    }
    unselectAll();
  }, [context, unselectAll]);
  if (!isAtLeastOneSelected) {
    return null;
  }

  const bulkActionsStrings = bulkActions.map((action) => {
    if (typeof action === 'string') {
      return action;
    }
    return action.action;
  });

  const deleteActionProps = bulkActions.reduce((acc, action) => {
    if (
      typeof action === 'object' &&
      action.action === BulkAction.delete
    ) {
      return action.props || {};
    }
    return acc;
  }, {});

  return (
    <div className={styles.container}>
      {bulkActionsStrings.includes(BulkAction.add_to_workspace) && (
        <UserGroupActionCreateBulk
          entities={entities as UserGroup[]}
          options={{
            onSuccess: unselectAllWithGrid,
          }}
        />
      )}
      {bulkActionsStrings.includes(
        BulkAction.update_organization_role,
      ) && (
        <OrganizationActionUpdateUserRoleBulk
          entities={entities as UserOrganization[]}
          options={{
            onSuccess: unselectAllWithGrid,
          }}
        />
      )}
      {bulkActionsStrings.includes(BulkAction.add_to_folder) && (
        <FolderActionAddBulk
          entityName={entityName}
          parentEntities={entities as FolderParent[]}
          options={{
            onSuccess: unselectAllWithGrid,
            toast: true,
          }}
        />
      )}
      {bulkActionsStrings.includes(BulkAction.remove_from_folder) && (
        <FolderActionRemoveBulk
          entityName={entityName}
          parentEntities={entities as FolderParent[]}
          options={{
            onSuccess: unselectAllWithGrid,
          }}
        />
      )}
      {bulkActionsStrings.includes(BulkAction.tag) && (
        <EntityActionTagBulk
          entityName={entityName}
          parentEntities={entities as TagParent[]}
          options={{
            onSuccess: unselectAllWithGrid,
          }}
        />
      )}
      {bulkActionsStrings.includes(BulkAction.assign) && (
        <EntityActionAssignBulk
          parentEntityName={entityName}
          parentEntities={entities as TopLevelPermissionEntity[]}
          options={{
            onSuccess: unselectAllWithGrid,
          }}
        />
      )}
      {bulkActionsStrings.includes(
        BulkAction.order_request_update_status,
      ) && (
        <OrderRequestActionUpdateStatusBulk
          entities={entities as OrderRequest[]}
          options={{
            onSuccess: unselectAllWithGrid,
          }}
        />
      )}
      {bulkActionsStrings.includes(
        BulkAction.order_request_set_purchase_order,
      ) && (
        <OrderRequestActionSetPurchaseOrderBulk
          entities={entities as OrderRequest[]}
          options={{
            onSuccess: unselectAllWithGrid,
          }}
        />
      )}
      {bulkActionsStrings.includes(
        BulkAction.order_request_update_urgent,
      ) && (
        <OrderRequestActionUpdateUrgentBulk
          entities={entities as OrderRequest[]}
          options={{
            onSuccess: unselectAllWithGrid,
          }}
        />
      )}
      {bulkActionsStrings.includes(
        BulkAction.resource_item_update_status,
      ) && (
        <ResourceItemActionUpdateStatusBulk
          entities={entities as ResourceItem[]}
          options={{
            onSuccess: unselectAllWithGrid,
          }}
        />
      )}
      {bulkActionsStrings.includes(
        BulkAction.resource_item_update_resource_location,
      ) && (
        <ResourceItemActionUpdateResourceLocationBulk
          entities={entities as ResourceItem[]}
          options={{
            onSuccess: unselectAllWithGrid,
          }}
        />
      )}
      {bulkActionsStrings.includes(
        BulkAction.resource_item_update_amount,
      ) && (
        <ResourceItemActionUpdateAmountBulk
          entities={entities as ResourceItem[]}
          options={{
            onSuccess: unselectAllWithGrid,
          }}
        />
      )}
      {bulkActionsStrings.includes(
        BulkAction.resource_create_order_request,
      ) && (
        <OrderRequestActionCreateBulk
          entities={entities as Resource[]}
          options={{
            onSuccess: unselectAllWithGrid,
          }}
        />
      )}
      {bulkActionsStrings.includes(
        BulkAction.experiment_workflow_finish,
      ) && (
        <ExperimentWorkflowActionFinishBulk
          entities={entities as ExperimentWorkflow[]}
          options={{
            onSuccess: unselectAllWithGrid,
          }}
        />
      )}
      {bulkActionsStrings.includes(
        BulkAction.experiment_workflow_lock,
      ) && (
        <ExperimentWorkflowActionLockBulk
          entityName={entityName}
          entities={entities as ExperimentWorkflow[]}
          options={{
            onSuccess: unselectAllWithGrid,
          }}
        />
      )}
      {bulkActionsStrings.includes(
        BulkAction.signature_request_create,
      ) && (
        <SignatureRequestActionCreateBulk
          entities={entities as ExperimentWorkflow[]}
          options={{
            onSuccess: unselectAllWithGrid,
          }}
        />
      )}
      {bulkActionsStrings.includes(BulkAction.update_template) && (
        <EntityActionUpdateTemplateBulk
          entityName={entityName as 'device' | 'resource'}
          entities={entities as (Device | Resource)[]}
          options={{
            onSuccess: unselectAllWithGrid,
          }}
        />
      )}
      {bulkActionsStrings.includes(
        BulkAction.order_request_remove_purchase_order,
      ) && (
        <OrderRequestActionRemovePurchaseOrderBulk
          entities={entities as OrderRequest[]}
          options={{
            onSuccess: unselectAllWithGrid,
          }}
        />
      )}
      {bulkActionsStrings.includes(BulkAction.transfer_ownership) && (
        <EntityActionTransferOwnershipBulk
          entities={entities as PermissionParent[]}
          options={{
            onSuccess: unselectAllWithGrid,
          }}
        />
      )}
      {bulkActionsStrings.includes(BulkAction.share) && (
        <EntityActionShareBulk
          entityName={entityName}
          entities={entities as PermissionParent[]}
          options={{
            onSuccess: unselectAllWithGrid,
          }}
        />
      )}
      {bulkActionsStrings.includes(BulkAction.delete) && (
        <EntityActionDeleteBulk
          entityName={entityName}
          entities={entities as Entity[]}
          options={{
            onSuccess: unselectAllWithGrid,
          }}
          {...deleteActionProps}
        />
      )}
      {bulkActionsStrings.includes(BulkAction.disable_users) && (
        <EntityActionDisableUserBulk
          entities={entities as UserOrganization[]}
          options={{
            onSuccess: unselectAllWithGrid,
          }}
        />
      )}
    </div>
  );
};

export default EntitySearchBulkActions;
