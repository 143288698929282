/**
 * Labstep
 *
 * @module components/Group/Settings/Device
 * @desc   Group settings for Device
 */

import LayoutLinks from 'labstep-web/components/Layout/Links';
import PremiumFeatureScreenGuard from 'labstep-web/core/PremiumFeature/ScreenGuard';
import { TagType } from 'labstep-web/models/tag.model';
import DeviceIndexTemplate from 'labstep-web/screens/Device/Index/Template';
import TagIndex from 'labstep-web/screens/Tag/Index';
import React from 'react';
import { GroupSettingsDeviceProps } from './types';

const GroupSettingsDevice: React.FC<GroupSettingsDeviceProps> = ({
  group,
}) => {
  return (
    <div id="group-settings-devices" style={{ flex: 1 }}>
      <PremiumFeatureScreenGuard unstyled premiumFeature="devices">
        <LayoutLinks
          inverted
          links={[
            {
              children: 'Categories',
              route: {
                to: 'group_settings_device_categories',
                params: { id: group.id },
              },
              render: () => <DeviceIndexTemplate group={group} />,
            },
            {
              children: 'Tags',
              route: {
                to: 'group_settings_device_tags',
                params: { id: group.id },
              },
              render: () => (
                <TagIndex group={group} type={TagType.device} />
              ),
            },
          ]}
        />
      </PremiumFeatureScreenGuard>
    </div>
  );
};

export default GroupSettingsDevice;
