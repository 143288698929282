/**
 * Labstep
 *
 * @module core/Form/ReactForm/ProseMirrorHtml
 */

import ProseMirrorHtmlOnly from 'labstep-web/prosemirror/HtmlOnly';
import React from 'react';
import { Controller } from 'react-hook-form';
import { IProseMirrorHtmlFieldProps } from './types';

export const ProseMirrorHtml: React.FC<IProseMirrorHtmlFieldProps> = (
  props,
) => (
  <Controller
    name={props.name}
    control={props.control}
    render={({ field }): React.ReactElement => {
      const { value, onChange } = field;

      return (
        <ProseMirrorHtmlOnly
          initialState={value}
          onChange={onChange as any}
        />
      );
    }}
  />
);

export default ProseMirrorHtml;
