/**
 * Labstep
 *
 * @module components/ExperimentWorkflow/Filter/Pane
 * @desc Filter pane for experiment workflow
 */

import ExperimentWorkflowFilterPrimaryList from 'labstep-web/components/ExperimentWorkflow/Filter/Primary/List';
import ExperimentWorkflowFilterSignedList from 'labstep-web/components/ExperimentWorkflow/Filter/Signed/List';
import ExperimentWorkflowFilterStatusList from 'labstep-web/components/ExperimentWorkflow/Filter/Status/List';
import FilterPane from 'labstep-web/components/Filter/Pane';
import { ICONS } from 'labstep-web/constants/icons';
import React from 'react';

const ExperimentWorkflowFilterPane: React.FC = () => {
  return (
    <FilterPane
      entityName="experiment_workflow"
      extraFilters={
        <>
          <ExperimentWorkflowFilterPrimaryList />
          <ExperimentWorkflowFilterStatusList />
          <ExperimentWorkflowFilterSignedList />
        </>
      }
      icon={ICONS.experiment_workflow.primary}
      text={'Experiments'}
    />
  );
};

export default ExperimentWorkflowFilterPane;
