/**
 * Labstep
 *
 * @module screens/Device/Show/Right
 * @desc Device Show Right
 */

import DeviceDataManager from 'labstep-web/components/DeviceData/Manager';
import EntityQRCode from 'labstep-web/components/Entity/QRCode';
import ExperimentWorkflowLinkedList from 'labstep-web/components/ExperimentWorkflow/LinkedList';
import RightPane from 'labstep-web/components/Layout/RightPane';
import { RightPaneProps } from 'labstep-web/components/Layout/RightPane/types';
import LogList from 'labstep-web/components/Log/List';
import ProtocolCollectionLinkedList from 'labstep-web/components/ProtocolCollection/LinkedList';
import ThreadForm from 'labstep-web/components/Thread/Form';
import { ICONS } from 'labstep-web/constants/icons';
import React from 'react';
import { IScreensDeviceShowRightProps } from './types';

const ScreensDeviceShowRight: React.FC<
  IScreensDeviceShowRightProps
> = ({ device }) => {
  const commonSubPages: RightPaneProps['subPages'] = [
    {
      name: 'Notes',
      icon: ICONS.thread.primary,
      count: device.thread.comment_count,
      route: {
        to: 'device_show_thread',
        params: { id: device.id },
      },
      component: (
        <ThreadForm
          // This will cause remount if the threadId changes (e.g. when adding an experiment to an experiment workflow)
          key={JSON.stringify(device.thread.id)}
          createThreadId={device.thread.id}
          readThreadIds={device.thread.id}
          entityNameLabel="notes"
        />
      ),
    },
    {
      name: 'Activity',
      icon: 'history',
      route: {
        to: 'device_show_activity',
        params: { id: device.id },
      },
      component: <LogList entity={device} />,
    },
  ];
  const subPages: RightPaneProps['subPages'] = device.is_template
    ? commonSubPages
    : [
        {
          name: 'QR Code',
          icon: 'qrcode',
          route: {
            to: 'device_show_permalink',
            params: { id: device.id },
          },
          component: <EntityQRCode entity={device} />,
        },
        {
          name: 'Data',
          icon: ICONS.metadata.primary,
          route: {
            to: 'device_show_device_data',
            params: { id: device.id },
          },
          component: <DeviceDataManager device={device} />,
        },
        {
          name: 'Linked Experiments',
          icon: ICONS.experiment_workflow.primary,
          route: {
            to: 'device_show_experiment_workflows',
            params: { id: device.id },
          },
          component: (
            <ExperimentWorkflowLinkedList
              params={{ device_id: device.id }}
            />
          ),
        },
        {
          name: 'Linked Protocols',
          icon: ICONS.protocol.primary,
          route: {
            to: 'device_show_protocols',
            params: { id: device.id },
          },
          component: (
            <ProtocolCollectionLinkedList
              params={{ device_id: device.id }}
            />
          ),
        },
        ...commonSubPages,
      ];
  return (
    <RightPane
      route={{
        to: 'device_show',
        params: { id: device.id },
      }}
      subPages={subPages}
    />
  );
};

export default ScreensDeviceShowRight;
