/**
 * Labstep
 *
 * @module components/File/List
 * @desc List of Files
 */

import FileActionMenu from 'labstep-web/components/File/Action/Menu';
import { FileNamePreview } from 'labstep-web/components/File/Table';
import GenericCard from 'labstep-web/core/Card/Generic';
import List from 'labstep-web/core/List';
import { humanReadableDate } from 'labstep-web/services/date.service';
import React from 'react';
import { FileListProps } from './types';

const FileList: React.FC<FileListProps> = ({ files, actions }) => (
  <List id="files-list">
    {files.map((file) => (
      <GenericCard
        key={file.id}
        headerTitle={<FileNamePreview file={file} />}
        headerHeader={
          <>
            <span>CREATED ON</span>
            <div>{humanReadableDate(file.created_at)}</div>
          </>
        }
        actionMenu={<FileActionMenu file={file} actions={actions} />}
      />
    ))}
  </List>
);

export default FileList;
