/**
 * Labstep
 */

import React, { createContext } from 'react';
import { ParamsContextType } from './types';

export const defaultParamsContext: ParamsContextType = {
  initialSearchParams: {},
  searchParams: {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setParams: (): void => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  addPostFilter: (): void => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  clearAll: (): void => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  clearParams: (): void => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setPostFilterType: (): void => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  removePostFilter: (): void => {},
};

/**
 * Context for params to access searchParams, setParams and addPostFilter in children
 * without passing props down multiple levels.
 * TODO: Remove props passing completely and use context instead.
 */
export const ParamsContext = createContext<ParamsContextType>(
  defaultParamsContext,
);

export const ParamsContextProvider: React.FC<{
  value: ParamsContextType;
}> = ({ value, children }) => {
  return <ParamsContext.Provider value={value} children={children} />;
};
