/**
 * Labstep
 *
 * @module components/ResourceItem/Action/Menu
 * @desc Action Menu for resourceItem
 */

import SignatureActionRevoke from 'labstep-web/components/Signature/Action/Revoke';
import ActionMenu from 'labstep-web/core/Action/Menu';
import React from 'react';
import { ISignatureActionMenuProps } from './types';

export const SignatureActionMenu: React.FC<
  ISignatureActionMenuProps
> = ({ signature, experimentWorkflow, type, actions }) => (
  <ActionMenu type={type}>
    {actions.includes('revoke') && (
      <SignatureActionRevoke
        signature={signature}
        experimentWorkflow={experimentWorkflow}
      />
    )}
  </ActionMenu>
);

SignatureActionMenu.defaultProps = {
  type: 'icon',
};

export default SignatureActionMenu;
