/**
 * Labstep
 *
 * @module components/Experiment/LinkedList
 * @desc Experiment List
 */

import { ICONS } from 'labstep-web/constants/icons';
import ElementCard from 'labstep-web/core/Card/Element';
import LinkButton from 'labstep-web/core/Link/Button';
import List from 'labstep-web/core/List';
import TextPlaceholder from 'labstep-web/core/Text/Placeholder';
import { ReadOnMountHOC } from 'labstep-web/hoc/ReadOnMount';
import { ExperimentWorkflow } from 'labstep-web/models/experiment-workflow.model';
import React from 'react';
import styles from './styles.module.scss';
import { ExperimentWorkflowLinkedListProps } from './types';

const ExperimentWorkflowLinkedList: React.FC<
  ExperimentWorkflowLinkedListProps
> = ({ params }) => (
  <ReadOnMountHOC
    type="cursor"
    entityName={ExperimentWorkflow.entityName}
    params={{
      count: 10,
      ...params,
    }}
    children={({ entities, total }) =>
      total > 0 ? (
        <List className={styles.feed} id="experiment-workflow-list">
          {entities.map((experimentWorkflow: ExperimentWorkflow) => (
            <LinkButton
              key={experimentWorkflow.id}
              route={{
                to: 'experiment_workflow_show',
                params: { id: experimentWorkflow.id },
              }}
            >
              <ElementCard
                name={experimentWorkflow.displayName}
                icon={
                  experimentWorkflow.is_template
                    ? ICONS.experiment_workflow.template
                    : ICONS.experiment_workflow.primary
                }
              />
            </LinkButton>
          ))}
        </List>
      ) : (
        <TextPlaceholder>None</TextPlaceholder>
      )
    }
  />
);

export default ExperimentWorkflowLinkedList;
