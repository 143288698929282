/**
 * Labstep
 *
 * @module components/Metadata/Manager/List
 * @desc Metadata Manager in list form
 */

import Container from 'labstep-web/components/Layout/Container';
import MetadataActionCreateDataModal from 'labstep-web/components/Metadata/Action/Create/DataModal';
import MetadataCardList from 'labstep-web/components/Metadata/CardList';
import EmptyState from 'labstep-web/core/Card/EmptyState';
import { ReadOnMountHOC } from 'labstep-web/hoc/ReadOnMount';
import { Experiment } from 'labstep-web/models/experiment.model';
import { Metadata } from 'labstep-web/models/metadata';
import React from 'react';
import MetadataActionCreateFile from '../../Action/Create/File';
import { MetadataManagerListProps } from './types';

const MetadataManagerList: React.FC<MetadataManagerListProps> = ({
  entity,
  explanation = 'Add data fields to capture variable input parameters, such as incubation temperatures, or output data, such as purification yields, in a quick and structured fashion.',
  draggable,
  hideActions,
}) => {
  const showEmptyState =
    (entity instanceof Experiment ? entity : entity.metadata_thread)
      .metadata_count === 0;

  const metadataActionCreate = (
    <MetadataActionCreateDataModal entity={entity} />
  );
  return (
    <ReadOnMountHOC
      type="cursor"
      entityName={Metadata.entityName}
      params={{
        metadata_thread_id: entity.metadata_thread.id,
        has_variable_template: false,
      }}
      loading={{ cached: true }}
      children={({ total }) =>
        total > 0 ? (
          <div
            style={{ display: 'block' }}
            data-testId="metadata-manager-list"
          >
            <Container>{metadataActionCreate}</Container>
            <MetadataCardList
              entity={entity}
              draggable={draggable}
              hideActions={hideActions}
            />
          </div>
        ) : (
          <div style={{ display: 'block' }}>
            <MetadataActionCreateFile
              metadataThreadId={entity.metadata_thread.id}
            >
              <EmptyState
                src="/img/empty-state/data.svg"
                explanation={explanation}
                action={metadataActionCreate}
              />
            </MetadataActionCreateFile>
          </div>
        )
      }
    />
  );
};

export default MetadataManagerList;
