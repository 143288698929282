/**
 * Labstep
 */

import { GridEntityImportService } from 'labstep-web/grid/EntityImport/services/grid-entity-import.service';
import { GridReportService } from 'labstep-web/grid/Report/services/grid-report.service';
import { GridService } from 'labstep-web/grid/services/grid.service';
import { useContext, useEffect, useState } from 'react';
import { DataGridContext } from '.';
import { DataGridContextType } from './types';

export const useDataGridContext = (): DataGridContextType => {
  const context = useContext(DataGridContext);
  if (!context) {
    throw new Error(
      'useDataGridContext must be used within a DataGridContextProvider',
    );
  }
  return context;
};

export const useGridServiceFromContext = ():
  | GridService
  | undefined => {
  const dataGridContext = useDataGridContext();

  const [gridService, setGridService] = useState<GridService>();

  useEffect(() => {
    if (
      dataGridContext?.agGrid?.api &&
      dataGridContext?.agGrid?.columnApi
    ) {
      setGridService(new GridService(dataGridContext.agGrid));
    }
  }, [
    dataGridContext?.agGrid?.api,
    dataGridContext?.agGrid?.columnApi,
  ]);

  return gridService;
};

export const useAgGridEntityImportServiceFromContext = ():
  | GridEntityImportService
  | undefined => {
  const dataGridContext = useDataGridContext();

  const [gridService, setGridService] =
    useState<GridEntityImportService>();

  useEffect(() => {
    if (
      dataGridContext?.agGrid?.api &&
      dataGridContext?.agGrid?.columnApi
    ) {
      setGridService(
        new GridEntityImportService(dataGridContext.agGrid),
      );
    }
  }, [
    dataGridContext?.agGrid?.api,
    dataGridContext?.agGrid?.columnApi,
  ]);

  return gridService;
};

export const useAgGridReportServiceFromContext = ():
  | GridReportService
  | undefined => {
  const dataGridContext = useDataGridContext();

  const [gridService, setGridService] = useState<GridReportService>();

  useEffect(() => {
    if (
      dataGridContext?.agGrid?.api &&
      dataGridContext?.agGrid?.columnApi
    ) {
      setGridService(new GridReportService(dataGridContext.agGrid));
    }
  }, [
    dataGridContext?.agGrid?.api,
    dataGridContext?.agGrid?.columnApi,
  ]);

  return gridService;
};
