/**
 * Labstep
 *
 * @module components/Experiment/List
 * @desc Experiment List
 */

import ExperimentWorkflowCard from 'labstep-web/components/ExperimentWorkflow/Card';
import List from 'labstep-web/core/List';
import React from 'react';
import styles from './styles.module.scss';
import { IExperimentWorkflowListProps } from './types';

const ExperimentWorkflowList: React.FC<
  IExperimentWorkflowListProps
> = ({ experimentWorkflows, actions, createdBy, mainActionType }) => (
  <List className={styles.feed} id="experiment-workflow-list">
    {experimentWorkflows.map((experimentWorkflow) => (
      <ExperimentWorkflowCard
        key={`experimentWorkflow-${experimentWorkflow.id}`}
        experimentWorkflow={experimentWorkflow}
        actions={actions}
        createdBy={createdBy}
        mainActionType={mainActionType}
      />
    ))}
  </List>
);

export default ExperimentWorkflowList;
