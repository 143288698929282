/**
 * Labstep
 *
 * @module components/ExperimentWorkflow/Action/Create
 * @desc Create Action for experiment workflow
 */

import EntityActionCreate from 'labstep-web/components/Entity/Action/Create';
import { EntityActionCreateContainerProps } from 'labstep-web/components/Entity/Action/Create/types';
import { ActionComponentProps } from 'labstep-web/core/Action/Component/types';
import { addActionComponentPropsToDefault } from 'labstep-web/core/Action/Component/utils';
import { ExperimentWorkflow } from 'labstep-web/models/experiment-workflow.model';
import React from 'react';
import { ExperimentWorkflowActionCreateProps } from './types';

export const ExperimentWorkflowActionCreate: React.FC<
  ExperimentWorkflowActionCreateProps
> = ({
  protocol,
  body,
  actionComponentProps: extraActionComponentProps,
}) => {
  const redirectRoute: EntityActionCreateContainerProps['redirectRoute'] =
    ({ response }) =>
      ({
        to: protocol ? 'experiment_show' : 'experiment_workflow_show',
        params: {
          id: protocol
            ? (
                response.entities.experiment_workflow[
                  response.result as number
                ] as ExperimentWorkflow
              ).experiments[0]
            : response.result,
        },
      }) as const;

  let actionComponentProps = {
    dataCy: 'components/ExperimentWorkflow/Action/Create',
    type: 'button',
    elementProps: {
      ...(protocol && { color: 'green' }),
      primary: !protocol,
    },
    ...(protocol && {
      text: 'Run',
      icon: 'play',
      showIcon: true,
    }),
  } as ActionComponentProps;

  actionComponentProps = addActionComponentPropsToDefault(
    actionComponentProps,
    extraActionComponentProps,
  );

  return (
    <EntityActionCreate
      entityName="experiment_workflow"
      redirectRoute={redirectRoute}
      actionComponentProps={actionComponentProps}
      body={protocol ? { protocol_id: protocol.id, ...body } : body}
    />
  );
};

export default ExperimentWorkflowActionCreate;
