/**
 * Labstep
 *
 * @module components/Filter/Post/Active/Label
 * @desc Filter Post Active Label
 */

import FilterAvailableResourceItemCountActivePost from 'labstep-web/components/Filter/AvailableResourceItemCount/Active/Post';
import FilterDeletedActivePost from 'labstep-web/components/Filter/Deleted/Active/Post';
import FilterMetadataActivePost from 'labstep-web/components/Filter/Metadata/Active/Post';
import FilterResourceItemStatusActivePost from 'labstep-web/components/Filter/ResourceItemStatus/Active/Post';
import TagFilterActivePost from 'labstep-web/components/Filter/Tag/Active/Post';
import FilterUserActivePost from 'labstep-web/components/Filter/User/Active/Post';
import FilterMoleculeActivePost from 'labstep-web/components/Molecule/Filter/Active/Post';
import FilterResourceLocationActivePost from 'labstep-web/components/ResourceLocation/Filter/Active/Post';
import EntityViewFilterDateActivePost from 'labstep-web/grid/Report/components/Filter/Date/Active/Post';
import EntityViewFilterMetadataActivePost from 'labstep-web/grid/Report/components/Filter/Metadata/Active/Post';
import EntityViewFilterMetadataHasValueActivePost from 'labstep-web/grid/Report/components/Filter/MetadataHasValue/Active/Post';
import EntityViewFilterProtocolCollectionActivePost from 'labstep-web/grid/Report/components/Filter/ProtocolCollection/Active/Post';
import React from 'react';
import { FilterPostActiveLabelProps } from './types';

export const FilterPostActiveLabel: React.FC<
  FilterPostActiveLabelProps
> = (props): React.ReactElement | null =>
  props.isReporting ? (
    <>
      <EntityViewFilterDateActivePost {...props} />
      <EntityViewFilterMetadataActivePost {...props} />
      <EntityViewFilterMetadataHasValueActivePost {...props} />
      <EntityViewFilterProtocolCollectionActivePost {...props} />
    </>
  ) : (
    <>
      <FilterResourceLocationActivePost {...props} />
      <FilterUserActivePost {...props} />
      <FilterMetadataActivePost {...props} />
      <FilterResourceItemStatusActivePost {...props} />
      <FilterDeletedActivePost {...props} />
      <FilterMoleculeActivePost {...props} />
      <FilterAvailableResourceItemCountActivePost {...props} />
      <TagFilterActivePost {...props} />
    </>
  );
