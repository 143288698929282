/**
 * Labstep
 *
 * @module grid/CreateUsers
 * @desc   Grid to create users
 * @url    /organization/:organizationIdentifier/users > Add Users
 */

import {
  GridApi,
  GridReadyEvent,
  ProcessDataFromClipboardParams,
} from 'ag-grid-community';
import ActionComponent from 'labstep-web/core/Action/Component';
import DataGrid from 'labstep-web/core/DataGrid';
import Flex from 'labstep-web/core/Flex';
import Message from 'labstep-web/core/Message';
import {
  ADDING_ROW_CTA,
  columnDefs,
} from 'labstep-web/grid/CreateUsers/coldefs';
import { ModalContent } from 'labstep-web/grid/CreateUsers/components/ModalContent';
import { NewUser } from 'labstep-web/grid/CreateUsers/models/new-user';
import {
  getDefaultRowData,
  getEditableRowCount,
  getNewUsersCount,
  isInvalidGrid,
  onCellEditingStarted,
  onCellEditingStopped,
  processPastedData,
} from 'labstep-web/grid/CreateUsers/services/grid-create-users.service';
import React, { useState } from 'react';
import styles from './styles.module.scss';
import { GridCreateUsersProps } from './types';

export const GridCreateUsers: React.FC<GridCreateUsersProps> = ({
  organization,
  organizationPlan,
  toggleModal,
  create,
  status,
}) => {
  const availableSeats = organization.availableSeats || undefined;
  const [gridApi, setGridApi] = useState<GridApi | null>(null);
  const [isInvalid, setIsInvalid] = useState(false);

  const { rowData, rowCount } = getDefaultRowData(availableSeats);
  const [editableRowCount, setEditableRowCount] = useState(rowCount);

  const onGridReady = (params: GridReadyEvent) => {
    setGridApi(params.api);
    params.api.sizeColumnsToFit();
  };

  const processDataFromClipboard = (
    params: ProcessDataFromClipboardParams,
  ): string[][] | null => {
    processPastedData(params, availableSeats);
    return params.data;
  };

  const handleAddUsers = () => {
    const newUsers: {
      first_name: string;
      last_name: string;
      email: string;
    }[] = [];

    gridApi!.forEachNode((rowNode) => {
      const data = rowNode.data as NewUser;
      if (
        data.firstName !== ADDING_ROW_CTA &&
        data.firstName !== '' &&
        data.lastName !== '' &&
        data.email !== ''
      ) {
        newUsers.push({
          first_name: data.firstName,
          last_name: data.lastName,
          email: data.email,
        });
      }
    });

    if (newUsers.length === 0) {
      toggleModal();
      return;
    }

    create(newUsers, {
      onSuccess: () => {
        toggleModal();
      },
    });
  };

  const newUsersCount = gridApi ? getNewUsersCount(gridApi) : 0;
  const existingUsersCount = organization.seats || 0;
  const totalUsers = newUsersCount + existingUsersCount;
  const showConfirmation =
    organizationPlan &&
    newUsersCount > 0 &&
    totalUsers > (organizationPlan.quantity || 0);

  return (
    <>
      {availableSeats && editableRowCount >= availableSeats && (
        <Message
          warning
          content={
            <span>
              You can only add {availableSeats} more users before
              reaching the user limit for your plan. Please contact{' '}
              <a href="mailto:sales@labstep.com">sales@labstep.com</a>{' '}
              or your account manager to add more.
            </span>
          }
        />
      )}
      <DataGrid
        className="create-users-grid"
        rowClass={styles.rowStyle}
        rowSelection="multiple"
        rowData={rowData}
        columnDefs={columnDefs}
        onGridReady={onGridReady}
        stopEditingWhenCellsLoseFocus
        processDataFromClipboard={processDataFromClipboard}
        onPasteEnd={(e) => {
          setEditableRowCount(getEditableRowCount(e.api));
        }}
        onCellEditingStarted={(e) => {
          onCellEditingStarted(e, availableSeats);
          setEditableRowCount(getEditableRowCount(e.api));
        }}
        onCellEditingStopped={onCellEditingStopped}
        onCellValueChanged={(e) => {
          setIsInvalid(isInvalidGrid(e));
          e.api.refreshCells({
            rowNodes: [e.node],
            force: true,
            suppressFlash: true,
          });
        }}
      />
      <Message>
        Users who already have an account will receive an invitation
        and only be added once they accept.
      </Message>
      <Flex className={styles.actionButton}>
        <ActionComponent
          onClick={() => {
            handleAddUsers();
          }}
          status={status}
          type="button"
          text="Add"
          disabled={isInvalid}
          modalConfirmationProps={
            showConfirmation
              ? {
                  confirmButtonContent: 'Confirm',
                  header: 'Confirm Plan Amendment',
                  size: 'tiny',
                  message: (
                    <ModalContent
                      organizationPlan={organizationPlan}
                      totalUsers={totalUsers}
                    />
                  ),
                }
              : undefined
          }
        />
      </Flex>
    </>
  );
};
