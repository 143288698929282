/**
 * Labstep
 *
 * @module screens/ExperimentWorkflow/Show/Right/ExperimentWorkflows
 * @desc Linked Experiment Workflows screen
 */

import ExperimentWorkflowActionLinkExperimentWorkflow from 'labstep-web/components/ExperimentWorkflow/Action/LinkExperimentWorkflow';
import ExperimentWorkflowListLink from 'labstep-web/components/ExperimentWorkflow/List/Link';
import Container from 'labstep-web/components/Layout/Container';
import EmptyState from 'labstep-web/core/Card/EmptyState';
import Header from 'labstep-web/core/Header';
import TextWithCount from 'labstep-web/core/Text/WithCount';
import { ExperimentWorkflow } from 'labstep-web/models/experiment-workflow.model';
import { getHumanReadableEntityName } from 'labstep-web/services/i18n.service';
import React from 'react';
import { IExperimentWorkflowShowRightExperimentWorkflowLinksProps } from './types';

export const ExperimentWorkflowShowRightExperimentWorkflowLinks: React.FC<
  IExperimentWorkflowShowRightExperimentWorkflowLinksProps
> = ({ experimentWorkflow }) => {
  const action = (
    <ExperimentWorkflowActionLinkExperimentWorkflow
      experimentWorkflow={experimentWorkflow}
    />
  );

  return experimentWorkflow.totalLinkCount !== 0 ? (
    <div>
      <Container>{action}</Container>
      {!!experimentWorkflow.forward_link_count && (
        <ExperimentWorkflowListLink
          experimentWorkflowId={experimentWorkflow.id}
        />
      )}
      {!!experimentWorkflow.back_link_count && (
        <>
          <Header size="mini">
            <TextWithCount
              text="Backlinks"
              count={experimentWorkflow.back_link_count}
            />
          </Header>
          <ExperimentWorkflowListLink
            experimentWorkflowId={experimentWorkflow.id}
            showBackLinks
          />
        </>
      )}
    </div>
  ) : (
    <EmptyState
      src="/img/empty-state/experiments.svg"
      action={action}
      explanation={`Link other ${getHumanReadableEntityName(
        ExperimentWorkflow.entityName,
        true,
      )} to your ${getHumanReadableEntityName(
        ExperimentWorkflow.entityName,
      )}.`}
    />
  );
};

export default ExperimentWorkflowShowRightExperimentWorkflowLinks;
