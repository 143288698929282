/**
 * Labstep
 *
 * @module components/ExperimentWorkflow/FilterPane
 * @desc Filter list for experiment workflow signatures
 */

import { FilterSection } from 'labstep-web/components/Filter/List';
import Icon from 'labstep-web/core/Icon';
import { SignedTypes } from 'labstep-web/models/experiment-workflow.model';
import React from 'react';

const ExperimentWorkflowFilterSignedList: React.FC = () => (
  <FilterSection
    filter={{ entityName: 'experiment_workflow', name: 'signatures' }}
    header="Signatures"
    filters={Object.keys(SignedTypes).map((key) => {
      const type = SignedTypes[key as keyof typeof SignedTypes];
      return {
        component: <Icon name={type.icon} />,
        text: type.text,
        params: type.params,
      };
    })}
  />
);

export default ExperimentWorkflowFilterSignedList;
