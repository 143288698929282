/**
 * Labstep
 *
 * @module components/Layout/GlobalSearch/ProtectedEntityLink
 * @desc Shows an entity_show link but switches workspace if
 *       the entity is not shared with current active workspace
 */

import { withActiveGroup } from 'labstep-web/containers/ActiveGroup';
import ModalDefault from 'labstep-web/core/Modal/Default';
import { Experiment } from 'labstep-web/models/experiment.model';
import { Protocol } from 'labstep-web/models/protocol.model';
import { ResourceItem } from 'labstep-web/models/resource-item.model';
import React, { useState } from 'react';
import Content from './Content';
import { ProtectedEntityLinkProps } from './types';

export const getTopLevelEntity = (
  entity: ProtectedEntityLinkProps['entity'],
) => {
  if (entity instanceof ResourceItem) {
    return entity.resource;
  }
  if (entity instanceof Protocol) {
    return entity.collection;
  }
  if (entity instanceof Experiment) {
    return entity.experiment_workflow;
  }
  return entity;
};

export const ProtectedEntityLink: React.FC<
  ProtectedEntityLinkProps
> = ({ entity, activeGroup }) => {
  const [display, setDisplay] = useState(false);
  const topLevelEntity = getTopLevelEntity(entity);

  if (topLevelEntity && topLevelEntity.owner.id !== activeGroup?.id) {
    const content = (
      <Content entity={entity} setDisplay={setDisplay} />
    );
    if (!display) {
      return <div style={{ display: 'none' }}>{content}</div>;
    }
    return (
      <ModalDefault
        className="yooo"
        id="switch-workspace-modal"
        closeIcon={false}
        header={false}
        size="fullscreen"
        initialModalState
        content={content}
        viewComponent={null}
      />
    );
  }
  return null;
};
export default withActiveGroup(ProtectedEntityLink);
