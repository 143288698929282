/**
 * Labstep
 *
 * @module components/Group/Template/EmptyState
 * @desc Group Templatable Entity EmptyState
 */

import EntityActionCreate from 'labstep-web/components/Entity/Action/Create';
import EmptyState from 'labstep-web/core/Card/EmptyState';
import { getHumanReadableEntityName } from 'labstep-web/services/i18n.service';
import React from 'react';
import { GroupTemplateEmptyStateProps } from './types';

export const GroupTemplateEmptyState: React.FC<
  GroupTemplateEmptyStateProps
> = ({ group, entityName, options, extraBody }) => (
  <EmptyState
    title={`No ${getHumanReadableEntityName(
      entityName,
      false,
      true,
      true,
    )}`}
    explanation={`${getHumanReadableEntityName(
      entityName,
      true,
      true,
      true,
    )} allow each new ${getHumanReadableEntityName(
      entityName,
      false,
      true,
    )} to be initialised with your choice of default metadata fields.`}
    src="/img/empty-state/inventory.svg"
    action={
      group.logged_user_user_group?.type === 'owner' && (
        <EntityActionCreate
          entityName={entityName}
          body={{
            group_id: group.id,
            is_template: true,
            ...extraBody,
          }}
          options={options}
          actionComponentProps={{
            type: 'button',
            text: 'Create Template',
          }}
        />
      )
    }
  />
);

export default GroupTemplateEmptyState;
