/**
 * Labstep
 *
 * @module components/Entity/Action/UpdateTemplate
 * @desc Action to update template of an entity
 * TODO Refactor Only used for Device/Resource as we have hardcoded 'Set Category' label
 */

import EntityActionEdit from 'labstep-web/components/Entity/Action/Edit';
import { ICONS } from 'labstep-web/constants/icons';
import { withActiveGroup } from 'labstep-web/containers/ActiveGroup';
import { EntityUpdateContainer } from 'labstep-web/containers/Entity/Update';
import ModalDefault from 'labstep-web/core/Modal/Default';
import SearchSelect from 'labstep-web/core/Select/Search';
import { enhanceOptions } from 'labstep-web/services/utils.service';
import React from 'react';
import { EntityActionUpdateTemplateProps } from './types';

export const EntityActionUpdateTemplate: React.FC<
  EntityActionUpdateTemplateProps
> = ({
  entity,
  actionType = 'option',
  activeGroup,
  modalProps,
  options,
}) => (
  <ModalDefault
    header="Set Category"
    content={({ toggleModal }): React.ReactElement => (
      <EntityUpdateContainer
        entityName={entity.entityName}
        id={entity.id}
        optimistic={false}
      >
        {({ update, status }): React.ReactElement => (
          <SearchSelect
            params={{ is_template: true, group_id: activeGroup?.id }}
            autoFocus
            openMenuOnFocus
            creatable
            createBody={{ is_template: true }}
            entityName={entity.entityName}
            value={null}
            isDisabled={(status && status.isFetching) || false}
            onChange={(selectedOption): void => {
              update(
                { template_id: selectedOption.id },
                enhanceOptions({ options, onSuccess: toggleModal }),
              );
            }}
          />
        )}
      </EntityUpdateContainer>
    )}
    viewComponent={({ toggleModal }): React.ReactElement => (
      <EntityActionEdit
        entity={entity}
        actionComponentProps={{
          type: actionType,
          onClick: toggleModal,
          text: 'Set Category',
          icon: ICONS.resource_template.primary,
        }}
      />
    )}
    {...modalProps}
  />
);

export default withActiveGroup(EntityActionUpdateTemplate);
