/**
 * Labstep
 *
 * @module prosemirror/components/Toolbar/ProtocolTable
 * @desc Button to insert an ProtocolTable
 */

import EntityActionCreate from 'labstep-web/components/Entity/Action/Create';
import TableModal from 'labstep-web/components/Table/Modal';
import { EntityReadEntityContainer } from 'labstep-web/containers/Entity/Read/Entity';
import { ProtocolTable } from 'labstep-web/models/protocol-table.model';
import { Protocol } from 'labstep-web/models/protocol.model';
import NodeProtocolTable from 'labstep-web/prosemirror/nodes/protocol-table';
import { generateTableData } from 'labstep-web/services/table.service';
import React, { useState } from 'react';
import { IProtocolTableToolbarProps } from './types';

const ProtocolTableToolbar: React.FC<IProtocolTableToolbarProps> = ({
  entity,
  dispatch,
  state,
}) => {
  const [id, setId] = useState(null);

  const { key } = NodeProtocolTable;
  const { entityName } = ProtocolTable;

  const onSubmit = ({ response }: any) => {
    setId(response.result);
    const { tr } = state;
    const content = state.schema.nodes[key].create({
      id: response.entities[key][response.result].id,
      guid: response.entities[key][response.result].guid,
    });
    tr.insert(state.selection.$from.pos, content);
    if (dispatch) {
      dispatch(tr);
    }
  };

  if (!id) {
    const data = generateTableData();

    const table: any = {
      name: 'Untitled Table',
      data,
    };

    return (
      <EntityActionCreate
        entityName={entityName}
        parentName={entity.entityName}
        parentId={entity.id}
        actionComponentProps={{
          type: 'option',
          icon: 'table',
          text: 'Table',
        }}
        body={table}
        options={{ onSuccess: onSubmit }}
      />
    );
  }

  return (
    <EntityReadEntityContainer entityName={entityName} id={id}>
      {({ entity: protocolTable }) => (
        <TableModal
          allowLocking={
            entity.entityName === Protocol.entityName ||
            entity.isTemplate
          }
          table={protocolTable}
          viewComponent={null}
          initialModalState
          onClose={() => setId(null)}
          onDone={() => {
            setId(null);
          }}
        />
      )}
    </EntityReadEntityContainer>
  );
};

export default ProtocolTableToolbar;
