/**
 * Labstep
 *
 * @module components/OrderRequest/Action/Share
 * @desc Share a orderRequest with all users on the platform
 */

import EntityActionShare from 'labstep-web/components/Entity/Action/Share';
import React from 'react';
import { OrderRequestActionShareProps } from './types';

export const OrderRequestActionShare: React.FC<
  OrderRequestActionShareProps
> = ({ orderRequest }) => <EntityActionShare entity={orderRequest} />;

export default OrderRequestActionShare;
