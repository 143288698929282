/**
 * Labstep
 *
 * @module components/Entity/Action/Create/Templatable
 * @desc Create Action for Templatable entities
 */

import { CanCreate } from 'labstep-web/components/Entity/Can';
import EntityFormCreateTemplatable from 'labstep-web/components/Entity/Form/Create/Templatable';
import ActionComponent from 'labstep-web/core/Action/Component';
import ModalDefault from 'labstep-web/core/Modal/Default';
import { APIResponse } from 'labstep-web/models';
import { getHumanReadableEntityName } from 'labstep-web/services/i18n.service';
import { navigation } from 'labstep-web/services/navigation';
import React from 'react';
import { withRouter } from 'react-router';
import { EntityActionCreateTemplatableProps } from './types';

export const EntityActionCreateTemplatable: React.FC<
  EntityActionCreateTemplatableProps
> = ({ entityName, templateId, history, actionComponentProps }) => {
  const text = `New ${getHumanReadableEntityName(
    entityName,
    false,
    true,
  )}`;
  return (
    <ModalDefault
      header={text}
      content={({ toggleModal }): React.ReactElement => (
        <EntityFormCreateTemplatable
          entityName={entityName}
          templateId={templateId}
          showLoaderWhileCreating
          options={{
            onSuccess: ({
              response,
            }: {
              response: APIResponse;
            }): void => {
              toggleModal();
              history.push(
                navigation.get(`${entityName}_show`, {
                  id: response.result,
                }),
              );
            },
          }}
        />
      )}
      viewComponent={({ toggleModal }): React.ReactElement => {
        if (actionComponentProps?.type) {
          return (
            <CanCreate entityName={entityName}>
              <ActionComponent
                text={text}
                {...actionComponentProps}
                onClick={toggleModal}
              />
            </CanCreate>
          );
        }
        return (
          <CanCreate entityName={entityName}>
            <ActionComponent
              type="button"
              text={text}
              onClick={toggleModal}
            />
          </CanCreate>
        );
      }}
    />
  );
};

export default withRouter(EntityActionCreateTemplatable);
