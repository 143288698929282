/**
 * Labstep
 *
 * @module grid/Report/components/Filter/MetadataHasValue
 * @desc Metadata has value filter
 */

import { ICONS } from 'labstep-web/constants/icons';
import ActionComponent from 'labstep-web/core/Action/Component';
import { getPostFilterMetadatasHasValue } from 'labstep-web/services/postFilter/filters/metadatas';
import { PostFilterComparison } from 'labstep-web/services/query-parameter.service';
import React from 'react';
import { GridReportFilterMetadataHasValueProps } from './types';

const GridReportFilterMetadataHasValue: React.FC<
  GridReportFilterMetadataHasValueProps
> = ({ path, addPostFilter, metadataLabel }) => (
  <ActionComponent
    type="option"
    icon={ICONS.metadata.secondary}
    text="Only show rows with data in this field"
    onClick={() => {
      const filter = getPostFilterMetadatasHasValue(path);
      addPostFilter(
        filter.getNode([
          metadataLabel || '',
          ['', PostFilterComparison.not_null],
        ]),
        true,
      );
    }}
  />
);

export default GridReportFilterMetadataHasValue;
