/**
 * Labstep
 *
 * @module components/Entity/Action/TransferOwnership
 * @desc Action to transfer ownership for an entity
 */

import { CanGroup } from 'labstep-web/components/Entity/Can';
import { TransferOwnershipContainer } from 'labstep-web/containers/TransferOwnership';
import ActionComponent from 'labstep-web/core/Action/Component';
import FormReusable from 'labstep-web/core/Form/Reusable';
import { FieldType } from 'labstep-web/core/Form/Reusable/types';
import ModalDefault from 'labstep-web/core/Modal/Default';
import TextError from 'labstep-web/core/Text/Error';
import { Group } from 'labstep-web/models/group.model';
import {
  USER_GROUP_TYPE_VIEW,
  UserGroup,
} from 'labstep-web/models/user-group.model';
import { enhanceOptions } from 'labstep-web/services/utils.service';
import React from 'react';
import { object } from 'yup';
import { EntityActionTransferOwnershipProps } from './types';

const EntityActionTransferOwnership: React.FC<
  EntityActionTransferOwnershipProps
> = ({ entity, bulkIds, actionComponentProps, options = {} }) => (
  <CanGroup action="owner" groupId={entity.owner.id}>
    <TransferOwnershipContainer>
      {({ transferOwnership, status }): React.ReactElement => (
        <ModalDefault
          header="Transfer Ownership"
          inverted
          content={({ toggleModal }) => (
            <div>
              <FormReusable
                color="red"
                submitButtonText="Transfer Ownership"
                onCancel={toggleModal}
                status={status}
                onSubmit={(values) => {
                  transferOwnership(
                    entity.entityName,
                    bulkIds || entity.id,
                    values.group_id as number,
                    enhanceOptions({
                      options,
                      onSuccess: toggleModal,
                      toast: true,
                    }),
                  );
                }}
                fields={[
                  {
                    fieldLabel: `Select ${Group.getHumanReadableEntityName()}`,
                    name: 'group',
                    validation: object().required(),
                    fieldType: FieldType.SearchSelect,
                    elementProps: {
                      searchKey: 'search_query',
                      entityName: 'user_group',
                      params: {
                        not_type: USER_GROUP_TYPE_VIEW,
                        not_group_id: entity.owner.id,
                        is_group_deleted: false,
                      },
                      setOptions: (userGroups: UserGroup[]) =>
                        userGroups.map(
                          (userGroup: UserGroup) => userGroup.group,
                        ),
                    },
                  },
                  {
                    fieldType: FieldType.action,
                    component: () => (
                      <TextError>
                        {`WARNING: if you transfer ownership to a
                          ${Group.getHumanReadableEntityName()} you do not own you will no longer
                          have owner permissions.`}
                      </TextError>
                    ),
                  },
                ]}
              />
            </div>
          )}
          viewComponent={({ toggleModal }) => (
            <ActionComponent
              text="Transfer Ownership"
              type="option"
              icon="exchange"
              {...actionComponentProps}
              onClick={toggleModal}
            />
          )}
        />
      )}
    </TransferOwnershipContainer>
  </CanGroup>
);

export default EntityActionTransferOwnership;
