/**
 * Labstep
 *
 * @module components/Entity/Action/Update/DropdownPill
 * @desc Entity Update action with Dropdown Pill
 */

import { useHasAccess } from 'labstep-web/components/Entity/Can';
import { Action } from 'labstep-web/components/Entity/Can/types';
import { EntityUpdateContainer } from 'labstep-web/containers/Entity/Update';
import DropdownPill from 'labstep-web/core/Dropdown/Pill';
import React from 'react';
import { EntityActionUpdateDropdownPillProps } from './types';

export const EntityActionUpdateDropdownPill: React.FC<
  EntityActionUpdateDropdownPillProps
> = ({
  entity,
  items,
  disabled = false,
  field,
  currentValue,
  ...rest
}) => {
  const canEdit = useHasAccess(
    entity.entityName,
    entity.idAttr,
    Action.edit,
    field,
  );

  return (
    <EntityUpdateContainer
      entityName={entity.entityName}
      id={entity.idAttr}
    >
      {({ update }) => (
        <DropdownPill
          {...rest}
          activeLabel={currentValue}
          disabled={!canEdit || disabled}
          items={items.map((item) => {
            const showPremiumModal =
              item.premiumItem &&
              item.premiumItem.premiumFeature &&
              !item.premiumItem.enabled;
            return {
              label: item.label,
              premiumItem: item.premiumItem,
              onClick: showPremiumModal
                ? () => {}
                : item.onClick ||
                  (() => {
                    if (item.body) {
                      update(item.body, item.options || {});
                    }
                  }),
            };
          })}
        />
      )}
    </EntityUpdateContainer>
  );
};

export default EntityActionUpdateDropdownPill;
