/**
 * Labstep
 *
 * @desc Service to remove sensitive fields
 */

import { SENSITIVE_FIELDS } from 'labstep-web/constants/sensitive';
import cloneDeep from 'lodash/cloneDeep';
import forEach from 'lodash/forEach';

type RecursiveObject<T> = {
  [K in keyof T]: T[K] extends object ? RecursiveObject<T[K]> : T[K];
};

export class SensitiveService {
  /**
   * Strip sensitive fields from a redux action
   */
  static purifyReduxAction(
    properties: RecursiveObject<Record<string, unknown>>,
  ) {
    const trackedProperties = cloneDeep(properties);
    this.purifyObject(trackedProperties);
    this.recursivelyPurifyObject(trackedProperties);
    return trackedProperties;
  }

  /**
   * Strip sensitive fields from a network request
   */
  static purifyRequest(request: any): any {
    if (request.body) {
      const bodyObject = JSON.parse(request.body);
      this.purifyObject(bodyObject);
      this.recursivelyPurifyObject(bodyObject);
      request.body = JSON.stringify(bodyObject);
    }

    if (request.headers) {
      request.headers.apikey = undefined;
      request.headers.Authorization = undefined;
    }

    return request;
  }

  static recursivelyPurifyObject<T>(obj: RecursiveObject<T>) {
    forEach(Object.keys(obj), (key) => {
      if (obj[key] !== null && typeof obj[key] === 'object') {
        this.recursivelyPurifyObject(
          obj[key] as RecursiveObject<any>,
        );
        this.purifyObject(obj[key]);
      }
    });
  }

  static purifyObject(object: any): any {
    forEach(SENSITIVE_FIELDS, (field) => {
      if (
        object[field] !== undefined &&
        object[field] !== null &&
        typeof object[field] !== 'object'
      ) {
        object[field] = '[REDACTED]';
      }
    });
  }
}
