/**
 * Labstep
 *
 * @module components/Group/Form/fields
 * @desc Form Fields for Group
 */

import {
  FieldType,
  FieldWithoutActionProps,
} from 'labstep-web/core/Form/Reusable/types';
import { Group } from 'labstep-web/models/group.model';
import { getHumanReadableEntityName } from 'labstep-web/services/i18n.service';
import rules from 'labstep-web/services/validation/rules';

export const fieldName: FieldWithoutActionProps = {
  fieldType: FieldType.Text,
  fieldLabel: 'Name',
  name: 'name',
  validation: rules.group.name,
  placeholder: `Give your ${getHumanReadableEntityName(
    Group.entityName,
  )} a name, e.g. My Research Group.`,
};

export const fieldDescription: FieldWithoutActionProps = {
  fieldLabel: 'Description',
  name: 'description',
  fieldType: FieldType.TextArea,
  validation: rules.group.description,
  placeholder: `What is this ${getHumanReadableEntityName(
    Group.entityName,
  )} for? You can use a ${getHumanReadableEntityName(
    Group.entityName,
  )} to manage your research group, organisation or just as a personal project.`,
};

export const fieldGroupDefaultSignatureStatement: FieldWithoutActionProps =
  {
    fieldLabel: 'Default Signature Statement',
    name: 'default_signature_statement',
    fieldType: FieldType.TextArea,
    validation: rules.group.default_signature_statement,
    placeholder: `Enter Default Signature Statement`,
  };

export const fieldGroupDefaultSignatureRequestMessage: FieldWithoutActionProps =
  {
    fieldLabel: 'Default Signature Request Message',
    name: 'default_signature_request_message',
    fieldType: FieldType.TextArea,
    validation: rules.group.default_signature_request_message,
    placeholder: `Enter Default Signature Request Message`,
  };
