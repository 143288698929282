/**
 * Labstep
 *
 * @module core/Action/Component/Label
 * @desc Label on Action Component dropdown/button/option
 */

import Icon from 'labstep-web/core/Icon';
import React from 'react';
import { IActionComponentLabelProps } from './types';

export const ActionComponentLabel: React.FC<
  IActionComponentLabelProps
> = ({ icon, cornerIcon, text, iconSize }) => (
  <>
    {icon && (
      <>
        <Icon
          size={iconSize}
          name={icon}
          cornerIcon={cornerIcon}
        />{' '}
      </>
    )}
    {text}
  </>
);

export default ActionComponentLabel;
