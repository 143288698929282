/**
 * Labstep
 *
 * @module components/Resource/Image
 * @desc Resource image
 */

import EntityImageUploadable from 'labstep-web/components/Entity/Image/Uploadable';
import React from 'react';
import { IResourceImagUploadableProps } from './types';

const ResourceImageUploadble: React.FC<
  IResourceImagUploadableProps
> = ({ resource, mobile }) => (
  <EntityImageUploadable entity={resource} mobile={mobile} />
);

export default ResourceImageUploadble;
