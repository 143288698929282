/**
 * Labstep
 *
 * @module components/File/Filter/Extension/List
 * @desc Filter pane for file
 */

import {
  FilterList,
  FilterSection,
} from 'labstep-web/components/Filter/List';
import { ToggleContainer } from 'labstep-web/containers/Toggle';
import ActionComponent from 'labstep-web/core/Action/Component';
import Image from 'labstep-web/core/Image';
import {
  File as FileModel,
  fileTypes,
} from 'labstep-web/models/file.model';
import React from 'react';

export const FileFilterExtensionList: React.FC = () => (
  <ToggleContainer>
    {({ toggle, toggled }) => (
      <FilterSection
        header={'File Type'}
        filters={[]}
        filter={{
          entityName: 'file',
          name: 'extension',
        }}
        extraFilters={
          <>
            <FilterList
              filters={fileTypes
                .filter((f, i) => i < (toggled ? Infinity : 6))
                .map((type) => ({
                  component: (
                    <Image
                      size="mini"
                      src={FileModel.getIconPath(type)}
                    />
                  ),
                  text: type,
                  params: { extension: type },
                }))}
            />
            {!toggled && (
              <ActionComponent
                onClick={toggle}
                type="text"
                text={'Show more'}
              />
            )}
          </>
        }
      />
    )}
  </ToggleContainer>
);

export default FileFilterExtensionList;
