/**
 * Labstep
 *
 * @module components/Device/Action/Duplicate
 * @desc Duplicate action for Device
 */

import EntityActionDuplicate from 'labstep-web/components/Entity/Action/Duplicate';
import React from 'react';
import { DeviceActionDuplicateProps } from './types';

export const DeviceActionDuplicate: React.FC<
  DeviceActionDuplicateProps
> = ({ device }) => <EntityActionDuplicate entity={device} />;

export default DeviceActionDuplicate;
