/**
 * Labstep
 *
 * @module components/DeviceBooking/Form/ShowEdit/EndedAt
 * @desc EndedAt show edit for device booking
 *
 * FIXME Dead code
 */

import FormShowEditDate from 'labstep-web/core/Form/ShowEdit/Date';
import React from 'react';
import { getBody } from '../StartedAt';
import { DeviceBookingFormShowEditEndedAtProps } from './types';

export const DeviceBookingFormShowEditEndedAt: React.FC<
  DeviceBookingFormShowEditEndedAtProps
> = ({ deviceBooking }) => (
  <FormShowEditDate
    entity={deviceBooking}
    label="end"
    field="ended_at"
    getBody={(body) =>
      getBody(body, deviceBooking.started_at, deviceBooking.ended_at)
    }
    optimistic
    // dateTimePickerOptions={{
    //   minDate: deviceBooking.started_at,
    // }}
  />
);

export default DeviceBookingFormShowEditEndedAt;
