/**
 * Labstep
 *
 * @module screens/ExperimentWorkflow/Show
 * @desc ExperimentWorkflow Show Screen
 */

import ProtocolConditionModal from 'labstep-web/components/ProtocolCondition/Modal/Container';
import { ProtocolConditionModalProvider } from 'labstep-web/components/ProtocolCondition/Modal/context';
import DocumentHead from 'labstep-web/core/DocumentHead';
import { ReadOnMountHOC } from 'labstep-web/hoc/ReadOnMount';
import { ExperimentWorkflow } from 'labstep-web/models/experiment-workflow.model';
import MasterShow from 'labstep-web/screens/Master/Show';
import React from 'react';
import Center from './Center';
import Right from './Right';
import {
  IExperimentWorkflowShowScreenContainerProps,
  IExperimentWorkflowShowScreenProps,
} from './types';

export const ExperimentWorkflowShowScreen: React.FC<
  IExperimentWorkflowShowScreenProps
> = ({ experimentWorkflow, activeExperimentWorkflowRoute }) => (
  <>
    <DocumentHead title={experimentWorkflow.displayName} />

    <ProtocolConditionModalProvider
      entityName={ExperimentWorkflow.entityName}
    >
      <ProtocolConditionModal
        protocol={experimentWorkflow.root_experiment}
      />
      <MasterShow
        entity={experimentWorkflow}
        center={<Center experimentWorkflow={experimentWorkflow} />}
        right={
          <Right
            experimentWorkflow={experimentWorkflow}
            activeExperimentWorkflowRoute={
              activeExperimentWorkflowRoute
            }
          />
        }
      />
    </ProtocolConditionModalProvider>
  </>
);

const ExperimentWorkflowShowScreenContainer: React.FC<
  IExperimentWorkflowShowScreenContainerProps
> = ({ id, activeExperimentWorkflowRoute }) =>
  id ? (
    <ReadOnMountHOC
      type="entities"
      entityName="experiment_workflow"
      params={{
        get_single: 1,
        is_deleted: 'both',
        id,
      }}
      loading={{ loader: 'show', cached: true }}
      children={({ entity }) => (
        <ExperimentWorkflowShowScreen
          experimentWorkflow={entity}
          activeExperimentWorkflowRoute={
            activeExperimentWorkflowRoute
          }
        />
      )}
    />
  ) : null;

export default ExperimentWorkflowShowScreenContainer;
