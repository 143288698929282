/**
 * Labstep
 *
 * @module state/epics/bugsnag
 * @desc Bugsnag epic for reporting non-fatal error
 */

import { Action } from 'labstep-web/models/action.model';
import bugsnagService from 'labstep-web/services/bugsnag.service';
import {
  NETWORK_ERROR,
  RESPONSE_ERROR,
  TIMEOUT_ERROR,
} from 'labstep-web/services/http-client.service';
import { selectAuthenticatedUser } from 'labstep-web/state/selectors/authenticated-user';
import { StateObservable } from 'redux-observable';
import { Observable, concat, of } from 'rxjs';
import {
  catchError,
  filter,
  ignoreElements,
  tap,
} from 'rxjs/operators';
import { LabstepReduxState } from '../types';

/**
 * On login success, identify user in bugsnag
 *
 * @function
 * @param  {Observable<Action>} action$
 * @param  {StateObservable<LabstepReduxState>} state$
 * @return {Observable<Action>}
 */
export const onLoginSuccessActionEpic = (
  action$: Observable<Action>,
  state$: StateObservable<LabstepReduxState>,
): Observable<Action> =>
  action$.pipe(
    filter(
      (action: Action) => action.type === 'SUCCESS_INTERNAL_LOGIN',
    ),
    tap(() => {
      const user = selectAuthenticatedUser(state$.value);
      bugsnagService.identify(user);
    }),
    ignoreElements(),
    catchError((err, source$: Observable<Action>) =>
      concat(
        of({ type: 'EPIC_FAIL_BUGSNAG_LOGIN_SUCCESS', payload: err }),
        source$,
      ),
    ),
  );

/**
 * Call on epic Fail
 * Track error on bugsnag if available
 * @see https://docs.bugsnag.com/platforms/javascript/reporting-handled-errors
 * @see https://docs.bugsnag.com/platforms/javascript/customizing-error-reports/#errorclass
 *
 * @function
 * @param  {Observable<Action>} action$
 * @return {Observable<Action>}
 */
export const onEpicFailActionEpic = (
  action$: Observable<Action>,
): Observable<Action> =>
  action$.pipe(
    filter((action: Action) => action.type.startsWith('EPIC_FAIL')),
    tap((action: Action) => {
      if (action && bugsnagService) {
        bugsnagService.notify(
          new Error(
            `${action.type} - ${(action.payload as any)?.message}`,
          ),
          { action },
          (action.payload as any)?.message ===
            'Applying a mismatched transaction'
            ? 'info'
            : 'warning',
        );
      }
    }),
    ignoreElements(),
    catchError((err, source$: Observable<Action>) =>
      concat(
        of({ type: 'END_OF_LOOP_EPIC_FAIL_BUGSNAG', payload: err }),
        source$,
      ),
    ),
  );

/**
 * Call onFail
 * Track error on bugsnag if available
 * @see https://docs.bugsnag.com/platforms/javascript/reporting-handled-errors
 * @see https://docs.bugsnag.com/platforms/javascript/customizing-error-reports/#errorclass
 *
 * @function
 * @param  {Observable<Action>} action$
 * @return {Observable<Action>}
 */
export const onFailActionEpic = (
  action$: Observable<Action>,
): Observable<Action> =>
  action$.pipe(
    filter((action: Action) => action.type.startsWith('FAIL')),
    tap((action: Action) => {
      if (action.meta && bugsnagService) {
        let severity = 'error';
        let { type } = action;
        if (action.error) {
          if ([403, 404].indexOf((action.error as any).status) > -1) {
            severity = 'info';
          }

          if (action.error.name !== RESPONSE_ERROR) {
            type = action.error.name as string;
          }
        }

        if (type === TIMEOUT_ERROR || type === NETWORK_ERROR) {
          severity = 'info';
        }

        bugsnagService.notify(
          new Error(type),
          { action, error: action.error },
          severity,
        );
      }
    }),
    ignoreElements(),
    catchError((err, source$: Observable<Action>) =>
      concat(
        of({ type: 'EPIC_FAIL_BUGSNAG_FAIL', payload: err }),
        source$,
      ),
    ),
  );

/**
 * Tracks WEBSOCKET_DISCONNECT
 * @see https://docs.bugsnag.com/platforms/javascript/reporting-handled-errors
 * @see https://docs.bugsnag.com/platforms/javascript/customizing-error-reports/#errorclass
 *
 * @function
 * @param  {Observable<Action>} action$
 * @return {Observable<Action>}
 */
export const onWebsocketDisconnectActionEpic = (
  action$: Observable<Action>,
): Observable<Action> =>
  action$.pipe(
    filter((action: Action) =>
      action.type.includes('WEBSOCKET_DISCONNECT'),
    ),
    tap((action: Action) => {
      if (bugsnagService) {
        bugsnagService.notify(
          new Error(action.type),
          { action },
          'info',
        );
      }
    }),
    ignoreElements(),
    catchError((err, source$: Observable<Action>) =>
      concat(
        of({
          type: 'EPIC_FAIL_BUGSNAG_WEBSOCKET_DISCONNECT',
          payload: err,
        }),
        source$,
      ),
    ),
  );

/**
 * Tracks WEBSOCKET_ERROR
 * @see https://docs.bugsnag.com/platforms/javascript/reporting-handled-errors
 * @see https://docs.bugsnag.com/platforms/javascript/customizing-error-reports/#errorclass
 *
 * @function
 * @param  {Observable<Action>} action$
 * @return {Observable<Action>}
 */
export const onWebsocketErrorActionEpic = (
  action$: Observable<Action>,
): Observable<Action> =>
  action$.pipe(
    filter((action: Action) =>
      action.type.includes('WEBSOCKET_ERROR'),
    ),
    tap((action: Action) => {
      if (bugsnagService) {
        bugsnagService.notify(
          new Error(`${action.type} - ${action.payload}`),
          { action },
          'info',
        );
      }
    }),
    ignoreElements(),
    catchError((err, source$: Observable<Action>) =>
      concat(
        of({
          type: 'EPIC_FAIL_BUGSNAG_WEBSOCKET_ERROR',
          payload: err,
        }),
        source$,
      ),
    ),
  );

/**
 * Track print progress above 4 (user waiting more than 2 minutes).
 *
 * @function
 * @param  {Observable<Action>} action$
 * @return {Observable<Action>}
 */
export const onPrintProgressActionEpic = (
  action$: Observable<Action>,
): Observable<Action> =>
  action$.pipe(
    filter(
      (action: Action) => action.type === 'UPDATE_PRINT_PROGRESS',
    ),
    tap((action) => {
      if (action.meta && (action.meta as any).progress > 3) {
        if (bugsnagService) {
          bugsnagService.notify(
            new Error(`Print slowness ${action.meta.progress}`),
            {
              action,
            },
          );
        }
      }
    }),
    ignoreElements(),
    catchError((err, source$: Observable<Action>) =>
      concat(
        of({
          type: 'EPIC_FAIL_BUGSNAG_PRINT_PROGRESS',
          payload: err,
        }),
        source$,
      ),
    ),
  );
