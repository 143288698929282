/**
 * Labstep
 *
 * @module core/Toast
 * @desc Displays a toast
 */

import cx from 'classnames';
import Icon from 'labstep-web/core/Icon';
import React from 'react';
import styles from './styles.module.scss';
import { ToastProps } from './types';

const infoType = {
  header: 'Info',
  color: null,
};

const successType = {
  header: null,
  color: null,
};

const errorType = {
  header: 'Oops',
  color: 'white',
};

const availableTypes = {
  success: successType,
  error: errorType,
  info: infoType,
};

const Toast: React.FC<ToastProps> = ({ message, options, close }) => {
  // const selectedType = availableTypes[options.type] || infoType;

  const { header } = options;

  return (
    <div className={cx(styles.container, styles[options.type])}>
      <div className={styles.messageContainer}>
        <div className={styles.message}>
          {header && (
            <p>
              <b>{header}</b>
            </p>
          )}
          <span>{message}</span>
        </div>
      </div>
      <div className={cx(styles.closeContainer)}>
        <Icon link name="close" onClick={close} />
      </div>
    </div>
  );
};

export default Toast;
