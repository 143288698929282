/**
 * Labstep
 */

import { GridCreateUsers } from 'labstep-web/grid/CreateUsers';
import { ReadOnMountHOC } from 'labstep-web/hoc/ReadOnMount';
import { OrganizationPlan } from 'labstep-web/models/organization-plan.model';
import React from 'react';
import { CreateUsersProps } from './types';

export const CreateUsersContainer: React.FC<CreateUsersProps> = ({
  organization,
  ...rest
}) =>
  organization.is_sync_stripe_enabled ? (
    <ReadOnMountHOC
      type="entity"
      id={organization.id}
      entityName={OrganizationPlan.entityName}
      loading={{
        cached: true,
        loader: 'placeholder',
      }}
      children={({ entity }) => (
        <GridCreateUsers
          organization={organization}
          organizationPlan={entity}
          {...rest}
        />
      )}
    />
  ) : (
    <GridCreateUsers organization={organization} {...rest} />
  );

export default CreateUsersContainer;
