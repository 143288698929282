/**
 * Labstep
 *
 * @module prosemirror/marks/italic
 * @desc Italic mark
 */

import { LabstepMark } from 'labstep-web/prosemirror/marks/types';
import { toggleMark } from 'prosemirror-commands';

const MarkItalic: LabstepMark = {
  key: 'em',
  spec: {
    toDOM() {
      return ['em', 0];
    },
    parseDOM: [
      { tag: 'i' },
      { tag: 'em' },
      { style: 'font-style=italic' },
    ],
    shortcut: 'Mod-i',
    toolbarIcon: 'italic',
  },
  keymap: {
    'Mod-i': (state, dispatch) =>
      toggleMark(state.schema.marks[MarkItalic.key], {})(
        state,
        dispatch,
      ),
  },
  toolbarIcon: 'italic',
};

export default MarkItalic;
