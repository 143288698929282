/**
 * Labstep
 *
 * @module components/Thread/Form
 * @desc Comment form + comment list
 */

import CommentEmptyState from 'labstep-web/components/Comment/Card/EmptyState';
import CreateCommentForm from 'labstep-web/components/Comment/Form/Create';
import CommentList from 'labstep-web/components/Comment/List';
import React from 'react';
import styles from './styles.module.scss';
import {
  IThreadFormProps,
  IThreadFormViewComponentProps,
} from './types';

export const scrollFunction = () => {
  const mainContentChildrenElement =
    document.getElementById('right-pane-body');
  if (!mainContentChildrenElement) {
    return;
  }
  mainContentChildrenElement.scrollTop =
    mainContentChildrenElement.scrollHeight;
};

export const scrollToBottom = () => {
  setTimeout(scrollFunction, 0);
};

const onSuccess = scrollToBottom;

export const ThreadFormViewComponent: React.FC<
  IThreadFormViewComponentProps
> = ({
  createThreadId,
  readThreadIds,
  experimentWorkflow,
  entityNameLabel,
  header,
  noCommentsMessage,
  showCreateComment = true,
}) => (
  <div className={styles.container} id="thread">
    <div>
      <CommentList
        noCommentsMessage={noCommentsMessage || <CommentEmptyState />}
        threadId={readThreadIds}
        experimentWorkflow={experimentWorkflow}
        header={header}
      />
    </div>
    {showCreateComment && (
      <div className={styles.create}>
        <CreateCommentForm
          onSuccess={onSuccess}
          threadId={createThreadId}
          placeholder={`Add your ${entityNameLabel}`}
        />
      </div>
    )}
  </div>
);

export const ThreadForm: React.FC<IThreadFormProps> = (props) => {
  scrollToBottom();
  return <ThreadFormViewComponent {...props} />;
};

export default ThreadForm;
