/**
 * Labstep
 *
 * @module components/Metadata/Detail
 * @desc Detail for Metadata Modal
 */

import ProtocolConditionActionCompareData from 'labstep-web/components/ProtocolCondition/Action/CompareData';
import { useProtocolConditionModalContext } from 'labstep-web/components/ProtocolCondition/Modal/context';
import Collapsible from 'labstep-web/core/Collapsible';
import Divider from 'labstep-web/core/Divider';
import Flex from 'labstep-web/core/Flex';
import Icon from 'labstep-web/core/Icon';
import React from 'react';
import { useLocation } from 'react-router-dom';
import MetadataVariableDetail from '../Variable/Detail';
import MetadataDetailTableSettings from './Table/Settings';
import MetadataDetailValue from './Value';
import { MetadataDetailProps } from './types';

export const MetadataDetail: React.FC<MetadataDetailProps> = ({
  metadata,
  entity,
}) => {
  const { state } = useLocation();
  const { isOpen } = useProtocolConditionModalContext();
  const settings = (
    <MetadataDetailTableSettings
      metadata={metadata}
      entity={entity}
    />
  );
  return (
    <Flex column grow style={{ justifyContent: 'space-around' }}>
      <Flex column grow>
        {isOpen ? (
          settings
        ) : (
          <Collapsible
            label={(expanded): React.ReactElement => (
              <span>
                <Icon name="cog" />
                {`${expanded ? 'Hide' : 'Show'} Settings`}
              </span>
            )}
            component={settings}
            // true when metadata first created
            initialExpandedState={(state as any)?.expanded}
            withChevron={false}
          />
        )}
        <Divider />
        {metadata.is_variable ? (
          <MetadataVariableDetail
            protocol={entity}
            metadata={metadata}
          />
        ) : (
          <MetadataDetailValue entity={entity} metadata={metadata} />
        )}
      </Flex>
      <ProtocolConditionActionCompareData
        actionComponentType="button"
        metadata={metadata}
      />
    </Flex>
  );
};

export default MetadataDetail;
