/**
 * Labstep
 *
 * @module grid/SmartTable
 * @desc Smart Table (still called Conditions Table)
 * @url  experiment-workflow/:experimentWorkflowId/conditions
 */

import { useHasAccessCreate } from 'labstep-web/components/Entity/Can';
import EntitySearch from 'labstep-web/components/Entity/Search';
import GridIndex from 'labstep-web/grid/Index';
import { GridSmartTableEmptyState } from 'labstep-web/grid/SmartTable/EmptyState';
import { ProtocolCondition } from 'labstep-web/models/protocol-condition.model';
import React from 'react';
import { GridSmartTableProps } from './types';

export const GridSmartTable: React.FC<GridSmartTableProps> = ({
  protocol,
  params,
  emptyState: emptyStateComponent,
  isCursor,
  ...rest
}) => {
  const canEdit = useHasAccessCreate(
    ProtocolCondition.entityName,
    protocol.permission_entity_info?.entityName,
    protocol.permission_entity_info?.id,
  );

  return (
    <EntitySearch
      entityName={ProtocolCondition.entityName}
      params={params}
      hideFilters
      showEmptyStateOnSearchOnly
      useAllPages={!!isCursor}
    >
      {(containerProps): React.ReactElement => {
        // show both the empty state and the data grid
        const emptyState =
          containerProps.entities.length === 0
            ? emptyStateComponent
            : undefined;

        const dataGridProps = { ...rest };
        if (emptyState) {
          // remove the height from the dataGridProps
          dataGridProps.height = undefined;
        }

        return (
          <>
            <GridIndex
              // need to add a key to force a re-render
              // otherwise the height will not be updated
              key={emptyState ? 0 : 1}
              {...containerProps}
              {...dataGridProps}
              suppressMovableColumns
              editable={canEdit}
              loadMore={containerProps.readNextPage}
            />
            <GridSmartTableEmptyState emptyState={emptyState} />
          </>
        );
      }}
    </EntitySearch>
  );
};

export default GridSmartTable;
