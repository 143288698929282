/**
 * Labstep
 */

import { Action } from 'labstep-web/models/action.model';
import { nameOfChildKey } from 'labstep-web/services/schema/helpers';
import {
  arrayify,
  filterById,
  isPaginated,
  jsonParse,
} from 'labstep-web/state/reducers/helpers/utils';

/**
 * Removes entity id from array of all parents that hold this entity
 *
 * FIXME Should handle removing from files
 *
 * @function
 * @param  {object} state - Redux state
 * @param  {string} action - Redux action
 * @param  {object} state
 * @return {object} - Updated state
 */
export const removeChildEntityFromParents = (
  state: any,
  action: any,
  entityName: string,
) =>
  Object.keys(state).reduce((result: any, key: string) => {
    const childKey = nameOfChildKey(
      entityName,
      action.meta.entityName,
    );

    // FIXME JD: this is a hack for OneToOne associations
    if (!childKey && entityName === 'user_group') {
      const updatedParent = {
        ...state[key],
        [action.meta.entityName]: null,
      };
      return { ...result, [key]: updatedParent };
    }
    if (!state[key][childKey]) {
      return { ...result, [key]: state[key] };
    }

    // TODO: Write test. This is to reduce the number of count when deleting an entity.
    // This was written specifically for comments. We should harmonize the count key
    // on the backend.
    const shouldFilter = Boolean(
      state[key][childKey].find(
        (id: number) => action.meta && id === action.meta.identifier,
      ),
    );
    const updateCount =
      shouldFilter && state[key][`${action.meta.entityName}_count`]
        ? state[key][`${action.meta.entityName}_count`] - 1
        : state[key][`${action.meta.entityName}_count`];

    return {
      ...result,
      [key]: {
        ...state[key],
        [childKey]: state[key][childKey].filter(
          (id: number) =>
            action.meta && id !== action.meta.identifier,
        ),
        [`${action.meta.entityName}_count`]: updateCount,
      },
    };
  }, {});

/**
 * Removes id from array of children
 *
 * @function
 * @param  {object} state - Redux state
 * @param  {number} childId - Id to be removed
 * @param  {string} childrenArrayName - Name of array that holds the children (e.g. comments)
 * @return {object} - Updated Redux state
 */
export const removeChild = (
  state: any,
  childId: number | number[],
  childrenArrayName: string,
) => {
  // batch remove children
  const childIds = arrayify(childId);
  return Object.keys(state).reduce((result, key) => {
    if (!state[key][childrenArrayName]) {
      return { ...result, [key]: state[key] };
    }

    return {
      ...result,
      [key]: {
        ...state[key],
        [childrenArrayName]: state[key][childrenArrayName].filter(
          (id: number) => !childIds.includes(id),
        ),
      },
    };
  }, {});
};

/**
 * Remove one to one
 *
 * @function
 * @param  {object} state - Redux state
 * @param  {object} action - Redux action
 * @param  {string} entityName - Entity name
 * @return {object} - Updated Redux state
 */
export const removeOneToOne = (
  state: any,
  action: Action,
  childKeyName?: string,
) => {
  return Object.keys(state).reduce((result, key) => {
    const childKey = childKeyName || action.meta.entityName;
    if (state[key][childKey] !== action.meta.identifier) {
      return { ...result, [key]: state[key] };
    }

    return {
      ...result,
      [key]: {
        ...state[key],
        [childKey]: null,
      },
    };
  }, {});
};

/**
 * This works only for many to many (group - user)
 *
 * @function
 * @param  {object} state - Redux state (only the portion for this reducer)
 * @param  {string} action - Redux action
 * @return {object}
 */
export const readRemoveManyToManyParent = (
  state: any,
  action: Action,
) =>
  Object.keys(state).reduce((result: any, key: string) => {
    const keyState = state[key];
    const { parentId, entityIds, entityName } = action.meta;
    const params = jsonParse(key);

    if (typeof params !== 'object') {
      return { ...result, [key]: keyState };
    } // Single id

    const entityNameKey = `${entityName}_id`;
    const isNotRelevant =
      Number(params[entityNameKey]) !== Number(entityIds);

    if (
      isNotRelevant &&
      !key.includes(`ROLE_${entityName}_${entityIds}`.toUpperCase())
    ) {
      return { ...result, [key]: keyState };
    }

    if ('totalPages' in keyState) {
      return {
        ...result,
        [key]: {
          ...keyState,
          pages: Object.keys(keyState.pages).reduce(
            (pagesResult, page) => {
              const pageState = keyState.pages[page];
              const items = pageState.items.filter(
                (id) => arrayify(parentId).indexOf(id) === -1,
              );
              return {
                ...pagesResult,
                [page]: {
                  ...pageState,
                  items,
                },
              };
            },
            {},
          ),
        },
      };
    }

    const items = keyState.items.filter(
      (item: any) => item !== action.meta.parentId,
    );

    const total = keyState.total
      ? keyState.total - (keyState.items.length - items.length)
      : keyState.total;

    return {
      ...result,
      [key]: {
        ...keyState,
        items,
        total,
      },
    };
  }, {});

/**
 * This works only for one to many
 *
 * @function
 * @param  {object} state - Redux state (only the portion for this reducer)
 * @param  {string} action - Redux action
 * @return {object}
 */
export const readRemoveOneToManyParent = (
  state: any,
  action: Action,
) => {
  return Object.keys(state).reduce((result: any, key: string) => {
    const keyState = state[key];
    const params = jsonParse(key);
    const { parentName } = action.meta;
    let { entityIds } = action.meta;

    if (!isPaginated(keyState, params)) {
      return { ...result, [key]: keyState };
    }
    if (
      ['tag', 'folder'].indexOf(action.meta.entityName) > -1 &&
      params[`${parentName}_id`] !== action.meta.parentId
    ) {
      return { ...result, [key]: keyState };
    }

    if (
      !(
        key.includes(`${parentName}`) ||
        action.type === 'SUCCESS_REMOVE_GROUP_FROM_USER'
      )
    ) {
      if (action.meta.entityName === 'permission') {
        entityIds = action.meta.permissionParentEntityId;
      } else {
        return { ...result, [key]: keyState };
      }
    }

    if ('totalPages' in keyState) {
      return {
        ...result,
        [key]: {
          ...keyState,
          pages: Object.keys(keyState.pages).reduce(
            (pagesResult, page) => {
              const pageState = keyState.pages[page];
              return {
                ...pagesResult,
                [page]: {
                  ...pageState,
                  items: pageState.items.filter(
                    (id) => arrayify(entityIds).indexOf(id) === -1,
                  ),
                },
              };
            },
            {},
          ),
        },
      };
    }

    const items = keyState.items.filter(
      (id) => arrayify(entityIds).indexOf(id) === -1,
    );

    const total = keyState.total
      ? keyState.total - (keyState.items.length - items.length)
      : keyState.total;

    return {
      ...result,
      [key]: {
        ...keyState,
        items,
        total,
      },
    };
  }, {});
};

export const removeEntityFromFolderItems = (state, action) => {
  return Object.keys(state).reduce((result: any, key: string) => {
    const keyState = state[key];
    const params = jsonParse(key);
    const { parentId } = action.meta;

    if (!params.folder_group_not_id) {
      return { ...result, [key]: keyState };
    }
    // Remove id from paginated items array
    if ('totalPages' in keyState) {
      let { total } = keyState;
      const newPageState = { ...keyState };
      newPageState.pages = Object.keys(newPageState.pages).reduce(
        (pageResult, pageKey) => {
          const { items } = newPageState.pages[pageKey];
          const filteredItems = items.filter(
            (id) => arrayify(parentId).indexOf(id) === -1,
          );
          total -= items.length - filteredItems.length;

          return {
            ...pageResult,
            [pageKey]: {
              ...newPageState.pages[pageKey],
              items: filteredItems,
            },
          };
        },
        {},
      );

      return {
        ...result,
        [key]: {
          ...newPageState,
          total,
        },
      };
    }
    return { ...result, [key]: keyState };
  }, {});
};

/**
 * Remove entity id from any items array that this id exists
 *
 * @function
 * @param  {object} state - Redux state (only the portion for this reducer)
 * @param  {string} action - Redux action
 * @param  {function} filter - Apply filter to state objects
 * @return {object}
 */
export const filterOutEntitiesFromItems = (
  state: any,
  action: Action,
  filter?: (keyState, params) => boolean,
) =>
  Object.keys(state).reduce((result: any, key: string) => {
    const keyState = state[key];
    const params = jsonParse(key);

    if (filter && !filter(keyState, params)) {
      return { ...result, [key]: keyState };
    }

    if (!isPaginated(keyState, params)) {
      return { ...result, [key]: keyState };
    }

    // Remove id from paginated items array
    if ('totalPages' in keyState) {
      let { total } = keyState;
      const newPageState = { ...keyState };
      newPageState.pages = Object.keys(newPageState.pages).reduce(
        (pageResult, pageKey) => {
          const { items } = newPageState.pages[pageKey];
          const filteredItems = items.filter((id) =>
            filterById(action, id),
          );
          total -= items.length - filteredItems.length;

          return {
            ...pageResult,
            [pageKey]: {
              ...newPageState.pages[pageKey],
              items: filteredItems,
            },
          };
        },
        {},
      );

      return {
        ...result,
        [key]: {
          ...newPageState,
          total,
        },
      };
    }

    const items = keyState.items.filter((item: any) =>
      filterById(action, item),
    );

    // Remove from total the number of items filtered out
    const difference = keyState.items.length - items.length;
    const total = keyState.total - difference;

    return {
      ...result,
      [key]: {
        ...keyState,
        items,
        total,
      },
    };
  }, {});
