/**
 * Labstep
 *
 * @module components/Metadata/DataGrid/coldefs/rowNumber
 * @desc Column definition for metadata with row number
 */

import EntityActionDelete from 'labstep-web/components/Entity/Action/Delete';
import { IDeletableEntity } from 'labstep-web/components/Entity/Action/Delete/types';
import ProtocolConditionActionViewVariablesTable from 'labstep-web/components/ProtocolCondition/Action/ViewVariablesTable';
import {
  ColDef,
  ColDefParams,
} from 'labstep-web/core/DataGrid/types';
import rowNumber from 'labstep-web/grid/Index/coldefs/Resource/rowNumber';
import { Experiment } from 'labstep-web/models/experiment.model';
import { Metadata } from 'labstep-web/models/metadata';
import { ProtocolCondition } from 'labstep-web/models/protocol-condition.model';
import { Protocol } from 'labstep-web/models/protocol.model';

/**
 * Row number column for metadata with delete protocol condition
 * @param protocol Experiment or Protocol
 * @returns Column definition
 */
const colDefMetadataRowNumber = (
  protocol: Experiment | Protocol,
  getNestedEntity?: <T>(params: ColDefParams<T>) => IDeletableEntity,
): ColDef<Metadata> =>
  ({
    ...rowNumber,
    maxWidth: 60,
    cellRendererParams: (params) => ({
      props: {
        action: (
          <EntityActionDelete
            entity={
              getNestedEntity ? getNestedEntity(params) : params.data
            }
            actionComponentProps={{ type: 'option' }}
            confirmMessage={`Are you sure you want to delete this ${ProtocolCondition.getHumanReadableEntityName()}? There are ${
              protocol.protocol_condition_variables.length - 1
            } other variable fields for this condition that may be affected.`}
          />
        ),
      },
    }),
    headerComponentParams: {
      action: (
        <ProtocolConditionActionViewVariablesTable
          actionComponentProps={{
            type: 'icon',
            icon: 'external',
            elementProps: {
              asLink: true,
            },
          }}
          protocol={protocol}
        />
      ),
      displayName: null,
    },
  }) as ColDef<Metadata>;

export default colDefMetadataRowNumber;
