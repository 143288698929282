/**
 * Labstep
 *
 * @module screens/ProtocolCollection/Edit/Right/Timers
 * @desc Protocol Timers
 */

import ProtocolTimerActionCreateForm from 'labstep-web/components/ProtocolTimer/Action/Create/Form';
import ProtocolTimerList from 'labstep-web/components/ProtocolTimer/List';
import EmptyState from 'labstep-web/core/Card/EmptyState';
import { ProtocolCollection } from 'labstep-web/models/protocol-collection.model';
import { getHumanReadableEntityName } from 'labstep-web/services/i18n.service';
import React from 'react';
import { IProtocolTimerManagerProps } from './types';

const ProtocolTimerManager: React.FC<IProtocolTimerManagerProps> = ({
  protocol,
}) => {
  const action = (
    <ProtocolTimerActionCreateForm
      protocol={protocol}
      actionComponentProps={{ type: 'button' }}
    />
  );

  const protocolTimers = protocol.protocol_timers.filter(
    (p) => !p.deleted_at,
  );

  return (
    <>
      {protocolTimers.length === 0 ? (
        <EmptyState
          src="/img/empty-state/timers.svg"
          action={action}
          explanation={
            <div>
              {`Add timers to your ${getHumanReadableEntityName(
                ProtocolCollection.entityName,
              )} and track time-dependent actions each time you follow the ${getHumanReadableEntityName(
                ProtocolCollection.entityName,
              )}.`}
            </div>
          }
        />
      ) : (
        <div>
          {action}
          <ProtocolTimerList
            protocolTimers={protocolTimers}
            protocol={protocol}
          />
        </div>
      )}
    </>
  );
};

export default ProtocolTimerManager;
