/**
 * Labstep
 *
 * @module components/Device/ListOrTable
 * @desc List or table of devices
 */

import DeviceList from 'labstep-web/components/Device/List';
import DeviceGrid from 'labstep-web/grid/IndexDevice';
import MasterIndexCenterListOrTable from 'labstep-web/screens/Master/Index/Center/ListOrTable';
import React from 'react';
import { actions } from './constants';
import { DeviceListOrTableProps } from './types';

export const DeviceListOrTable: React.FC<DeviceListOrTableProps> = ({
  devices,
  ...rest
}) => (
  <MasterIndexCenterListOrTable
    tableComponent={<DeviceGrid devices={devices} {...rest} />}
    listComponent={<DeviceList devices={devices} actions={actions} />}
  />
);

export default DeviceListOrTable;
