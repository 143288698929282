/**
 * Labstep
 *
 * @module components/ProtocolCollection/Action/Create
 * @desc Create Action for protocol
 */

import EntityActionCreate from 'labstep-web/components/Entity/Action/Create';
import { ProtocolCollection } from 'labstep-web/models/protocol-collection.model';
import { IRouting } from 'labstep-web/services/navigation.types';
import React from 'react';
import { IProtocolCollectionActionCreateProps } from './types';

export const ProtocolCollectionActionCreate: React.FC<
  IProtocolCollectionActionCreateProps
> = ({ body = {}, actionComponentProps }) => {
  const { entityName } = ProtocolCollection;

  return (
    <EntityActionCreate
      entityName={entityName}
      actionComponentProps={{
        type: 'button',
        ...actionComponentProps,
      }}
      redirectRoute={({ response }) => ({
        to: `${entityName}_show` as keyof IRouting,
        params: { id: response.result },
      })}
      body={body}
    />
  );
};
