/**
 * Labstep
 *
 * @module grid/SmartTableProtocolCondition
 * @desc Protocol Condition SmartTable
 */

import ProtocolConditionEmptyState from 'labstep-web/components/ProtocolCondition/EmptyState';
import { useProtocolConditionModalContext } from 'labstep-web/components/ProtocolCondition/Modal/context';
import { IDataGridProps } from 'labstep-web/core/DataGrid/types';
import GridSmartTable from 'labstep-web/grid/SmartTable';
import { getColDefs } from 'labstep-web/grid/SmartTable/coldefs';
import { ReadOnMountHOC } from 'labstep-web/hoc/ReadOnMount';
import { Metadata } from 'labstep-web/models/metadata';
import { ProtocolValue } from 'labstep-web/models/protocol-value.model';
import React, { useMemo } from 'react';
import { GridSmartTableProtocolConditionProps } from './types';
import { getReadProps, getStatusBarChildren } from './utils';

export const GridSmartTableProtocolCondition: React.FC<
  GridSmartTableProtocolConditionProps
> = ({ protocol, params, read, ...rest }) => {
  const context = useProtocolConditionModalContext();

  const colDefs: NonNullable<IDataGridProps['columnDefs']> = useMemo(
    (): NonNullable<IDataGridProps['columnDefs']> =>
      getColDefs(protocol, context),
    [protocol],
  );

  return (
    <ReadOnMountHOC
      {...getReadProps(protocol, ProtocolValue.entityName)}
    >
      {({
        entities: protocolValueVariables,
      }: {
        entities: ProtocolValue[];
      }): JSX.Element => (
        <ReadOnMountHOC
          {...getReadProps(protocol, Metadata.entityName)}
        >
          {(): JSX.Element => (
            <GridSmartTable
              columnDefs={colDefs}
              key={protocolValueVariables.length}
              protocol={protocol}
              params={{
                [`${protocol.entityName}_guid`]: protocol.guid,
                ...params,
              }}
              statusBarChildren={getStatusBarChildren({
                protocol,
                protocolValueVariables,
              })}
              readOn={[
                JSON.stringify(protocol.protocol_condition_variables), // variable order changed
              ]}
              emptyState={
                <ProtocolConditionEmptyState protocol={protocol} />
              }
              onCellValueChanged={(e) => {
                e.api.refreshCells({
                  rowNodes: [e.node],
                  force: true,
                  suppressFlash: true,
                });
                e.api.refreshHeader();
              }}
              {...rest}
            />
          )}
        </ReadOnMountHOC>
      )}
    </ReadOnMountHOC>
  );
};

export default GridSmartTableProtocolCondition;
