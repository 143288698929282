/**
 * Labstep
 *
 * @module components/JupyterNotebook/Modal/Edit
 * @desc JupyterNotebook Modal
 */

import JupyterNotebookContent from 'labstep-web/components/JupyterNotebook/Content';
import JupyterNotebookFormShowEditName from 'labstep-web/components/JupyterNotebook/Form/ShowEdit/Name';
import Modal from 'labstep-web/core/Modal/Default';
import React from 'react';
import { JupyterNotebookModalEditProps } from './types';

export const JupyterNotebookModalEdit: React.FC<
  JupyterNotebookModalEditProps
> = ({ jupyterNotebook, viewComponent, onClose, open }) => (
  <Modal
    size="fullscreen"
    open={open}
    onClose={onClose}
    header={
      <JupyterNotebookFormShowEditName
        jupyterNotebook={jupyterNotebook}
      />
    }
    content={
      <JupyterNotebookContent jupyterNotebook={jupyterNotebook} />
    }
    viewComponent={viewComponent}
  />
);

export default JupyterNotebookModalEdit;
