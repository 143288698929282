/**
 * Labstep
 *
 * @module core/AutoSave/Spinner
 * @desc Autosave spinner feature
 */

import Loader from 'labstep-web/core/Loader';
import React from 'react';
import { getStatusMessage } from '../utils';
import { IAutoSaveSpinnerProps } from './types';

export const AutoSaveSpinner: React.FC<IAutoSaveSpinnerProps> = ({
  status,
}) =>
  status &&
  (status.isFetching ? (
    <Loader size="tiny" inline active />
  ) : (
    <span style={{ marginRight: '5px' }}>
      {getStatusMessage(status)}
    </span>
  ));

export default AutoSaveSpinner;
