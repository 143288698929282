/**
 * Labstep
 *
 * @module components/PurchaseOrder/Action/Menu
 * @desc Action Menu for purchaseOrder
 */

import EntityUserActionManage from 'labstep-web/components/EntityUser/Action/Manage';
import PurchaseOrderActionDelete from 'labstep-web/components/PurchaseOrder/Action/Delete';
import PurchaseOrderActionPrint from 'labstep-web/components/PurchaseOrder/Action/Print';
import PurchaseOrderActionView from 'labstep-web/components/PurchaseOrder/Action/View';
import ActionMenu from 'labstep-web/core/Action/Menu';
import React from 'react';
import { Action, IPurchaseOrderActionMenuProps } from './types';

export const PurchaseOrderActionMenu: React.FC<
  IPurchaseOrderActionMenuProps
> = ({ purchaseOrder, type, actions = [] }) => (
  <ActionMenu type={type}>
    {actions.includes(Action.view) && (
      <PurchaseOrderActionView purchaseOrder={purchaseOrder} />
    )}
    {actions.includes(Action.print) && (
      <PurchaseOrderActionPrint purchaseOrder={purchaseOrder} />
    )}
    {actions.includes(Action.assign) && (
      <EntityUserActionManage entity={purchaseOrder} />
    )}
    {actions.includes(Action.delete) && (
      <PurchaseOrderActionDelete purchaseOrder={purchaseOrder} />
    )}
  </ActionMenu>
);

PurchaseOrderActionMenu.defaultProps = {
  actions: [],
  type: 'icon',
};

export default PurchaseOrderActionMenu;
