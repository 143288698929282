/**
 * Labstep
 */

import { ActionMenuProps } from 'labstep-web/core/Action/Menu/types';
import { File } from 'labstep-web/models/file.model';

// eslint-disable-next-line no-shadow
export enum Action {
  download = 'download',
  preview = 'preview',
  delete = 'delete',
}

export interface FileActionMenuProps {
  file: File;
  actions: Action[];
  type?: ActionMenuProps['type'];
}
