/**
 * Labstep
 *
 * @module components/ProtocolCollection/Filter/Status/Active
 * @desc Active filter component for status
 */

import RemoveLabel from 'labstep-web/core/Label/Remove';
import React from 'react';
import { ProtocolCollectionStatuses } from '../Select';
import { IProtocolCollectionFilterStatusActiveProps } from './types';

const ProtocolCollectionFilterStatusActive: React.FC<
  IProtocolCollectionFilterStatusActiveProps
> = ({ clearParams, searchParams }) => {
  const activeStatus = Object.values(ProtocolCollectionStatuses).find(
    (value) => {
      return (
        searchParams.has_last_version ===
          (value.params.has_last_version ? 'true' : undefined) &&
        searchParams.has_draft_version ===
          (value.params.has_draft_version ? 'true' : undefined)
      );
    },
  );

  if (activeStatus) {
    return (
      <RemoveLabel
        icon={activeStatus.icon}
        name={activeStatus.text}
        onRemoveClick={
          clearParams &&
          (() => clearParams(Object.keys(activeStatus.params)))
        }
      />
    );
  }

  return null;
};

export default ProtocolCollectionFilterStatusActive;
