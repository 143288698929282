/**
 * Labstep
 *
 * @module models/metadata-thread
 * @desc Typescript export class for MetadataThread
 */

import { Type } from 'class-transformer';
import { Entity } from 'labstep-web/models/entity.model';
import { ExperimentWorkflow } from 'labstep-web/models/experiment-workflow.model';
import { Metadata } from 'labstep-web/models/metadata';
import { Experiment } from './experiment.model';
import { Protocol } from './protocol.model';

export class MetadataThread extends Entity {
  static readonly entityName = 'metadata_thread';

  get entityName(): string {
    return MetadataThread.entityName;
  }

  constructor(data: Partial<MetadataThread> = {}) {
    super();
    Object.assign(this, data);
  }

  id!: number;

  metadata_count!: number;

  @Type(() => Metadata)
  metadatas!: Metadata[];

  @Type(() => Experiment)
  experiment!: Experiment;

  @Type(() => Protocol)
  protocol!: Protocol;

  @Type(() => ExperimentWorkflow)
  experiment_workflow!: ExperimentWorkflow;

  @Type(() => Metadata)
  metadatas_preview!: Metadata[];

  get nonDeletedMetadatas(): Metadata[] {
    return this.metadatas.filter((m) => !m.deleted_at);
  }
}
