import { ParamsHOCChildrenProps } from 'labstep-web/hoc/Params/types';
import pickBy from 'lodash/pickBy';

// eslint-disable-next-line no-shadow
export enum PostFilterComparisonHumanReadable {
  eq = 'equals',
  neq = 'does not equal',
  lte = 'less than or equal to',
  lt = 'less than',
  gte = 'greater than or equal to',
  gt = 'greater than',
  null = 'is empty',
  not_null = 'is not empty',
  contains = 'includes',
  not_contains = 'excludes',
}

// eslint-disable-next-line no-shadow
export enum PostFilterComparisonHumanReadableChronologic {
  eq = 'equals',
  neq = 'does not equal',
  lte = 'before or equal to',
  lt = 'before',
  gte = 'after or equal to',
  gt = 'after',
  null = 'is empty',
  not_null = 'is not empty',
  contains = 'includes',
  not_contains = 'excludes',
}

// eslint-disable-next-line no-shadow
export enum PostFilterComparisonHumanReadableShort {
  eq = 'is',
  neq = 'is not',
  lte = '≤',
  lt = '<',
  gte = '≥',
  gt = '>',
  null = 'is empty',
  not_null = 'is not empty',
  contains = 'includes',
  not_contains = 'excludes',
}

// eslint-disable-next-line no-shadow
export enum PostFilterOperator {
  and = 'and',
  or = 'or',
}

export interface PostFilterPredicateType {
  json_path?: string;
  attribute: string;
  comparison: PostFilterComparison;
  value?: string | number | boolean;
}

export interface PostFilterNodeType {
  type: PostFilterOperator;
  path?: string;
  predicates: PostFilterPredicateType[]; // | PostFilterNodeType[] but we only allow depth of 1 for now
}

export type PostFilterBranchType = 0 | 1;

export type PostFilterType = [
  // user-controlled branch
  {
    type: PostFilterOperator;
    predicates: PostFilterNodeType[];
  },
  // system-controlled branch
  {
    type: PostFilterOperator;
    predicates: PostFilterNodeType[];
  },
];

// eslint-disable-next-line no-shadow
export enum PostFilterComparison {
  eq = 'eq',
  neq = 'neq',
  lte = 'lte',
  lt = 'lt',
  gte = 'gte',
  gt = 'gt',
  null = 'null',
  not_null = 'not_null',
  contains = 'contains',
  not_contains = 'not_contains',
}

export interface IPostFilterParameters {
  filter?: PostFilterType[0][];
}

export class QueryParameterService {
  /**
   * Returns a predicate value from a post filter in parameters
   * @param parameters: IPostFilterParameters
   * @param path: string
   * @returns string | number | boolean | null
   */
  static getFilterNodeValue(
    parameters: IPostFilterParameters,
    path: string,
  ): string | number | boolean | null {
    if (
      !parameters ||
      !parameters.filter ||
      !parameters.filter.length ||
      !parameters.filter[0]
    ) {
      return null;
    }
    const topLevelPredicates = parameters.filter[0].predicates;

    let value = null;
    topLevelPredicates.forEach((node) => {
      if (node.predicates && node.path === path) {
        node.predicates.forEach((predicate) => {
          value = predicate.value;
        });
      }
    });

    return value;
  }

  /**
   * Checks if a value is valid
   * @param value unknown
   * @returns boolean
   */
  static valueIsValid(value: unknown): boolean {
    return value !== undefined && value !== '' && value !== null;
  }

  /**
   * We clear empty values i.e. when removing a value from a search select
   * We keep filters that don't have a value by default
   * i.e. have a comparison of null or not_null
   * @param predicate PostFilterPredicateType
   * @returns boolean
   */
  static predicateIsValid(
    predicate: PostFilterPredicateType,
  ): boolean {
    if (
      predicate.comparison !== PostFilterComparison.null &&
      predicate.comparison !== PostFilterComparison.not_null
    ) {
      const { value } = predicate;
      return value !== undefined && value !== '' && value !== null;
    }
    return true;
  }

  /**
   * Sanitizes a node by removing predicates that are invalid
   * And removing the node if it has no predicates
   * @param node PostFilterNodeType
   * @returns PostFilterNodeType | null
   */
  static sanitizeNode(
    node: PostFilterNodeType,
  ): PostFilterNodeType | null {
    const newPostFilter = { ...node };
    const predicates: PostFilterPredicateType[] = [];
    node.predicates.forEach((p) => {
      if (QueryParameterService.predicateIsValid(p)) {
        predicates.push(p);
      }
    });
    if (!predicates.length) {
      return null;
    }
    newPostFilter.predicates = predicates;
    return newPostFilter;
  }

  /**
   * Sanitizes filter by removing nodes that are invalid
   * And removing the filter if it has no nodes
   * @param filter Filter
   * @returns Filter
   */
  static sanitizeFilter(
    filter: PostFilterType | undefined,
  ): PostFilterType | undefined {
    if (!filter) {
      return undefined;
    }
    const sanitizedFilter = filter.map((f) => {
      const { type, predicates } = f;
      const sanitizedNodes = predicates.map(
        QueryParameterService.sanitizeNode,
      );
      // null if the node has no predicates
      const sanitizedNodesWithoutNull = sanitizedNodes.filter(
        (node) => node !== null,
      ) as PostFilterNodeType[];
      return { type, predicates: sanitizedNodesWithoutNull };
    }) as PostFilterType;

    if (sanitizedFilter.every((f) => f.predicates.length === 0)) {
      return undefined;
    }

    return sanitizedFilter;
  }

  /**
   * Sanitizes parameters without a value
   * @param params Parameters
   * @returns Parameters
   */
  static sanitizeParams(
    params: ParamsHOCChildrenProps['searchParams'] | undefined,
  ): ParamsHOCChildrenProps['searchParams'] {
    let sanitizedParams = { ...params };
    sanitizedParams.filter = QueryParameterService.sanitizeFilter(
      sanitizedParams.filter as any,
    );
    sanitizedParams = pickBy(
      sanitizedParams || {},
      QueryParameterService.valueIsValid,
    );

    return sanitizedParams;
  }
}
