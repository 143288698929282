/**
 * Labstep
 *
 * @module core/Button
 * @desc Wrapper around SemanticButton
 */

import cx from 'classnames';
import Link from 'labstep-web/core/Link';
import React from 'react';
import SemanticButton from 'semantic-ui-react/dist/commonjs/elements/Button';
import styles from './styles.module.scss';
import { IButtonProps } from './types';

export const ButtonGroup = SemanticButton.Group;

class Button extends React.Component<IButtonProps> {
  static Group = SemanticButton.Group;

  render() {
    const {
      children,
      status,
      loader = false,
      onClick,
      borderless,
      className,
      border,
      route,
      dataCy,
      ...rest
    } = this.props;
    const isFetching = !!(status && status.isFetching);
    const disabled = isFetching;
    const loading = loader && isFetching;
    const additionalProps = route || {};

    return (
      <SemanticButton
        data-cy={dataCy}
        as={route ? Link : undefined}
        onClick={onClick}
        disabled={disabled}
        className={
          border === 'fancy'
            ? cx(className, styles['fancy-border'])
            : cx(className, { borderless })
        }
        loading={loading}
        {...additionalProps}
        {...rest}
        data-public
      >
        {children}
      </SemanticButton>
    );
  }
}

export default Button;
