/**
 * Labstep
 *
 * @module components/ProtocolTimer/Modal
 * @desc Modal for protocol timer
 */

import Modal from 'labstep-web/core/Modal/Default';
import React from 'react';
import ProtocolTimerModalContent from './Content';
import { IProtocolTimerModalProps } from './types';

export const ProtocolTimerModal: React.FC<
  IProtocolTimerModalProps
> = ({ protocol, protocolTimer, viewComponent }) => (
  <Modal
    header={protocolTimer.name}
    viewComponent={viewComponent}
    content={
      <ProtocolTimerModalContent
        protocol={protocol}
        protocolTimer={protocolTimer}
      />
    }
  />
);

export default ProtocolTimerModal;
