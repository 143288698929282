/**
 * Labstep
 *
 * @module components/NotificationAlert/Details
 * @desc Notificaion alert details with showedit components
 */

import EntityActionUpdateDropdownPill from 'labstep-web/components/Entity/Action/Update/DropdownPill';
import Divider from 'labstep-web/core/Divider';
import { useToggle } from 'labstep-web/hooks/toggle';
import { MetadataType } from 'labstep-web/models/metadata/types';
import React from 'react';
import NotificationAlertActionDelete from '../Action/Delete';
import NotificationAlertFormShowEditMessage from '../Form/ShowEdit/Message';
import NotificationAlertFormShowEditMinutesBefore from '../Form/ShowEdit/MinutesBefore';
import styles from './styles.module.scss';
import { NotificationAlertDetailsProps } from './types';
import {
  DEFAULT_DATETIME_OPTIONS,
  DEFAULT_DATE_OPTIONS,
  convertMinutesBeforeToLabel,
} from './utils';

const NotificationAlertDetails: React.FC<
  NotificationAlertDetailsProps
> = ({ metadata }) => {
  const [isToggled, toggle] = useToggle();
  const notificationAlert = metadata.notification_alert;
  const items =
    metadata.type === MetadataType.date
      ? DEFAULT_DATE_OPTIONS
      : [
          ...DEFAULT_DATETIME_OPTIONS,
          { label: 'Custom', body: {}, onClick: toggle },
        ];
  return (
    <div>
      <p>
        <b>Schedule Alert for</b>
      </p>
      <div>
        {!isToggled && notificationAlert && (
          <div className={styles.timeContainer}>
            <EntityActionUpdateDropdownPill
              entity={notificationAlert}
              items={items}
              currentValue={convertMinutesBeforeToLabel(
                notificationAlert.minutes_before,
                metadata.type,
              )}
            />
            <div>
              <span>{metadata.alertTriggerTime}</span>
            </div>
          </div>
        )}
      </div>
      {isToggled && notificationAlert && (
        <div className={styles.minutesBeforeEditingContainer}>
          <NotificationAlertFormShowEditMinutesBefore
            notificationAlert={notificationAlert}
            onToggle={toggle}
            initialEditingState
          />
          <span> minutes before</span>
        </div>
      )}
      <Divider />
      <div>
        <p>
          <b>Message</b> (Optional)
        </p>
        {notificationAlert && (
          <NotificationAlertFormShowEditMessage
            notificationAlert={notificationAlert}
          />
        )}
      </div>
      <Divider />
      {notificationAlert && (
        <NotificationAlertActionDelete
          notificationAlert={notificationAlert}
          actionComponentProps={{
            type: 'button',
            text: 'Disable',
            elementProps: { basic: true, fluid: true },
          }}
        />
      )}
    </div>
  );
};

export default NotificationAlertDetails;
