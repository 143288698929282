/**
 * Labstep
 *
 * @module state/selectors/ui-persistent
 * @desc Selectors for UI persistent
 */

import { ShareLink } from 'labstep-web/models/share-link.model';
import { selectEntity } from 'labstep-web/state/selectors/entity';
import { createSelector } from 'reselect';

export const getUiPersistent = (state) => state.ui_persistent;

export const getActiveShareLink = (state) =>
  state.ui_persistent.active_share_link
    ? selectEntity(
        state,
        ShareLink.entityName,
        state.ui_persistent.active_share_link,
      )
    : null;

export const selectUiPersistent = createSelector(
  [getUiPersistent, getActiveShareLink],
  (uiPersistent, activeShareLink) => ({
    ...uiPersistent,
    active_share_link: activeShareLink,
  }),
);
