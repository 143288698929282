/**
 * Labstep
 *
 * @module components/EntityExport/Table
 * @desc A table displaying EntityExports
 */

import TableSimple from 'labstep-web/core/Table/Simple';
import { EntityExport } from 'labstep-web/models/entity-export.model';
import { humanReadableDate } from 'labstep-web/services/date.service';
import React from 'react';
import { EntityExportTableProps } from './types';

const EntityExportTable: React.FC<EntityExportTableProps> = ({
  entityExports,
}) => {
  const columns = [
    {
      header: 'Date',
      content: (entityExport: EntityExport) =>
        humanReadableDate(entityExport.created_at),
      cellProps: { flex: 1 },
    },
    {
      header: 'Type',
      content: (entityExport: EntityExport) =>
        entityExport.type.toUpperCase(),
      cellProps: { style: { width: 120 } },
    },
    {
      header: 'User',
      content: (entityExport: EntityExport) =>
        entityExport.author.name,
      cellProps: { flex: 1 },
    },
    {
      header: 'Workspace',
      content: (entityExport: EntityExport) =>
        entityExport.owner.name,
      cellProps: { flex: 1 },
    },
    {
      header: 'IP',
      content: (entityExport: EntityExport) =>
        entityExport.log_entry_create
          ? entityExport.log_entry_create.client_ip
          : '',
      cellProps: { flex: 1 },
    },
    {
      header: '',
      content: (entityExport: EntityExport) =>
        entityExport.log_entry_create
          ? `${entityExport.browser.name} ${entityExport.printDeviceType} ${entityExport.os.name}`
          : '',
      cellProps: { flex: 1 },
    },
  ];
  return <TableSimple rows={entityExports} columns={columns} />;
};

export default EntityExportTable;
