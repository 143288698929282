/**
 * Labstep
 *
 * @module models/molecule
 * @desc Typescript export class for Molecule
 */

import { Type } from 'class-transformer';
import { PubChemMetadata } from 'labstep-web/containers/PubChem/types';
import { Chemical } from 'labstep-web/models/chemical.model';
import { Comment } from 'labstep-web/models/comment.model';
import { Entity } from 'labstep-web/models/entity.model';
import { Experiment } from 'labstep-web/models/experiment.model';
import { Metadata } from 'labstep-web/models/metadata';
import { Protocol } from 'labstep-web/models/protocol.model';

export const REACTION_ARROW = '>>';

export type MoleculeParent =
  | Comment
  | Experiment
  | Metadata
  | Protocol;
export class Molecule extends Entity {
  public static readonly idAttr = 'guid' as const;

  public static readonly entityName = 'molecule';

  public get entityName(): string {
    return Molecule.entityName;
  }

  public constructor(data: Partial<Molecule> = {}) {
    super();
    Object.assign(this, data);
  }

  public name!: string;

  public data!: string;

  public svg!: string;

  public smiles!: string | null;

  public table_data!: Record<string, unknown>;

  public parent_type!: string;

  @Type(() => Experiment)
  public experiment!: Experiment;

  @Type(() => Protocol)
  public protocol!: Protocol;

  @Type(() => Metadata)
  public metadata!: Metadata;

  @Type(() => Comment)
  public comment!: Comment;

  @Type(() => Chemical)
  public chemicals!: Chemical[];

  @Type(() => Chemical)
  public limiting_chemical!: Chemical;

  public pubchem?: {
    reactants: PubChemMetadata[];
    products: PubChemMetadata[];
  };

  public get properties(): PubChemMetadata | null {
    if (this.pubchem) {
      const reactant = this.pubchem.reactants[0];
      if (reactant) {
        return reactant;
      }
    }
    return null;
  }

  public get pubchemReactants(): Array<PubChemMetadata> {
    return this.pubchem ? this.pubchem.reactants : [];
  }

  public get pubchemProducts(): Array<PubChemMetadata> {
    return this.pubchem ? this.pubchem.products : [];
  }

  public get pubchemCompounds(): Array<PubChemMetadata> {
    return this.pubchem
      ? [...this.pubchem.products, ...this.pubchem.reactants]
      : [];
  }

  public get getId(): string {
    return this.guid;
  }

  public get parent(): MoleculeParent {
    return (
      this.experiment ||
      this.protocol ||
      this.metadata ||
      this.comment ||
      null
    );
  }

  public static get createBodyDefault(): Record<string, unknown> {
    return {
      name: 'Untitled',
      data: '',
      svg: '',
      pubchem: null,
      smiles: null,
    };
  }

  /**
   * Initialise pubchem metadata
   */
  public static get metadataDefault(): PubChemMetadata {
    return {
      IUPACName: null,
      MolecularFormula: null,
      CAS: null,
      InChI: null,
      SMILES: null,
      Density: '1',
      MolecularWeight: null,
      Safety: {
        ghs_hazard_statements: [],
        precautionary: {
          General: [],
          Prevention: [],
          Response: [],
          Storage: [],
          Disposal: [],
        },
        pictograms: [],
      },
    };
  }

  /**
   * Returns an object to initialise pubchem field
   */
  public static get pubChemJsonDefault(): Molecule['pubchem'] {
    return {
      reactants: [this.metadataDefault],
      products: [],
    };
  }
}
