/**
 * Labstep
 *
 * @module components/Resource/ListOrTable
 * @desc List or table of resources
 */

import ResourceList from 'labstep-web/components/Resource/List';
import GridResource from 'labstep-web/grid/IndexResource';
import MasterIndexCenterListOrTable from 'labstep-web/screens/Master/Index/Center/ListOrTable';
import React from 'react';
import { actions } from './constants';
import { IResourceListOrTableProps } from './types';

export const ResourceListOrTable: React.FC<
  IResourceListOrTableProps
> = ({ resources, ...rest }) => (
  <MasterIndexCenterListOrTable
    tableComponent={<GridResource resources={resources} {...rest} />}
    listComponent={
      <ResourceList resources={resources} actions={actions} />
    }
  />
);

export default ResourceListOrTable;
