/**
 * Labstep
 *
 * @module components/Filter/Search
 * @desc Search Bar Filter
 */

import { useParamsContext } from 'labstep-web/contexts/params/hook';
import SearchBar from 'labstep-web/core/SearchBar';
import SearchBarToggle from 'labstep-web/core/SearchBar/Toggle';
import { ParamsHOCChildrenProps } from 'labstep-web/hoc/Params/types';
import React from 'react';
import { ISearchFilterProps } from './types';

export const onSearchChange = (
  value: string,
  setParams: ParamsHOCChildrenProps['setParams'],
  searchKey: string,
) => {
  const param = value === '' ? undefined : value;
  setParams({ [searchKey]: param });
};

export const SearchFilter: React.FC<ISearchFilterProps> = ({
  searchKey = 'search_query',
  searchBarProps,
}) => {
  const { setParams, searchParams, clearAll } = useParamsContext();
  const props = {
    defaultValue: searchParams[searchKey] as string,
    onChange: (value: string) =>
      onSearchChange(value, setParams, searchKey),
  };

  return searchBarProps && 'viewComponent' in searchBarProps ? (
    <SearchBarToggle
      onClose={clearAll}
      {...props}
      {...searchBarProps}
    />
  ) : (
    <SearchBar {...props} {...searchBarProps} />
  );
};

export default SearchFilter;
