/**
 * Labstep
 *
 * @module grid/Report/components/ToolPanel/Experiment
 * @desc Hardcoded list of columns for experiment fields
 */

import { ICONS } from 'labstep-web/constants/icons';
import Flex from 'labstep-web/core/Flex';
import { SectionHeader } from 'labstep-web/core/Header';
import {
  experimentNameColDef,
  experimentWorkflowCreatedAtColDef,
  experimentWorkflowEndedAtColDef,
  experimentWorkflowEntityStateNameColDef,
  experimentWorkflowStartedAtColDef,
} from 'labstep-web/grid/Report/coldefs';
import EntityDataGridToolPanelListItem from 'labstep-web/grid/Report/components/ToolPanel/ListItem';
import { GridReportService } from 'labstep-web/grid/Report/services/grid-report.service';
import React from 'react';
import { IEntityDataGridToolPanelExperimentProps } from './types';

export const EntityDataGridToolPanelExperiment: React.FC<
  IEntityDataGridToolPanelExperimentProps
> = ({
  authenticatedUserEntity,
  gridReportService,
  searchQuery,
  colIds,
}) => (
  <Flex column>
    {GridReportService.showListSection(
      [
        'experiment name',
        'creation date',
        'started date',
        'completion date',
      ],
      searchQuery,
    ) && <SectionHeader label="Experiment Fields" />}
    {GridReportService.showListItem(
      'experiment name',
      searchQuery,
    ) && (
      <EntityDataGridToolPanelListItem
        icon="book"
        actionIcon="eye"
        text="Experiment Name"
        onClick={() => {
          gridReportService.addColumnDef(experimentNameColDef);
        }}
        disabled={
          experimentNameColDef.colId
            ? colIds.includes(experimentNameColDef.colId)
            : false
        }
      />
    )}
    {GridReportService.showListItem('creation date', searchQuery) && (
      <EntityDataGridToolPanelListItem
        icon="calendar"
        actionIcon="eye"
        text="Creation Date"
        onClick={() => {
          gridReportService.addColumnDef(
            experimentWorkflowCreatedAtColDef,
          );
        }}
        disabled={
          experimentWorkflowCreatedAtColDef.colId
            ? colIds.includes(experimentWorkflowCreatedAtColDef.colId)
            : false
        }
      />
    )}
    {GridReportService.showListItem('started date', searchQuery) && (
      <EntityDataGridToolPanelListItem
        icon="calendar outline"
        actionIcon="eye"
        text="Started Date"
        onClick={() => {
          gridReportService.addColumnDef(
            experimentWorkflowStartedAtColDef,
          );
        }}
        disabled={
          experimentWorkflowStartedAtColDef.colId
            ? colIds.includes(experimentWorkflowStartedAtColDef.colId)
            : false
        }
      />
    )}
    {GridReportService.showListItem(
      'completion date',
      searchQuery,
    ) && (
      <EntityDataGridToolPanelListItem
        icon="calendar check outline"
        actionIcon="eye"
        text="Completion Date"
        onClick={() => {
          gridReportService.addColumnDef(
            experimentWorkflowEndedAtColDef,
          );
        }}
        disabled={
          experimentWorkflowEndedAtColDef.colId
            ? colIds.includes(experimentWorkflowEndedAtColDef.colId)
            : false
        }
      />
    )}
    {authenticatedUserEntity.hasFeatureEnabled(
      'signature_workflow',
    ) &&
      GridReportService.showListItem('status', searchQuery) && (
        <EntityDataGridToolPanelListItem
          icon={ICONS.entity_state_workflow.primary}
          actionIcon="eye"
          text="Status"
          onClick={() => {
            gridReportService.addColumnDef(
              experimentWorkflowEntityStateNameColDef,
            );
          }}
          disabled={
            experimentWorkflowEntityStateNameColDef.colId
              ? colIds.includes(
                  experimentWorkflowEntityStateNameColDef.colId,
                )
              : false
          }
        />
      )}
  </Flex>
);

export default EntityDataGridToolPanelExperiment;
