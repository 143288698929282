/**
 * Labstep
 *
 * @module core/Action/Copy
 * @desc Action to copy text in clipboard
 */

import copy from 'copy-to-clipboard';
import ActionComponent from 'labstep-web/core/Action/Component';
import { ActionComponentProps } from 'labstep-web/core/Action/Component/types';
import { addActionComponentPropsToDefault } from 'labstep-web/core/Action/Component/utils';
import React, { useState } from 'react';
import { IActionCopyProps } from './types';

export const COPIED_ALERT = 'Copied!';

const ActionCopy: React.FC<IActionCopyProps> = ({
  text,
  hoverContent = 'Click to copy',
  actionComponentProps: extraActionComponentProps,
}) => {
  const [content, setContent] = useState(hoverContent);

  let actionComponentProps = {
    type: 'option',
    elementProps: {
      popup: {
        content,
        on: content === COPIED_ALERT ? 'click' : 'hover',
        onClose: () => {
          setContent(hoverContent);
        },
        hideOnScroll: true,
      },
    },
  } as ActionComponentProps;

  actionComponentProps = addActionComponentPropsToDefault(
    actionComponentProps,
    extraActionComponentProps,
  );

  return (
    <ActionComponent
      data-private
      onClick={(): void => {
        setContent(COPIED_ALERT);
        copy(text);
      }}
      {...actionComponentProps}
    />
  );
};

export default ActionCopy;
