/**
 * Labstep
 *
 * @module core/SearchBar
 * @desc Search bar
 */

import AsyncInput from 'labstep-web/core/Input/Async';
import React from 'react';
import styles from './styles.module.scss';
import { ISearchBarProps } from './types';

const SearchBar: React.FC<ISearchBarProps> = ({
  onChange,
  label,
  icon,
  ...rest
}) => (
  <AsyncInput
    className={styles.input}
    onChange={onChange}
    icon={icon}
    iconPosition="left"
    label={label}
    labelPosition={label ? 'right' : undefined}
    {...rest}
  />
);

SearchBar.defaultProps = {
  icon: { name: 'search' },
};

export default SearchBar;
