/**
 * Labstep
 *
 * @module core/List/LabelValue
 * @desc A preview list of metadata
 */

import classnames from 'classnames';
import Icon from 'labstep-web/core/Icon';
import React from 'react';
import styles from './styles.module.scss';
import { IListLabelValueProps } from './types';

const ListLabelValue: React.FC<IListLabelValueProps> = ({
  array,
  fluid,
}) => (
  <div>
    {array.map((element, index) => (
      <div
        className={styles.container}
        // eslint-disable-next-line react/no-array-index-key
        key={index}
        data-testid={element.dataTestId}
      >
        {element.icon && (
          <Icon name={element.icon} cornerIcon={element.cornerIcon} />
        )}
        <span className={styles.label}>{element.label}:</span>
        <span
          className={classnames(styles.value, {
            [styles.fluid]: fluid,
          })}
        >
          {element.value}
        </span>
      </div>
    ))}
  </div>
);

export default ListLabelValue;
