/**
 * Labstep
 *
 * @module components/Resource/Action/View
 * @desc Button linking to resource show
 */

import EntityActionView from 'labstep-web/components/Entity/Action/View';
import { Resource } from 'labstep-web/models/resource.model';
import React from 'react';
import { IResourceActionViewProps } from './types';

const ResourceActionView: React.FC<IResourceActionViewProps> = ({
  resource,
  actionComponentProps,
}) => (
  <EntityActionView
    entityName={Resource.entityName}
    params={{ id: resource.id }}
    actionComponentProps={actionComponentProps}
  />
);

export default ResourceActionView;
