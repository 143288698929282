/**
 * Labstep
 *
 * @module core/Form/ReactForm/DateTimePicker
 * @desc React-form field for DateTimePicker component
 */

import DateTimePicker from 'labstep-web/core/DateTimePicker';
import {
  format as dateServiceFormat,
  defaultFormat,
  formatDateOnly,
} from 'labstep-web/services/date.service';
import moment from 'moment';
import React, { useState } from 'react';
import { Controller } from 'react-hook-form';
import {
  IDateTimePickerFieldComponentProps,
  IDateTimePickerFieldProps,
} from './types';

export const updateValue = (
  setValue: (dateValue: string | string[]) => void,
  date: unknown[],
  dateString: string,
  isDateOnly: boolean,
): void => {
  let dateValue: string | string[];

  // Sometimes datetimepicker gives invalid date (e.g. backspace). Set this to null.
  if (dateString === 'Invalid date') {
    dateValue = null;
  } else if (date.length === 1) {
    dateValue = isDateOnly
      ? formatDateOnly(date[0])
      : dateServiceFormat(date[0]);
  } else {
    dateValue = date.map(dateServiceFormat);
  }
  setValue(dateValue);
};

export const DateTimePickerFieldComponent: React.FC<
  IDateTimePickerFieldComponentProps
> = ({ field, ...props }) => {
  const { value, onChange: setValue } = field;
  const { elementProps, autoFocus, disabled } = props;
  const enableTime = elementProps && elementProps.enableTime;
  const dateFormat = enableTime ? 'YYYY-MM-DD HH:mm' : 'YYYY-MM-DD';

  const [mounted, setMounted] = useState(false);

  const isDateOnly = !enableTime;

  return (
    <DateTimePicker
      value={value}
      type="hidden"
      setRef={(ref) => {
        if (!mounted && ref && autoFocus) {
          ref.flatpickr.open();
          setMounted(true);
        }
      }}
      disabled={disabled}
      options={{
        type: 'hidden',
        time_24hr: true,
        dateFormat,
        parseDate: (datestr) => {
          return moment(datestr, defaultFormat, true).toDate();
        },
        formatDate: (date, format) => {
          return moment(date).format(format);
        },
        onClose: (date, dateString) =>
          updateValue(setValue as any, date, dateString, isDateOnly),
        ...elementProps,
      }}
    />
  );
};

export const DateTimePickerField: React.FC<
  IDateTimePickerFieldProps
> = ({ name, control, ...rest }) => (
  <Controller
    name={name}
    control={control}
    render={({ field }): React.ReactElement => (
      <DateTimePickerFieldComponent field={field} {...rest} />
    )}
  />
);

export default DateTimePickerField;
