/**
 * Labstep
 *
 * @module components/Entity/Action/New
 * @desc Dropdown Menu to create any of the main Labstep entities
 */

import ExperimentWorkflowActionCreate from 'labstep-web/components/ExperimentWorkflow/Action/Create';
import GuardGroupCreate from 'labstep-web/components/Guard/GroupCreate';
import OrderRequestActionCreate from 'labstep-web/components/OrderRequest/Action/Create';
import { ProtocolCollectionActionCreate } from 'labstep-web/components/ProtocolCollection/Action/Create';
import ResourceActionCreateForm from 'labstep-web/components/Resource/Action/Create/Form';
import Button from 'labstep-web/core/Button';
import ButtonCircular from 'labstep-web/core/Button/Circular';
import Dropdown from 'labstep-web/core/Dropdown';
import Icon from 'labstep-web/core/Icon';
import MediaQuery from 'labstep-web/core/MediaQuery';
import { Size } from 'labstep-web/core/MediaQuery/types';
import { useHasFeatureFlagEnabled } from 'labstep-web/hooks/feature-flag';
import { ExperimentWorkflow } from 'labstep-web/models/experiment-workflow.model';
import { OrderRequest } from 'labstep-web/models/order-request.model';
import { ProtocolCollection } from 'labstep-web/models/protocol-collection.model';
import { Resource } from 'labstep-web/models/resource.model';
import React from 'react';
import styles from './styles.module.scss';
import { EntityActionNewProps } from './types';

export const EntityActionNew: React.FC<EntityActionNewProps> = () => (
  <GuardGroupCreate>
    <Dropdown
      pointing="top right"
      icon={null}
      trigger={
        <MediaQuery size={Size.tablet_desktop}>
          {({ match }) =>
            match ? (
              <Button className={styles.button} icon>
                <Icon name="plus" /> New
              </Button>
            ) : (
              <ButtonCircular icon="plus" text="New" />
            )
          }
        </MediaQuery>
      }
    >
      <ExperimentWorkflowActionCreate
        actionComponentProps={{
          icon: undefined,
          type: 'option',
          text: ExperimentWorkflow.getHumanReadableEntityName(
            false,
            true,
          ),
        }}
      />
      <ProtocolCollectionActionCreate
        actionComponentProps={{
          type: 'option',
          icon: undefined,
          text: ProtocolCollection.getHumanReadableEntityName(
            false,
            true,
          ),
        }}
      />
      {useHasFeatureFlagEnabled('inventory') && (
        <ResourceActionCreateForm
          actionComponentProps={{
            icon: undefined,
            type: 'option',
            text: Resource.getHumanReadableEntityName(false, true),
          }}
        />
      )}
      {useHasFeatureFlagEnabled('order_management') && (
        <OrderRequestActionCreate
          actionComponentProps={{
            type: 'option',
            icon: undefined,
            text: OrderRequest.getHumanReadableEntityName(
              false,
              true,
            ),
          }}
        />
      )}
    </Dropdown>
  </GuardGroupCreate>
);

export default EntityActionNew;
