/**
 * Labstep
 */

import MetadataManager from 'labstep-web/components/Metadata/Manager';
import colDefName from 'labstep-web/grid/Index/coldefs/Resource/name';
import { Resource } from 'labstep-web/models/resource.model';
import { PrecursorCellEditor } from './cellEditor/precursorCellEditor';

export const colDefResourceName = {
  ...colDefName(Resource.entityName, {
    getEntityPreviewProps: (resource) => ({
      secondaryInfo: <MetadataManager entity={resource} />,
    }),
  }),
  cellEditor: PrecursorCellEditor,
};
