/**
 * Labstep
 *
 * @module screens/Experiment/Show/Center
 * @desc Center of ExperimentShow Screen
 */

import ExperimentFormShowEditStateContainer from 'labstep-web/components/Experiment/Form/ShowEdit/State/Container';
import ExperimentInfo from 'labstep-web/components/Experiment/Info';
import ScrollToStepContainer from 'labstep-web/containers/ScrollToStep';
import ActionComponent from 'labstep-web/core/Action/Component';
import InformationCard from 'labstep-web/core/Card/Information';
import { useToggle } from 'labstep-web/hooks/toggle';
import React from 'react';
import styles from './styles.module.scss';
import { IScreensExperimentShowCenterProps } from './types';

export const ScreensExperimentShowCenter: React.FC<
  IScreensExperimentShowCenterProps
> = ({ experiment }) => {
  const [showInfo, toggleShowInfo] = useToggle(true);
  return (
    <InformationCard
      className={styles.card}
      primaryInfo={
        showInfo && <ExperimentInfo experiment={experiment} />
      }
      bottom={
        <ScrollToStepContainer>
          <ExperimentFormShowEditStateContainer
            // key needed because of https://github.com/Labstep/web/issues/7191
            key={experiment.id}
            experiment={experiment}
            withActionBar
            actions={
              <ActionComponent
                type="text"
                onClick={toggleShowInfo}
                text={showInfo ? 'Hide Info' : 'Show Info'}
                icon={showInfo ? 'chevron up' : 'chevron down'}
              />
            }
            topMenuClassName={styles.topMenu}
          />
        </ScrollToStepContainer>
      }
    />
  );
};

export default ScreensExperimentShowCenter;
