/**
 * Labstep
 *
 * @module components/Filter/DateRange
 * @desc Date range filter
 */

import ActionComponent from 'labstep-web/core/Action/Component';
import DateTimePicker from 'labstep-web/core/DateTimePicker';
import Modal from 'labstep-web/core/Modal/Default';
import { format } from 'labstep-web/services/date.service';
import { PostFilterCreatedAt } from 'labstep-web/services/postFilter/filters/createdAt';
import moment from 'moment';
import React from 'react';
import { DateProps, FilterDateRangeProps } from './types';

export const onDatePickerChange = (
  { startDate, endDate }: DateProps,
  setParams?: any,
  addPostFilter?: FilterDateRangeProps['addPostFilter'],
) => {
  const created_at_from = !startDate ? undefined : format(startDate);
  const created_at_to = !endDate
    ? undefined
    : format(moment(endDate).endOf('day'));

  if (addPostFilter) {
    addPostFilter(
      PostFilterCreatedAt.getNode([created_at_from, created_at_to]),
      true,
    );
  } else {
    setParams({ created_at_from, created_at_to });
  }
};

const FilterDateRange: React.FC<FilterDateRangeProps> = ({
  setParams,
  searchParams,
  addPostFilter,
}) => (
  <Modal
    header="Date Range"
    content={({ toggleModal }) => (
      <DateTimePicker
        options={{
          defaultDate: [
            searchParams?.created_at_from,
            searchParams?.created_at_to,
          ],
          mode: 'range',
          maxDate: 'today',
          dateFormat: 'Y-m-d',
        }}
        placeholder="Select dates"
        onClose={(selectedDates) => {
          onDatePickerChange(
            {
              startDate: selectedDates[0],
              endDate: selectedDates[1],
            },
            setParams,
            addPostFilter,
          );
          toggleModal();
        }}
      />
    )}
    viewComponent={({ toggleModal }) => (
      <ActionComponent
        type="option"
        icon="calendar alternate"
        text={'Date Created'}
        onClick={toggleModal}
      />
    )}
  />
);

export default FilterDateRange;
