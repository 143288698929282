/**
 * Labstep
 */

import {
  FieldType,
  FieldWithoutActionProps,
} from 'labstep-web/core/Form/Reusable/types';
import { ProtocolCondition } from 'labstep-web/models/protocol-condition.model';
import rules from 'labstep-web/services/validation/rules';

export const fieldQuantityName = 'quantity';

export const fieldQuantity: FieldWithoutActionProps = {
  fieldType: FieldType.Text,
  fieldLabel: `How many ${ProtocolCondition.getHumanReadableEntityName(
    true,
    false,
  )} do you want to add?`,
  name: fieldQuantityName,
  validation: rules.protocol_condition.quantity,
};
