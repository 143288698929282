/**
 * Labstep
 *
 * @module components/Table/Form/ShowEdit/Name
 * @desc ShowEdit for name for resource item
 */

import { fieldTableName } from 'labstep-web/components/Table/Form/fields';
import Button from 'labstep-web/core/Button';
import ShowEdit from 'labstep-web/core/Form/ShowEdit/Text';
import Icon from 'labstep-web/core/Icon';
import PremiumFeatureModal from 'labstep-web/core/PremiumFeature/Modal';
import { useHasFeatureFlagEnabled } from 'labstep-web/hooks/feature-flag';
import React from 'react';
import styles from './styles.module.scss';
import { ITableFormShowEditNameProps } from './types';

const TableFormShowEditName: React.FC<
  ITableFormShowEditNameProps
> = ({ table, onDone, status }) => (
  <div className={styles.header}>
    <ShowEdit
      entityName={table.entityName}
      entity={table}
      field={fieldTableName}
    >
      <span>{table.name}</span>
    </ShowEdit>
    <div>
      {!useHasFeatureFlagEnabled('spreadsheet_designer') && (
        <PremiumFeatureModal
          premiumFeature="spreadsheet_designer"
          viewComponent={({ toggleModal }) => (
            <Button
              primary
              className={styles.shinyButton}
              onClick={toggleModal}
            >
              <Icon name="arrow alternate circle up" />
              Upgrade
            </Button>
          )}
        />
      )}
      <Button onClick={onDone} status={status} primary>
        Done
      </Button>
    </div>
  </div>
);

export default TableFormShowEditName;
