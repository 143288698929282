/**
 * Labstep
 *
 * @module components/OrderRequest/List
 * @desc OrderRequest List
 */

import MetadataPreview from 'labstep-web/components/Metadata/Preview';
import OrderRequestActionMenu from 'labstep-web/components/OrderRequest/Action/Menu';
import OrderRequestActionUpdateStatus from 'labstep-web/components/OrderRequest/Action/UpdateStatus';
import CardGeneric from 'labstep-web/core/Card/Generic';
import List from 'labstep-web/core/List';
import React from 'react';
import styles from './styles.module.scss';
import { OrderRequestListProps } from './types';

const OrderRequestList: React.FC<OrderRequestListProps> = ({
  orderRequests,
  actions,
}) => (
  // <EntitySearchContext.Consumer>
  //   {bulkSelectProps => (
  <List className={styles.feed} id="order-request-list">
    {orderRequests.map((orderRequest) => (
      <CardGeneric
        key={orderRequest.id}
        // checkbox={
        //   <Checkbox
        //     checked={bulkSelectProps.isSelected(orderRequest)}
        //     onClick={() =>
        //       bulkSelectProps.toggleSelection(orderRequest)
        //     }
        //   />
        // }
        body={
          <div className={styles.body}>
            <OrderRequestActionUpdateStatus
              orderRequest={orderRequest}
              withAdditionalInfo={false}
            />
            <span>{orderRequest.quantity} unit(s)</span>
            <span>{orderRequest.printAmount}</span>
          </div>
        }
        headerTitle={{
          to: 'order_request_show',
          params: { id: orderRequest.id },
          title: orderRequest.name,
        }}
        customSubtitle={
          <MetadataPreview entity={orderRequest.resource} />
        }
        actionMenu={
          <OrderRequestActionMenu
            orderRequest={orderRequest}
            actions={actions}
          />
        }
      />
    ))}
  </List>
  //   )}
  // </EntitySearchContext.Consumer>
);

export default OrderRequestList;
