/**
 * Labstep
 *
 * @module components/RichText/Item
 * @desc Item comment post
 */

import RichTextDangerousDiv from 'labstep-web/components/RichText/DangerousDiv';
import CoreCodeMirror from 'labstep-web/core/CodeMirror';
import React from 'react';
import { IRichTextItemProps } from './types';

const RichTextItem: React.FC<IRichTextItemProps> = ({
  richText,
  codeMirrorProps,
}) => {
  if (richText.type === 'html') {
    return <RichTextDangerousDiv richText={richText} />;
  }

  return (
    <CoreCodeMirror
      readOnly
      mode={richText.type}
      value={richText.description}
      {...codeMirrorProps}
    />
  );
};

export default RichTextItem;
