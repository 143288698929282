/**
 * Labstep
 *
 * @module components/ExperimentWorkflow/Filter/Status/List
 * @desc Filter pane for experiment workflow
 */

import { FilterSection } from 'labstep-web/components/Filter/List';
import { withActiveGroup } from 'labstep-web/containers/ActiveGroup';
import Icon from 'labstep-web/core/Icon';
import { ReadOnMountHOC } from 'labstep-web/hoc/ReadOnMount';
import { useHasFeatureFlagEnabled } from 'labstep-web/hooks/feature-flag';
import { EntityState } from 'labstep-web/models/entity-state.model';
import { StatusTypes } from 'labstep-web/models/experiment-workflow.model';
import { TypeToggleUiFilterAction } from 'labstep-web/state/actions/ui-persistent.types';
import React from 'react';
import styles from './styles.module.scss';
import { ExperimentWorkflowFilterStatusListProps } from './types';

export const ExperimentWorkflowFilterStatusList: React.FC<
  ExperimentWorkflowFilterStatusListProps
> = ({ activeGroup }) => {
  const hasSignatureFeatureEnabled = useHasFeatureFlagEnabled(
    'signature_workflow',
  );
  return (
    <>
      <ReadOnMountHOC
        type="cursor"
        loading={{ loader: 'placeholder' }}
        entityName={EntityState.entityName}
        params={{
          group_by_name: 1,
          sort: 'name',
          group_id: activeGroup?.id,
          count: 100,
        }}
        children={({ entities }: { entities: EntityState[] }) => {
          return (
            <FilterSection
              data-testid="experiment-workflow-filter-status-list"
              filter={{
                entityName: 'experiment_workflow',
                name: 'status',
              }}
              header="Status"
              filters={Object.values(StatusTypes).map(
                (statusType) => {
                  const subFilters = hasSignatureFeatureEnabled
                    ? entities
                        .filter(
                          (e) =>
                            e.type.toString().toLowerCase() ===
                            statusType.text.toLowerCase(),
                        )
                        .map((entityState: EntityState) => {
                          return {
                            component: (
                              <Icon
                                name={statusType.icon}
                                style={{
                                  color: entityState.color,
                                }}
                                className={styles.circleOutline}
                              />
                            ),
                            text: entityState.name,
                            params: {
                              entity_state_name: entityState.name,
                              is_started: undefined,
                              is_ended: undefined,
                            },
                          };
                        })
                    : undefined;
                  return {
                    component: (
                      <Icon
                        name={statusType.icon}
                        className={styles.circleOutline}
                      />
                    ),
                    text: statusType.text,
                    params: {
                      ...statusType.params,
                      entity_state_name: undefined,
                    },
                    subFilters: subFilters
                      ? {
                          filters: subFilters,
                          filter: {
                            entityName: 'entity_state',
                            name: statusType.text.toLowerCase() as TypeToggleUiFilterAction['filter'],
                          },
                        }
                      : undefined,
                  };
                },
              )}
            />
          );
        }}
      />
    </>
  );
};

export default withActiveGroup(ExperimentWorkflowFilterStatusList);
