/**
 * Labstep
 *
 * @module components/ExperimentWorkflow/ListOrTable
 * @desc List or table of experimentWorkflows
 */

import ExperimentWorkflowList from 'labstep-web/components/ExperimentWorkflow/List';
import MasterIndexCenterListOrTable from 'labstep-web/screens/Master/Index/Center/ListOrTable';
import React from 'react';
import ExperimentWorkflowViewManager from '../ViewManager';
import { actions } from './constants';
import { ExperimentWorkflowListOrTableProps } from './types';

export const ExperimentWorkflowListOrTable: React.FC<
  ExperimentWorkflowListOrTableProps
> = ({ experimentWorkflows, isTemplate, ...rest }) => (
  <MasterIndexCenterListOrTable
    tableComponent={
      <ExperimentWorkflowViewManager
        showCollaborators
        experimentWorkflows={experimentWorkflows}
        actions={actions}
        isTemplate={isTemplate}
        {...rest}
      />
    }
    listComponent={
      <ExperimentWorkflowList
        actions={actions}
        experimentWorkflows={experimentWorkflows}
        createdBy
      />
    }
  />
);

export default ExperimentWorkflowListOrTable;
