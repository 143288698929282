/**
 * Labstep
 *
 * @see https://github.com/podefr/react-debounce-render/blob/master/src/index.js
 */

import hoistNonReactStatics from 'hoist-non-react-statics';
import debounce from 'lodash/debounce';
import React, { Component } from 'react';

const debounceRender = <P extends Record<string, unknown>>(
  ComponentToDebounce: React.ComponentType<P>,
) => {
  class DebouncedContainer extends Component<P> {
    updateDebounced = debounce(this.forceUpdate, 100);

    shouldComponentUpdate() {
      this.updateDebounced();
      return false;
    }

    componentWillUnmount() {
      this.updateDebounced.cancel();
    }

    render() {
      return <ComponentToDebounce {...this.props} />;
    }
  }

  return hoistNonReactStatics(
    DebouncedContainer,
    ComponentToDebounce,
  );
};

export default debounceRender;
