/**
 * Labstep
 *
 * @module core/Card/EmptyState
 * @desc Skeleton for empty state warnings
 */

import classnames from 'classnames';
import Header from 'labstep-web/core/Header';
import Image from 'labstep-web/core/Image';
import React from 'react';
import styles from './styles.module.scss';
import { EmptyStateProps } from './types';

const EmptyState: React.FC<EmptyStateProps> = ({
  title,
  src,
  explanation,
  action,
  secondaryAction,
  warning,
  stackedActions = false,
}) => (
  <div className={styles.main} data-testid="empty-state" data-public>
    <Image data-public src={src} fluid />
    {title && (
      <Header size="xsmall" color={warning ? 'red' : undefined}>
        {title}
      </Header>
    )}
    <p data-public>{explanation}</p>
    <div
      className={classnames(styles.actions, {
        [styles.stackedActions]: stackedActions,
        [styles.withoutSecondaryAction]: !secondaryAction,
      })}
    >
      <div data-public>{action}</div>
      {secondaryAction && <div data-public>{secondaryAction}</div>}
    </div>
  </div>
);

export default EmptyState;
