/**
 * Labstep
 *
 * @module components/Resource/Action/Create/Form
 * @desc Create Action for resource with form
 */

import EntityActionCreateTemplatable from 'labstep-web/components/Entity/Action/Create/Templatable';
import React from 'react';
import { IResourceActionCreateFormProps } from './types';

export const ResourceActionCreateForm: React.FC<
  IResourceActionCreateFormProps
> = ({ resourceTemplate, actionComponentProps }) => (
  <EntityActionCreateTemplatable
    entityName="resource"
    templateId={resourceTemplate?.guid}
    actionComponentProps={actionComponentProps}
  />
);

export default ResourceActionCreateForm;
