/**
 * Labstep
 *
 * @module components/Group/Action/Delete
 * @desc Action to delete group
 */

import DeleteAction from 'labstep-web/components/Entity/Action/Delete';
import { getHumanReadableEntityName } from 'labstep-web/services/i18n.service';
import { navigation } from 'labstep-web/services/navigation';
import React from 'react';
import { withRouter } from 'react-router';
import { GroupDeleteActionProps } from './types';

const groupText = getHumanReadableEntityName('group');
export const restoreGroupMessage = `Restoring this ${groupText} will make this ${groupText} visible to all members.
  All members will be notified that this ${groupText} has been restored.`;

export const deleteGroupMessage = `Deleting this ${groupText} will restrict access for all members and they will no longer be able to share content.
  All ${groupText} members will be notified that this ${groupText} has been deleted. You can restore this ${groupText} at any time.`;

export const GroupDeleteAction: React.FC<GroupDeleteActionProps> = ({
  group,
  history,
  actionComponentProps,
}) => (
  <DeleteAction
    actionComponentProps={actionComponentProps}
    options={{
      onSuccess: (): void => {
        if (
          !group.deleted_at &&
          actionComponentProps?.type === 'button'
        ) {
          history.push(navigation.get('group_index'));
        }
      },
    }}
    entity={group}
    confirmMessage={
      group.deleted_at ? restoreGroupMessage : deleteGroupMessage
    }
  />
);

export default withRouter(GroupDeleteAction);
