/**
 * Labstep
 */

import { ActionMenuProps } from 'labstep-web/core/Action/Menu/types';
import { EntityView } from 'labstep-web/models/entity-view.model';

// eslint-disable-next-line no-shadow
export enum Action {
  delete = 'delete',
  tag = 'tag',
  add_to_folder = 'add_to_folder',
  remove_from_folder = 'remove_from_folder',
}

export interface EntityViewActionMenuProps
  extends Pick<ActionMenuProps, 'type'> {
  entityView: EntityView;
  actions: Action[];
}
