/**
 * Labstep
 *
 * @module screens/OrderRequest/Show/Center/SecondaryInfo
 * @desc OrderRequest Show Center Secondary Info
 */

import EntityLink from 'labstep-web/components/Entity/Link';
import MetadataManager from 'labstep-web/components/Metadata/Manager';
import MetadataTableExtraRows from 'labstep-web/components/Metadata/Table/ExtraRows';
import { getOrderRequestDetails } from 'labstep-web/components/OrderRequest/Details';
import Card from 'labstep-web/core/Card/Card';
import List from 'labstep-web/core/List';
import TextWithHelp from 'labstep-web/core/Text/WithHelp';
import React from 'react';
import Bottom from '../Bottom';
import { OrderRequestShowCenterSecondaryInfoProps } from './types';

export const OrderRequestShowCenterSecondaryInfo: React.FC<
  OrderRequestShowCenterSecondaryInfoProps
> = ({ orderRequest, showStatusLog }) => (
  <List>
    <Card>
      <MetadataTableExtraRows
        rows={getOrderRequestDetails()}
        entity={orderRequest}
      />
    </Card>
    <Card
      data-testid="order-request-show-metadatas-resource"
      size="mini"
      header={
        <TextWithHelp
          text={
            <>
              Metadata for{' '}
              <EntityLink entity={orderRequest.resource} />
            </>
          }
          helperText="These metadata fields will be the same every time this resource is requested i.e. Vendor, Catalog No."
        />
      }
    >
      <MetadataManager entity={orderRequest.resource} />
    </Card>
    <Card
      data-testid="order-request-show-metadatas"
      size="mini"
      header={
        <TextWithHelp
          text="Metadata specific to this request"
          helperText="These metadata fields may change each time this resource is requested i.e. Grant Code, Expected Delivery Date"
        />
      }
    >
      <MetadataManager entity={orderRequest} />
    </Card>
    {showStatusLog && (
      <Card
        data-testid="order-request-show-logs"
        size="mini"
        header="Status History"
      >
        <Bottom orderRequest={orderRequest} />
      </Card>
    )}
  </List>
);

export default OrderRequestShowCenterSecondaryInfo;
