/**
 * Labstep
 *
 * @module core/StatusCard
 * @desc A Card component for displaying a status
 */

import classNames from 'classnames';
import Flex from 'labstep-web/core/Flex';
import Icon from 'labstep-web/core/Icon';
import { APICallStatusCardProps } from 'labstep-web/core/StatusCard/types';
import React, { useState } from 'react';
import { SemanticICONS } from 'semantic-ui-react';
import ElementCard from '../Card/Element';
import styles from './styles.module.scss';

const StatusCard: React.FC<APICallStatusCardProps> = ({
  text,
  secondaryText,
  color,
  icon,
  onSelect = () => {},
  hoverable = true,
  onHoverComponent,
  active = false,
  dragHandle,
}) => {
  const [hovering, setHovering] = useState<boolean>(false);
  const onMouseEnter = () => {
    setHovering(true);
  };

  const onMouseLeave = () => {
    setHovering(false);
  };

  const cardClass = classNames({
    [styles.statusCard]: true,
    [styles.selectedBorder]: active,
    [styles.unselectedBorder]: !active,
    [styles.disableHover]: !dragHandle,
  });

  const handleSelectClick = () => {
    onSelect();
  };

  return (
    <ElementCard
      name={
        <Flex
          data-testid="status-card"
          className={cardClass}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          onClick={handleSelectClick}
          spacing="between"
        >
          {' '}
          <Flex spacing="gap">
            <Flex
              shrink={0}
              className={styles.iconPanel}
              style={{ backgroundColor: color }}
              vAlignContent="center"
              hAlignContent="center"
            >
              <Icon
                name={icon as SemanticICONS}
                color="white"
                size="large"
              />
            </Flex>
            <Flex
              column
              vAlignContent="center"
              className={styles.textContent}
            >
              <span>{text}</span>
              {secondaryText ? (
                <span
                  data-testid="secondary-text"
                  className={styles.secondaryText}
                >
                  {secondaryText}
                </span>
              ) : null}
            </Flex>
          </Flex>
          <Flex vAlignContent="center" hAlignContent="center">
            <>{!hoverable || hovering ? onHoverComponent : null}</>
          </Flex>
        </Flex>
      }
      dragHandle={dragHandle}
      basic={dragHandle !== null}
    />
  );
};

export default StatusCard;
