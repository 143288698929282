/**
 * Labstep
 *
 * @module components/Filter/CreatedBy
 * @desc Filter Created By
 */

import { Filter } from 'labstep-web/components/Filter/Menu/types';
import FilterUser from 'labstep-web/components/Filter/User';
import React from 'react';
import { FilterCreatedByProps } from './types';

// TODO Refactor - Label should be part of the filter
const FILTERS_LABELS = {
  tag: 'Tags',
  created_by: 'Created by',
  assigned_to: 'Assigned to',
  requested_by: 'Requested by',
  date_range: 'Date Created',
  status: 'Status',
  status_type: 'Status Type',
  extension: 'File Type',
  type: 'Type',
  signed_type: 'Signatures',
};

export const FilterCreatedBy: React.FC<FilterCreatedByProps> = ({
  filter = Filter.created_by,
  ...rest
}) => (
  <FilterUser
    type="author"
    header={FILTERS_LABELS[filter]}
    icon="user circle"
    {...rest}
  />
);

export default FilterCreatedBy;
