/**
 * Labstep
 *
 * @module components/Filter/CreatedBy/List
 * @desc Filter Created By List
 */

import FilterUserList from 'labstep-web/components/Filter/User/List';
import React from 'react';
import { FilterCreatedByListProps } from './types';

export const FilterCreatedByList: React.FC<
  FilterCreatedByListProps
> = ({ header, entityName, usePostFilter }) => (
  <FilterUserList
    header={header || 'Created by'}
    name="created_by"
    field="author_id"
    entityName={entityName}
    usePostFilter={usePostFilter}
  />
);

export default FilterCreatedByList;
