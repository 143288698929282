/**
 * Labstep
 *
 * @module components/EntityStateWorkflow/Modal/RightPanel
 * @desc Right panel of the entity state workflow modal
 */

import Flex from 'labstep-web/core/Flex';
import React from 'react';
import GeneralSettings from '../GeneralSettings';
import RolesAndSignatures from '../RolesAndSignatures';
import { EntityStateWorkflowModalRightPanelProps } from './types';

const EntityStateWorkflowModalRightPanel: React.FC<
  EntityStateWorkflowModalRightPanelProps
> = ({ entityState, entityStateWorkflow }) => (
  <Flex column style={{ gap: '20px' }}>
    <GeneralSettings entityState={entityState} />
    <RolesAndSignatures
      entityStateWorkflow={entityStateWorkflow}
      entityState={entityState}
    />
  </Flex>
);

export default EntityStateWorkflowModalRightPanel;
