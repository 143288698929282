/**
 * Labstep
 *
 * @module components/Guard/GroupCreate
 * @desc Guard against creating inside group. Returns null if it can't create.
 *
 * TODO Refactor alongside useHasAccess hooks
 */

import { withActiveGroup } from 'labstep-web/containers/ActiveGroup';
import React from 'react';
import { IGuardGroupCreateProps } from './types';

export const GuardGroupCreate: React.FC<IGuardGroupCreateProps> = ({
  activeGroup,
  children,
}) => {
  if (!activeGroup || !activeGroup.canCreate) {
    return null;
  }
  return <>{children}</>;
};

export default withActiveGroup(GuardGroupCreate);
