/**
 * Labstep
 *
 * @module components/UserOrganization/Action/Update
 * @desc Update UserOrganization
 */

import EntityActionUpdateDropdownPill from 'labstep-web/components/Entity/Action/Update/DropdownPill';
import { UserOrganizationType } from 'labstep-web/models/user-organization.model';
import { capitalize } from 'labstep-web/services/i18n.service';
import React from 'react';
import { IUserOrganizationActionUpdateProps } from './types';

export const UserOrganizationActionUpdate: React.FC<
  IUserOrganizationActionUpdateProps
> = ({ userOrganization }) => (
  <EntityActionUpdateDropdownPill
    entity={userOrganization}
    items={Object.keys(UserOrganizationType)
      .filter((type) => type !== UserOrganizationType.viewer)
      .map((type) => ({
        label: capitalize(type),
        body: { type },
      }))}
    currentValue={capitalize(userOrganization.type)}
    disabled={userOrganization.type === UserOrganizationType.viewer}
  />
);

export default UserOrganizationActionUpdate;
