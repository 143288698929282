/**
 * Labstep
 *
 * @module prosemirror/components/NodeView/ProtocolTimer
 * @desc Renders an ProtocolTimer node
 */

import ProtocolTimerActionEdit from 'labstep-web/components/ProtocolTimer/Action/Edit';
import ProtocolTimerModal from 'labstep-web/components/ProtocolTimer/Modal';
import { ICONS } from 'labstep-web/constants/icons';
import { ProtocolTimerItemContainer } from 'labstep-web/containers/ProtocolTimer/Item';
import { isRunningLow } from 'labstep-web/containers/ProtocolTimer/Item/utils';
import { getTimeText } from 'labstep-web/core/Time';
import { ToggleModalType } from 'labstep-web/hoc/Modal/types';
import { ProtocolTimer } from 'labstep-web/models/protocol-timer.model';
import ViewInline from 'labstep-web/prosemirror/components/Inline/View';
import Read from 'labstep-web/prosemirror/components/NodeView/Read';
import { useReactNodeView } from 'labstep-web/prosemirror/components/ReactNodeView';
import React from 'react';
import { ProtocolTimerNodeProps } from './types';

export class ProtocolTimerNode extends React.Component<ProtocolTimerNodeProps> {
  // eslint-disable-next-line class-methods-use-this
  shouldComponentUpdate() {
    return false;
  }

  render() {
    const { id, protocol, timestamp } = this.props;

    return (
      <Read
        entityName={ProtocolTimer.entityName}
        id={id}
        timestamp={timestamp}
      >
        {({ entity: protocolTimer }) => {
          const viewComponent =
            (props?: {
              children: React.ReactNode;
              inverted?: boolean;
            }) =>
            ({ toggleModal }: { toggleModal?: ToggleModalType }) => (
              <ViewInline
                entity={protocolTimer}
                icon={ICONS.protocol.timer}
                onClick={!timestamp ? toggleModal : undefined}
                {...props}
              />
            );

          return (protocolTimer as ProtocolTimer)
            .is_experiment_child ? (
            <ProtocolTimerItemContainer
              bypass
              protocolTimer={protocolTimer as ProtocolTimer}
            >
              {({ timeRemaining }) => (
                <ProtocolTimerModal
                  protocolTimer={protocolTimer as ProtocolTimer}
                  protocol={protocol}
                  viewComponent={viewComponent({
                    inverted: isRunningLow(timeRemaining),
                    children: getTimeText(timeRemaining),
                  })}
                />
              )}
            </ProtocolTimerItemContainer>
          ) : (
            <ProtocolTimerActionEdit
              protocolTimer={protocolTimer as ProtocolTimer}
              viewComponent={viewComponent({
                children: getTimeText(protocolTimer as ProtocolTimer),
              })}
            />
          );
        }}
      </Read>
    );
  }
}

const ProtocolTimerNodeView = ({ entity, timestamp }: any) => {
  const context = useReactNodeView();
  const { guid } = (context.node as any).attrs;

  return (
    <ProtocolTimerNode
      id={guid}
      protocol={entity}
      timestamp={timestamp}
    />
  );
};

export default ProtocolTimerNodeView;
