/**
 * Labstep
 *
 * @module components/PermaLink/Redirect
 * @desc Scan and redirect
 */

import Container from 'labstep-web/components/Layout/Container';
import PermaLinkReader from 'labstep-web/components/PermaLink/Reader';
import ActionComponent from 'labstep-web/core/Action/Component';
import Modal from 'labstep-web/core/Modal/Default';
import Redirect from 'labstep-web/core/Redirect';
import React from 'react';

export const PermaLinkActionRedirect: React.FC = () => (
  <Modal
    viewComponent={
      <ActionComponent
        type="icon"
        icon="qrcode"
        elementProps={{ color: 'blue', size: 'large' }}
      />
    }
    content={({ toggleModal }) => (
      <div>
        <Container>
          <PermaLinkReader description="Scan the QR Code for a resource, item or device to go to the corresponding page.">
            {({ id, entityName }) => (
              <Redirect to={`${entityName}_show`} params={{ id }} />
            )}
          </PermaLinkReader>
        </Container>
        <ActionComponent
          type="button"
          text="Cancel"
          elementProps={{ basic: true, fluid: true }}
          onClick={toggleModal}
        />
      </div>
    )}
  />
);

export default PermaLinkActionRedirect;
