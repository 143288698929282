/**
 * Labstep
 *
 * @module components/OrganizationSAML/Show
 * @desc Display organization billing plan
 */

import LayoutContainerActionButtons from 'labstep-web/components/Layout/Container/ActionButtons';
import OrganizationSAMLFormShowEdit from 'labstep-web/components/OrganizationSAML/Form/ShowEdit';
import UserActionSetSaml from 'labstep-web/components/User/Action/SetSaml';
import { FieldType } from 'labstep-web/core/Form/Reusable/types';
import HorizontalTable from 'labstep-web/core/Table/Horizontal';
import { OrganizationSAML } from 'labstep-web/models/organization-saml.model';
import { configService } from 'labstep-web/services/config.service';
import React from 'react';
import OrganizationSAMLActionUpdateIsEnabled from '../Action/UpdateIsEnabled';
import { OrganizationSAMLShowProps } from './types';

export const OrganizationSAMLShow: React.FC<
  OrganizationSAMLShowProps
> = ({ organization }) => {
  const organizationSAML =
    organization.organization_saml as OrganizationSAML;
  return (
    <div>
      <h3>Labstep SAML parameters (SP)</h3>
      <HorizontalTable
        padded
        headerProps={{ width: 3 }}
        columns={[organization]}
        rows={[
          {
            header: 'Metadata endpoint',
            content: (): React.ReactNode => (
              <a
                href={`${configService.labstepApiUrl}/public-api/saml/${organization.identifier}/metadata`}
              >
                {configService.labstepApiUrl}/public-api/saml/
                {organization.identifier}/metadata
              </a>
            ),
          },
          {
            header: 'Consumer endpoint',
            content: (): React.ReactNode => (
              <a
                href={`${configService.labstepApiUrl}/public-api/saml/${organization.identifier}/acs`}
              >
                {configService.labstepApiUrl}/public-api/saml/
                {organization.identifier}/acs
              </a>
            ),
          },
          {
            header: 'Logout endpoint',
            content: (): React.ReactNode => (
              <a
                href={`${configService.labstepApiUrl}/public-api/saml/${organization.identifier}/logout`}
              >
                {configService.labstepApiUrl}/public-api/saml/
                {organization.identifier}/logout
              </a>
            ),
          },
        ]}
      />
      <h3>Your organization SAML parameters (IDP)</h3>
      <HorizontalTable
        padded
        headerProps={{ width: 3 }}
        columns={[organization]}
        rows={[
          {
            header: 'XML Path to attribute Email',
            content: (): React.ReactNode => (
              <OrganizationSAMLFormShowEdit
                organizationSAML={organizationSAML}
                fieldType={FieldType.Text}
                name="attribute_email"
                fieldLabel="XML Path to attribute Email (optional)"
                placeholder="Example: [samlNameId]"
              />
            ),
          },
          {
            header: 'XML Path to attribute First Name',
            content: (): React.ReactNode => (
              <OrganizationSAMLFormShowEdit
                organizationSAML={organizationSAML}
                fieldType={FieldType.Text}
                name="attribute_first_name"
                fieldLabel="XML Path to attribute First Name (optional)"
                placeholder="Example: [samlUserdata][http://schemas.xmlsoap.org/ws/2005/05/identity/claims/givenname][0]"
              />
            ),
          },
          {
            header: 'XML Path to attribute Last Name',
            content: (): React.ReactNode => (
              <OrganizationSAMLFormShowEdit
                organizationSAML={organizationSAML}
                fieldType={FieldType.Text}
                name="attribute_last_name"
                fieldLabel="XML Path to attribute Last Name (optional)"
                placeholder="[samlUserdata][http://schemas.xmlsoap.org/ws/2005/05/identity/claims/surname][0]"
              />
            ),
          },
          {
            header: 'Entity ID',
            content: (): React.ReactNode => (
              <OrganizationSAMLFormShowEdit
                organizationSAML={organizationSAML}
                fieldType={FieldType.Text}
                name="idp_entity_id"
                fieldLabel="IDP Entity ID"
                placeholder="Example: https://sts.windows.net/b209745c-7e15-4160-8f05-20a6d0a52cd7/"
              />
            ),
          },
          {
            header: 'Sign On URL',
            content: (): React.ReactNode => (
              <OrganizationSAMLFormShowEdit
                organizationSAML={organizationSAML}
                fieldType={FieldType.Text}
                name="idp_sign_on_url"
                fieldLabel="IDP SignOn URL"
                placeholder="Example: https://sts.windows.net/b209745c-7e15-4160-8f05-20a6d0a52cd7/"
              />
            ),
          },
          {
            header: 'Logout URL',
            content: (): React.ReactNode => (
              <OrganizationSAMLFormShowEdit
                organizationSAML={organizationSAML}
                fieldType={FieldType.Text}
                name="idp_logout_url"
                fieldLabel="IDP Logout URL"
                placeholder="Example: https://sts.windows.net/b209745c-7e15-4160-8f05-20a6d0a52cd7/"
              />
            ),
          },
          {
            header: 'Certificate',
            content: (): React.ReactNode => (
              <OrganizationSAMLFormShowEdit
                organizationSAML={organizationSAML}
                fieldType={FieldType.TextArea}
                name="idp_certificate"
                fieldLabel="IDP Certificate"
                placeholder="-----BEGIN PUBLIC KEY-----XXX-----END PUBLIC KEY-----"
                secret
              />
            ),
          },
        ]}
      />
      <LayoutContainerActionButtons>
        <UserActionSetSaml organization={organization} />
        <OrganizationSAMLActionUpdateIsEnabled
          organizationSAML={organizationSAML}
        />
      </LayoutContainerActionButtons>
    </div>
  );
};

export default OrganizationSAMLShow;
