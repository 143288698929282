/**
 * Labstep
 *
 * @module containers/Ui/Toast
 * @desc Toast container
 */

import { showToast } from 'labstep-web/state/actions/ui';
import { selectToast } from 'labstep-web/state/selectors/toast';
import { connect } from 'react-redux';
import {
  IWithToastMapDispatchToProps,
  IWithToastMapStateToProps,
  WithToastInjectedProps,
} from './types';

const mapStateToProps = (state: any): IWithToastMapStateToProps => ({
  toast: selectToast(state),
});

const mapDispatchToProps: IWithToastMapDispatchToProps = {
  showToast,
};

export const withToast = <P extends WithToastInjectedProps, S>(
  Component: React.FC<P> | React.ComponentClass<P, S>,
): React.FC<
  Pick<P, Exclude<keyof P, keyof WithToastInjectedProps>>
> => connect(mapStateToProps, mapDispatchToProps)(Component as any);
