/**
 * Labstep
 *
 * @module components/PermaLink/Action/Copy
 * @desc Action to copy permalink in clipboard
 */

import ActionCopy from 'labstep-web/core/Action/Copy';
import React from 'react';
import { IPermaLinkActionCopyProps } from './types';

const PermaLinkActionCopy: React.FC<IPermaLinkActionCopyProps> = ({
  entity,
}) => (
  <ActionCopy
    text={entity.perma_link?.url || ''}
    actionComponentProps={{ type: 'icon', icon: 'linkify' }}
    hoverContent="Copy Permalink"
  />
);

export default PermaLinkActionCopy;
