/**
 * Labstep
 *
 * @module components/ExperimentWorkflow/Table
 * @desc Table listing all experimentWorkflows
 */

import { ICONS } from 'labstep-web/constants/icons';
import Link from 'labstep-web/core/Link';
import List from 'labstep-web/core/List';
import TextWithCount from 'labstep-web/core/Text/WithCount';
import React from 'react';
import { ExperimentWorkflowShortcutProps } from './types';

export const ExperimentWorkflowShortcuts: React.FC<
  ExperimentWorkflowShortcutProps
> = ({ experimentWorkflow }) => (
  <List horizontal separation={false}>
    <List.Item>
      <Link
        unstyled
        to="experiment_workflow_show_workflow"
        params={{ id: experimentWorkflow.id }}
      >
        <TextWithCount
          showCountIfZero
          icon={ICONS.protocol_collection.primary}
          count={experimentWorkflow.experiment_count}
          iconProps={{
            color: 'grey',
            popup: {
              content: 'Protocols',
              position: 'bottom center',
            },
          }}
        />
      </Link>
    </List.Item>
    <List.Item>
      <Link
        unstyled
        to="experiment_workflow_show_values"
        params={{ id: experimentWorkflow.id }}
      >
        <TextWithCount
          showCountIfZero
          icon={ICONS.resource.primary}
          count={experimentWorkflow.protocol_value_count}
          iconProps={{
            color: 'grey',
            popup: {
              content: 'Inventory',
              position: 'bottom center',
            },
          }}
        />
      </Link>
    </List.Item>
    <List.Item>
      <Link
        unstyled
        to="experiment_workflow_show_metadata"
        params={{ id: experimentWorkflow.id }}
      >
        <TextWithCount
          showCountIfZero
          icon={ICONS.metadata.default}
          count={experimentWorkflow.metadata_count}
          iconProps={{
            color: 'grey',
            popup: {
              content: 'Data',
              position: 'bottom center',
            },
          }}
        />
      </Link>
    </List.Item>
    <List.Item>
      <Link
        unstyled
        to="experiment_workflow_show_thread"
        params={{ id: experimentWorkflow.id }}
      >
        <TextWithCount
          showCountIfZero
          icon={ICONS.thread.primary}
          count={experimentWorkflow.comment_count}
          iconProps={{
            color: 'grey',
            popup: {
              content: 'Notes',
              position: 'bottom center',
            },
          }}
        />
      </Link>
    </List.Item>
  </List>
);

export default ExperimentWorkflowShortcuts;
