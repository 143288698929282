/**
 * Labstep
 *
 * @module components/PermissionRole/Action/Menu
 * @desc Action Menu for permissionRole
 */

import PermissionRoleActionDelete from 'labstep-web/components/PermissionRole/Action/Delete';
import PermissionRoleActionEdit from 'labstep-web/components/PermissionRole/Action/Edit';
import ActionMenu from 'labstep-web/core/Action/Menu';
import React from 'react';
import { Action, IPermissionRoleActionMenuProps } from './types';

export const PermissionRoleActionMenu: React.FC<
  IPermissionRoleActionMenuProps
> = ({ permissionRole, type, actions = [] }) => (
  <ActionMenu type={type}>
    {actions.includes(Action.edit) && (
      <PermissionRoleActionEdit permissionRole={permissionRole} />
    )}
    {actions.includes(Action.delete) && (
      <PermissionRoleActionDelete permissionRole={permissionRole} />
    )}
  </ActionMenu>
);

PermissionRoleActionMenu.defaultProps = {
  actions: [],
  type: 'icon',
};

export default PermissionRoleActionMenu;
