/**
 * Labstep
 *
 * @module models/data-table
 * @desc Typescript export class for DataTable
 */

import { Entity } from 'labstep-web/models/entity.model';

export class DataTable extends Entity {
  static readonly entityName = 'data_table';

  get entityName(): string {
    return DataTable.entityName;
  }

  constructor(data: Partial<DataTable> = {}) {
    super();
    Object.assign(this, data);
  }

  id!: number;

  content!: string;

  data: Record<string, unknown> | null = null;

  name!: string;
}
