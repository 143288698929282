/**
 * Labstep
 *
 * @module prosemirror/components/ReactNodeView
 * @desc Integrates React into ProseMirror
 * @see https://github.com/johnkueh/prosemirror-react-nodeviews
 */

import { unwrapFromProtocolStep } from 'labstep-web/prosemirror/nodes/protocol-step/commands';
import { getIdAttribute } from 'labstep-web/services/schema/helpers';
import { Node } from 'prosemirror-model';
import { Decoration, EditorView, NodeView } from 'prosemirror-view';
import React, {
  ReactPortal,
  useContext,
  useEffect,
  useRef,
} from 'react';
import ReactDOM from 'react-dom';
import shortid from 'shortid';
import { addAttrsToClass } from './utils';

interface IReactNodeViewContext {
  node: Node;
  view: EditorView;
  getPos: TGetPos;
  decorations: Decoration[];
}

const ReactNodeViewContext = React.createContext<
  Partial<IReactNodeViewContext>
>({
  node: undefined,
  view: undefined,
  getPos: undefined,
  decorations: undefined,
});

type TGetPos = boolean | (() => number);

class ReactNodeView implements NodeView {
  componentRef: React.RefObject<HTMLDivElement>;

  dom!: HTMLElement;

  contentDOM?: HTMLElement;

  component: React.FC<any>;

  node: Node;

  view: EditorView;

  getPos: TGetPos;

  decorations: Decoration[];

  createdPortal: ReactPortal | undefined;

  constructor(
    node: Node,
    view: EditorView,
    getPos: TGetPos,
    decorations: Decoration[],
    component: React.FC<any>,
  ) {
    this.node = node;
    this.view = view;
    this.getPos = getPos;
    this.decorations = decorations;
    this.component = component;
    this.componentRef = React.createRef();
    this.onDelete = this.onDelete.bind(this);
  }

  init() {
    this.dom = document.createElement('div');
    this.dom.contentEditable = 'true';
    this.dom.setAttribute(
      `data-${this.node.type.name}-id`,
      this.node.attrs[getIdAttribute(this.node.type.name)],
    );
    this.dom.classList.add('ProseMirror__dom');
    if (this.node.type.spec.group) {
      this.dom.classList.add(this.node.type.spec.group);
    }
    addAttrsToClass(this.node, this.dom);

    if (
      this.node.type.spec.group === 'inline' ||
      !this.view.editable
    ) {
      this.dom.contentEditable = 'false';
    }

    if (!this.node.isLeaf) {
      this.contentDOM = document.createElement('div');
      this.contentDOM.classList.add('ProseMirror__contentDOM');
      this.dom.appendChild(this.contentDOM);
    }

    return {
      nodeView: this,
      portal: this.renderPortal(this.dom),
    };
  }

  onDelete(guid: string) {
    if (
      ['experiment_step', 'protocol_step'].includes(
        this.node.type.name,
      )
    ) {
      unwrapFromProtocolStep(
        this.view.state,
        this.view.dispatch,
        guid,
      );
    }
  }

  renderPortal(container: HTMLElement) {
    const Component: React.FC = () => {
      const componentRef = useRef<HTMLDivElement>(null);

      useEffect(() => {
        const componentDOM = componentRef.current;
        if (componentDOM !== null && this.contentDOM) {
          if (!this.node.isLeaf) {
            componentDOM.firstChild?.appendChild(this.contentDOM);
          }
        }
      }, []);

      return (
        <div
          ref={componentRef}
          className="ProseMirror__reactComponent"
        >
          <ReactNodeViewContext.Provider
            value={{
              node: this.node,
              view: this.view,
              getPos: this.getPos,
              decorations: this.decorations,
            }}
          >
            <this.component onDelete={this.onDelete} />
          </ReactNodeViewContext.Provider>
        </div>
      );
    };

    this.createdPortal = ReactDOM.createPortal(
      <Component />,
      container,
      shortid.generate(),
    );
    return this.createdPortal;
  }

  update(node: Node, decorations: readonly Decoration[]) {
    if (this.node.attrs !== node.attrs) {
      addAttrsToClass(this.node, this.dom);
    }
    if (decorations && this.node.type.name.includes('step')) {
      this.dom.contentEditable = this.view.editable
        ? 'true'
        : 'false';
    }
    if (
      this.node.type.name !== node.type.name &&
      this.createdPortal
    ) {
      this.createdPortal.children = undefined;
      return false;
    }
    if (
      this.node.type.name === 'experiment_step' ||
      this.node.type.name === 'protocol_step'
    ) {
      return true;
    }
    // It will get updated by Redux
    return false;
  }

  // eslint-disable-next-line class-methods-use-this
  ignoreMutation(mutation: MutationRecord) {
    if ((mutation.type as string) === 'selection') {
      return false;
    }
    // This is required otherwise when navigating away from the
    // editor another transaction is sent causing the
    // step content to disappear and not saved
    return true;
  }

  // eslint-disable-next-line consistent-return
  stopEvent() {
    // prevent deleting table when in edit mode
    if (
      this.node.type.name === 'experiment_table' ||
      this.node.type.name === 'protocol_table' ||
      this.node.type.name === 'conditions' ||
      // https://github.com/Labstep/labstep/issues/7182
      this.node.type.name === 'metadata' ||
      this.node.type.name === 'molecule'
    ) {
      // https://github.com/Labstep/web/issues/6519
      // Prevent cut / copy to act in the whole document
      // if (event instanceof KeyboardEvent) {
      //   if (
      //     (event.metaKey || event.ctrlKey) &&
      //     ['c', 'x', 'v'].indexOf(event.key) > -1
      //   ) {
      //     event.preventDefault();
      //   }
      // }
      return true;
    }
    return false;
  }

  destroy() {
    if (this.createdPortal) {
      this.createdPortal.children = undefined;
    }
    this.dom.remove();
  }
}

interface TCreateReactNodeView extends IReactNodeViewContext {
  component: React.FC<any>;
  onCreatePortal: (portal: any) => void;
}

export const createReactNodeView = ({
  node,
  view,
  getPos,
  decorations,
  component,
  onCreatePortal,
}: TCreateReactNodeView) => {
  const reactNodeView = new ReactNodeView(
    node,
    view,
    getPos,
    decorations,
    component,
  );
  const { nodeView, portal } = reactNodeView.init();
  onCreatePortal(portal);

  return nodeView;
};
export const useReactNodeView = () =>
  useContext(ReactNodeViewContext);
export default ReactNodeView;
