/**
 * Labstep
 *
 * @module screens/EntityStateWorkflow/Show/Center
 * @desc EntityStateWorkflow Show Center
 */

import { useHasAccess } from 'labstep-web/components/Entity/Can';
import { Action as PermissionAction } from 'labstep-web/components/Entity/Can/types';
import { EntityStateWorkflowActionConfigureWorkflowTrigger } from 'labstep-web/components/EntityStateWorkflow/Action/ConfigureWorkflow/Trigger';
import EntityStateWorkflowActionMenu from 'labstep-web/components/EntityStateWorkflow/Action/Menu';
import { Action } from 'labstep-web/components/EntityStateWorkflow/Action/Menu/types';
import EntityStateWorkflowShowEditName from 'labstep-web/components/EntityStateWorkflow/Form/ShowEdit/Name';
import EntityStateWorkflowPreview from 'labstep-web/components/EntityStateWorkflow/Preview';
import EntityUserCard from 'labstep-web/components/EntityUser/Card';
import InformationCard from 'labstep-web/core/Card/Information';
import Flex from 'labstep-web/core/Flex';
import React from 'react';
import styles from './styles.module.scss';
import { ICenterProps } from './types';

const Center: React.FC<ICenterProps> = ({ entityStateWorkflow }) => {
  const canEdit = useHasAccess(
    entityStateWorkflow.entityName,
    entityStateWorkflow.id,
    PermissionAction.edit,
  );
  return (
    <InformationCard
      additionalActions={
        <>
          <EntityUserCard entity={entityStateWorkflow} />
        </>
      }
      actionMenu={
        <EntityStateWorkflowActionMenu
          entityStateWorkflow={entityStateWorkflow}
          actions={[
            Action.duplicate,
            Action.share,
            Action.delete,
            Action.transfer_ownership,
            Action.assign,
          ]}
        />
      }
      primaryInfo={
        <EntityStateWorkflowShowEditName
          entityStateWorkflow={entityStateWorkflow}
        />
      }
      bottom={
        <Flex
          column
          className={styles.previewContainer}
          spacing="gap"
        >
          <Flex hAlignContent="left">
            <EntityStateWorkflowActionConfigureWorkflowTrigger
              entityStateWorkflow={entityStateWorkflow}
              buttonType="button"
              text="Edit"
              buttonProps={{
                icon: 'pencil',
              }}
              showIcon
            />
          </Flex>
          <EntityStateWorkflowActionConfigureWorkflowTrigger
            entityStateWorkflow={entityStateWorkflow}
            viewComponent={({ toggleModal }) => (
              <div onClick={canEdit ? toggleModal : () => null}>
                <EntityStateWorkflowPreview
                  entityStateWorkflow={entityStateWorkflow}
                />
              </div>
            )}
          />
        </Flex>
      }
    />
  );
};

export default Center;
