/**
 * Labstep
 *
 * @module core/Loader/TimeEnabled
 * @desc A loader that shows a message if it's taking too long to load
 */

import Loader from 'labstep-web/core/Loader';
import React from 'react';
import styles from './styles.module.scss';
import {
  ILoaderTimeEnabledProps,
  ILoaderTimeEnabledState,
} from './types';

export class LoaderTimeEnabled extends React.Component<
  ILoaderTimeEnabledProps,
  ILoaderTimeEnabledState
> {
  asyncShowingDound: any;

  asyncTakingLongDebounce: any;

  constructor(props: ILoaderTimeEnabledProps) {
    super(props);
    this.setActiveFalse = this.setActiveFalse.bind(this);
    this.state = {
      showing: false,
      takingLong: false,
      mounted: false,
    };
  }

  componentDidMount() {
    const { showingDebound, takingLongDebounce } = this.props;
    this.asyncShowingDound = setTimeout(() => {
      this.setState({ showing: true });
    }, showingDebound || 1000);
    this.asyncTakingLongDebounce = setTimeout(() => {
      this.setState({ takingLong: true });
    }, takingLongDebounce || 3000);
  }

  componentWillUnmount() {
    clearTimeout(this.asyncShowingDound);
    clearTimeout(this.asyncTakingLongDebounce);
  }

  setActiveFalse() {
    this.setState({ mounted: true });
  }

  render() {
    const { mounted, showing, takingLong } = this.state;
    const { children, style } = this.props;

    return (
      <div style={style}>
        {!mounted && showing && (
          <Loader
            className={styles.loader}
            inverted
            inline
            children={takingLong ? 'This might take a while...' : ''}
            active
          />
        )}
        {children({ setActiveFalse: this.setActiveFalse })}
      </div>
    );
  }
}

export default LoaderTimeEnabled;
