/**
 * Labstep
 *
 * @module components/ResourceItem/Action/Create
 * @desc Create Action for resource item
 */

import { CanCreate } from 'labstep-web/components/Entity/Can';
import ResourceItemFormCreate from 'labstep-web/components/ResourceItem/Form/Create';
import ActionComponent from 'labstep-web/core/Action/Component';
import ButtonWithDropdown from 'labstep-web/core/Button/WithDropdown';
import ModalDefault from 'labstep-web/core/Modal/Default';
import { IOnSuccessProps } from 'labstep-web/models/action.model';
import { ResourceItem } from 'labstep-web/models/resource-item.model';
import { Resource } from 'labstep-web/models/resource.model';
import { navigation } from 'labstep-web/services/navigation';
import { enhanceOptions } from 'labstep-web/services/utils.service';
import React from 'react';
import { withRouter } from 'react-router';
import { IResourceItemActionCreateProps } from './types';

const text = `New ${ResourceItem.getHumanReadableEntityName(
  false,
  true,
)}`;

export const ResourceItemActionCreate: React.FC<
  IResourceItemActionCreateProps
> = ({
  resource,
  history,
  resourceTemplate,
  withRedirect = true,
  actionComponentProps,
  options,
  protocolValueOrigin,
  extraOptions,
}) => {
  const defaultOptions = {
    toast: !withRedirect,
    onSuccess: ({ response }: IOnSuccessProps) => {
      if (withRedirect) {
        history.push(
          navigation.get('resource_item_show', {
            id: response.result,
          }),
        );
      }
    },
  };
  const enhancedOptions = enhanceOptions({
    ...defaultOptions,
    options,
  } as any);
  const entityActionCreate = (
    <CanCreate entityName={Resource.entityName}>
      <ModalDefault
        dataTestId="modal-resource-item-create"
        header={text}
        content={({ toggleModal }) => (
          <ResourceItemFormCreate
            resource={resource}
            resourceTemplate={resourceTemplate}
            options={enhanceOptions({
              ...enhancedOptions,
              options: { onSuccess: toggleModal },
            })}
            protocolValueOrigin={protocolValueOrigin}
          />
        )}
        viewComponent={({ toggleModal }) => (
          <ActionComponent
            type="button"
            text={text}
            {...actionComponentProps}
            onClick={toggleModal}
          />
        )}
      />
    </CanCreate>
  );

  return extraOptions ? (
    <CanCreate entityName="resource">
      <ButtonWithDropdown
        primaryAction={entityActionCreate}
        extraOptions={extraOptions}
      />
    </CanCreate>
  ) : (
    entityActionCreate
  );
};

export default withRouter(ResourceItemActionCreate);
