/**
 * Labstep
 *
 * @module prosemirror/components/Toolbar/Alignment
 * @desc Buttons to set Alignment attrs
 */

import ActionComponent from 'labstep-web/core/Action/Component';
import Dropdown from 'labstep-web/core/Dropdown';
import { IconProps } from 'labstep-web/core/Icon/types';
import ToolbarButton from 'labstep-web/prosemirror/components/Toolbar/Button';
import { setTextAlign } from 'labstep-web/prosemirror/extensions/alignment/commands';
import { getActiveAlignment } from 'labstep-web/prosemirror/extensions/alignment/utils';
import { modToPlatformText } from 'labstep-web/prosemirror/utils';
import { startCase } from 'lodash';
import React from 'react';
import { IAlignmentProps, IAlignmentToolbarProps } from './types';

export const Alignment: React.FC<IAlignmentProps> = ({
  alignment,
  dispatch,
  state,
  shortcut,
}) => (
  <ActionComponent
    type="option"
    icon={`align ${alignment}` as IconProps['name']}
    text={`${
      alignment === 'justify'
        ? 'Justify'
        : `Align ${startCase(alignment)}`
    } (${shortcut})`}
    onClick={() => setTextAlign(alignment)(state, dispatch)}
  />
);

const AlignmentToolbar: React.FC<IAlignmentToolbarProps> = (
  props,
) => {
  const shortcutPrefix = modToPlatformText();

  const activeAlignment = getActiveAlignment(props.state);

  return (
    <div style={{ display: 'flex' }}>
      <Dropdown
        icon={false}
        trigger={
          <ToolbarButton
            icon={`align ${activeAlignment || 'left'}` as any}
            popup="Alignment"
            isActive={!!activeAlignment}
          />
        }
      >
        <Alignment
          {...props}
          alignment="left"
          shortcut={`${shortcutPrefix}+⇧+L`}
        />

        <Alignment
          {...props}
          alignment="center"
          shortcut={`${shortcutPrefix}+⇧+E`}
        />

        <Alignment
          {...props}
          alignment="right"
          shortcut={`${shortcutPrefix}+⇧+R`}
        />

        <Alignment
          {...props}
          alignment="justify"
          shortcut={`${shortcutPrefix}+⇧+J`}
        />
      </Dropdown>
    </div>
  );
};

export default AlignmentToolbar;
