/**
 * Labstep
 *
 * @module components/Metadata/CardList
 * @desc List of metadatas
 */

import {
  DragDropContext,
  Draggable,
  DropResult,
  Droppable,
} from '@hello-pangea/dnd';
import { useHasAccess } from 'labstep-web/components/Entity/Can';
import { Action } from 'labstep-web/components/Entity/Can/types';
import MetadataActionCreateFile from 'labstep-web/components/Metadata/Action/Create/File';
import MetadataCard from 'labstep-web/components/Metadata/Card';
import { EntityUpdateAnyContainer } from 'labstep-web/containers/Entity/Update/Any';
import List from 'labstep-web/core/List';
import { ReadOnMountHOC } from 'labstep-web/hoc/ReadOnMount';
import { Metadata } from 'labstep-web/models/metadata';
import { PositionService } from 'labstep-web/services/position.service';
import React from 'react';
import { MetadataCardListProps } from './types';

export const MetadataCardList: React.FC<MetadataCardListProps> = ({
  entity,
  draggable = true,
  hideActions,
}) => {
  const canEdit = useHasAccess(
    entity.entityName,
    entity.id,
    Action.edit,
  );
  return (
    <MetadataActionCreateFile
      metadataThreadId={entity.metadata_thread.id}
    >
      <ReadOnMountHOC
        type="cursor"
        entityName={Metadata.entityName}
        params={{
          metadata_thread_id: entity.metadata_thread.id,
          has_variable_template: false,
        }}
        children={({ entities }) => (
          <EntityUpdateAnyContainer entityName={Metadata.entityName}>
            {({ update }) => (
              <DragDropContext
                onDragEnd={(result: DropResult) =>
                  PositionService.onDragEnd(result, entities, update)
                }
              >
                <Droppable
                  droppableId="metadata-list"
                  isDropDisabled={!canEdit || !draggable}
                >
                  {(provided) => (
                    <div id="metadata-list" ref={provided.innerRef}>
                      <List>
                        {entities
                          .sort(
                            (a: Metadata, b: Metadata) =>
                              a.position - b.position,
                          )
                          .map(
                            (metadata: Metadata, index: number) => (
                              <Draggable
                                key={`metadata-${metadata.id}`}
                                draggableId={`metadata:${metadata.guid}`}
                                index={index}
                              >
                                {(draggableProvided) => (
                                  <div
                                    ref={draggableProvided.innerRef}
                                    {...draggableProvided.draggableProps}
                                    {...draggableProvided.dragHandleProps}
                                  >
                                    <MetadataCard
                                      entity={entity}
                                      metadata={metadata as Metadata}
                                      hideActions={hideActions}
                                    />
                                  </div>
                                )}
                              </Draggable>
                            ),
                          )}
                      </List>
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            )}
          </EntityUpdateAnyContainer>
        )}
      />
    </MetadataActionCreateFile>
  );
};

export default MetadataCardList;
