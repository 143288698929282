/**
 * Labstep
 *
 * @module components/ProtocolCollection/ProtocolCollection
 * @desc Table listing all protocolCollections
 */

import EntityTable from 'labstep-web/components/Entity/Table';
import { DefaultSort } from 'labstep-web/components/Entity/Table/SortOptions/types';
import ProtocolLabelStatus from 'labstep-web/components/Protocol/Label/Status';
import ProtocolCollectionActionMenu from 'labstep-web/components/ProtocolCollection/Action/Menu';
import ProtocolConditionActionCompareData from 'labstep-web/components/ProtocolCondition/Action/CompareData';
import { ProtocolCollection } from 'labstep-web/models/protocol-collection.model';
import React from 'react';
import ProtocolCollectionActionRun from '../Action/Run';
import styles from './styles.module.scss';
import { IProtocolCollectionTableProps } from './types';

export const ProtocolCollectionTable: React.FC<
  IProtocolCollectionTableProps
> = ({ protocolCollections, actions, ...rest }) => {
  const extraColumns = [
    {
      header: '',
      content: (protocolCollection: ProtocolCollection) => (
        <div className={styles.container}>
          {protocolCollection.draft_version && (
            <ProtocolLabelStatus
              protocol={protocolCollection.draft_version}
              showVersionLabel
            />
          )}
          {protocolCollection.last_version && (
            <ProtocolLabelStatus
              protocol={protocolCollection.last_version}
              showVersionLabel
            />
          )}
          <ProtocolCollectionActionRun
            protocolCollection={protocolCollection}
          />
          <ProtocolConditionActionCompareData
            actionComponentType="button"
            protocolCollection={protocolCollection}
          />
        </div>
      ),
      cellProps: { style: { display: 'flex', alignItems: 'center' } },
    },
  ];

  return (
    <EntityTable
      {...rest}
      dataCy="components/ProtocolCollection/Table"
      id="protocol-collection-table"
      entities={protocolCollections}
      extraColumns={extraColumns}
      sortOptions={{
        name: {
          label: 'Name',
          default_sort: DefaultSort.asc,
        },
        updated_at: {
          label: 'Last Updated',
          default_sort: DefaultSort.desc,
        },
        experiment_created_at: {
          label: 'Last Used',
          default_sort: DefaultSort.desc,
        },
        experiment_count: {
          label: 'Most Used',
          default_sort: DefaultSort.desc,
        },
      }}
      editableName
      actionMenu={({ entity }) => (
        <ProtocolCollectionActionMenu
          protocolCollection={entity}
          actions={actions}
        />
      )}
    />
  );
};

export default ProtocolCollectionTable;
