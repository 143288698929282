/**
 * Labstep
 *
 * @module core/NavLink
 * @desc Wrapper around react-router-dom NavLink
 */

import cx from 'classnames';
import { navigation } from 'labstep-web/services/navigation';
import React from 'react';
import { NavLink as RouterNavLink } from 'react-router-dom';
import styles from './styles.module.scss';
import { INavLinkProps } from './types';

const NavLink: React.FC<INavLinkProps> = ({
  children,
  to,
  params,
  unstyled,
  className,
  query,
  ...rest
}) => {
  const cxArgs = [{ [styles.unstyled]: unstyled }];
  if (className) {
    cxArgs.push({ [className]: !!className });
  }
  const classes = cx(...cxArgs);
  return (
    <RouterNavLink
      className={classes}
      to={navigation.get(to, params, false, query)}
      {...rest}
    >
      {children}
    </RouterNavLink>
  );
};

export default NavLink;
