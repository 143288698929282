/**
 * Labstep
 *
 * @module services/position
 * @desc Service to reorder entities
 */

import { DropResult } from '@hello-pangea/dnd';
import { EntityUpdateAnyContainerChildrenProps } from 'labstep-web/containers/Entity/Update/Any';
import { getPosition } from 'labstep-web/core/Sortable/Container/utils';
import { cloneDeep } from 'lodash';

export interface PositionParent {
  id: number | string;
  guid: string;
  position: number;
}

export class PositionService {
  static computeNewPosition(
    positionParents: PositionParent[],
    newIndex: number,
    currentIndex: number,
  ) {
    if (newIndex === 0) {
      return positionParents[0].position / 2;
    }
    if (newIndex === positionParents.length - 1) {
      return positionParents[positionParents.length - 1].position + 1;
    }

    if (newIndex < currentIndex) {
      const previousPosition = positionParents[newIndex - 1].position;
      const nextPosition = positionParents[newIndex].position;
      return (previousPosition + nextPosition) / 2;
    }

    const previousPosition = positionParents[newIndex].position;
    const nextPosition = positionParents[newIndex + 1].position;
    return (previousPosition + nextPosition) / 2;
  }

  static onDragEnd(
    result: DropResult,
    positionParents: PositionParent[],
    update: EntityUpdateAnyContainerChildrenProps['update'],
  ) {
    const positionParentsInOrder = cloneDeep(positionParents).sort(
      (a, b) => a.position - b.position,
    );
    const draggableId = result.draggableId.split(':');
    const currentIndex = positionParents.findIndex(
      (p) => p.guid === draggableId[1],
    );
    const newIndex = result.destination?.index;
    if (
      newIndex !== undefined &&
      newIndex !== null &&
      currentIndex !== newIndex
    ) {
      const newPosition = PositionService.computeNewPosition(
        positionParentsInOrder,
        newIndex,
        currentIndex,
      );

      const entityId = positionParents[currentIndex].id;

      update(
        entityId,
        {
          position: newPosition,
        },
        { optimistic: true },
      );
    }
  }
}
