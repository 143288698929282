/**
 * Labstep
 *
 * @module components/Entity/Link
 * @desc Link to an entity show page
 */

import { fieldEntityName } from 'labstep-web/components/Entity/Form/fields';
import FormShowEditLink from 'labstep-web/core/Form/ShowEdit/Link';
import Link from 'labstep-web/core/Link';
import { IRouting } from 'labstep-web/services/navigation.types';
import React from 'react';
import EntityLinkComponent from './Component';
import { EntityLinkProps } from './types';

export const EntityLink: React.FC<EntityLinkProps> = ({
  entity,
  editableName,
  showIcon,
  secondary,
  to,
  displayName,
  children,
  disabled,
  linkParams = () => ({}),
}) => {
  const link = (
    <Link
      to={to || (`${entity.entityName}_show` as keyof IRouting)}
      params={{ id: entity.idAttr, ...linkParams(entity) }}
      disabled={disabled}
    >
      {children || (
        <EntityLinkComponent
          secondary={secondary}
          showIcon={showIcon}
          displayName={displayName}
          entity={entity}
          disabled={disabled}
        />
      )}
    </Link>
  );

  if (editableName) {
    return (
      <FormShowEditLink
        link={link}
        entity={entity}
        field={fieldEntityName(entity.entityName)}
        value={entity.displayName}
        entityName={entity.entityName}
      />
    );
  }

  return link;
};

export default EntityLink;
