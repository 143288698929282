/**
 * Labstep
 *
 * @module components/Academic/Wizard/Setup
 * @desc Show modal with wizard to complete academic account
 */

import GroupListTransfer from 'labstep-web/components/Group/List/Transfer';
import OrganizationFormCreate from 'labstep-web/components/Organization/Form/Create';
import OrganizationInviteMembers from 'labstep-web/components/Organization/InviteMembers';
import { withAuthenticatedUserEntity } from 'labstep-web/containers/AuthenticatedUser';
import { EntityUpdateContainer } from 'labstep-web/containers/Entity/Update';
import { ModalWizardContext } from 'labstep-web/contexts/modal-wizard';
import ActionComponent from 'labstep-web/core/Action/Component';
import ModalWizard from 'labstep-web/core/Modal/Wizard';
import { User } from 'labstep-web/models/user.model';
import { navigation } from 'labstep-web/services/navigation';
import React from 'react';
import { withRouter } from 'react-router';
import styles from './styles.module.scss';
import { AcademicWizardSetupProps } from './types';

const ROUTE_ID = 'academic-setup';

export const AcademicWizardSetup: React.FC<
  AcademicWizardSetupProps
> = ({ actionComponentProps, authenticatedUserEntity, history }) => {
  // Set shouldHide to true if the user already has an organization
  // before it begins this journey. Needs to be in state because half way
  // through the journey the user will create an organization and we want
  // to keep it open
  const [shouldHide] = React.useState(
    !!authenticatedUserEntity.userOrganization,
  );
  if (shouldHide) {
    return null;
  }

  return (
    <EntityUpdateContainer
      entityName={User.entityName}
      id={authenticatedUserEntity.guid}
    >
      {({ update, status }) => (
        <ModalWizard
          startIndex={
            authenticatedUserEntity.userOrganizationOrganization
              ? 1
              : 0
          }
          open
          closeIcon={false}
          mode="fullscreen"
          routeId={ROUTE_ID}
          title="Upgrade"
          trigger={({ toggleModal }) => (
            <ActionComponent
              type="button"
              {...actionComponentProps}
              onClick={() => toggleModal()}
            />
          )}
          steps={[
            'Create Organization',
            'Transfer Workspaces',
            'Invite Users',
            'Getting Started',
          ]}
        >
          <ModalWizard.Step index={0} hideContinueButton>
            <ModalWizardContext.Consumer>
              {({ goForward }) => (
                <div className={styles.container}>
                  <p>
                    Your new organization will house your workspaces
                    and users
                  </p>
                  <OrganizationFormCreate
                    options={{
                      onSuccess: () => {
                        goForward();
                      },
                    }}
                  />
                </div>
              )}
            </ModalWizardContext.Consumer>
          </ModalWizard.Step>
          <ModalWizard.Step
            index={1}
            hideBackButton
            hideContinueButton
          >
            <ModalWizardContext.Consumer>
              {({ goForward }) => (
                <div className={styles.container}>
                  <GroupListTransfer
                    options={{
                      onSuccess: () => {
                        goForward();
                      },
                    }}
                  />
                </div>
              )}
            </ModalWizardContext.Consumer>
          </ModalWizard.Step>
          <ModalWizard.Step
            index={2}
            hideBackButton
            hideContinueButton
          >
            <ModalWizardContext.Consumer>
              {({ goForward }) => (
                <div className={styles.container}>
                  <OrganizationInviteMembers
                    organization={
                      authenticatedUserEntity.userOrganizationOrganization
                    }
                    options={{
                      onSuccess: () => {
                        goForward();
                      },
                    }}
                  />
                </div>
              )}
            </ModalWizardContext.Consumer>
          </ModalWizard.Step>
          <ModalWizard.Step
            index={3}
            hideBackButton
            hideContinueButton
          >
            <div className={styles.container}>
              <p>Welcome to your new Labstep Organization!</p>
              <p>
                You now have access to the Organization layer of
                Labstep which brings all your workspaces and users
                into one place.
              </p>
              <img
                src="/img/organization/org-demo.gif"
                alt="Gif showing how to manage permissions for organizations"
              />
              <p>
                Manage the permissions for each user across all your
                workspaces, set-up Custom Roles and enjoy access to
                premium features such as Timemachine, and Two Factor
                Authentication.
              </p>
              <ActionComponent
                type="button"
                text="Let's Go!"
                status={status}
                onClick={() => {
                  update(
                    {
                      is_organization_setup_completed: true,
                    },
                    {
                      onSuccess: () => {
                        history.push(
                          navigation.get(
                            'organization_show_billing',
                            {
                              identifier:
                                authenticatedUserEntity.userOrganizationOrganization!
                                  .identifier,
                            },
                          ),
                        );
                      },
                    },
                  );
                }}
              />
            </div>
          </ModalWizard.Step>
        </ModalWizard>
      )}
    </EntityUpdateContainer>
  );
};

export default withRouter(
  withAuthenticatedUserEntity(AcademicWizardSetup),
);
