/**
 * Labstep
 *
 * @module components/Group/Action/SetIsCompletionChecklistEnabled
 * @desc Group field required
 */

import EntityActionUpdateCheckbox from 'labstep-web/components/Entity/Action/Update/Checkbox';
import React from 'react';
import { GroupActionSetIsCompletionChecklistEnabledProps } from './types';

export const GroupActionSetIsCompletionChecklistEnabled: React.FC<
  GroupActionSetIsCompletionChecklistEnabledProps
> = ({ group }) => (
  <EntityActionUpdateCheckbox
    entity={group}
    field={'is_completion_checklist_enabled' as const}
    optimistic
    toggle
  />
);

export default GroupActionSetIsCompletionChecklistEnabled;
