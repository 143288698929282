/**
 * Labstep
 *
 * @module components/PurchaseOrder/Action/Delete
 * @desc Action to delete
 */

import EntityActionDelete from 'labstep-web/components/Entity/Action/Delete';
import React from 'react';
import { IPurchaseOrderDeleteActionProps } from './types';

export const PurchaseOrderDeleteAction: React.FC<
  IPurchaseOrderDeleteActionProps
> = ({ purchaseOrder, actionComponentProps }) => (
  <EntityActionDelete
    actionComponentProps={actionComponentProps}
    entity={purchaseOrder}
  />
);

export default PurchaseOrderDeleteAction;
