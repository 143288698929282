/**
 * Labstep
 */

import ProtocolConditionActionViewVariableManager from 'labstep-web/components/ProtocolCondition/Action/ViewVariableManager';
import { ProtocolConditionModalContextType } from 'labstep-web/components/ProtocolCondition/Modal/types';
import { ICONS } from 'labstep-web/constants/icons';
import { ActionComponent } from 'labstep-web/core/Action/Component';
import {
  ColDef,
  ColGroupDef,
  IDataGridProps,
} from 'labstep-web/core/DataGrid/types';
import colDefMetadata from 'labstep-web/grid/SmartTable/coldefs/metadata';
import colDefAmount from 'labstep-web/grid/SmartTable/coldefs/protocolValue/amount';
import colDefResourceItem from 'labstep-web/grid/SmartTable/coldefs/protocolValue/resourceItem';
import colDefRowNumber from 'labstep-web/grid/SmartTable/coldefs/rowNumber';
import { getChildren } from 'labstep-web/grid/SmartTableProtocolCondition/utils';
import { Experiment } from 'labstep-web/models/experiment.model';
import {
  ProtocolCondition,
  ProtocolConditionVariableType,
} from 'labstep-web/models/protocol-condition.model';
import { ProtocolValue } from 'labstep-web/models/protocol-value.model';
import { Protocol } from 'labstep-web/models/protocol.model';
import styles from './styles.module.scss';
import { getDisplayName } from './utils';

/**
 * Get column defs on empty state (level=1)
 * @param protocol Experiment / Protocol
 * @param isInput Is input action
 * @returns Column definitions
 */
export const getColDefsEmptyState = (
  context: ProtocolConditionModalContextType,
  protocol: Experiment | Protocol,
  isInput = false,
  isDouble = false,
): (ColGroupDef<ProtocolCondition> | ColDef<ProtocolCondition>)[] => {
  const children = {
    headerComponentParams: {
      action: (
        <ActionComponent
          type="button"
          text={`Add variable ${isInput ? 'inputs' : 'outputs'}`}
          elementProps={{
            className: styles.action,
          }}
          onClick={(): void => {
            context.setVariableManager(
              isInput ? 'inputs' : 'outputs',
            );
            context.setIsOpen(protocol);
          }}
        />
      ),
    },
  };
  return [getChildren(children, isDouble)];
};

/**
 * Get protocol children column defs (level=1)
 * @param variables ProtocolValue / Metadata variables
 * @returns Column definitions
 */
export const getColDefsChildren = (
  variables: ProtocolConditionVariableType[],
  context: ProtocolConditionModalContextType,
  isDouble = false,
): IDataGridProps['columnDefs'] =>
  variables.map((variable) => {
    if (variable.field_type === ProtocolValue.entityName) {
      return {
        headerName: getDisplayName(variable),
        headerGroupComponentParams: {
          icon: ICONS.resource.primary,
          onClick: context.isOpen
            ? (): void => context.setActiveVariable(variable)
            : undefined,
        },
        children: [
          colDefAmount(variable),
          colDefResourceItem(variable),
        ],
      };
    }

    const children = colDefMetadata(variable, context);
    return getChildren(children, isDouble);
  });

export const getColDefRowNumber = (
  isDouble?: boolean,
): ColGroupDef<ProtocolCondition> | ColDef<ProtocolCondition> =>
  isDouble
    ? {
        children: [getChildren(colDefRowNumber, true)],
      }
    : colDefRowNumber;

/**
 * Get input/output column defs (level=0)
 * @param protocol Experiment / Protocol
 * @returns Column definitions
 */
export const getColDefs = (
  protocol: Experiment | Protocol,
  context: ProtocolConditionModalContextType,
): NonNullable<IDataGridProps['columnDefs']> => {
  const variables = protocol.protocol_condition_variables;
  const hasInputVariables =
    !!protocol.protocol_condition_variables.find((v) => v.is_input);
  const hasOutputVariables =
    !!protocol.protocol_condition_variables.find((v) => !v.is_input);
  const isDouble = !!protocol.protocol_condition_variables.find(
    (v) => v.field_type === ProtocolValue.entityName,
  );
  return [
    getColDefRowNumber(isDouble),
    {
      headerName: 'Inputs',
      headerGroupComponentParams: {
        onClick: (): void => context.setVariableManager('inputs'),
        action: (
          <ProtocolConditionActionViewVariableManager
            protocol={protocol}
            type="inputs"
          />
        ),
        icon: ICONS.protocol_condition.info.inputs,
      },
      children: !hasInputVariables
        ? getColDefsEmptyState(context, protocol, true, isDouble)
        : getColDefsChildren(
            variables.filter((v) => v.is_input),
            context,
            isDouble,
          ) || undefined,
    },
    {
      headerName: 'Outputs',
      headerGroupComponentParams: {
        onClick: (): void => context.setVariableManager('outputs'),
        action: (
          <ProtocolConditionActionViewVariableManager
            protocol={protocol}
            type="outputs"
          />
        ),
        icon: ICONS.protocol_condition.info.outputs,
      },
      children: !hasOutputVariables
        ? getColDefsEmptyState(context, protocol, false, isDouble)
        : getColDefsChildren(
            variables.filter((v) => !v.is_input),
            context,
            isDouble,
          ) || undefined,
    },
  ];
};
