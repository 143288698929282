/**
 * Labstep
 *
 * @module components/ResourceItem/ListOrTable
 * @desc List or table of resourceItems
 */

import ResourceItemList from 'labstep-web/components/ResourceItem/List';
import GridResourceItemContainer from 'labstep-web/grid/IndexResourceItem';
import MasterIndexCenterListOrTable from 'labstep-web/screens/Master/Index/Center/ListOrTable';
import React from 'react';
import { actions } from './constants';
import { IResourceItemListOrTableProps } from './types';

export const ResourceItemListOrTable: React.FC<
  IResourceItemListOrTableProps
> = ({
  resourceItems,
  templateId,
  status,
  loadMore,
  tableFormat,
}) => (
  <MasterIndexCenterListOrTable
    tableComponent={
      <GridResourceItemContainer
        resourceItems={resourceItems}
        templateId={templateId}
        loadMore={loadMore}
        status={status}
      />
    }
    listComponent={
      <ResourceItemList
        resourceItems={resourceItems}
        actions={actions}
      />
    }
    tableFormat={tableFormat}
  />
);

export default ResourceItemListOrTable;
