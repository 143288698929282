/**
 * Labstep
 *
 * @module components/Experiment/List
 * @desc Experiment List
 */

import PurchaseOrderActionMenu from 'labstep-web/components/PurchaseOrder/Action/Menu';
import PurchaseOrderStatus from 'labstep-web/components/PurchaseOrder/Status';
import CardGeneric from 'labstep-web/core/Card/Generic';
import List from 'labstep-web/core/List';
import React from 'react';
import styles from './styles.module.scss';
import { IPurchaseOrderListProps } from './types';

const PurchaseOrderList: React.FC<IPurchaseOrderListProps> = ({
  purchaseOrders,
  actions,
}) => (
  <List className={styles.feed} id="purchase-order-list">
    {purchaseOrders.map((purchaseOrder) => (
      <CardGeneric
        key={purchaseOrder.id}
        mainAction={
          <PurchaseOrderStatus purchaseOrder={purchaseOrder} />
        }
        headerTitle={{
          to: 'purchase_order_show',
          params: { id: purchaseOrder.id },
          title: purchaseOrder.name,
        }}
        headerSubtitle={{ date: purchaseOrder.created_at }}
        actionMenu={
          <PurchaseOrderActionMenu
            purchaseOrder={purchaseOrder}
            actions={actions}
          />
        }
      />
    ))}
  </List>
);

export default PurchaseOrderList;
