/**
 * Labstep
 *
 * @module components/PurchaseOrder/Form/ShowEdit/Currency
 * @desc Currency showedit field
 */

import { fieldPurchaseOrderCurrency } from 'labstep-web/components/PurchaseOrder/Form/fields';
import ShowEditSelect from 'labstep-web/core/Form/ShowEdit/Select';
import React from 'react';
import { IPurchaseOrderFormShowEditCurrencyProps } from './types';

const PurchaseOrderFormShowEditCurrency: React.FC<
  IPurchaseOrderFormShowEditCurrencyProps
> = ({ purchaseOrder }) => (
  <ShowEditSelect
    entity={purchaseOrder}
    field={fieldPurchaseOrderCurrency}
  >
    {purchaseOrder.currency}
  </ShowEditSelect>
);

export default PurchaseOrderFormShowEditCurrency;
