/**
 * Labstep
 *
 * @module components/Group/Card/About
 * @desc About card for group
 */

import GroupCardAboutMembers from 'labstep-web/components/Group/Card/About/Members';
import GroupFormShowEditDescription from 'labstep-web/components/Group/Form/ShowEdit/Description';
import { fieldName } from 'labstep-web/components/Group/Form/fields';
import ProfilePicture from 'labstep-web/components/Profile/Picture';
import { withAuthenticatedUserEntity } from 'labstep-web/containers/AuthenticatedUser';
import Flex from 'labstep-web/core/Flex';
import ShowEditText from 'labstep-web/core/Form/ShowEdit/Text';
import Header from 'labstep-web/core/Header';
import { Size } from 'labstep-web/core/Initials/types';
import React from 'react';
import styles from './styles.module.scss';
import { IGroupCardAboutProps } from './types';

export const GroupCardAbout: React.FC<IGroupCardAboutProps> = ({
  group,
  authenticatedUserEntity,
}) => (
  <div className={styles.container}>
    <div>
      <ProfilePicture
        bordered
        entity={group}
        size={Size.small}
        circular={false}
        rounded
        uploadable
      />
    </div>
    <Flex column grow spacing="gap" className={styles.info}>
      <div className={styles.name}>
        <ShowEditText
          entityName={group.entityName}
          entity={group}
          field={{ ...fieldName, header: true }}
        >
          <Header isDataPublic={false} size="small" noMargin>
            {group.name}
          </Header>
        </ShowEditText>
        <GroupFormShowEditDescription group={group} />
      </div>
      {(group.logged_user_user_group ||
        authenticatedUserEntity.isOrganizationAdmin) && (
        <GroupCardAboutMembers
          group={group}
          manageMembers={
            authenticatedUserEntity.isOrganizationAdmin ||
            group.logged_user_user_group?.type === 'owner'
          }
        />
      )}
    </Flex>
  </div>
);

export default withAuthenticatedUserEntity(GroupCardAbout);
