/**
 * Labstep
 *
 * @module components/ExperimentWorkflow/Filter/Status/Select
 * @desc Select filter for status
 */

import { ICONS } from 'labstep-web/constants/icons';
import { withActiveGroup } from 'labstep-web/containers/ActiveGroup';
import ActionComponent from 'labstep-web/core/Action/Component';
import React from 'react';
import { ProtocolCollectionFilterStatusSelectProps } from './types';

export const ProtocolCollectionStatuses = {
  draft: {
    icon: ICONS.protocol_collection.info.draft,
    text: 'Pending Drafts',
    params: {
      has_draft_version: true,
      has_last_version: undefined,
    },
  },
  live: {
    icon: ICONS.protocol_collection.info.live,
    text: 'Live Protocols',
    params: {
      has_draft_version: undefined,
      has_last_version: true,
    },
  },
};

export const ProtocolCollectionFilterStatusSelect: React.FC<
  ProtocolCollectionFilterStatusSelectProps
> = ({ setParams, searchParams }) => (
  <>
    <ActionComponent
      type="option"
      icon={ProtocolCollectionStatuses.live.icon}
      text="Live Protocols"
      onClick={() =>
        setParams(ProtocolCollectionStatuses.live.params)
      }
    />
    <ActionComponent
      type="option"
      icon={ProtocolCollectionStatuses.draft.icon}
      text="Pending Drafts"
      onClick={() =>
        setParams(ProtocolCollectionStatuses.draft.params)
      }
    />
  </>
);
export default withActiveGroup(ProtocolCollectionFilterStatusSelect);
