/**
 * Labstep
 */

import { ValueGetterParams } from 'ag-grid-community';
import UserPreview from 'labstep-web/components/User/Preview';
import { ICONS } from 'labstep-web/constants/icons';
import {
  CellRendererParams,
  ColDef,
} from 'labstep-web/core/DataGrid/types';
import { Entity } from 'labstep-web/models/entity.model';
import {
  OrderRequest,
  OrderRequestStatus,
  OrderRequestStatusValues,
} from 'labstep-web/models/order-request.model';

export const colDefLogAuthor = (
  status: OrderRequestStatus,
): ColDef<OrderRequest> => ({
  colId: `${status}_log_author`,
  headerName: `${OrderRequestStatusValues[status]} by`,
  headerComponentParams: {
    icon: ICONS.user.primary,
  },
  cellRenderer: (params: CellRendererParams<Entity>) => {
    if (!params.data) {
      return '';
    }
    const user = params.data.getStatusLog(status)?.author;
    return user ? <UserPreview user={user} /> : null;
  },
  valueGetter: (params: ValueGetterParams) =>
    params.data.getStatusLog(status)?.author?.idAttr || null,
  editable: false,
});
