/**
 * Labstep
 */

import { Currency as DineroCurrency } from 'dinero.js';
import { currencies } from 'labstep-web/constants/currencies';

export type Currency = DineroCurrency;

export type CurrencyISO = Lowercase<Currency>;

class CurrencyService {
  static getPrice(
    amount: number,
    currency: Currency,
    withDigits?: boolean,
  ): number | string {
    const currencyInfo = currencies[currency];
    const price = amount / 10 ** currencyInfo.decimal_digits;

    if (!withDigits) {
      return price;
    }
    return price.toFixed(currencyInfo.decimal_digits);
  }

  static getNumberFromFloat(
    amount: number,
    currency: Currency,
  ): number {
    const currencyInfo = currencies[currency];

    return Math.round(
      Number(amount.toFixed(currencyInfo.decimal_digits)) *
        10 ** currencyInfo.decimal_digits,
    );
  }

  static getCurrencySymbol(currency: Currency) {
    return currencies[currency].symbol;
  }

  static print(
    amount: number,
    currency: Currency,
    withDigitis = true,
  ) {
    if (amount === null || currency === null) {
      return ' ';
    }
    return `${currencies[currency].symbol}${CurrencyService.getPrice(
      amount,
      currency,
      withDigitis,
    )}`;
  }

  static getCurrencyFromAmountCurrency(amountCurrency: unknown) {
    const result = String(amountCurrency)
      .replace(/[0-9.,]/g, '')
      .trim();
    if (!result.length) {
      return undefined;
    }
    return Object.keys(currencies).find(
      (currency) =>
        currencies[currency].symbol === result ||
        currencies[currency].code === result.toUpperCase() ||
        currencies[currency].name
          .toLowerCase()
          .includes(result.toLowerCase()),
    );
  }

  static getAmountFromAmountCurrency(amountCurrency: unknown) {
    const result = String(amountCurrency)
      .replace(/[^\d.,]/g, '')
      .trim();
    if (!result.length) {
      return undefined;
    }
    return Number(result.replace(',', '')) * 100;
  }
}

export default CurrencyService;
