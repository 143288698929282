/**
 * Labstep
 *
 * @module components/GroupAutoSharing/Form/Delete
 * @desc Form to delete a GroupAutoSharing
 */

import { EntityUpdateContainer } from 'labstep-web/containers/Entity/Update';
import ReusableForm from 'labstep-web/core/Form/Reusable';
import { GroupAutoSharing } from 'labstep-web/models/group-auto-sharing.model';
import { generateNewDateString } from 'labstep-web/services/date.service';
import React from 'react';
import { fieldDeletePropagate } from '../fields';
import {
  GroupAutoSharingFormDeleteContainerProps,
  GroupAutoSharingFormDeleteProps,
} from './types';

export const GroupAutoSharingFormDelete: React.FC<
  GroupAutoSharingFormDeleteProps
> = ({ update, status, options, deletedAt }) => (
  <ReusableForm
    fields={[fieldDeletePropagate]}
    status={status}
    submitButtonText="Delete"
    defaultValues={{
      propagate: false,
    }}
    onSubmit={({ propagate }) => {
      if (update) {
        update(
          {
            propagate: propagate ? 1 : undefined,
            deleted_at: deletedAt,
          },
          options,
        );
      }
    }}
    autoFocus
  />
);

export const GroupAutoSharingFormDeleteContainer: React.FC<
  GroupAutoSharingFormDeleteContainerProps
> = (props) => (
  <EntityUpdateContainer
    entityName={GroupAutoSharing.entityName}
    id={props.groupAutoSharing.guid}
  >
    {(containerProps) => (
      <GroupAutoSharingFormDelete
        {...containerProps}
        {...props}
        deletedAt={generateNewDateString()}
      />
    )}
  </EntityUpdateContainer>
);

export default GroupAutoSharingFormDeleteContainer;
