/**
 * Labstep
 *
 * @module components/EntityImport/Action/Create/Steps/ImportFileMapping
 * @desc EntityImport Create Step 2: ImportFileMapping
 */

import Header from 'labstep-web/core/Header';
import ModalWizard from 'labstep-web/core/Modal/Wizard';
import Select from 'labstep-web/core/Select';
import { GridEntityImportExcelService } from 'labstep-web/grid/EntityImport/services/grid-entity-import-excel.service';
import React, { useCallback } from 'react';
import { Table } from 'semantic-ui-react';
import OptionWithPreview from './OptionWithPreview';
import styles from './styles.module.scss';
import { EntityImportActionCreateStepsImportFileMappingProps } from './types';

export const EntityImportActionCreateStepsImportFileMapping: React.FC<
  EntityImportActionCreateStepsImportFileMappingProps
> = ({ importData, index, onContinue }) => {
  const [selectedHeaderRow, setSelectedHeaderRow] =
    React.useState<number>(0);

  const [rowPreview, nOthers] =
    GridEntityImportExcelService.getRowPreview(
      importData,
      selectedHeaderRow,
    );

  const getRowOption = useCallback(
    (i: number) => ({
      value: i,
      label: `Row ${i + 1}`,
      preview: GridEntityImportExcelService.getRowPreview(
        importData,
        i,
      )[0],
    }),
    [],
  );

  const nRows = GridEntityImportExcelService.getNRows(
    importData,
    true,
  );

  return (
    <ModalWizard.Step
      index={index}
      onContinue={({ goForward }) => {
        onContinue(selectedHeaderRow);
        goForward();
      }}
      description={
        <>
          Select the row in your file that contains the column
          headers.
        </>
      }
      help={
        <div className={styles.help}>
          <div>
            <Header size="tiny">
              Selecting a Column Heading Row
            </Header>
            <p>
              Avoid leaving empty rows at the top of your spreadsheet.
              The first row should always include column headers,
              which will serve as the field names in Labstep
              Inventory.
            </p>
          </div>
        </div>
      }
    >
      <Table basic="very" data-testid="import-file-mapping">
        <Table.Body>
          <Table.Row verticalAlign="top">
            <Table.Cell width="4">
              <Select
                value={getRowOption(selectedHeaderRow)}
                label={{
                  text: 'Row Containing Column Headers',
                  position: 'top',
                }}
                options={Array.from(new Array(nRows).keys()).map(
                  (_, i) => getRowOption(i),
                )}
                onChange={(
                  newValue: ReturnType<typeof getRowOption>,
                ) => setSelectedHeaderRow(newValue.value)}
                components={{ Option: OptionWithPreview }}
              />
            </Table.Cell>
            <Table.Cell width="8">
              <div className={styles.preview}>
                Data Preview
                <br />
                <b>{rowPreview}</b>
                {nOthers > 0 ? <i> + {nOthers} others</i> : ''}
              </div>
            </Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
    </ModalWizard.Step>
  );
};

export default EntityImportActionCreateStepsImportFileMapping;
