/**
 * Labstep
 *
 * @module components/EntityStateWorkflow/Modal/LeftPanel
 * @desc Left panel of the entity state workflow modal
 */

import EntityActionCreate from 'labstep-web/components/Entity/Action/Create';
import EntityActionDelete from 'labstep-web/components/Entity/Action/Delete';
import EntityDraggable from 'labstep-web/components/Entity/Draggable';
import EntitySortableActionContainer from 'labstep-web/components/Entity/Sortable/Action/Container';
import EntitySortableList from 'labstep-web/components/Entity/Sortable/List';
import Flex from 'labstep-web/core/Flex';
import Header from 'labstep-web/core/Header';
import SortableItem from 'labstep-web/core/Sortable/Item';
import SortableItemDragHandle from 'labstep-web/core/Sortable/Item/DragHandle';
import StatusCard from 'labstep-web/core/StatusCard';
import TextWithHelp from 'labstep-web/core/Text/WithHelp';
import {
  EntityState,
  EntityStateType,
} from 'labstep-web/models/entity-state.model';
import React from 'react';
import styles from './styles.module.scss';
import { EntityStateWorkflowModalLeftPanelProps } from './types';
import {
  getHelperTextByType,
  getMaxPositionByType,
  getRandomColor,
} from './utils';

const EntityStateWorkflowModalLeftPanel: React.FC<
  EntityStateWorkflowModalLeftPanelProps
> = ({ entityStateWorkflow, onSelect, selectedState }) => {
  return (
    <Flex
      data-testid="entity-state-workflow-modal-left-panel"
      className={styles.left}
      spacing="gap"
      column
    >
      {entityStateWorkflow
        ? Object.values(EntityStateType).map((type) => (
            <Flex
              key={type}
              column
              data-testid={`${type.toLocaleLowerCase()}`}
            >
              <Flex spacing="between" data-testid="header">
                <Header size="mini">
                  <TextWithHelp
                    text={type}
                    helperText={getHelperTextByType(type)}
                  />
                </Header>
                <EntityActionCreate
                  entityName={EntityState.entityName}
                  parentName={entityStateWorkflow.entityName}
                  parentId={entityStateWorkflow.id}
                  body={{
                    name: 'Untitled State',
                    type: type.toLowerCase(),
                    color: getRandomColor(),
                    position: getMaxPositionByType(
                      entityStateWorkflow,
                      type,
                    ),
                  }}
                  options={{
                    onSuccess: ({ response }) => {
                      if (!response) {
                        return;
                      }
                      onSelect(response.result as any);
                    },
                  }}
                  actionComponentProps={{
                    icon: 'plus',
                    type: 'icon',
                  }}
                />
              </Flex>
              <Flex
                data-testid={`entity-states-${type.toLocaleLowerCase()}`}
                spacing="gap"
                column
              >
                <EntitySortableActionContainer>
                  {({ disabled: sortDisabled, getSortAction }) => {
                    const disabled = sortDisabled;
                    return (
                      <div id="entity-state-list">
                        <EntitySortableList
                          items={entityStateWorkflow.getStatesWithType(
                            type,
                          )}
                          entityName={EntityState.entityName}
                          disabled={disabled}
                          renderItem={(item): JSX.Element => {
                            const entityState = item as EntityState;
                            return (
                              <SortableItem
                                key={item.id}
                                id={item.id}
                                disabled={disabled}
                              >
                                <EntityDraggable
                                  key={`entity-state-${item.id}`}
                                  entityId={item.id}
                                  entityName={EntityState.entityName}
                                >
                                  <StatusCard
                                    key={entityState.id}
                                    text={entityState.name}
                                    color={entityState.color}
                                    icon={entityState.icon}
                                    onSelect={() => {
                                      onSelect(entityState.id);
                                    }}
                                    onHoverComponent={
                                      <EntityActionDelete
                                        entity={entityState}
                                        actionComponentProps={{
                                          type: 'icon',
                                        }}
                                      />
                                    }
                                    active={
                                      selectedState?.id ===
                                      entityState.id
                                    }
                                    dragHandle={
                                      <SortableItemDragHandle
                                        children={getSortAction(
                                          item.id,
                                        )}
                                      />
                                    }
                                  />
                                </EntityDraggable>
                              </SortableItem>
                            );
                          }}
                        />
                      </div>
                    );
                  }}
                </EntitySortableActionContainer>
              </Flex>
            </Flex>
          ))
        : null}
    </Flex>
  );
};

export default EntityStateWorkflowModalLeftPanel;
