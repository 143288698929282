/**
 * Labstep
 *
 * @module components/Tag/Form/Create
 * @desc Createable expeirment table - Auto save on change
 */

import {
  fieldTagName,
  fieldTagType,
} from 'labstep-web/components/Tag/Form/fields';
import FormCreate from 'labstep-web/core/Form/Create';
import { Tag } from 'labstep-web/models/tag.model';
import React from 'react';
import { ITagFormCreateProps } from './types';

export const TagFormCreate: React.FC<ITagFormCreateProps> = ({
  defaultType,
  ...rest
}) => (
  <FormCreate
    entityName={Tag.entityName}
    autoFocus
    preSubmit={({ name, type }) => ({
      name,
      type: defaultType || (type as any).value,
    })}
    fields={
      defaultType ? [fieldTagName] : [fieldTagName, fieldTagType]
    }
    {...rest}
  />
);

export default TagFormCreate;
