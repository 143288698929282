/**
 * Labstep
 */

import { Metadata } from 'labstep-web/models/metadata';
import { MetadataType } from 'labstep-web/models/metadata/types';
import { Resource } from 'labstep-web/models/resource.model';
import {
  IFieldsData,
  grapecityService,
} from 'labstep-web/services/grapecity.service';
import {
  fromOADate,
  isOADate,
} from 'labstep-web/services/ms-date.service';
import difference from 'lodash/difference';
import uniq from 'lodash/uniq';

interface HardcodedFieldsKeyNames {
  Name?: string;
  Location?: string;
}

export const HARDCODED_FIELDS = ['Name', 'Location'];
export const RESOURCE_ITEM_IMPORT_ROWS_LIMIT = 50;
export const RESOURCE_ITEM_IMPORT_EMPTY_DATA_MESSAGE =
  'No valid rows.';
export const RESOURCE_ITEM_IMPORT_DUPLICATE_HEADER_VALUES_ERROR_MESSAGE =
  'Duplicate column names. Columns must be unique (Case Sensitive).';
export const RESOURCE_ITEM_IMPORT_ROWS_LIMIT_REACHED_ERROR_MESSAGE = `Cannot import more than ${RESOURCE_ITEM_IMPORT_ROWS_LIMIT} rows.`;
export const RESOURCE_ITEM_IMPORT_MISSING_HEADER_ERROR_MESSAGE =
  'Missing valid header in the first row';

export class ResourceItemImportService {
  getFilledData = (data) =>
    data.filter((row) => {
      return Object.keys(row).reduce((isFilled, key) => {
        if (isFilled) {
          return isFilled;
        }
        return Boolean(row[key]);
      }, false);
    });

  purifyValue = (value) => {
    if (
      value === undefined ||
      value === null ||
      String(value).trim().length === 0
    ) {
      return undefined;
    }

    if (isOADate(value)) {
      return fromOADate(value);
    }

    if (Array.isArray(value) || value instanceof Object) {
      return JSON.stringify(value);
    }

    return String(value);
  };

  getPurifiedData = (data) =>
    data.map((row) =>
      Object.keys(row).reduce((result, key) => {
        const purifiedValue = this.purifyValue(row[key]);

        if (purifiedValue) {
          return {
            ...result,
            [key]: purifiedValue,
          };
        }
        return result;
      }, {}),
    );

  hasUniqueValues = (object: any) => {
    const values = Object.values(object).map((value: any) =>
      String(value).trim().toLowerCase(),
    );
    return uniq(values).length === values.length;
  };

  validateData = (data) => {
    let error: any = null;

    const filledData = this.getFilledData(data);

    const purifiedData = this.getPurifiedData(filledData);

    if (purifiedData.length === 0) {
      error = { message: RESOURCE_ITEM_IMPORT_EMPTY_DATA_MESSAGE };
    } else if (!this.hasUniqueValues(purifiedData[0])) {
      error = {
        message:
          RESOURCE_ITEM_IMPORT_DUPLICATE_HEADER_VALUES_ERROR_MESSAGE,
      };
    } else if (
      purifiedData.length - 1 >
      RESOURCE_ITEM_IMPORT_ROWS_LIMIT
    ) {
      error = {
        message:
          RESOURCE_ITEM_IMPORT_ROWS_LIMIT_REACHED_ERROR_MESSAGE,
      };
    } else {
      const headerKeys = Object.keys(purifiedData[0]);
      for (let i = 1; i < purifiedData.length; i += 1) {
        const rowKeys = Object.keys(purifiedData[i]);
        if (difference(rowKeys, headerKeys).length > 0) {
          error = {
            message:
              RESOURCE_ITEM_IMPORT_MISSING_HEADER_ERROR_MESSAGE,
          };
        }
      }
    }

    return [purifiedData, error];
  };

  convertRowToMetadatas = (
    firstRow: any,
    row: any,
    hardcodedFieldsKeyNames: HardcodedFieldsKeyNames,
  ) => {
    const metadatas = [];

    Object.keys(row).forEach((key) => {
      if (!(key in row)) {
        return;
      }

      if (!row[key] || row[key] === '') {
        return;
      }
      if (
        hardcodedFieldsKeyNames.Name &&
        key === hardcodedFieldsKeyNames.Name
      ) {
        return;
      }
      if (
        hardcodedFieldsKeyNames.Location &&
        key === hardcodedFieldsKeyNames.Location
      ) {
        return;
      }

      if (isOADate(row[key])) {
        metadatas.push({
          type: MetadataType.date,
          label: firstRow[key],
          date: fromOADate(row[key]),
        });
      } else {
        metadatas.push({
          type: MetadataType.default,
          label: firstRow[key],
          value: row[key],
        });
      }
    });

    return metadatas;
  };

  convertSpreadsheetDataToPostData = (data, params) => {
    const resourceItems: any = [];

    const hardcodedFieldsKeyNames =
      this.getHardcodedFieldsKeyNames(data);

    data.forEach((row) => {
      if (data[0] !== row) {
        const metadatas = this.convertRowToMetadatas(
          data[0],
          row,
          hardcodedFieldsKeyNames,
        );
        resourceItems.push({
          name: hardcodedFieldsKeyNames.Name
            ? row[hardcodedFieldsKeyNames.Name]
            : undefined,
          resource_location_path: hardcodedFieldsKeyNames.Location
            ? row[hardcodedFieldsKeyNames.Location]
            : undefined,
          metadatas,
          ...params,
        });
      }
    });

    return resourceItems;
  };

  getHardcodedFieldsKeyNames = (data): HardcodedFieldsKeyNames => {
    const keys: HardcodedFieldsKeyNames = {};

    const firstRow = data[0];
    Object.keys(firstRow).forEach((key) => {
      if (key in firstRow) {
        HARDCODED_FIELDS.forEach((hardcodedField) => {
          if (firstRow[key] === hardcodedField) {
            keys[hardcodedField] = key;
          }
        });
      }
    });

    return keys;
  };

  getInitialFieldsAndData = (
    resource: Resource,
    N = 5,
  ): IFieldsData => {
    const alphabet = grapecityService.generateAlphabets(26);

    const fields = alphabet;

    const rows = Math.min(N, RESOURCE_ITEM_IMPORT_ROWS_LIMIT);

    const data = new Array(RESOURCE_ITEM_IMPORT_ROWS_LIMIT + 1)
      .fill(0)
      .map(() => {
        const row = fields.reduce(
          (result, field) => ({
            ...result,
            [field]: '',
          }),
          {},
        );
        return row;
      });

    data[0] = {
      A: HARDCODED_FIELDS[0],
      B: HARDCODED_FIELDS[1],
    };

    const { templateMetadatas, itemTemplateLocation } = resource;

    const METADATA_TYPES = [
      MetadataType.default,
      MetadataType.date,
      MetadataType.datetime,
    ];

    const filteredMetadatas = templateMetadatas
      ? templateMetadatas.filter(
          (metadata: Metadata) =>
            METADATA_TYPES.indexOf(metadata.type) !== -1,
        )
      : [];

    const locationPath =
      itemTemplateLocation && itemTemplateLocation.path;

    for (let i = 1; i <= rows; i += 1) {
      data[i][alphabet[0]] = `${resource.displayName} #${
        resource.resource_item_count + i
      }`;
      data[i][alphabet[1]] = locationPath;
    }

    Object.keys(filteredMetadatas).forEach((key) => {
      if (key in filteredMetadatas) {
        data[0][alphabet[parseInt(key, 10) + 2]] =
          filteredMetadatas[key].label;
        for (let i = 1; i <= rows; i += 1) {
          data[i][alphabet[parseInt(key, 10) + 2]] =
            filteredMetadatas[key].value;
        }
      }
    });

    return {
      fields,
      data,
    };
  };
}

export const resourceItemImportService =
  new ResourceItemImportService();
