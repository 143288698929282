/**
 * Labstep
 *
 * Sortable Item to wrap Semantic components in Ref with DnD context
 */

import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import Ref from 'labstep-web/core/Ref';
import React, { useMemo } from 'react';
import type { CSSProperties } from 'react';
import { SortableItemContext } from '../context';
import { ISortableItemRefProps } from './types';

export const SortableItemRef: React.FC<ISortableItemRefProps> = ({
  children,
  id,
  disabled,
}) => {
  const {
    attributes,
    isDragging,
    listeners,
    setNodeRef,
    setActivatorNodeRef,
    transform,
    transition,
  } = useSortable({ id, disabled });
  const context = useMemo(
    () => ({
      attributes,
      listeners,
      ref: setActivatorNodeRef,
    }),
    [attributes, listeners, setActivatorNodeRef],
  );
  const style: CSSProperties = {
    opacity: isDragging ? 0.4 : undefined,
    transform: CSS.Translate.toString(transform),
    transition,
  };

  return (
    <SortableItemContext.Provider key={id} value={context}>
      <Ref innerRef={setNodeRef}>{children({ style })}</Ref>
    </SortableItemContext.Provider>
  );
};

export default SortableItemRef;
