/**
 * Labstep
 *
 * @module components/File/Filter/Extension/Select
 * @desc Select filter for extensions
 */

import ActionComponent from 'labstep-web/core/Action/Component';
import Modal from 'labstep-web/core/Modal/Default';
import ReactSelect from 'labstep-web/core/Select';
import { ReactSelectOption } from 'labstep-web/core/Select/types';
import { fileTypes } from 'labstep-web/models/file.model';
import React from 'react';
import { placeholder } from './strings';
import { FileFilterExtensionSelectProps } from './types';

const FileFilterExtensionSelect: React.FC<
  FileFilterExtensionSelectProps
> = ({ setParams, searchParams }) => {
  return (
    <Modal
      header={'File Type'}
      content={({ toggleModal }) => (
        <ReactSelect
          value={
            searchParams.extension
              ? {
                  value: searchParams.extension,
                  label: searchParams.extension,
                }
              : null
          }
          options={fileTypes.map((key) => ({
            value: key,
            label: key,
          }))}
          isClearable
          onChange={(selectedOption: ReactSelectOption) => {
            setParams({
              extension: selectedOption
                ? selectedOption.value
                : undefined,
            });

            toggleModal();
          }}
          placeholder={placeholder}
        />
      )}
      viewComponent={({ toggleModal }) => (
        <ActionComponent
          type="option"
          icon="file"
          text={'File Type'}
          onClick={toggleModal}
        />
      )}
    />
  );
};

export default FileFilterExtensionSelect;
