/**
 * Labstep
 *
 * @module components/ProtocolValue/Action/AmountDeductedStatus
 * @desc Shows if amount has been deducted
 */

import Icon from 'labstep-web/core/Icon';
import { isAmountUpdated } from 'labstep-web/services/amount-unit.service';
import React from 'react';
import { IProtocolValueActionAmountDeductedStatusProps } from './types';

const ProtocolValueActionAmountDeductedStatus: React.FC<
  IProtocolValueActionAmountDeductedStatusProps
> = ({ protocolValue, showNotSpecifiedIfApplicable }) => {
  if (protocolValue.is_output) {
    return null;
  }

  if (protocolValue.is_variable) {
    if (
      protocolValue.is_input &&
      !protocolValue.variable_template &&
      protocolValue.protocol_value_template_children.length > 0
    ) {
      const protocolValues =
        protocolValue.protocol_value_template_children.filter(
          (c) => !(c.resourceItem === null && c.amount === null),
        );
      if (protocolValues.length === 0) {
        return null;
      } else if (protocolValues.some((c) => !isAmountUpdated(c))) {
        return (
          <Icon
            name="warning sign"
            color="yellow"
            popup={{ content: 'Amount not updated in inventory' }}
          />
        );
      } else {
        return (
          <Icon
            name="check circle"
            color="green"
            popup={{ content: 'Amount updated in inventory' }}
          />
        );
      }
    }
    return null;
  }

  if (isAmountUpdated(protocolValue)) {
    return (
      <Icon
        name="check circle"
        color="green"
        popup={{ content: 'Amount updated in inventory' }}
      />
    );
  }
  if (!protocolValue.resourceItem) {
    if (showNotSpecifiedIfApplicable) {
      return (
        <Icon
          name="warning sign"
          color="yellow"
          popup={{ content: 'Item not specified' }}
        />
      );
    }
    return null;
  }
  return (
    <Icon
      name="warning sign"
      color="yellow"
      popup={{ content: 'Amount not updated in inventory' }}
    />
  );
};

export default ProtocolValueActionAmountDeductedStatus;
