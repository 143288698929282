/**
 * Labstep
 *
 * @module core/BoxPicker
 * @desc BoxPicker component
 */

import axios from 'axios';
import Picker from 'labstep-web/components/File/Action/Create/Picker';
import { withAuthenticatedUserEntity } from 'labstep-web/containers/AuthenticatedUser';
import { EntityCreateContainer } from 'labstep-web/containers/Entity/Create';
import ModalDefault from 'labstep-web/core/Modal/Default';
import { configService } from 'labstep-web/services/config.service';
import isEqual from 'lodash/isEqual';
import React from 'react';
import {
  IBoxPickerContainerProps,
  IBoxPickerProps,
  IBoxPickerState,
  Item,
} from './types';

export const BOX_AUTH_TOKEN_INFO_KEY = 'box_auth_token_info';

export class BoxPicker extends React.Component<
  IBoxPickerProps,
  IBoxPickerState
> {
  filePicker: any;

  static defaultProps = {
    multiple: false,
  };

  constructor(props: any) {
    super(props);
    this.onClick = this.onClick.bind(this);
    this.state = {
      authorizationUrl: null,
      open: false,
      info: JSON.parse(
        localStorage.getItem(BOX_AUTH_TOKEN_INFO_KEY) as any,
      ),
      token: null,
    } as any;
    this.handleChoose = this.handleChoose.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.handleStorageChange = this.handleStorageChange.bind(this);
    this.openModal = this.openModal.bind(this);
    this.onClick = this.onClick.bind(this);
  }

  componentDidMount() {
    axios
      .get(`${configService.boxUrl}/get-authorize-url`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${this.props.authenticatedUserEntity.token}`,
        },
      })
      .then(({ data }) =>
        this.setState({ authorizationUrl: data.authorize_url }),
      )
      // eslint-disable-next-line no-console
      .catch((error) => console.log('error=', error));

    window.addEventListener('storage', this.handleStorageChange);
  }

  componentDidUpdate(prevProps: any, prevState: any) {
    if (!prevState.open && this.state.open) {
      this.filePicker.show('0', this.state.token, {
        container: '.box-picker',
        chooseButtonLabel: 'Select',
      });
    }
    if (!isEqual(prevState.info, this.state.info)) {
      this.openModal();
    }
  }

  componentWillUnmount() {
    if (this.filePicker) {
      this.filePicker.removeListener('choose', this.handleChoose);
      this.filePicker.removeListener('cancel', this.handleCancel);
    }
    window.removeEventListener('storage', this.handleStorageChange);
  }

  handleStorageChange() {
    if (localStorage.getItem(BOX_AUTH_TOKEN_INFO_KEY)) {
      this.setState({
        info: JSON.parse(
          localStorage.getItem(BOX_AUTH_TOKEN_INFO_KEY) as any,
        ),
      });
    }
  }

  handleCancel() {
    this.filePicker.hide();
    this.setState({ open: false });
  }

  // eslint-disable-next-line class-methods-use-this
  handleChoose(items: Item[]) {
    const { create, options, onSelect } = this.props;

    const onSuccess = (body: any) => {
      create(body, options);
    };

    const filesToUpload = items.map((file) => ({
      path: `https://app.box.com/file/${file.id}`,
      size: file.size,
      name: file.name,
      mime_type: file.name.split('.').pop(),
      link_source: 'box',
    }));

    if (onSelect) {
      onSelect(filesToUpload as any);
    }

    filesToUpload.forEach((file) => {
      onSuccess(file);
    });
  }

  onClick() {
    const { info } = this.state;
    if (
      info &&
      info.token_info.acquiredAtMS +
        info.token_info.accessTokenTTLMS >
        Date.now() - 1000000
    ) {
      this.openModal();
    } else if (this.state.authorizationUrl) {
      const params = `scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,
        width=600,height=600,left=100,top=100`;

      window.open(this.state.authorizationUrl, 'test', params);
    }
  }

  openModal() {
    const onSuccess = (token: any) =>
      this.setState({ open: true, token });
    axios({
      method: 'POST',
      url: `${configService.boxUrl}/get-access-token`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.props.authenticatedUserEntity.token}`,
      },
      data: this.state.info,
    })
      .then(({ data }) => {
        this.filePicker = new window.Box.FilePicker();
        this.filePicker.addListener('choose', this.handleChoose);
        this.filePicker.addListener('cancel', this.handleCancel);
        onSuccess(data.token);
      })
      // eslint-disable-next-line no-console
      .catch((error) => console.log('error=', error));
  }

  render() {
    return (
      <>
        <ModalDefault
          open={this.state.open}
          header=""
          onClose={this.handleCancel}
          size="large"
          viewComponent={() => (
            <Picker onClick={this.onClick} icon="box" text="Box" />
          )}
          content={<div className="box-picker" />}
        />
      </>
    );
  }
}

export const BoxPickerFileContainer: React.FC<
  IBoxPickerContainerProps
> = ({ parentName, parentId, ...rest }) => (
  <EntityCreateContainer
    entityName="file"
    parentName={parentName}
    parentId={parentId}
  >
    {({ create }) => <BoxPicker create={create} {...rest} />}
  </EntityCreateContainer>
);

export default withAuthenticatedUserEntity(BoxPickerFileContainer);
