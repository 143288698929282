/**
 * Labstep
 *
 * @module core/SequenceEditor
 * @desc Imports (lazy) sequence editor in error boundary
 */

import { ErrorBoundaryContainer } from 'labstep-web/containers/ErrorBoundary';
import React, { Suspense, lazy } from 'react';
import { ISequenceEditorProps } from './types';

const SequenceEditorComponent = lazy(() => import('./component'));

const SequenceEditor: React.FC<ISequenceEditorProps> = (props) => (
  <ErrorBoundaryContainer FallbackComponent={<div />}>
    <Suspense fallback={<div />}>
      <SequenceEditorComponent {...props} />
    </Suspense>
  </ErrorBoundaryContainer>
);

export default SequenceEditor;
