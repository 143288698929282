/**
 * Labstep
 *
 * @module core/Form/ReactForm/SearchSelect
 * @desc SearchSelect component for react-form using ReactSelect component
 */

import SelectSearch from 'labstep-web/core/Select/Search';
import { flattenObject } from 'labstep-web/services/utils.service';
import React from 'react';
import { Controller } from 'react-hook-form';
import { ISearchSelectFieldProps } from './types';

export const SearchSelectField: React.FC<ISearchSelectFieldProps> = ({
  name,
  control,
  elementProps,
  disabled,
  placeholder,
  ...rest
}) => (
  <Controller
    name={name}
    control={control}
    render={({ field }): React.ReactElement => (
      <SelectSearch
        {...elementProps}
        {...rest}
        isDisabled={disabled}
        placeholder={placeholder}
        onChange={(val) => {
          field.onChange(flattenObject(val));
        }}
        value={field.value}
        // https://github.com/Labstep/web/wiki/Caveats
        denormalized
      />
    )}
  />
);

export default SearchSelectField;
