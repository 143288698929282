/**
 * Labstep
 *
 * @module components/Entity/Form/ShowEdit/Name
 * @desc ShowEditName for Entity
 */

import FormShowEditText from 'labstep-web/core/Form/ShowEdit/Text';
import React from 'react';
import { fieldEntityName } from '../../fields';
import { EntityFormShowEditNameProps } from './types';

export const EntityFormShowEditName: React.FC<
  EntityFormShowEditNameProps
> = ({ entity }) => (
  <FormShowEditText
    entityName={entity.entityName}
    entity={entity}
    field={{ ...fieldEntityName(entity.entityName), header: false }}
  >
    {entity.name}
  </FormShowEditText>
);

export default EntityFormShowEditName;
