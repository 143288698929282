/**
 * Labstep
 *
 * @module components/ResourceItem/Form/Location
 * @desc ShowEdit Form for location field
 */

import classnames from 'classnames';
import { useHasAccess } from 'labstep-web/components/Entity/Can';
import { Action } from 'labstep-web/components/Entity/Can/types';
import Icon from 'labstep-web/core/Icon';
import { capitalize } from 'labstep-web/services/i18n.service';
import React from 'react';
import ResourceItemActionUpdateStatus from '../../Action/UpdateStatus';
import styles from './styles.module.scss';
import { IResourceItemFormStatusProps } from './types';

const ResourceItemFormStatus: React.FC<
  IResourceItemFormStatusProps
> = ({ resourceItem }) => {
  const canEdit = useHasAccess(
    resourceItem.entityName,
    resourceItem.id,
    Action.edit,
  );
  const children = (
    <div
      className={classnames(styles[resourceItem.status], {
        [styles.toggleContainer]: canEdit,
      })}
    >
      {capitalize(resourceItem.status)}{' '}
      {canEdit && <Icon name="chevron down" />}
    </div>
  );
  if (!canEdit) {
    return <div className={styles.children}>{children}</div>;
  }
  return (
    <ResourceItemActionUpdateStatus
      trigger={children}
      resourceItem={resourceItem}
    />
  );
};

export default ResourceItemFormStatus;
