/**
 * Labstep
 */

import { dateOnlyAtUTC } from 'labstep-web/services/date.service';
import { PostFilter } from 'labstep-web/services/postFilter';
import {
  PostFilterComparison,
  PostFilterComparisonHumanReadableShort,
  PostFilterNodeType,
} from 'labstep-web/services/query-parameter.service';

export class PostFilterMetadatas extends PostFilter<
  'label' | 'value' | 'number' | 'date' | 'options',
  string
> {
  /**
   * Get the human readable string for a metadata filter
   * @param node Post filter node
   * @returns Human readable string
   */
  public getHumanReadableString(
    node: PostFilterNodeType,
  ): string | null {
    const attributeValues = this.getAttributeValues(node);
    const { label, value, number, date, options } = attributeValues;
    if (!label) {
      return null;
    } // if attributeValues is empty
    const metadataLabel = label.value;
    let metadataComparison: PostFilterComparison =
      PostFilterComparison.eq;
    let metadataValue: string | undefined;
    if (value) {
      metadataValue = value.value;
      metadataComparison = value.comparison;
    } else if (number) {
      metadataValue = number.value;
      metadataComparison = number.comparison;
    } else if (date) {
      metadataValue = dateOnlyAtUTC(date.value);
      metadataComparison = date.comparison;
    } else if (options) {
      metadataValue = options.value;
      metadataComparison = options.comparison;
    }
    return `${metadataLabel} ${PostFilterComparisonHumanReadableShort[metadataComparison]} ${metadataValue}`;
  }
}
