/**
 * Labstep
 */

import { EntityActionDeleteBulkProps } from 'labstep-web/components/Entity/Action/Delete/Bulk/types';

// eslint-disable-next-line no-shadow
export enum BulkAction {
  delete = 'delete',
  tag = 'tag',
  assign = 'assign',
  order_request_update_status = 'order_request_update_status',
  resource_item_update_status = 'resource_item_update_status',
  resource_item_update_resource_location = 'resource_item_update_resource_location',
  resource_create_order_request = 'resource_create_order_request',
  experiment_workflow_finish = 'experiment_workflow_finish',
  experiment_workflow_lock = 'experiment_workflow_lock',
  resource_item_update_amount = 'resource_item_update_amount',
  update_template = 'update_template',
  order_request_update_urgent = 'order_request_update_urgent',
  order_request_remove_purchase_order = 'order_request_remove_purchase_order',
  order_request_set_purchase_order = 'order_request_set_purchase_order',
  transfer_ownership = 'transfer_ownership',
  share = 'share',
  add_to_folder = 'add_to_folder',
  remove_from_folder = 'remove_from_folder',
  signature_request_create = 'signature_request_create',
  add_to_workspace = 'add_to_workspace',
  update_organization_role = 'update_organization_role',
  disable_users = 'disable_users',
}
export interface EntitySearchBulkActionsProps {
  bulkActions?: (
    | BulkAction
    | {
        action: BulkAction.delete;
        props?: Partial<EntityActionDeleteBulkProps>;
      }
  )[];
  entityName: string;
}
