/**
 * Labstep
 *
 * @module components/Molecule/DataGrid/coldefs/pubchem
 * @desc Column definition for pubchem fields
 */

import { ValueGetterParams } from 'ag-grid-community';
import DataGridPlaceholder from 'labstep-web/core/DataGrid/Placeholder';
import { CellRendererParams } from 'labstep-web/core/DataGrid/types';
import {
  getEditable,
  getEntityDefault,
  setValue,
} from 'labstep-web/core/DataGrid/utils';
import { Entity } from 'labstep-web/models/entity.model';
import { Molecule } from 'labstep-web/models/molecule.model';
import { PubchemService } from 'labstep-web/services/pubchem.service';
import { isValid } from 'labstep-web/services/validation';
import { colDefPubchemType } from './types';

/**
 * Column definition for pubchem fields
 * @param field Field (IUPACName, MolecularWeight, Density, MolecularFormula, CAS)
 * @param options Options
 * @returns Column definition
 */
const colDefPubchem: colDefPubchemType = (field, options = {}) => {
  const { getNestedEntity, columnProps, getCreateProps } = options;
  const editable = getEditable(getNestedEntity);
  const getEntity = getNestedEntity || getEntityDefault;

  return {
    colId: `pubchem_${field.name}`,
    cellRenderer: (params: CellRendererParams<Entity>) => {
      if (!params.data) {
        return '';
      }
      const fallback = (
        <DataGridPlaceholder params={params} editable={editable} />
      );
      const entity = getEntity(params);
      return entity instanceof Molecule
        ? PubchemService.getPubchemFieldValue(entity, field) ||
            fallback
        : fallback;
    },
    headerName: field.fieldLabel,
    editable,
    valueGetter: (
      params: ValueGetterParams,
    ): string | number | null => {
      const entity = getEntity(params);
      if (entity instanceof Molecule) {
        return PubchemService.getPubchemFieldValue(entity, field);
      }
      return null;
    },
    valueSetter: (params: any): boolean => {
      const entity = getEntity(params);
      if (!(entity instanceof Molecule)) {
        return false;
      }
      return setValue(
        'pubchem',
        PubchemService.getPubchemBody(entity, field, params.newValue),
        {
          entityName: entity.entityName,
          id: entity.idAttr,
          createProps: getCreateProps?.(params),
        },
        (value) => ({
          value,
          error: !isValid(params.newValue, field.validation),
        }),
      );
    },
    ...columnProps,
  };
};

export default colDefPubchem;
