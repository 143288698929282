/**
 * Labstep
 */

import { ColDefParams } from 'labstep-web/core/DataGrid/types';
import { Metadata } from 'labstep-web/models/metadata';
import {
  ProtocolCondition,
  ProtocolConditionVariableType,
} from 'labstep-web/models/protocol-condition.model';
import { ProtocolValue } from 'labstep-web/models/protocol-value.model';

/**
 * Find entity in array that has matching template
 * @param entities Metadata / ProtocolValue array
 * @param variable ProtocolCondition variable
 * @returns Metadata / ProtocolValue
 */
export const findEntityWithTemplate = <
  T extends Metadata | ProtocolValue,
>(
  entities: T[],
  variable: { guid: string },
): T | undefined =>
  entities.find(
    (entity) => entity.variable_template_guid === variable.guid,
  );

/**
 * Get nested metadata or protocol_value entity on protocol condition
 * @param params ColDefParams
 * @param childName 'metadatas' or 'protocol_values'
 * @param variable ProtocoCondition variable
 * @returns Metadata / ProtocolValue
 */
export const getNestedEntity = <
  T extends 'metadatas' | 'protocol_values',
>(
  params: ColDefParams<ProtocolCondition>,
  childName: T,
  variable: { guid: string },
):
  | (T extends 'metadatas' ? Metadata : ProtocolValue)
  | null
  | undefined => {
  if (!params.data) {
    return null;
  }
  const entities = params.data[childName] as (T extends 'metadatas'
    ? Metadata
    : ProtocolValue)[];
  return findEntityWithTemplate(
    entities,
    variable,
  ) as T extends 'metadatas' ? Metadata : ProtocolValue;
};

/**
 * Return variable name or untitled label
 * @param variable ProtocoCondition variable
 * @returns Display name
 */
export const getDisplayName = (
  variable: ProtocolConditionVariableType,
): string => variable.name || 'Untitled';

/**
 * Return col id with variable guid
 * @param colName Column name
 * @param variableGuid Variable guid
 * @returns Col id
 */
export const getColId = (
  colName: string,
  variableGuid: string,
): string => `${colName}${variableGuid}`;

/**
 * Extract variable guid from col id
 * @param colId Column id
 * @param colName Column name
 * @returns Variable guid
 */
export const getVariableGuidFromColId = (
  colId: string,
  colName: string,
): string | null => {
  if (!colId.startsWith(colName)) {
    return null;
  }
  return colId.replace(colName, '');
};
