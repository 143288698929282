/**
 * Labstep
 */

import { IStateDispatchProps } from 'labstep-web/prosemirror/marks/types';
import NodeReference from 'labstep-web/prosemirror/nodes/reference';
import { Attrs } from 'prosemirror-model';

export const replaceWithReference = (
  state: IStateDispatchProps['state'],
  dispatch: IStateDispatchProps['dispatch'],
  from: number,
  to: number,
  attrs: Attrs,
) => {
  const node = state.schema.nodes[NodeReference.key].create(attrs);
  if (dispatch) {
    dispatch(state.tr.replaceRangeWith(from, to, node));
  }
};
