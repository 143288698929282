/**
 * Labstep
 *
 * @module components/Protocol/Form/Create
 * @desc Form to create a Protocol
 */

import { CanCreate } from 'labstep-web/components/Entity/Can';
import { EntityCreateContainer } from 'labstep-web/containers/Entity/Create';
import ReusableForm from 'labstep-web/core/Form/Reusable';
import { Protocol } from 'labstep-web/models/protocol.model';
import React from 'react';
import { fieldProtocolName } from '../fields';
import {
  IProtocolFormCreateCreateContainerProps,
  IProtocolFormCreateProps,
} from './types';

export const ProtocolFormCreate: React.FC<
  IProtocolFormCreateProps
> = ({ body, create, status, options }) => (
  <ReusableForm
    fields={[fieldProtocolName]}
    status={status}
    submitButtonText="Create"
    onSubmit={({ name }) => {
      if (create) {
        create({ ...body, name }, options);
      }
    }}
    autoFocus
  />
);

export const ProtocolFormCreateCreateContainer: React.FC<
  IProtocolFormCreateCreateContainerProps
> = (props) => (
  <CanCreate entityName="protocol_collection">
    <EntityCreateContainer
      entityName={Protocol.entityName}
      showLoaderWhileCreating
    >
      {(containerProps) => (
        <ProtocolFormCreate {...containerProps} {...props} />
      )}
    </EntityCreateContainer>
  </CanCreate>
);

export default ProtocolFormCreateCreateContainer;
