/**
 * Labstep
 *
 * @module components/Tag/Item
 * @desc Tag label
 */

import classnames from 'classnames';
import Icon from 'labstep-web/core/Icon';
import Label from 'labstep-web/core/Label';
import React from 'react';
import styles from './styles.module.scss';
import { ICoreRemoveLabelProps, IRemoveLabelProps } from './types';

export const CoreRemoveLabel: React.FC<ICoreRemoveLabelProps> = ({
  name,
  onNameClick,
  icon,
  remove,
  className,
  cornerIcon,
  action,
}) => (
  <Label size="large" circular>
    <span
      data-testid="label-remove"
      onClick={onNameClick}
      className={classnames(styles.label, className, {
        [styles.clickable]: onNameClick,
      })}
    >
      {icon && (
        <span style={{ marginRight: 2 }}>
          <Icon name={icon} cornerIcon={cornerIcon} />
        </span>
      )}
      {name}
    </span>
    <span style={{ marginLeft: 9 }}>
      {action}
      {remove}
    </span>
  </Label>
);

const RemoveLabel: React.FC<IRemoveLabelProps> = ({
  onRemoveClick,
  status,
  ...rest
}) => (
  <CoreRemoveLabel
    remove={
      onRemoveClick && (
        <Icon
          disabled={(status && status.isFetching) || false}
          name="times"
          onClick={onRemoveClick}
        />
      )
    }
    {...rest}
  />
);

export default RemoveLabel;
