/**
 * Labstep
 *
 * @module services/file
 * @desc File Service
 */

import axios, { AxiosRequestConfig } from 'axios';
import { APP_VERSION } from 'labstep-web/constants/version';
import { File } from 'labstep-web/models/file.model';
import qs from 'query-string';
import { configService } from './config.service';
import { windowService } from './window.service';

export interface FileResponseWithData {
  data: string;
}

export interface APIFileDownloadCallOptions {
  onSuccess?: ({
    response,
  }: APIFileDownloadResponse) => void | Promise<void>;
  onFail?: (...props: any) => void;
  toast?: boolean | { message: string };
  params?: Record<string, unknown>;
  noOutput?: boolean;
  additionalMeta?: Record<string, unknown>;
  meta?: Record<string, unknown>;
  optimistic?: boolean;
}

export interface APIFileDownloadResponse {
  response: {
    id: number;
    name: string;
    mime_type: string;
    signed_url: string;
  };
}

export class FileService {
  getFileContent = (url: string): Promise<string> => {
    return axios({
      url,
      method: 'GET',
    }).then((fileResponse: FileResponseWithData) => {
      return fileResponse.data;
    });
  };

  getFileUrl = ({ response }: APIFileDownloadResponse): string => {
    return `${response.signed_url}&inline=1`;
  };

  downloadFile = ({
    response,
  }: APIFileDownloadResponse): Promise<void> => {
    return this.fetchFileAndTurnToBlob({ response }).then((blob) => {
      const url = windowService.createObjectURL(blob);
      const link = document.createElement('a');
      if (url) {
        link.href = url;
      }
      link.setAttribute('download', response.name);
      document.body.appendChild(link);
      link.click();
    });
  };

  fetchFileAndTurnToBlob = ({
    response,
  }: APIFileDownloadResponse): Promise<Blob> => {
    return axios({
      url: response.signed_url,
      method: 'GET',
      responseType: 'blob',
    })
      .then((fileResponse: FileResponseWithData) => {
        return fileResponse.data;
      })
      .then((fileData: string) => {
        const blob = new Blob([fileData], {
          type: response.mime_type,
        });
        return blob;
      });
  };

  getThumbnailSrcData = (
    apikey: string,
    file: File,
    params?: any,
  ): Promise<string | null> => {
    const config: AxiosRequestConfig = {
      url: `${
        configService.labstepApiUrl
      }/api/generic/file/thumbnail/${file.id}?${qs.stringify({
        q: 500000 / file.size,
        ...params,
        format: 'jpg',
      })}`,
      method: 'GET',
      responseType: file.extension === 'svg' ? 'text' : 'blob',
      headers: {
        'labstep-web-app-version': APP_VERSION,
        apikey,
      },
    };
    if (config.responseType === 'text') {
      return axios(config)
        .then(
          (fileResponse) =>
            `data:image/svg+xml;base64,${btoa(fileResponse.data)}`,
        )
        .catch(() => {
          return null;
        });
    }

    return axios(config)
      .then((fileResponse: any) =>
        windowService.createObjectURL(fileResponse.data),
      )
      .catch(() => {
        return null;
      });
  };
}

export const fileService = new FileService();
