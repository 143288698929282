/**
 * Labstep
 */

import BulkSelectContext from 'labstep-web/contexts/bulk-select';
import Checkbox from 'labstep-web/core/Checkbox';
import { Entity } from 'labstep-web/models/entity.model';
import React from 'react';

export const bulkSelectColumn = {
  header: (
    <BulkSelectContext.Consumer>
      {(containerProps): React.ReactElement => {
        const {
          canBulkSelect,
          persistSelection,
          unselected,
          unselectAll,
        } = containerProps;
        const selectedAll = persistSelection
          ? containerProps.selectedAllVisible
          : containerProps.selectedAll;
        const selectAll = persistSelection
          ? containerProps.selectAllVisible
          : containerProps.selectAll;
        return (
          <Checkbox
            indeterminate={selectedAll && unselected.length > 0}
            checked={selectedAll && unselected.length === 0}
            onClick={selectedAll ? unselectAll : selectAll}
            disabled={!canBulkSelect}
          />
        );
      }}
    </BulkSelectContext.Consumer>
  ),
  content: (entity: Entity): React.ReactElement => (
    <BulkSelectContext.Consumer>
      {({
        isSelected,
        toggleSelection,
        isSelectComplete,
        requiredCount,
      }): React.ReactElement => (
        <Checkbox
          checked={isSelected(entity)}
          onClick={(): void => toggleSelection(entity)}
          disabled={
            (requiredCount &&
              isSelectComplete &&
              !isSelected(entity)) ||
            false
          }
        />
      )}
    </BulkSelectContext.Consumer>
  ),
  cellProps: { style: { width: 40 } },
};
