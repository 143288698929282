/**
 * Labstep
 *
 * @module core/Form/ShowEdit/Date
 * @desc A component to toggle between Show and Edit mode
 * for date fields
 */

import {
  useHasAccess,
  useHasAccessCreate,
} from 'labstep-web/components/Entity/Can';
import { Action } from 'labstep-web/components/Entity/Can/types';
import UpdateOrCreateContainer from 'labstep-web/containers/UpdateOrCreate';
import DatePreview from 'labstep-web/core/DatePreview';
import TextPlaceholder from 'labstep-web/core/Text/Placeholder';
import {
  dateOnlyAtUTC,
  format,
  formatDateOnly,
} from 'labstep-web/services/date.service';
import { callIf } from 'labstep-web/services/react.service';
import React from 'react';
import FormShowEditDateToggleComponent from './ToggleComponent';
import { IFormShowEditDateToggleComponentProps } from './ToggleComponent/types';
import {
  EntityWithDateFieldType,
  IFormShowEditDateContainerProps,
  IFormShowEditDateProps,
} from './types';

export const FormShowEditDate = <
  TEntity extends EntityWithDateFieldType,
>({
  field,
  entity,
  dateTimePickerOptions = {},
  dateOnly,
  popup,
  label,
  placeholder,
  action,
  status,
  createProps,
  getBody = (b): Record<string | number, unknown> => b,
  dateTimePickerOnly,
  onClose,
  dataCy,
}: IFormShowEditDateProps<TEntity>) => {
  const canEdit = useHasAccess(
    entity.entityName,
    entity.idAttr,
    Action.edit,
    field as string,
  );

  const canCreate = useHasAccessCreate(
    entity.entityName,
    createProps?.parentName,
    createProps?.parentId,
  );

  const canCreateOrEdit = createProps ? canCreate : canEdit;

  const onCloseFn: IFormShowEditDateToggleComponentProps['onClose'] =
    (date, dateString): void => {
      if (!dateString) {
        return;
      }
      const entityField = entity[field];
      const isDateable =
        entityField instanceof Date ||
        typeof entityField === 'string' ||
        typeof entityField === 'number';

      if (
        new Date(dateString).toString() ===
        (isDateable ? new Date(entityField).toString() : null)
      ) {
        return;
      }
      const value = dateOnly
        ? formatDateOnly(date[0])
        : format(date[0]);
      // https://github.com/Labstep/labstep/issues/6909
      setTimeout(() => {
        action(getBody({ [field]: value }) as any);
      }, 10);
      callIf(onClose);
    };

  const value = entity[field] as string | null;

  let placeHolderText;
  if (placeholder) {
    placeHolderText = placeholder;
  } else if (canCreateOrEdit) {
    if (dateOnly) {
      placeHolderText = `Add ${label} date`;
    } else {
      placeHolderText = `Add ${label} date and time`;
    }
  } else {
    placeHolderText = `No ${label} date`;
  }

  let viewComponent;
  if (value && !createProps) {
    if (dateOnly) {
      viewComponent = dateOnlyAtUTC(value);
    } else {
      viewComponent = <DatePreview dateString={value} />;
    }
  } else {
    viewComponent = (
      <TextPlaceholder>{placeHolderText}</TextPlaceholder>
    );
  }

  return (
    <FormShowEditDateToggleComponent
      dataCy={dataCy}
      dateTimePickerOnly={dateTimePickerOnly}
      viewComponent={viewComponent}
      disabled={(status && status.isFetching) || false}
      defaultDate={value}
      canEdit={canCreateOrEdit}
      dateTimePickerOptions={dateTimePickerOptions}
      popup={popup}
      onClose={onCloseFn}
      dateOnly={dateOnly}
    />
  );
};

const FormShowEditDateContainer = <
  TEntity extends EntityWithDateFieldType,
>({
  optimistic,
  createProps,
  ...rest
}: IFormShowEditDateContainerProps<TEntity>) => (
  <UpdateOrCreateContainer
    createProps={createProps}
    entityName={rest.entity.entityName}
    updateProps={{ id: rest.entity.id }}
    optimistic={optimistic}
  >
    {({ action, status }): React.ReactElement => (
      <FormShowEditDate
        action={action}
        status={createProps && status}
        createProps={createProps}
        {...rest}
      />
    )}
  </UpdateOrCreateContainer>
);

export default FormShowEditDateContainer;
