/**
 * Labstep
 *
 * @module constants/limits
 * @desc Free tier limits
 */

// eslint-disable-next-line no-shadow
export enum LimitTypes {
  MAX_USERS_PER_GROUP = 'MAX_USERS_PER_GROUP',
  MAX_GROUPS_PER_USER = 'MAX_GROUPS_PER_USER',
}

// If the below limits are updated they must also be updated in the backend
export const Limits = {
  [LimitTypes.MAX_USERS_PER_GROUP]: 5,
  [LimitTypes.MAX_GROUPS_PER_USER]: 5,
};
