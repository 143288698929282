/**
 * Labstep
 *
 * @module state/reducers/optimistic
 * @desc Reducers for Optimistic updates
 */

import { combineReducers } from 'redux';

export const getOptimisticReducer =
  (entityName) =>
  (state = {}, action) => {
    if (
      action.type ===
        `OPTIMISTIC_UPDATE_${entityName.toUpperCase()}` &&
      action.meta.optimistic
    ) {
      const existingState = state[action.meta.identifier] || {};
      const openCalls = existingState.open_calls
        ? existingState.open_calls
        : 0;

      // If there are no open calls then store body values, otherwise ignore
      const stateToUpdate = openCalls === 0 ? action.currentBody : {};

      return {
        ...state,
        [action.meta.identifier]: {
          ...existingState,
          ...stateToUpdate,
          open_calls: openCalls + 1, // Increase open calls
        },
      };
    }

    if (
      action.type === `SUCCESS_UPDATE_${entityName.toUpperCase()}` &&
      action.meta.optimistic
    ) {
      const { body, identifier } = action.meta;
      // Assuming the state is set (if not, guess we'll die ¯\_(ツ)_/¯)
      const existingState = state[identifier];
      const openCalls = existingState.open_calls;

      if (openCalls > 1) {
        return {
          ...state,
          [identifier]: {
            ...existingState,
            ...body,
            open_calls: openCalls - 1, // Decrease open calls
          },
        };
      }

      // Clear cache
      return {
        ...state,
        [identifier]: {},
      };
    }

    return state;
  };

const reducers: any = combineReducers({
  access_key: getOptimisticReducer('access_key'),
  amount_update: getOptimisticReducer('amount_update'),
  comment: getOptimisticReducer('comment'),
  chemical: getOptimisticReducer('chemical'),
  data_table: getOptimisticReducer('data_table'),
  rich_text: getOptimisticReducer('rich_text'),
  molecule: getOptimisticReducer('molecule'),
  entity_export: getOptimisticReducer('entity_export'),
  experiment: getOptimisticReducer('experiment'),
  experiment_workflow: getOptimisticReducer('experiment_workflow'),
  group: getOptimisticReducer('group'),
  log: getOptimisticReducer('log'),
  notification: getOptimisticReducer('notification'),
  perma_link: getOptimisticReducer('perma_link'),
  profile: getOptimisticReducer('profile'),
  protocol: getOptimisticReducer('protocol'),
  protocol_collection: getOptimisticReducer('protocol_collection'),
  protocol_table: getOptimisticReducer('protocol_table'),
  protocol_timer: getOptimisticReducer('protocol_timer'),
  protocol_value: getOptimisticReducer('protocol_value'),
  protocol_step: getOptimisticReducer('protocol_step'),
  purchase_order: getOptimisticReducer('purchase_order'),
  order_request: getOptimisticReducer('order_request'),
  resource: getOptimisticReducer('resource'),
  resource_location: getOptimisticReducer('resource_location'),
  resource_item: getOptimisticReducer('resource_item'),
  metadata: getOptimisticReducer('metadata'),
  metadata_thread: getOptimisticReducer('metadata_thread'),
  metadata_molecule: getOptimisticReducer('metadata_molecule'),
  metadata_table: getOptimisticReducer('metadata_table'),
  research_area: getOptimisticReducer('research_area'),
  signature: getOptimisticReducer('signature'),
  signature_request: getOptimisticReducer('signature_request'),
  share_link: getOptimisticReducer('share_link'),
  sequence: getOptimisticReducer('sequence'),
  share_link_invitation: getOptimisticReducer(
    'share_link_invitation',
  ),
  tag: getOptimisticReducer('tag'),
  thread: getOptimisticReducer('thread'),
  user: getOptimisticReducer('user'),
  user_group: getOptimisticReducer('user_group'),
  user_group_notification: getOptimisticReducer(
    'user_group_notification',
  ),
  user_share_link: getOptimisticReducer('user_share_link'),
});

export default reducers;
