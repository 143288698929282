import pick from 'lodash/pick';
import { getKeys } from '../epics/optimistic';

export const optimistic =
  (store: any) => (next: any) => (action: any) => {
    const state = store.getState();
    if (
      action?.type.startsWith('REQUEST_UPDATE') &&
      action?.meta?.optimistic
    ) {
      const keys = getKeys(action);
      store.dispatch({
        type: action.type.replace('REQUEST', 'OPTIMISTIC'),
        meta: action.meta,
        currentBody: pick(
          state.entities[action.meta.entityName].byId[
            action.meta.identifier
          ],
          keys,
        ),
      });
    }

    return next(action);
  };
