/**
 * Labstep
 */

import { ParamsHOCChildrenProps } from 'labstep-web/hoc/Params/types';
import {
  DefaultSort,
  DefaultSortOptions,
  ISortOption,
  ISortOptions,
} from './types';

/**
 * Get the default sort key
 * @param defaultSortOptions Default sort options
 * @returns Default sort key
 */
export const getDefaultSortKey = (
  defaultSortOptions: DefaultSortOptions,
): string => {
  const defaultSortKey = Object.keys(defaultSortOptions)[0];
  if (
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    defaultSortOptions[
      defaultSortKey as keyof typeof defaultSortOptions
    ]!.default_sort === DefaultSort.asc
  ) {
    return defaultSortKey;
  }
  return `-${defaultSortKey}`;
};

/**
 * Get the active sort key from the search params
 * @param searchParams Search params
 * @returns Active sort key
 */
export const getActiveSortKey = (
  searchParams: ParamsHOCChildrenProps['searchParams'],
  defaultSortOptions: DefaultSortOptions,
): string =>
  searchParams.sort || getDefaultSortKey(defaultSortOptions);

/**
 * Check if the option is active
 * @param searchParams Search params
 * @param option Sort option
 * @returns True if the option is active
 */
export const isActiveSortOption = (
  option: string,
  activeSortKey: string,
): boolean =>
  activeSortKey === option || activeSortKey === `-${option}`;

/**
 * Set the sort option
 * @param setParams Set params function
 * @param options Sort options
 * @param option Sort option
 */
export const setSortOption = (
  setParams: ParamsHOCChildrenProps['setParams'],
  options: ISortOptions,
  option: string,
): void => {
  const selectedOption = options[
    option as keyof typeof options
  ] as ISortOption;
  if (!selectedOption) {
    return;
  }
  setParams({
    sort:
      selectedOption.default_sort === DefaultSort.asc
        ? option
        : `-${option}`,
  });
};
