/**
 * Labstep
 *
 * @module components/SignatureRequest/Form/fields
 * @desc SignatureRequest fields
 */

import {
  FieldType,
  FieldWithoutActionProps,
  IFieldTypeSearchSelectProps,
} from 'labstep-web/core/Form/Reusable/types';
import { User } from 'labstep-web/models/user.model';
import rules from 'labstep-web/services/validation/rules';

export const fieldSignatureRequestUser: IFieldTypeSearchSelectProps =
  {
    name: 'user',
    fieldLabel: 'To',
    fieldType: FieldType.SearchSelect,
    placeholder: 'Search for members',
    validation: rules.signature_request.user,
    elementProps: {
      entityName: User.entityName,
    },
  };

export const fieldSignatureRequestMessage: FieldWithoutActionProps = {
  name: 'message',
  fieldLabel: 'Message',
  fieldType: FieldType.TextArea,
  validation: rules.signature_request.message,
};
