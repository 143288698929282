/**
 * Labstep
 *
 * @module components/OrderRequest/Action/SetPurchaseOrder
 * @desc Set purchase order
 */

import Can from 'labstep-web/components/Entity/Can';
import { Action } from 'labstep-web/components/Entity/Can/types';
import PurchaseOrderSearchSelect from 'labstep-web/components/PurchaseOrder/SearchSelect';
import PurchaseOrderSideMenu from 'labstep-web/components/PurchaseOrder/SideMenu';
import { ICONS } from 'labstep-web/constants/icons';
import { EntityUpdateContainer } from 'labstep-web/containers/Entity/Update';
import ActionComponent from 'labstep-web/core/Action/Component';
import ModalDefault from 'labstep-web/core/Modal/Default';
import { OrderRequest } from 'labstep-web/models/order-request.model';
import { PurchaseOrder } from 'labstep-web/models/purchase-order.model';
import React, { useState } from 'react';
import {
  OrderRequestActionUpdatePurchaseOrderComponentProps,
  OrderRequestActionUpdatePurchaseOrderProps,
} from './types';

export const OrderRequestActionUpdatePurchaseOrderComponent: React.FC<
  OrderRequestActionUpdatePurchaseOrderComponentProps
> = ({ orderRequestId, viewComponent, options = {}, currency }) => {
  const [selectedPurchaseOrder, setSelectedPurchaseOrder] =
    useState<PurchaseOrder | null>(null);
  return (
    <>
      {selectedPurchaseOrder && (
        <PurchaseOrderSideMenu
          purchaseOrderId={selectedPurchaseOrder.id}
          header={selectedPurchaseOrder.name}
          onClose={() => {
            if (options.onSuccess) {
              (options as any).onSuccess();
            }
            setSelectedPurchaseOrder(null);
          }}
        />
      )}
      <ModalDefault
        header="Add to Order"
        content={({ toggleModal }) => (
          <EntityUpdateContainer
            entityName={OrderRequest.entityName}
            id={orderRequestId}
          >
            {({ update }) => (
              <PurchaseOrderSearchSelect
                currency={currency}
                onChange={(purchaseOrder) => {
                  update(
                    { purchase_order_id: purchaseOrder.id },
                    {
                      onSuccess: () => {
                        setSelectedPurchaseOrder(purchaseOrder);
                      },
                    },
                  );
                  toggleModal();
                }}
              />
            )}
          </EntityUpdateContainer>
        )}
        viewComponent={viewComponent}
      />
    </>
  );
};

const OrderRequestActionUpdatePurchaseOrder: React.FC<
  OrderRequestActionUpdatePurchaseOrderProps
> = ({ orderRequest, currency }) =>
  orderRequest.canSetPurchaseOrder ? (
    <Can
      entityName={orderRequest.entityName}
      id={orderRequest.id}
      action={Action.edit}
    >
      <OrderRequestActionUpdatePurchaseOrderComponent
        orderRequestId={orderRequest.id}
        currency={currency}
        viewComponent={({ toggleModal }) => (
          <ActionComponent
            type="option"
            text="Add to Order"
            icon={ICONS.purchase_order.primary}
            onClick={toggleModal}
          />
        )}
      />
    </Can>
  ) : null;

export default OrderRequestActionUpdatePurchaseOrder;
