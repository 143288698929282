/**
 * Labstep
 *
 * @module components/ResourceLocation/Form/Create
 * @desc Createable ResourceLocation - Auto save on change
 */

import { fieldResourceLocationNameMultiple } from 'labstep-web/components/ResourceLocation/Form/fields';
import { EntityCreateContainer } from 'labstep-web/containers/Entity/Create';
import ReusableForm from 'labstep-web/core/Form/Reusable';
import { ResourceLocation } from 'labstep-web/models/resource-location.model';
import React from 'react';
import { IResourceLocationFormCreateProps } from './types';

export const ResourceLocationFormCreate: React.FC<
  IResourceLocationFormCreateProps
> = ({ body, options }) => (
  <EntityCreateContainer
    batch
    entityName={ResourceLocation.entityName}
  >
    {({ create, status }): React.ReactElement => (
      <ReusableForm
        autoFocus
        status={status}
        fields={[fieldResourceLocationNameMultiple]}
        onSubmit={(values): void => {
          create(
            (values as { names: string[] }).names.map(
              (name: string) => ({
                name,
                ...body,
              }),
            ),
            options,
          );
        }}
      />
    )}
  </EntityCreateContainer>
);

export default ResourceLocationFormCreate;
