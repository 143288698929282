/**
 * Labstep
 *
 * @module components/ExperimentValue/List/Lineage
 * @desc Lineage Parents List
 */

import EmptyState from 'labstep-web/core/Card/EmptyState';
import React from 'react';
import ExperimentValueListLineageChildren from './Children';
import ExperimentValueListLineageParents from './Parents';
import { ExperimentValueListLineageProps } from './types';

export const ExperimentValueListLineage: React.FC<
  ExperimentValueListLineageProps
> = ({ resourceItem }) => {
  const hasChildren =
    resourceItem.input_experiment_ids &&
    resourceItem.input_experiment_ids.length > 0;

  const hasParents =
    resourceItem.protocol_value_origin &&
    resourceItem.protocol_value_origin.is_lineage;

  if (!hasParents && !hasChildren) {
    return (
      <EmptyState
        src="/img/empty-state/inventory.svg"
        title="No lineage"
        explanation={
          <p>
            Use ‘Inventory’ elements in your Protocols and Experiments
            to keep track of how items were created and trace the
            lineage back to other items in your inventory. For more on
            lineage tracking see{' '}
            <a
              href="https://help.labstep.com/en/"
              target="_blank"
              rel="noreferrer"
            >
              here
            </a>
            .
          </p>
        }
      />
    );
  }

  return (
    <div>
      <ExperimentValueListLineageParents
        resourceItem={resourceItem}
      />
      <ExperimentValueListLineageChildren
        resourceItem={resourceItem}
      />
    </div>
  );
};

export default ExperimentValueListLineage;
