/**
 * Labstep
 *
 * @module components/Entity/Table/SortOptions
 * @desc Table sort options
 */

import { useParamsContext } from 'labstep-web/contexts/params/hook';
import Dropdown from 'labstep-web/core/Dropdown';
import Flex from 'labstep-web/core/Flex';
import React from 'react';
import EntityTableSortOptionsOrder from './Order';
import styles from './styles.module.scss';
import { DefaultSort, EntityTableSortOptionsProps } from './types';
import {
  getActiveSortKey,
  isActiveSortOption,
  setSortOption,
} from './utils';

export const defaultSortOptionsCreatedAt = {
  created_at: {
    label: 'Date Created',
    default_sort: DefaultSort.desc,
  },
};

const EntityTableSortOptions: React.FC<
  EntityTableSortOptionsProps
> = ({
  sortOptions,
  trigger,
  showOrderAction = true,
  defaultSortOptions = defaultSortOptionsCreatedAt,
}) => {
  const { searchParams, setParams } = useParamsContext();

  const options = {
    ...defaultSortOptions,
    ...sortOptions,
  } as const;

  const activeSortKey = getActiveSortKey(
    searchParams,
    defaultSortOptions,
  );

  return (
    <Flex grow shrink={0} style={{ justifyContent: 'flex-end' }}>
      <Dropdown
        pointing="top right"
        icon={null}
        trigger={
          trigger || <span className={styles.trigger}>Sort by</span>
        }
      >
        {(Object.keys(options) as (keyof typeof options)[])
          .filter((option) => !!options[option])
          .map((option) => (
            <Dropdown.Item
              key={option}
              onClick={(): void =>
                setSortOption(setParams, options, option)
              }
              active={isActiveSortOption(option, activeSortKey)}
            >
              {options[option]!.label}
            </Dropdown.Item>
          ))}
      </Dropdown>
      {showOrderAction && (
        <div className={styles.order}>
          <EntityTableSortOptionsOrder
            activeSortKey={activeSortKey}
            setParams={setParams}
          />
        </div>
      )}
    </Flex>
  );
};

export default EntityTableSortOptions;
