/**
 * Labstep
 */

import MetadataManager from 'labstep-web/components/Metadata/Manager';
import ResearchAreaManager from 'labstep-web/components/ResearchArea/Manager';
import UserFormShowEditBiography from 'labstep-web/components/User/Form/ShowEdit/Biography';
import { User } from 'labstep-web/models/user.model';

export const getLinks = (user: User) => {
  let links = [
    {
      key: 'Bio',
      render: () => <UserFormShowEditBiography user={user} />,
    },
    {
      key: 'Research Areas',

      render: () => <ResearchAreaManager user={user} />,
    },
    {
      key: 'Info',
      render: () => (
        <MetadataManager
          entity={user}
          emptyStateMessage="Attach any other relevant information about yourself here."
        />
      ),
    },
  ];

  if (!user.group) {
    links = [links[0]];
  }

  return links;
};
