/**
 * Labstep
 *
 * @module components/ExperimentWorkflow/Filter/StatusType/Active
 * @desc Active filter component for status
 */

import { isActive } from 'labstep-web/components/Filter/List/utils';
import RemoveLabel from 'labstep-web/core/Label/Remove';
import { StatusTypes } from 'labstep-web/models/experiment-workflow.model';
import React from 'react';
import { ExperimentWorkflowFilterStatusTypeActiveProps } from './types';

const ExperimentWorkflowFilterStatusTypeActive: React.FC<
  ExperimentWorkflowFilterStatusTypeActiveProps
> = ({ clearParams, searchParams }) => {
  const activeStatusType = Object.values(StatusTypes).find((value) =>
    isActive(searchParams, value.params),
  );

  if (
    searchParams.is_ended === 'false' &&
    !('is_started' in searchParams)
  ) {
    return (
      <RemoveLabel
        icon={StatusTypes.started.icon}
        name="Incomplete"
        onRemoveClick={
          clearParams && (() => clearParams(['is_ended']))
        }
      />
    );
  }

  if (activeStatusType) {
    return (
      <RemoveLabel
        icon={activeStatusType.icon}
        name={activeStatusType.text}
        onRemoveClick={
          clearParams &&
          (() => clearParams(Object.keys(activeStatusType.params)))
        }
      />
    );
  }

  return null;
};

export default ExperimentWorkflowFilterStatusTypeActive;
