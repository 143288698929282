/**
 * Labstep
 */

import { ColDef } from 'labstep-web/core/DataGrid/types';
import colDefStatus from 'labstep-web/grid/Index/coldefs/Resource/status';
import { ResourceItem } from 'labstep-web/models/resource-item.model';

const colDefResourceItemStatus: ColDef<ResourceItem> = colDefStatus(
  ResourceItem.entityName,
);

export default colDefResourceItemStatus;
