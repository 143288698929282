/**
 * Labstep
 *
 * @module models/custom-identifier-set
 * @desc Typescript export class for CustomIdentifierSet
 */

import { Type } from 'class-transformer';
import { Entity } from 'labstep-web/models/entity.model';
import { Group } from 'labstep-web/models/group.model';

/** Control character length for generate/display preview */
const PREFIX_MAX_CHAR = 30;
const ZERO_PADDING_MAX_CHAR = 10;

export interface GetPreviewValues {
  prefix: string;
  zero_padding: number;
}

export class CustomIdentifierSet extends Entity {
  static readonly entityName = 'custom_identifier_set';

  static defaultZeroPadding = 3;

  get entityName(): string {
    return CustomIdentifierSet.entityName;
  }

  constructor(data: Partial<CustomIdentifierSet> = {}) {
    super();
    Object.assign(this, data);
  }

  settings!: { prefix: string; zero_padding: number };

  number_auto_increment!: number;

  public parent_class!: string;

  @Type(() => Group)
  group!: Group;

  static generateCustomIdentifier = (
    prefix: string,
    zero_padding: number,
    number_auto_increment = 0,
  ) =>
    `${prefix}${String(number_auto_increment + 1).padStart(
      zero_padding,
      '0',
    )}`;

  getPreview() {
    return CustomIdentifierSet.generateCustomIdentifier(
      this.settings.prefix,
      this.settings.zero_padding,
      this.number_auto_increment,
    );
  }

  static getPreview(prefix?: string, zeroPadding?: number): string {
    const zeroPaddingOrDefault =
      zeroPadding || CustomIdentifierSet.defaultZeroPadding;

    const firstCustomIdentifier =
      prefix &&
      prefix.length <= PREFIX_MAX_CHAR &&
      zeroPaddingOrDefault >= 1 &&
      zeroPaddingOrDefault <= ZERO_PADDING_MAX_CHAR
        ? CustomIdentifierSet.generateCustomIdentifier(
            prefix,
            zeroPaddingOrDefault,
          )
        : '';

    return `Preview: ${firstCustomIdentifier}`;
  }
}
