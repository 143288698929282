/**
 * Labstep
 */

import { ActionMenuProps } from 'labstep-web/core/Action/Menu/types';
import { ProtocolValue } from 'labstep-web/models/protocol-value.model';

// eslint-disable-next-line no-shadow
export enum Action {
  view = 'view',
  delete = 'delete',
  reporting = 'reporting',
}

export interface IProtocolValueActionMenuProps
  extends Pick<
    ActionMenuProps,
    Exclude<keyof ActionMenuProps, 'children'>
  > {
  protocolValue: ProtocolValue;
  actions: Action[];
}
