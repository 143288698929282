/**
 * Labstep
 *
 * @module components/Metadata/Detail/Value
 * @desc Table for metadata detail
 */

import MetadataDetailTableValue from 'labstep-web/components/Metadata/Detail/Table/Value';
import MetadataFormShowEditValue from 'labstep-web/components/Metadata/Form/ShowEdit/Value';
import React from 'react';
import { MetadataDetailValueProps } from './types';

export const MetadataDetailValue: React.FC<
  MetadataDetailValueProps
> = ({ metadata, entity }) =>
  metadata.type === 'file' ? (
    <MetadataFormShowEditValue
      entity={entity}
      metadata={metadata}
      isDropzone
    />
  ) : (
    <MetadataDetailTableValue entity={entity} metadata={metadata} />
  );

export default MetadataDetailValue;
