/**
 * Labstep
 *
 * @module prosemirror/nodes/horizontal-rule/commands
 * @desc Commands for Horizontal rule node
 */

import { IStateDispatchProps } from 'labstep-web/prosemirror/marks/types';
import NodeHorizontalRule from 'labstep-web/prosemirror/nodes/horizontal-rule';
import { EditorState } from 'prosemirror-state';

export const createHorizontalRuleCmd = (
  state: EditorState,
  dispatch: IStateDispatchProps['dispatch'],
) => {
  if (dispatch) {
    dispatch(
      state.tr
        .replaceSelectionWith(
          state.schema.nodes[NodeHorizontalRule.key].create(),
        )
        .scrollIntoView(),
    );
    return true;
  }
  return false;
};
