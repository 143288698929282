/**
 * Labstep
 *
 * @module components/Tag/Action/Delete
 * @desc Delete resource location
 */

import EntityActionDelete from 'labstep-web/components/Entity/Action/Delete';
import React from 'react';
import { ITagActionDeleteProps } from './types';

export const Message: React.FC<{ count: number }> = ({ count }) => (
  <div>
    {'This tag is associated with '}
    <b>{count}</b>
    {
      ' entities. If you want to delete it please remove it from all these entities.'
    }
  </div>
);

const TagActionDelete: React.FC<ITagActionDeleteProps> = ({
  tag,
}) => <EntityActionDelete entity={tag} />;

export default TagActionDelete;
