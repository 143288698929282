/**
 * Labstep
 *
 * @module components/Experiment/Form/ShowEdit/State/ButtonEdit
 * @desc Button to switch to edit mode
 */

import ExperimentActionEdit from 'labstep-web/components/Experiment/Form/ShowEdit/State/ExperimentActionEdit';
import ExperimentWorkflowActionUpdateEntityState from 'labstep-web/components/ExperimentWorkflow/Action/UpdateEntityState';
import React from 'react';
import styles from './styles.module.scss';
import { ExperimentFormShowEditStateButtonEditProps } from './types';

export const ExperimentFormShowEditStateButtonEdit: React.FC<
  ExperimentFormShowEditStateButtonEditProps
> = ({ experimentWorkflow, experiment, onClick }) => (
  <div className={styles.container}>
    <ExperimentActionEdit
      experiment={experiment}
      actionComponentProps={{
        type: 'button',
        icon: 'pencil',
        showIcon: true,
        elementProps: { color: undefined, basic: true },
      }}
      onClick={onClick}
    />

    {experimentWorkflow && (
      <ExperimentWorkflowActionUpdateEntityState
        experimentWorkflow={experimentWorkflow}
      />
    )}
  </div>
);

export default ExperimentFormShowEditStateButtonEdit;
