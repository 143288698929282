/**
 * Labstep
 *
 * @module screens/Landing/Login/ContinueButton
 * @desc Continue Button for login modal
 */

import Button from 'labstep-web/core/Button';
import { IButtonProps } from 'labstep-web/core/Button/types';
import React from 'react';

const ContinueButton: React.FC<IButtonProps> = ({
  children,
  ...rest
}) => (
  <Button {...rest} type="submit" fluid primary loader>
    {children}
  </Button>
);

export default ContinueButton;
