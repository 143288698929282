/**
 * Labstep
 *
 * @module components/Permission/Action/Revoke
 * @desc Revoke Permission
 */

import EntityActionDelete from 'labstep-web/components/Entity/Action/Delete';
import React from 'react';
import { PermissionActionRevokeProps } from './types';

export const PermissionActionRevoke: React.FC<
  PermissionActionRevokeProps
> = ({ permission }) => (
  <EntityActionDelete
    entity={permission}
    actionComponentProps={{ type: 'icon', icon: 'times' }}
  />
);

export default PermissionActionRevoke;
