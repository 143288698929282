/**
 * Labstep
 *
 * @module core/OfficeFile
 * @desc Component to view files in Google Docs viewer
 */

import { FileDownloadContainer } from 'labstep-web/containers/File/Download';
import Loader from 'labstep-web/core/Loader';
import React, { Component } from 'react';
import {
  IOfficeFileContainerProps,
  IOfficeFileProps,
  IOfficeFileState,
} from './types';

const getSrc = (url) => {
  const encodedUrl = encodeURIComponent(url);
  // Load with microsoft viewer
  return `https://view.officeapps.live.com/op/view.aspx?src=${encodedUrl}`;
};

export class OfficeFile extends Component<
  IOfficeFileProps,
  IOfficeFileState
> {
  constructor(props: IOfficeFileProps) {
    super(props);
    this.loadFile = this.loadFile.bind(this);
    this.state = { url: null };
  }

  componentDidMount() {
    this.loadFile();
  }

  loadFile() {
    const { downloadFile, file } = this.props;
    downloadFile({
      id: file.id,
      onSuccess: ({ response }) => {
        this.setState({ url: response.signed_url });
      },
    });
  }

  render() {
    const { url } = this.state;
    const { file, status } = this.props;

    return status && !status.isFetching && !status.error && url ? (
      <iframe
        title={file.name}
        src={getSrc(url)}
        style={{
          width: '100%',
          height: '100%',
          border: 'none',
        }}
      />
    ) : (
      <Loader active />
    );
  }
}

export const OfficeFileContainer: React.FC<
  IOfficeFileContainerProps
> = (props) => (
  <FileDownloadContainer file={props.file}>
    {(containerProps) => (
      <OfficeFile {...props} {...containerProps} />
    )}
  </FileDownloadContainer>
);

export default OfficeFileContainer;
