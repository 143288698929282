/**
 * Labstep
 *
 * @desc Validation service
 * @see https://github.com/skaterdav85/validatorjs
 */

import {
  FieldType,
  FieldWithoutActionProps,
  IFieldProps,
} from 'labstep-web/core/Form/Reusable/types';
import { AnySchema } from 'yup';
import rules from './rules';

/**
 * Transforms name ending with confirm to confirmation
 * This is done so that fields ending with _confirm in backend
 * which was the naming convention can still with validatorjs library
 * @param  {string} fieldName - Field Name
 * @return {string} - New name
 */
export const getFieldName = (fieldName: string): string => {
  let name = fieldName;
  if (name.endsWith('_confirm')) {
    name = `${name}ation`;
  }
  return name;
};

/**
 * It gets the validaiton rules of the fields using thier entitiy name
 * @param  {array}  fields     form fields
 * @param  {string} entityName name of entitiy type (e.g. protocol_timer)
 */
export const getFieldsWithValidationRules = (
  fields: IFieldProps[],
  entityName: string,
): IFieldProps[] =>
  fields.map((field: IFieldProps) => {
    if (field.fieldType === FieldType.action) {
      return field;
    }
    let { validation } = field;
    if (!('validation' in field)) {
      const rulesForEntityName: Record<string, AnySchema> = rules[
        entityName as keyof typeof rules
      ] as Record<string, AnySchema>;
      const rule =
        rulesForEntityName[
          (field as FieldWithoutActionProps)
            .name as keyof typeof rulesForEntityName
        ];

      validation = rule;
    }

    return { ...field, validation };
  });

/**
 * Check if single rule is valid
 * @param value Value
 * @param rule Rule
 * @returns Bool
 */
export const isValid = (
  value: unknown,
  rule: AnySchema | '',
): boolean => {
  /** Validator does not accept empty rule */
  if (rule === '' || value === '') {
    return true;
  }
  return rule.isValidSync(value);
};
