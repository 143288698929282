/**
 * Labstep
 *
 * @module components/ResourceItem/FilterPane
 * @desc Filter pane for resource item
 */

import FilterAssignedToList from 'labstep-web/components/Filter/AssignedTo/List';
import FilterCreatedByList from 'labstep-web/components/Filter/CreatedBy/List';
import { FilterList } from 'labstep-web/components/Filter/List';
import FilterPane from 'labstep-web/components/Filter/Pane';
import { ICONS } from 'labstep-web/constants/icons';
import { ResourceItem } from 'labstep-web/models/resource-item.model';
import { PostFilterStatus } from 'labstep-web/services/postFilter/filters/status';
import React from 'react';
import {
  IResourceItemExtraFiltersProps,
  IResourceItemFilterPaneProps,
} from './types';

export const ResourceItemExtraFilters: React.FC<
  IResourceItemExtraFiltersProps
> = ({ usePostFilter }) => (
  <FilterList
    filters={[
      {
        icon: ICONS.resource.info.available,
        text: 'Available',
        params: { status: 'available' },
        postFilterValues: usePostFilter
          ? [PostFilterStatus, ['available']]
          : undefined,
      },
      {
        icon: ICONS.resource.info.unavailable,
        text: 'Unavailable',
        params: { status: 'unavailable' },
        postFilterValues: usePostFilter
          ? [PostFilterStatus, ['unavailable']]
          : undefined,
      },
    ]}
  />
);

const ResourceItemFilterPane: React.FC<
  IResourceItemFilterPaneProps
> = ({ usePostFilter }) => (
  <FilterPane
    entityName={ResourceItem.entityName}
    filters={[]}
    extraFilters={
      <>
        <ResourceItemExtraFilters usePostFilter={usePostFilter} />
        <FilterCreatedByList
          entityName={ResourceItem.entityName}
          usePostFilter={usePostFilter}
        />
        <FilterAssignedToList
          entityName={ResourceItem.entityName}
          usePostFilter={usePostFilter}
        />
      </>
    }
    icon={ICONS.resource_item.primary}
    text={'Items'}
  />
);

export default ResourceItemFilterPane;
