/**
 * Labstep
 *
 * @module prosemirror/components/Menu/Referencing
 * @desc A menu that will display when the user type '@'
 * It will allow the user to select an entity and create the corresponding node
 */

import { withActiveGroup } from 'labstep-web/containers/ActiveGroup';
import { withProseMirrorReferencingSearch } from 'labstep-web/containers/ProseMirror/ReferencingSearch';
import {
  handleSetCategories,
  handleSetCreatableItems,
  handleSetExternalItems,
} from 'labstep-web/prosemirror/extensions/referencing/commands';
import { getState as getReferencingPluginState } from 'labstep-web/prosemirror/extensions/referencing/commands/selectors';
import { isEqual } from 'lodash';
import React from 'react';
import MenuReferencingMenu from './Menu';
import { IMenuReferencingProps } from './types';
import { getReferencingPluginStateChange, setItems } from './utils';

let fun: string | number | NodeJS.Timeout | undefined;
const debounceTime = 500;

export class MenuReferencing extends React.Component<
  IMenuReferencingProps,
  { debouncing: boolean }
> {
  constructor(props: IMenuReferencingProps) {
    super(props);
    this.state = { debouncing: false };
    this.setDebouncing = this.setDebouncing.bind(this);
  }

  componentDidUpdate(nextProps: IMenuReferencingProps) {
    const {
      state,
      dispatch,
      entity,
      experimentWorkflow,
      entities,
      creatableEntities,
      activeGroup,
      setSearchParams,
      searchCreatable,
      status,
      searchSubExperiments,
    } = this.props;

    const { selectedCategory, token } =
      getReferencingPluginState(state);

    const [openChanged, categoryChanged, tokenChanged] =
      getReferencingPluginStateChange(state, nextProps.state);

    if (openChanged) {
      if (dispatch) {
        handleSetCategories(state, dispatch, entity);
      }
    }
    if (selectedCategory) {
      if (categoryChanged) {
        setItems(
          state,
          dispatch,
          experimentWorkflow,
          entity,
          selectedCategory,
        );
      }

      if (categoryChanged || tokenChanged) {
        const shouldSearchSubExperiments =
          experimentWorkflow &&
          (selectedCategory.value === 'protocol_values' ||
            selectedCategory.value === 'metadatas');

        const shouldSearchCreatable =
          selectedCategory.creatableEntityName;

        if (shouldSearchSubExperiments || shouldSearchCreatable) {
          clearTimeout(fun);
          this.setDebouncing(true);
          fun = setTimeout(() => {
            const params: any = {
              experimentWorkflowId: experimentWorkflow
                ? experimentWorkflow.id
                : null,
              experimentNotId: entity.id,
              entityName: selectedCategory.value.slice(0, -1),
              searchQuery: token!.text,
              groupId: activeGroup?.id,
              creatableEntityName:
                selectedCategory.creatableEntityName,
            };
            if (
              selectedCategory.creatableEntityName ===
              'protocol_collection'
            ) {
              params.has_last_version = true;
            }

            setSearchParams(params);

            if (shouldSearchSubExperiments) {
              searchSubExperiments(params);
            }

            if (shouldSearchCreatable) {
              searchCreatable(params);
            }
            this.setDebouncing(false);
          }, debounceTime);
        }
      }

      if (
        !isEqual(status?.cached, nextProps.status?.cached) &&
        dispatch
      ) {
        handleSetExternalItems(state, dispatch, entities);
      }

      if (
        !isEqual(creatableEntities, nextProps.creatableEntities) &&
        dispatch
      ) {
        handleSetCreatableItems(state, dispatch, creatableEntities);
      }
    }
  }

  setDebouncing(state: boolean) {
    this.setState({ debouncing: state });
  }

  render() {
    const {
      state,
      dispatch,
      cursorPosition,
      status,
      entity,
      experimentWorkflow,
    } = this.props;
    return (
      <MenuReferencingMenu
        state={state}
        dispatch={dispatch}
        cursorPosition={cursorPosition}
        loading={(status && status.isFetching) || false}
        debouncing={this.state.debouncing}
        entityName={(experimentWorkflow || entity).entityName}
      />
    );
  }
}

export default withProseMirrorReferencingSearch(
  withActiveGroup(MenuReferencing),
);
