/**
 * Labstep
 *
 * @module components/ResourceLocation/Action/Edit
 * @desc Edit a resource location
 */

import EntityActionEdit from 'labstep-web/components/Entity/Action/Edit';
import ResourceLocationFormEdit from 'labstep-web/components/ResourceLocation/Form/Edit';
import ModalDefault from 'labstep-web/core/Modal/Default';
import React from 'react';
import { IResourceLocationActionEditProps } from './types';

export const ResourceLocationActionEdit: React.FC<
  IResourceLocationActionEditProps
> = ({ resourceLocation }) => (
  <ModalDefault
    header="Editing"
    viewComponent={({ toggleModal }) => (
      <EntityActionEdit
        entity={resourceLocation}
        actionComponentProps={{
          type: 'option',
          onClick: toggleModal,
          icon: 'pencil',
          text: 'Edit',
        }}
      />
    )}
    content={({ toggleModal }) => (
      <ResourceLocationFormEdit
        resourceLocation={resourceLocation}
        options={{ onSuccess: toggleModal }}
      />
    )}
  />
);

export default ResourceLocationActionEdit;
