/**
 * Labstep
 *
 * @module components/AccessKey/Action/Menu
 * @desc Menu of all actions for AccessKey
 */

import AccessKeyActionDelete from 'labstep-web/components/AccessKey/Action/Delete';
import ActionMenu from 'labstep-web/core/Action/Menu';
import React from 'react';
import { AccessKeyActionMenuProps } from './types';

const AccessKeyActionMenu: React.FC<AccessKeyActionMenuProps> = ({
  accessKeyItem,
  actions = [],
}) => (
  <ActionMenu>
    {actions.includes('delete') && (
      <AccessKeyActionDelete accessKeyItem={accessKeyItem} />
    )}
  </ActionMenu>
);

AccessKeyActionMenu.defaultProps = {
  actions: [],
};

export default AccessKeyActionMenu;
