/**
 * Labstep
 *
 * @module components/ProtocolCondition/Action/Create/Bulk
 * @desc Bulk Create Action for ProtocolCondition
 */

import { CanCreate } from 'labstep-web/components/Entity/Can';
import ProtocolConditionFormCreateBulk from 'labstep-web/components/ProtocolCondition/Form/Create/Bulk';
import ActionComponent from 'labstep-web/core/Action/Component';
import Modal from 'labstep-web/core/Modal/Default';
import { ProtocolCondition } from 'labstep-web/models/protocol-condition.model';
import React from 'react';
import { IProtocolConditionActionCreateBulkProps } from './types';

const text = `Add ${ProtocolCondition.getHumanReadableEntityName(
  true,
  true,
)}`;

export const ProtocolConditionActionCreateBulk: React.FC<
  IProtocolConditionActionCreateBulkProps
> = ({ protocol, actionComponentProps }) => (
  <CanCreate
    entityName={ProtocolCondition.entityName}
    parentId={protocol?.id}
    parentName={protocol?.entityName}
  >
    <Modal
      size="small"
      header={text}
      viewComponent={
        <ActionComponent
          dataCy="components/ProtocolCondition/Action/Create/Bulk"
          type="button"
          text={text}
          {...actionComponentProps}
        />
      }
      content={({ toggleModal }): React.ReactFragment => (
        <ProtocolConditionFormCreateBulk
          protocol={protocol}
          options={{ onSuccess: toggleModal }}
        />
      )}
    />
  </CanCreate>
);

export default ProtocolConditionActionCreateBulk;
