/**
 * Labstep
 *
 * @module components/Organization/Form/ShowEdit/Name
 * @desc Name showedit field
 */

import { fieldOrganizationName } from 'labstep-web/components/Organization/Form/fields';
import ShowEditText from 'labstep-web/core/Form/ShowEdit/Text';
import React from 'react';
import { OrganizationFormShowEditNameProps } from './types';

const OrganizationFormShowEditName: React.FC<
  OrganizationFormShowEditNameProps
> = ({ organization }) => (
  <ShowEditText
    entityName={organization.entityName}
    entity={organization}
    field={fieldOrganizationName}
  >
    {organization.name}
  </ShowEditText>
);

export default OrganizationFormShowEditName;
