/**
 * Labstep
 *
 * @module components/DeviceBooking/Form/ShowEdit/StartedAt
 * @desc StartedAt show edit for device booking
 *
 * FIXME Dead code
 */

import FormShowEditDate from 'labstep-web/core/Form/ShowEdit/Date';
import {
  appendDateDifference,
  dateDifferenceInMinutes,
  format,
  subtractDateDifference,
} from 'labstep-web/services/date.service';
import React from 'react';
import { DeviceBookingFormShowEditStartedAtProps } from './types';

export interface BodyStartedAtEndedAt {
  started_at?: string;
  ended_at?: string;
}

export const getBody = (
  body: BodyStartedAtEndedAt,
  startedAt: string,
  endedAt: string,
): BodyStartedAtEndedAt => {
  const difference = dateDifferenceInMinutes(endedAt, startedAt);
  if (
    'started_at' in body &&
    body.started_at &&
    body.started_at >= endedAt
  ) {
    return {
      ...body,
      ended_at: format(
        appendDateDifference(body.started_at, difference),
      ),
    };
  }
  if (
    'ended_at' in body &&
    body.ended_at &&
    body.ended_at <= startedAt
  ) {
    return {
      ...body,
      started_at: format(
        subtractDateDifference(body.ended_at, difference),
      ),
    };
  }
  return body;
};

export const DeviceBookingFormShowEditStartedAt: React.FC<
  DeviceBookingFormShowEditStartedAtProps
> = ({ deviceBooking }) => (
  <FormShowEditDate
    entity={deviceBooking}
    label="start"
    field="started_at"
    getBody={(body) =>
      getBody(body, deviceBooking.started_at, deviceBooking.ended_at)
    }
    optimistic
    // dateTimePickerOptions={{
    //   maxDate: deviceBooking.ended_at,
    // }}
  />
);

export default DeviceBookingFormShowEditStartedAt;
