/**
 * Labstep
 *
 * @module components/OrganizationPlan/Card/NextPayment
 * @desc Organization plan card for next payment
 */

import OrganizationPlanCard from 'labstep-web/components/OrganizationPlan/Card';
import Header from 'labstep-web/core/Header';
import TextPlaceholder from 'labstep-web/core/Text/Placeholder';
import { dateOnly } from 'labstep-web/services/date.service';
import React from 'react';
import { OrganizationPlanActionViewPayments } from '../../Action/ViewPayments';
import { OrganizationPlanCardNextPaymentProps } from './types';

export const OrganizationPlanCardNextPayment: React.FC<
  OrganizationPlanCardNextPaymentProps
> = ({ organizationPlan, organization }) => (
  <OrganizationPlanCard
    icon="dollar sign"
    header="Next Payment"
    secondary={
      <>
        <Header size="xsmall">
          {organizationPlan.currencyDisplay}
          {organizationPlan.upcomingTotalDisplay}
        </Header>
        <p>
          <TextPlaceholder>To be paid: </TextPlaceholder>
          <b>{dateOnly(organizationPlan.upcoming_invoice_date)}</b>
        </p>
      </>
    }
    action={
      <OrganizationPlanActionViewPayments
        organization={organization}
      />
    }
  />
);

export default OrganizationPlanCardNextPayment;
