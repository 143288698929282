/**
 * Labstep
 *
 * @module components/File/Filter/Extension/Active
 * @desc Active filter component for extension active
 */

import RemoveLabel from 'labstep-web/core/Label/Remove';
import React from 'react';
import { FileFilterExtensionActiveProps } from './types';

const FileFilterExtensionActive: React.FC<
  FileFilterExtensionActiveProps
> = ({ clearParams, searchParams }) =>
  'extension' in searchParams ? (
    <RemoveLabel
      name={searchParams.extension}
      onRemoveClick={
        clearParams && (() => clearParams(['extension']))
      }
    />
  ) : null;

export default FileFilterExtensionActive;
