/**
 * Labstep
 *
 * @module components/ResourceItem/Form/ShowEdit/AlertThreshold
 * @desc Edit ResourceItem alert threshold
 */

import { useHasAccess } from 'labstep-web/components/Entity/Can';
import { Action } from 'labstep-web/components/Entity/Can/types';
import { fieldResourceItemAmountAlertThreshold } from 'labstep-web/components/ResourceItem/Form/fields';
import ActionComponent from 'labstep-web/core/Action/Component';
import ShowEditText from 'labstep-web/core/Form/ShowEdit/Text';
import React from 'react';
import { IResourceItemFormShowEditAlertThresholdProps } from './types';

const ResourceItemFormShowEditAlertThreshold: React.FC<
  IResourceItemFormShowEditAlertThresholdProps
> = ({ resourceItem, label }) => {
  const canEdit = useHasAccess(
    resourceItem.entityName,
    resourceItem.id,
    Action.edit,
  );
  return (
    <ShowEditText
      entityName={resourceItem.entityName}
      entity={resourceItem}
      field={fieldResourceItemAmountAlertThreshold}
      label={label}
      customPlaceholder={({ toggle }) => (
        <ActionComponent
          type="icon"
          icon="bell outline"
          elementProps={{
            color: 'grey',
            popup: {
              content: 'Set Alert Threshold',
            },
          }}
          onClick={toggle}
        />
      )}
    >
      <ActionComponent
        type="icon"
        icon="bell outline"
        elementProps={{
          color: 'red',
          popup: {
            content: `When the amount remaining falls below ${
              resourceItem.amount_alert_threshold
            } ${
              resourceItem.unit ? resourceItem.unit : ''
            } it will trigger a notification. ${
              canEdit ? 'Click to edit.' : ''
            }`,
          },
        }}
      />
    </ShowEditText>
  );
};

export default ResourceItemFormShowEditAlertThreshold;
