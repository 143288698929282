/**
 * Labstep
 */

import * as sfApi from 'labstep-web/services/sf-api.service';

export const setupMfaActionType = 'setup_mfa';

export const setupMfaAction = (options: any = {}): any =>
  sfApi.post({
    type: 'SETUP_MFA',
    route: {
      custom: 'app_api_usertwofactor_setup',
    },
    meta: {
      action_type: setupMfaActionType,
      customReducer: 1,
    },
    ...options,
  });

export const confirmMfaActionType = 'confirm_mfa';

export const confirmMfaAction = (
  body: Record<string, unknown>,
  options: Record<string, unknown> = {},
): Record<string, unknown> =>
  sfApi.post({
    type: 'CONFIRM_MFA',
    route: {
      custom: 'app_api_usertwofactor_confirm',
    },
    meta: {
      action_type: confirmMfaActionType,
      customReducer: 1,
      normalize: 'user',
    },
    body,
    ...options,
  });

export const disableMfaActionType = 'disable_mfa';

export const disableMfaAction = (
  body: Record<string, unknown>,
  options: Record<string, unknown> = {},
): Record<string, unknown> =>
  sfApi.post({
    type: 'DISABLE_MFA',
    route: {
      custom: 'app_api_usertwofactor_disable',
    },
    meta: {
      action_type: disableMfaActionType,
      customReducer: 1,
      normalize: 'user',
    },
    body,
    ...options,
  });
