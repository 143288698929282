/**
 * Labstep
 *
 * @module prosemirror/utils/selection
 * @desc ProseMirror helper methods for state.selection
 */

import { IProseMirrorReferencingToken } from 'labstep-web/prosemirror/extensions/referencing/types';
import schema from 'labstep-web/prosemirror/schema';
import bugsnagService from 'labstep-web/services/bugsnag.service';
import { isEqual } from 'lodash';
import { Mark, MarkType, Node } from 'prosemirror-model';
import { EditorState, Transaction } from 'prosemirror-state';

/**
 * Resolves the start and end of node based on start of selection
 *
 * @param state
 */
export const getSelectionRange = (state: EditorState): any => {
  const { doc } = state;
  const { parent, pos } = state.selection.$from;
  let from = 1;
  let to = 1;
  if (
    parent.type === schema.nodes.paragraph ||
    parent.type === schema.nodes.heading
  ) {
    try {
      // https://github.com/Labstep/web/issues/5973
      const positionToResolve = pos > 0 ? pos - 1 : 0;
      const $pos = doc.resolve(positionToResolve);

      from = pos - $pos.textOffset;
      to = from + $pos.parent.child($pos.index()).nodeSize;
    } catch (e) {
      bugsnagService.notify(e, {
        state,
      });
    }
  }
  return {
    from: from - 1,
    to,
  };
};

export const removeMarkFromSelectedNode = (markType: MarkType) => {
  return (
    state: EditorState,
    dispatch: (tr: Transaction) => void,
  ) => {
    const { tr } = state;
    const selectionRange = getSelectionRange(state);
    tr.removeMark(
      selectionRange.from,
      selectionRange.to - 1,
      markType,
    );
    dispatch(tr.scrollIntoView());
    return true;
  };
};

export const setMarkFromSelectedNode = (
  markType: MarkType,
  attrs: Mark['attrs'],
) => {
  return (
    state: EditorState,
    dispatch: (tr: Transaction) => void,
  ) => {
    const { tr } = state;
    const selectionRange = getSelectionRange(state);
    tr.removeMark(
      selectionRange.from,
      selectionRange.to - 1,
      markType,
    );
    tr.addMark(
      selectionRange.from,
      selectionRange.to - 1,
      markType.create(attrs),
    );
    dispatch(tr.scrollIntoView());
    return true;
  };
};

const CONTAINER_NODE_NAMES = [
  'paragraph',
  'heading',
  'bullet_list',
  'ordered_list',
  'list_item',
  'table',
  'table_row',
  'table_cell',
  'table_header',
  // 'codemirror',
];

export type SelectionNode = {
  node: Node;
  pos: number;
};

export const getSelectionNodeType = (
  state: EditorState,
  nodeTypeName: string,
): SelectionNode | null => {
  const { from, to } = state.selection;

  const selectionNodes: SelectionNode[] = [];
  state.doc.nodesBetween(from, to, (node: Node, pos: number) => {
    selectionNodes.push({ node, pos });
  });

  const foundNodes = selectionNodes.filter(
    (selectionNode: SelectionNode) =>
      !CONTAINER_NODE_NAMES.includes(selectionNode.node.type.name) &&
      selectionNode.node.type.name === nodeTypeName,
  );

  if (foundNodes.length === 1) {
    return foundNodes[0];
  }

  return null;
};

export const getSelectionTextCut = (state: EditorState) => {
  try {
    const node = state.doc.cut(
      state.selection.from,
      state.selection.to,
    );

    if (node) {
      return node.textContent;
    }
  } catch (e) {
    bugsnagService.notify(e, {
      state,
    });
  }

  return null;
};

export const getSelectionText = (state: EditorState) => {
  const { from, to } = state.selection;

  let text: string | undefined = '';
  state.doc.nodesBetween(from - 1, to, (node: Node) => {
    if (node.type.name === 'text') {
      text = node.text;
    }
  });

  return text;
};

export const getSelectionTextSize = (state: EditorState): number => {
  const { from, to } = state.selection;

  let size = 0;
  state.doc.nodesBetween(from - 1, to, (node: Node) => {
    if (node.type.name === 'text') {
      size = node.nodeSize;
    }
  });

  return size;
};

export const getSelectionRangeParent = (state: EditorState): any => {
  const { doc } = state;
  const { pos } = state.selection.$from;

  let from = 1;
  let to = 1;
  try {
    const positionToResolve = pos > 0 ? pos - 1 : 0;
    const $pos = doc.resolve(positionToResolve);

    from = $pos.start();
    to = $pos.end();
  } catch (e) {
    bugsnagService.notify(e, {
      state,
    });
  }

  return {
    from,
    to,
  };
};

export const analyzeFoundToken = (
  token: IProseMirrorReferencingToken,
  trigger = '@',
): IProseMirrorReferencingToken | null => {
  if (token.text.startsWith(trigger)) {
    return {
      text: token.text.substring(1),
      from: token.from,
      to: token.to,
    };
  }

  return null;
};

/**
 *
 * @param state
 * @param trigger
 * @param separator
 * @returns
 */
export const getToken = (
  state: EditorState,
  trigger: string,
): IProseMirrorReferencingToken | null => {
  // Get selected text node
  const text = getSelectionText(state);
  // Get positions relative to the start of the parent text node
  const cursorPositionInText = state.selection.$anchor.textOffset;
  // WHen cursor at end of text, textOffset is 0. We need to set it to the end of the text.
  const endOfText =
    cursorPositionInText === 0 ? undefined : cursorPositionInText;
  const triggerPositionInText = text.lastIndexOf(trigger, endOfText);

  if (triggerPositionInText !== -1) {
    const cursorPosition = state.selection.anchor;
    const token = text.substring(
      triggerPositionInText + 1,
      endOfText,
    );
    return {
      text: token,
      from: cursorPosition - token.length - 1,
      to: cursorPosition,
    };
  }

  return null;
};

export const getSelectionNodeTypes = (state: EditorState) => {
  const { from, to } = state.selection;
  const nodeTypes: Record<string, number> = {};

  state.doc.nodesBetween(from, to, (node: Node) => {
    if (!nodeTypes[node.type.name]) {
      nodeTypes[node.type.name] = 0;
    }
    nodeTypes[node.type.name] += 1;
    return false;
  });

  return nodeTypes;
};

export const getIsStepSelected = (state: EditorState) => {
  const selectionNodeTypes: any = getSelectionNodeTypes(state);
  return (
    Object.keys(selectionNodeTypes).includes('experiment_step') ||
    Object.keys(selectionNodeTypes).includes('protocol_step')
  );
};

export const getIsSingleListSelected = (state: EditorState) => {
  const selectionNodeTypes: any = getSelectionNodeTypes(state);
  return (
    isEqual(selectionNodeTypes, { ordered_list: 1 }) ||
    isEqual(selectionNodeTypes, { bullet_list: 1 })
  );
};
