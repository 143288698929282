/**
 * Labstep
 *
 * @module components/ResourceTemplate/Action/Create
 * @desc Create Action for resource category
 */

import EntityActionCreate from 'labstep-web/components/Entity/Action/Create';
import { Resource } from 'labstep-web/models/resource.model';
import { IRouting } from 'labstep-web/services/navigation.types';
import React from 'react';
import { withRouter } from 'react-router';
import { IResourceTemplateActionCreateProps } from './types';

export const ResourceTemplateActionCreate: React.FC<
  IResourceTemplateActionCreateProps
> = ({ options = {}, actionComponentProps }) => {
  const { entityName } = Resource;

  return (
    <EntityActionCreate
      entityName={entityName}
      body={{ is_template: true }}
      options={options}
      actionComponentProps={{
        type: 'button',
        ...actionComponentProps,
      }}
      redirectRoute={({ response }) => ({
        to: `${entityName}_template_show` as keyof IRouting,
        params: { id: response.result },
      })}
    />
  );
};

export default withRouter(ResourceTemplateActionCreate);
