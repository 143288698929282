/**
 * Labstep
 *
 * @module components/AccessKey/Form/ShowEdit/Name
 * @desc Form to Edit AccessKey name
 */

import { accessKeyFieldName } from 'labstep-web/components/AccessKey/Form/fields';
import { FieldWithoutActionProps } from 'labstep-web/core/Form/Reusable/types';
import ShowEdit from 'labstep-web/core/Form/ShowEdit';
import { AccessKey } from 'labstep-web/models/access-key.model';
import omit from 'lodash/omit';
import React from 'react';
import { AccessKeyTableCellProps } from './types';

const NameCell: React.FC<AccessKeyTableCellProps> = ({
  accessKeyItem,
}) => (
  <ShowEdit
    entityName={AccessKey.entityName}
    entity={accessKeyItem}
    field={
      omit(accessKeyFieldName, [
        'fieldExplainer',
        'fieldLabel',
      ]) as FieldWithoutActionProps
    }
    canEdit
  >
    {accessKeyItem.name}
  </ShowEdit>
);

export default NameCell;
