/**
 * Labstep
 *
 * @module core/Form/Reusable
 * @desc Generic Form implementation to abstract all types of fields
 */

import { flattenObject } from 'labstep-web/services/utils.service';
import { FieldType, FieldWithoutActionProps } from './types';

export const flattenDefaultValues = (defaultValues) => {
  if (!defaultValues) {
    return defaultValues;
  }
  return Object.keys(defaultValues).reduce((result, key) => {
    const value = defaultValues[key];
    if (value && value.id) {
      return {
        ...result,
        [key]: flattenObject(defaultValues[key]),
      };
    }
    return {
      ...result,
      [key]: value,
    };
  }, {});
};

export const getPreSubmit =
  (fields: FieldWithoutActionProps[]) => (values) => {
    let sanitizedValues = { ...values };

    // Omit falsey values
    sanitizedValues = Object.keys(values).reduce((result, key) => {
      const value = values[key];
      if (value === '') {
        return { ...result, [key]: null };
      }
      if (value === undefined) {
        return result;
      }
      return { ...result, [key]: value };
    }, {});

    // valuesWithIntegers
    sanitizedValues = Object.keys(sanitizedValues).reduce(
      (result, key) => {
        const value = sanitizedValues[key];
        const field: FieldWithoutActionProps | undefined =
          fields.find((f) => f.name === key);

        if (
          field &&
          field.validation.type === 'number' &&
          value !== null &&
          field.skipTransform
        ) {
          return { ...result, [key]: value.toString() };
        }
        // Search Select
        if (
          field &&
          field.fieldType === 'SearchSelect' &&
          !field.skipTransform
        ) {
          if (
            field.elementProps.valueKey &&
            field.elementProps.valueKey === 'guid'
          ) {
            return {
              ...result,
              [`${field.name}_id`]: value ? value.guid : null,
            };
          }
          return {
            ...result,
            [`${field.name}_id`]: value ? value.id : null,
          };
        }
        // Date values
        if (field && field.fieldType === 'DateTimePicker') {
          if (
            field?.elementProps?.dateTimePickerOptions &&
            field?.elementProps?.dateTimePickerOptions.mode ===
              'range'
          ) {
            const [startName, endName] =
              field.elementProps.dateTimePickerOptions.rangeNames;
            return {
              ...result,
              [startName]: value[0],
              [endName]: value[1],
            };
          }
          return { ...result, [key]: value };
        }
        // File values
        if (
          field &&
          field.fieldType === FieldType.File &&
          !field.skipTransform
        ) {
          if (field.elementProps.multiple) {
            return { ...result, file_id: value.map((v) => v.id) };
          }
          return { ...result, file_id: value.id };
        }

        return { ...result, [key]: value };
      },
      {},
    );
    return sanitizedValues;
  };
