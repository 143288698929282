/**
 * Labstep
 *
 * @module components/Tag/Action/ViewAll
 * @desc Action linking to tag index
 */

import ViewAllAction from 'labstep-web/components/Entity/Action/ViewAll';
import { Tag } from 'labstep-web/models/tag.model';
import React from 'react';

export const TagActionViewAll: React.FC = () => (
  <ViewAllAction
    entityName={Tag.entityName}
    actionComponentProps={{ type: 'option', text: 'Manage tags' }}
  />
);

export default TagActionViewAll;
