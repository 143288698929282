/**
 * Labstep
 *
 * @module components/Layout/UserNotificationSidebar
 * @desc Sidebar with user notifications
 */

import { withAuthenticatedUserEntity } from 'labstep-web/containers/AuthenticatedUser';
import { NotificationsSetViewedContainer } from 'labstep-web/containers/Notification/SetViewed';
import { withSidebar } from 'labstep-web/containers/Ui/Sidebar';
import ActionComponent from 'labstep-web/core/Action/Component';
import { ReadOnMountHOC } from 'labstep-web/hoc/ReadOnMount';
import React from 'react';
import styles from './styles.module.scss';
import { ProfileDropdownProps } from './types';

export const ProfileDropdown: React.FC<ProfileDropdownProps> = (
  props,
) => {
  const { setSidebar, unreadNotificationCount } = props;
  return (
    <NotificationsSetViewedContainer>
      {({ setAllNotificationAsViewed }): React.ReactElement => (
        <div className={styles.container}>
          <ActionComponent
            type="icon"
            icon="bell outline"
            elementProps={{
              color: 'blue',
              size: 'large',
              popup: { content: 'Notifications' },
            }}
            onClick={(): void => {
              setSidebar(true, 'right');
              setAllNotificationAsViewed();
            }}
          />
          {unreadNotificationCount > 0 && (
            <div className={styles.dot}>
              <div />
            </div>
          )}
        </div>
      )}
    </NotificationsSetViewedContainer>
  );
};

const ProfileDropdownWithBurgerMenu = withSidebar(ProfileDropdown);

export const LayoutUserNotificationSidebar = ({
  authenticatedUserEntity,
}) =>
  authenticatedUserEntity ? (
    <ReadOnMountHOC
      type="count"
      loading={false}
      entityName="notification"
      params={{ is_viewed: false }}
      children={({ total }) => (
        <ProfileDropdownWithBurgerMenu
          unreadNotificationCount={total}
        />
      )}
    />
  ) : null;

export default withAuthenticatedUserEntity(
  LayoutUserNotificationSidebar,
);
