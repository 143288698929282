/**
 * Labstep
 *
 * @module core/Table/Collapsible
 * @description A table with collapsible rows
 */

import classnames from 'classnames';
import Icon from 'labstep-web/core/Icon';
import Table from 'labstep-web/core/Table';
import React, { useState } from 'react';
import { TableSimpleBody } from '../Body';
import { TableSimpleHeader } from '../Header';
import styles from './styles.module.scss';
import { TableSimpleCollapsibleProps } from './types';

const TableSimpleCollapsible: React.FC<
  TableSimpleCollapsibleProps
> = ({
  columns,
  sections,
  hideHeader,
  borderless,
  colspan,
  ...rest
}) => {
  const initialState = sections.reduce(
    (state, section, index) => ({
      ...state,
      [index]: section.isInitiallyUncollapsed ?? true,
    }),
    {},
  );
  const [state, setState] = useState(initialState);
  const toggle = (key) => setState({ ...state, [key]: !state[key] });
  const isExpanded = (key) => state[key];
  if (!sections.length) {
    return null;
  }
  if (sections.length !== Object.keys(state).length) {
    setState(initialState);
  }

  return (
    <Table
      fixed
      {...rest}
      className={classnames({ [styles.borderless]: borderless })}
    >
      {!hideHeader && <TableSimpleHeader columns={columns} />}
      {sections.map((section, i) => (
        // eslint-disable-next-line react/no-array-index-key
        <React.Fragment key={i}>
          {section.collapsible && (
            <Table.Body>
              <Table.Row
                style={{ cursor: 'pointer' }}
                onClick={() => toggle(i)}
              >
                <Table.Cell
                  className="collapse-header"
                  colSpan={colspan || columns.length}
                >
                  <div className={styles.sectionRow}>
                    <span data-public>{section.name}</span>
                    <Icon
                      name={
                        isExpanded(i)
                          ? 'chevron down'
                          : 'chevron right'
                      }
                      link
                    />
                  </div>
                </Table.Cell>
              </Table.Row>
            </Table.Body>
          )}
          {isExpanded(i) &&
            (section.customBody ? (
              section.customBody({ columns, rows: section.rows })
            ) : (
              <TableSimpleBody
                rows={section.rows}
                columns={columns}
              />
            ))}
        </React.Fragment>
      ))}
    </Table>
  );
};

TableSimpleCollapsible.defaultProps = {
  sections: [],
};

export default TableSimpleCollapsible;
