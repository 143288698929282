/**
 * Labstep
 *
 * @module components/EntityImport/Action/Create/Steps/FieldDefinitions/Fields/Table
 * @desc Table for EntityImport Create Step 3: FieldDefinitions
 */

import Header from 'labstep-web/core/Header';
import Table from 'labstep-web/core/Table';
import TextWithIcon from 'labstep-web/core/Text/WithIcon';
import React from 'react';
import { EntityImportActionCreateStepsFieldDefinitionsFieldsTableProps } from './types';

export const EntityImportActionCreateStepsFieldDefinitionsFieldsTable: React.FC<
  EntityImportActionCreateStepsFieldDefinitionsFieldsTableProps
> = ({
  dataTestId,
  header,
  rows,
  action,
  headerNames = ['Field Name', 'Field Type', 'Data Preview'],
}) => (
  <div>
    <Header size="tiny">{header}</Header>
    <Table basic="very" data-testid={dataTestId}>
      <Table.Header>
        <Table.Row>
          {headerNames.map((headerName, i) => (
            // eslint-disable-next-line react/no-array-index-key
            <Table.HeaderCell key={i}>{headerName}</Table.HeaderCell>
          ))}
          <Table.HeaderCell />
          <Table.HeaderCell />
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {rows.map((row) => (
          <Table.Row key={row.id}>
            <Table.Cell width="2">{row.name}</Table.Cell>
            <Table.Cell width="3">{row.fieldType}</Table.Cell>
            <Table.Cell width="3">{row.preview}</Table.Cell>
            <Table.Cell width="3">
              {row.message ? (
                <TextWithIcon
                  icon={
                    row.message.type === 'warning'
                      ? 'warning sign'
                      : 'check'
                  }
                  iconProps={{
                    color:
                      row.message.type === 'warning'
                        ? 'yellow'
                        : 'green',
                  }}
                  text={row.message.text}
                />
              ) : null}
            </Table.Cell>
            <Table.Cell width="1">{row.action}</Table.Cell>
          </Table.Row>
        ))}
        {action && (
          <Table.Row key="action">
            <Table.Cell width="2">{action}</Table.Cell>
            <Table.Cell width="3" />
            <Table.Cell width="3" />
            <Table.Cell width="3" />
            <Table.Cell width="1" />
          </Table.Row>
        )}
      </Table.Body>
    </Table>
  </div>
);

export default EntityImportActionCreateStepsFieldDefinitionsFieldsTable;
