/**
 * Labstep
 *
 * @module components/Group/Settings/DefaultSignatureStatement
 * @desc DefaultSignatureStatement field update
 */

import { fieldGroupDefaultSignatureStatement } from 'labstep-web/components/Group/Form/fields';
import ShowEditText from 'labstep-web/core/Form/ShowEdit/Text';
import React from 'react';
import { GroupFormShowEditDefaultSignatureStatementProps } from './types';

export const GroupFormShowEditDefaultSignatureStatement: React.FC<
  GroupFormShowEditDefaultSignatureStatementProps
> = ({ group }) => (
  <ShowEditText
    entityName={group.entityName}
    entity={group}
    field={fieldGroupDefaultSignatureStatement}
  >
    {group.default_signature_statement}
  </ShowEditText>
);

export default GroupFormShowEditDefaultSignatureStatement;
