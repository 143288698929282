/**
 * Labstep
 *
 * @module components/Device/Card/EmptyState
 * @desc Empty state for device
 */

import Button from 'labstep-web/core/Button';
import EmptyState from 'labstep-web/core/Card/EmptyState';
import { Device } from 'labstep-web/models/device.model';
import { getHumanReadableEntityName } from 'labstep-web/services/i18n.service';
import React from 'react';
import { DeviceCardEmptyStateProps } from './types';

const DeviceCardEmptyState: React.FC<DeviceCardEmptyStateProps> = ({
  action,
}) => (
  <EmptyState
    src="/img/empty-state/devices.svg"
    title={`No ${getHumanReadableEntityName(
      Device.entityName,
      true,
      true,
    )}`}
    explanation="Represent all the devices in your lab on Labstep. Set up devices to send data to Labstep. Fetch data from the device and attach to your samples and experiments in a single click."
    action={action}
    secondaryAction={
      <a
        target="_blank"
        rel="noopener noreferrer"
        href="https://help.labstep.com/en/articles/5694792-setting-up-a-device-a-complete-guide"
      >
        <Button as="a" primary basic>
          Find out more
        </Button>
      </a>
    }
  />
);

export default DeviceCardEmptyState;
