/**
 * Labstep
 *
 * @module components/ResourceLocation/Filter/Active
 * @desc Active filter component for resource location
 */

import { FilterPostActiveLabelProps } from 'labstep-web/components/Filter/Post/Active/Label/types';
import { ICONS } from 'labstep-web/constants/icons';
import { EntityReadEntityContainer } from 'labstep-web/containers/Entity/Read/Entity';
import RemoveLabel from 'labstep-web/core/Label/Remove';
import { ResourceLocation } from 'labstep-web/models/resource-location.model';
import { PostFilterResourceLocation } from 'labstep-web/services/postFilter/filters/resourceLocation';
import React from 'react';

const ResourceLocationFilterActivePost: React.FC<
  FilterPostActiveLabelProps
> = ({ node, onRemoveClick }) => {
  const { guid: guidAttribute } =
    PostFilterResourceLocation.getAttributeValues(node);
  const guid = guidAttribute?.value;
  if (!guid) {
    return null;
  }
  return (
    <EntityReadEntityContainer
      entityName={ResourceLocation.entityName}
      id={guid}
    >
      {({ entity }): React.ReactElement | null =>
        entity ? (
          <RemoveLabel
            icon={ICONS.resource_location.primary}
            name={entity.name}
            onRemoveClick={onRemoveClick}
          />
        ) : null
      }
    </EntityReadEntityContainer>
  );
};

export default ResourceLocationFilterActivePost;
