/**
 * Labstep
 *
 * @module components/File/Preview/Document
 * @desc Display PDF or Office document in a modal
 */

import FileActionDownload from 'labstep-web/components/File/Action/Download';
import FileGetURL from 'labstep-web/containers/File/GetURL';
import GoogleDoc from 'labstep-web/core/GoogleDoc';
import Modal from 'labstep-web/core/Modal/Default';
import OfficeFile from 'labstep-web/core/OfficeFile';
import PDFObject from 'labstep-web/core/PDFObject';
import TextFilePreview from 'labstep-web/core/TextFilePreview';
import React from 'react';
import { FilePreviewDocumentProps } from './types';

export const FilePreviewDocument: React.FC<
  FilePreviewDocumentProps
> = ({ file, toggle, isModal = true, containerId }) => {
  let content: React.ReactElement;
  switch (true) {
    case file.isPdf:
      content = (
        <PDFObject
          file={file}
          containerId={containerId || 'pdfObject'}
        />
      );
      break;
    case file.isOfficeFile:
      content = <OfficeFile file={file} />;
      break;
    case file.isGoogleDoc:
      content = <GoogleDoc file={file} />;
      break;
    case file.isText:
      content = (
        <FileGetURL file={file}>
          {({ url }) => (
            <div>
              <FileActionDownload
                file={file}
                actionComponentProps={{
                  type: 'text',
                }}
              />
              <TextFilePreview url={url} />
            </div>
          )}
        </FileGetURL>
      );
      break;
    default:
      content = (
        <div>
          <FileActionDownload
            file={file}
            actionComponentProps={{
              type: 'text',
            }}
          />
          <div>File Cannot be Previewed</div>
        </div>
      );
  }

  return isModal ? (
    <Modal
      header={file.name}
      onClose={toggle}
      size="fullscreen"
      open
      viewComponent={() => null}
      content={() => content}
    />
  ) : (
    content
  );
};

export default FilePreviewDocument;
