/**
 * Labstep
 *
 * @module components/Organization/Item
 * @desc Organization Item component
 */

import { ICONS } from 'labstep-web/constants/icons';
import Flex from 'labstep-web/core/Flex';
import Icon from 'labstep-web/core/Icon';
import React from 'react';
import OrganizationLogo from '../Logo';
import styles from './styles.module.scss';
import { OrganizationItemProps } from './types';

export const OrganizationItem: React.FC<OrganizationItemProps> = ({
  organization,
}) => (
  <Flex vAlignContent="center">
    {organization.logo_url ? (
      <OrganizationLogo organization={organization} size="mini" />
    ) : (
      <Icon name={ICONS.organization.primary} />
    )}
    <span className={styles.name}>{organization.name}</span>
  </Flex>
);

export default OrganizationItem;
