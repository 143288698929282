/**
 * Labstep
 *
 * @module components/Protocol/Action/Restore
 * @desc Restore a previous protocol version
 */

import EntityActionCreate from 'labstep-web/components/Entity/Action/Create';
import { navigation } from 'labstep-web/services/navigation';
import React from 'react';
import { withRouter } from 'react-router';
import { ProtocolActionRestoreProps } from './types';

export const ProtocolActionRestore: React.FC<
  ProtocolActionRestoreProps
> = ({ history, protocol, actionComponentProps }) => {
  let finalActionComponentProps: ProtocolActionRestoreProps['actionComponentProps'] =
    { type: 'option', icon: 'redo', ...actionComponentProps };
  const body: any = {
    protocol_collection_id: protocol.collection.id,
    source_id: protocol.id,
  };
  const options: any = {
    onSuccess: () => {
      history.push(
        navigation.get('protocol_collection_show', {
          id: protocol.collection.id,
        }),
      );
    },
  };

  if (protocol.collection.draft_version) {
    finalActionComponentProps = {
      ...finalActionComponentProps,
      modalConfirmationProps: {
        header: 'Protocol Draft Already Exists',
        message:
          'There is already an open draft that needs to be abandoned or set Live in order to create a new draft from this version.',
        confirmButtonContent: false,
      },
    };
  }

  return (
    <EntityActionCreate
      customActionName="Restore"
      entityName="protocol"
      body={body}
      options={options}
      actionComponentProps={finalActionComponentProps}
    />
  );
};

export default withRouter(ProtocolActionRestore);
