/**
 * Labstep
 *
 * @module components/Filter/AssignedTo
 * @desc Filter for assigned to
 */

import FilterUserList from 'labstep-web/components/Filter/User/List';
import React from 'react';
import { FilterAssignedToListProps } from './types';

export const FilterAssignedToList: React.FC<
  FilterAssignedToListProps
> = ({ entityName, usePostFilter }) => (
  <FilterUserList
    header={'Assigned to'}
    name="assignee"
    field="assignee_id"
    entityName={entityName}
    usePostFilter={usePostFilter}
  />
);

export default FilterAssignedToList;
