/**
 * Labstep
 *
 * @module components/ExperimentWorkflow/Info/LockedBy
 * @desc Showing who locked the experiment
 */

import { ICONS } from 'labstep-web/constants/icons';
import Icon from 'labstep-web/core/Icon';
import Message from 'labstep-web/core/Message';
import TextError from 'labstep-web/core/Text/Error';
import { ExperimentWorkflow } from 'labstep-web/models/experiment-workflow.model';
import React from 'react';
import { ExperimentWorkflowInfoLockedByProps } from './types';

const ExperimentWorkflowInfoLockedBy: React.FC<
  ExperimentWorkflowInfoLockedByProps
> = ({ experimentWorkflow }) =>
  experimentWorkflow.locked_at ? (
    <Message>
      <Icon name={ICONS.experiment_workflow.actions?.lock} />
      <span>
        {` This ${ExperimentWorkflow.getHumanReadableEntityName()} is now `}
        <TextError>
          <b>locked </b>
        </TextError>
        . It must be unlocked by an authorized user in order to make
        further changes.
      </span>
    </Message>
  ) : null;

export default ExperimentWorkflowInfoLockedBy;
