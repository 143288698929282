/**
 * Labstep
 *
 * @module core/Identifier
 * @desc Label for identifier
 */

import ActionCopy from 'labstep-web/core/Action/Copy';
import React from 'react';
import { IIdentifierProps } from './types';

const Identifier: React.FC<IIdentifierProps> = ({ children }) => (
  <ActionCopy
    text={children}
    actionComponentProps={{
      type: 'text',
      text: children,
      elementProps: { style: { color: 'inherit' }, underline: false },
    }}
  />
);

export default Identifier;
