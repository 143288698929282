/**
 * Labstep
 *
 * @module components/ResourceItem/Action/Create
 * @desc Fields for resource item
 */

import { ICONS } from 'labstep-web/constants/icons';
import {
  FieldType,
  FieldWithoutActionProps,
} from 'labstep-web/core/Form/Reusable/types';
import { ResourceItem } from 'labstep-web/models/resource-item.model';
import { Resource } from 'labstep-web/models/resource.model';
import { unitsAsOptions } from 'labstep-web/services/amount-unit.service';
import rules from 'labstep-web/services/validation/rules';

export const fieldResourceItemAmount: FieldWithoutActionProps = {
  fieldType: FieldType.Text,
  name: 'amount',
  placeholder: 'Add Amount',
  fieldLabel: 'Amount',
  validation: rules.resource_item.amount,
};

export const getFieldResourceItemUnit = (
  isChemical = false,
  additionalUnit?: string,
): FieldWithoutActionProps => ({
  name: 'unit',
  placeholder: 'Add Unit',
  fieldLabel: 'Unit',
  validation: rules.resource_item.unit,
  fieldType: FieldType.ReactSelect,
  elementProps: {
    options: unitsAsOptions(isChemical, additionalUnit),
    creatable: !isChemical,
  },
});

export const fieldResourceItemName: FieldWithoutActionProps = {
  fieldType: FieldType.Text,
  name: 'name',
  placeholder: 'Specify Item Name (Optional)',
  fieldLabel: `${ResourceItem.getHumanReadableEntityName(
    false,
    true,
  )} name`,
  validation: rules.resource_item.name,
  header: true,
};

export const fieldResourceItemAmountAlertThreshold: FieldWithoutActionProps =
  {
    fieldType: FieldType.Text,
    fieldLabel: 'Alert Threshold',
    name: 'amount_alert_threshold',
    validation: rules.resource_item.amount_alert_threshold,
    placeholder: 'Set alert threshold',
    fieldExplainer:
      'When the amount remaining falls below this threshold it will trigger a notification',
  };

export const getFieldsResource = ({
  groupId,
  resourceTemplate,
}: {
  groupId?: number;
  resourceTemplate?: Resource;
}): FieldWithoutActionProps => {
  const createEntityPrefix =
    resourceTemplate?.custom_identifier_set?.settings.prefix.concat(
      (
        (resourceTemplate?.custom_identifier_set
          ?.number_auto_increment ?? 0) + 1
      )
        .toString()
        .padStart(
          resourceTemplate?.custom_identifier_set?.settings
            .zero_padding,
          '0',
        ),
    );

  const rescourceTemplateId = resourceTemplate?.id;

  const field: FieldWithoutActionProps = {
    fieldType: FieldType.SearchSelect,
    name: 'resource',
    elementProps: {
      entityName: 'resource',
      creatable: !!rescourceTemplateId,
      createIcon: ICONS.entity.actions.create,
      createEntityPrefix,
      allowCreateWithEmptyInput:
        !!resourceTemplate?.custom_identifier_set,
      params: { group_id: groupId },
      setOptions: (entities) =>
        entities.map((entity) => {
          return {
            ...entity,
            name: entity.displayName,
          };
        }),
    },
    fieldLabel: Resource.getHumanReadableEntityName(false, true),
    validation: rules.resource_item.resource,
  };

  if (rescourceTemplateId && field.elementProps) {
    field.elementProps.params = {
      ...field.elementProps.params,
      template_id: rescourceTemplateId,
    };
    field.elementProps.createBody = {
      template_id: rescourceTemplateId,
    };
  }

  return field;
};

export const getFieldsResourceItemCreateForm = ({
  groupId,
  resourceTemplate,
}: {
  groupId?: number;
  resourceTemplate?: Resource;
}): FieldWithoutActionProps[] => {
  const fields = [
    getFieldsResource({
      groupId,
      resourceTemplate,
    }),
    fieldResourceItemName,
  ];
  return fields;
};
