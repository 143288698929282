/**
 * Labstep
 *
 * @module components/Device/Preview
 * @desc Preview for resource template
 */

import DeviceBookingManager from 'labstep-web/components/DeviceBooking/Manager';
import EntityPreview from 'labstep-web/components/Entity/Preview';
import MetadataManager from 'labstep-web/components/Metadata/Manager';
import React from 'react';
import { DevicePreviewProps } from './types';

const DevicePreview: React.FC<DevicePreviewProps> = ({
  device,
  ...rest
}) => (
  <EntityPreview
    entity={device}
    secondaryInfo={<MetadataManager entity={device} />}
    bottom={
      !device.is_template && <DeviceBookingManager device={device} />
    }
    {...rest}
  />
);

export default DevicePreview;
