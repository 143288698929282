/**
 * Labstep
 *
 * @module components/Metadata/Action/UpdateIsRequired
 * @desc Checkbox to update metadata is_required field
 */

import EntityActionUpdateCheckbox from 'labstep-web/components/Entity/Action/Update/Checkbox';
import React from 'react';
import { MetadataActionUpdateIsRequiredProps } from './types';

export const MetadataActionUpdateIsRequired: React.FC<
  MetadataActionUpdateIsRequiredProps
> = ({ metadata }) => (
  <EntityActionUpdateCheckbox
    entity={metadata}
    field={'is_required' as const}
    optimistic
    toggle={false}
  />
);

export default MetadataActionUpdateIsRequired;
