/**
 * Labstep
 *
 * @module grid/SmartTableProtocolValueVariable
 * @desc ProtocolValue Variable Grid
 */

import ProtocolConditionActionCreateBulk from 'labstep-web/components/ProtocolCondition/Action/Create/Bulk';
import ProtocolValueVariableActionSetItems from 'labstep-web/components/ProtocolValue/Variable/Action/SetItems';
import ProtocolValueVariableActionSetItemsContainer from 'labstep-web/components/ProtocolValue/Variable/Action/SetItems/Container';
import { COL_ID_RESOURCE_ITEM } from 'labstep-web/grid/Index/coldefs/ProtocolValue';
import { getEntitiesByColId } from 'labstep-web/grid/Index/components/StatusBar/utils';
import GridSmartTable from 'labstep-web/grid/SmartTable';
import { findEntityWithTemplate } from 'labstep-web/grid/SmartTable/coldefs/utils';
import { getColumnDefs } from 'labstep-web/grid/SmartTableProtocolValueVariable/coldefs';
import { Experiment } from 'labstep-web/models/experiment.model';
import { ProtocolCondition } from 'labstep-web/models/protocol-condition.model';
import { ProtocolValue } from 'labstep-web/models/protocol-value.model';
import React from 'react';
import { GridProtocolValueVariableProps } from './types';

export const GridProtocolValueVariable: React.FC<
  GridProtocolValueVariableProps
> = ({ protocol, variableTemplate }) => (
  <ProtocolValueVariableActionSetItemsContainer>
    {({ disabled, ...containerProps }): React.ReactElement => (
      <GridSmartTable
        isCursor
        params={
          protocol.entityName === Experiment.entityName
            ? {
                experiment_guid: protocol.guid,
              }
            : {}
        }
        columnDefs={getColumnDefs(protocol, variableTemplate)}
        protocol={protocol}
        resetColumnsOn={[
          variableTemplate.is_input,
          variableTemplate.resource_template?.id,
          variableTemplate.resource?.id,
        ]}
        emptyState={undefined}
        suppressMovableColumns
        statusBarChildren={
          variableTemplate.is_experiment_child &&
          variableTemplate.is_input ? (
            ({ ranges }) => {
              const protocolConditions =
                getEntitiesByColId<ProtocolCondition>(
                  ranges,
                  COL_ID_RESOURCE_ITEM,
                );
              const protocolValues = protocolConditions.map(
                (protocolCondition) =>
                  findEntityWithTemplate(
                    protocolCondition.protocol_values,
                    variableTemplate,
                  ),
              ) as ProtocolValue[];

              return (
                <ProtocolValueVariableActionSetItems
                  protocolValue={variableTemplate}
                  protocolValues={protocolValues}
                  {...containerProps}
                />
              );
            }
          ) : (
            <ProtocolConditionActionCreateBulk
              key="create-bulk"
              protocol={protocol}
              actionComponentProps={{
                type: 'text',
                icon: 'plus',
              }}
            />
          )
        }
        loading={disabled}
      />
    )}
  </ProtocolValueVariableActionSetItemsContainer>
);

export default GridProtocolValueVariable;
