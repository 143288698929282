/**
 * Labstep
 */

import { Experiment } from 'labstep-web/models/experiment.model';
import { Metadata } from 'labstep-web/models/metadata';
import { ProtocolCollection } from 'labstep-web/models/protocol-collection.model';
import { reactPropsKey } from 'labstep-web/prosemirror/extensions/external-comm';
import { referencingPluginKey } from 'labstep-web/prosemirror/extensions/referencing/plugin';
import { IStateDispatchProps } from 'labstep-web/prosemirror/marks/types';
import { replaceWithReference } from 'labstep-web/prosemirror/nodes/reference/commands';
import { createEntityIfNotCreating } from 'labstep-web/state/actions/entity';
import store from 'labstep-web/state/store';

export class ProsemirrorReferencingService {
  static replaceTokenWithReferenceNode(
    state: IStateDispatchProps['state'],
    dispatch: IStateDispatchProps['dispatch'],
    index: number,
  ) {
    const { availableItems, token } = state.plugins
      .find((p) => p.spec.key === referencingPluginKey)
      .getState(state);
    const entity = availableItems[index];
    const { id, guid, entityName } = entity;
    replaceWithReference(state, dispatch, token.from, token.to, {
      id,
      guid,
      entityName,
    });
    return true;
  }

  static createAndReplaceTokenWithReferenceNode(
    state: IStateDispatchProps['state'],
    dispatch: IStateDispatchProps['dispatch'],
    index: number,
  ) {
    const reactPropsKeyState = state.plugins
      .find((p) => p.spec.key === reactPropsKey)
      .getState(state);
    const { experimentWorkflow } = reactPropsKeyState;
    let { entity } = reactPropsKeyState;

    const pluginState = state.plugins
      .find((p) => p.spec.key === referencingPluginKey)
      .getState(state);
    const {
      availableItems,
      creatableItems,
      selectedCategory,
      token,
    } = pluginState;
    const entityName = selectedCategory.value.slice(0, -1);

    let data = {};
    const createNew =
      index === availableItems.length + creatableItems.length;
    if (createNew) {
      if (token.text.length) {
        data = {
          [entityName === Metadata.entityName ? 'label' : 'name']:
            token.text,
        };
      }
    } else {
      let creatableEntity =
        creatableItems[index - availableItems.length];
      if (
        creatableEntity.entityName === ProtocolCollection.entityName
      ) {
        creatableEntity = creatableEntity.last_version;
      }
      data = {
        [`${creatableEntity.entityName}_id`]: creatableEntity.id,
      };
    }

    const options: Record<string, unknown> = {
      onSuccess: ({ response }) =>
        replaceWithReference(state, dispatch, token.from, token.to, {
          id: response.entities[entityName][response.result].id,
          guid: response.entities[entityName][response.result].guid,
          entityName,
        }),
      additionalMeta: { redirect: true },
    };

    if (entityName === Metadata.entityName) {
      entity = entity.metadata_thread;
    } else if (entityName === Experiment.entityName) {
      entity = experimentWorkflow;
    }

    store.dispatch(
      createEntityIfNotCreating(
        entityName,
        data,
        entity.entityName,
        entity.id,
        null,
        options,
      ),
    );
    return true;
  }
}
