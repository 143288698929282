/**
 * Labstep
 *
 * @module components/Signature/Manager
 * @desc Signature manager
 */

import EntityLink from 'labstep-web/components/Entity/Link';
import Container from 'labstep-web/components/Layout/Container';
import SignatureActionCreate from 'labstep-web/components/Signature/Action/Create';
import SignatureRequestActionCreate from 'labstep-web/components/SignatureRequest/Action/Create';
import SignatureRequestActionViewList from 'labstep-web/components/SignatureRequest/Action/ViewList';
import Button from 'labstep-web/core/Button';
import Flex from 'labstep-web/core/Flex';
import { ExperimentWorkflow } from 'labstep-web/models/experiment-workflow.model';
import { Signature } from 'labstep-web/models/signature.model';
import React from 'react';
import { SignatureEmptyState } from '../EmptyState';
import SignatureList from '../List';
import { ISignatureManagerProps } from './types';

export const SignatureManager: React.FC<ISignatureManagerProps> = ({
  experimentWorkflow,
}) => {
  const actionsDefault = (
    <Flex wrap>
      <SignatureActionCreate
        experimentWorkflow={experimentWorkflow}
      />
      <SignatureRequestActionCreate
        experimentWorkflow={experimentWorkflow}
      />
      <SignatureRequestActionViewList
        experimentWorkflow={experimentWorkflow}
      />
    </Flex>
  );

  const explanationText = experimentWorkflow.entity_state_workflow
    ? 'The Workflow that this Experiment uses has no signature requirements set. This must be done before you can view and manage signatures from here!'
    : `This ${ExperimentWorkflow.getHumanReadableEntityName()} has not yet been signed. Enforce processes and reduce risk by adding / requesting ${Signature.getHumanReadableEntityName(
        true,
      )} and witness statements to your ${ExperimentWorkflow.getHumanReadableEntityName()}.`;

  if (
    experimentWorkflow.entity_state_workflow &&
    !experimentWorkflow.entity_state_workflow.hasSignatureRequirements()
  ) {
    return (
      <SignatureEmptyState
        explanationText={explanationText}
        action={
          <EntityLink
            entity={experimentWorkflow.entity_state_workflow}
          >
            <Button>Go to Workflow</Button>
          </EntityLink>
        }
      />
    );
  }

  return experimentWorkflow.signatures.length === 0 &&
    !experimentWorkflow.entity_state_workflow ? (
    <SignatureEmptyState
      explanationText={explanationText}
      action={actionsDefault}
    />
  ) : (
    <div>
      {!experimentWorkflow.entity_state_workflow && (
        <Container>{actionsDefault}</Container>
      )}
      <SignatureList experimentWorkflow={experimentWorkflow} />
    </div>
  );
};

export default SignatureManager;
