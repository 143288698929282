/**
 * Labstep
 */

import { PostFilter } from 'labstep-web/services/postFilter';
import { PostFilterComparison } from 'labstep-web/services/query-parameter.service';

export const PostFilterResourceLocation = new PostFilter(
  [['guid', PostFilterComparison.eq]],
  'resourceLocation',
);
