/**
 * Labstep
 *
 * @module grid/EntityImport/components/AddRow
 * @desc Add a row in DataGrid
 */

import { useGridServiceFromContext } from 'labstep-web/contexts/grid/hook';
import ActionComponent from 'labstep-web/core/Action/Component';
import React from 'react';

export const GridEntityImportAddRow: React.FC = () => {
  const gridService = useGridServiceFromContext();
  if (!gridService) {
    return null;
  }

  return (
    <ActionComponent
      onClick={(): void => gridService.addNewRow()}
      type="text"
      icon="plus"
      text="Add row"
    />
  );
};

export default GridEntityImportAddRow;
