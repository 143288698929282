/**
 * Labstep
 *
 * @module core/Paginator
 * @desc Paginator with infinite scroller option
 */

import cx from 'classnames';
import LoadingContent from 'labstep-web/components/Layout/LoadingContent';
import { DEFAULT_CURSOR } from 'labstep-web/constants/cursor';
import ActionComponent from 'labstep-web/core/Action/Component';
import { APICallOptions } from 'labstep-web/models';
import React from 'react';
import styles from './styles.module.scss';
import { IPaginatorProps } from './types';

class Paginator extends React.Component<IPaginatorProps> {
  public static defaultProps = {
    infinite: true,
    showMore: true,
    seeMoreMessage: 'Show more',
  };

  public constructor(props: IPaginatorProps) {
    super(props);
    this.loadMore = this.loadMore.bind(this);
    this.handleScroll = this.handleScroll.bind(this);
  }

  public componentDidMount(): void {
    const { infinite } = this.props;
    if (!infinite) {
      return;
    }
    const layoutGridElement = document.getElementById(
      'main-content-children',
    );
    if (layoutGridElement) {
      layoutGridElement.addEventListener('scroll', this.handleScroll);
    }
  }

  public componentWillUnmount(): void {
    const { infinite } = this.props;
    if (!infinite) {
      return;
    }
    const layoutGridElement = document.getElementById(
      'main-content-children',
    );
    if (layoutGridElement) {
      layoutGridElement.removeEventListener(
        'scroll',
        this.handleScroll,
      );
    }
  }

  public handleScroll(): void {
    const { status, nextCursor, infinite } = this.props;
    const hasMore = nextCursor !== DEFAULT_CURSOR;

    if (!hasMore || !infinite || (status && status.isFetching)) {
      return;
    }

    this.loadMore();
  }

  public loadMore(options?: APICallOptions): void {
    const { nextCursor, read } = this.props;

    if (nextCursor !== DEFAULT_CURSOR) {
      read(options);
    }
  }

  public render(): JSX.Element {
    const {
      children,
      status,
      currentCursor,
      nextCursor,
      showMore,
      reverse,
      seeMoreMessage,
      loader = 'placeholder',
      hideAction,
      total,
    } = this.props;

    const hasMore = nextCursor !== DEFAULT_CURSOR;
    const isFetching = status && status.isFetching;
    const searching = isFetching && currentCursor === DEFAULT_CURSOR;
    const error = status && status.error;
    const isCached =
      status &&
      status.cached &&
      Math.floor((status.cached - Date.now()) / 1000 / 60) < 5;

    const showMoreAction = showMore && !error && hasMore && (
      <div className={styles.seeMore}>
        <ActionComponent
          status={{ isFetching }}
          onClick={(e): void => {
            // Hack so that portal doesn't close when
            // we click show more https://github.com/Labstep/web/issues/7387
            e.stopPropagation();
            this.loadMore();
          }}
          icon="search plus"
          type="text"
          text={
            total ? `${seeMoreMessage} (${total})` : seeMoreMessage
          }
        />
      </div>
    );
    return (
      <LoadingContent
        loader={loader}
        status={{
          isFetching: !status || (!isCached && searching) || false,
          error,
        }}
      >
        {(): JSX.Element => (
          <div className={cx(styles.container, { reverse })}>
            <>
              {typeof children === 'function'
                ? children({
                    showMoreAction,
                    loadMore: this.loadMore,
                    hasMore,
                  })
                : children}
            </>
            {!hideAction && showMoreAction}
          </div>
        )}
      </LoadingContent>
    );
  }
}

export default Paginator;
