/**
 * Labstep
 */

import { ActionMenuProps } from 'labstep-web/core/Action/Menu/types';
import { PurchaseOrder } from 'labstep-web/models/purchase-order.model';

// eslint-disable-next-line no-shadow
export enum Action {
  view = 'view',
  print = 'print',
  delete = 'delete',
  assign = 'assign',
}

export interface IPurchaseOrderActionMenuProps
  extends Pick<
    ActionMenuProps,
    Exclude<keyof ActionMenuProps, 'children'>
  > {
  purchaseOrder: PurchaseOrder;
  actions: Action[];
}
