/**
 * Labstep
 *
 * @module prosemirror/components/Menu/Code/Action/Edit
 * @desc Button to edit a code mark
 */

import Button from 'labstep-web/core/Button';
import CodeMirror from 'labstep-web/core/CodeMirror/Modal';
import Icon from 'labstep-web/core/Icon';
import React from 'react';
import { IMenuCodeActionEditProps } from './types';

const MenuCodeActionEdit: React.FC<IMenuCodeActionEditProps> = ({
  state,
  dispatch,
  codeNode,
}) => (
  <CodeMirror
    submitButtonText="Update"
    onSubmit={({ description: value, type: mode }, toggleModal) => {
      if (dispatch) {
        dispatch(
          state.tr.setNodeMarkup(codeNode.pos, null, { value, mode }),
        );
        toggleModal();
      }
    }}
    status={null}
    value={codeNode.node.attrs.value}
    viewComponent={({ toggleModal }) => (
      <Button onMouseDown={toggleModal}>
        <Icon name="pencil" />
        <span>Edit</span>
      </Button>
    )}
  />
);

export default MenuCodeActionEdit;
