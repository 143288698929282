/**
 * Labstep
 *
 * @module components/Layout/GlobalSearch/ProtectedEntityLink/Content
 * @desc Content for ProtectedEntityLink Modal
 */

import { GroupInitials } from 'labstep-web/components/Group/Initials';
import { withActiveGroup } from 'labstep-web/containers/ActiveGroup';
import { withAuthenticatedUser } from 'labstep-web/containers/AuthenticatedUser';
import Header from 'labstep-web/core/Header';
import TableSimple from 'labstep-web/core/Table/Simple';
import { ReadOnMountHOC } from 'labstep-web/hoc/ReadOnMount';
import { Group } from 'labstep-web/models/group.model';
import { Permission } from 'labstep-web/models/permission.model';
import { getHumanReadableEntityName } from 'labstep-web/services/i18n.service';
import React, { useState } from 'react';
import { withRouter } from 'react-router';
// import { AuthorizationService } from 'labstep-web/services/authorization.service';
import { getTopLevelEntity } from '..';
import styles from '../styles.module.scss';
import {
  ProtectedEntityLinkContentProps,
  ShareableEntity,
} from './types';

export const ProtectedEntityLinkContent: React.FC<
  ProtectedEntityLinkContentProps
> = ({
  entity,
  activeGroup,
  setActiveGroupId,
  authenticatedUser,
  setDisplay,
}) => {
  const [groupIdToRead, setGroupIdToRead] = useState<number | null>(
    null,
  );
  const topLevelEntity = getTopLevelEntity(entity);
  const setActiveGroupAndRedirect = (id: number): void => {
    setTimeout(() => {
      setActiveGroupId?.(id);
      setDisplay(false);
    });
  };
  const loader = <Header>Checking workspace permissions...</Header>;
  let component;
  if (groupIdToRead) {
    component = (
      <ReadOnMountHOC
        // Need this key because React gets confused and thinks
        // we are still rendering the ReadOnMountHOC below
        key={1}
        type="entities"
        entityName={Group.entityName}
        params={{
          id: groupIdToRead,
          get_single: 1,
        }}
        loading={{
          loader: 'header',
          message: 'Switching workspace...',
        }}
        options={{
          onSuccess: (): void => {
            setActiveGroupAndRedirect(groupIdToRead);
          },
        }}
        children={(): null => null}
      />
    );
  } else {
    component = (
      <ReadOnMountHOC
        type="entities"
        entityName={Permission.entityName}
        params={{
          [`${topLevelEntity.entityName}_id`]: topLevelEntity.id,
          group_id: activeGroup?.id,
          get_single: 1,
          user_id: authenticatedUser.id,
        }}
        loading={false}
        children={({ entity: groupPermission, status }) => {
          if (!(status && !status.isFetching)) {
            return loader;
          }
          if (activeGroup && groupPermission) {
            setActiveGroupAndRedirect(activeGroup.id);
            return null;
          }
          // Entity not shared with current workspace
          setDisplay(true);
          return (
            <ReadOnMountHOC
              type="cursor"
              entityName={Permission.entityName}
              params={{
                [`${topLevelEntity.entityName}_id`]:
                  topLevelEntity.id,
                user_id: authenticatedUser.id,
              }}
              loading={false}
              children={({
                entities: permissions,
                status: entitySearchStatus,
              }) => {
                if (
                  !(
                    entitySearchStatus &&
                    !entitySearchStatus.isFetching
                  )
                ) {
                  return loader;
                }
                let groups: Group[] = permissions.map(
                  (p: Permission) => p.group,
                );

                if (groups.length === 0) {
                  setGroupIdToRead(
                    (topLevelEntity as ShareableEntity).owner.id,
                  );
                  return null;
                }

                if (
                  (topLevelEntity as ShareableEntity).owner
                    .allowed_actions.length > 2
                ) {
                  // Combination of owner group + all other groups the topLevelEntity has been shared with.
                  groups = [
                    (topLevelEntity as ShareableEntity).owner,
                    ...groups,
                  ];
                }

                if (groups.length === 1) {
                  setGroupIdToRead(groups[0].id);
                  return null;
                }

                return (
                  <>
                    <Header>
                      Select a workspace in which to open{' '}
                      {getHumanReadableEntityName(entity.entityName)}
                    </Header>
                    <TableSimple
                      hideHeader
                      rows={groups}
                      columns={[
                        {
                          header: 'Owner',
                          content: (
                            group: Group,
                          ): React.ReactElement => (
                            <GroupInitials group={group} />
                          ),
                          cellProps: { style: { width: 100 } },
                        },
                        {
                          header: 'Name',
                          content: (
                            group: Group,
                          ): React.ReactElement => (
                            <div
                              data-testid="protected-link-name"
                              className={styles.name}
                              onClick={(): void =>
                                setGroupIdToRead(group.id)
                              }
                            >
                              {group.name}
                            </div>
                          ),
                          cellProps: { style: { flex: 3 } },
                        },
                      ]}
                    />
                  </>
                );
              }}
            />
          );
        }}
      />
    );
  }

  return <div className={styles.tableContainer}>{component}</div>;
};

export default withAuthenticatedUser(
  withActiveGroup(withRouter(ProtectedEntityLinkContent)),
);
