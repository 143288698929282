/**
 * Labstep
 *
 * @module components/ExperimentWorkflow/Info/EndedAt
 * @desc Showing experimentWorkflow.ended_at
 */

import ExperimentWorkflowInfoDateAt from 'labstep-web/components/ExperimentWorkflow/Info/DateAt';
import React from 'react';
import { ExperimentWorkflowInfoEndedAtProps } from './types';

export const ExperimentWorkflowInfoEndedAt: React.FC<
  ExperimentWorkflowInfoEndedAtProps
> = ({ experimentWorkflow }) => (
  <ExperimentWorkflowInfoDateAt
    experimentWorkflow={experimentWorkflow}
    field={
      experimentWorkflow.statusType === 'completed'
        ? 'ended_at'
        : 'end_planned_at'
    }
    label={
      experimentWorkflow.statusType === 'completed'
        ? 'completion'
        : 'planned completion'
    }
    dateTimePickerOptions={{
      minDate: experimentWorkflow.activeStart,
    }}
    popup={
      experimentWorkflow.activeEnd &&
      (experimentWorkflow.statusType === 'completed'
        ? 'Date Completed'
        : 'Planned Completion Date')
    }
  />
);

export default ExperimentWorkflowInfoEndedAt;
