/**
 * Labstep
 *
 * @module screens/UserGroupNotification/Manager
 */

import UserGroupNotificationTable from 'labstep-web/components/UserGroupNotification/Table';
import { withActiveGroup } from 'labstep-web/containers/ActiveGroup';
import { ReadOnMountHOC } from 'labstep-web/hoc/ReadOnMount';
import { UserGroupNotification } from 'labstep-web/models/user-group-notification.model';
import React from 'react';
import { IUserGroupNotificationManagerProps } from './types';

export const UserGroupNotificationManager: React.FC<
  IUserGroupNotificationManagerProps
> = ({ activeGroup }) => {
  if (!activeGroup) {
    return null;
  }

  return (
    <ReadOnMountHOC
      type="cursor"
      entityName={UserGroupNotification.entityName}
      params={{
        user_group_id: activeGroup.logged_user_user_group!.id,
        count: 50,
      }}
      children={({
        entities,
      }: {
        entities: UserGroupNotification[];
      }) => (
        <UserGroupNotificationTable
          userGroupNotifications={entities}
        />
      )}
    />
  );
};

export default withActiveGroup(UserGroupNotificationManager);
