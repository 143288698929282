/**
 * Labstep
 */

import { IconProps } from 'labstep-web/core/Icon/types';
import { Entity } from 'labstep-web/models/entity.model';
import { ColDefParams } from '../types';

export interface DataGridColumnHeaderProps
  extends ColDefParams<Entity> {
  displayName?: string;
  icon?: IconProps['name'] | null;
  cornerIcon?: IconProps['cornerIcon'];
  action?: React.ReactElement;
  secondaryHeader?: string;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
  /** Merge with parent/child cell */
  mergeAs?: DataGridColumnHeaderMergeAsTypes | null;
}

export type DataGridColumnHeaderStyleStateType = {
  marginTop: number;
  marginBottom: number;
};

// eslint-disable-next-line no-shadow
export enum DataGridColumnHeaderMergeAsTypes {
  /** Parent of merged column header */
  parent = 'parent',
  /** Child of merged column header */
  child = 'child',
}
