/**
 * Labstep
 *
 * @module components/File/Table
 * @desc Table listing all Files
 */

import EntityTable from 'labstep-web/components/Entity/Table';
import FileActionMenu from 'labstep-web/components/File/Action/Menu';
import FilePreview from 'labstep-web/components/File/Preview';
import FileTypeImage from 'labstep-web/components/File/TypeImage';
import { File } from 'labstep-web/models/file.model';
import { humanReadableDate } from 'labstep-web/services/date.service';
import React from 'react';
import styles from './styles.module.scss';
import { FileNamePreviewProps, FileTableProps } from './types';

export const FileNamePreview: React.FC<FileNamePreviewProps> = ({
  file,
}) => (
  <FilePreview
    file={file}
    withDefault
    viewComponent={({ toggle }): React.ReactElement => (
      <div className={styles.nameContainer} onClick={toggle}>
        <div>
          <FileTypeImage file={file} size="mini" />
        </div>
        <div>{file.name}</div>
      </div>
    )}
  />
);

const extraColumns = [
  {
    header: 'Created on',
    content: (file: File): string =>
      humanReadableDate(file.created_at),
    sortKey: 'created_at',
    cellProps: { style: { flex: 4 } },
  },
  {
    header: 'File Type',
    content: (file: File): string =>
      file.extension ? file.extension.toUpperCase() : '-',
    sortKey: 'extension',
    cellProps: { style: { flex: 3 } },
  },
  {
    header: 'Size',
    content: (file: File): string => file.filesize,
    sortKey: 'extension',
    cellProps: { style: { flex: 2 } },
  },
];

export const FileTable: React.FC<FileTableProps> = ({
  files,
  actions,
  ...rest
}) => (
  <EntityTable
    {...rest}
    id="files-table"
    primaryColumn={{
      header: '',
      content: (file): React.ReactElement => (
        <FileNamePreview file={file} />
      ),
      cellProps: { width: 4 },
    }}
    entities={files}
    extraColumns={extraColumns}
    actionMenu={({ entity }): React.ReactElement => (
      <FileActionMenu file={entity} actions={actions} />
    )}
    withCheckbox={false}
  />
);

export default FileTable;
