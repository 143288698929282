/**
 * Labstep
 *
 * @module components/Tag/Action/Edit
 * @desc Edit Tag
 */

import EntityActionEdit from 'labstep-web/components/Entity/Action/Edit';
import TagFormEdit from 'labstep-web/components/Tag/Form/Edit';
import Modal from 'labstep-web/core/Modal/Default';
import React from 'react';
import { ITagActionEditProps } from './types';

const TagActionEdit: React.FC<ITagActionEditProps> = ({ tag }) => (
  <Modal
    header="Edit tag"
    viewComponent={({ toggleModal }) => (
      <EntityActionEdit
        actionComponentProps={{
          type: 'option',
          onClick: toggleModal,
        }}
        entity={tag}
      />
    )}
    content={({ toggleModal }) => (
      <TagFormEdit tag={tag} options={{ onSuccess: toggleModal }} />
    )}
  />
);

export default TagActionEdit;
