/**
 * Labstep
 *
 * @module components/Folder/Breadcrumb
 * @desc Breadcrumb for Folder
 */

import FolderActionCreate from 'labstep-web/components/Folder/Action/Create';
import FolderBreadcrumbItem from 'labstep-web/components/Folder/Breadcrumb/Item';
import FolderPaneList from 'labstep-web/components/Folder/Pane/List';
import { ICONS } from 'labstep-web/constants/icons';
import { EntityView } from 'labstep-web/models/entity-view.model';
import { ExperimentWorkflow } from 'labstep-web/models/experiment-workflow.model';
import { FolderType } from 'labstep-web/models/folder.model';
import { ProtocolCollection } from 'labstep-web/models/protocol-collection.model';
import ScreensIndexBreadcrumb from 'labstep-web/screens/Breadcrumb';
import React from 'react';
import styles from './styles.module.scss';
import { FolderBreadcrumbProps } from './types';

export const FolderBreadcrumb: React.FC<FolderBreadcrumbProps> = ({
  type,
}) => {
  let entityName = '';

  if (type === FolderType.entity_view) {
    entityName = EntityView.getHumanReadableEntityName(true, true);
  }
  if (type === FolderType.experiment_workflow) {
    entityName = ExperimentWorkflow.getHumanReadableEntityName(
      true,
      true,
    );
  }
  if (type === FolderType.protocol_collection) {
    entityName = ProtocolCollection.getHumanReadableEntityName(
      true,
      true,
    );
  }

  const icon = ICONS[type].primary;

  return (
    <ScreensIndexBreadcrumb
      text={`All ${entityName}`}
      rightMobileIcon={ICONS.folder.primary}
      dropdownContent={({ header }) => (
        <div className={styles.contentContainer}>
          {header}
          <FolderPaneList folderType={type} />
          <FolderActionCreate folderType={type} />
        </div>
      )}
      icon={icon}
      right={({ searchParams }) =>
        searchParams.folder_id || searchParams.folder_group_not_id ? (
          <FolderBreadcrumbItem searchParams={searchParams} />
        ) : null
      }
    />
  );
};

export default FolderBreadcrumb;
