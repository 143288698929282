/**
 * Labstep
 *
 * @module core/Select/FloatingSearch
 * @desc Async Floating Search Select
 */

import { ActionComponent } from 'labstep-web/core/Action/Component';
import SearchSelect from 'labstep-web/core/Select/Search';
import React, { useRef, useState } from 'react';
import styles from './styles.module.scss';
import { FloatingSearchSelectProps } from './types';

export const FloatingSearchSelect: React.FC<
  FloatingSearchSelectProps
> = ({
  alignRight,
  keepMenuOpen,
  actionComponentProps,
  onChange,
  ...rest
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);

  const handleButtonClick = () => {
    setIsOpen(!isOpen);
  };

  const handleClickOutside = (e: MouseEvent) => {
    if (
      containerRef.current &&
      !containerRef.current.contains(e.target as Node)
    ) {
      setIsOpen(false);
    }
  };

  React.useEffect(() => {
    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen]);

  return (
    <div
      ref={containerRef}
      className={styles.floatingSearchContainer}
    >
      <ActionComponent
        text="Add"
        icon="add"
        {...actionComponentProps}
        onClick={handleButtonClick}
      />

      {isOpen && (
        <div
          data-testid="floating-search-select"
          className={`${styles.dropdownContainer} ${
            alignRight ? styles.alignRight : ''
          }`}
        >
          <SearchSelect
            {...rest}
            isFloating
            keepMenuOpen={!keepMenuOpen ? undefined : true}
            autoFocus
            components={{
              DropdownIndicator: () => null,
              IndicatorSeparator: () => null,
            }}
            onChange={(option) => {
              if (onChange) {
                onChange(option);
              }
              if (!keepMenuOpen) {
                setIsOpen(false);
              }
            }}
          />
        </div>
      )}
    </div>
  );
};

FloatingSearchSelect.defaultProps = {
  alignRight: false,
};

export default FloatingSearchSelect;
