/**
 * Labstep
 */

import {
  type PayloadAction,
  type SliceCaseReducers,
  createSlice,
} from '@reduxjs/toolkit';
import { JupyterNotebook } from 'labstep-web/models/jupyter-notebook.model';
import { Metadata } from 'labstep-web/models/metadata';
import { Molecule } from 'labstep-web/models/molecule.model';
import { ProtocolTable } from 'labstep-web/models/protocol-table.model';
import { ProtocolValue } from 'labstep-web/models/protocol-value.model';
import { ActiveEditModalState } from './types';

const initialState = null;

// TODO: get these from the slices
const actionTypes = {
  SUCCESS_CREATE_JUPYTER_NOTEBOOK: JupyterNotebook.entityName,
  SUCCESS_CREATE_PROTOCOL_TABLE: ProtocolTable.entityName,
  SUCCESS_CREATE_PROTOCOL_VALUE: ProtocolValue.entityName,
  SUCCESS_CREATE_MOLECULE: Molecule.entityName,
  SUCCESS_CREATE_METADATA: Metadata.entityName,
};

export const activeEditModalSlice = createSlice<
  ActiveEditModalState,
  SliceCaseReducers<ActiveEditModalState>
>({
  name: 'activeEditModal',
  initialState,
  reducers: {
    setActiveEditModal: (
      state,
      action: PayloadAction<ActiveEditModalState>,
    ) => {
      return action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(
        (action) =>
          // TODO: Match this by using action.match
          Object.keys(actionTypes).includes(action.type) &&
          action.meta.redirect,
        (state, action) => ({
          id: action.payload.result,
          entityName:
            actionTypes[action.type as keyof typeof actionTypes],
        }),
      )
      .addMatcher(
        (action) =>
          [
            'SUCCESS_UPDATE_PROTOCOL_VALUE',
            'SUCCESS_UPDATE_METADATA',
          ].includes(action.type) && action.meta.body.deleted_at,
        () => null,
      );
  },
});

// TODO: update state type
export const selectActiveEditModal = (state: any) =>
  state.activeEditModal;

export const { setActiveEditModal } = activeEditModalSlice.actions;

export default activeEditModalSlice.reducer;
