/**
 * Labstep
 *
 * @module core/Select
 * @desc Wrapper around react-select Select
 */

import classNames from 'classnames';
import React from 'react';
import ReactSelect from 'react-select';
import styles from './styles.module.scss';
import './styles.scss';
import { SelectProps } from './types';

export const Select: React.FC<SelectProps> = ({ label, ...rest }) => {
  const component = (
    <ReactSelect
      data-cy="core/Select"
      data-testid="select"
      inputId={label ? label.text : undefined}
      className="Select-Search-Container"
      classNamePrefix="Select-Search"
      {...rest}
    />
  );
  return label ? (
    <div
      className={classNames({
        [styles[label.position]]: true,
      })}
    >
      <label htmlFor={label.text}>{label.text}</label>
      {component}
    </div>
  ) : (
    component
  );
};

export default Select;
