/**
 * Labstep
 *
 * @module models/protocol-step
 * @desc Typescript export class for ProtocolStep
 */

import { Type } from 'class-transformer';
import { Entity } from 'labstep-web/models/entity.model';
import { Protocol } from 'labstep-web/models/protocol.model';
import { Thread } from 'labstep-web/models/thread.model';
import NodeStep from 'labstep-web/prosemirror/nodes/protocol-step';
import { Experiment } from './experiment.model';

export class ProtocolStep extends Entity {
  public static readonly idAttr = 'guid' as const;

  public static readonly entityName = 'protocol_step';

  public get entityName(): string {
    return ProtocolStep.entityName;
  }

  public constructor(data: Partial<ProtocolStep> = {}) {
    super();
    Object.assign(this, data);
  }

  public id!: never;

  public name!: string | null;

  public position!: number;

  public started_at!: string | null;

  public ended_at!: string | null;

  @Type(() => Protocol)
  public protocol!: Protocol | null;

  @Type(() => Experiment)
  public experiment!: Experiment | null;

  @Type(() => Thread)
  public thread!: Thread;

  public getStepNumber(protocol: Experiment | Protocol): number {
    return (
      protocol.stepsContentState.findIndex(
        (node: (typeof NodeStep)['spec']) =>
          this.guid === node.attrs?.guid,
      ) + 1
    );
  }
}
