/**
 * Labstep
 *
 * @module grid/Report/components/ToolPanel/Section
 * @desc Entity DataGrid column options section for shown/hidden fields
 */

import Header from 'labstep-web/core/Header';
import Sortable from 'labstep-web/core/Sortable';
import EntityDataGridToolPanelSectionItem from 'labstep-web/grid/Report/components/ToolPanel/Section/Item';
import { GridReportService } from 'labstep-web/grid/Report/services/grid-report.service';
import React, { useMemo } from 'react';
import {
  ColumnWithId,
  IEntityDataGridToolPanelSectionProps,
  SectionType,
} from './types';

export const EntityDataGridToolPanelSection: React.FC<
  IEntityDataGridToolPanelSectionProps
> = ({ columns, section, onToggleColumnVisible, onSortColumns }) => {
  if (!columns.length) {
    return null;
  }
  const items: { id: string; column: ColumnWithId }[] = useMemo(
    () => GridReportService.columnsToItems(columns),
    [columns],
  );

  const onSortEnd: Parameters<typeof Sortable>[0]['onSortEnd'] =
    useMemo(
      () => GridReportService.onSortEndColumns(items, onSortColumns),
      [items, onSortColumns],
    );

  return (
    <div style={{ overflowY: 'auto' }}>
      {section === SectionType.hidden && (
        <Header size="mini">Hidden</Header>
      )}
      <Sortable
        /** dnd-kit transform does not work with overlay in this case */
        hideOverlay
        items={items}
        onSortEnd={onSortEnd}
        renderItem={({ column }): JSX.Element => (
          <EntityDataGridToolPanelSectionItem
            column={column}
            section={section}
            onToggleColumnVisible={onToggleColumnVisible}
          />
        )}
      />
    </div>
  );
};

export default EntityDataGridToolPanelSection;
