/**
 * Labstep
 *
 * @module components/ProtocolDevice/Action/Delete
 * @desc Delete for Protocol Device
 */

import EntityActionDelete from 'labstep-web/components/Entity/Action/Delete';
import React from 'react';
import { IProtocolDeviceActionDeleteProps } from './types';
import { getDisallowedMessage } from './utils';

export const ProtocolDeviceActionDelete: React.FC<
  IProtocolDeviceActionDeleteProps
> = ({ protocolDevice }) => (
  <EntityActionDelete
    entity={protocolDevice}
    disallowedMessage={
      !!protocolDevice.metadatas.length &&
      getDisallowedMessage(protocolDevice.metadatas)
    }
    isRemoveAction
  />
);

export default ProtocolDeviceActionDelete;
