/**
 * Labstep
 *
 * @module prosemirror/extensions/alignment/utils
 * @desc Method to detect if alignment is active for selected node
 */

import { Alignment } from 'labstep-web/prosemirror/extensions/alignment/types';
import { EditorState } from 'prosemirror-state';

export const getActiveAlignment = (
  state: EditorState,
): boolean | string => {
  const {
    selection: { from, to },
    doc,
  } = state;

  let keepLooking = true;
  let activeAlignment = false;
  doc.nodesBetween(from, to, (node) => {
    if (keepLooking && node.attrs.align) {
      keepLooking = false;
      activeAlignment = node.attrs.align;
    }
    return keepLooking;
  });
  return activeAlignment;
};

export const isAlignmentActive = (
  state: EditorState,
  alignment: Alignment,
): boolean => {
  return getActiveAlignment(state) === alignment;
};
