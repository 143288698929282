/**
 * Labstep
 *
 * @module components/Group/Settings/Export
 * @desc Group settings for Export
 */

import ActionComponent from 'labstep-web/core/Action/Component';
import EmptyState from 'labstep-web/core/Card/EmptyState';
import React from 'react';

const GroupSettingsExport: React.FC = () => {
  return (
    <EmptyState
      src="/img/empty-state/export-client-graphic.svg"
      explanation={
        'Workspaces can be exported using our Desktop Client. Click below to download for your platform.'
      }
      action={
        <a
          href={
            'https://github.com/Labstep/desktop-client-releases/releases/download/v0.1.15/Labstep.Desktop.Client.Setup.0.1.15.exe'
          }
          style={{ color: 'white' }}
          aria-label="Download for Windows"
        >
          <ActionComponent
            type="button"
            elementProps={{ primary: true }}
            text="Download for Windows"
          />
        </a>
      }
      secondaryAction={
        <a
          href={
            'https://github.com/Labstep/desktop-client-releases/releases/download/v0.1.15/Labstep.Desktop.Client-0.1.15.pkg'
          }
          style={{ color: 'white' }}
          aria-label="Download for Mac"
        >
          <ActionComponent
            type="button"
            elementProps={{ primary: true, basic: true }}
            text="Download for Mac"
          />
        </a>
      }
    />
  );
};

export default GroupSettingsExport;
