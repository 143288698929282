/**
 * Labstep
 *
 * @module components/Folder/Card/Manager
 * @desc Folder Manager Card component
 */

import EntityToggleList from 'labstep-web/components/Entity/List/Toggle';
import FolderList from 'labstep-web/components/Folder/List';
import { ICONS } from 'labstep-web/constants/icons';
import { withActiveGroup } from 'labstep-web/containers/ActiveGroup';
import Button from 'labstep-web/core/Button';
import Modal from 'labstep-web/core/Modal/Default';
import Popup from 'labstep-web/core/Popup';
import { Folder } from 'labstep-web/models/folder.model';
import React from 'react';
import { FolderCardManagerProps } from './types';

export const FolderCardManager: React.FC<FolderCardManagerProps> = ({
  parentEntity,
  activeGroup,
}) => (
  <Modal
    header="Manage Collections"
    viewComponent={({ toggleModal }) => (
      <Popup
        trigger={
          <Button
            onClick={toggleModal}
            icon={ICONS.folder.primary}
            circular
          />
        }
        content="Manage Collections"
        size="mini"
      />
    )}
    content={
      <EntityToggleList
        parent={parentEntity}
        entityName={Folder.entityName}
        labelKey="folder_path"
        caseSensitive={false}
        extraParams={{
          group_id: activeGroup?.id,
          count: 50,
        }}
        creatable
        createBody={{ type: parentEntity.entityName }}
        actionMessageIcon={ICONS.folder.primary}
        list={({ entities }) => (
          <FolderList
            folders={entities as Folder[]}
            parentEntity={parentEntity}
          />
        )}
      />
    }
  />
);

export default withActiveGroup(FolderCardManager);
