/**
 * Labstep
 *
 * @module components/OrderRequest/Form/ShowEdit/Amount
 * @desc ShowEdit form for order request amount
 */

import { fieldOrderRequestAmount } from 'labstep-web/components/OrderRequest/Form/fields';
import ShowEditAmount from 'labstep-web/core/Form/ShowEdit/Amount';
import CurrencyService from 'labstep-web/services/currency.service';
import React from 'react';
import { OrderRequestFormShowEditAmountProps } from './types';

export const OrderRequestFormShowEditAmount: React.FC<
  OrderRequestFormShowEditAmountProps
> = ({ orderRequest, withCurrency }) => (
  <ShowEditAmount
    currency={orderRequest.currency}
    entity={orderRequest}
    entityName={orderRequest.entityName}
    field={fieldOrderRequestAmount}
  >
    {orderRequest.amount && withCurrency
      ? CurrencyService.print(
          orderRequest.amount,
          orderRequest.currency,
          true,
        )
      : CurrencyService.getPrice(
          orderRequest.amount,
          orderRequest.currency,
          true,
        )}
  </ShowEditAmount>
);

export default OrderRequestFormShowEditAmount;
