/**
 * Labstep
 *
 * @module constants/user-group-notification
 * @desc User Notifications settings
 */

import { Entity } from 'labstep-web/models/entity.model';
import { NotificationType } from 'labstep-web/models/notification.model';

const NotificationTypeAlert = {
  label: 'have alerts on date fields',
  mode: true,
};

export type NotificationTypeValue = {
  label: string;
  // If true user can change UserGroupNotification mode field
  mode: boolean;
};

export type NotificationDefinition = Partial<
  Record<NotificationType, NotificationTypeValue>
>;

export type NotificationDefinitions = Record<
  Entity['entityName'],
  NotificationDefinition
>;

export const NOTIFICATIONS_DEFINITIONS: NotificationDefinitions = {
  experiment_workflow: {
    experiment_workflow_created: {
      label: 'are created',
      mode: false,
    },
    experiment_workflow_assigned: {
      label: 'are assigned to me',
      mode: false,
    },
    experiment_workflow_entity_state_updated: {
      label: 'status is updated',
      mode: true,
    },
    comment_created_experiment_workflow: {
      label: 'have new notes / comments',
      mode: true,
    },
    signature_created: { label: 'are signed', mode: true },
    signature_request_created: {
      label: 'need my signature',
      mode: false,
    },
    signature_revoked: {
      label: 'have signatures revoked',
      mode: true,
    },
    notification_alert_triggered: NotificationTypeAlert,
    jupyter_instance_run_status_updated: {
      label: 'have jupyter code capsule run completed',
      mode: true,
    },
  },
  device: {
    device_created: { label: 'are created', mode: false },
    device_assigned: { label: 'are assigned to me', mode: false },
    comment_created_device: {
      label: 'have new notes / comments',
      mode: true,
    },
    device_data_created: { label: 'have new data', mode: true },
    device_booking_created: {
      label: 'have new bookings',
      mode: true,
    },
    device_booking_updated: {
      label: 'bookings are amended',
      mode: true,
    },
    notification_alert_triggered: NotificationTypeAlert,
  },
  group: {
    comment_created_group: {
      label: 'have new message board posts',
      mode: false,
    },
  },
  order_request: {
    order_request_created: { label: 'are created', mode: false },
    order_request_updated: {
      label: 'are updated or marked urgent',
      mode: true,
    },
    order_request_assigned: {
      label: 'are assigned to me',
      mode: false,
    },
    comment_created_order_request: {
      label: 'have new notes / comments',
      mode: true,
    },
    notification_alert_triggered: NotificationTypeAlert,
  },
  protocol_collection: {
    protocol_collection_created: {
      label: 'are created',
      mode: false,
    },
    protocol_created: {
      label: 'new draft version created',
      mode: true,
    },
    protocol_updated_is_draft: {
      label: 'new version set live',
      mode: true,
    },
    protocol_collection_assigned: {
      label: 'are assigned to me',
      mode: false,
    },
    comment_created_protocol_collection: {
      label: 'have new notes / comments',
      mode: true,
    },
  },
  purchase_order: {
    purchase_order_created: { label: 'are created', mode: false },
    purchase_order_updated_order_request: {
      label: 'have items added / removed',
      mode: true,
    },
    purchase_order_updated_status: {
      label: 'are finalised / completed',
      mode: true,
    },
    purchase_order_assigned: {
      label: 'are assigned to me',
      mode: false,
    },
    comment_created_purchase_order: {
      label: 'have new notes / comments',
      mode: true,
    },
    notification_alert_triggered: NotificationTypeAlert,
  },
  resource: {
    resource_created: { label: 'are created', mode: false },
    resource_assigned: { label: 'are assigned to me', mode: false },
    comment_created_resource: {
      label: 'have new notes / comments',
      mode: true,
    },
    resource_available_resource_item_count_alert: {
      label: 'available items fall below threshold',
      mode: true,
    },
    notification_alert_triggered: NotificationTypeAlert,
  },
  resource_item: {
    resource_item_assigned: {
      label: 'are assigned to me',
      mode: false,
    },
    comment_created_resource_item: {
      label: 'have new notes / comments',
      mode: true,
    },
    resource_item_amount_alert: {
      label: 'amount remaining falls below threshold',
      mode: true,
    },
    notification_alert_triggered: NotificationTypeAlert,
  },
  resource_location: {
    resource_location_assigned: {
      label: 'are assigned to me',
      mode: false,
    },
    comment_created_resource_location: {
      label: 'have new notes / comments',
      mode: true,
    },
    notification_alert_triggered: NotificationTypeAlert,
  },
  comment: {
    user_mention_created: {
      label: "I'm mentioned in a note / comment",
      mode: false,
    },
  },
  entity_state_workflow: {
    entity_state_workflow_created: {
      label: 'are created',
      mode: false,
    },
    entity_state_workflow_assigned: {
      label: 'are assigned to me',
      mode: false,
    },
  },
};
