/**
 * Labstep
 *
 * @module core/DataGrid/ColumnHeader/EntityImport/DateFormat
 * @desc Dropdown to switch metadata type
 */

import { useAgGridEntityImportServiceFromContext } from 'labstep-web/contexts/grid/hook';
import ActionComponent from 'labstep-web/core/Action/Component';
import Dropdown from 'labstep-web/core/Dropdown/Portal';
import React from 'react';
import { DataGridColumnHeaderEntityImportDateFormatProps } from './types';
import { getOptions } from './utils';

export const DataGridColumnHeaderEntityImportDateFormat: React.FC<
  DataGridColumnHeaderEntityImportDateFormatProps
> = ({ colDef }) => {
  const gridEntityImportService =
    useAgGridEntityImportServiceFromContext();
  if (!gridEntityImportService) {
    return null;
  }

  return (
    <Dropdown
      trigger={<div>{colDef.entity_import?.date_format}</div>}
      closeOnChange
    >
      {getOptions(colDef).map((option) => (
        <ActionComponent
          key={option}
          type="option"
          text={option}
          onClick={(): void =>
            gridEntityImportService.setColDefEntityImportParameter(
              colDef.colId,
              {
                date_format: option,
              },
            )
          }
        />
      ))}
    </Dropdown>
  );
};

export default DataGridColumnHeaderEntityImportDateFormat;
