/**
 * Labstep
 */

import { IStateDispatchProps } from 'labstep-web/prosemirror/marks/types';
import NodeCode from 'labstep-web/prosemirror/nodes/code';

export const replaceWithCode = (
  state: IStateDispatchProps['state'],
  dispatch: IStateDispatchProps['dispatch'],
  from: number,
  to: number,
  mode: string,
  value: string,
) => {
  const { tr, schema } = state;
  const $from = tr.doc.resolve(from);
  const $to = tr.doc.resolve(to);
  const node = schema.nodes[NodeCode.key].create({
    mode,
    value,
  });
  tr.setStoredMarks([]);

  // If empty list item
  if (
    $from.parent.type === schema.nodes.paragraph &&
    $to.parent.type === schema.nodes.list_item
  ) {
    const createParagraph = schema.nodes.paragraph.createAndFill();
    if (createParagraph) {
      tr.insert(to, createParagraph);
    }
  }

  tr.replaceRangeWith(from, to, node);
  if (dispatch) {
    dispatch(tr);
  }
};
