/**
 * Labstep
 *
 * @module components/OrderRequest/Action/View
 * @desc Button linking to orderRequest show
 */

import EntityActionView from 'labstep-web/components/Entity/Action/View';
import { OrderRequest } from 'labstep-web/models/order-request.model';
import React from 'react';
import { OrderRequestActionViewProps } from './types';

const OrderRequestActionView: React.FC<
  OrderRequestActionViewProps
> = ({ orderRequest }) => (
  <EntityActionView
    entityName={OrderRequest.entityName}
    params={{ id: orderRequest.id }}
  />
);

export default OrderRequestActionView;
