/**
 * Labstep
 *
 * @module grid/Index/coldefs/rowNumber
 * @desc Column Definition for displaying a static row number
 */

import DataGrid from 'labstep-web/core/DataGrid';
import { ColDef } from 'labstep-web/core/DataGrid/types';
import { Entity } from 'labstep-web/models/entity.model';

export const ROW_NUMBER_COLUMN_ID = 'rowN';

export default {
  headerName: '#',
  colId: ROW_NUMBER_COLUMN_ID,
  maxWidth: 50,
  lockPosition: 'left',
  cellRenderer: DataGrid.CellRenderer.RowNumber,
  cellStyle: { paddingLeft: 0, paddingRight: 0 },
  resizable: false,
} as ColDef<Entity>;
