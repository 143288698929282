/**
 * Labstep
 *
 * @module components/ProtocolValue/Preview/Inline
 * @desc ProtocoLValue inline preview
 */

import EntityActionClear from 'labstep-web/components/Entity/Action/Clear';
import ProtocolValueFormShowEditResourceItem from 'labstep-web/components/ProtocolValue/Form/ShowEdit/ResourceItem';
import ResourcePreview from 'labstep-web/components/Resource/Preview';
import ResourceItemPreview from 'labstep-web/components/ResourceItem/Preview';
import Flex from 'labstep-web/core/Flex';
import Hoverable from 'labstep-web/core/Hoverable';
import React from 'react';
import styles from './styles.module.scss';
import { IProtocolValuePreviewInlineProps } from './types';

export const ProtocolValuePreviewInline: React.FC<
  IProtocolValuePreviewInlineProps
> = ({ protocolValue, withResourceItem = true }) => (
  <Flex className={styles.container}>
    <Hoverable
      className={styles.preview}
      hoverable={
        protocolValue.resourceItem ? (
          <ResourceItemPreview
            basic
            resourceItem={protocolValue.resourceItem}
          />
        ) : (
          <ResourcePreview resource={protocolValue.resource} />
        )
      }
      component={
        <EntityActionClear
          entityName={protocolValue.entityName}
          id={protocolValue.guid}
          field="resource_id"
        />
      }
    />
    {!protocolValue.resourceItem && withResourceItem && (
      <ProtocolValueFormShowEditResourceItem
        protocolValue={protocolValue}
        type={null}
      />
    )}
  </Flex>
);

export default ProtocolValuePreviewInline;
