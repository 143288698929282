/**
 * Labstep
 */

import { ValueGetterParams } from 'ag-grid-community';
import { CellRendererParams } from 'labstep-web/core/DataGrid/types';
import { Resource } from 'labstep-web/models/resource.model';

export const colDefResourceTemplate = {
  colId: 'template',
  editable: false,
  cellRenderer: (params: CellRendererParams<Resource>) => {
    return params.node?.data?.template
      ? params.node?.data?.template.name || 'Untitled Category'
      : 'No Category';
  },
  valueGetter: (params: ValueGetterParams) =>
    params.node?.data?.template?.idAttr || null,
  valueGetterExport: (params: ValueGetterParams) =>
    params.node?.data?.template
      ? params.node?.data?.template.name || 'Untitled Category'
      : 'No Category',
  headerName: 'Category',
};
