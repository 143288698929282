/**
 * Labstep
 *
 * @module components/EntityImport/Modal/Trigger
 * @desc Modal trigger for EntityImport
 */

import { CanCreate } from 'labstep-web/components/Entity/Can';
import { ENTITY_IMPORT_ROUTE_ID } from 'labstep-web/components/EntityImport/Modal';
import { EntityImportModalTriggerProps } from 'labstep-web/components/EntityImport/Modal/Trigger/types';
import { ICONS } from 'labstep-web/constants/icons';
import ActionComponent from 'labstep-web/core/Action/Component';
import { useModalRoute } from 'labstep-web/hooks/modalRoute';
import { EntityImport } from 'labstep-web/models/entity-import.model';
import React from 'react';

export const EntityImportModalTrigger: React.FC<
  EntityImportModalTriggerProps
> = ({
  targetEntityName,
  templateId,
  viewComponent,
  actionComponentProps,
}) => {
  const { open, toggleModal } = useModalRoute(ENTITY_IMPORT_ROUTE_ID);
  const toggleModalWithState = (): void => {
    toggleModal({
      templateId,
      entityName: targetEntityName,
    });
  };
  return (
    viewComponent?.({ open, toggleModal: toggleModalWithState }) || (
      <CanCreate entityName={EntityImport.entityName}>
        <ActionComponent
          type="option"
          text="Import"
          icon={ICONS.entity_import.primary}
          onClick={(): void => toggleModalWithState()}
          {...actionComponentProps}
        />
      </CanCreate>
    )
  );
};

export default EntityImportModalTrigger;
