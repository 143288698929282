/**
 * Labstep
 *
 * @module containers/ShareLinkEmail
 * @desc Email ShareLink container
 */

import { APICallOptions } from 'labstep-web/models';
import {
  shareLinkAcceptAction,
  shareLinkAcceptActionType,
} from 'labstep-web/state/actions/share-link-accept';
import { selectApiStatus } from 'labstep-web/state/selectors/ui';
import { LabstepReduxState } from 'labstep-web/state/types';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';

export type ShareLinkAcceptContainerChildrenProps = ReturnType<
  typeof mapDispatchToProps
> &
  ReturnType<typeof mapStateToProps>;

const Container = ({
  children,
  ...rest
}: {
  children: (
    props: ShareLinkAcceptContainerChildrenProps,
  ) => React.ReactElement | null;
} & ShareLinkAcceptContainerChildrenProps): React.ReactElement | null =>
  children(rest);

const mapStateToProps = (state: LabstepReduxState) => ({
  status: selectApiStatus(state, shareLinkAcceptActionType),
});

const mapDispatchToProps = (
  dispatch: Dispatch,
  ownProps: {
    token: string;
  },
) => ({
  accept(body: Record<string, unknown>, options?: APICallOptions) {
    dispatch(shareLinkAcceptAction(ownProps.token, body, options));
  },
});

export const ShareLinkAcceptContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(Container);
