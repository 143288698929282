/**
 * Labstep
 *
 * @module core/Breadcrumb
 */

import Flex from 'labstep-web/core/Flex';
import Icon from 'labstep-web/core/Icon';
import Link from 'labstep-web/core/Link';
import React from 'react';
import styles from './styles.module.scss';
import { IBreadcrumbProps } from './types';

const Breadcrumb: React.FC<IBreadcrumbProps> = ({ links }) => (
  <Flex vAlignContent="center" wrap>
    {links.map((link) => (
      <Flex key={link.name} vAlignContent="center">
        <Link className={styles.link} {...link.route}>
          {link.icon && <Icon name={link.icon} />}
          {link.name}
        </Link>{' '}
        <Icon color="grey" name="chevron right" size="small" />
      </Flex>
    ))}
  </Flex>
);

export default Breadcrumb;
