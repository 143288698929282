/**
 * Labstep
 *
 * @module components/ProtocolCollection/List
 * @desc ProtocolCollection List
 */

import ProtocolCollectionItem from 'labstep-web/components/ProtocolCollection/Item';
import List from 'labstep-web/core/List';
import React from 'react';
import { IProtocolCollectionListProps } from './types';

export const ProtocolCollectionList: React.FC<
  IProtocolCollectionListProps
> = ({ protocolCollections, actions }) => (
  <List id="protocol-list">
    {protocolCollections.map((collection) => (
      <ProtocolCollectionItem
        key={collection.id}
        protocolCollection={collection}
        actions={actions}
      />
    ))}
  </List>
);

export default ProtocolCollectionList;
