/**
 * Labstep
 *
 * @module components/Entity/Action/Tag
 * @desc Action to tag entity. Opens modal showing tag manager.
 */

import Can from 'labstep-web/components/Entity/Can';
import { Action } from 'labstep-web/components/Entity/Can/types';
import TagManager from 'labstep-web/components/Tag/Manager';
import { ICONS } from 'labstep-web/constants/icons';
import ActionComponent from 'labstep-web/core/Action/Component';
import React from 'react';
import { EntityActionTagProps } from './types';

const EntityActionTag: React.FC<EntityActionTagProps> = ({
  parentEntity,
}) => (
  <Can
    entityName={parentEntity.entityName}
    id={parentEntity.idAttr}
    action={Action.tag_add_remove}
  >
    <TagManager
      parentEntity={parentEntity}
      viewComponent={({ toggleModal }) => (
        <ActionComponent
          dataCy="components/Entity/Action/Tag"
          type="option"
          onClick={toggleModal}
          text={'Add Tags'}
          icon={ICONS.entity.actions.tag}
        />
      )}
    />
  </Can>
);

export default EntityActionTag;
