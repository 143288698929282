/**
 * Labstep
 *
 * @module components/ExperimentWorkflow/Action/Share
 * @desc Share a experiment workflow with all users on the platform
 */

import EntityActionShare from 'labstep-web/components/Entity/Action/Share';
import React from 'react';
import { ExperimentWorkflowActionShareProps } from './types';

export const ExperimentWorkflowActionShare: React.FC<
  ExperimentWorkflowActionShareProps
> = ({ experimentWorkflow }) => (
  <EntityActionShare entity={experimentWorkflow} />
);

export default ExperimentWorkflowActionShare;
