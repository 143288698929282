/**
 * Labstep
 *
 * @module prosemirror/components/Toolbar/Mark
 * @desc Buttons to set/unset marks
 */

import ToolbarButton from 'labstep-web/prosemirror/components/Toolbar/Button';
import { marks } from 'labstep-web/prosemirror/marks';
import { isMarkActive } from 'labstep-web/prosemirror/marks/utils';
import { modToPlatformText } from 'labstep-web/prosemirror/utils';
import { capitalize } from 'lodash';
import { toggleMark } from 'prosemirror-commands';
import React from 'react';
import { IMarkToolbarProps } from './types';

const MarkToolbar: React.FC<IMarkToolbarProps> = ({
  state,
  dispatch,
}) => (
  <div style={{ display: 'flex' }}>
    {marks
      .filter((mark) => !!mark.toolbarIcon)
      .map((mark) => {
        const shortcut = ` (${Object.keys(mark.keymap!)[0]
          .replace('Mod', modToPlatformText())
          .replace('-', '+')})`.toUpperCase();
        return (
          <ToolbarButton
            key={mark.key}
            icon={mark.toolbarIcon}
            popup={`${capitalize(mark.toolbarIcon)}${shortcut}`}
            disabled={
              !toggleMark(state.schema.marks[mark.key], {})(state)
            }
            isActive={isMarkActive(
              state,
              state.schema.marks[mark.key],
            )}
            onMouseDown={() => {
              toggleMark(state.schema.marks[mark.key], {})(
                state,
                dispatch,
              );
            }}
          />
        );
      })}
  </div>
);

export default MarkToolbar;
