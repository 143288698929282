/**
 * Labstep
 *
 * @module components/EntityUserRole/EditModal
 * @desc Edit Modal for EntityUserRole
 */

import Button from 'labstep-web/core/Button';
import HorizontalTable from 'labstep-web/core/Table/Horizontal';
import { EntityUserRole } from 'labstep-web/models/entity-user-role.model';
import React from 'react';
import ModalDefault from '../../../core/Modal/Default';
import EntityFormShowEditDescription from '../../Entity/Form/ShowEdit/Description';
import EntityFormShowEditName from '../../Entity/Form/ShowEdit/Name';
import styles from './styles.module.scss';
import { EntityUserRoleEditModalProps } from './types';

export const EntityUserRoleEditModal: React.FC<
  EntityUserRoleEditModalProps
> = ({ entityUserRole, onClose }) => {
  return (
    <ModalDefault
      open
      onClose={onClose}
      header="Configure Role"
      className={styles.roleModal}
      viewComponent={null}
      content={() => (
        <HorizontalTable
          rows={[
            {
              header: 'Name',
              content: (role: EntityUserRole) => (
                <EntityFormShowEditName entity={role} />
              ),
            },
            {
              header: 'Description',
              content: (role: EntityUserRole) => (
                <EntityFormShowEditDescription entity={role} />
              ),
            },
          ]}
          columns={[entityUserRole]}
        />
      )}
      footer={() => (
        <Button primary onClick={onClose}>
          Done
        </Button>
      )}
    />
  );
};
