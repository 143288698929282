/**
 * Labstep
 *
 * @module components/Experiment/CompletionChecklist/Modal
 * @desc Component showing a checklist of steps not completed for experiment
 */

import EntityActionEditModal from 'labstep-web/components/Entity/Action/Edit/Modal';
import ExperimentActionEnd, {
  ExperimentActionEndViewComponent,
} from 'labstep-web/components/Experiment/Action/End';
import ExperimentCompletionChecklistContent from 'labstep-web/components/Experiment/CompletionChecklist/Content';
import { ReadOnMountHOC } from 'labstep-web/hoc/ReadOnMount';
import { Experiment } from 'labstep-web/models/experiment.model';
import { ProtocolValue } from 'labstep-web/models/protocol-value.model';
import React from 'react';
import { ExperimentCompletionChecklistModalProps } from './types';

const ExperimentCompletionChecklistModal: React.FC<
  ExperimentCompletionChecklistModalProps
> = ({
  experiment,
  actionType,
  experimentWorkflow,
  action,
  ...rest
}) => {
  const experimentId = experiment.id;
  return (
    <EntityActionEditModal
      entity={experiment}
      header="Completion Checklist"
      field={['ended_at', 'started_at']}
      content={({ toggleModal }): React.ReactElement => (
        <ReadOnMountHOC
          type="entities"
          entityName={experiment.entityName}
          params={{ id: experiment.id, get_single: 1 }}
          loading={{ loader: 'spinner' }}
          children={({
            entity,
          }: {
            entity: Experiment;
          }): React.ReactElement => (
            <div>
              <ReadOnMountHOC
                type="count"
                entityName={ProtocolValue.entityName}
                loading={{ loader: 'placeholder', multiplier: 1 }}
                params={{
                  experiment_id: experimentId,
                  has_resource_item_any: false,
                  is_variable: false,
                }}
                children={({
                  total: protocolValuesWithoutResourceItemCount,
                }): React.ReactElement => (
                  <ExperimentCompletionChecklistContent
                    experimentWorkflow={experimentWorkflow}
                    experiment={
                      experimentWorkflow
                        ? experimentWorkflow.root_experiment
                        : experiment
                    }
                    onLinkNavigation={toggleModal}
                    protocolValuesWithoutResourceItemCount={
                      protocolValuesWithoutResourceItemCount
                    }
                  />
                )}
              />
              <>
                <br />

                {action ? (
                  action({ toggleModal })
                ) : (
                  <ExperimentActionEnd
                    actionType="button"
                    experiment={entity}
                    disabled={
                      entity.metadataRequiredWithValueMissingCount > 0
                    }
                    options={{ onSuccess: toggleModal }}
                  />
                )}
              </>
            </div>
          )}
        />
      )}
      viewComponent={({ toggleModal }): React.ReactElement => (
        <ExperimentActionEndViewComponent
          actionType={actionType}
          experiment={experiment}
          onClick={toggleModal}
        />
      )}
      {...rest}
    />
  );
};

export default ExperimentCompletionChecklistModal;
