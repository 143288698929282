/**
 * Labstep
 *
 * @module components/ResourceLocation/Action/Create/Modal
 * @desc Create action showing resource location form in modal
 */

import { CanCreate } from 'labstep-web/components/Entity/Can';
import ResourceLocationFormCreate from 'labstep-web/components/ResourceLocation/Form/Create';
import ActionComponent from 'labstep-web/core/Action/Component';
import ModalDefault from 'labstep-web/core/Modal/Default';
import { ResourceLocation } from 'labstep-web/models/resource-location.model';
import React from 'react';
import { IResourceLocationActionCreateProps } from './types';

export const ResourceLocationActionCreateModal: React.FC<
  IResourceLocationActionCreateProps
> = ({ actionComponentProps }) => {
  const text = `New ${ResourceLocation.getHumanReadableEntityName(
    false,
    true,
  )}`;

  return (
    <CanCreate entityName="resource_location">
      <ModalDefault
        header={text}
        viewComponent={({ toggleModal }) => (
          <ActionComponent
            type="option"
            text={text}
            onClick={toggleModal}
            icon="plus"
            {...actionComponentProps}
          />
        )}
        content={({ toggleModal }) => (
          <ResourceLocationFormCreate
            autoFocus
            options={{ onSuccess: toggleModal }}
          />
        )}
      />
    </CanCreate>
  );
};

export default ResourceLocationActionCreateModal;
