/**
 * Labstep
 *
 * @desc The element that is rendered when a protocol timer is added to the text editor
 */

import cx from 'classnames';
import Icon from 'labstep-web/core/Icon';
import Placeholder from 'labstep-web/core/Placeholder';
import React from 'react';
import styles from './styles.module.scss';
import { IInlineViewProps } from './types';

const InlineView: React.FC<IInlineViewProps> = ({
  children,
  onClick,
  icon,
  entity,
  className,
  inverted,
}) => {
  let iconRendered = null;
  let childrenRendered: React.ReactNode = (
    <Placeholder.Placeholder fluid>
      <Placeholder.Line />
    </Placeholder.Placeholder>
  );
  if (entity) {
    if (entity.deleted_at) {
      iconRendered = 'ban' as const;
      childrenRendered = 'deleted' as const;
    } else {
      childrenRendered = children;
      iconRendered = icon;
    }
  }
  return (
    <div
      className={cx(
        styles.inline,
        { [styles.loading]: !entity },
        { [styles.loaded]: !!entity },
        { [styles.inverted]: inverted },
        className,
      )}
      contentEditable={false}
      onDrop={(e) => e.preventDefault()}
      onClick={entity && !entity.deleted_at ? onClick : undefined}
    >
      {iconRendered && (
        <div className={styles.iconContainer}>
          <Icon size="small" name={iconRendered} />{' '}
        </div>
      )}
      <div>{childrenRendered}</div>
    </div>
  );
};

export default InlineView;
