/**
 * Labstep
 *
 * @module screens/Resource/Show/Right
 * @desc Resource Show Right
 */

import EntityQRCode from 'labstep-web/components/Entity/QRCode';
import ExperimentWorkflowLinkedList from 'labstep-web/components/ExperimentWorkflow/LinkedList';
import RightPane from 'labstep-web/components/Layout/RightPane';
import LogList from 'labstep-web/components/Log/List';
import MetadataSearch from 'labstep-web/components/Metadata/Search';
import ProtocolValueListLineage from 'labstep-web/components/ProtocolValue/List/Lineage';
import ThreadForm from 'labstep-web/components/Thread/Form';
import { ICONS } from 'labstep-web/constants/icons';
import { ResourceItem } from 'labstep-web/models/resource-item.model';
import React from 'react';
import { IScreensResourceItemShowRightProps } from './types';

const ScreensResourceItemShowRight: React.FC<
  IScreensResourceItemShowRightProps
> = ({ resourceItem }) => (
  <RightPane
    route={{
      to: 'resource_item_show',
      params: { id: resourceItem.id },
    }}
    subPages={[
      {
        name: 'QR Code',
        icon: 'qrcode',
        route: {
          to: 'resource_item_show_permalink',
          params: { id: resourceItem.id },
        },
        component: (
          <>
            <EntityQRCode entity={resourceItem} />
            <p>{`Download, print and attach QR codes to your ${ResourceItem.getHumanReadableEntityName(
              true,
            )}. Use a QR code scanner to quickly get or update ${ResourceItem.getHumanReadableEntityName()} information.`}</p>
            <p>
              For help printing QR Codes in bulk see{' '}
              <a href="https://help.labstep.com/en/articles/5046457-printing-qr-code-labels-in-bulk">
                here
              </a>
            </p>
          </>
        ),
      },
      {
        name: 'Lineage',
        icon: 'sitemap',
        route: {
          to: 'resource_item_show_lineage',
          params: { id: resourceItem.id },
        },
        component: (
          <ProtocolValueListLineage resourceItem={resourceItem} />
        ),
      },
      {
        name: 'Data',
        icon: ICONS.metadata.primary,
        route: {
          to: 'resource_item_show_metadata',
          params: { id: resourceItem.id },
        },
        component: <MetadataSearch entity={resourceItem} />,
      },
      {
        name: 'Linked Experiments',
        icon: ICONS.experiment_workflow.primary,
        route: {
          to: 'resource_item_show_experiment_workflows',
          params: { id: resourceItem.id },
        },
        component: (
          <ExperimentWorkflowLinkedList
            params={{ resource_item_id: resourceItem.id }}
          />
        ),
      },
      {
        name: 'Notes',
        icon: ICONS.thread.primary,
        count: resourceItem.thread!.comment_count,
        route: {
          to: 'resource_item_show_thread',
          params: { id: resourceItem.id },
        },
        component: (
          <ThreadForm
            createThreadId={resourceItem.thread!.id}
            readThreadIds={resourceItem.thread!.id}
            entityNameLabel="notes"
          />
        ),
      },
      {
        name: 'Activity',
        icon: 'history',
        route: {
          to: 'resource_item_show_activity',
          params: { id: resourceItem.id },
        },
        component: <LogList entity={resourceItem} />,
      },
    ]}
  />
);

export default ScreensResourceItemShowRight;
