/**
 * Labstep
 *
 * @module components/Folder/Form/Create
 * @desc Folder Form Create
 */

import { fieldName } from 'labstep-web/components/Folder/Form/fields';
import FormCreate from 'labstep-web/core/Form/Create';
import { Folder } from 'labstep-web/models/folder.model';
import React from 'react';
import { FolderFormCreateProps } from './types';

export const FolderFormCreate: React.FC<FolderFormCreateProps> = ({
  type,
  ...rest
}) => (
  <FormCreate
    autoFocus
    entityName={Folder.entityName}
    body={{ type }}
    fields={[fieldName]}
    {...rest}
  />
);

export default FolderFormCreate;
