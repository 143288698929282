/**
 * Labstep
 *
 * @module components/Metadata/Table/ExtraRows
 * @desc Extra Rows table for MetadataTable
 */

import HorizontalTable from 'labstep-web/core/Table/Horizontal';
import React from 'react';
import { MetadataTableExtraRowsProps } from './types';

const MetadataTableExtraRows: React.FC<
  MetadataTableExtraRowsProps
> = ({ rows, entity }) => (
  <HorizontalTable
    data-testid="metadata-table-extra"
    rows={rows}
    columns={[entity]}
    headerProps={{ width: 4 }}
    borderless
    fixed
  />
);

export default MetadataTableExtraRows;
