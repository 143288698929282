/**
 * Labstep
 */

import { ColDef } from 'labstep-web/core/DataGrid/types';
import colDefName from 'labstep-web/grid/Index/coldefs/Resource/name';
import { OrderRequest } from 'labstep-web/models/order-request.model';
import OrderRequestShowCenterSecondaryInfo from 'labstep-web/screens/OrderRequest/Show/Center/SecondaryInfo';

export const colDefOrderRequestName: ColDef<OrderRequest> = {
  ...colDefName<OrderRequest>(OrderRequest.entityName, {
    getEntityPreviewProps: (orderRequest) => ({
      prefix: 'Request for',
      nameEditable: false,
      secondaryInfo: (
        <OrderRequestShowCenterSecondaryInfo
          orderRequest={orderRequest as OrderRequest}
          showStatusLog={false}
        />
      ),
    }),
    getMetadataPreviewEntity: (orderRequest) => orderRequest.resource,
  }),
  headerName: 'Request for',
  editable: false,
};
