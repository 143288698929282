/**
 * Labstep
 *
 * @module components/Layout/Menu/TopBar/ModuleContext
 * @desc ModuleContext
 */

import { primaryLinks } from 'labstep-web/components/Layout/FullScreen/Links/constants';
import DropdownPortalHover from 'labstep-web/core/Dropdown/Portal/Hover';
import Icon from 'labstep-web/core/Icon';
import Link from 'labstep-web/core/Link';
import NavLink from 'labstep-web/core/NavLink';
import { useHasFeatureFlagEnabled } from 'labstep-web/hooks/feature-flag';
import React from 'react';
import styles from './styles.module.scss';
import { IModuleContextProps } from './types';

export const ModuleContext: React.FC<IModuleContextProps> = ({
  activeGroup,
  moduleContext,
}) => (
  <DropdownPortalHover
    trigger={
      <div id="module-context" className={styles.context}>
        <Link
          to={moduleContext.route.to}
          params={{ id: activeGroup?.id }}
        >
          <Icon name={moduleContext.icon} />
          {moduleContext.text}
        </Link>
      </div>
    }
    content={
      <div className={styles.dropdown}>
        {primaryLinks
          .filter(
            (link) =>
              !link.premiumFeature ||
              useHasFeatureFlagEnabled(link.premiumFeature),
          )
          .map((link) => (
            <div key={link.route.to} className={styles.dropdownItem}>
              <NavLink
                className={styles.link}
                to={link.route.to}
                params={{ id: activeGroup?.id }}
              >
                <Icon name={link.icon} />
                {link.text}
              </NavLink>
            </div>
          ))}
      </div>
    }
  />
);

export default ModuleContext;
