/**
 * Labstep
 *
 * @module components/User/Filter/User/Active/Label
 * @desc Active filter component for user
 */

import RemoveLabel from 'labstep-web/core/Label/Remove';
import React from 'react';
import { FilterUserActiveLabelProps } from './types';

const FilterUserActiveLabel: React.FC<FilterUserActiveLabelProps> = ({
  user,
  type,
  onRemoveClick,
}) => (
  <RemoveLabel
    icon={type === 'assignee' ? 'user plus' : 'user circle'}
    name={user.name}
    onRemoveClick={onRemoveClick}
  />
);

export default FilterUserActiveLabel;
