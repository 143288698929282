/**
 * Labstep
 *
 * @module core/ChemDraw
 * @desc ChemDraw component
 */

import { Ketcher } from 'labstep-web/core/ChemDraw/types';
import React from 'react';
import styles from './styles.module.scss';
import { IChemistryEditorKetcherProps } from './types';

export class ChemistryEditorKetcher extends React.Component<
  IChemistryEditorKetcherProps,
  { editorReady: boolean }
> {
  public ketcher!: Ketcher;

  public frame: any = {
    contentWindow: {
      ketcher: { getRxnAsync: () => null, getSmiles: () => null },
    },
    contentDocument: { querySelector: () => null },
  };

  public constructor(props: IChemistryEditorKetcherProps) {
    super(props);
    this.onLoad = this.onLoad.bind(this);

    this.state = {
      editorReady: false,
    };
  }

  public onLoad = async () => {
    // frame detaches
    if (!this.frame) {
      return;
    }
    const { contentWindow } = this.frame;
    this.ketcher = contentWindow.ketcher;
    this.setState({ editorReady: true });
    this.props.onLoad(this.ketcher, this.frame);
  };

  public render(): React.ReactElement {
    return (
      <iframe
        hidden={this.props.hidden}
        title="ketcherFrame"
        // eslint-disable-next-line no-return-assign
        ref={(f) => (this.frame = f)}
        onLoad={() => {
          setTimeout(this.onLoad, 100);
        }}
        src="/ketcher/index.html"
        width="100%"
        height={this.state.editorReady ? '550' : '0'}
        className={styles.ketcher}
      />
    );
  }
}

export default ChemistryEditorKetcher;
