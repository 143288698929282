/**
 * Labstep
 *
 * @module components/EntityUser/Action/Manage/List
 * @desc   Manage collaborators
 */

import EntityUserActionManage from 'labstep-web/components/EntityUser/Action/Manage';
import EntityUserList from 'labstep-web/components/EntityUser/List';
import React from 'react';
import { EntityUserActionManageListProps } from './types';

export const EntityUserActionManageList: React.FC<
  EntityUserActionManageListProps
> = ({ entity, hidePopup }) => (
  <EntityUserActionManage
    entity={entity}
    viewComponent={({ toggleModal }) => (
      <EntityUserList
        onPlusCountClick={toggleModal}
        entity={entity}
        hidePopup={hidePopup}
      />
    )}
  />
);

export default EntityUserActionManageList;
