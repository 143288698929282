/**
 * Labstep
 *
 * @module components/ResourceLocation/Label
 * @desc Resource Location Label
 */

import Link from 'labstep-web/core/Link';
import React from 'react';
import styles from './styles.module.scss';
import { IResourceLocationLabelLinkProps } from './types';

export const ResourceLocationLabelLink: React.FC<
  IResourceLocationLabelLinkProps
> = ({ resourceLocation }) => (
  <Link
    to="resource_location_show"
    params={{ id: resourceLocation.guid }}
    unstyled
    className={styles.active}
  >
    {resourceLocation.name}
  </Link>
);

export default ResourceLocationLabelLink;
