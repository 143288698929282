/**
 * Labstep
 *
 * @module prosemirror/components/Toolbar/Experiment
 * @desc Button to insert an Experiment node
 */

import ExperimentWorkflowActionAddProtocol from 'labstep-web/components/ExperimentWorkflow/Action/AddProtocol';
import Dropdown from 'labstep-web/core/Dropdown';
import NodeExperiment from 'labstep-web/prosemirror/nodes/experiment';
import React from 'react';
import { IExperimentToolbarProps } from './types';

const ExperimentToolbar: React.FC<IExperimentToolbarProps> = ({
  experimentWorkflow,
  view,
}) => (
  <ExperimentWorkflowActionAddProtocol
    experimentWorkflow={experimentWorkflow}
    viewComponent={({ toggleModal }) => (
      <Dropdown.Item
        onClick={toggleModal}
        icon="tasks"
        text="Protocol"
      />
    )}
    isModal
    onSuccess={({ response }) => {
      const { tr } = view.state;
      const node = view.state.schema.nodes[NodeExperiment.key].create(
        {
          id: response!.result,
        },
      );
      tr.setStoredMarks([]);
      tr.insert(view.state.selection.$to.pos, node);
      view.dispatch(tr);
      view.focus();
    }}
  />
);

export default ExperimentToolbar;
