/**
 * Labstep
 *
 * @module screens/ProtocolCollection/Show/Center
 * @desc Center Component of Protocol Show
 * Shows alert if the user cannot edit this version
 */

import EntityPrimaryInfo from 'labstep-web/components/Entity/PrimaryInfo';
import ProtocolActionCreate from 'labstep-web/components/Protocol/Action/Create';
import ProtocolActionDelete from 'labstep-web/components/Protocol/Action/Delete';
import ProtocolActionMenu from 'labstep-web/components/Protocol/Action/Menu';
import ProtocolActionRestore from 'labstep-web/components/Protocol/Action/Restore';
import ProtocolActionRun from 'labstep-web/components/Protocol/Action/Run';
import ProtocolActionUpdateIsDraft from 'labstep-web/components/Protocol/Action/UpdateIsDraft';
import ProtocolChangelog from 'labstep-web/components/Protocol/Changelog';
import ProtocolFormShowEditState from 'labstep-web/components/Protocol/Form/ShowEdit/State/Container';
import ProtocolLabelStatus from 'labstep-web/components/Protocol/Label/Status';
import { ICONS } from 'labstep-web/constants/icons';
import InformationCard from 'labstep-web/core/Card/Information';
import Flex from 'labstep-web/core/Flex';
import Link from 'labstep-web/core/Link';
import Message from 'labstep-web/core/Message';
import { navigation } from 'labstep-web/services/navigation';
import React from 'react';
import styles from './styles.module.scss';
import { ICenterProps } from './types';

const ProtocolShowCenter: React.FC<ICenterProps> = ({ protocol }) => {
  let banner;
  let actions;
  if (protocol.is_draft) {
    actions = (
      <>
        <ProtocolLabelStatus protocol={protocol} />
        <ProtocolActionUpdateIsDraft
          protocol={protocol}
          protocolCollection={protocol.collection}
        />
        <ProtocolActionDelete protocol={protocol} />
      </>
    );
    banner = (
      <Message
        children={
          <div className={styles.banner}>
            <span>
              You are viewing a draft version of this protocol
            </span>
            {protocol.collection.last_version && (
              <Link
                to="protocol_show"
                params={{ id: protocol.collection.last_version?.id }}
              >
                View Live Version
              </Link>
            )}
          </div>
        }
      />
    );
  } else if (protocol.is_last_version) {
    actions = (
      <>
        <ProtocolLabelStatus protocol={protocol} />
        {!protocol.collection.draft_version && (
          <ProtocolActionCreate
            parentName={protocol.collection.entityName}
            parentId={protocol.collection.id}
            actionComponentProps={{
              type: 'text',
              icon: 'edit',
              text: 'Draft New Version',
            }}
            options={{
              onSuccess: () => {
                window.location.href = navigation.get(
                  'protocol_collection_show',
                  {
                    id: protocol.collection.id,
                  },
                  true,
                );
              },
            }}
          />
        )}
      </>
    );
    banner = protocol.collection.draft_version && (
      <Message
        children={
          <div className={styles.banner}>
            <span>
              There is a pending draft version of this protocol.
            </span>
            <Link
              to="protocol_show"
              params={{ id: protocol.collection.draft_version.id }}
            >
              View Draft Version
            </Link>
          </div>
        }
      />
    );
  } else {
    actions = (
      <>
        <ProtocolLabelStatus protocol={protocol} />
        <ProtocolActionRestore
          protocol={protocol}
          actionComponentProps={{ type: 'text' }}
        />
      </>
    );
    banner = protocol.collection.last_version && (
      <Message
        children={
          <div className={styles.banner}>
            <span>
              The version has been superseded by a more recent
              version.
            </span>
            <Link
              to="protocol_show"
              params={{ id: protocol.collection.last_version?.id }}
            >
              View Live Version
            </Link>
          </div>
        }
      />
    );
  }
  return (
    <div className={styles.container}>
      <InformationCard
        actionMenu={
          <ProtocolActionMenu
            protocol={protocol}
            actions={['print', 'duplicate', 'restore']}
          />
        }
        primaryInfo={
          <EntityPrimaryInfo
            entity={protocol}
            icon={ICONS.protocol_collection.primary}
          />
        }
        secondaryInfo={
          <Flex wrap style={{ alignItems: 'center' }} spacing="gap">
            <ProtocolChangelog
              protocol={protocol}
              protocolCollection={protocol.collection}
            />
            {actions}
          </Flex>
        }
        bottom={
          <>
            {banner}
            <Flex hAlignContent="right">
              <ProtocolActionRun protocol={protocol} />
            </Flex>
            <ProtocolFormShowEditState
              key={protocol.id}
              protocolCollection={protocol.collection}
              protocol={protocol}
            />
          </>
        }
      />
    </div>
  );
};

export default ProtocolShowCenter;
