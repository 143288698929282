/**
 * Labstep
 *
 * @module components/Metadata/Form/ShowEdit/Label
 * @desc Label update
 */

import {
  FieldType,
  FieldWithoutActionProps,
} from 'labstep-web/core/Form/Reusable/types';
import ShowEdit from 'labstep-web/core/Form/ShowEdit/Text';
import { Metadata } from 'labstep-web/models/metadata';
import rules from 'labstep-web/services/validation/rules';
import React from 'react';
import { MetadataFormShowEditLabelProps } from './types';

/* Field for metadata label */
export const FIELD_METADATA_LABEL: FieldWithoutActionProps = {
  fieldType: FieldType.Text,
  name: 'label',
  placeholder: 'Add field name',
  fieldLabel: 'Field Name',
  validation: rules.metadata.label,
};

const MetadataFormShowEditLabel: React.FC<
  MetadataFormShowEditLabelProps
> = ({ metadata }) => (
  <ShowEdit
    entityName={Metadata.entityName}
    entity={metadata}
    field={FIELD_METADATA_LABEL}
  >
    {metadata.label}
  </ShowEdit>
);

export default MetadataFormShowEditLabel;
