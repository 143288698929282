/**
 * Labstep
 */

import { ActionMenuProps } from 'labstep-web/core/Action/Menu/types';
import { APICallOptions } from 'labstep-web/models';
import { Folder, FolderType } from 'labstep-web/models/folder.model';

// eslint-disable-next-line no-shadow
export enum Action {
  edit = 'edit',
  delete = 'delete',
  add_subfolders = 'add_subfolders',
  update_outer = 'update_outer',
}

export interface FolderActionMenuProps
  extends Pick<
    ActionMenuProps,
    Exclude<keyof ActionMenuProps, 'children' | 'options'>
  > {
  folder: Folder;
  actions: Action[];
  options?: APICallOptions;
  folderType: FolderType;
}
