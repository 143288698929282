/**
 * Labstep
 */

import { IDataGridProps } from 'labstep-web/core/DataGrid/types';
import colDefMetadataRowNumber from 'labstep-web/grid/Index/coldefs/Metadata/rowNumber';
import colDefValue from 'labstep-web/grid/Index/coldefs/Metadata/value';
import { getNestedEntity } from 'labstep-web/grid/SmartTable/coldefs/utils';
import { Experiment } from 'labstep-web/models/experiment.model';
import { Metadata } from 'labstep-web/models/metadata';
import { Protocol } from 'labstep-web/models/protocol.model';

export const getColumnDefs = (
  protocol: Experiment | Protocol,
  variableTemplate: Metadata,
): NonNullable<IDataGridProps['columnDefs']> => [
  colDefMetadataRowNumber(protocol),
  colDefValue({
    columnProps: {
      headerName: variableTemplate.displayName,
      resizable: false,
      flex: 1,
    },
    getNestedEntity: (params) =>
      getNestedEntity(params, 'metadatas', variableTemplate),
  }),
];
