/**
 * Labstep
 *
 * @module components/Notification/List
 * @desc List of notification
 */

import NotificationCardEmptyState from 'labstep-web/components/Notification/Card/EmptyState';
import Notification from 'labstep-web/components/Notification/Card/Preview';
import List from 'labstep-web/core/List';
import { ReadOnMountHOC } from 'labstep-web/hoc/ReadOnMount';
import React from 'react';
import { NotificationListProps } from './types';

export const NotificationList: React.FC<NotificationListProps> = ({
  notifications,
}) => (
  <List id="notification-list">
    {notifications.length > 0 ? (
      notifications.map((notification) => (
        <Notification
          key={notification.id}
          notification={notification}
        />
      ))
    ) : (
      <NotificationCardEmptyState />
    )}
  </List>
);

export const NotificationListContainer: React.FC = () => (
  <ReadOnMountHOC
    type="cursor"
    entityName="notification"
    params={{}}
    children={(containerProps) => (
      <NotificationList
        {...containerProps}
        notifications={containerProps.entities}
      />
    )}
  />
);

export default NotificationListContainer;
