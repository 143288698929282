/**
 * Labstep
 *
 * @module components/GroupAutoSharing/Form/Create
 * @desc Form to create a GroupAutoSharing
 */

import { CanCreate } from 'labstep-web/components/Entity/Can';
import { EntityCreateContainer } from 'labstep-web/containers/Entity/Create';
import ReusableForm from 'labstep-web/core/Form/Reusable';
import { GroupAutoSharing } from 'labstep-web/models/group-auto-sharing.model';
import React from 'react';
import {
  entityTypeOptionExperimentWorkflow,
  fieldEntityType,
  fieldPermissionType,
  fieldPropagate,
  getFieldTargetGroup,
  permissionTypeOptionEdit,
} from '../fields';
import {
  GroupAutoSharingFormCreateContainerProps,
  GroupAutoSharingFormCreateProps,
} from './types';

export const GroupAutoSharingFormCreate: React.FC<
  GroupAutoSharingFormCreateProps
> = ({ body, create, status, options, sourceGroup }) => (
  <ReusableForm
    fields={[
      fieldEntityType,
      getFieldTargetGroup(sourceGroup),
      fieldPermissionType,
      fieldPropagate,
    ]}
    status={status}
    submitButtonText="Create"
    defaultValues={{
      entity_type: entityTypeOptionExperimentWorkflow,
      permission_type: permissionTypeOptionEdit,
      propagate: true,
    }}
    onSubmit={({
      entity_type,
      permission_type,
      target_group_id,
      propagate,
    }: any): void =>
      create(
        {
          ...body,
          target_group_guid: target_group_id,
          entity_type: entity_type.value,
          permission_type: permission_type.value,
          propagate: propagate ? 1 : undefined,
        },
        options,
      )
    }
    autoFocus
  />
);

export const GroupAutoSharingFormCreateContainer: React.FC<
  GroupAutoSharingFormCreateContainerProps
> = (props) => (
  <CanCreate entityName="group_auto_sharing">
    <EntityCreateContainer entityName={GroupAutoSharing.entityName}>
      {(containerProps) => (
        <GroupAutoSharingFormCreate {...containerProps} {...props} />
      )}
    </EntityCreateContainer>
  </CanCreate>
);

export default GroupAutoSharingFormCreateContainer;
