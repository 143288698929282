/**
 * Labstep
 */

import { Route } from 'labstep-web/components/Layout/Links/types';
import { Notification as NotificationModel } from 'labstep-web/models/notification.model';
import { NotificationIcon } from './types';

/**
 * Returns show page route depending on the key of the metadata
 */
export const getShowPageRoute = (metadata: {
  [key: string]: unknown;
}): Route => {
  const key = Object.keys(metadata)[0];
  const id = metadata[key];
  const entityName = key.slice(0, -3) as
    | 'experiment_workflow'
    | 'group'
    | 'order_request'
    | 'protocol_collection'
    | 'purchase_order'
    | 'resource'
    | 'resource_item'
    | 'resource_location';
  return {
    to: `${entityName}_show`,
    params: { id },
  };
};

const getUserMentionCreatedRoute = (metadata: {
  [key: string]: unknown;
}): Route => {
  if (metadata.experiment_workflow_id) {
    return {
      to: 'experiment_workflow_show_thread',
      params: { id: metadata.experiment_workflow_id },
    };
  }
  if (metadata.group_id) {
    return {
      to: 'group_show',
      params: { id: metadata.group_id },
    };
  }
  if (metadata.order_request_id) {
    return {
      to: 'order_request_show_thread',
      params: { id: metadata.order_request_id },
    };
  }
  if (metadata.protocol_collection_id) {
    return {
      to: 'protocol_collection_show_thread',
      params: { id: metadata.protocol_collection_id },
    };
  }
  if (metadata.purchase_order_id) {
    return {
      to: 'purchase_order_show_thread',
      params: { id: metadata.purchase_order_id },
    };
  }
  if (metadata.resource_id) {
    return {
      to: 'resource_show_thread',
      params: { id: metadata.resource_id },
    };
  }
  if (metadata.resource_item_id) {
    return {
      to: 'resource_item_show_thread',
      params: { id: metadata.resource_item_id },
    };
  }
  if (metadata.resource_location_id) {
    return {
      to: 'resource_location_show_thread',
      params: { id: metadata.resource_location_id },
    };
  }
  if (metadata.device_id) {
    return {
      to: 'device_show_thread',
      params: { id: metadata.device_id },
    };
  }

  return { to: 'notification_index' };
};

export const getRoute = (notification: NotificationModel): Route => {
  const { metadata } = notification;

  if (!metadata) {
    return { to: 'notification_index' };
  }

  switch (notification.type) {
    case 'comment_created_purchase_order': {
      return {
        to: 'purchase_order_show_thread',
        params: { id: metadata.purchase_order_id },
      };
    }
    case 'comment_created_experiment_workflow': {
      if (metadata.experiment_id) {
        return {
          to: 'experiment_workflow_show_thread_show',
          params: {
            id: metadata.experiment_workflow_id,
            childId:
              metadata?.parent_thread_comment_id ||
              metadata.comment_id,
          },
        };
      }
      return {
        to: 'experiment_workflow_show_thread',
        params: { id: metadata.experiment_workflow_id },
      };
    }
    case 'comment_created_group':
      return {
        to: 'group_thread',
        params: { id: metadata.group_id },
      };
    case 'comment_created_order_request':
      return {
        to: 'order_request_show_thread',
        params: { id: metadata.order_request_id },
      };
    case 'comment_created_protocol_collection':
      return {
        to: 'protocol_collection_show_thread',
        params: { id: metadata.protocol_collection_id },
      };
    case 'comment_created_resource':
      return {
        to: 'resource_show_thread',
        params: { id: metadata.resource_id },
      };
    case 'comment_created_resource_item':
      return {
        to: 'resource_item_show_thread',
        params: { id: metadata.resource_item_id },
      };
    case 'comment_created_resource_location':
      return {
        to: 'resource_location_show_thread',
        params: { id: metadata.resource_location_id },
      };
    case 'device_booking_created': {
      return {
        to: 'device_show',
        params: { id: metadata.device_id },
      };
    }
    case 'device_booking_updated': {
      return {
        to: 'device_show',
        params: { id: metadata.device_id },
      };
    }
    case 'experiment_workflow_entity_state_updated':
    case 'experiment_workflow_assigned':
    case 'experiment_workflow_created':
      return {
        to: 'experiment_workflow_show',
        params: { id: metadata.experiment_workflow_id },
      };
    case 'order_request_created':
    case 'order_request_updated':
      return {
        to: 'order_request_show',
        params: { id: metadata.order_request_id },
      };
    case 'purchase_order_updated_status':
    case 'purchase_order_updated_order_request':
    case 'purchase_order_created':
      return {
        to: 'purchase_order_show',
        params: { id: metadata.purchase_order_id },
      };
    case 'protocol_created':
    case 'protocol_updated_is_draft':
    case 'protocol_collection_created':
      return {
        to: 'protocol_collection_show',
        params: { id: metadata.protocol_collection_id },
      };
    case 'resource_created':
      return {
        to: 'resource_show',
        params: { id: metadata.resource_id },
      };
    case 'signature_created':
      return {
        to: 'experiment_workflow_show',
        params: { id: metadata.experiment_workflow_id },
      };
    case 'signature_revoked':
      return {
        to: 'experiment_workflow_show',
        params: { id: metadata.experiment_workflow_id },
      };
    case 'signature_request_created':
      return {
        to: 'experiment_workflow_show',
        params: { id: metadata.experiment_workflow_id },
      };
    // Legacy
    case 'share_link':
      return {
        to: 'sharelink_show',
        params: { token: metadata.share_link_token },
      };
    case 'share_link_invitation_created':
      return {
        to: 'sharelink_show',
        params: { token: metadata.share_link_token },
      };
    case 'user_group_security_policy_set':
      return {
        to: 'group_settings',
        params: { id: metadata.group_id },
      };
    case 'user_group_security_policy_unset':
      return {
        to: 'group_settings',
        params: { id: metadata.group_id },
      };
    case 'user_mention_created':
      return getUserMentionCreatedRoute(metadata);
    case 'notification_alert_triggered':
      return getShowPageRoute(metadata);
    case 'resource_available_resource_item_count_alert':
      return {
        to: 'resource_show',
        params: { id: metadata.resource_id },
      };
    default:
      return { to: 'notification_index' };
  }
};

export const getIcon = (
  notification: NotificationModel,
): NotificationIcon => {
  switch (notification.type) {
    case 'comment_created_group':
    case 'comment_created_experiment_workflow':
    case 'comment_created_protocol_collection':
    case 'comment_created_resource':
    case 'comment_created_resource_item':
    case 'comment_created_resource_location':
    case 'comment_created_order_request':
    case 'comment_created_purchase_order':
      return { name: 'comment', color: 'black' };
    case 'signature_request_created':
      return { name: 'edit outline', color: 'black' };
    case 'user_mention_created':
      return { name: 'at', color: 'black' };
    case 'notification_alert_triggered':
      return { name: 'bell', color: 'red' };
    case 'resource_assigned':
    case 'experiment_workflow_assigned':
    case 'resource_item_assigned':
    case 'resource_location_assigned':
    case 'protocol_collection_assigned':
    case 'purchase_order_assigned':
    case 'order_request_assigned':
    case 'device_assigned':
      return { name: 'user plus', color: 'black' };
    default:
      return { name: 'info circle', color: 'blue' };
  }
};
