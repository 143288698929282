/**
 * Labstep
 *
 * @module grid/Report/components/ToolPanel/Protocol
 * @desc Hardcoded list of columns for experiment fields
 */

import Flex from 'labstep-web/core/Flex';
import { SectionHeader } from 'labstep-web/core/Header';
import {
  experimentEndedAtColDef,
  experimentStartedAtColDef,
  protocolNameColDef,
} from 'labstep-web/grid/Report/coldefs';
import EntityDataGridToolPanelListItem from 'labstep-web/grid/Report/components/ToolPanel/ListItem';
import { GridReportService } from 'labstep-web/grid/Report/services/grid-report.service';
import React from 'react';
import { IEntityDataGridToolPanelProtocolProps } from './types';

export const EntityDataGridToolPanelProtocol: React.FC<
  IEntityDataGridToolPanelProtocolProps
> = ({ gridReportService, searchQuery, colIds }) => (
  <Flex column>
    {GridReportService.showListSection(
      ['protocol name', 'started date', 'completion date'],
      searchQuery,
    ) && <SectionHeader label="Protocol Fields" />}
    {GridReportService.showListItem('protocol name', searchQuery) && (
      <EntityDataGridToolPanelListItem
        icon="tasks"
        actionIcon="eye"
        text="Protocol Name"
        onClick={() => {
          gridReportService.addColumnDef(protocolNameColDef);
        }}
        disabled={
          protocolNameColDef.colId
            ? colIds.includes(protocolNameColDef.colId)
            : false
        }
      />
    )}
    {GridReportService.showListItem('started date', searchQuery) && (
      <EntityDataGridToolPanelListItem
        icon="calendar outline"
        actionIcon="eye"
        text="Started Date"
        onClick={() => {
          gridReportService.addColumnDef(experimentStartedAtColDef);
        }}
        disabled={
          experimentStartedAtColDef.colId
            ? colIds.includes(experimentStartedAtColDef.colId)
            : false
        }
      />
    )}
    {GridReportService.showListItem(
      'completion date',
      searchQuery,
    ) && (
      <EntityDataGridToolPanelListItem
        icon="calendar check outline"
        actionIcon="eye"
        text="Completion Date"
        onClick={() => {
          gridReportService.addColumnDef(experimentEndedAtColDef);
        }}
        disabled={
          experimentEndedAtColDef.colId
            ? colIds.includes(experimentEndedAtColDef.colId)
            : false
        }
      />
    )}
  </Flex>
);

export default EntityDataGridToolPanelProtocol;
