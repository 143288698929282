/**
 * Labstep
 *
 * @module components/ResourceLocation/Action/UpdateOuter
 * @desc Action to update outer resource location of a resource location
 */

import EntityActionEdit from 'labstep-web/components/Entity/Action/Edit';
import ResourceLocationFormShowEditOuter from 'labstep-web/components/ResourceLocation/Form/ShowEdit/Outer';
import ModalDefault from 'labstep-web/core/Modal/Default';
import React from 'react';
import { IResourceLocationActionUpdateOuterProps } from './types';

const ResourceLocationActionUpdateOuter: React.FC<
  IResourceLocationActionUpdateOuterProps
> = ({ resourceLocation, actionComponentProps }) => (
  <ModalDefault
    header="Select Outer Location"
    showDone
    content={() => (
      <ResourceLocationFormShowEditOuter
        resourceLocation={resourceLocation}
      />
    )}
    viewComponent={({ toggleModal }) => (
      <EntityActionEdit
        entity={resourceLocation}
        actionComponentProps={{
          ...actionComponentProps,
          icon: 'move',
          text: 'Move',
          onClick: toggleModal,
        }}
      />
    )}
  />
);

export default ResourceLocationActionUpdateOuter;
