/**
 * Labstep
 */

import colDefAmount from 'labstep-web/grid/Index/coldefs/Resource/amount';
import { getHeaderParams } from 'labstep-web/grid/Index/coldefs/Resource/amount/utils';
import { ProtocolValue } from 'labstep-web/models/protocol-value.model';
import { getNestedEntity } from '../../utils';
import { ColDefProtocolValueAmountType } from './types';

/**
 * Get colDefs based on template
 * @param variable ProtocolCondition variable
 * @returns ColGroupDef
 */
const colDefProtocolValueAmount: ColDefProtocolValueAmountType<
  ProtocolValue
> = (variable) =>
  colDefAmount({
    getNestedEntity: (params) =>
      getNestedEntity(params, 'protocol_values', variable) ||
      undefined,
    columnProps: getHeaderParams(variable.is_input),
  });

export default colDefProtocolValueAmount;
