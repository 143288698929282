/**
 * Labstep
 *
 * @module grid/Index/components/SaveView
 * @desc Entity DataGrid Action Save View
 */

import { useEntityViewContext } from 'labstep-web/components/EntityView/Context';
import UpdateOrCreate from 'labstep-web/containers/UpdateOrCreate';
import { useDataGridContext } from 'labstep-web/contexts/grid/hook';
import ActionComponent from 'labstep-web/core/Action/Component';
import { ActionComponentProps } from 'labstep-web/core/Action/Component/types';
import { useActiveGroup } from 'labstep-web/hooks/activeGroup';
import { EntityView } from 'labstep-web/models/entity-view.model';
import React, { useEffect, useRef, useState } from 'react';

export const GridIndexActionSaveView: React.FC = () => {
  const entityViewContext = useEntityViewContext();
  const { agGrid } = useDataGridContext();
  const [stateChange, setStateChange] = useState(false);
  const { activeGroup } = useActiveGroup();

  useEffect(() => {
    let i = 0;
    agGrid?.api.addGlobalListener((type: string) => {
      if (
        type.indexOf('column') >= 0 &&
        type !== 'columnHoverChanged'
      ) {
        // hack to skip initial column state change
        if (entityViewContext?.activeEntityView) {
          // columnEverythingChanged, columnMoved, columnResized
          if (i > 2) {
            setStateChange(true);
          }
        } else {
          setStateChange(true);
        }
        i += 1;
      }
    });
  }, [agGrid]);

  const didMountRef = useRef(false);
  useEffect(() => {
    if (didMountRef.current) {
      setStateChange(true);
    }
    didMountRef.current = true;
  }, [entityViewContext?.columnOptions]);

  if (
    !agGrid?.columnApi?.getColumnState() ||
    !entityViewContext ||
    !activeGroup
  ) {
    return null;
  }

  const {
    activeEntityView,
    entityName,
    parameters,
    context,
    allowSave,
  } = entityViewContext;

  if (!allowSave || !stateChange) {
    return null;
  }

  const actionComponentProps: ActionComponentProps = {
    dataTestId: 'entity-view-save',
    type: 'button',
    showIcon: true,
    icon: 'save',
    text: 'Save View',
    elementProps: {
      basic: true,
    },
    modalConfirmationProps: {
      header: 'Warning',
      positive: false,
      message: (
        <>
          <p>
            If you save your changes to the view, the order, width and
            visibility of columns will become the default for everyone
            in the workspace.
          </p>
          <p>Are you sure you want to proceed?</p>
        </>
      ),
    },
  };

  return (
    <UpdateOrCreate
      entityName={EntityView.entityName}
      updateProps={{ id: activeEntityView?.guid as string }}
      createProps={
        activeEntityView
          ? undefined
          : {
              createBody: {
                parameters,
                entity_name: entityName,
                context,
                group_id: activeGroup.id,
              },
            }
      }
    >
      {({ action, status }) => (
        <ActionComponent
          {...actionComponentProps}
          status={status}
          onClick={() =>
            action(
              {
                state: agGrid.columnApi?.getColumnState(),
                column_options: entityViewContext.columnOptions,
              },
              {
                onSuccess: () => setStateChange(false),
              },
            )
          }
        />
      )}
    </UpdateOrCreate>
  );
};

export default GridIndexActionSaveView;
