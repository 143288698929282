/**
 * Labstep
 *
 * @module components/CustomIdentifierSet/Form/fields
 * @desc Form Fields for CustomIdentifierSet
 */

import {
  FieldType,
  FieldWithoutActionProps,
  IFieldProps,
} from 'labstep-web/core/Form/Reusable/types';
import { CustomIdentifierSet } from 'labstep-web/models/custom-identifier-set.model';
import rules from 'labstep-web/services/validation/rules';

export const fieldPrefix: FieldWithoutActionProps = {
  fieldType: FieldType.Text,
  name: 'prefix',
  fieldLabel: 'Prefix',
  placeholder: 'Specify Identifier prefix',
  validation: rules.custom_identifier_set.prefix,
};

export const fieldZeroPadding: FieldWithoutActionProps = {
  fieldType: FieldType.Text,
  name: 'zero_padding',
  fieldLabel: 'Minimum Number of Digits',
  placeholder: 'Specify minimum number of digits',
  validation: rules.custom_identifier_set.zero_padding,
};

export const fieldPreview: IFieldProps = {
  fieldType: FieldType.action,
  component: ({
    values,
  }: {
    values: { prefix?: string; zero_padding?: number };
  }) => (
    <div>
      {CustomIdentifierSet.getPreview(
        values.prefix,
        values.zero_padding,
      )}
    </div>
  ),
};
