/**
 * Labstep
 *
 * @module grid/EntityImport
 * @desc Grid EntityImport
 * @url  /workspace/:workspaceId/resources > Import
 */

import { GetContextMenuItemsParams } from 'ag-grid-community';
import { ICONS } from 'labstep-web/constants/icons';
import { useAgGridEntityImportServiceFromContext } from 'labstep-web/contexts/grid/hook';
import DataGrid from 'labstep-web/core/DataGrid';
import Icon from 'labstep-web/core/Icon';
import GridEntityImportAddRow from 'labstep-web/grid/EntityImport/components/AddRow';
import { GridEntityImportValidationService } from 'labstep-web/grid/EntityImport/services/grid-entity-import-validation.service';
import { GridEntityImportService } from 'labstep-web/grid/EntityImport/services/grid-entity-import.service';
import React, { useCallback, useEffect, useMemo } from 'react';
import ReactDOMServer from 'react-dom/server';
import { GridEntityImportProps } from './types';

const isGridEmpty = (
  gridEntityImportService?: GridEntityImportService,
) => {
  if (gridEntityImportService) {
    return gridEntityImportService.isGridEmpty();
  }

  return true;
};

export const GridEntityImport: React.FC<GridEntityImportProps> = ({
  targetEntityName,
  template,
  columnDefs,
  rowData,
  showOnlyInvalidRows,
  setErrorMessages,
  setIsValid,
}) => {
  const gridEntityImportService =
    useAgGridEntityImportServiceFromContext();

  useEffect(() => {
    if (gridEntityImportService) {
      gridEntityImportService.onFilterChanged();
      const { isValid, errorMessage } =
        gridEntityImportService.getCustomIdentifierValidation(
          columnDefs,
          template,
        );
      setIsValid(isValid && !isGridEmpty(gridEntityImportService));
      if (!isValid && errorMessage) {
        setErrorMessages([errorMessage]);
      } else {
        setErrorMessages([]);
      }
    }
  }, [gridEntityImportService, columnDefs, showOnlyInvalidRows]);

  const statusBar = useMemo(
    () => ({
      statusPanels: [
        {
          statusPanel: () => <GridEntityImportAddRow />,
          align: 'left',
        },
      ],
    }),
    [],
  );

  return (
    <DataGrid
      height="100%"
      defaultColDef={GridEntityImportService.defaultColDef}
      columnDefs={columnDefs}
      rowData={rowData}
      context={{ template, targetEntityName }}
      suppressRowClickSelection
      isExternalFilterPresent={useCallback(
        (): boolean => showOnlyInvalidRows,
        [showOnlyInvalidRows],
      )}
      statusBar={statusBar}
      doesExternalFilterPass={useCallback(
        (node) => {
          const data = gridEntityImportService?.getData();
          return GridEntityImportValidationService.isNodeInvalid(
            node,
            data,
            gridEntityImportService?.getColumnDefs(),
            template,
          );
        },
        [
          showOnlyInvalidRows,
          gridEntityImportService?.getColumnDefs(),
        ],
      )}
      getContextMenuItems={(event: GetContextMenuItemsParams) => [
        {
          name: 'Delete Row',
          icon: ReactDOMServer.renderToStaticMarkup(
            <Icon name={ICONS.ag_grid.delete_row} />,
          ),
          action: () => {
            if (event.api && event.node?.data) {
              event.api.applyTransaction({
                remove: [event.node.data],
              });
            }
          },
        },
        'separator',
        'copy',
        'separator',
        'export',
      ]}
      onCellValueChanged={(e) => {
        if (gridEntityImportService) {
          e.api?.refreshHeader();
          const { isValid, errorMessage } =
            gridEntityImportService.getCustomIdentifierValidation(
              columnDefs,
              template,
            );
          setIsValid(
            isValid && !isGridEmpty(gridEntityImportService),
          );
          if (!isValid && errorMessage) {
            setErrorMessages([errorMessage]);
          } else {
            setErrorMessages([]);
          }
        }
      }}
      processDataFromClipboard={(params) => {
        return GridEntityImportService.addNewRowsOnPaste(params);
      }}
    />
  );
};

export default GridEntityImport;
