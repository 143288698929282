/**
 * Labstep
 *
 * @module components/Metadata/Required
 * @desc A text showing if metadata is required.
 */

import Icon from 'labstep-web/core/Icon';
import React from 'react';
import { MetadataRequiredProps } from './types';

const MetadataRequired: React.FC<MetadataRequiredProps> = ({
  metadata,
}) => {
  let shouldShow = false;
  if (metadata.is_template && metadata.is_required) {
    shouldShow = true;
  } else if (!metadata.is_template && !metadata.hasValue) {
    if (metadata.template) {
      shouldShow = !!metadata.template.is_required;
    } else {
      shouldShow = !!metadata.is_required;
    }
  }

  if (!shouldShow) {
    return null;
  }
  return (
    <Icon
      name="warning sign"
      color="red"
      popup={{ content: 'Required' }}
    />
  );
};

export default MetadataRequired;
