/**
 * Labstep
 *
 * @module core/Loadable
 * @desc Wrapper around react loadable
 */

import LoaderTimeEnabled from 'labstep-web/core/Loader/TimeEnabled';
import React from 'react';
import { ILoadableProps } from './types';

const Loadable: React.FC<ILoadableProps> = ({ loader, children }) => (
  <React.Suspense
    fallback={loader || <LoaderTimeEnabled children={() => null} />}
  >
    {children}
  </React.Suspense>
);

export default Loadable;
