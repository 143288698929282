/**
 * Labstep
 *
 * @module models/tag
 * @desc Typescript export class for Tag
 */

import { Type } from 'class-transformer';
import { Entity } from 'labstep-web/models/entity.model';
import { User } from 'labstep-web/models/user.model';
import { getHumanReadableEntityName } from 'labstep-web/services/i18n.service';

// eslint-disable-next-line no-shadow
export enum TagType {
  device = 'device',
  entity_view = 'entity_view',
  experiment_workflow = 'experiment_workflow',
  order_request = 'order_request',
  protocol_collection = 'protocol_collection',
  resource = 'resource',
}

export class Tag extends Entity {
  static readonly entityName = 'tag';

  get entityName(): typeof Tag.entityName {
    return Tag.entityName;
  }

  constructor(data: Partial<Tag> = {}) {
    super();
    Object.assign(this, data);
  }

  id!: number;

  name!: string;

  device_count!: number;

  entity_view_count!: number;

  experiment_workflow_count!: number;

  protocol_collection_count!: number;

  resource_count!: number;

  order_request_count!: number;

  type!: TagType;

  @Type(() => User)
  author!: User;

  get associatedEntitiesCount(): number {
    return this[`${this.type}_count`];
  }

  get isAssociated(): boolean {
    return this.associatedEntitiesCount > 0;
  }

  static getHumanReadableEntityName(
    plural?: boolean,
    capitalized?: boolean,
  ): string {
    return getHumanReadableEntityName(
      this.entityName,
      plural,
      capitalized,
    );
  }
}
