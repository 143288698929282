/**
 * Labstep
 *
 * @module components/Device/Action/Share
 * @desc Share a device with all users on the platform
 */

import EntityActionShare from 'labstep-web/components/Entity/Action/Share';
import React from 'react';
import { DeviceActionShareProps } from './types';

export const DeviceActionShare: React.FC<DeviceActionShareProps> = ({
  device,
  ...rest
}) => <EntityActionShare {...rest} entity={device} />;

export default DeviceActionShare;
