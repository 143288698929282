/**
 * Labstep
 *
 * @module state/reducers/entity
 * @desc Reducers for any entity
 */

import { EntityNamesById } from 'labstep-web/state/types';
import { combineReducers } from 'redux';
import defaultById from './byId';
import creatingIds from './creatingIds';
import deletingIds from './deletingIds';
import readingIds from './readingIds';
import togglingIds from './togglingIds';
import updatingIds from './updatingIds';

/**
 * Factory design pattern to return reducers for any entity
 *
 * 3 main parts:
 * - Denormalized entities: byId
 * - CRUD: creatingIds, readingIds, updatingIds, deletingIds
 * - Add/Remove parent/child: togglingIds
 *
 * @function
 * @param  {string} entityName - Entity name
 * @param  {object} reducers - Additional sub-reducers to customise final result
 */
export const getEntityReducers = (
  entityName: string,
  reducers?: any,
): any => {
  const entityNameUppercase = entityName.toUpperCase();

  /**
   * Returns reducer for entities denormalized by id
   *
   * @function
   * @param  {object} state - Redux state
   * @param  {string} action - Redux action
   */
  const byId = (state: any = {}, action: any): any =>
    defaultById(entityName as EntityNamesById, entityNameUppercase)(
      state,
      action,
    );

  return combineReducers({
    byId,
    creatingIds: creatingIds(entityName, entityNameUppercase),
    readingIds: readingIds(entityName, entityNameUppercase),
    updatingIds: updatingIds(entityName, entityNameUppercase),
    deletingIds: deletingIds(entityName, entityNameUppercase),
    togglingIds: togglingIds(entityName),
    ...reducers,
  });
};
