/**
 * Labstep
 *
 * @module components/ProtocolCondition/Action/SetIsVariable
 * @desc Set isVariable action
 */

import EntityActionUpdate from 'labstep-web/components/Entity/Action/Update';
import { useProtocolConditionModalContext } from 'labstep-web/components/ProtocolCondition/Modal/context';
import { ICONS } from 'labstep-web/constants/icons';
import { ActionComponentProps } from 'labstep-web/core/Action/Component/types';
import React from 'react';
import { IProtocolConditionActionSetIsVariableProps } from './types';

export const defaultActionComponentProps = {
  type: 'icon',
  icon: ICONS.protocol_condition.info.variable,
  elementProps: { popup: { content: 'Convert to Variable' } },
  dataTestId: 'action-set-is-variable',
} as ActionComponentProps;

export const ProtocolConditionActionSetIsVariable: React.FC<
  IProtocolConditionActionSetIsVariableProps
> = ({ entity, protocol, actionComponentProps }) => {
  const { setIsOpen, isOpen } = useProtocolConditionModalContext();
  return (
    <EntityActionUpdate
      entityName={entity.entityName}
      id={entity.idAttr}
      body={{ is_variable: true }}
      actionComponentProps={
        actionComponentProps || defaultActionComponentProps
      }
      options={{
        onSuccess: (): void => {
          if (!isOpen) {
            setIsOpen(protocol);
          }
        },
      }}
    />
  );
};

export default ProtocolConditionActionSetIsVariable;
