/**
 * Labstep
 *
 * @module components/ProtocolCondition/EmptyState
 * @desc Empty state for protocol condition
 */

import ProtocolConditionActionCreateBulk from 'labstep-web/components/ProtocolCondition/Action/Create/Bulk';
import EmptyState from 'labstep-web/core/Card/EmptyState';
import Image from 'labstep-web/core/Image';
import { ProtocolCondition } from 'labstep-web/models/protocol-condition.model';
import React from 'react';
import { IProtocolConditionEmptyStateProps } from './types';

export const ProtocolConditionEmptyState: React.FC<
  IProtocolConditionEmptyStateProps
> = ({ protocol }) => (
  <EmptyState
    src="/img/empty-state/data-steppy.svg"
    title={`No ${ProtocolCondition.getHumanReadableEntityName(
      true,
      true,
    )}`}
    explanation={
      <>
        <p>
          Protocols can be run with multiple{' '}
          <b>
            {ProtocolCondition.getHumanReadableEntityName(
              true,
              false,
            )}
          </b>
          . Each{' '}
          <b>
            {ProtocolCondition.getHumanReadableEntityName(
              false,
              false,
            )}
          </b>{' '}
          can have a different combination of values for{' '}
          <b>variable </b> inventory and / or data fields.
        </p>
        <i>
          Example: 4{' '}
          <b>
            {ProtocolCondition.getHumanReadableEntityName(
              true,
              false,
            )}
          </b>{' '}
          with sample, temperature and yield as <b>variable</b> fields{' '}
        </i>
        <Image src="/img/empty-state/conditions-example.svg" />
      </>
    }
    action={
      <ProtocolConditionActionCreateBulk
        protocol={protocol}
        actionComponentProps={{
          type: 'button',
        }}
      />
    }
  />
);

export default ProtocolConditionEmptyState;
