/**
 * Labstep
 *
 * @module components/ProtocolValue/Variable/Detail
 * @desc ProtocolValue Variable Detail
 */

import { useProtocolConditionModalContext } from 'labstep-web/components/ProtocolCondition/Modal/context';
import ProtocolValueVariableActionContainer from 'labstep-web/components/ProtocolValue/Variable/Action/Container';
import GridSmartTableProtocolValueVariable from 'labstep-web/grid/SmartTableProtocolValueVariable';
import { Protocol } from 'labstep-web/models/protocol.model';
import React from 'react';
import { GridProtocolValueVariableProps } from './types';

export const ProtocolValueVariableDetail: React.FC<
  GridProtocolValueVariableProps
> = ({ protocol, protocolValue }) => {
  const { isOpen } = useProtocolConditionModalContext();
  if (isOpen) {
    return null;
  }
  return protocol.n_protocol_conditions ||
    protocol instanceof Protocol ||
    protocolValue.is_output ? (
    <GridSmartTableProtocolValueVariable
      protocol={protocol}
      variableTemplate={protocolValue}
    />
  ) : (
    <ProtocolValueVariableActionContainer
      protocolValue={protocolValue}
    />
  );
};

export default ProtocolValueVariableDetail;
