/**
 * Labstep
 *
 * @module components/Layout/Menu/Side
 * @desc Default right side menu
 */

import cx from 'classnames';
import { HorizontalDivider } from 'labstep-web/core/Divider';
import Header from 'labstep-web/core/Header';
import CoreSideMenu from 'labstep-web/core/SideMenu';
import { objectOrFunction } from 'labstep-web/services/react.service';
import React from 'react';
import styles from './styles.module.scss';
import {
  LayoutMenuSideContainerProps,
  LayoutMenuSideProps,
} from './types';

export const LayoutMenuSide: React.FC<LayoutMenuSideProps> = ({
  children,
  header,
  defaultChildrenContainer,
  footer,
}) => (
  <div className={styles.main}>
    <div
      className={styles.headerContainer}
      id="side-menu-header-container"
    >
      <Header className={styles.header} noMargin size="tiny">
        {header}
      </Header>
    </div>
    <HorizontalDivider />
    <div
      className={cx(styles.children, {
        [styles.default]: defaultChildrenContainer,
      })}
    >
      {children}
    </div>
    {footer && (
      <>
        <HorizontalDivider />
        <div className={styles.footer}>{footer}</div>
      </>
    )}
  </div>
);

export const LayoutMenuSideContainer: React.FC<
  LayoutMenuSideContainerProps
> = ({
  header,
  name,
  children,
  viewComponent,
  defaultChildrenContainer,
  footer,
  ...rest
}) => (
  <CoreSideMenu name={name} viewComponent={viewComponent} {...rest}>
    {(props) => (
      <LayoutMenuSide
        header={header}
        children={objectOrFunction(children, props)}
        footer={footer}
        defaultChildrenContainer={defaultChildrenContainer}
      />
    )}
  </CoreSideMenu>
);

export default LayoutMenuSideContainer;
