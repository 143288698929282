/**
 * Labstep
 *
 * @module components/Thread/Item
 * @desc Thread
 */

import CreateComment from 'labstep-web/components/Comment/Form/Create';
import CommentList from 'labstep-web/components/Comment/List';
import { scrollToBottom } from 'labstep-web/components/Thread/Modal';
import Mobile from 'labstep-web/core/MediaQuery/Mobile';
import React from 'react';
import styles from './styles.module.scss';
import {
  IThreadMediaQueryContainerProps,
  IThreadProps,
} from './types';

export const Thread: React.FC<IThreadProps> = ({
  thread,
  placeholder,
  noCommentsMessage,
  mobile,
  showCreateComment = true,
  autoFocus,
  ...rest
}) => (
  <div className={styles.content}>
    <div className={styles.commentList}>
      <CommentList
        noCommentsMessage={noCommentsMessage}
        threadId={thread.id}
        {...rest}
      />
    </div>
    {showCreateComment && (
      <div className={styles.create}>
        <CreateComment
          autoFocus={autoFocus}
          onSuccess={() => scrollToBottom(thread.id)}
          mobile={mobile}
          threadId={thread.id}
          placeholder={placeholder}
        />
      </div>
    )}
  </div>
);

const ThreadMediaQueryContainer: React.FC<
  IThreadMediaQueryContainerProps
> = (props) => (
  <Mobile>
    {({ match }) => <Thread {...props} mobile={match} />}
  </Mobile>
);

export default ThreadMediaQueryContainer;
