/**
 * Labstep
 *
 * @module grid/Device/coldefs
 * @desc Device Grid Column Definitions
 */

import DeviceActionDelete from 'labstep-web/components/Device/Action/Delete';
import DeviceActionDuplicate from 'labstep-web/components/Device/Action/Duplicate';
import DeviceActionShare from 'labstep-web/components/Device/Action/Share';
import DeviceActionUpdateTemplate from 'labstep-web/components/Device/Action/UpdateTemplate';
import DeviceBookingManager from 'labstep-web/components/DeviceBooking/Manager';
import EntityActionTag from 'labstep-web/components/Entity/Action/Tag';
import EntityActionTransferOwnership from 'labstep-web/components/Entity/Action/TransferOwnership';
import MetadataManager from 'labstep-web/components/Metadata/Manager';
import { ColDef } from 'labstep-web/core/DataGrid/types';
import colDefActionMenu from 'labstep-web/grid/Index/coldefs/Entity/actionMenu';
import colDefBulkSelect from 'labstep-web/grid/Index/coldefs/Entity/bulkSelect';
import colDefEntityUser from 'labstep-web/grid/Index/coldefs/Entity/entityUser';
import { getMetadataColDefs } from 'labstep-web/grid/Index/coldefs/Resource/metadatas';
import colDefName from 'labstep-web/grid/Index/coldefs/Resource/name';
import colDefTemplate from 'labstep-web/grid/Index/coldefs/Resource/template';
import { ParamsHOCChildrenProps } from 'labstep-web/hoc/Params/types';
import { Device } from 'labstep-web/models/device.model';

export const colDefDeviceLink = colDefName(Device.entityName, {
  getEntityPreviewProps: (device) => ({
    secondaryInfo: <MetadataManager entity={device} />,
    bottom: !device.is_template && (
      <DeviceBookingManager device={device as Device} />
    ),
  }),
});

export const colDefDeviceActionMenu = (isTemplate?: boolean) =>
  colDefActionMenu<Device>((params) => (
    <>
      <EntityActionTag parentEntity={params.data} />
      <DeviceActionShare device={params.data} />
      <DeviceActionDuplicate device={params.data} />
      {!isTemplate && (
        <DeviceActionUpdateTemplate device={params.data} />
      )}
      <EntityActionTransferOwnership entity={params.data} />
      <DeviceActionDelete device={params.data} />
    </>
  ));

/**
 * For all metadatas on device item template, return a column
 * @param deviceTemplate Device template
 * @returns Column definitions
 */
export const getDeviceMetadataColDefs = (deviceTemplate: Device) =>
  getMetadataColDefs(Device.entityName, deviceTemplate);

export const generateDeviceGridcoldefs = (
  setParams: ParamsHOCChildrenProps['setParams'],
  deviceTemplate?: Device,
): ColDef<Device>[] => {
  const colDefs = deviceTemplate
    ? [
        colDefDeviceActionMenu(),
        colDefBulkSelect,
        colDefDeviceLink,
        ...getDeviceMetadataColDefs(deviceTemplate),
        colDefEntityUser,
      ]
    : [
        colDefDeviceActionMenu(),
        colDefBulkSelect,
        colDefDeviceLink,
        colDefTemplate(Device.entityName, setParams),
        colDefEntityUser,
      ];
  return colDefs;
};
