/**
 * Labstep
 *
 * @module components/Experiment/Action/End/Reverse
 * @desc Action to finish experiment
 */

import EntityActionUpdate from 'labstep-web/components/Entity/Action/Update';
import styles from 'labstep-web/components/ProtocolStep/Card/Detail/styles.module.scss';
import { humanReadableDate } from 'labstep-web/services/date.service';
import React from 'react';
import { ExperimentActionEndReverseProps } from './types';

export const ExperimentActionEndReverse: React.FC<
  ExperimentActionEndReverseProps
> = ({ experiment, options = {} }) =>
  experiment.ended_at ? (
    <EntityActionUpdate
      entityName={experiment.entityName}
      id={experiment.id}
      actionComponentProps={{
        type: 'icon',
        elementProps: {
          name: 'check circle',
          color: 'green',
          size: 'large',
          popup: {
            content: (
              <div>
                <div>{`Started on: ${humanReadableDate(
                  experiment.started_at,
                )}`}</div>
                <div>{`Completed on: ${humanReadableDate(
                  experiment.ended_at,
                )}`}</div>
                <div>{`Progress: ${experiment.completionPercentage} steps completed`}</div>
              </div>
            ),
          },
          className: styles.actionButton,
        },
      }}
      body={{ ended_at: null }}
      options={options}
    />
  ) : null;

export default ExperimentActionEndReverse;
