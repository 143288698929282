/**
 * Labstep
 *
 * @module components/PurchaseOrder/Filter/Status/List
 * @desc Filter for PurchaseOrder status as list
 */

import { FilterSection } from 'labstep-web/components/Filter/List';
import { ICONS } from 'labstep-web/constants/icons';
import Icon from 'labstep-web/core/Icon';
import { PurchaseOrderStatusValues } from 'labstep-web/models/purchase-order.model';
import React from 'react';
import styles from './styles.module.scss';

export const PurchaseOrderFilterStatusList: React.FC = () => (
  <FilterSection
    header={'Status'}
    filter={{
      entityName: 'purchase_order',
      name: 'status',
    }}
    filters={Object.keys(PurchaseOrderStatusValues).map((key) => ({
      component: (
        <Icon
          className={styles[key]}
          name={ICONS.purchase_order.info.status}
        />
      ),
      text: PurchaseOrderStatusValues[
        key as keyof typeof PurchaseOrderStatusValues
      ],
      params: { status: key },
    }))}
  />
);

export default PurchaseOrderFilterStatusList;
