/**
 * Labstep
 *
 * @module services/drop
 * @desc Provides a method to setup handling drag and drop
 */

interface IEvent {
  dataTransfer: any;
  target: any;
  preventDefault: VoidFunction;
}

export const allowed = (target: IEvent['target']) => {
  if (!(target && target.closest)) {
    return false;
  }

  if (
    target.id === 'dropzone' ||
    (target.closest && Boolean(target.closest('#dropzone')))
  ) {
    return true;
  }
  const editor = document.getElementById('editor');
  if (editor) {
    return target === editor || target.closest('#editor');
  }

  const prosemirror = document.getElementById('prose-mirror-editor');
  if (prosemirror) {
    return (
      target === prosemirror || target.closest('#prose-mirror-editor')
    );
  }

  return false;
};

export const handle = (e: IEvent) => {
  if (!allowed(e.target)) {
    e.preventDefault();
    e.dataTransfer.effectAllowed = 'none';
    e.dataTransfer.dropEffect = 'none';
  }
};

export const setupDropHandler = () => {
  window.addEventListener('dragenter', handle, false);
  window.addEventListener('dragover', handle);
  window.addEventListener('drop', handle);
};
