/**
 * Labstep
 *
 * @module components/ExperimentWorkflow/Action/View
 * @desc Button linking to experiment show
 */

import EntityActionView from 'labstep-web/components/Entity/Action/View';
import React from 'react';
import { ExperimentWorkflowActionViewProps } from './types';

export const ExperimentWorkflowActionView: React.FC<
  ExperimentWorkflowActionViewProps
> = ({ experimentWorkflow, ...rest }) => (
  <EntityActionView
    entityName="experiment_workflow"
    params={{ id: experimentWorkflow.id }}
    {...rest}
  />
);

export default ExperimentWorkflowActionView;
