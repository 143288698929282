/**
 * Labstep
 *
 * @module components/ProtocolDevice/Action/Menu
 * @desc Action Menu for ProtocolDevice
 */

import ProtocolDeviceActionDelete from 'labstep-web/components/ProtocolDevice/Action/Delete';
import ActionMenu from 'labstep-web/core/Action/Menu';
import React from 'react';
import { IProtocolDeviceActionMenuProps } from './types';

const ProtocolDeviceActionMenu: React.FC<
  IProtocolDeviceActionMenuProps
> = ({ protocolDevice, type, actions = [] }) => {
  return (
    <ActionMenu type={type}>
      {actions.indexOf('delete') > -1 && (
        <ProtocolDeviceActionDelete protocolDevice={protocolDevice} />
      )}
    </ActionMenu>
  );
};

ProtocolDeviceActionMenu.defaultProps = {
  actions: [],
  type: 'icon',
};

export default ProtocolDeviceActionMenu;
