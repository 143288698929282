/**
 * Labstep
 */

import { useEntityViewContext } from 'labstep-web/components/EntityView/Context';
import { useBulkSelectContext } from 'labstep-web/contexts/bulk-select/hook';
import { useDataGridContext } from 'labstep-web/contexts/grid/hook';
import { IDataGridProps } from 'labstep-web/core/DataGrid/types';
import { GridIndexProps } from 'labstep-web/grid/Index/types';
import ToolPanel from 'labstep-web/grid/Report/components/ToolPanel';
import { GridService } from 'labstep-web/grid/services/grid.service';
import { EntityView } from 'labstep-web/models/entity-view.model';
import { Entity } from 'labstep-web/models/entity.model';
import { debounce } from 'lodash';
import { useCallback, useEffect, useMemo, useRef } from 'react';

/**
 * Maximum height of the grid before we start scrolling
 */
export const MAX_HEIGHT = 750;

/**
 * Refreshes the columns on the grid when the columnDefs change
 * Reads the data from the server when the readOn prop changes
 * Refreshes the columns on the grid when the resetColumnsOn prop changes
 *
 * @param columnDefs Column definitions
 * @param read Read function
 * @param readOn Read if this prop changes
 * @param resetColumnsOn Refresh columns if this prop changes. Defaults to when header names change.
 */
export const useReadOrRefreshColumns = (
  columnDefs: GridIndexProps['columnDefs'],
  read: GridIndexProps['read'],
  readOn: GridIndexProps['readOn'],
  resetColumnsOn: GridIndexProps['resetColumnsOn'],
): void => {
  const { agGrid } = useDataGridContext();
  const refreshColumns = useCallback((): void => {
    if (agGrid) {
      agGrid.api.setColumnDefs(columnDefs);
    }
  }, [columnDefs]);

  // prevent useEffect calls on mount
  const firstUpdate = useRef(true);

  useEffect(
    () => {
      if (firstUpdate.current) {
        return;
      }
      refreshColumns();
    },
    resetColumnsOn ||
      (columnDefs && columnDefs.map((c) => c.headerName)),
  );

  useEffect(() => {
    // prevent call on mount
    if (firstUpdate.current || !read) {
      return;
    }
    read({
      onSuccess: () => refreshColumns(),
    });
  }, readOn);

  useEffect(() => {
    firstUpdate.current = false;
  }, []);
};

/**
 * Hook to use infinite scroll
 * Loads more data when the user scrolls to the bottom of the grid
 * We use the onGridReady callback to load more data when the grid is initialised
 * So that if the number of rows is smaller than the grid height,
 * we load more data until we reach the max height and the scrollbar appears
 * We use the onBodyScrollEnd callback to load more data when the user scrolls to the bottom of the grid
 *
 * @param loadMore Load more function
 * @returns Grid callbacks to load more data on scroll
 */
export const useInfiniteScroll = (
  loadMore: GridIndexProps['loadMore'],
): Pick<
  IDataGridProps,
  'height' | 'onBodyScroll' | 'onGridReady'
> => {
  if (!loadMore) {
    return {};
  }
  const debouncedLoadMore = debounce(loadMore, 200, {
    leading: true,
  });
  return {
    height: '100%',
    onBodyScroll: (params) => {
      if (params.direction !== 'vertical') {
        return;
      }
      GridService.loadMoreOnScroll(debouncedLoadMore)(params);
    },
    onGridReady: GridService.loadMoreOnScroll(debouncedLoadMore),
  };
};

/**
 * Hook to initialise column state
 * Applies the column state from the active entity view to the grid
 *
 * @returns Grid callbacks to initialise column state
 */
export const useInitialiseColumnState = (): Pick<
  IDataGridProps,
  'onGridReady'
> => {
  /**
   * Apply the column state from the active entity view to the grid
   */
  const context = useEntityViewContext();
  const onGridReady = useCallback<
    NonNullable<IDataGridProps['onGridReady']>
  >(
    (params) => {
      if (context?.activeEntityView?.state) {
        params.columnApi.applyColumnState({
          state: context.activeEntityView.state,
          applyOrder: true,
        });
      }
    },
    [context?.activeEntityView],
  );

  return { onGridReady };
};

/**
 * Hook to manage model updates on transactions
 * Model updates are disabled after update transactions
 * To prevent the undo redo stack from being cleared
 * But needs to be updated when the sort changes
 * So that the grid is refreshed with the new order
 *
 * @param entities Entities
 * @returns Grid props to suppress model update after update transaction
 */
export const useRowModelUpdate = (
  entities: Entity[],
): Partial<IDataGridProps> => {
  const { agGrid } = useDataGridContext();
  const prevEntities = useRef(entities);
  useEffect(() => {
    const orderChange =
      prevEntities.current.length !== entities.length ||
      prevEntities.current.some(
        (item, index) => item.idAttr !== entities[index].idAttr,
      );
    if (orderChange) {
      agGrid?.api?.refreshClientSideRowModel();
    }
    prevEntities.current = entities;
  }, [entities]);

  return {
    // required so the undo redo stack does not
    // get cleared on update row
    suppressModelUpdateAfterUpdateTransaction: true,
  };
};

export const useBulkSelect = (): Partial<IDataGridProps> => {
  const { toggleSelection, isSelected } = useBulkSelectContext();
  const onRowSelected = useCallback(
    (event): void => {
      if (isSelected(event.data) !== event.node.isSelected()) {
        toggleSelection(event.data);
      }
    },
    [isSelected, toggleSelection],
  );
  return {
    onRowSelected,
    suppressRowClickSelection: true,
  };
};

export const useSideBar = (entityView: EntityView) => {
  const { agGrid } = useDataGridContext();
  const sideBar = useMemo(
    () => ({
      toolPanels: [
        {
          id: 'columns',
          labelDefault: 'Columns',
          labelKey: 'customs',
          iconKey: 'columns',
          toolPanel: ToolPanel,
          toolPanelParams: {
            entityView,
          },
        },
      ],
      defaultToolPanel: 'columns',
    }),
    [],
  );

  useEffect(() => {
    if (agGrid) {
      agGrid.api.setSideBar(sideBar);
      agGrid.api.setSideBarVisible(false);
    }
  }, [agGrid, sideBar]);
};
