/**
 * Labstep
 *
 * @module prosemirror/components/Menu/Referencing/Menu
 * @desc Menu component for referencing menu
 */

import Icon from 'labstep-web/core/Icon';
import Menu from 'labstep-web/core/Menu';
import PremiumFeatureModal from 'labstep-web/core/PremiumFeature/Modal';
import Ref, { RefWrapper } from 'labstep-web/core/Ref';
import { usePremiumFeatureStatus } from 'labstep-web/hooks/premium-feature';
import { PremiumFeature } from 'labstep-web/models/organization.model';
import MenuItem from 'labstep-web/prosemirror/components/Menu/Commands/Menu/Item';
import MenuCursor from 'labstep-web/prosemirror/components/Menu/Cursor';
import {
  getIcon,
  getLabel,
  getSecondaryLabel,
} from 'labstep-web/prosemirror/components/NodeView/Reference/Content';
import {
  handleSelectCategory,
  handleSelectItem,
} from 'labstep-web/prosemirror/extensions/referencing/commands';
import { getState as getReferencingPluginState } from 'labstep-web/prosemirror/extensions/referencing/commands/selectors';
import { getHumanReadableEntityName } from 'labstep-web/services/i18n.service';
import React from 'react';
import MenuReferencingMenuCreatableAction from './Creatable/Action';
import MenuReferencingMenuCreatableList from './Creatable/List';
import styles from './styles.module.scss';
import { IMenuReferencingMenuProps } from './types';
import { showAction } from './utils';

export const MenuReferencingMenu: React.FC<
  IMenuReferencingMenuProps
> = ({
  state,
  dispatch,
  entityName,
  cursorPosition,
  loading,
  debouncing,
}) => {
  const {
    isOpen,
    index,
    selectedCategory,
    availableCategories,
    availableItems,
    creatableItems,
    token,
  } = getReferencingPluginState(state);

  const [openModal, setOpenModal] = React.useState(false);
  const [premiumFeature, setPremiumFeature] = React.useState<
    PremiumFeature | undefined
  >(undefined);
  const premiumCategories = usePremiumFeatureStatus(
    availableCategories,
    'creatableEntityName',
    'referencing_menu_categories',
  );
  const actionIndex = availableItems.length + creatableItems.length;

  return (
    <>
      {premiumFeature && (
        <PremiumFeatureModal
          open={openModal}
          premiumFeature={premiumFeature}
          viewComponent={<></>}
          onClose={() => {
            setOpenModal(false);
            setPremiumFeature(undefined);
          }}
        />
      )}
      <MenuCursor cursorPosition={cursorPosition} isOpen={isOpen}>
        {({ menuRef, activeItemRef }) => {
          let items: React.ReactElement | React.ReactElement[];

          if (selectedCategory) {
            if (availableItems.length === 0) {
              items = !loading && !debouncing && (
                <Menu.Item onClick={undefined}>
                  No item found
                </Menu.Item>
              );
            } else {
              items = availableItems.map((item, i: number) => {
                const isActive = index === i;
                return (
                  <RefWrapper
                    // eslint-disable-next-line react/no-array-index-key
                    key={i}
                    innerRef={isActive && activeItemRef}
                  >
                    <Menu.Item
                      active={isActive}
                      onClick={() =>
                        handleSelectItem(state, dispatch, i)
                      }
                    >
                      <div className={styles.item}>
                        <div>
                          <Icon
                            name={getIcon(selectedCategory.value)}
                          />
                          {getLabel(
                            item,
                            state.doc.content
                              .toJSON()
                              .filter((b) => b.type.endsWith('step'))
                              .map((b) => b.attrs.guid),
                          )}
                        </div>
                        {getSecondaryLabel(item, true)}
                      </div>
                    </Menu.Item>
                  </RefWrapper>
                );
              });
            }
          } else {
            items = premiumCategories.map((category, i) => {
              const isActive = index === i;
              return (
                <RefWrapper
                  key={category.value}
                  innerRef={isActive && activeItemRef}
                >
                  <MenuItem
                    onClick={() => {
                      if (
                        category.premiumFeature &&
                        !category.premiumFeatureEnabled
                      ) {
                        setOpenModal(true);
                        setPremiumFeature(category.premiumFeature);
                        return;
                      }

                      handleSelectCategory(state, dispatch, i);
                    }}
                    active={isActive}
                    header={`${category.label}`}
                    icon={getIcon(category.value)}
                    premiumFeature={category.premiumFeature}
                  />
                </RefWrapper>
              );
            });
          }

          return (
            <Ref innerRef={menuRef}>
              <Menu vertical borderless fluid size="mini">
                <Menu.Item>
                  <b>
                    {selectedCategory
                      ? `In this ${getHumanReadableEntityName(
                          entityName,
                        )} `
                      : 'Insert reference to'}
                    ...
                  </b>
                </Menu.Item>
                {items}
                {selectedCategory &&
                  (loading ||
                    (debouncing && availableItems.length === 0)) && (
                    <Menu.Item>Loading . . .</Menu.Item>
                  )}
                {selectedCategory && (
                  <>
                    {!!creatableItems.length &&
                      selectedCategory.creatableEntityName && (
                        <MenuReferencingMenuCreatableList
                          state={state}
                          dispatch={dispatch}
                          items={creatableItems}
                          selectedCategory={selectedCategory}
                          index={index}
                          startIndex={availableItems.length}
                          activeItemRef={activeItemRef}
                        />
                      )}
                    {showAction(selectedCategory) && (
                      <RefWrapper
                        innerRef={
                          actionIndex === index && activeItemRef
                        }
                      >
                        <MenuReferencingMenuCreatableAction
                          state={state}
                          dispatch={dispatch}
                          active={actionIndex === index}
                          index={actionIndex}
                          token={token}
                          selectedCategory={selectedCategory}
                        />
                      </RefWrapper>
                    )}
                  </>
                )}
              </Menu>
            </Ref>
          );
        }}
      </MenuCursor>
    </>
  );
};

export default MenuReferencingMenu;
