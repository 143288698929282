/**
 * Labstep
 *
 * @module components/Layout/Container/ActionButtons
 * @desc Container for action buttons
 */

import cx from 'classnames';
import React from 'react';
import styles from './styles.module.scss';
import { LayoutContainerActionButtonsProps } from './types';

export const LayoutContainerActionButtons: React.FC<
  LayoutContainerActionButtonsProps
> = ({ children, className }) => (
  <div className={cx(styles.container, className)}>{children}</div>
);

export default LayoutContainerActionButtons;
