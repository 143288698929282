/**
 * Labstep
 *
 * @module containers/UniqueId
 * @desc Container to generate unique ids
 */

import React from 'react';
import { v4 } from 'uuid';

interface ChildrenProps {
  uuid: string;
  refreshUuid: VoidFunction;
}

interface IProps {
  children: (props: ChildrenProps) => React.ReactNode;
}

interface IState {
  uuid: string;
}

export class UniqueIdContainer extends React.Component<
  IProps,
  IState
> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      uuid: v4(),
    };
    this.refreshUuid = this.refreshUuid.bind(this);
  }

  refreshUuid() {
    this.setState({ uuid: v4() });
  }

  render() {
    const { uuid } = this.state;
    const { children } = this.props;
    return children({
      uuid,
      refreshUuid: this.refreshUuid,
    });
  }
}
