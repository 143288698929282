/**
 * Labstep
 *
 * @module components/Comment/Action/CreateFile
 * @desc Upload a file in a comment
 */

import FileActionCreate from 'labstep-web/components/File/Action/Create';
import ActionComponent from 'labstep-web/core/Action/Component';
import Dropdown from 'labstep-web/core/Dropdown';
import React from 'react';
import { CommentActionCreateFileProps } from './types';

export const CommentActionCreateFile: React.FC<
  CommentActionCreateFileProps
> = ({ create, showOnly }) => (
  <Dropdown.Item className="no-padding">
    <FileActionCreate
      orphan
      multiple
      showOnly={showOnly}
      options={{
        onSuccess: ({ response }) => {
          if (!response) {
            return;
          }
          create({ file_id: [response.result] });
        },
      }}
    >
      {({ toggleModal }) => (
        <div>
          <ActionComponent
            onClick={toggleModal}
            type="option"
            icon={showOnly === 'cloud' ? 'cloud' : 'computer'}
            text={
              showOnly === 'cloud'
                ? 'From Cloud Provider'
                : 'From Device / Camera'
            }
          />
        </div>
      )}
    </FileActionCreate>
  </Dropdown.Item>
);

export default CommentActionCreateFile;
