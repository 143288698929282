/**
 * Labstep
 *
 * @module components/ExperimentWorkflow/Filter/Resource/Active
 * @desc Active filter component for resource
 */

import { ICONS } from 'labstep-web/constants/icons';
import { EntityReadEntityContainer } from 'labstep-web/containers/Entity/Read/Entity';
import RemoveLabel from 'labstep-web/core/Label/Remove';
import { ResourceItem } from 'labstep-web/models/resource-item.model';
import { Resource } from 'labstep-web/models/resource.model';
import React from 'react';
import {
  ExperimentWorkflowFilterResourceActiveProps,
  ResourceChildrenProps,
  ResourceItemChildrenProps,
} from './types';

const ExperimentWorkflowFilterResourceActive: React.FC<
  ExperimentWorkflowFilterResourceActiveProps
> = ({ clearParams, searchParams }) => (
  <>
    {'resource_item_id' in searchParams && (
      <EntityReadEntityContainer
        entityName={ResourceItem.entityName}
        id={searchParams.resource_item_id as number}
      >
        {({ entity: resourceItem }: ResourceItemChildrenProps) => {
          return resourceItem ? (
            <RemoveLabel
              icon={ICONS.resource_item.primary}
              name={`${resourceItem.resource.name} (${resourceItem.name})`}
              onRemoveClick={
                clearParams &&
                (() => clearParams(['resource_item_id']))
              }
            />
          ) : null;
        }}
      </EntityReadEntityContainer>
    )}
    {'resource_id' in searchParams && (
      <EntityReadEntityContainer
        entityName={Resource.entityName}
        id={searchParams.resource_id as number}
      >
        {({ entity: resource }: ResourceChildrenProps) => {
          return resource ? (
            <RemoveLabel
              name={`${resource.displayName}`}
              icon={ICONS.resource.primary}
              onRemoveClick={
                clearParams && (() => clearParams(['resource_id']))
              }
            />
          ) : null;
        }}
      </EntityReadEntityContainer>
    )}
  </>
);

export default ExperimentWorkflowFilterResourceActive;
