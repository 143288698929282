/**
 * Labstep
 *
 * @module grid/IndexOrderRequest/coldefs
 * @desc OrderRequest Index Grid Column Definitions
 */

import colDefBulkSelect from 'labstep-web/grid/Index/coldefs/Entity/bulkSelect';
import colDefEntityUser from 'labstep-web/grid/Index/coldefs/Entity/entityUser';
import {
  OrderRequest,
  OrderRequestStatus,
} from 'labstep-web/models/order-request.model';
import { colDefOrderRequestActionMenu } from './actionMenu';
import { colDefAmount } from './amount';
import { colDefIsUrgent } from './isUrgent';
import { colDefLogAuthor } from './logAuthor';
import { colDefLogCreatedAt } from './logCreatedAt';
import { getOrderRequestMetadataColDefs } from './metadata';
import { colDefOrderRequestName } from './name';
import { colDefPurchaseOrder } from './purchaseOrder';
import { colDefQuantity } from './quantity';
import colDefStatus from './status';

export const getIndexOrderRequestColDefs = (
  onChangeStatus: (
    orderRequest: OrderRequest,
    status: OrderRequestStatus,
  ) => void,
  template?: OrderRequest,
) => {
  let colDefs = [
    colDefOrderRequestActionMenu,
    colDefBulkSelect,
    colDefOrderRequestName,
    colDefIsUrgent,
    colDefQuantity,
    colDefStatus(onChangeStatus),
    colDefPurchaseOrder,
    colDefAmount,
    colDefLogAuthor(OrderRequestStatus.new),
    colDefLogCreatedAt(OrderRequestStatus.new),
    colDefLogAuthor(OrderRequestStatus.ordered),
    colDefLogCreatedAt(OrderRequestStatus.ordered),
    colDefLogAuthor(OrderRequestStatus.received),
    colDefLogCreatedAt(OrderRequestStatus.received),
  ];
  if (template) {
    colDefs = [
      ...colDefs,
      ...getOrderRequestMetadataColDefs(template),
      colDefEntityUser,
    ];
  } else {
    colDefs = [...colDefs, colDefEntityUser];
  }
  return colDefs;
};
