import FilterEntity from 'labstep-web/components/Filter/Entity';
import Container from 'labstep-web/components/Layout/Container';
import LayoutContainerActionButtons from 'labstep-web/components/Layout/Container/ActionButtons';
import { ICONS } from 'labstep-web/constants/icons';
import { withActiveGroup } from 'labstep-web/containers/ActiveGroup';
import { EntityReadEntityContainer } from 'labstep-web/containers/Entity/Read/Entity';
import ActionComponent from 'labstep-web/core/Action/Component';
import Modal from 'labstep-web/core/Modal/Default';
import SearchSelect from 'labstep-web/core/Select/Search';
import { Device } from 'labstep-web/models/device.model';
import React, { useState } from 'react';
import {
  DeviceChildrenProps,
  FilterDeviceSelectProps,
} from './types';

export const DeviceSelect: React.FC<FilterDeviceSelectProps> = ({
  setParams,
  searchParams,
}) => {
  let children = null;

  if ('device_id' in searchParams) {
    children = (
      <EntityReadEntityContainer
        entityName={Device.entityName}
        id={(searchParams.device_id as number) || 'random'}
      >
        {({ entity: device }: DeviceChildrenProps) => (
          <>
            <SearchSelect
              entityName={Device.entityName}
              params={{}}
              onChange={(selectedOption) => {
                setParams({
                  device_id: selectedOption
                    ? selectedOption.guid
                    : undefined,
                });
              }}
              value={device}
            />
          </>
        )}
      </EntityReadEntityContainer>
    );
  } else {
    children = (
      <EntityReadEntityContainer
        entityName={Device.entityName}
        id={(searchParams.device_id as number) || 'random'}
      >
        {({ entity: device }: DeviceChildrenProps) => (
          <>
            <SearchSelect
              entityName={Device.entityName}
              params={{}}
              onChange={(selectedOption) => {
                setParams({
                  device_id: selectedOption
                    ? selectedOption.id
                    : undefined,
                });
              }}
              value={device || null}
            />
          </>
        )}
      </EntityReadEntityContainer>
    );
  }
  return (
    <Modal
      header="Filter by device"
      content={({ toggleModal }) => (
        <div>
          <Container>{children}</Container>
          <LayoutContainerActionButtons>
            <ActionComponent
              type="button"
              text="Done"
              onClick={toggleModal}
            />
          </LayoutContainerActionButtons>
        </div>
      )}
      viewComponent={({ toggleModal }) => (
        <ActionComponent
          type="option"
          icon={ICONS.device.primary}
          text="Device used"
          onClick={toggleModal}
        />
      )}
    />
  );
};

export const FilterDeviceSelectContainer: React.FC<
  FilterDeviceSelectProps
> = ({ setParams, searchParams, ...rest }) => {
  const [shouldRenderWithFilter] = useState(
    'device_id' in searchParams,
  );

  const children = (
    <DeviceSelect
      setParams={setParams}
      searchParams={searchParams}
      {...rest}
    />
  );

  if (!shouldRenderWithFilter) {
    return children;
  }

  let entityName;
  let searchParamKey;

  if (searchParams.device_id) {
    entityName = Device.entityName;
    searchParamKey = 'device_id';
  } else {
    return children;
  }

  return (
    <FilterEntity
      entityName={entityName}
      searchParamKey={searchParamKey}
      searchParams={searchParams}
    >
      {() => children}
    </FilterEntity>
  );
};

export default withActiveGroup(FilterDeviceSelectContainer);
