/**
 * Labstep
 *
 * @module components/Entity/Action/Edit
 * @desc Button to edit entity
 */

import { useHasAccess } from 'labstep-web/components/Entity/Can';
import { Action } from 'labstep-web/components/Entity/Can/types';
import ActionComponent from 'labstep-web/core/Action/Component';
import ModalDefault from 'labstep-web/core/Modal/Default';
import React from 'react';
import { EntityActionEditModalProps } from './types';

export const EntityActionEditModal: React.FC<
  EntityActionEditModalProps
> = ({
  entity,
  viewComponent,
  actionComponentProps,
  renderIfNoEditPermissions = false,
  field,
  ...rest
}) => {
  const canEdit = useHasAccess(
    entity.entityName,
    entity.idAttr,
    Action.edit,
    field,
  ) as boolean;
  return (
    <ModalDefault
      viewComponent={({ toggleModal }) => {
        if (!canEdit && !renderIfNoEditPermissions) {
          return null;
        }
        const onClick = canEdit ? toggleModal : undefined;
        if (viewComponent !== undefined) {
          return typeof viewComponent === 'function'
            ? viewComponent({ toggleModal, canEdit })
            : viewComponent;
        }
        return (
          <ActionComponent
            type="option"
            text="Edit"
            icon="pencil"
            onClick={onClick}
            {...actionComponentProps}
          />
        );
      }}
      {...rest}
    />
  );
};

export default EntityActionEditModal;
