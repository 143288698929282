/**
 * Labstep
 */

import ProtocolConditionActionCreateBulk from 'labstep-web/components/ProtocolCondition/Action/Create/Bulk';
import ProtocolValueVariableActionSetItems from 'labstep-web/components/ProtocolValue/Variable/Action/SetItems';
import ProtocolValueVariableActionSetItemsContainer from 'labstep-web/components/ProtocolValue/Variable/Action/SetItems/Container';
import { getDoubleHeader } from 'labstep-web/core/DataGrid/ColumnHeader/utils';
import { ColDef, ColGroupDef } from 'labstep-web/core/DataGrid/types';
import { COL_ID_RESOURCE_ITEM } from 'labstep-web/grid/Index/coldefs/ProtocolValue';
import { IRangeType } from 'labstep-web/grid/Index/components/StatusBar/types';
import { getVariableGuidFromColId } from 'labstep-web/grid/SmartTable/coldefs/utils';
import {
  IReadOnMountContainerProps,
  IReadOnMountCursorProps,
} from 'labstep-web/hoc/ReadOnMount/types';
import { Experiment } from 'labstep-web/models/experiment.model';
import { Metadata } from 'labstep-web/models/metadata';
import { MetadataEntityNameType } from 'labstep-web/models/metadata/types';
import { ProtocolCondition } from 'labstep-web/models/protocol-condition.model';
import {
  ProtocolValue,
  ProtocolValueEntityNameType,
} from 'labstep-web/models/protocol-value.model';
import { Protocol } from 'labstep-web/models/protocol.model';
import React from 'react';
import { getStatusBarChildrenType } from './types';

/** Convert to double header if required */
export const getChildren = (
  children:
    | ColGroupDef<ProtocolCondition>
    | ColDef<ProtocolCondition>,
  isDouble: boolean,
): ColGroupDef<ProtocolCondition> | ColDef<ProtocolCondition> =>
  isDouble ? getDoubleHeader(children) : children;

/**
 * Get read props for metadata / protocolValue variables
 * @param protocol Experiment / Protocol
 * @param entityName string
 * @returns Read properties
 */
export const getReadProps = (
  protocol: Experiment | Protocol,
  entityName: MetadataEntityNameType | ProtocolValueEntityNameType,
): Omit<IReadOnMountCursorProps, 'children'> &
  Pick<IReadOnMountContainerProps, 'loading'> => {
  const params =
    entityName === Metadata.entityName
      ? {
          metadata_thread_id: protocol.metadata_thread.id,
        }
      : {
          [`${protocol.entityName}_id`]: protocol.id,
        };
  return {
    type: 'cursor',
    entityName,
    params: {
      count: 20, // only support <=20 variables for now
      is_variable: true,
      ...params,
    },
    loading: { loader: 'placeholder' },
  };
};

/**
 * Get selected variable and entities linked to protocol condition column
 * @param ranges Ranges
 * @param colName Column name
 * @param protocolValueVariables ProtocolValue variables
 * @returns ProtocolValues and variable object
 */
export const getSelectedColumn = (
  ranges: IRangeType[],
  colName: string,
  protocolValueVariables: ProtocolValue[],
): {
  protocolValues: ProtocolValue[];
  variableTemplate: ProtocolValue;
} | null => {
  // do not support multi selection for now
  if (ranges.length > 1) {
    return null;
  }
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < ranges.length; i++) {
    const range = ranges[i];
    const colId =
      range.columns.length === 1 && range.columns[0].getColId();
    if (colId) {
      const variableTemplateGuid = getVariableGuidFromColId(
        colId,
        colName,
      );
      if (variableTemplateGuid) {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const variableTemplate = protocolValueVariables.find(
          (v) => v.guid === variableTemplateGuid,
        );
        if (!variableTemplate) {
          return null;
        }

        const protocolConditions =
          range.entities as ProtocolCondition[];
        const protocolValues = protocolConditions.map(
          (protocolCondition) =>
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            protocolCondition.protocol_values.find(
              (protocolValue) =>
                protocolValue.variable_template_guid ===
                variableTemplateGuid,
            ),
        );

        if (protocolValues.includes(undefined)) {
          return null;
        }

        // we only support actions for one column at a time
        // otherwise we would append here rather than return
        return {
          protocolValues: protocolValues as ProtocolValue[],
          variableTemplate,
        };
      }
    }
  }
  return null;
};

export const getStatusBarChildren: getStatusBarChildrenType =
  ({ protocolValueVariables, protocol }) =>
  ({ ranges }): JSX.Element => {
    const column = getSelectedColumn(
      ranges,
      COL_ID_RESOURCE_ITEM,
      protocolValueVariables,
    );
    if (!column || column.protocolValues.length <= 1) {
      return (
        <ProtocolConditionActionCreateBulk
          protocol={protocol}
          actionComponentProps={{
            type: 'text',
            icon: 'plus',
          }}
        />
      );
    }
    return (
      <ProtocolValueVariableActionSetItemsContainer>
        {(containerProps): React.ReactElement => (
          <ProtocolValueVariableActionSetItems
            protocolValue={column.variableTemplate}
            protocolValues={column.protocolValues}
            {...containerProps}
          />
        )}
      </ProtocolValueVariableActionSetItemsContainer>
    );
  };
