/**
 * Labstep
 *
 * @module components/Molecule/Pictograms/ActionRemove
 * @desc Action to add pictogram
 */

import EntityActionUpdate from 'labstep-web/components/Entity/Action/Update';
import React from 'react';
import { getUpdatedPubChemBody } from '../ActionAdd/utils';
import { MoleculeSafetyPictogramsActionRemoveProps } from './types';

export const MoleculeSafetyPictogramsActionRemove: React.FC<
  MoleculeSafetyPictogramsActionRemoveProps
> = ({ parent, type, code, subtype, pubChemMetadata }) => (
  <EntityActionUpdate
    entityName={parent.entityName}
    id={parent.idAttr}
    body={getUpdatedPubChemBody(
      parent,
      pubChemMetadata,
      type,
      code,
      'remove',
      subtype,
    )}
    actionComponentProps={{
      type: 'icon',
      elementProps: { name: 'times' },
    }}
  />
);

export default MoleculeSafetyPictogramsActionRemove;
