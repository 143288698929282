/**
 * Labstep
 *
 * @module components/JupyterNotebook/Item
 * @desc JupyterNotebook card
 */

import Can from 'labstep-web/components/Entity/Can';
import { Action } from 'labstep-web/components/Entity/Can/types';
import JupyterNotebookActionAutomation from 'labstep-web/components/JupyterNotebook/Action/Automation';
import JupyterNotebookActionEdit from 'labstep-web/components/JupyterNotebook/Action/Edit';
import JupyterNotebookActionRun from 'labstep-web/components/JupyterNotebook/Action/Run';
import JupyterNotebookContent from 'labstep-web/components/JupyterNotebook/Content';
import JupyterNotebookPreviousRun from 'labstep-web/components/JupyterNotebook/PreviousRun';
import ActionComponent from 'labstep-web/core/Action/Component';
import Card from 'labstep-web/core/Card/Card';
import Image from 'labstep-web/core/Image';
import Loadable from 'labstep-web/core/Loadable';
import { JupyterNotebook } from 'labstep-web/models/jupyter-notebook.model';
import React, { useState } from 'react';
import styles from './styles.module.scss';
import { IJupyterNotebookItemProps } from './types';

export const NotebookJS = React.lazy(
  () => import('labstep-web/core/NotebookJS'),
);

export const JupyterNotebookItem: React.FC<
  IJupyterNotebookItemProps
> = ({ jupyterNotebook }) => {
  const [collapsed, setCollapsed] = useState(true);
  const [edit, setEdit] = useState(false);

  return (
    <>
      <Card
        className={styles.card}
        onDoubleClick={() => setCollapsed(!collapsed)}
      >
        <div>
          <div className={styles.logoContainer}>
            <Image src={JupyterNotebook.icon} avatar />
          </div>
          <div>{jupyterNotebook.label}</div>
        </div>
        <JupyterNotebookActionAutomation
          jupyterNotebook={jupyterNotebook}
        />
        <JupyterNotebookPreviousRun
          jupyterNotebook={jupyterNotebook}
        />
        <JupyterNotebookActionRun jupyterNotebook={jupyterNotebook} />
        <Can
          entityName={jupyterNotebook.entityName}
          id={jupyterNotebook.idAttr}
          action={Action.edit}
        >
          <ActionComponent
            type="icon"
            icon={edit ? 'eye' : 'pencil'}
            elementProps={{
              popup: { content: edit ? 'View Code' : 'Edit Code' },
            }}
            onClick={() => {
              setEdit(!edit);
              setCollapsed(false);
            }}
          />
        </Can>

        <JupyterNotebookActionEdit
          jupyterNotebook={jupyterNotebook}
        />
        {jupyterNotebook.data && (
          <ActionComponent
            type="icon"
            icon={collapsed ? 'chevron down' : 'chevron up'}
            onClick={() => {
              setCollapsed(!collapsed);
            }}
          />
        )}
      </Card>
      {jupyterNotebook.data && !collapsed && (
        <div className={styles.inlineFrame}>
          <Loadable>
            {edit ? (
              <JupyterNotebookContent
                jupyterNotebook={jupyterNotebook}
              />
            ) : (
              <NotebookJS data={jupyterNotebook.data} />
            )}
          </Loadable>
        </div>
      )}
    </>
  );
};

export default JupyterNotebookItem;
