/**
 * Labstep
 *
 * @module components/PurchaseOrder/ListOrTable
 * @desc List or table of purchaseOrders
 */

import PurchaseOrderList from 'labstep-web/components/PurchaseOrder/List';
import PurchaseOrderTable from 'labstep-web/components/PurchaseOrder/Table';
import MasterIndexCenterListOrTable from 'labstep-web/screens/Master/Index/Center/ListOrTable';
import React from 'react';
import { actions } from './constants';
import { IPurchaseOrderListOrTableProps } from './types';

export const PurchaseOrderListOrTable: React.FC<
  IPurchaseOrderListOrTableProps
> = ({ purchaseOrders, ...rest }) => (
  <MasterIndexCenterListOrTable
    tableComponent={
      <PurchaseOrderTable
        showCollaborators
        purchaseOrders={purchaseOrders}
        actions={actions}
        {...rest}
      />
    }
    listComponent={
      <PurchaseOrderList
        purchaseOrders={purchaseOrders}
        actions={actions}
      />
    }
  />
);

export default PurchaseOrderListOrTable;
