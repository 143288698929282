/**
 * Labstep
 *
 * @module components/ProtocolCollection/Action/Delete
 * @desc Button to delete protocol collection
 */

import EntityActionDelete from 'labstep-web/components/Entity/Action/Delete';
import React from 'react';
import { IProtocolCollectionActionDeleteProps } from './types';

export const ProtocolCollectionActionDelete: React.FC<
  IProtocolCollectionActionDeleteProps
> = ({ protocolCollection, ...rest }) => (
  <EntityActionDelete {...rest} entity={protocolCollection} />
);

export default ProtocolCollectionActionDelete;
