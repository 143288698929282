/**
 * Labstep
 *
 * @module components/File/Thumbnail
 * @desc Component to load securely a thumbnail
 */

import { withAuthenticatedUserEntity } from 'labstep-web/containers/AuthenticatedUser';
import { NewThumbnail } from 'labstep-web/containers/File/NewThumbnail';
import { NewThumbnailContainer } from 'labstep-web/containers/Thumbnail';
import React from 'react';
import { IThumbnailContainerProps } from './types';

const purifyParams = (params: any) => {
  let purifiedParams = { ...params };
  if (!purifiedParams) {
    purifiedParams = {};
  }
  if (!purifiedParams.h) {
    purifiedParams.h = 1280;
  }
  if (!purifiedParams.w) {
    purifiedParams.w = 1280;
  }

  return purifiedParams;
};

export const Thumbnail: React.FC<IThumbnailContainerProps> = ({
  file,
  params,
  children,
  customFetching,
  placeholder,
}) => (
  <NewThumbnailContainer
    fileGuid={file.guid}
    params={purifyParams(params)}
  >
    {({ status, src, fetchUrl, onError }) => (
      <NewThumbnail
        customFetching={customFetching}
        status={status}
        src={src}
        fetchUrl={fetchUrl}
        onError={onError}
        children={children}
        placeholder={placeholder}
      />
    )}
  </NewThumbnailContainer>
);

export const ThumbnailContainer =
  withAuthenticatedUserEntity(Thumbnail);
