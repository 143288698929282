/**
 * Labstep
 */

import { ActionMenuProps } from 'labstep-web/core/Action/Menu/types';
import { Resource } from 'labstep-web/models/resource.model';
import { IResourceTemplateDeleteActionProps } from '../Delete/types';

// eslint-disable-next-line no-shadow
export enum Action {
  view = 'view',
  share = 'share',
  duplicate = 'duplicate',
  delete = 'delete',
  export = 'export',
  revoke = 'revoke',
  transfer_ownership = 'transfer_ownership',
}

export interface IResourceTemplateActionMenuProps
  extends Pick<
    ActionMenuProps,
    Exclude<keyof ActionMenuProps, 'children' | 'options'>
  > {
  resourceTemplate: Resource;
  actions: Action[];
  options?: IResourceTemplateDeleteActionProps['options'];
}
