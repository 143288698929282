/**
 * Labstep
 *
 * @module components/ProtocolDevice/ListItem
 * @desc Protocol Device item
 */

import DevicePreview from 'labstep-web/components/Device/Preview';
import ProtocolDeviceActionMenu from 'labstep-web/components/ProtocolDevice/Action/Menu';
import { ICONS } from 'labstep-web/constants/icons';
import ElementCard from 'labstep-web/core/Card/Element';
import React from 'react';
import { IProtocolDeviceListItemProps } from './types';

export const ProtocolDeviceListItem: React.FC<
  IProtocolDeviceListItemProps
> = ({ protocolDevice }) => (
  <DevicePreview
    device={protocolDevice.device}
    modalProps={{
      viewComponent: ({ toggleModal }) => (
        <ElementCard
          icon={ICONS.device.primary}
          name={protocolDevice.name}
          onClick={toggleModal}
          actions={
            <ProtocolDeviceActionMenu
              protocolDevice={protocolDevice}
              actions={['delete']}
            />
          }
        />
      ),
    }}
  />
);

export default ProtocolDeviceListItem;
