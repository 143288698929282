/**
 * Labstep
 *
 * @module components/DeviceData/Preview
 * @desc Preview a DeviceData
 */

import EntityCardData from 'labstep-web/components/Entity/Card/Data';
import FileViewer from 'labstep-web/components/File/Viewer';
import { ICONS } from 'labstep-web/constants/icons';
import React from 'react';
import { DeviceDataPreviewProps } from './types';

export const DeviceDataPreview: React.FC<DeviceDataPreviewProps> = ({
  deviceData,
}) => (
  <EntityCardData
    icon={ICONS.device.primary}
    date={deviceData.updated_at}
    label={deviceData.name}
    value={
      deviceData &&
      deviceData.files &&
      deviceData.files.length > 0 ? (
        <>
          {deviceData.files
            .filter((file) => file.deleted_at === null)
            .map((file) => (
              <FileViewer key={file.guid} file={file} />
            ))}
        </>
      ) : (
        deviceData.printValue || ''
      )
    }
  />
);

export default DeviceDataPreview;
