/**
 * Labstep
 *
 * @module prosemirror/nodes/file
 * @desc File node
 */

import { getAttrsForId } from 'labstep-web/prosemirror/nodes/utils';
import { Node as ProseMirrorNode } from 'prosemirror-model';
import { LabstepNode } from '../types';

const key = 'file';

const NodeFile: LabstepNode = {
  key,
  spec: {
    attrs: {
      id: { default: null },
      fullWidth: { default: false },
      width: { default: null },
    },
    inline: true,
    group: 'inline',
    marks: '',
    // atom: true,
    draggable: true,
    parseDOM: [{ tag: 'span', getAttrs: getAttrsForId(key) }],
    toDOM(node: ProseMirrorNode) {
      const { id } = node.attrs;
      return ['span', { [`data-${key}-id`]: id }];
    },
  },
};

export default NodeFile;
