/**
 * Labstep
 *
 * @module components/Layout/SubPage/Header
 * @desc Sub page header on RightPane
 */

import classnames from 'classnames';
import Header from 'labstep-web/core/Header';
import Icon from 'labstep-web/core/Icon';
import LinkButton from 'labstep-web/core/Link/Button';
import Modal from 'labstep-web/core/Modal/Default';
import React from 'react';
import styles from './styles.module.scss';
import { RightPaneSubPageHeaderProps } from './types';

export const RightPaneSubPageHeader: React.FC<
  RightPaneSubPageHeaderProps
> = ({ backRoute, icon, header, expanded, onClose, action }) => (
  <>
    {backRoute && (
      <LinkButton route={backRoute} className={styles.linkButton}>
        <Icon name="angle left" size="big" />
      </LinkButton>
    )}
    {onClose && (
      <div className={styles.linkButton}>
        <Icon name="angle left" size="big" onClick={onClose} />
      </div>
    )}
    <div
      className={classnames(styles.headerText, {
        [styles.headerWithAction]: action,
      })}
    >
      <Header size="tiny" noMargin>
        <Icon name={icon} />
        {header}
      </Header>
      {expanded && (
        <div className={styles.expand}>
          <Modal
            header={
              <Header size="tiny" noMargin>
                <Icon name={icon} />
                {header}
              </Header>
            }
            viewComponent={({ toggleModal }): React.ReactElement => (
              <Icon name="external" onClick={toggleModal} />
            )}
            content={expanded}
            size="large"
            closeOnDimmerClick
          />
        </div>
      )}
    </div>
    {action && <div className={styles.action}>{action}</div>}
  </>
);

export default RightPaneSubPageHeader;
