/**
 * Labstep
 *
 * @module components/Entity/Sortable/List
 * @desc Entity Sortable List
 */

import SortableList from 'labstep-web/core/Sortable/List';
import React from 'react';
import EntitySortable from '..';
import { EntitySortableListProps } from './types';

const EntitySortableList: React.FC<EntitySortableListProps> = ({
  entityName,
  status,
  disabled,
  ...rest
}) => (
  <EntitySortable
    entityName={entityName}
    render={({ onSortEnd, disabled: sortableDisabled }) => {
      const isDisabled =
        disabled || sortableDisabled || (status && status.isFetching);
      return (
        <SortableList
          disabled={isDisabled}
          onSortEnd={onSortEnd}
          {...rest}
        />
      );
    }}
  />
);

export default EntitySortableList;
