/**
 * Labstep
 *
 * @module grid/IndexResource/coldefs
 * @desc Resource Grid Column Definitions
 */

import { ColDef } from 'labstep-web/core/DataGrid/types';
import colDefBulkSelect from 'labstep-web/grid/Index/coldefs/Entity/bulkSelect';
import colDefEntityUser from 'labstep-web/grid/Index/coldefs/Entity/entityUser';
import colDefTemplate from 'labstep-web/grid/Index/coldefs/Resource/template';
import { colDefResourceActionMenu } from 'labstep-web/grid/IndexResource/coldefs/actionMenu';
import colDefAvailableItems from 'labstep-web/grid/IndexResource/coldefs/availableItems';
import { getResourceMetadataColDefs } from 'labstep-web/grid/IndexResource/coldefs/metadata';
import { colDefResourceName } from 'labstep-web/grid/IndexResource/coldefs/name';
import { ParamsHOCChildrenProps } from 'labstep-web/hoc/Params/types';
import { Resource } from 'labstep-web/models/resource.model';

export const generateResourceGridcoldefs = (
  setParams: ParamsHOCChildrenProps['setParams'],
  resourceTemplate?: Resource,
): ColDef<Resource>[] => {
  const colDefs = resourceTemplate
    ? [
        colDefResourceActionMenu,
        colDefBulkSelect,
        colDefResourceName,
        colDefAvailableItems,
        ...getResourceMetadataColDefs(resourceTemplate),
        colDefEntityUser,
      ]
    : [
        colDefResourceActionMenu,
        colDefBulkSelect,
        colDefResourceName,
        colDefTemplate(Resource.entityName, setParams),
        colDefAvailableItems,
        colDefEntityUser,
      ];
  return colDefs;
};
