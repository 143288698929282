/**
 * Labstep
 *
 * @module components/OrderRequest/Card/EmptyState
 * @desc Empty state for group
 */

import OrderRequestCreateAction from 'labstep-web/components/OrderRequest/Action/Create';
import Card from 'labstep-web/core/Card/Card';
import EmptyState from 'labstep-web/core/Card/EmptyState';
import { OrderRequest } from 'labstep-web/models/order-request.model';
import { getHumanReadableEntityName } from 'labstep-web/services/i18n.service';
import React from 'react';
import { OrderRequestEmptyStateProps } from './types';

const label = getHumanReadableEntityName(
  OrderRequest.entityName,
  true,
  true,
);

export const OrderRequestEmptyState: React.FC<
  OrderRequestEmptyStateProps
> = (props) => (
  <EmptyState
    title={`No ${OrderRequest.getHumanReadableEntityName(
      true,
      true,
    )}`}
    explanation={
      'Track the order requests for the resources you need to restock. Include all relevant order information, such as PO numbers, receipts or invoices. Seamlessly add the items back to your inventory on arrival.'
    }
    src="/img/empty-state/orderRequests.svg"
    action={<OrderRequestCreateAction />}
    {...props}
  />
);

const OrderRequestEmptyStateCard = () => (
  <Card header={label}>
    <OrderRequestEmptyState />
  </Card>
);

export default OrderRequestEmptyStateCard;
