/**
 * Labstep
 *
 * @module components/EntityImport/EmptyState
 * @desc EntityImport Empty State
 */

import EntityImportActionCreateTrigger from 'labstep-web/components/EntityImport/Action/Create/Trigger';
import EmptyState from 'labstep-web/core/Card/EmptyState';
import Loadable from 'labstep-web/core/Loadable';
import { ExcelIOData } from 'labstep-web/services/excel-io/types';
import { getHumanReadableEntityName } from 'labstep-web/services/i18n.service';
import React, { useState } from 'react';
import { EntityImportEmptyStateProps } from './types';

export const EntityImportActionCreate = React.lazy(
  () => import('labstep-web/components/EntityImport/Action/Create'),
);

const EntityImportEmptyState: React.FC<
  EntityImportEmptyStateProps
> = ({ entityName, templateId }) => {
  const [importData, setImportData] = useState<
    ExcelIOData | undefined
  >();
  return (
    <Loadable>
      <EntityImportActionCreate
        entityName={entityName}
        templateId={templateId}
        importData={importData}
        setImportData={setImportData}
      />
      <EntityImportActionCreateTrigger
        entityName={entityName}
        allowImportOnly
        setImportData={setImportData}
        dropzoneProps={{
          noClick: true,
          showDragEnter: true,
          children: (
            <EmptyState
              src="/img/empty-state/file-to-cloud.svg"
              explanation={`Drag and Drop CSV /XLSX files to import ${getHumanReadableEntityName(
                entityName,
                true,
              )} in bulk`}
              action={
                <EntityImportActionCreateTrigger
                  entityName={entityName}
                  setImportData={setImportData}
                />
              }
            />
          ),
        }}
      />
    </Loadable>
  );
};

export default EntityImportEmptyState;
