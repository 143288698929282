/**
 * Labstep
 *
 * @module components/Entity/PrimaryInfo/Image
 * @desc Primary Info with uploadable image
 */

import EntityImageUploadable from 'labstep-web/components/Entity/Image/Uploadable';
import EntityPrimaryInfo from 'labstep-web/components/Entity/PrimaryInfo';
import React from 'react';
import styles from './styles.module.scss';
import { EntityPrimaryInfoImageProps } from './types';

export const EntityPrimaryInfoImage: React.FC<
  EntityPrimaryInfoImageProps
> = ({ entity, primaryInfo }) => (
  <div className={styles.infoContainer}>
    <div className={styles.image}>
      <EntityImageUploadable entity={entity} />
    </div>
    <div className={styles.info}>
      {primaryInfo || <EntityPrimaryInfo entity={entity} />}
    </div>
  </div>
);

export default EntityPrimaryInfoImage;
