/**
 * Labstep
 *
 * @module components/OrganizationSAML/Form/ShowEdit
 * @desc Edit organizationSAML field
 */

import ShowEditText from 'labstep-web/core/Form/ShowEdit/Text';
import React from 'react';
import { getFormField } from '../fields';
import { OrganizationSAMLFormShowEditProps } from './types';

const OrganizationSAMLFormShowEdit: React.FC<
  OrganizationSAMLFormShowEditProps
> = ({
  organizationSAML,
  fieldType,
  name,
  fieldLabel,
  placeholder,
  secret,
}) => (
  <ShowEditText
    entityName={organizationSAML.entityName}
    entity={organizationSAML}
    field={getFormField(fieldType, name, fieldLabel, placeholder)}
    secret={secret}
  >
    {organizationSAML[name]}
  </ShowEditText>
);

export default OrganizationSAMLFormShowEdit;
