/**
 * Labstep
 *
 * @module grid/Index/components/StatusBar
 * @desc Entity DataGrid Status Bar
 * @desc Renders an action based on the selected ranges
 */

import type { RangeSelectionChangedEvent } from 'ag-grid-community';
import React, { useCallback, useEffect, useState } from 'react';
import { GridIndexStatusBarProps, IRangeType } from './types';
import { getRangesFromSelection } from './utils';

export const GridIndexStatusBar: React.FC<
  GridIndexStatusBarProps
> = ({ children, api }) => {
  const [ranges, setRanges] = useState<IRangeType[]>([]);
  const updateStatusBar = useCallback(
    (e: RangeSelectionChangedEvent): void => {
      setRanges(getRangesFromSelection(e));
    },
    [],
  );
  useEffect(() => {
    api.addEventListener('rangeSelectionChanged', updateStatusBar);
    return () =>
      api.removeEventListener(
        'rangeSelectionChanged',
        updateStatusBar,
      );
  }, []);

  return (
    <>
      {typeof children === 'function'
        ? children({ ranges })
        : children}
    </>
  );
};

export default GridIndexStatusBar;
