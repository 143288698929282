/**
 * Labstep
 *
 * @module grid/Report/components/Filter/ProtocolCollection/Form
 * @desc Filter ProtocolCollection form
 */

import ProtocolCollectionSearchSelect from 'labstep-web/components/ProtocolCollection/SearchSelect';
import { withActiveGroup } from 'labstep-web/containers/ActiveGroup';
import { ParamsStateContextProvider } from 'labstep-web/contexts/params-state';
import { PostFilter } from 'labstep-web/services/postFilter';
import { PostFilterComparison } from 'labstep-web/services/query-parameter.service';
import React from 'react';
import { IFilterProtocolCollectionFormProps } from './types';

const FilterProtocolCollectionForm: React.FC<
  IFilterProtocolCollectionFormProps
> = ({ addPostFilter, onDone, activeGroup }) => {
  if (!activeGroup) {
    return null;
  }
  return (
    <ParamsStateContextProvider value={{}}>
      <ProtocolCollectionSearchSelect
        params={{
          group_id: activeGroup.id,
        }}
        returnProtocolCollection
        onChange={(option: any) => {
          const filter = new PostFilter(
            [['guid', PostFilterComparison.eq]],
            'experiment.protocol.collection',
          );
          addPostFilter(filter.getNode([option.guid]), true);
          onDone();
        }}
      />
    </ParamsStateContextProvider>
  );
};

export default withActiveGroup(FilterProtocolCollectionForm);
