/**
 * Labstep
 *
 * @module components/ResourceItem/Action/Import
 * @desc ResourceItem Import Action
 */

import { CanCreate } from 'labstep-web/components/Entity/Can';
import LayoutContainerActionButtons from 'labstep-web/components/Layout/Container/ActionButtons';
import { ICONS } from 'labstep-web/constants/icons';
import { EntityCreateContainer } from 'labstep-web/containers/Entity/Create';
import ActionComponent from 'labstep-web/core/Action/Component';
import Button from 'labstep-web/core/Button';
import ReusableForm from 'labstep-web/core/Form/Reusable';
import {
  FieldType,
  FieldWithoutActionProps,
} from 'labstep-web/core/Form/Reusable/types';
import Loadable from 'labstep-web/core/Loadable';
import DefaultModal from 'labstep-web/core/Modal/Default';
import { ResourceItem } from 'labstep-web/models/resource-item.model';
import { Resource } from 'labstep-web/models/resource.model';
import { IFieldsData } from 'labstep-web/services/grapecity.service';
import {
  RESOURCE_ITEM_IMPORT_ROWS_LIMIT,
  resourceItemImportService,
} from 'labstep-web/services/resource-item-import.service';
import React from 'react';
import { number } from 'yup';
import {
  ResourceItemActionImportContainerProps,
  ResourceItemActionImportProps,
  ResourceItemActionImportState,
} from './types';

export const SpreadSheet = React.lazy(() => import('./SpreadSheet'));

export const fieldQuantity: FieldWithoutActionProps = {
  fieldType: FieldType.Text,
  name: 'quantity',
  fieldLabel: 'Number of Items',
  validation: number()
    .required()
    .integer()
    .max(RESOURCE_ITEM_IMPORT_ROWS_LIMIT)
    .min(1),
};

export const initialState = (
  defaultOpen = false,
): ResourceItemActionImportState => {
  return {
    open: defaultOpen,
    data: null,
    fields: null,
    error: null,
    attempt: 0,
  };
};

export class ResourceItemActionImport extends React.Component<
  ResourceItemActionImportProps,
  ResourceItemActionImportState
> {
  constructor(props: ResourceItemActionImportProps) {
    super(props);
    this.open = this.open.bind(this);
    this.reset = this.reset.bind(this);
    this.import = this.import.bind(this);
    this.state = initialState(props.defaultOpen);
  }

  componentDidMount() {
    const { defaultOpen } = this.props;
    if (defaultOpen) {
      this.open();
    }
  }

  import() {
    const { create, resource, orderRequest } = this.props;
    const { attempt, data } = this.state;

    this.setState({
      attempt: attempt + 1,
    });

    const [validData, error] =
      resourceItemImportService.validateData(data);

    if (error) {
      this.setState({
        error,
      });

      return;
    }

    this.setState({
      error: null,
    });

    const postData: any =
      resourceItemImportService.convertSpreadsheetDataToPostData(
        validData,
        {
          resource_id: resource.id,
          order_request_id: orderRequest
            ? orderRequest.id
            : undefined,
        },
      );

    create(postData, {
      onSuccess: this.reset,
      toast: true,
    });
    this.props.onImport?.();
  }

  reset() {
    this.setState(initialState());
    const { onReset } = this.props;
    if (onReset) {
      onReset();
    }
  }

  open() {
    const { resource, orderRequest } = this.props;
    if (orderRequest) {
      const initialResourceState: IFieldsData =
        resourceItemImportService.getInitialFieldsAndData(
          resource,
          orderRequest.quantity - orderRequest.resource_item_count,
        );
      this.setState({ open: true, ...initialResourceState });
    } else {
      this.setState({ open: true });
    }
  }

  render() {
    const {
      status,
      resource,
      actionComponentProps,
      showViewComponent = true,
      ...rest
    } = this.props;
    const { open, data, fields, error, attempt } = this.state;

    return (
      <DefaultModal
        open={open}
        onClose={this.reset}
        size={data === null ? 'tiny' : 'fullscreen'}
        header={`Add ${ResourceItem.getHumanReadableEntityName(
          true,
          true,
        )}`}
        viewComponent={() =>
          showViewComponent && (
            <ActionComponent
              type="text"
              icon={ICONS.resource_item.primary}
              text={`Add ${ResourceItem.getHumanReadableEntityName(
                true,
                true,
                resource.is_template,
              )}`}
              onClick={() => this.open()}
              {...actionComponentProps}
            />
          )
        }
        content={() => {
          if (!data) {
            return (
              <ReusableForm
                autoFocus
                status={status}
                defaultValues={{ quantity: 1 }}
                fields={[fieldQuantity]}
                onSubmit={(values) => {
                  const initialResourceState: IFieldsData =
                    resourceItemImportService.getInitialFieldsAndData(
                      resource,
                      values.quantity as number,
                    );

                  this.setState((prevState) => ({
                    ...prevState,
                    ...initialResourceState,
                  }));
                }}
              />
            );
          }

          return (
            <div style={{ height: 500, width: '100%' }}>
              <Loadable>
                <SpreadSheet
                  withValidation
                  data={data}
                  fields={fields}
                />
              </Loadable>
              <div>
                {error && (
                  <DefaultModal
                    key={attempt}
                    initialModalState
                    header="Errors"
                    viewComponent={
                      error.rows &&
                      error.rows.length > 0 && (
                        <ActionComponent
                          type="text"
                          text="Click here to see all errors."
                        />
                      )
                    }
                    content={
                      <div>
                        {error.rows && error.rows.length > 0
                          ? error.rows.map(
                              (
                                rowError: React.ReactElement,
                                index: number,
                              ) => (
                                // eslint-disable-next-line react/no-array-index-key
                                <div key={index}>{rowError}</div>
                              ),
                            )
                          : error.message}
                      </div>
                    }
                  />
                )}
                <LayoutContainerActionButtons>
                  <Button
                    onClick={this.import}
                    primary
                    loader
                    status={status}
                  >
                    Done
                  </Button>
                </LayoutContainerActionButtons>
              </div>
            </div>
          );
        }}
        {...rest}
      />
    );
  }
}

export const ResourceItemActionImportContainer: React.FC<
  ResourceItemActionImportContainerProps
> = (props) => (
  <CanCreate
    entityName={ResourceItem.entityName}
    parentName={Resource.entityName}
    parentId={props.resource.id}
  >
    <EntityCreateContainer batch entityName={ResourceItem.entityName}>
      {({ create, status }) => (
        <ResourceItemActionImport
          create={create}
          status={status}
          {...props}
        />
      )}
    </EntityCreateContainer>
  </CanCreate>
);

export default ResourceItemActionImportContainer;
