/**
 * Labstep
 *
 * @module components/Entity/Can
 * @desc Component to determine if user has permissions for certain action
 */

import React from 'react';
import {
  useHasAccess,
  useHasAccessCreate,
  useHasAccessGroup,
} from './hooks';
import { CanCreateProps, CanGroupProps, CanProps } from './types';

export { useHasAccess, useHasAccessCreate, useHasAccessGroup };

export const CanCreate: React.FC<CanCreateProps> = ({
  parentName,
  parentId,
  entityName,
  children,
}) =>
  useHasAccessCreate(entityName, parentName, parentId) ? (
    <>{children}</>
  ) : null;

export const CanGroup: React.FC<CanGroupProps> = ({
  action,
  groupId,
  children,
}) => (useHasAccessGroup(action, groupId) ? <>{children}</> : null);

const Can: React.FC<CanProps> = ({
  entityName,
  id,
  action,
  children,
  field,
}) =>
  useHasAccess(entityName, id, action, field) ? (
    <>{children}</>
  ) : null;

export default Can;
