/**
 * Labstep
 */

import { Group } from 'labstep-web/models/group.model';

// eslint-disable-next-line no-shadow
export enum Action {
  edit = 'edit',
  delete = 'delete',
  lock = 'lock',
  unlock = 'unlock',
  assign = 'entity_user:*',
  share = 'permission:*',
  comment = 'comment:*',
  add_remove = 'add_remove',
  tag_add_remove = 'tag:add_remove',
  folder_add_remove = 'folder:add_remove',
  experiment_create = 'experiment:create',
  protocol_value_edit = 'protocol_value:edit',
  device_booking_edit = 'device_booking:edit',
  jupyter = 'jupyter_notebook:*',
  experiment_workflow_edit = 'experiment_workflow:edit',
  experiment_workflow_edit_entity_state = 'experiment_workflow:edit:entity_state',
}

// export interface Permissions {
//   view: boolean;
//   edit: boolean;
//   owner: boolean;
// }

export interface CanProps {
  entityName: string;
  id: number | string;
  action: Action;
  field?: string | string[];
}

export interface CanGroupProps {
  action: string;
  groupId: Group['id'];
}

export interface CanCreateProps {
  parentName?: string;
  parentId?: number | string;
  entityName: string;
}
