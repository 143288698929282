/**
 * Labstep
 *
 * @module containers/ShareLinkEmail
 * @desc Email ShareLink container
 */

import { APICallOptions } from 'labstep-web/models';
import {
  sharelinkInvitationOrganizationAction,
  sharelinkInvitationOrganizationActionType,
} from 'labstep-web/state/actions/sharelink-invitation-organization';
import { selectApiStatus } from 'labstep-web/state/selectors/ui';
import { connect } from 'react-redux';

const Container = ({ children, ...rest }) => children(rest);

const mapStateToProps = (state) => ({
  status: selectApiStatus(
    state,
    sharelinkInvitationOrganizationActionType,
  ),
});

const mapDispatchToProps = (dispatch) => ({
  createSharelinkInvitationOrganization(
    body: Record<string, unknown>,
    options: APICallOptions,
  ): void {
    dispatch(sharelinkInvitationOrganizationAction(body, options));
  },
});

export const SharelinkInvitationOrganizationContainer = connect<
  any,
  any,
  any
>(
  mapStateToProps,
  mapDispatchToProps,
)(Container);
