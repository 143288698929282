/**
 * Labstep
 *
 * @module grid/IndexOrderRequest
 * @desc OrderRequest Index Grid
 * @url  /workspace/:workspaceId/order-requests
 */

import GridIndex from 'labstep-web/grid/Index';
import React from 'react';
import { GridOrderRequestProps } from './types';

export const GridOrderRequest: React.FC<GridOrderRequestProps> = ({
  orderRequests,
  columnDefs,
  ...rest
}) => {
  return (
    <GridIndex
      entities={orderRequests}
      columnDefs={columnDefs}
      {...rest}
    />
  );
};

export default GridOrderRequest;
