/**
 * Labstep
 *
 * @desc Protocol Version label
 */

import Icon from 'labstep-web/core/Icon';
import Label from 'labstep-web/core/Label';
import React from 'react';
import styles from './styles.module.scss';
import { IProtocolVersionProps } from './types';

const ProtocolVersion: React.FC<IProtocolVersionProps> = ({
  protocol,
  onClick,
}) => (
  <Label className={styles.label} onClick={onClick} circular>
    <span>{protocol.printVersion}</span>
    <Icon name="angle down" />
  </Label>
);

export default ProtocolVersion;
