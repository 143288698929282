/**
 * Labstep
 *
 * @module components/Experiment/List
 * @desc Experiment List
 */

import {
  DragDropContext,
  Draggable,
  DropResult,
  Droppable,
} from '@hello-pangea/dnd';
import { useHasAccess } from 'labstep-web/components/Entity/Can';
import { Action } from 'labstep-web/components/Entity/Can/types';
import ExperimentCard from 'labstep-web/components/Experiment/Card';
import { EntityUpdateAnyContainer } from 'labstep-web/containers/Entity/Update/Any';
import List from 'labstep-web/core/List';
import { ExperimentWorkflow } from 'labstep-web/models/experiment-workflow.model';
import { Experiment } from 'labstep-web/models/experiment.model';
import { PositionService } from 'labstep-web/services/position.service';
import React from 'react';
import { ExperimentListProps } from './types';

export const ExperimentList: React.FC<ExperimentListProps> = ({
  experiments,
  experimentWorkflowId,
}) => {
  const canEdit = useHasAccess(
    ExperimentWorkflow.entityName,
    experimentWorkflowId,
    Action.edit,
  );
  return (
    <EntityUpdateAnyContainer entityName={Experiment.entityName}>
      {({ update }) => (
        <DragDropContext
          onDragEnd={(result: DropResult) =>
            PositionService.onDragEnd(result, experiments, update)
          }
        >
          <Droppable
            droppableId="experiment-list"
            isDropDisabled={!canEdit}
          >
            {(provided) => (
              <div id="experiment-list" ref={provided.innerRef}>
                <List>
                  {experiments
                    .sort((a, b) => a.position - b.position)
                    .map((experiment, index) => (
                      <Draggable
                        key={`experiment-${experiment.id}`}
                        draggableId={`experiment:${experiment.guid}`}
                        index={index}
                      >
                        {(draggableProvided) => (
                          <div
                            ref={draggableProvided.innerRef}
                            {...draggableProvided.draggableProps}
                            {...draggableProvided.dragHandleProps}
                          >
                            <ExperimentCard experiment={experiment} />
                          </div>
                        )}
                      </Draggable>
                    ))}
                </List>
              </div>
            )}
          </Droppable>
        </DragDropContext>
      )}
    </EntityUpdateAnyContainer>
  );
};

export default ExperimentList;
