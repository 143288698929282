/**
 * Labstep
 *
 * @module ProtocolValue/Action/Create
 * @desc ProtocolValue create.
 */

import EntityActionCreate from 'labstep-web/components/Entity/Action/Create';
import { withActiveEditModal } from 'labstep-web/containers/ActiveEditModal';
import { ProtocolValue } from 'labstep-web/models/protocol-value.model';
import { getHumanReadableEntityName } from 'labstep-web/services/i18n.service';
import { enhanceOptions } from 'labstep-web/services/utils.service';
import React from 'react';
import { IProtocolValueActionCreateProps } from './types';

export const ProtocolValueActionCreate: React.FC<
  IProtocolValueActionCreateProps
> = ({
  protocol,
  setActiveEditModal,
  actionComponentProps,
  options,
}) => {
  const { entityName } = ProtocolValue;

  return (
    <EntityActionCreate
      entityName={entityName}
      parentName={protocol.entityName}
      parentId={protocol.id}
      options={enhanceOptions({
        options,
        onSuccess: ({ response }) => {
          setActiveEditModal({
            id: response!.result,
            entityName,
          });
        },
      })}
      actionComponentProps={{
        dataCy: 'components/ProtocolValue/Action/Create',
        type: 'button',
        text: `Add ${getHumanReadableEntityName(
          ProtocolValue.entityName,
          false,
          true,
        )}`,
        ...actionComponentProps,
      }}
    />
  );
};

export default withActiveEditModal(ProtocolValueActionCreate);
