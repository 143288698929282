/**
 * Labstep
 *
 * @module components/ShareLinkInvitation/Form/Create/Organization
 * @desc A form to create a sharelink invitation for organization
 */

import { withAuthenticatedUserEntity } from 'labstep-web/containers/AuthenticatedUser';
import { SharelinkInvitationOrganizationContainer } from 'labstep-web/containers/SharelinkInvitationOrganization';
import FormReusable from 'labstep-web/core/Form/Reusable';
import {
  FieldType,
  FieldWithoutActionProps,
  IFieldProps,
} from 'labstep-web/core/Form/Reusable/types';
import React from 'react';
import { array, object, string } from 'yup';
import {
  IShareLinkInvitationFormCreateOrganizationProps,
  SharelinkInvitationOrganizationContainerChildrenProps,
} from './types';

export const fieldEmails: FieldWithoutActionProps = {
  name: 'emails',
  fieldType: FieldType.SelectMulti,
  validation: array().of(string().email()).required(),
  fieldLabel: 'Email Addresses',
};

export const ShareLinkInvitationFormCreateOrganization: React.FC<
  IShareLinkInvitationFormCreateOrganizationProps
> = ({
  organization,
  options,
  withWorkspace,
  cancelButton,
  users,
  authenticatedUserEntity,
}) => {
  let fields: IFieldProps[] = [fieldEmails];

  const fieldWorkspaceForUsers: FieldWithoutActionProps = {
    name: 'workspace',
    fieldType: FieldType.SearchSelect,
    elementProps: {
      entityName: 'group',
      params: { organization_id: organization.id },
    },
    validation: object(),
    placeholder: 'Select a home workspace for the users...',
    fieldLabel: 'Home Workspace',
  };

  if (withWorkspace) {
    fields = [
      ...fields,
      fieldWorkspaceForUsers,
      {
        fieldType: FieldType.action,
        component: () => (
          <p>
            Note: Users will only start counting towards your monthly
            invoice once the invitations are accepted.
          </p>
        ),
      },
    ];
  }

  return (
    <SharelinkInvitationOrganizationContainer>
      {({
        createSharelinkInvitationOrganization,
        status,
      }: SharelinkInvitationOrganizationContainerChildrenProps): React.ReactElement => (
        <FormReusable
          autoFocus
          status={status}
          fields={fields}
          submitButtonText="Invite"
          cancelButton={cancelButton}
          defaultValues={
            users
              ? {
                  emails: users
                    .map(({ username }) => username)
                    .filter(
                      (email) =>
                        email !== authenticatedUserEntity.username,
                    ),
                }
              : undefined
          }
          onSubmit={({ emails, message, workspace_id }): void => {
            createSharelinkInvitationOrganization(
              {
                emails,
                organization_id: organization.id,
                organization_group_id: workspace_id,
                message,
              },
              options,
            );
          }}
        />
      )}
    </SharelinkInvitationOrganizationContainer>
  );
};

export default withAuthenticatedUserEntity(
  ShareLinkInvitationFormCreateOrganization,
);
