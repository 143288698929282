/**
 * Labstep
 *
 * @module components/OrderRequest/Action/UpdateStatus/Bulk
 * @desc Action to bulk update status for orderequest
 */

import { useHasAccessMultiple } from 'labstep-web/components/Entity/Can/hooks';
import { Action } from 'labstep-web/components/Entity/Can/types';
import BulkActionComponent from 'labstep-web/core/Action/Component/Bulk';
import { OrderRequest } from 'labstep-web/models/order-request.model';
import React from 'react';
import { OrderRequestActionUpdateStatus } from '..';
import { OrderRequestActionUpdateStatusBulkProps } from './types';

const OrderRequestActionUpdateStatusBulk: React.FC<
  OrderRequestActionUpdateStatusBulkProps
> = ({ entities, options }) => {
  const entityIdsAccessObject = useHasAccessMultiple(
    OrderRequest.entityName,
    entities ? entities.map((e) => e.id) : [],
    Action.edit,
  );

  const entitiesWithPermissions = entities
    ? entities.filter((e) => entityIdsAccessObject[e.id])
    : [];

  if (
    !entities ||
    entities.length === 0 ||
    entitiesWithPermissions.length === 0
  ) {
    return null;
  }

  const trigger = (
    <BulkActionComponent
      content="Set Status"
      onClick={undefined}
      icon="check circle outline"
      entitiesSelectedCount={entities.length}
      entitiesWithPermissionCount={entitiesWithPermissions.length}
    />
  );
  if (entitiesWithPermissions.length === 0) {
    return trigger;
  }
  return (
    <OrderRequestActionUpdateStatus
      disabled={entitiesWithPermissions.length === 0}
      trigger={trigger}
      orderRequestIds={entitiesWithPermissions.map((e) => e.id)}
      options={options}
    />
  );
};

export default OrderRequestActionUpdateStatusBulk;
