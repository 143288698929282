/**
 * Labstep
 *
 * @module containers/ActiveGroup
 * @desc Container for ui
 */

import {
  selectActiveGroup,
  selectActiveGroupId,
  setActiveGroupId,
} from 'labstep-web/state/slices/activeGroup';
import { LabstepReduxState } from 'labstep-web/state/types';
import React from 'react';
import { connect } from 'react-redux';
import {
  WithActiveGroupInjectedProps,
  WithActiveGroupMapDispatchToProps,
  WithActiveGroupMapStateToProps,
} from './types';

const mapStateToProps = (
  state: LabstepReduxState,
): WithActiveGroupMapStateToProps => {
  return {
    activeGroup: selectActiveGroup(state),
    activeGroupId: selectActiveGroupId(state),
  };
};

const mapDispatchToProps: WithActiveGroupMapDispatchToProps = {
  setActiveGroupId,
};

export const withActiveGroup = <
  P extends WithActiveGroupInjectedProps,
  S,
>(
  Component: React.FC<P> | React.ComponentClass<P, S>,
): React.FC<
  Pick<P, Exclude<keyof P, keyof WithActiveGroupInjectedProps>>
> => connect(mapStateToProps, mapDispatchToProps)(Component as any);
