/**
 * Labstep
 *
 * @module core/CharMap
 * @desc Shows special characters for addition
 */

import classnames from 'classnames';
import fuzzysort from 'fuzzysort';
import { charmap } from 'labstep-web/constants/charmap';
import Input from 'labstep-web/core/Input';
import React, { useState } from 'react';
import styles from './styles.module.scss';
import {
  CategoryFilterType,
  FiltersProps,
  ICharMapProps,
  ICharactersProps,
} from './types';

export const Filters: React.FC<FiltersProps> = ({
  setCategoryFilter,
  categoryFilter,
}) => (
  <>
    <div
      className={classnames({
        [styles.active]: categoryFilter === null,
      })}
      onClick={() => setCategoryFilter(null)}
    >
      All
    </div>
    {charmap.map((charSet) => (
      <div
        className={classnames({
          [styles.active]: categoryFilter === charSet.name,
        })}
        key={charSet.name}
        onClick={() => setCategoryFilter(charSet.name)}
      >
        {charSet.name}
      </div>
    ))}
  </>
);

export const Characters: React.FC<ICharactersProps> = ({
  characters,
  onClick,
}) => (
  <>
    {characters.map((character) => (
      <div
        key={character.code}
        onClick={() => onClick(String.fromCharCode(character.code))}
        className={styles.symbol}
      >
        {String.fromCharCode(character.code)}
      </div>
    ))}
  </>
);

const CharMap: React.FC<ICharMapProps> = ({ onClick }) => {
  const [categoryFilter, setCategoryFilter] =
    useState<CategoryFilterType | null>(null);
  const [textFilter, setTextFilter] = useState('');

  const allCharacters = charmap.reduce(
    (result, charset) => [...result, ...charset.characters],
    [] as (typeof charmap)[0]['characters'],
  );
  let characters = categoryFilter
    ? charmap.find((charset) => charset.name === categoryFilter)
        ?.characters || []
    : allCharacters;

  if (textFilter) {
    characters = fuzzysort
      .go(textFilter, characters, {
        threshold: -Infinity, // Don't return matches worse than this (higher is faster)
        limit: Infinity, // Don't return more results than this (lower is faster)
        allowTypo: true, // Allwos a snigle transpoes (false is faster)
        key: 'name', // For when targets are objects (see its example usage)
      })
      .map((match) => match.obj);
  }

  return (
    <div className={styles.container}>
      <div className={styles.categoryFilterContainer}>
        <Filters
          setCategoryFilter={setCategoryFilter}
          categoryFilter={categoryFilter}
        />
      </div>
      <div className={styles.right}>
        <Input
          placeholder="Search"
          fluid
          value={textFilter}
          onChange={(e) => setTextFilter(e.target.value)}
        />
        <div className={styles.charactersContainer}>
          <Characters onClick={onClick} characters={characters} />
        </div>
      </div>
    </div>
  );
};

export default CharMap;
