/**
 * Labstep
 *
 * @module prosemirror/components/Toolbar/Main
 * @desc Main toolbar displayed at the top of the editor
 */

import debounceRender from 'labstep-web/containers/DebounceRender';
import ToolbarAlignment from 'labstep-web/prosemirror/components/Toolbar/Alignment';
import Break from 'labstep-web/prosemirror/components/Toolbar/Break';
import ToolbarClearFormatting from 'labstep-web/prosemirror/components/Toolbar/ClearFormatting';
import ToolbarColorPicker from 'labstep-web/prosemirror/components/Toolbar/ColorPicker';
import ToolbarHeading from 'labstep-web/prosemirror/components/Toolbar/Heading';
import ToolbarHistory from 'labstep-web/prosemirror/components/Toolbar/History';
import ToolbarInline from 'labstep-web/prosemirror/components/Toolbar/Inline';
import ToolbarList from 'labstep-web/prosemirror/components/Toolbar/List';
import ToolbarMark from 'labstep-web/prosemirror/components/Toolbar/Mark';
import ToolbarSymbol from 'labstep-web/prosemirror/components/Toolbar/Symbol';
import ToolbarTable from 'labstep-web/prosemirror/components/Toolbar/Table';
import React from 'react';
import { isMobile } from 'react-device-detect';
import styles from './styles.module.scss';
import { IToolbarMainProps } from './types';

export const ToolbarMain: React.FC<IToolbarMainProps> = ({
  view,
  entity,
  experimentWorkflow,
}) => {
  const { state, dispatch } = view;

  return (
    <div className={styles.container}>
      {isMobile && (
        <>
          <ToolbarInline
            view={view}
            entity={entity}
            experimentWorkflow={experimentWorkflow}
          />
          <Break />
        </>
      )}
      <ToolbarHistory state={state} dispatch={dispatch} />
      <Break />
      <ToolbarSymbol state={state} dispatch={dispatch} />
      <Break />
      <ToolbarHeading state={state} dispatch={dispatch} />
      <ToolbarMark state={state} dispatch={dispatch} />
      <Break />
      <ToolbarColorPicker view={view} />
      <Break />
      <ToolbarAlignment state={state} dispatch={dispatch} />
      <Break />
      <ToolbarList state={state} dispatch={dispatch} />
      <Break />
      <ToolbarClearFormatting state={state} dispatch={dispatch} />
      <ToolbarTable state={state} dispatch={dispatch} />
    </div>
  );
};

export default debounceRender(ToolbarMain as any);
