/**
 * Labstep
 *
 * @module components/Entity/Form/ShowEdit/Name
 * @desc ShowEditDescripion for Entity
 */

import { fieldEntityDescription } from 'labstep-web/components/Entity/Form/fields';
import ShowEditText from 'labstep-web/core/Form/ShowEdit/Text';
import Linkify from 'labstep-web/core/Linkify';
import Pre from 'labstep-web/core/Pre';
import React from 'react';
import { EntityFormShowEditDescriptionProps } from './types';

export const EntityFormShowEditDescription: React.FC<
  EntityFormShowEditDescriptionProps
> = ({ entity }) => {
  return (
    <ShowEditText
      entityName={entity.entityName}
      entity={entity}
      field={{
        ...fieldEntityDescription(entity.entityName),
        header: false,
      }}
      placeholder="Add description"
    >
      <Pre>
        <Linkify>{entity.description}</Linkify>
      </Pre>
    </ShowEditText>
  );
};

export default EntityFormShowEditDescription;
