/**
 * Labstep
 *
 * @module components/Entity/Action/UpdateTemplate/Bulk
 * @desc Action to bulk update entities template
 * TODO Refactor Only used for Device/Resource as we have hardcoded 'Set Category' label
 */

import { useHasAccessMultiple } from 'labstep-web/components/Entity/Can/hooks';
import { Action } from 'labstep-web/components/Entity/Can/types';
import { ICONS } from 'labstep-web/constants/icons';
import { withActiveGroup } from 'labstep-web/containers/ActiveGroup';
import { EntityUpdateContainer } from 'labstep-web/containers/Entity/Update';
import BulkActionComponent from 'labstep-web/core/Action/Component/Bulk';
import ModalDefault from 'labstep-web/core/Modal/Default';
import SearchSelect from 'labstep-web/core/Select/Search';
import React from 'react';
import { EntityActionUpdateTemplateBulkProps } from './types';

export const EntityActionUpdateTemplateBulk: React.FC<
  EntityActionUpdateTemplateBulkProps
> = ({ entityName, entities, options, activeGroup }) => {
  const entityIdsAccessObject = useHasAccessMultiple(
    entityName,
    entities ? entities.map((e) => e.idAttr) : [],
    Action.edit,
  );

  if (!entities || entities.length === 0) {
    return null;
  }

  const entitiesWithPermission = entities.filter(
    (e) =>
      entityIdsAccessObject[
        e.idAttr as keyof typeof entityIdsAccessObject
      ],
  );

  return (
    <ModalDefault
      header="Set category"
      content={({ toggleModal }): React.ReactElement => (
        <EntityUpdateContainer
          entityName={entityName}
          id={entitiesWithPermission.map((e) => e.idAttr)}
        >
          {({ update }): React.ReactElement => (
            <SearchSelect
              params={{
                is_template: true,
                group_id: activeGroup?.id,
              }}
              autoFocus
              openMenuOnFocus
              creatable
              createBody={{ is_template: true }}
              entityName={entityName}
              value={null}
              onChange={(selectedOption): void => {
                update({ template_id: selectedOption.id }, options);
                toggleModal();
              }}
            />
          )}
        </EntityUpdateContainer>
      )}
      viewComponent={({ toggleModal }): React.ReactElement => (
        <BulkActionComponent
          entitiesWithPermissionCount={entitiesWithPermission.length}
          entitiesSelectedCount={entities.length}
          onClick={toggleModal}
          content="Set category"
          icon={ICONS.resource_template.primary}
        />
      )}
    />
  );
};

export default withActiveGroup(EntityActionUpdateTemplateBulk);
