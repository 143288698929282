/**
 * Labstep
 *
 * @module components/ResourceLocationMap/Action/CreateMap
 * @desc CreateMap Resource Location Map Action
 */

import EntityActionEdit from 'labstep-web/components/Entity/Action/Edit';
import ResourceLocationFormCreateMap from 'labstep-web/components/ResourceLocation/Form/Create/Map';
import ModalDefault from 'labstep-web/core/Modal/Default';
import React from 'react';
import { IResourceLocationMapActionCreateMapProps } from './types';

export const ResourceLocationMapActionCreateMap: React.FC<
  IResourceLocationMapActionCreateMapProps
> = ({ resourceLocation, actionComponentProps }) => (
  <ModalDefault
    header="Select Grid Size"
    content={({ toggleModal }) => (
      <ResourceLocationFormCreateMap
        resourceLocation={resourceLocation}
        options={{ onSuccess: toggleModal }}
      />
    )}
    viewComponent={({ toggleModal }) => (
      <EntityActionEdit
        entity={resourceLocation}
        actionComponentProps={{
          type: 'button',
          icon: 'plus',
          text: 'Create Map',
          onClick: toggleModal,
          ...actionComponentProps,
        }}
      />
    )}
  />
);

export default ResourceLocationMapActionCreateMap;
