/**
 * Labstep
 *
 * @module prosemirror/components/NodeView/File
 * @desc Renders a File node
 */

import FileCollapsibleCard from 'labstep-web/components/File/CollapsibleCard';
import { File as FileModel } from 'labstep-web/models/file.model';
import ViewInline from 'labstep-web/prosemirror/components/Inline/View';
import Read from 'labstep-web/prosemirror/components/NodeView/Read';
import { useReactNodeView } from 'labstep-web/prosemirror/components/ReactNodeView';
import { expandedPluginKey } from 'labstep-web/prosemirror/extensions/expanded/plugin';
import { NodeSelection } from 'prosemirror-state';
import React from 'react';
import FileWithResize from './WithResize';
import styles from './styles.module.scss';
import { IFileNodeProps } from './types';

export class FileNode extends React.Component<IFileNodeProps> {
  shouldComponentUpdate() {
    return Boolean(!this);
  }

  render() {
    const {
      id,
      timestamp,
      fullWidth,
      initialExpandedState,
      resizeImage,
      attrs,
      focus,
      editable,
    } = this.props;

    return (
      <Read
        entityName={FileModel.entityName}
        timestamp={timestamp}
        id={id}
      >
        {({ entity: file }: any) => {
          if (
            file &&
            file.mime_type?.startsWith('image') &&
            !file.isLinked
          ) {
            return (
              <FileWithResize
                editable={editable}
                attrs={attrs}
                resizeImage={resizeImage}
                file={file}
                focus={focus}
              />
            );
          }
          return fullWidth ? (
            <FileCollapsibleCard
              file={file}
              initialExpandedState={initialExpandedState}
            />
          ) : (
            <ViewInline
              entity={file}
              icon="file"
              className={styles.container}
            >
              {file.name}
            </ViewInline>
          );
        }}
      </Read>
    );
  }
}

const FileNodeView = ({ timestamp }: any) => {
  const context: any = useReactNodeView();
  const { attrs } = context.node as any;
  const { id } = attrs;

  const { state } = context.view as any;
  const initialExpandedState =
    state.plugins
      .find((p: any) => p.spec.key === expandedPluginKey)
      .getState(state).file === id;

  const { node } = context;
  const resizeImage = (resizeImageAttrs: IFileNodeProps['attrs']) => {
    // Change this node's attrs to set width
    const { tr } = context.view.state;
    const pos = context.getPos();
    const newAttrs = { ...node.attrs, ...resizeImageAttrs };

    tr.setNodeMarkup(pos, null, newAttrs);
    // Set selection to current selection
    const selection = NodeSelection.create(tr.doc, pos);
    tr.setSelection(selection);

    context.view.dispatch(tr);
    context.view.focus();
  };

  return (
    <FileNode
      id={id}
      timestamp={timestamp}
      attrs={attrs}
      focus={() => {
        const pos = context.getPos();
        const { tr } = context.view.state;
        // Set selection at pos
        const selection = NodeSelection.create(tr.doc, pos);
        tr.setSelection(selection);

        context.view.dispatch(tr);
        context.view.focus();
      }}
      initialExpandedState={initialExpandedState}
      resizeImage={resizeImage}
      editable={context.view.editable}
    />
  );
};

export default FileNodeView;
