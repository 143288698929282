/**
 * Labstep
 */

import EntityActionTransferOwnership from 'labstep-web/components/Entity/Action/TransferOwnership';
import ResourceActionDelete from 'labstep-web/components/Resource/Action/Delete';
import ResourceActionDuplicate from 'labstep-web/components/Resource/Action/Duplicate';
import ResourceActionShare from 'labstep-web/components/Resource/Action/Share';
import colDefActionMenu from 'labstep-web/grid/Index/coldefs/Entity/actionMenu';
import { Resource } from 'labstep-web/models/resource.model';

export const colDefResourceActionMenu = colDefActionMenu<Resource>(
  (params) => (
    <>
      <ResourceActionShare resource={params.data} />
      <ResourceActionDuplicate resource={params.data} />
      <EntityActionTransferOwnership entity={params.data} />
      <ResourceActionDelete resource={params.data} />
    </>
  ),
);
