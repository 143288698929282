/**
 * Labstep
 *
 * @module components/EntityStateWorkflow/Action/Duplicate
 * @desc Button to duplicate
 */

import EntityActionDuplicate from 'labstep-web/components/Entity/Action/Duplicate';
import React from 'react';
import { EntityStateWorkflowActionDuplicateProps } from './types';

export const EntityStateWorkflowActionDuplicate: React.FC<
  EntityStateWorkflowActionDuplicateProps
> = ({ entityStateWorkflow }) => (
  <EntityActionDuplicate entity={entityStateWorkflow} />
);

export default EntityStateWorkflowActionDuplicate;
