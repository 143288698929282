/**
 * Labstep
 *
 * @module components/OrganizationPlan/Card
 * @desc Card to display organization plan details
 */

import classnames from 'classnames';
import Card from 'labstep-web/core/Card/Card';
import Header from 'labstep-web/core/Header';
import Icon from 'labstep-web/core/Icon';
import SubHeader from 'labstep-web/core/SubHeader';
import React from 'react';
import styles from './styles.module.scss';
import { OrganizationPlanCardProps } from './types';

const OrganizationPlanCard: React.FC<OrganizationPlanCardProps> = ({
  icon,
  header,
  primary,
  secondary,
  action,
  shiny,
}) => (
  <Card
    className={classnames(
      shiny ? styles.shiny : styles.basic,
      styles.card,
    )}
  >
    <div className={styles.container}>
      <div className={styles.subheading}>
        <div className={styles.logo}>
          <Icon name={icon} color="white" />
        </div>
        <SubHeader
          className={shiny ? styles.lightHeader : styles.darkHeader}
        >
          {header}
        </SubHeader>
      </div>
      <div className={styles.info}>
        {primary && <Header size="xsmall">{primary}</Header>}
        {secondary && <div>{secondary}</div>}
      </div>
    </div>

    {action && <div className={styles.actionContainer}>{action}</div>}
  </Card>
);

export default OrganizationPlanCard;
