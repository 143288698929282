/**
 * Labstep
 */

export interface AllowedActionsLockSection {
  default: boolean;
  label: string;
  value: string | string[];
}

export const AllowedActionsLockSections: Record<
  string,
  AllowedActionsLockSection[]
> = {
  Protocol: [
    {
      default: false,
      label: 'Can edit the name of the protocol',
      value: 'experiment:edit:name',
    },
    {
      default: true,
      label: 'Can edit start / end date',
      value: [
        'experiment:edit:started_at',
        'experiment:edit:ended_at',
      ],
    },
    {
      default: true,
      label: 'Can mark steps as complete',
      value: 'protocol_step:edit:ended_at',
    },
    {
      default: false,
      label: 'Can edit the text and basic tables',
      value: [
        'experiment:edit:state',
        'protocol_table:*',
        'protocol_step:*',
        'protocol_device:*',
        'file:*',
      ],
    },
    {
      default: true,
      label: 'Can add comments',
      value: ['comment:*'],
    },
  ],
  'Interactive Tables': [
    {
      default: false,
      label: 'Can edit the name',
      value: 'protocol_table:edit:name',
    },
    {
      default: true,
      label: 'Can edit data',
      value: 'protocol_table:edit:data',
    },
  ],
  'Data Fields': [
    {
      default: false,
      label: 'Can add new data fields',
      value: ['metadata:create', 'metadata:edit'],
    },
    {
      default: false,
      label: 'Can remove data fields',
      value: 'metadata:delete',
    },
    // { default: false,label: 'Can edit data field settings', value: '' },
    {
      default: true,
      label: 'Can edit data values',
      value: [
        'metadata:edit:number',
        'metadata:edit:unit',
        'metadata:edit:value',
        'metadata:edit:date',
        'metadata:edit:molecule',
        'metadata:edit:sequence',
        'metadata:edit:file',
        'metadata:edit:file_id',
        'file:edit:metadata_id',
        'file:edit:metadata',
        'file:edit:annotated_file',
        'file:edit:annotated_file_id',
        'file:edit:image_annotation_data',
        'metadata:add_remove',
        'file:add_remove',
        'metadata:edit:options',
        'metadata:edit:device_data',
        'molecule:edit',
        'sequence:edit',
      ],
    },
  ],
  'Inventory Fields': [
    {
      default: false,
      label: 'Can add new inventory fields',
      value: ['protocol_value:create', 'protocol_value:edit'],
    },
    {
      default: false,
      label: 'Can remove inventory fields',
      value: 'protocol_value:delete',
    },
    // { default: false,label: 'Can edit inventory field settings', value: '' },
    {
      default: false,
      label: 'Can edit the field name',
      value: 'protocol_value:edit:label',
    },
    {
      default: true,
      label: 'Can edit amount used',
      value: ['protocol_value:edit:amount'],
    },
    {
      default: true,
      label: 'Can edit the unit of the inventory field',
      value: ['protocol_value:edit:unit'],
    },
    {
      default: true,
      label: 'Can select items',
      value: [
        'protocol_value:edit:resource',
        'protocol_value:edit:resource_item',
        'protocol_value:edit:resource_item_output',
      ],
    },
  ],
  Timers: [
    {
      default: false,
      label: 'Can add timers',
      value: 'protocol_timer:create',
    },
    {
      default: false,
      label: 'Can edit timers',
      value: [
        'protocol_timer:edit:name',
        'protocol_timer:edit:hours',
        'protocol_timer:edit:minutes',
        'protocol_timer:edit:seconds',
      ],
    },
    {
      default: true,
      label: 'Can run timers',
      value: [
        'protocol_timer:edit:paused_at',
        'protocol_timer:edit:ended_at',
      ],
    },
  ],
  Conditions: [
    {
      default: false,
      label: 'Can add conditions',
      value: 'protocol_condition:create',
    },
    {
      default: false,
      label: 'Can remove conditions',
      value: 'protocol_condition:delete',
    },
    // { default: false,label: 'Can add / removing variables', value: '' },
  ],
  Chemistry: [
    {
      default: true,
      label: 'Can edit reaction schemes',
      value: [
        'molecule:edit',
        'chemical:create',
        'chemical:delete',
        'experiment:edit:molecule',
        'protocol_value:add_remove',
        'chemical:add_remove',
      ],
    },
  ],
  'Jupyter Notebooks': [
    {
      default: true,
      label: 'Can run jupyter notebooks',
      value: 'jupyter_instance:create',
    },
    {
      default: false,
      label: 'Can edit jupyter notebooks',
      value: 'jupyter_notebook:edit',
    },
  ],
};
