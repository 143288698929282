/**
 * Labstep
 *
 * @module components/ExperimentWorkflow/Filter/Status/Active
 * @desc Active filter component for status
 */

import { ICONS } from 'labstep-web/constants/icons';
import RemoveLabel from 'labstep-web/core/Label/Remove';
import React from 'react';
import { ExperimentWorkflowFilterStatusActiveProps } from './types';

const ExperimentWorkflowFilterStatusActive: React.FC<
  ExperimentWorkflowFilterStatusActiveProps
> = ({ clearParams, searchParams }) => (
  <>
    {'entity_state_name' in searchParams && (
      <RemoveLabel
        icon={ICONS.experiment_workflow.info.started}
        name={searchParams.entity_state_name}
        onRemoveClick={
          clearParams && (() => clearParams(['entity_state_name']))
        }
      />
    )}
  </>
);

export default ExperimentWorkflowFilterStatusActive;
