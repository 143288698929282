/**
 * Labstep
 *
 * @module components/ResourceLocation/Action/Menu
 * @desc Action Menu for ResourceLocation
 */

import EntityUserActionManage from 'labstep-web/components/EntityUser/Action/Manage';
import ResourceLocationActionCreateSub from 'labstep-web/components/ResourceLocation/Action/CreateSub';
import ResourceLocationActionDelete from 'labstep-web/components/ResourceLocation/Action/Delete';
import ResourceLocationActionEdit from 'labstep-web/components/ResourceLocation/Action/Edit';
import ResourceLocationActionStockCheck from 'labstep-web/components/ResourceLocation/Action/StockCheck';
import ResourceLocationActionUpdateOuter from 'labstep-web/components/ResourceLocation/Action/UpdateOuter';
import ResourceLocationActionView from 'labstep-web/components/ResourceLocation/Action/View';
import ActionMenu from 'labstep-web/core/Action/Menu';
import React from 'react';
import { Action, IResourceLocationActionMenuProps } from './types';

const ResourceLocationActionMenu: React.FC<
  IResourceLocationActionMenuProps
> = ({ resourceLocation, type, actions, options }) => (
  <ActionMenu type={type}>
    {actions.includes(Action.create_sub) && (
      <ResourceLocationActionCreateSub
        actionComponentProps={{ type: 'option' }}
        resourceLocation={resourceLocation}
      />
    )}
    {actions.includes(Action.view) && (
      <ResourceLocationActionView
        resourceLocation={resourceLocation}
      />
    )}
    {actions.includes(Action.edit) && (
      <ResourceLocationActionEdit
        resourceLocation={resourceLocation}
      />
    )}
    {actions.includes(Action.update_outer) && (
      <ResourceLocationActionUpdateOuter
        resourceLocation={resourceLocation}
      />
    )}
    {actions.includes(Action.assign) && (
      <EntityUserActionManage entity={resourceLocation} />
    )}
    {actions.indexOf(Action.delete) > -1 && (
      <ResourceLocationActionDelete
        resourceLocation={resourceLocation}
        options={options}
      />
    )}
    {actions.includes(Action.stock_check) && (
      <ResourceLocationActionStockCheck
        resourceLocation={resourceLocation}
      />
    )}
  </ActionMenu>
);

ResourceLocationActionMenu.defaultProps = {
  actions: [],
  type: 'icon',
};

export default ResourceLocationActionMenu;
