/**
 * Labstep
 *
 * @module components/Metadata/Action/Create/Sequence
 * @desc Action to create a metadata sequence
 */

import { withActiveEditModal } from 'labstep-web/containers/ActiveEditModal';
import UpdateOrCreateContainer from 'labstep-web/containers/UpdateOrCreate';
import ActionComponent from 'labstep-web/core/Action/Component';
import { Metadata } from 'labstep-web/models/metadata';
import { Sequence } from 'labstep-web/models/sequence.model';
import React from 'react';
import { MetadataActionCreateSequenceProps } from './types';

export const MetadataActionCreateSequence: React.FC<
  MetadataActionCreateSequenceProps
> = ({
  metadata,
  setActiveEditModal,
  createProps,
  options,
  actionComponentProps,
}) => (
  <UpdateOrCreateContainer
    createProps={createProps}
    entityName={metadata.entityName}
    updateProps={{ id: metadata.id }}
    // This is needed because the sequence has not yet been created
    optimistic={false}
  >
    {({ action, status }): React.ReactElement => (
      <ActionComponent
        dataTestId="metadata-action-create-sequence"
        type="text"
        text="Create sequence"
        status={status}
        elementProps={{
          placeholder: true,
        }}
        onClick={(): void => {
          action(
            {
              sequence: Sequence.createBodyDefault,
            },
            {
              onSuccess: ({ response }) => {
                if (!response) {
                  return;
                }
                setActiveEditModal({
                  entityName: Sequence.entityName,
                  id: (
                    response.entities.metadata[
                      response.result as number
                    ] as Metadata
                  ).sequence,
                });
              },
              ...options,
            },
          );
        }}
        {...actionComponentProps}
      />
    )}
  </UpdateOrCreateContainer>
);

export default withActiveEditModal(MetadataActionCreateSequence);
