/**
 * Labstep
 *
 * @module components/EntityStateWorkflow/Modal/RolesAndSignatures
 * @desc Roles and signatures settings for the entity state workflow modal
 */

import EntityUserRoleRequirementActionCreate from 'labstep-web/components/EntityUserRoleRequirement/Action/Create';
import EntityUserRoleRequirementsTable from 'labstep-web/components/EntityUserRoleRequirement/Table';
import TextPlaceholder from 'labstep-web/core/Text/Placeholder';
import React from 'react';
import { EntityStateWorkflowModalRolesAndSignaturesProps } from './types';

const EntityStateWorkflowModalRolesAndSignatures: React.FC<
  EntityStateWorkflowModalRolesAndSignaturesProps
> = ({ entityState, entityStateWorkflow }) => {
  return (
    <div data-testid="entity-state-workflow-modal-roles">
      <h3>Role Requirements</h3>
      <div>
        Here you can define any roles that need to be assigned at this
        stage and if they are required to sign.
      </div>
      <div>
        <TextPlaceholder>
          i.e Assignee, Safety Officer, Reviewer, Witness, Approver
          etc.
        </TextPlaceholder>
      </div>
      <br />
      {entityState.entity_user_role_requirements.length > 0 ? (
        <EntityUserRoleRequirementsTable
          entityStateWorkflow={entityStateWorkflow}
          entityUserRoleRequirements={
            entityState.entity_user_role_requirements
          }
          entityState={entityState}
        />
      ) : (
        <>
          <p>No Role Requirements Set</p>
          <br />
          <EntityUserRoleRequirementActionCreate
            entityState={entityState}
          />
        </>
      )}
    </div>
  );
};

export default EntityStateWorkflowModalRolesAndSignatures;
