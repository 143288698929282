/**
 * Labstep
 *
 * @module components/DeviceBooking/TimeGrid
 * @desc TimeGrid for Device booking
 */

import { withActiveEditModal } from 'labstep-web/containers/ActiveEditModal';
import { EntityUpdateAnyContainer } from 'labstep-web/containers/Entity/Update/Any';
import ModalExpandable from 'labstep-web/core/Modal/Expandable';
import { SearchHOC } from 'labstep-web/hoc/Search';
import { DeviceBooking } from 'labstep-web/models/device-booking.model';
import React from 'react';
import DeviceBookingTimeGrid from './Component';
import { DeviceBookingTimeGridContainerProps } from './types';

export const DeviceBookingTimeGridContainer: React.FC<
  DeviceBookingTimeGridContainerProps
> = ({ device, setActiveEditModal }) => (
  <SearchHOC
    entityName={DeviceBooking.entityName}
    params={{
      device_id: device.id,
      count: 100,
    }}
  >
    {({
      entities: deviceBookings,
      status,
      setParams,
      searchParams,
    }) => (
      <EntityUpdateAnyContainer entityName={DeviceBooking.entityName}>
        {({ statuses: updateStatuses, update }) => (
          <ModalExpandable
            icon="calendar alternate"
            name="Booking"
            content={({ expanded }) => (
              <DeviceBookingTimeGrid
                device={device}
                deviceBookings={deviceBookings}
                status={status}
                updateStatuses={updateStatuses}
                update={update}
                setActiveEditModal={setActiveEditModal}
                setParams={setParams}
                searchParams={searchParams}
                expanded={expanded}
              />
            )}
          />
        )}
      </EntityUpdateAnyContainer>
    )}
  </SearchHOC>
);

export default withActiveEditModal(DeviceBookingTimeGridContainer);
