/**
 * Labstep
 *
 * @module prosemirror/marks/strong
 * @desc Strong mark
 */

import { LabstepMark } from 'labstep-web/prosemirror/marks/types';
import { toggleMark } from 'prosemirror-commands';

const MarkStrong: LabstepMark = {
  key: 'strong',
  spec: {
    parseDOM: [
      { tag: 'strong' },
      // This works around a Google Docs misbehavior where
      // pasted content will be inexplicably wrapped in `<b>`
      // tags with a font-weight normal.
      {
        tag: 'b',
        getAttrs: (node) => {
          if (node instanceof HTMLElement) {
            return node.style.fontWeight !== 'normal' && null;
          }
          return null;
        },
      },
      {
        style: 'font-weight',
        getAttrs: (value) => {
          if (value instanceof Node) {
            return null;
          }
          return /^(bold(er)?|[5-9]\d{2,})$/.test(value) && null;
        },
      },
    ],
    toDOM() {
      return ['strong', 0];
    },
  },
  keymap: {
    'Mod-b': (state, dispatch) =>
      toggleMark(state.schema.marks[MarkStrong.key], {})(
        state,
        dispatch,
      ),
  },
  toolbarIcon: 'bold',
};

export default MarkStrong;
