/**
 * Labstep
 *
 * @module services/grid-validation
 * @desc AGGrid Validation Service
 */

import { ICellRendererParams, RowNode } from 'ag-grid-community';
import { formatISO, isMatch, parse, set } from 'date-fns';
import { ImportableEntity } from 'labstep-web/models';
import { CustomIdentifierSet } from 'labstep-web/models/custom-identifier-set.model';
import { Device } from 'labstep-web/models/device.model';
import {
  ENTITY_IMPORT_DEFAULT_DATE_FORMAT,
  EntityImportColDef,
  EntityImportDataRowData,
  EntityImportDataRowDataRow,
  ParamsValidation,
} from 'labstep-web/models/entity-import.model';
import {
  MetadataOptions,
  MetadataType,
} from 'labstep-web/models/metadata/types';
import { Resource } from 'labstep-web/models/resource.model';
import {
  convertAmountUnitToAmount,
  convertAmountUnitToUnit,
} from 'labstep-web/services/amount-unit.service';

export class GridEntityImportValidationService {
  public static isNodeInvalid(
    node: RowNode,
    data?: EntityImportDataRowData,
    columnDefs?: EntityImportColDef[],
    template?: ImportableEntity,
  ): boolean {
    if (
      GridEntityImportValidationService.isRowEmpty(
        node.data as EntityImportDataRowDataRow,
      )
    ) {
      return false;
    }
    let isRowValid = GridEntityImportValidationService.isRowValid(
      node.data as EntityImportDataRowDataRow,
      columnDefs,
    );

    let customIdentifierSet: CustomIdentifierSet | null = null;
    if (template instanceof Resource) {
      customIdentifierSet = template.custom_identifier_set;
    }
    if (isRowValid && node.data && customIdentifierSet) {
      const customIdentifier =
        node.data.resource_identifier ||
        node.data.resource_item_resource_identifier;
      const { hasDuplicates, hasEmptyIdentifiers, hasInvalidPrefix } =
        GridEntityImportValidationService.validateCustomIdentifier(
          customIdentifierSet,
          customIdentifier,
          undefined,
          data,
        );

      if (hasDuplicates || hasEmptyIdentifiers || hasInvalidPrefix) {
        isRowValid = false;
      }
    }

    return !isRowValid;
  }

  public static isRowValid(
    row: EntityImportDataRowDataRow,
    columnDefs?: EntityImportColDef[],
  ): boolean {
    const rowIsEmpty =
      GridEntityImportValidationService.isRowEmpty(row);
    if (rowIsEmpty) {
      return true;
    }

    const rowIsInvalid = columnDefs?.some(
      (columnDef) =>
        !this.isCellValid({
          colDef: columnDef,
          value: row[columnDef.colId!],
        }),
    );

    return !rowIsInvalid;
  }

  public static isRowEmpty(row: EntityImportDataRowDataRow): boolean {
    return (
      Object.keys(row).filter(
        (key: string) => this.parseValue(row[key]) === '',
      ).length === Object.keys(row).length
    );
  }

  public static isCellValid(params: ParamsValidation): boolean {
    const paramsParsed = {
      ...params,
      value: this.parseValue(params.value),
    };
    const { colDef } = paramsParsed;
    if (colDef.isRequired && !this.validateIsRequired(paramsParsed)) {
      return false;
    }
    if (this.isValueEmpty(paramsParsed.value)) {
      return true;
    }
    if (colDef.validator) {
      return colDef.validator(paramsParsed);
    }
    return true;
  }

  public static validateIsRequired = (params: ParamsValidation) => {
    const { value } = params;
    return !GridEntityImportValidationService.isValueEmpty(value);
  };

  public static validatorMetadata = (
    params: ParamsValidation,
    metadataType: MetadataType,
  ) => {
    if (metadataType === MetadataType.date) {
      return this.validateDate(params);
    }

    if (metadataType === MetadataType.datetime) {
      return this.validateDatetime(params);
    }

    if (metadataType === MetadataType.numeric) {
      return this.validateNumeric(params);
    }

    if (metadataType === MetadataType.options) {
      return this.validateOptions(params);
    }

    return true;
  };

  public static isReservedIdentifier = (
    Identifier: string,
    customIdentifierSet: CustomIdentifierSet,
  ) => {
    const { prefix } = customIdentifierSet.settings;
    const number = customIdentifierSet.number_auto_increment + 1;
    const numberPart = parseInt(Identifier.slice(prefix.length), 10);
    if (!Number.isNaN(numberPart) && numberPart < number) {
      return true;
    }
    return false;
  };

  public static validateCustomIdentifier = (
    customIdentifierSet: CustomIdentifierSet,
    customIdentifier?: string,
    params?: ICellRendererParams,
    data?: EntityImportDataRowData,
  ): {
    hasInvalidPrefix: boolean;
    hasEmptyIdentifiers: boolean;
    hasDuplicates: boolean;
    isReservedIdentifier: boolean;
  } => {
    const validation = {
      hasInvalidPrefix: false,
      hasEmptyIdentifiers: false,
      hasDuplicates: false,
      isReservedIdentifier: false,
    };

    const { prefix } = customIdentifierSet.settings;

    if (!customIdentifier || customIdentifier.trim() === '') {
      validation.hasEmptyIdentifiers = true;
    } else if (
      !customIdentifier.startsWith(prefix) ||
      Number.isNaN(
        parseInt(customIdentifier.slice(prefix.length), 10),
      )
    ) {
      validation.hasInvalidPrefix = true;
    } else {
      const rowData: EntityImportDataRowData = data || [];
      if (params) {
        params.api.forEachNode((rowNode) => {
          if (rowNode.data) {
            rowData.push(rowNode.data);
          }
        });
      }

      const identifiers: string[] = [];
      const resourceNames: Set<string> = new Set();
      rowData.forEach((row) => {
        if (
          row.resource_identifier &&
          row.resource_identifier === customIdentifier
        ) {
          identifiers.push(row.resource_identifier);
        } else if (
          row.resource_item_resource_identifier &&
          row.resource_item_resource_identifier === customIdentifier
        ) {
          resourceNames.add(row.resource_item_resource || '');
        }
      });
      if (
        identifiers.filter((val) => val === customIdentifier).length >
          1 ||
        resourceNames.size > 1
      ) {
        validation.hasDuplicates = true;
      } else {
        validation.isReservedIdentifier = this.isReservedIdentifier(
          customIdentifier,
          customIdentifierSet,
        );
      }
    }

    return validation;
  };

  public static parseValue = (value: string | null) => {
    if (!value) {
      return '';
    }
    if (typeof value === 'string') {
      return value.trim();
    }
    return value;
  };

  public static isValueEmpty = (value: string) =>
    value === '' || !value;

  public static validateNumeric = (
    params: ParamsValidation,
  ): boolean => {
    const { value } = params;
    const number = convertAmountUnitToAmount(value);
    const unit = convertAmountUnitToUnit(value);
    return !!(number || unit);
  };

  public static validateNumber = (params: ParamsValidation) => {
    const { value } = params;
    return !Number.isNaN(Number(value));
  };

  public static validateDate = (
    params: ParamsValidation,
  ): boolean => {
    const { value } = params;
    return isMatch(
      value,
      GridEntityImportValidationService.getDateFormat(
        params.colDef,
        'date',
      ),
    );
  };

  public static validateDatetime = (
    params: ParamsValidation,
  ): boolean => {
    const { value } = params;
    return isMatch(
      value,
      GridEntityImportValidationService.getDateFormat(
        params.colDef,
        'datetime',
      ),
    );
  };

  public static validateOptions = (
    params: ParamsValidation,
  ): boolean => {
    const { value } = params;
    const metadataOptions =
      GridEntityImportValidationService.getMetadataOptions(params);
    if (!metadataOptions) {
      return false;
    }
    if (metadataOptions.is_allow_add) {
      return true;
    }

    const supportedValues = Object.keys(metadataOptions.values);
    const selectedValues: string[] = value
      .split(',')
      .map((subValue: string) => subValue.trim());

    if (
      !metadataOptions.is_allow_multiple &&
      selectedValues.length > 1
    ) {
      return false;
    }

    return selectedValues.every((subValue) =>
      supportedValues.includes(subValue),
    );
  };

  public static getMetadataOptions(
    params: ParamsValidation,
  ): MetadataOptions | null {
    const colDef = params.colDef as EntityImportColDef;
    if (
      colDef.entity_import?.metadata &&
      colDef.entity_import?.metadata.type === MetadataType.options
    ) {
      return colDef.entity_import?.metadata?.options || null;
    }
    return null;
  }

  public static getDateFormat(
    colDef: EntityImportColDef,
    type: 'date' | 'datetime',
  ): string {
    const defaultDateFormat = ENTITY_IMPORT_DEFAULT_DATE_FORMAT[type];
    return colDef?.entity_import?.date_format
      ? colDef.entity_import.date_format
      : defaultDateFormat;
  }

  public static formatDateISO(
    value: string,
    colDef: EntityImportColDef,
    type: 'date' | 'datetime',
  ): string {
    let date = parse(
      value,
      GridEntityImportValidationService.getDateFormat(colDef, type),
      new Date(),
    );
    if (type === 'date') {
      date = set(date, {
        hours: 12,
        minutes: 0,
        seconds: 0,
        milliseconds: 0,
      });
    }
    return formatISO(date);
  }

  public static getReservedIdentifiers(
    data: EntityImportDataRowData,
    template: Resource | Device,
  ): string[] {
    const reservedIdentifiers: string[] = [];
    let customIdentifierSet: CustomIdentifierSet | null = null;
    if (template instanceof Resource) {
      customIdentifierSet = template.custom_identifier_set;
    }

    if (data.length > 0 && customIdentifierSet) {
      data.forEach((row) => {
        if (
          row.resource_identifier &&
          this.isReservedIdentifier(
            row.resource_identifier,
            customIdentifierSet,
          )
        ) {
          reservedIdentifiers.push(row.resource_identifier);
        }
        if (
          row.resource_item_resource_identifier &&
          this.isReservedIdentifier(
            row.resource_item_resource_identifier,
            customIdentifierSet,
          )
        ) {
          reservedIdentifiers.push(
            row.resource_item_resource_identifier,
          );
        }
      });
    }
    return reservedIdentifiers;
  }
}
