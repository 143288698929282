/**
 * Labstep
 */

import React, { useContext } from 'react';
import EntitySearchContext from '.';
import { EntitySearchContextType } from './types';

export const useEntitySearchContext = (): EntitySearchContextType => {
  const context = useContext(EntitySearchContext);
  if (!context) {
    throw new Error(
      'useEntitySearchContext must be used within a EntitySearchContextProvider',
    );
  }
  return context;
};
