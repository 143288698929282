/**
 * Labstep
 *
 * @module components/Comment/Action/Edit
 * @desc Comment Action Edit
 */

import DataTableActionEdit from 'labstep-web/components/DataTable/Action/Edit';
import EntityActionEdit from 'labstep-web/components/Entity/Action/Edit';
import MoleculeActionEdit from 'labstep-web/components/Molecule/Action/Edit';
import RichTextActionEdit from 'labstep-web/components/RichText/Action/Edit';
import SequenceActionEdit from 'labstep-web/components/Sequence/Action/Edit';
import React from 'react';
import { CommentActionEditProps } from './types';

const CommentActionEdit: React.FC<CommentActionEditProps> = ({
  toggle,
  comment,
}) => {
  let text = 'Edit';
  if (
    (comment.files && comment.files.length > 0) ||
    comment.rich_text
  ) {
    text = comment.body ? 'Edit caption' : 'Add caption';
  }

  let edit = (
    <EntityActionEdit
      entity={comment}
      actionComponentProps={{
        type: 'option',
        onClick: toggle,
        text,
      }}
    />
  );
  if (comment.data_table) {
    edit = <DataTableActionEdit comment={comment} />;
  } else if (comment.rich_text) {
    edit = <RichTextActionEdit richText={comment.rich_text} />;
  } else if (comment.molecule) {
    edit = <MoleculeActionEdit molecule={comment.molecule} />;
  } else if (comment.sequence) {
    edit = <SequenceActionEdit sequence={comment.sequence} />;
  }

  return edit;
};

export default CommentActionEdit;
