/**
 * Labstep
 *
 * @module components/EntityView/Card/EmptyState
 * @desc Empty state when no entity view in index
 */

import EmptyState from 'labstep-web/core/Card/EmptyState';
import { EntityView } from 'labstep-web/models/entity-view.model';
import { ProtocolCollection } from 'labstep-web/models/protocol-collection.model';
import React from 'react';
import { EntityViewEmptyStateProps } from './types';

const explanation = `
Use ${EntityView.getHumanReadableEntityName(
  true,
)} to document your activities in the lab. Keep all your results and data linked to the ${ProtocolCollection.getHumanReadableEntityName(
  true,
)} that produced them. Track the specific inventory items and amounts used in each ${ProtocolCollection.getHumanReadableEntityName()}.
`;

export const EntityViewCardEmptyState: React.FC<
  EntityViewEmptyStateProps
> = (props) => (
  <EmptyState
    title={`No ${EntityView.getHumanReadableEntityName(true, true)}`}
    explanation={explanation}
    src="/img/empty-state/experiments.svg"
    {...props}
  />
);

export default EntityViewCardEmptyState;
