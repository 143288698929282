/**
 * Labstep
 *
 * @module components/Protocol/Action/AddToExperiment
 * @desc Add a Protocol to an ExperimentWorkflow
 */

import Can from 'labstep-web/components/Entity/Can';
import { Action } from 'labstep-web/components/Entity/Can/types';
import { withActiveGroup } from 'labstep-web/containers/ActiveGroup';
import { EntityCreateContainer } from 'labstep-web/containers/Entity/Create';
import ActionComponent from 'labstep-web/core/Action/Component';
import ReusableForm from 'labstep-web/core/Form/Reusable';
import {
  FieldType,
  FieldWithoutActionProps,
} from 'labstep-web/core/Form/Reusable/types';
import ModalDefault from 'labstep-web/core/Modal/Default';
import { ExperimentWorkflow } from 'labstep-web/models/experiment-workflow.model';
import { getHumanReadableEntityName } from 'labstep-web/services/i18n.service';
import { navigation } from 'labstep-web/services/navigation';
import React from 'react';
import { withRouter } from 'react-router';
import { object } from 'yup';
import {
  IAddToExperimentActionContainerProps,
  IAddToExperimentActionProps,
} from './types';

export const fieldExperimentWorkflow: FieldWithoutActionProps = {
  name: 'experiment_workflow',
  fieldType: FieldType.SearchSelect,
  validation: object().required(),
  elementProps: {
    entityName: 'experiment_workflow',
    creatable: true,
    params: { is_locked: false },
  },
};

export const AddToExperimentAction: React.FC<
  IAddToExperimentActionProps
> = ({ protocol, create, status, history }) => (
  <ModalDefault
    id="add-to-experiment"
    header="Add to Experiment"
    viewComponent={({ toggleModal }) => (
      <ActionComponent
        type="option"
        onClick={toggleModal}
        text={`Add to ${getHumanReadableEntityName(
          ExperimentWorkflow.entityName,
        )}`}
        icon="plus"
      />
    )}
    content={() => (
      <ReusableForm
        submitButtonText="Add"
        fields={[fieldExperimentWorkflow]}
        onSubmit={(values) => {
          create(
            {
              protocol_id: protocol.id,
              ...values,
            },
            {
              onSuccess: ({ response }) => {
                history.push(
                  navigation.get('experiment_show', {
                    id: response.result,
                  }),
                );
              },
            },
          );
        }}
        status={status}
      />
    )}
  />
);

export const AddToExperimentActionContainer: React.FC<
  IAddToExperimentActionContainerProps
> = ({ protocol, activeGroup, setActiveGroupId, ...props }) =>
  activeGroup && !protocol.is_draft ? (
    <Can
      entityName="group"
      id={activeGroup.id}
      action={Action.experiment_workflow_edit}
    >
      <EntityCreateContainer
        entityName="experiment"
        showLoaderWhileCreating
      >
        {({ create, status }) => (
          <AddToExperimentAction
            protocol={protocol}
            create={create}
            status={status}
            {...props}
          />
        )}
      </EntityCreateContainer>
    </Can>
  ) : null;

export default withActiveGroup(
  withRouter(AddToExperimentActionContainer),
);
