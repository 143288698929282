/**
 * Labstep
 */

import { FieldWithoutActionProps } from 'labstep-web/core/Form/Reusable/types';
import { capitalize } from 'labstep-web/services/i18n.service';
import { FieldType } from '../../types';
import { fieldTypes } from '../constants';
import { IFormReusableFieldsProps } from '../types';

export const getFieldType = (
  type: Exclude<FieldType, FieldType.action>,
): (typeof fieldTypes)[Exclude<FieldType, FieldType.action>] => {
  return fieldTypes[type];
};

export const getErrorMessage = (
  errors: IFormReusableFieldsProps['errors'],
  field: FieldWithoutActionProps,
): string | undefined => {
  const error = errors[field.name];

  if (!error) {
    return undefined;
  }
  if (error?.message?.includes('must be a `number`')) {
    return `${capitalize(
      field.fieldLabel || field.name,
    )} must be a number`;
  }

  if (error?.message) {
    const humanReadableFieldName = capitalize(
      field.fieldLabel || field.name.replaceAll('_', ' '),
    );
    return `${humanReadableFieldName} ${error.message.slice(
      error.message.indexOf(' '),
    )}`;
  }
  return error.message;
};
