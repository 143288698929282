/**
 * Labstep
 *
 * @module screens/ShareLinkInvitation/List
 * @desc ShareLinkInvitation List
 */

import ShareLinkActionDelete from 'labstep-web/components/ShareLink/Action/Delete';
import TableSimple from 'labstep-web/core/Table/Simple';
import { ReadOnMountHOC } from 'labstep-web/hoc/ReadOnMount';
import { ShareLinkInvitation } from 'labstep-web/models/share-link-invitation.model';
import React from 'react';
import {
  ChildrenProps,
  IShareLinkInvitationListContainerProps,
  IShareLinkInvitationListProps,
} from './types';

export const ShareLinkInvitationList: React.FC<
  IShareLinkInvitationListProps
> = ({ shareLinkInvitations }) => {
  const invitations = shareLinkInvitations.filter(
    (shareLinkInvitation) =>
      !shareLinkInvitation.share_link.deleted_at,
  );
  return (
    <div id="sharelink-invitation-list">
      {invitations.length > 0 ? (
        <TableSimple
          hideHeader
          rows={invitations}
          columns={[
            {
              header: '',
              content: (shareLinkInvitation: ShareLinkInvitation) =>
                shareLinkInvitation.email,
              cellProps: { width: 15 },
            },
            {
              header: '',
              cellProps: { style: { width: 50, textAlign: 'right' } },
              content: (shareLinkInvitation: ShareLinkInvitation) => (
                <ShareLinkActionDelete
                  shareLink={shareLinkInvitation.share_link}
                  humanReadableName="invitation"
                  actionComponentProps={{
                    type: 'icon',
                    icon: 'times',
                  }}
                />
              ),
            },
          ]}
        />
      ) : (
        'No invites pending.'
      )}
    </div>
  );
};

export const ShareLinkInvitationListContainer: React.FC<
  IShareLinkInvitationListContainerProps
> = ({ entity }) => (
  <ReadOnMountHOC
    type="cursor"
    entityName={ShareLinkInvitation.entityName}
    params={{
      [`${entity.entityName}_id`]: entity.id,
      share_link_is_deleted: false,
    }}
  >
    {({ entities: shareLinkInvitations }: ChildrenProps) => (
      <ShareLinkInvitationList
        shareLinkInvitations={shareLinkInvitations}
      />
    )}
  </ReadOnMountHOC>
);

export default ShareLinkInvitationListContainer;
