/**
 * Labstep
 *
 * @module components/ResourceItemExport/Table/CustomLabel
 * @desc Custom label for resource item export table
 */

import ReusableForm from 'labstep-web/core/Form/Reusable';
import {
  FieldType,
  FieldWithoutActionProps,
} from 'labstep-web/core/Form/Reusable/types';
import { ShowEditToggleComponent } from 'labstep-web/core/Form/ShowEdit';
import React from 'react';
import { string } from 'yup';
import styles from './styles.module.scss';
import { IResourceItemExportTableCustomLabelProps } from './types';

export const fieldCustom: FieldWithoutActionProps = {
  fieldType: FieldType.Text,
  fieldLabel: undefined,
  name: 'value',
  validation: string(),
  placeholder: 'Field name',
};

export const ResourceItemExportTableCustomLabel: React.FC<
  IResourceItemExportTableCustomLabelProps
> = ({ onSubmit, ...props }) => (
  <div className={styles.customLabel}>
    <ShowEditToggleComponent
      hasValue={false}
      children={{}}
      canEdit
      toggledComponent={({ toggle }) => (
        <ReusableForm
          fields={[fieldCustom]}
          submitButton={false}
          blurToggle={toggle}
          autoFocus
          onSubmit={(values) => {
            if (values.value) {
              onSubmit(values.value);
            }
            toggle();
          }}
        />
      )}
      {...props}
    />
  </div>
);

export default ResourceItemExportTableCustomLabel;
