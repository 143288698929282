/**
 * Labstep
 *
 * @module prosemirror
 * @desc ProseMirror main entry point
 */

import { getNodeViews } from 'labstep-web/prosemirror/components/NodeView';
import ReactNodeViewPortalsProvider, {
  ReactNodeViewPortals,
  useReactNodeViewPortals,
} from 'labstep-web/prosemirror/components/ReactNodeViewPortals';
import { getInitialState } from 'labstep-web/prosemirror/state/initial-state';
import { EditorView } from 'prosemirror-view';
import React, { useCallback, useEffect, useRef } from 'react';
import { StepsOrderContextProvider } from '../context';
import styles from './styles.module.scss';
import {
  IProseMirrorReadOnlyContainerProps,
  IProseMirrorReadOnlyProps,
} from './types';

export const ProseMirrorEditorReadOnly: React.FC<
  IProseMirrorReadOnlyProps
> = ({ initialState, entity, timestamp, portals }) => {
  const { createPortal } = useReactNodeViewPortals();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleCreatePortal = useCallback(createPortal, []);

  const editorViewRef = useRef(null);

  const createEditorView = useCallback((editorViewDOM) => {
    // eslint-disable-next-line no-new
    new EditorView(editorViewDOM, {
      state: getInitialState(initialState, {
        entity,
        handleCreatePortal,
        premiumFeatures: [],
      }),
      nodeViews: getNodeViews(entity, handleCreatePortal, timestamp),
      editable: (): false => false,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const editorViewDOM = editorViewRef.current;
    if (editorViewDOM) {
      createEditorView(editorViewDOM);
    }
  }, [createEditorView]);

  return (
    <div className={styles.prosemirrorStyles}>
      <div ref={editorViewRef} />
      <ReactNodeViewPortals portals={portals} />
    </div>
  );
};

const ProseMirrorContainer: React.FC<
  IProseMirrorReadOnlyContainerProps
> = (props) => (
  <StepsOrderContextProvider
    initialState={props.initialState}
    timestamp={props.timestamp}
  >
    <ReactNodeViewPortalsProvider>
      {({ data }) => (
        <ProseMirrorEditorReadOnly {...props} portals={data} />
      )}
    </ReactNodeViewPortalsProvider>
  </StepsOrderContextProvider>
);

export default ProseMirrorContainer;
