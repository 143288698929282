/**
 * Labstep
 */

import { APICreateAction, APIUpdateAction } from 'labstep-web/models';
import { ExperimentWorkflow } from 'labstep-web/models/experiment-workflow.model';

export const entityToOption = (entity: any) => ({
  label: entity.name,
  value: entity.id,
  description: entity.description ?? '',
  entity,
});

export const handleChange = (
  option: any,
  createEntityStateWorkflow: APICreateAction,
  updateExperimentWorkflow: APIUpdateAction,
  searchText: string,
  experimentWorkflow: ExperimentWorkflow,
  toggle: (props: { entityStateWorkflowId: number }) => void,
) => {
  if (option === null) {
    updateExperimentWorkflow({
      entity_state_workflow_id: null,
    });
    return;
  }
  if (option.value === 'entity') {
    createEntityStateWorkflow(
      {
        name: searchText !== '' ? searchText : 'Untitled Workflow',
        target_entity_class: 'ExperimentWorkflow',
        group_id: experimentWorkflow.owner.id,
      },
      {
        onSuccess: ({ response }) => {
          if (!response) {
            return;
          }
          const id = response.result;
          updateExperimentWorkflow({
            entity_state_workflow_id: id,
          });
          toggle({ entityStateWorkflowId: id as number });
        },
      },
    );
  } else {
    updateExperimentWorkflow({
      entity_state_workflow_id: option.entity.id,
    });
  }
};
