/**
 * Labstep
 */

import Content from 'labstep-web/components/Entity/Action/Update/AllowedActionsLock/Content';
import { ActionComponent } from 'labstep-web/core/Action/Component';
import ModalDefault from 'labstep-web/core/Modal/Default';
import { getHumanReadableEntityName } from 'labstep-web/services/i18n.service';
import React from 'react';
import { IEntityActionUpdateAllowedActionsLockTableProps } from './types';

export const EntityActionUpdateAllowedActionsLock: React.FC<
  IEntityActionUpdateAllowedActionsLockTableProps
> = ({ entity }) => (
  <ModalDefault
    size="large"
    viewComponent={({ toggleModal }) => (
      <ActionComponent
        dataCy="components/Entity/Action/Update/AllowedActionsLock:action"
        type="option"
        onClick={toggleModal}
        text="Settings"
        icon="cog"
      />
    )}
    showDone
    content={<Content entity={entity} />}
    header={`${getHumanReadableEntityName(
      entity.entityName,
      false,
      true,
    )} Settings`}
  />
);

export default EntityActionUpdateAllowedActionsLock;
