/**
 * Labstep
 *
 * @module components/Group/Form/ShowEdit/CustomIdentifierSet/ResourceTemplate
 * @desc Action to set CustomIdentifierSet for a ResourceTemplate
 */

import CustomIdentifierSetActionCreate from 'labstep-web/components/CustomIdentifierSet/Action/Create';
import CustomIdentifierSetPreview from 'labstep-web/components/CustomIdentifierSet/Preview';
import { useHasAccessCreate } from 'labstep-web/components/Entity/Can';
import { EntityUpdateContainer } from 'labstep-web/containers/Entity/Update';
import TextPlaceholder from 'labstep-web/core/Text/Placeholder';
import { CustomIdentifierSet } from 'labstep-web/models/custom-identifier-set.model';
import React from 'react';
import { GroupFormShowEditResourceCustomIdentifierSetProps } from './types';

export const GroupFormShowEditResourceCustomIdentifierSet: React.FC<
  GroupFormShowEditResourceCustomIdentifierSetProps
> = ({ group, resource }) => {
  const canCreate = useHasAccessCreate(
    CustomIdentifierSet.entityName,
    resource.permission_entity_info?.entityName,
    resource.permission_entity_info?.id,
  ) as boolean;

  if (resource.custom_identifier_set) {
    return (
      <CustomIdentifierSetPreview
        group={group}
        customIdentifierSet={resource.custom_identifier_set}
        canCreate={canCreate}
      />
    );
  }
  if (canCreate) {
    return (
      <EntityUpdateContainer
        entityName={resource.entityName}
        id={resource.id}
      >
        {({ update }) => (
          <CustomIdentifierSetActionCreate
            groupId={group.id}
            parentClass={resource.entityName}
            onSuccess={update}
          />
        )}
      </EntityUpdateContainer>
    );
  }
  return <TextPlaceholder>No Custom Identifier</TextPlaceholder>;
};

export default GroupFormShowEditResourceCustomIdentifierSet;
