/**
 * Labstep
 */

import {
  type PayloadAction,
  type SliceCaseReducers,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit';
import { Group } from 'labstep-web/models/group.model';
import { selectEntity } from 'labstep-web/state/selectors/entity';
import { LabstepReduxState } from 'labstep-web/state/types';
import { ActiveGroupIdState } from './types';

const initialState: ActiveGroupIdState = null;

export const activeGroupIdSlice = createSlice<
  ActiveGroupIdState,
  SliceCaseReducers<ActiveGroupIdState>
>({
  name: 'activeGroupId',
  initialState,
  reducers: {
    setActiveGroupId: (
      state,
      action: PayloadAction<ActiveGroupIdState>,
    ) => {
      return action.payload;
    },
  },
});

export const selectActiveGroupId = (state: any) =>
  state.activeGroupId;

export const selectActiveGroup = createSelector<
  readonly ((state: LabstepReduxState) => Group)[],
  Group | null
>(
  (state) => {
    const id = selectActiveGroupId(state);
    return id ? selectEntity(state, 'group', id) : null;
  },
  (group) => group,
);
export const { setActiveGroupId } = activeGroupIdSlice.actions;

export default activeGroupIdSlice.reducer;
