/**
 * Labstep
 *
 * @module components/EntityView/Action/Delete
 * @desc Action to delete EntityView
 */

import ActionDelete from 'labstep-web/components/Entity/Action/Delete';
import { navigation } from 'labstep-web/services/navigation';
import React from 'react';
import { withRouter } from 'react-router';
import { EntityViewActionDeleteProps } from './types';

export const EntityViewActionDelete: React.FC<
  EntityViewActionDeleteProps
> = ({ entityView, history }) => (
  <ActionDelete
    options={{
      onSuccess: (): void => {
        history.push(navigation.get('entity_view_index'));
      },
    }}
    entity={entityView}
  />
);

export default withRouter(EntityViewActionDelete);
