/**
 * Labstep
 *
 * @module components/NotificationAlert/Action/Delete
 * @desc Action to delete
 */

import EntityActionDelete from 'labstep-web/components/Entity/Action/Delete';
import React from 'react';
import { NotificationAlertActionDeleteProps } from './types';

export const NotificationAlertActionDelete: React.FC<
  NotificationAlertActionDeleteProps
> = ({ notificationAlert, actionComponentProps }) => (
  <EntityActionDelete
    entity={notificationAlert}
    actionComponentProps={actionComponentProps}
    skipConfirmation
  />
);

export default NotificationAlertActionDelete;
