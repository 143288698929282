/**
 * Labstep
 *
 * @module components/Layout/LeftPane
 * @desc Layout component for left pane in index pages
 */

import React from 'react';
import LayoutLinks from '../Links';
import styles from './styles.module.scss';
import { LayoutLeftPaneProps } from './types';

export const LayoutLeftPane: React.FC<LayoutLeftPaneProps> = ({
  children,
  links,
}) => (
  <div data-testid="left-pane" className={styles.container}>
    {links && (
      <div className={styles.linksContainer}>
        <LayoutLinks links={links} inverted stretch noBottomMargin />
      </div>
    )}
    <div data-testid="left-pane-panes" className={styles.panes}>
      {children}
    </div>
  </div>
);

export default LayoutLeftPane;
