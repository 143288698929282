/**
 * Labstep
 *
 * @module components/EntityImport/Action/ImportExcel
 * @desc User can select an excel file from its file system and import it into AG Grid
 */

import ActionComponent from 'labstep-web/core/Action/Component';
import Dropzone from 'labstep-web/core/Dropzone';
import { GridEntityImportExcelService } from 'labstep-web/grid/EntityImport/services/grid-entity-import-excel.service';
import React from 'react';
import { EntityImportActionImportExcelProps } from './types';

export const EntityImportActionImportExcel: React.FC<
  EntityImportActionImportExcelProps
> = ({ onImport, targetEntityName, ...rest }) => {
  const gridEntityImportExcelService =
    new GridEntityImportExcelService(onImport);

  return (
    <Dropzone
      accept={['.xlsx', '.csv']}
      onDropAccepted={(files: File[]): void =>
        gridEntityImportExcelService?.importFile(files[0])
      }
      children={
        <ActionComponent
          type="button"
          text="Upload XLSX / CSV"
          elementProps={{
            basic: true,
          }}
        />
      }
      {...rest}
    />
  );
};

export default EntityImportActionImportExcel;
