/**
 * Labstep
 *
 * @module components/Protocol/Action/Menu
 * @desc Action Menu for protocol
 */

import ProtocolActionDuplicate from 'labstep-web/components/Protocol/Action/Duplicate';
import PrintAction from 'labstep-web/components/Protocol/Action/Print';
import RestoreAction from 'labstep-web/components/Protocol/Action/Restore';
import ActionMenu from 'labstep-web/core/Action/Menu';
import React from 'react';
import { IProtocolActionMenuProps } from './types';

export const ProtocolActionMenu: React.FC<
  IProtocolActionMenuProps
> = ({ protocol, actions = [] }) => (
  <ActionMenu type="icon">
    {actions.includes('duplicate') && (
      <ProtocolActionDuplicate protocol={protocol} />
    )}
    {actions.includes('restore') && (
      <RestoreAction protocol={protocol} />
    )}
    {actions.includes('print') && <PrintAction protocol={protocol} />}
  </ActionMenu>
);

ProtocolActionMenu.defaultProps = {
  actions: [],
};

export default ProtocolActionMenu;
