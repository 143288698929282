/**
 * Labstep
 *
 * @module components/DataTable/Action/Create
 * @desc Create a DataTable
 */

import TableModal from 'labstep-web/components/Table/Modal';
import { EntityReadEntityContainer } from 'labstep-web/containers/Entity/Read/Entity';
import { withUi } from 'labstep-web/containers/Ui';
import ActionComponent from 'labstep-web/core/Action/Component';
import { Comment } from 'labstep-web/models/comment.model';
import { generateTableData } from 'labstep-web/services/table.service';
import React from 'react';
import {
  DataTableActionCreateProps,
  DataTableActionCreateState,
} from './types';

export class DataTableActionCreate extends React.Component<
  DataTableActionCreateProps,
  DataTableActionCreateState
> {
  constructor(props: DataTableActionCreateProps) {
    super(props);
    this.onCommentCreateSuccess =
      this.onCommentCreateSuccess.bind(this);
    this.resetCommentId = this.resetCommentId.bind(this);
    this.state = {
      commentId: undefined,
    };
  }

  onCommentCreateSuccess(e: any) {
    const commentId: number = e.response.result;
    this.setState({
      commentId,
    });
  }

  resetCommentId() {
    this.setState({
      commentId: undefined,
    });
  }

  render() {
    const { create, setActiveEntity } = this.props;
    const { commentId } = this.state;

    return commentId ? (
      <EntityReadEntityContainer
        entityName={Comment.entityName}
        id={commentId}
      >
        {({ entity: comment }) => (
          <TableModal
            viewComponent={null}
            initialModalState
            onClose={this.resetCommentId}
            table={comment.data_table}
            allowLocking
            onDone={() => {
              setActiveEntity(Comment.entityName, commentId);
              this.resetCommentId();
            }}
          />
        )}
      </EntityReadEntityContainer>
    ) : (
      <ActionComponent
        onClick={() => {
          const data = generateTableData();
          const table = {
            name: 'Untitled Table',
            data,
          };
          const comment = {
            body: 'DataTable',
            data_table: table,
          };
          create(comment, {
            onSuccess: this.onCommentCreateSuccess,
          });
        }}
        type="option"
        icon="table"
        text="Table"
      />
    );
  }
}

export default withUi(DataTableActionCreate);
