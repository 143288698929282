/**
 * Labstep
 *
 * @module components/Entity/Can/hooks
 * @desc Hook for useHasAccess. Returns if user has access or not.
 */

import { CanProps } from 'labstep-web/components/Entity/Can/types';
import { Group } from 'labstep-web/models/group.model';
import {
  makeSelectHasAccess,
  makeSelectHasAccessCreate,
  makeSelectHasAccessGroup,
} from 'labstep-web/state/new/authorization';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';

// This is a non memoized version
// Cache size is 1 so using this with different props
// Will make it behave as a regular Redux selector
export const selectHasAccess = makeSelectHasAccess();

export function useHasAccess(
  entityName: CanProps['entityName'],
  id: CanProps['id'],
  action: CanProps['action'],
  field?: string | string[],
): boolean {
  const memoSelectHasAccess = useMemo(makeSelectHasAccess, []);
  const hasAccess = useSelector((state) =>
    memoSelectHasAccess(state, entityName, id, action, field),
  );

  return hasAccess;
}

export function useHasAccessMultiple(
  entityName: CanProps['entityName'],
  ids: CanProps['id'][],
  action: CanProps['action'],
  field?: string | string[],
): { [key: number]: boolean } {
  const memoSelectHasAccess = useMemo(makeSelectHasAccess, []);

  return useSelector((state) =>
    ids.reduce(
      (result, i) => ({
        ...result,
        [i]: memoSelectHasAccess(state, entityName, i, action, field),
      }),
      {},
    ),
  );
}

export function useHasAccessGroup(
  action: string,
  groupId: Group['id'],
): boolean | { [key: number]: boolean } {
  const memoSelectHasAccessGroup = useMemo(
    makeSelectHasAccessGroup,
    [],
  );

  const hasAccess = useSelector((state) =>
    memoSelectHasAccessGroup(state, action, groupId),
  );

  return hasAccess;
}

export function useHasAccessCreate(
  entityName: string,
  parentName?: string | undefined,
  parentId?: number | string | undefined,
): boolean {
  const memoSelectHasAccessCreate = useMemo(
    makeSelectHasAccessCreate,
    [],
  );

  const hasAccess = useSelector((state) =>
    memoSelectHasAccessCreate(
      state,
      entityName,
      parentName,
      parentId,
    ),
  );

  return hasAccess;
}
