/**
 * Labstep
 *
 * @module components/ResourceLocation/Search
 * @desc Search for resource location
 */

import EntitySearch from 'labstep-web/components/Entity/Search';
import { Filter } from 'labstep-web/components/Filter/Menu/types';
import { Action } from 'labstep-web/components/ResourceLocation/Action/Menu/types';
import { ResourceSubLocationEmptyState } from 'labstep-web/components/ResourceLocation/Card/EmptyState';
import ResourceLocationTable from 'labstep-web/components/ResourceLocation/Table';
import { withActiveGroup } from 'labstep-web/containers/ActiveGroup';
import Mobile from 'labstep-web/core/MediaQuery/Mobile';
import { Entity } from 'labstep-web/models/entity.model';
import { ResourceLocation } from 'labstep-web/models/resource-location.model';
import React from 'react';
import { IResourceLocationSearchProps } from './types';

export const ResourceLocationSearch: React.FC<
  IResourceLocationSearchProps
> = ({
  activeGroup,
  tableFormat,
  noResultsMessage,
  outerResourceLocation,
  action,
}) => (
  <EntitySearch
    entityName={ResourceLocation.entityName}
    params={{
      group_id: activeGroup?.id,
      outer_location_guid: outerResourceLocation.guid,
    }}
    tableFormat={tableFormat}
    filters={[Filter.deleted]}
    additionalFilters={[
      (entity: Entity) =>
        (entity instanceof ResourceLocation &&
          entity.outer_location &&
          entity.outer_location.guid ===
            outerResourceLocation.guid) ||
        false,
    ]}
    noResultsMessage={
      noResultsMessage || (
        <ResourceSubLocationEmptyState
          resourceLocation={outerResourceLocation}
        />
      )
    }
    action={action}
  >
    {({ entities, ...rest }) => (
      <ResourceLocationTable
        actions={[Action.delete, Action.update_outer]}
        resourceLocations={entities}
        primaryColumn={undefined}
        {...rest}
      />
    )}
  </EntitySearch>
);

export const ResourceLocationSearchMobileContainer: React.FC<
  IResourceLocationSearchProps
> = (props) => (
  <Mobile>
    {({ match }) => (
      <ResourceLocationSearch {...props} tableFormat={!match} />
    )}
  </Mobile>
);

export default withActiveGroup(ResourceLocationSearchMobileContainer);
