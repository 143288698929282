/**
 * Labstep
 *
 * @module components/Chemical/Form/ShowEdit/Inventory/NoResources
 * @desc ShowEditInventoryNoResources for Chemical
 */

import ResourceFormCreate from 'labstep-web/components/Resource/Form/Create';
import { fieldResourceName } from 'labstep-web/components/Resource/Form/fields';
import { EntityCreateContainer } from 'labstep-web/containers/Entity/Create';
import { APIResponse } from 'labstep-web/models';
import { Metadata } from 'labstep-web/models/metadata';
import { MetadataParent } from 'labstep-web/models/metadata/types';
import marvinJSService from 'labstep-web/services/marvinjs';
import { enhanceOptions } from 'labstep-web/services/utils.service';
import React from 'react';
import {
  ChemicalFormShowEditInventoryNoResourcesContainerProps,
  ChemicalFormShowEditInventoryNoResourcesProps,
} from './types';

export const ChemicalFormShowEditInventoryNoResources: React.FC<
  ChemicalFormShowEditInventoryNoResourcesProps
> = ({ chemical, update, create, options }) => (
  <div>
    <p>
      No resources with this chemical structure were found in your
      inventory. Complete the form below to create a new one.
    </p>
    <ResourceFormCreate
      options={enhanceOptions({
        options,
        onSuccess: async ({
          response,
        }: {
          response?: APIResponse;
        }) => {
          if (!response) {
            return;
          }
          create({
            metadata_thread_id: (
              response.entities.resource[
                response.result as string | number
              ] as MetadataParent
            ).metadata_thread,
            type: 'molecule',
            label: 'Structure',
            molecule: {
              name: chemical.properties.IUPACName || 'Untitled',
              pubchem: {
                reactants: [chemical.properties],
                products: [],
              },
              data: chemical.properties.SMILES,
              svg: await marvinJSService.getStructure(
                chemical.properties.SMILES as string,
                'svg',
                'smiles',
              ),
              smiles: chemical.properties.SMILES,
            },
          });
          update({ resource_id: response.result });
        },
      })}
      defaultValues={{
        [fieldResourceName.name]: chemical.properties.IUPACName,
      }}
    />
  </div>
);

export const ChemicalFormShowEditInventoryNoResourcesContainer: React.FC<
  ChemicalFormShowEditInventoryNoResourcesContainerProps
> = (props) => (
  <EntityCreateContainer entityName={Metadata.entityName}>
    {(containerProps) => (
      <ChemicalFormShowEditInventoryNoResources
        {...containerProps}
        {...props}
      />
    )}
  </EntityCreateContainer>
);

export default ChemicalFormShowEditInventoryNoResourcesContainer;
