/**
 * Labstep
 *
 * @module components/EntityImport/Action/Create/Steps/Preview/WarningModal
 * @desc EntityImport Warning Modal on Preview Step for Data Overwrite
 */

import Button from 'labstep-web/core/Button';
import Icon from 'labstep-web/core/Icon';
import Image from 'labstep-web/core/Image';
import { ModalDefault } from 'labstep-web/core/Modal/Default';
import React, { useState } from 'react';
import styles from './styles.module.scss';
import { EntityImportActionCreateStepsPreviewWarningModalProps } from './types';

export const EntityImportActionCreateStepsPreviewWarningModal: React.FC<
  EntityImportActionCreateStepsPreviewWarningModalProps
> = ({ count, onConfirm, setShowWarningModal }) => {
  const [open, setOpen] = useState(true);

  const onClick = () => {
    setOpen(false);
    setShowWarningModal(false);
  };
  return (
    <ModalDefault
      size="small"
      header="Warning: Data Overwrite"
      modalHocProps={{ open, toggleModal: onClick }}
      content={
        <>
          <div>
            <Image
              className={styles.image}
              src="/img/resource-import/reserved-steppy.svg"
            />
          </div>
          <div className={styles.warningText}>
            Some of the identifiers in this import refer to existing
            resources. The data in the import may overwrite existing
            fields.
          </div>
          <div>
            <Icon color="yellow" name="warning sign" />
            <span style={{ marginLeft: '10px' }}>
              {count} existing{' '}
              {count === 1 ? 'identifier' : 'identifiers'} referenced.
            </span>
          </div>
        </>
      }
      footer={
        <div className={styles.footer}>
          <Button
            data-testid="warning-modal-back-button"
            primary
            basic
            onClick={onClick}
          >
            Back
          </Button>
          <Button
            data-testid="warning-modal-confirm-button"
            primary
            onClick={() => {
              onConfirm();
              onClick();
            }}
          >
            Confirm
          </Button>
        </div>
      }
    />
  );
};

export default EntityImportActionCreateStepsPreviewWarningModal;
