/**
 * Labstep
 *
 * @module components/Organization/Form/ShowEdit/WhitelistIp
 * @desc Organization Form to update WhitelistIp
 */

import { fieldOrganizationWhitelistIp } from 'labstep-web/components/Organization/Form/fields';
import FormShowEdit from 'labstep-web/core/Form/ShowEdit';
import React from 'react';
import { OrganizationFormShowEditWhitelistIpProps } from './types';

export const OrganizationFormShowEditWhitelistIp: React.FC<
  OrganizationFormShowEditWhitelistIpProps
> = ({ organization }) => (
  <FormShowEdit
    entityName={organization.entityName}
    entity={organization}
    field={fieldOrganizationWhitelistIp}
    canEdit
  >
    {organization.whitelist_ip &&
      organization.whitelist_ip.map((ip) => <div key={ip}>{ip}</div>)}
  </FormShowEdit>
);

export default OrganizationFormShowEditWhitelistIp;
