/**
 * Labstep
 *
 * @module components/UserGroup/Action/Menu
 * @desc Action Menu for group
 */

import GroupActionDelete from 'labstep-web/components/Group/Action/Delete';
import GroupActionLeave from 'labstep-web/components/Group/Action/Leave';
import GroupActionSetHome from 'labstep-web/components/Group/Action/SetHome';
import { GroupActionShare } from 'labstep-web/components/Group/Action/Share';
import ViewGroupAction from 'labstep-web/components/Group/Action/View';
import UserGroupActionDelete from 'labstep-web/components/UserGroup/Action/Delete';
import { Action } from 'labstep-web/components/UserGroup/Action/Menu/types';
import ActionMenu from 'labstep-web/core/Action/Menu';
import React from 'react';
import { IUserGroupActionMenuProps } from './types';

export const UserGroupActionMenu: React.FC<
  IUserGroupActionMenuProps
> = ({ userGroup, type, actions = [] }) => {
  const { group } = userGroup;

  return (
    <ActionMenu type={type}>
      {actions.includes(Action.view) && (
        <ViewGroupAction group={group} />
      )}
      {actions.includes(Action.leave) && (
        <GroupActionLeave group={group} userGroup={userGroup} />
      )}
      {actions.includes(Action.share) && (
        <GroupActionShare
          actionComponentProps={{ type: 'option' }}
          group={group}
        />
      )}
      {actions.includes(Action.set_home) && (
        <GroupActionSetHome
          actionComponentProps={{ type: 'option' }}
          userGroup={userGroup}
        />
      )}
      {actions.includes(Action.delete_group) && (
        <GroupActionDelete group={group} />
      )}
      {actions.includes(Action.delete_user_group) && (
        <UserGroupActionDelete userGroup={userGroup} />
      )}
    </ActionMenu>
  );
};

UserGroupActionMenu.defaultProps = {
  actions: [],
  type: 'icon',
};

export default UserGroupActionMenu;
