/**
 * Labstep
 *
 * @desc ShowEdit component for CAS
 */

import PubChemMetadataFormShowEditGeneric from 'labstep-web/components/PubChemMetadata/Form/ShowEdit/Generic';
import { fieldCAS } from 'labstep-web/components/PubChemMetadata/Form/fields';
import React from 'react';
import { IPubChemMetadataFormShowEditCASProps } from './types';

const PubChemMetadataFormShowEditCAS: React.FC<
  IPubChemMetadataFormShowEditCASProps
> = ({ parent, pubChemMetadata }) => (
  <PubChemMetadataFormShowEditGeneric
    pubChemMetadata={pubChemMetadata}
    parent={parent}
    field={fieldCAS}
  />
);

export default PubChemMetadataFormShowEditCAS;
