/**
 * Labstep
 *
 * @module models/organization-saml
 * @desc Typescript export class for OrganizationSAML
 */

import { Entity } from 'labstep-web/models/entity.model';

export class OrganizationSAML extends Entity {
  public static readonly idAttr = 'guid' as const;

  static readonly entityName = 'organization_saml';

  public get entityName(): string {
    return OrganizationSAML.entityName;
  }

  public constructor(data: Partial<OrganizationSAML>) {
    super();
    Object.assign(this, data);
  }

  get areAllAttributesFilled(): boolean {
    return (
      !!this.attribute_email &&
      !!this.attribute_first_name &&
      !!this.attribute_last_name &&
      !!this.idp_entity_id &&
      !!this.idp_sign_on_url &&
      !!this.idp_logout_url &&
      !!this.idp_certificate
    );
  }

  public id!: number;

  public created_at!: string;

  public updated_at!: string;

  public is_enabled!: boolean;

  public attribute_email!: string;

  public attribute_first_name!: string;

  public attribute_last_name!: string;

  public idp_entity_id!: string;

  public idp_sign_on_url!: string;

  public idp_logout_url!: string;

  public idp_certificate!: string;
}
