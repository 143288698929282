/**
 * Labstep
 *
 * @module services/grid-entity-import
 * @desc AGGrid Service Entity Import
 */

import {
  CellClassParams,
  CellStyle,
  ColDef,
  GridApi,
  ICellRendererParams,
  ITooltipParams,
  NewValueParams,
  RowNode,
} from 'ag-grid-community';
import { ICONS } from 'labstep-web/constants/icons';
import { suppressKeyboardEventClearCells } from 'labstep-web/core/DataGrid/CellEditor/utils';
import DataGridColumnHeaderEntityImport from 'labstep-web/core/DataGrid/ColumnHeader/EntityImport';
import Icon from 'labstep-web/core/Icon';
import TextPlaceholder from 'labstep-web/core/Text/Placeholder';
import { GridService } from 'labstep-web/grid/services/grid.service';
import { ImportableEntity } from 'labstep-web/models';
import { APICallOptions, APICreateAction } from 'labstep-web/models';
import { CustomIdentifierSet } from 'labstep-web/models/custom-identifier-set.model';
import { Device } from 'labstep-web/models/device.model';
import {
  ENTITY_IMPORT_DEFAULT_DATE_FORMAT,
  EntityImportColDef,
  EntityImportCreateMode,
  EntityImportDataItem,
  EntityImportDataItemRelationWithMetadatas,
  EntityImportDataItems,
  EntityImportDataRowData,
  EntityImportDataRowDataRow,
} from 'labstep-web/models/entity-import.model';
import { Metadata } from 'labstep-web/models/metadata';
import {
  MetadataOptions,
  MetadataType,
} from 'labstep-web/models/metadata/types';
import { ResourceItem } from 'labstep-web/models/resource-item.model';
import { ResourceLocation } from 'labstep-web/models/resource-location.model';
import { Resource } from 'labstep-web/models/resource.model';
import {
  convertAmountUnitToAmount,
  convertAmountUnitToUnit,
} from 'labstep-web/services/amount-unit.service';
import { convertDateFormat } from 'labstep-web/services/date.service';
import partition from 'lodash/partition';
import uniqWith from 'lodash/uniqWith';
import { GridEntityImportProps } from '../types';
import { GridEntityImportValidationService } from './grid-entity-import-validation.service';

interface SettingsModes {
  device?: string;
  resource?: string;
  resource_item?: string;
  resource_location?: string;
}

interface Settings {
  modes: SettingsModes;
}

export const RESOURCE_IDENTIFIER_COL_NAME = 'Resource Identifier';

export const DUPLICATE_IDENTIFIER_WARNING =
  'Duplicate identifiers used';
export const INVALID_PREFIX_WARNING = 'Prefix does not match';
export const EMPTY_IDENTIFIER_WARNING =
  'Identifiers must be allocated before proceeding';
export const RESERVED_IDENTIFIER_WARNING =
  'This identifier may refer to an existing resource. The data in the import may overwrite existing fields.';

export const HARDCODED_SETTINGS: Record<string, Settings> = {
  device: {
    modes: {
      device: EntityImportCreateMode.create_always,
    },
  },
  resource: {
    modes: {
      resource: EntityImportCreateMode.create_always,
      resource_item: EntityImportCreateMode.create_always,
    },
  },
  resource_item: {
    modes: {
      resource_item: EntityImportCreateMode.create_always,
      resource: EntityImportCreateMode.create_skip_existing,
      resource_location: EntityImportCreateMode.create_skip_existing,
    },
  },
  resource_location: {
    modes: {
      resource_location: EntityImportCreateMode.create_always,
    },
  },
};

export const HARDCODED_SETTINGS_IDENTIFIER: Record<string, Settings> =
  {
    device: {
      modes: {
        device: EntityImportCreateMode.create_always,
      },
    },
    resource: {
      modes: {
        resource: EntityImportCreateMode.create_update_existing,
        resource_item: EntityImportCreateMode.create_always,
      },
    },
    resource_item: {
      modes: {
        resource_item: EntityImportCreateMode.create_always,
        resource: EntityImportCreateMode.create_update_existing,
        resource_location:
          EntityImportCreateMode.create_skip_existing,
      },
    },
    resource_location: {
      modes: {
        resource_location: EntityImportCreateMode.create_always,
      },
    },
  };

export const UNSUPPORTED_METADATA_TYPES = [
  MetadataType.file,
  MetadataType.sequence,
  MetadataType.molecule,
];

interface Relation {
  entity_name: string;
  field: string;
  unique_key: string;
}

const RELATIONS: Record<string, Relation> = {
  resource_item_resource: {
    entity_name: Resource.entityName,
    field: 'resource',
    unique_key: 'name',
  },
  resource_item_resource_identifier: {
    entity_name: Resource.entityName,
    field: 'resource',
    unique_key: 'custom_identifier',
  },
  resource_item_resource_location: {
    entity_name: ResourceLocation.entityName,
    field: 'resourceLocation',
    unique_key: 'path',
  },
};

export class GridEntityImportService extends GridService {
  getCustomIdentifierValidation(
    columnDefs: GridEntityImportProps['columnDefs'],
    template?: ImportableEntity,
  ): {
    isValid: boolean;
    errorMessage?: string;
  } {
    let isValid = true;
    let errorMessage: string | undefined;
    const colDef =
      columnDefs &&
      columnDefs.find(
        (col) => col.headerName === RESOURCE_IDENTIFIER_COL_NAME,
      );

    if (colDef) {
      const { hasEmptyIdentifiers, hasInvalidPrefix, hasDuplicates } =
        this.validateCustomIdentifiers(template, true);

      const isResourceItem =
        'colId' in colDef &&
        colDef.colId === 'resource_item_resource_identifier';

      if (hasEmptyIdentifiers || hasInvalidPrefix || hasDuplicates) {
        isValid = false;

        if (!isResourceItem && hasDuplicates) {
          errorMessage = DUPLICATE_IDENTIFIER_WARNING;
        }
        if (hasInvalidPrefix) {
          errorMessage = INVALID_PREFIX_WARNING;
        }
        if (hasEmptyIdentifiers) {
          errorMessage = EMPTY_IDENTIFIER_WARNING;
        }
      }
    }
    return { isValid, errorMessage };
  }

  public static getMetadataColId = (
    metadata: Metadata,
    targetEntityName: ImportableEntity['entityName'],
  ): string =>
    `metadata:${targetEntityName}:${metadata.type}:${metadata.guid}`;

  public onFilterChanged(): void {
    const { api } = this.agGrid;

    api.onFilterChanged();
  }

  public getColDefByColId(
    colId: ColDef['colId'],
  ): EntityImportColDef | null {
    const { columnApi } = this.agGrid;

    const column = columnApi.getColumn(colId);
    if (column) {
      return column.getColDef() as EntityImportColDef;
    }

    return null;
  }

  public setColDefEntityImportParameter(
    colId: ColDef['colId'],
    param: Record<string, unknown>,
  ): void {
    const { columnApi } = this.agGrid;

    const column = columnApi.getColumn(colId);
    if (column) {
      const colDef = column.getColDef() as EntityImportColDef;
      colDef.entity_import = {
        ...colDef.entity_import,
        ...param,
      };
      column.setColDef(colDef, colDef);
      this.refreshColumnDefs();
    }
  }

  public getData(): EntityImportDataRowData {
    const { api } = this.agGrid;

    const data: EntityImportDataRowData = [];
    api.forEachNode((rowNode: RowNode) => {
      const rowNodesWithValues: Record<string, string | null> = {};

      Object.keys(rowNode.data).forEach((key: string) => {
        if (rowNode.data[key] !== '') {
          rowNodesWithValues[key] = rowNode.data[key];
        }
      });
      if (Object.keys(rowNodesWithValues).length !== 0) {
        data.push(rowNodesWithValues);
      }
    });
    return data;
  }

  public getInvalidRowCount(): number {
    const data = this.getData();
    const columnDefs = this.getColumnDefs();
    return data.filter(
      (row) =>
        !GridEntityImportValidationService.isRowValid(
          row,
          columnDefs,
        ),
    ).length;
  }

  public validateCustomIdentifiers(
    template?: ImportableEntity,
    checkPrefix = false,
  ): {
    hasInvalidPrefix: boolean;
    hasEmptyIdentifiers: boolean;
    hasDuplicates: boolean;
  } {
    const { api } = this.agGrid;
    const validation = {
      hasInvalidPrefix: false,
      hasEmptyIdentifiers: false,
      hasDuplicates: false,
    };

    if (
      this.getColumnDefs().filter(
        (colDef) =>
          colDef.headerName === RESOURCE_IDENTIFIER_COL_NAME,
      ).length === 0
    ) {
      return validation;
    }

    if (
      template instanceof Resource &&
      template._custom_identifier_set
    ) {
      const customIdentifierSet = template._custom_identifier_set;
      const { prefix } = customIdentifierSet.settings;

      const identifiers: string[] = [];
      const identifierMap: Map<string, Set<string>> = new Map();
      api.forEachNode((rowNode) => {
        if (
          rowNode.data &&
          !GridEntityImportValidationService.isRowEmpty(rowNode.data)
        ) {
          const customIdentifier =
            rowNode.data.resource_identifier ||
            rowNode.data.resource_item_resource_identifier;
          if (!customIdentifier) {
            validation.hasEmptyIdentifiers = true;
          } else {
            if (
              checkPrefix &&
              (!customIdentifier.startsWith(prefix) ||
                Number.isNaN(
                  parseInt(customIdentifier.slice(prefix.length), 10),
                ))
            ) {
              validation.hasInvalidPrefix = true;
            }
            if (rowNode.data.resource_identifier) {
              identifiers.push(customIdentifier);
            } else if (
              rowNode.data.resource_item_resource_identifier
            ) {
              const resourceIdentifier =
                rowNode.data.resource_item_resource_identifier;
              const resourceName =
                rowNode.data.resource_item_resource || '';

              if (!identifierMap.has(resourceIdentifier)) {
                identifierMap.set(resourceIdentifier, new Set());
              }

              identifierMap
                .get(resourceIdentifier)!
                .add(resourceName);
            }
          }
        }
      });
      const duplicates = identifiers.filter(
        (item, index) => identifiers.indexOf(item) !== index,
      );

      if (duplicates.length > 0) {
        validation.hasDuplicates = true;
      }

      identifierMap.forEach((resourceSet) => {
        if (resourceSet.size > 1) {
          validation.hasDuplicates = true;
        }
      });
    }

    return validation;
  }

  public hasEmptyIdentifier(excludeEmptyRows = false): boolean {
    const { api } = this.agGrid;
    let hasEmptyIdentifier = false;

    const columnDef = this.getColumnDefs().find(
      (colDef) => colDef.headerName === RESOURCE_IDENTIFIER_COL_NAME,
    );

    if (!columnDef) {
      return hasEmptyIdentifier;
    }

    api.forEachNode((rowNode) => {
      if (
        excludeEmptyRows &&
        (!rowNode.data || Object.keys(rowNode.data).length === 0)
      ) {
        return;
      }

      if (
        !rowNode.data[columnDef.colId!] ||
        rowNode.data[columnDef.colId!] === ''
      ) {
        hasEmptyIdentifier = true;
      }
    });

    return hasEmptyIdentifier;
  }

  public isGridEmpty(): boolean {
    const { api } = this.agGrid;
    const rowData: EntityImportDataRowData = [];

    api.forEachNode((node) => {
      rowData.push(node.data);
    });
    const allCellsEmpty = rowData.every((row) => {
      return Object.values(row).every(
        (cell) => cell === null || cell === undefined || cell === '',
      );
    });

    return allCellsEmpty;
  }

  public saveData(
    groupId: number,
    targetEntityName: string,
    create: APICreateAction,
    template?: ImportableEntity,
    options?: APICallOptions,
  ): void {
    const data = this.getData();
    if (data.length === 0) {
      return;
    }

    let customIdentifierSet: CustomIdentifierSet | null = null;
    let maxNumber: number | undefined;

    if (template instanceof Resource) {
      customIdentifierSet = template.custom_identifier_set;
      if (customIdentifierSet) {
        const { prefix } = customIdentifierSet.settings;
        maxNumber = Math.max(
          ...data
            .map((row) => {
              const customIdentifier =
                row.resource_identifier ||
                row.resource_item_resource_identifier;
              return customIdentifier &&
                customIdentifier.startsWith(prefix)
                ? parseInt(customIdentifier.slice(prefix.length), 10)
                : -1;
            })
            .filter((num) => !Number.isNaN(num)),
        );
      }
    }

    const body: Record<string, unknown> = {
      group_id: groupId,
      target_entity_name: targetEntityName,
      data: {
        settings: customIdentifierSet
          ? HARDCODED_SETTINGS_IDENTIFIER[targetEntityName]
          : HARDCODED_SETTINGS[targetEntityName],
        items: this.convertAGGridRowDataToItems(
          data,
          targetEntityName,
        ),
        custom_identifier_number_increment: maxNumber,
      },
    };
    if (template) {
      const targetEntityNameTemplate =
        targetEntityName === ResourceItem.entityName
          ? Resource.entityName
          : targetEntityName;

      body[`${targetEntityNameTemplate}_template_guid`] =
        template.guid;
    }
    create(body, options);
  }

  public static getMetadataColDef(
    metadata: Metadata,
    targetEntityName: ImportableEntity['entityName'],
  ): EntityImportColDef & {
    colId: string;
    field: string;
    headerName: string;
    entity_import: {
      metadata: Metadata;
      date_format: string | undefined;
    };
  } {
    return {
      colId: this.getMetadataColId(metadata, targetEntityName),
      field: this.getMetadataColId(metadata, targetEntityName),
      headerName: metadata.label || '',
      headerComponentParams: {
        icon: ICONS.metadata.secondary,
        cornerIcon: ICONS[targetEntityName]?.primary,
      },
      entity_import: {
        metadata,
        date_format:
          GridEntityImportService.getMetadataDateFormat(metadata),
        default_value:
          GridEntityImportService.metadataValueToCellValue(metadata),
      },
      entityName: targetEntityName,
      isRequired: !!metadata.is_required,
      validator: (params) =>
        GridEntityImportValidationService.validatorMetadata(
          params,
          metadata.type,
        ),
    };
  }

  public static getResourceIdentifierColDef(
    customIdentifierSet: CustomIdentifierSet,
    resourceItem = false,
  ): EntityImportColDef {
    const colId = resourceItem
      ? 'resource_item_resource_identifier'
      : 'resource_identifier';

    return {
      colId,
      field: colId,
      headerName: RESOURCE_IDENTIFIER_COL_NAME,
      optionalHeaderName: 'Identifier',
      isRequired: true,
      width: 250,
      entityName: resourceItem ? Resource.entityName : undefined,
      headerComponentParams: {
        icon: ICONS.resource.info.custom_identifier,
      },
      onCellValueChanged: (e: NewValueParams) => {
        e.api.refreshCells({
          force: true,
          suppressFlash: true,
        });
      },
      cellRenderer: (params: ICellRendererParams) => {
        const { prefix, zero_padding } = customIdentifierSet.settings;

        if (
          GridEntityImportValidationService.isValueEmpty(params.value)
        ) {
          return (
            <span
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                width: '100%',
              }}
            >
              <span>
                {prefix}
                <TextPlaceholder>
                  {`{${'x'.repeat(zero_padding)}}`}
                </TextPlaceholder>
              </span>
            </span>
          );
        }
        return (
          <span
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              width: '100%',
            }}
          >
            <span>{params.value || ''}</span>
            {(() => {
              const {
                hasDuplicates,
                hasInvalidPrefix,
                isReservedIdentifier,
              } =
                GridEntityImportValidationService.validateCustomIdentifier(
                  customIdentifierSet,
                  params.value,
                  params,
                );

              const showWarning =
                (!resourceItem && hasDuplicates) ||
                hasInvalidPrefix ||
                isReservedIdentifier;
              let popupContent = '';

              if (hasInvalidPrefix) {
                popupContent = INVALID_PREFIX_WARNING;
              }
              if (!resourceItem && hasDuplicates) {
                popupContent = DUPLICATE_IDENTIFIER_WARNING;
              }
              if (isReservedIdentifier) {
                popupContent = RESERVED_IDENTIFIER_WARNING;
              }
              return showWarning ? (
                <Icon
                  color={
                    hasInvalidPrefix || hasDuplicates
                      ? 'red'
                      : 'yellow'
                  }
                  name="warning sign"
                  popup={{
                    content: popupContent,
                  }}
                />
              ) : (
                <Icon color="green" name="check circle" />
              );
            })()}
          </span>
        );
      },
    };
  }

  public static metadataValueToCellValue(
    metadata: Metadata,
  ): string | null {
    if (metadata.type === MetadataType.default) {
      return metadata.value;
    }
    if (metadata.type === MetadataType.numeric) {
      return String(metadata.number) || null;
    }
    if (
      metadata.type === MetadataType.date ||
      metadata.type === MetadataType.datetime
    ) {
      if (metadata.date) {
        return convertDateFormat(
          metadata.date,
          ENTITY_IMPORT_DEFAULT_DATE_FORMAT[metadata.type],
        );
      }
    }
    return null;
  }

  /**
   * Converts a custom column to a metadata column
   * @param colId Column ID
   * @param metadata Metadata
   * @param targetEntityName Target entity class
   */
  public convertCustomColDefToMetadataColDef(
    colId: ColDef['colId'],
    metadata: Metadata,
    targetEntityName: ImportableEntity['entityName'],
  ): void {
    this.changeColDef(
      colId,
      GridEntityImportService.getMetadataColDef(
        metadata,
        targetEntityName,
      ),
    );
  }

  /**
   * Converts metadatas to column definitions
   * @param metadatas Metadatas
   * @param targetEntityName Target entity class
   * @returns Column definitions
   */
  public static metadatasToColDefs(
    metadatas: Metadata[] | undefined,
    targetEntityName: ImportableEntity['entityName'],
  ): EntityImportColDef[] {
    return (
      metadatas
        ?.filter(
          (metadata: Metadata) =>
            !UNSUPPORTED_METADATA_TYPES.includes(metadata.type),
        )
        .map((metadata: Metadata) =>
          GridEntityImportService.getMetadataColDef(
            metadata,
            targetEntityName,
          ),
        ) ?? []
    );
  }

  static isValidResourceName(
    api: GridApi,
    identifier?: string,
  ): boolean {
    if (identifier) {
      // Get all rows with the same identifier
      const matchingRows: EntityImportDataRowData = [];
      api.forEachNode((rowNode) => {
        if (
          rowNode.data?.resource_item_resource_identifier ===
          identifier
        ) {
          matchingRows.push(rowNode.data);
        }
      });

      // Check if any rows have different resource names
      const resourceNames = matchingRows.map(
        (row) => row.resource_item_resource,
      );
      if (
        resourceNames.length > 1 &&
        new Set(
          resourceNames.map((name) =>
            name === '' || name === null ? undefined : name,
          ),
        ).size > 1
      ) {
        return false;
      }
    }
    return true;
  }

  /**
   * Gets the column definitions based on the template
   * @param entityName Entity name
   * @param template Template
   * @returns Column definitions
   */
  public static getColumnDefsTemplate(
    entityName: ImportableEntity['entityName'],
    template?: ImportableEntity,
  ): EntityImportColDef[] {
    let metadataColDefs = template
      ? GridEntityImportService.metadatasToColDefs(
          template.metadata_thread.metadatas,
          template.entityName,
        )
      : [];

    const defaultColDefs: EntityImportColDef[] = [];
    let customIdentifierSet: CustomIdentifierSet | null = null;
    if (template instanceof Resource) {
      customIdentifierSet = template.custom_identifier_set;
    }

    if (entityName === ResourceItem.entityName) {
      if (customIdentifierSet) {
        defaultColDefs.push(
          GridEntityImportService.getResourceIdentifierColDef(
            customIdentifierSet,
            true,
          ),
        );
      }
      defaultColDefs.push({
        colId: 'resource_item_resource',
        field: 'resource_item_resource',
        headerName: 'Resource Name',
        optionalHeaderName: 'Name',
        isRequired: !customIdentifierSet,
        headerComponentParams: {
          icon: ICONS.resource.primary,
          info: 'If no Resources with the specified name are found in the workspace a new Resource will be created.',
        },
        entityName: Resource.entityName,
        cellStyle: (params: CellClassParams) => {
          const { data, api } = params;
          const identifier = data?.resource_item_resource_identifier;

          if (GridEntityImportValidationService.isRowEmpty(data)) {
            return null;
          }

          if (
            !GridEntityImportValidationService.isCellValid(params)
          ) {
            return { backgroundColor: '#F5B1B1' };
          }

          const isValidResourceName =
            GridEntityImportService.isValidResourceName(
              api,
              identifier,
            );

          if (!isValidResourceName) {
            return { backgroundColor: '#F5B1B1' };
          }
          return null;
        },
        tooltipValueGetter: (params: ITooltipParams) => {
          const { data, api } = params;
          const identifier = data?.resource_item_resource_identifier;

          const isValidResourceName =
            GridEntityImportService.isValidResourceName(
              api,
              identifier,
            );

          if (!isValidResourceName) {
            return 'The Resource Name must be the same for the same Resource Identifier';
          }
          return null;
        },
        onCellValueChanged: (e) => {
          e.api.refreshCells({
            force: true,
            suppressFlash: true,
          });
        },
      });
      defaultColDefs.push({
        colId: 'name',
        field: 'name',
        headerName: 'Item Name',
        headerComponentParams: {
          icon: ICONS.resource_item.primary,
          info: 'Unless specified, defaults to the Resource Name with an incrementing item number i.e. Ethanol #1, Ethanol #2 etc.',
        },
        cellRenderer: (params: ICellRendererParams) => {
          if (
            GridEntityImportValidationService.isValueEmpty(
              params.value,
            ) &&
            (params.data.resource_item_resource ||
              params.data.resource_item_resource_identifier)
          ) {
            return (
              <span>
                {params.data.resource_item_resource_identifier}
                {params.data.resource_item_resource_identifier &&
                  params.data.resource_item_resource &&
                  ' - '}
                {params.data.resource_item_resource}
                {' #'}
                <TextPlaceholder>{`{ItemNo.}`}</TextPlaceholder>
              </span>
            );
          }
          return params.value || '';
        },
      });
      defaultColDefs.push({
        colId: 'resource_item_resource_location',
        field: 'resource_item_resource_location',
        headerName: 'Location',
        headerComponentParams: {
          icon: ICONS.resource_location.primary,
          info: 'i.e. Fridge A > Box 1. This will look for a sub-location called Box 1 within a location called Fridge A. If no locations with those name are found they will be created.',
        },
      });
      defaultColDefs.push({
        colId: 'amount',
        field: 'amount',
        headerName: 'Amount',
        headerComponentParams: {
          icon: ICONS.resource_item.info.quantity,
        },
      });
      if (template instanceof Resource) {
        const resourceItemTemplate = template.resource_item_template;
        if (resourceItemTemplate) {
          metadataColDefs = metadataColDefs.concat(
            GridEntityImportService.metadatasToColDefs(
              resourceItemTemplate.metadata_thread.metadatas,
              entityName,
            ),
          );
        }
      }
    }

    if (entityName === Resource.entityName) {
      if (customIdentifierSet) {
        defaultColDefs.push(
          GridEntityImportService.getResourceIdentifierColDef(
            customIdentifierSet,
          ),
        );
      }
      defaultColDefs.push({
        colId: 'name',
        field: 'name',
        headerName: 'Resource Name',
        optionalHeaderName: 'Name',
        isRequired: !customIdentifierSet,
        headerComponentParams: {
          icon: ICONS.resource.primary,
        },
      });
      defaultColDefs.push({
        colId: 'resource_resource_items',
        field: 'resource_resource_items',
        headerName: 'Items in Stock',
        headerComponentParams: {
          icon: ICONS.resource_item.primary,
        },
        validator: GridEntityImportValidationService.validateNumber,
      });
    }

    if (entityName === Device.entityName) {
      defaultColDefs.push({
        colId: 'name',
        field: 'name',
        headerName: 'Name',
        optionalHeaderName: 'Device Name',
        isRequired: true,
        headerComponentParams: {
          icon: ICONS.device.primary,
        },
      });
    }

    const newColDefs: EntityImportColDef[] = [
      ...defaultColDefs,
      ...metadataColDefs,
    ];

    return newColDefs;
  }

  public static get defaultColDef(): ColDef & { valueClearer: null } {
    return {
      editable: true,
      lockVisible: true,
      headerComponent: DataGridColumnHeaderEntityImport,
      // needed for when resource name changes, to update the item name
      // and to check if row is not empty anymore / is valid
      onCellValueChanged: (params) => {
        // params.api.refreshHeader();
        const rowNode = params.node;
        if (!rowNode) {
          return;
        }
        params.api.refreshCells({
          rowNodes: [rowNode],
          force: true,
        });
      },
      cellStyle: (
        params: CellClassParams,
      ): CellStyle | null | undefined => {
        if (
          GridEntityImportValidationService.isRowEmpty(params.data)
        ) {
          return null;
        }
        if (!GridEntityImportValidationService.isCellValid(params)) {
          return { backgroundColor: '#F5B1B1' };
        }
        return null;
      },
      suppressKeyboardEvent: suppressKeyboardEventClearCells,
      valueClearer: null,
    };
  }

  public static generateEmptyRowData(
    nRows: number,
    columnDefs: EntityImportColDef[],
  ) {
    const rowData: EntityImportDataRowData = [];
    for (let i = 0; i < nRows; i += 1) {
      const row: EntityImportDataRowDataRow = {};
      columnDefs.forEach((colDef) => {
        row[colDef.field!] = null;
      });
      rowData.push(row);
    }
    return rowData;
  }

  public convertAGGridRowDataToItems(
    data: EntityImportDataRowData,
    targetEntityName: string,
  ): EntityImportDataItems {
    const importRows: EntityImportDataItems = {
      [targetEntityName]: [],
    };

    if (targetEntityName === Resource.entityName) {
      importRows.resource_item = [];
    }
    if (targetEntityName === ResourceItem.entityName) {
      importRows.resource = [];
      importRows.resource_location = [];
    }

    data.forEach((row: EntityImportDataRowDataRow, index: number) => {
      if (GridEntityImportValidationService.isRowEmpty(row)) {
        return;
      }

      const item: EntityImportDataItem = {
        fields: {
          entity_import_row_index: index,
        },
        metadatas: [],
        relations: {},
      };

      const [relationKeys, otherKeys] = partition(
        Object.keys(row),
        (key: string) => Object.keys(RELATIONS).includes(key),
      );

      relationKeys.forEach((key: string) => {
        const value = GridEntityImportValidationService.parseValue(
          row[key],
        );
        GridEntityImportService.processRowRelation(item, value, key);
      });
      const relations = structuredClone(
        item.relations,
      ) as EntityImportDataItemRelationWithMetadatas;

      otherKeys.forEach((key: string) => {
        const value = GridEntityImportValidationService.parseValue(
          row[key],
        );
        if (GridEntityImportValidationService.isValueEmpty(value)) {
          return;
        }

        let keyType = 'default';
        if (key === 'resource_resource_items') {
          return;
        }
        if (key.startsWith('metadata:')) {
          keyType = 'metadata';
        }
        if (key === 'amount') {
          keyType = 'amount';
        }
        if (key === 'resource_identifier') {
          keyType = 'custom_identifier';
        }
        switch (keyType) {
          case 'metadata':
            this.processRowMetadata(
              item,
              value,
              key,
              targetEntityName,
              relations,
            );
            break;
          case 'amount':
            GridEntityImportService.processRowAmount(item, value);
            break;
          case 'custom_identifier':
            item.fields!.custom_identifier = value;
            break;
          default:
            item.fields![key] = value;
            break;
        }
      });

      GridEntityImportService.appendRelationToImportRows(
        importRows,
        relations,
      );

      if (Object.keys(item.fields || []).length === 0) {
        delete item.fields;
      }
      if (Object.keys(item.metadatas || []).length === 0) {
        delete item.metadatas;
      }
      if (Object.keys(item.relations || []).length === 0) {
        delete item.relations;
      }

      const customIdentifier =
        row.resource_identifier ||
        row.resource_item_resource_identifier;
      if (
        targetEntityName === Resource.entityName &&
        (row.name || customIdentifier) &&
        row.resource_resource_items
      ) {
        GridEntityImportService.generateResourceResourceItems(
          importRows,
          row.name,
          index,
          Number(row.resource_resource_items),
          customIdentifier,
        );
      }

      importRows[targetEntityName].push(item);
    });

    if (importRows.resource_location?.length === 0) {
      delete importRows.resource_location;
    } else if (importRows.resource_location) {
      importRows.resource_location =
        GridEntityImportService.unwrapResourceLocations(importRows);
    }

    return importRows;
  }

  public static generateResourceResourceItems(
    importRows: EntityImportDataItems,
    name: string | null,
    index: number,
    resourceItemsCount: number,
    customIdentifier: string | null,
  ): void {
    for (let i = 1; i <= resourceItemsCount; i += 1) {
      const itemName = `${customIdentifier || ''}${
        customIdentifier && name ? ' - ' : ''
      }${name || ''} #${i}`.trim();
      const item: EntityImportDataItem = {
        fields: {
          name: itemName,
        },
        relations: {
          resource: {
            ...(name && {
              name,
            }),
            ...(customIdentifier && {
              custom_identifier: customIdentifier,
            }),
            entity_import_row_index: index,
          },
        },
      };
      importRows.resource_item.push(item);
    }
  }

  public static unwrapResourceLocations(
    importRows: EntityImportDataItems,
  ): EntityImportDataItem[] {
    const resourceLocationPaths: string[] =
      importRows.resource_location.map((resourceLocation) =>
        typeof resourceLocation.fields?.path === 'string'
          ? resourceLocation.fields?.path
          : '',
      );
    const uniquePaths = new Set<string>();
    resourceLocationPaths.forEach((path: string) => {
      const parts: string[] = path
        .split('>')
        .map((part) => part.trim());
      let rebuiltPath = parts[0];
      uniquePaths.add(rebuiltPath);
      Object.keys(parts).forEach((key: string) => {
        if (key === '0') {
          return;
        }
        rebuiltPath = `${rebuiltPath} > ${parts[Number(key)]}`;
        uniquePaths.add(rebuiltPath);
      });
    });

    return Array.from(uniquePaths).map((path) => ({
      fields: { path },
    }));
  }

  public static processRowAmount(
    item: EntityImportDataItem,
    value: string,
  ): void {
    const amount = convertAmountUnitToAmount(value);
    const unit = convertAmountUnitToUnit(value);
    if (amount) {
      // eslint-disable-next-line no-param-reassign
      item.fields!.amount = amount;
    }
    if (unit) {
      // eslint-disable-next-line no-param-reassign
      item.fields!.unit = unit;
    }
  }

  public static processRowRelation(
    item: EntityImportDataItem,
    value: string,
    key: string,
  ): void {
    const { field, unique_key } = RELATIONS[key];

    if (GridEntityImportValidationService.isValueEmpty(value)) {
      return;
    }

    // eslint-disable-next-line no-param-reassign
    item.relations![field] = {
      ...item.relations![field],
      [unique_key]: value,
    };
  }

  public static appendRelationToImportRows(
    importRows: EntityImportDataItems,
    relations: EntityImportDataItemRelationWithMetadatas,
  ): void {
    Object.keys(relations).forEach((key) => {
      const relationItem = relations[key];
      const { metadatas, ...fields } = relationItem;
      const uniqueKey =
        fields.custom_identifier && 'custom_identifier';
      const { entity_name, unique_key } = Object.values(
        RELATIONS,
      ).find(
        (r) =>
          r.field === key &&
          (!uniqueKey || r.unique_key === uniqueKey),
      )!;

      const entity = importRows[entity_name].find(
        (i: EntityImportDataItem) =>
          i.fields![unique_key] ===
          relationItem[
            unique_key as 'custom_identifier' | 'name' | 'path'
          ],
      );
      if (!entity) {
        const item = { fields, metadatas };
        importRows[entity_name].push(item);
      } else {
        entity.fields = { ...entity.fields, ...fields };
        if (metadatas) {
          entity.metadatas = uniqWith(
            [...(entity.metadatas || []), ...metadatas],
            (a, b) =>
              a.template_guid === b.template_guid &&
              a.fields.value === b.fields.value,
          );
        }
      }
    });
  }

  public processRowMetadata(
    item: EntityImportDataItem,
    value: string,
    key: string,
    targetEntityName: string,
    relations: EntityImportDataItemRelationWithMetadatas,
  ): void {
    const parts = key.split(':');
    const metadataParentEntityClass = parts[1];
    const metadataType = parts[2];
    const metadataGuid = parts[3];
    const colDef = this.getColDefByColId(key);
    if (!colDef) {
      return;
    }
    const metadataFields = GridEntityImportService.getMetadataFields(
      value,
      metadataType,
      colDef,
    );
    if (Object.keys(metadataFields).length === 0) {
      return;
    }
    const metadata = {
      template_guid: metadataGuid,
      fields: metadataFields,
    };
    if (metadataParentEntityClass === targetEntityName) {
      item.metadatas!.push(metadata);
    } else if (relations[metadataParentEntityClass]) {
      const { metadatas } = relations[metadataParentEntityClass];
      // eslint-disable-next-line no-param-reassign
      relations[metadataParentEntityClass].metadatas = [
        ...(metadatas || []),
        metadata,
      ];
    }
  }

  public static getMetadataFields(
    data: string | null,
    metadataType: string,
    colDef: EntityImportColDef,
  ): Record<string, unknown> {
    if (!data) {
      return {};
    }
    if (metadataType === MetadataType.default) {
      return {
        type: MetadataType.default,
        value: data,
      };
    }
    if (metadataType === MetadataType.numeric) {
      const number = convertAmountUnitToAmount(data);
      const unit = convertAmountUnitToUnit(data);
      return {
        type: MetadataType.numeric,
        number: number ? Number(number) : undefined,
        unit: unit || undefined,
      };
    }
    if (metadataType === MetadataType.date) {
      return {
        type: MetadataType.date,
        date: GridEntityImportValidationService.formatDateISO(
          data,
          colDef,
          'date',
        ),
      };
    }
    if (metadataType === MetadataType.datetime) {
      return {
        type: MetadataType.datetime,
        date: GridEntityImportValidationService.formatDateISO(
          data,
          colDef,
          'datetime',
        ),
      };
    }
    if (metadataType === MetadataType.options) {
      return {
        type: MetadataType.options,
        options: GridEntityImportService.generateOptions(data),
      };
    }
    return {};
  }

  public static generateOptions(value: string): MetadataOptions {
    return {
      is_allow_add: false,
      is_allow_multiple: false,
      values: value
        .split(',')
        .map((v) => v.trim())
        .reduce((result: Record<string, boolean>, v: string) => {
          // eslint-disable-next-line no-param-reassign
          result[v] = true;
          return result;
        }, {}),
    };
  }

  public static getMetadataDateFormat(
    metadata: Metadata,
  ): string | undefined {
    if (
      metadata.type === MetadataType.date ||
      metadata.type === MetadataType.datetime
    ) {
      return ENTITY_IMPORT_DEFAULT_DATE_FORMAT[metadata.type];
    }

    return undefined;
  }
}
