/**
 * Labstep
 *
 * @module components/Protocol/Action/UpdateIsDraft
 * @desc Protocol field required
 */

import Can from 'labstep-web/components/Entity/Can';
import { Action } from 'labstep-web/components/Entity/Can/types';
import { ActionComponent } from 'labstep-web/core/Action/Component';
import EditForm from 'labstep-web/core/Form/Edit';
import { FieldType } from 'labstep-web/core/Form/Reusable/types';
import Message from 'labstep-web/core/Message';
import ModalDefault from 'labstep-web/core/Modal/Default';
import React from 'react';
import { fieldProtocolChanges } from '../../Form/fields';
import { IProtocolActionUpdateIsDraftProps } from './types';

export const ProtocolActionUpdateIsDraft: React.FC<
  IProtocolActionUpdateIsDraftProps
> = ({ protocol, protocolCollection }) => {
  return (
    <ModalDefault
      header="Set New Version Live"
      content={({ toggleModal }) => (
        <EditForm
          entityName={protocol.entityName}
          entity={protocol}
          autoFocus
          preSubmit={(values) => {
            return { ...values, is_draft: false };
          }}
          onCancel={toggleModal}
          submitButtonText="Set Live"
          fields={[
            fieldProtocolChanges,
            {
              fieldType: FieldType.action,
              component: () => (
                <Message
                  warning
                  children={
                    <span>
                      Once the new version is <b>live</b> it can be
                      run and added to experiments by members of your
                      workspace. However, to make further edits you
                      will have to create a new version.
                    </span>
                  }
                />
              ),
            },
          ]}
        />
      )}
      viewComponent={({ toggleModal }) => (
        <Can
          entityName={protocolCollection.entityName}
          id={protocolCollection.idAttr}
          action={`protocol:set_live` as Action}
        >
          <ActionComponent
            type="text"
            icon="check"
            text="Set Live"
            onClick={toggleModal}
          />
        </Can>
      )}
    />
  );
};

export default ProtocolActionUpdateIsDraft;
