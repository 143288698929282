/**
 * Labstep
 *
 * @module components/Comment/Action/Menu
 * @desc Comment options (Delete, Edit)
 */

import ThreadCountIcon from 'labstep-web/components/Thread/CountIcon';
import ProtocolChildRouter from 'labstep-web/containers/Router/ProtocolChild';
import Modal from 'labstep-web/core/Modal/Default';
import React from 'react';
import { useLocation } from 'react-router-dom';
import CommentThreadDetail from './Detail';
import {
  ICommentThreadContainerProps,
  ICommentThreadProps,
} from './types';

export const CommentThread: React.FC<ICommentThreadProps> = ({
  comment,
  viewComponent: customViewComponent,
  navigate,
}) => {
  const viewComponent =
    customViewComponent ||
    (({ toggleModal }) => (
      <ThreadCountIcon
        onClick={toggleModal}
        count={comment.thread.comment_count}
        popup="Comments"
      />
    ));

  return navigate ? (
    viewComponent({
      toggleModal: navigate,
    })
  ) : (
    <Modal
      header="Thread"
      viewComponent={viewComponent}
      content={<CommentThreadDetail comment={comment} />}
    />
  );
};

export const CommentThreadContainer: React.FC<
  ICommentThreadContainerProps
> = (props) => {
  const currentLocation = useLocation();

  let route = 'thread_show';
  let { id }: { id: number | string } = props.comment;
  let params = {};
  let state;

  const { protocol_step, metadata } = props.comment.parent_thread;

  if (protocol_step) {
    route = 'step_show_thread_show';
    params = { threadId: id };
    id = protocol_step.guid;
    state = { referrer: currentLocation };
  } else if (metadata) {
    route = 'metadata_show_thread_show';
    params = { threadId: id };
    id = metadata.id;
    state = { referrer: currentLocation };
  }

  return (
    <ProtocolChildRouter
      route={route}
      id={id}
      params={params}
      state={state}
    >
      {({ navigate }) => (
        <CommentThread {...props} navigate={navigate} />
      )}
    </ProtocolChildRouter>
  );
};

export default CommentThreadContainer;
