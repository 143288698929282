/**
 * Labstep
 *
 * @module components/Layout/Menu/Left
 * @desc Left side bar. Shows navigation options. Shown only on mobile.
 */

import { withSidebar } from 'labstep-web/containers/Ui/Sidebar';
import Sidebar from 'labstep-web/core/Sidebar';
import React from 'react';
import LayoutMenuLeftContent from './Content';
import styles from './styles.module.scss';
import { LayoutMenuLeftProps } from './types';

export const menuName = 'left';

export const LayoutMenuLeft: React.FC<LayoutMenuLeftProps> = ({
  sidebar,
  setSidebar,
}) => (
  <div className={styles.sidebarStyles}>
    <Sidebar
      animation="overlay"
      visible={!!sidebar[menuName]}
      onHide={(): void => {
        setSidebar(false, menuName);
      }}
    >
      <LayoutMenuLeftContent />
    </Sidebar>
  </div>
);

export default withSidebar(LayoutMenuLeft);
