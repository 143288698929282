import { ParamsHOCChildrenProps } from 'labstep-web/hoc/Params/types';
import { ShareLink } from 'labstep-web/models/share-link.model';

// eslint-disable-next-line no-shadow
export enum State {
  collapsed = 'collapsed',
  uncollapsed = 'uncollapsed',
}

type IExperimentWorkflowView = 'table' | 'timeline';

interface FilterContent {
  state: State;
}

interface Filter {
  created_by?: FilterContent;
  tag?: FilterContent;
  assignee?: FilterContent;
  resource_template?: FilterContent;
  status?: FilterContent;
  status_type?: FilterContent;
  unstarted?: FilterContent;
  started?: FilterContent;
  completed?: FilterContent;
  extension?: FilterContent;
  tag_type?: FilterContent;
  signatures?: FilterContent;
}

export interface Filters {
  device: Filter;
  entity_state: Filter;
  entity_view: Filter;
  experiment_workflow: Filter;
  file: Filter;
  order_request: Filter;
  protocol_collection: Filter;
  purchase_order: Filter;
  resource: Filter;
  resource_item: Filter;
  tag: Filter;
}

export interface IUiPersistentState {
  filters: Filters;
  active_share_link: ShareLink | null;
  experiment_workflow_view: IExperimentWorkflowView;
  count_per_page: number;
  search_params: ParamsHOCChildrenProps['searchParams'];
  entity_view_params: Record<string, Record<string, unknown>>;
}

export interface TypeToggleUiFilterAction {
  type: 'SET_UI_FILTER';
  entityName: keyof Filters;
  filter: keyof Filter;
}
export interface TypeSetUiCountPerPageAction {
  type: 'SET_UI_COUNT_PER_PAGE';
  count: number;
}

export interface TypeResetAction {
  type: 'RESET_UI_PERISTENT';
}

export const SetEntityViewParamsActionType = 'SET_ENTITY_VIEW_PARAMS';
export interface TypeSetEntityViewParamsAction {
  type: typeof SetEntityViewParamsActionType;
  meta: {
    body: {
      entityName: string;
      groupId: string;
      params: Record<string, unknown>;
    };
  };
}

export type TypeResetActionCreator = () => TypeResetAction;

export interface TypeSetUiExperimentWorkflowViewAction {
  type: 'SET_UI_EXPERIMENT_WORKFLOW_VIEW';
  view: IExperimentWorkflowView;
}

export interface TypeSetUiSearchParamsAction {
  type: 'SET_UI_SEARCH_PARAMS';
  pathname: string;
  params: ParamsHOCChildrenProps['searchParams'];
}

export type TypeToggleUiFilterActionCreator = (
  entityName: TypeToggleUiFilterAction['entityName'],
  filter: TypeToggleUiFilterAction['filter'],
) => TypeToggleUiFilterAction;

export type TypeSetUiExperimentWorkflowViewActionCreator = (
  view: TypeSetUiExperimentWorkflowViewAction['view'],
) => TypeSetUiExperimentWorkflowViewAction;

export type TypeSetUiSearchParamsActionCreator = (
  pathname: TypeSetUiSearchParamsAction['pathname'],
  params: TypeSetUiSearchParamsAction['params'],
) => TypeSetUiSearchParamsAction;

export type TypeSetUiCountPerPageActionCreator = (
  count: TypeSetUiCountPerPageAction['count'],
) => TypeSetUiCountPerPageAction;

export type TypeSetEntityViewParamsActionCreator = (
  entityName: TypeSetEntityViewParamsAction['meta']['body']['entityName'],
  groupId: TypeSetEntityViewParamsAction['meta']['body']['groupId'],
  params: TypeSetEntityViewParamsAction['meta']['body']['params'],
) => TypeSetEntityViewParamsAction;
