/**
 * Labstep
 *
 * @module components/Metadata/Action/Create/Modal/Message
 * @desc Message for metadata template field
 */

import Message from 'labstep-web/core/Message';
import { getHumanReadableEntityName } from 'labstep-web/services/i18n.service';
import React from 'react';
import { MetadataActionCreateMessageProps } from './types';

export const MetadataActionCreateModalMessage: React.FC<
  MetadataActionCreateMessageProps
> = ({ entityName }) => {
  const entities = getHumanReadableEntityName(entityName, true);
  return (
    <Message color="yellow">
      {`You are adding a template field. This field will
  appear on all ${entities} using this template.`}
    </Message>
  );
};

export default MetadataActionCreateModalMessage;
