/**
 * Labstep
 *
 * @module components/Experiment/Info
 * @desc Experiment Info as Table
 */

import EntityLink from 'labstep-web/components/Entity/Link';
import ExperimentFormShowEditDates from 'labstep-web/components/Experiment/Form/ShowEdit/Dates';
import HorizontalTable from 'labstep-web/core/Table/Horizontal';
import { Experiment } from 'labstep-web/models/experiment.model';
import React from 'react';
import { ExperimentInfoProps } from './types';

export const getRows = (experiment: Experiment) => {
  const rows = [
    {
      header: 'Version used in',
      content: () => (
        <EntityLink
          entity={experiment.experiment_workflow}
          showIcon
          secondary
        />
      ),
    },
  ];
  if (experiment.protocol) {
    rows.push({
      header: 'Based on',
      content: () => (
        <EntityLink
          entity={experiment.protocol}
          displayName={experiment.protocol.nameAndVersion}
          showIcon
          secondary
        />
      ),
    });
  }

  if (experiment.started_at && !experiment.isTemplate) {
    rows.push({
      header: 'Dates',
      content: () => (
        <ExperimentFormShowEditDates experiment={experiment} />
      ),
    });
  }

  return rows;
};

const ExperimentInfo: React.FC<ExperimentInfoProps> = ({
  experiment,
}) => (
  <HorizontalTable
    borderless
    headerProps={{ width: 3 }}
    rows={getRows(experiment)}
    columns={[experiment]}
  />
);

export default ExperimentInfo;
