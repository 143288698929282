/**
 * Labstep
 *
 * @module components/Entity/Card/Data
 * @desc Card for data elements
 */

import CardGeneric from 'labstep-web/core/Card/Generic';
import Icon from 'labstep-web/core/Icon';
import { humanReadableDate } from 'labstep-web/services/date.service';
import React from 'react';
import styles from './styles.module.scss';
import { EntityCardDataProps } from './types';

export const EntityCardData: React.FC<EntityCardDataProps> = ({
  label,
  origin,
  icon,
  date,
  value,
  actions,
}) => (
  <CardGeneric
    topLeft={<Icon name={icon} />}
    headerRight={
      <div className={styles.date}>{humanReadableDate(date)}</div>
    }
    headerTitle={label}
    customSubtitle={origin && <span>from {origin}</span>}
    body={<div className={styles.value}>{value}</div>}
    footer={<div className={styles.actions}>{actions}</div>}
  />
);

export default EntityCardData;
