/**
 * Labstep
 *
 * @module prosemirror/components/NodeView/ExperimentWorkflowLink
 * @desc Renders an ExperimentWorkflowLink node
 */

import { ICONS } from 'labstep-web/constants/icons';
import Link from 'labstep-web/core/Link';
import { Entity } from 'labstep-web/models/entity.model';
import { ExperimentWorkflowLink } from 'labstep-web/models/experiment-workflow-link.model';
import ViewInline from 'labstep-web/prosemirror/components/Inline/View';
import Read from 'labstep-web/prosemirror/components/NodeView/Read';
import { useReactNodeView } from 'labstep-web/prosemirror/components/ReactNodeView';
import React from 'react';
import { IExperimentWorkflowLinkNodeProps } from './types';

export class ExperimentWorkflowLinkNode extends React.Component<
  IExperimentWorkflowLinkNodeProps,
  any
> {
  shouldComponentUpdate() {
    return Boolean(!this);
  }

  render() {
    const { id, timestamp } = this.props;
    return (
      <Read
        entityName={ExperimentWorkflowLink.entityName}
        id={id}
        timestamp={timestamp}
      >
        {({ entity: experimentWorkflowLink }: { entity: Entity }) => (
          <Link
            to="experiment_workflow_show"
            params={{
              id: (experimentWorkflowLink as ExperimentWorkflowLink)
                .dest.id,
            }}
          >
            <ViewInline
              entity={experimentWorkflowLink}
              icon={ICONS.experiment_workflow.primary}
            >
              {
                (experimentWorkflowLink as ExperimentWorkflowLink)
                  .dest.displayName
              }
            </ViewInline>
          </Link>
        )}
      </Read>
    );
  }
}

const ExperimentWorkflowLinkNodeView = ({
  entity,
  timestamp,
}: any) => {
  const context = useReactNodeView();
  const { guid } = (context.node as any).attrs;
  return (
    <ExperimentWorkflowLinkNode
      experiment={entity}
      id={guid}
      timestamp={timestamp}
    />
  );
};

export default ExperimentWorkflowLinkNodeView;
