/**
 * Labstep
 *
 * @module components/File/Image
 * @desc Component to show a file of image type
 */

import cx from 'classnames';
import FileLightbox from 'labstep-web/components/File/Lightbox';
import { ThumbnailContainer } from 'labstep-web/containers/File/Thumbnail';
import { ToggleContainer } from 'labstep-web/containers/Toggle';
import Image from 'labstep-web/core/Image';
import React from 'react';
import { Placeholder } from 'semantic-ui-react';
import styles from './styles.module.scss';
import {
  FileImageProps,
  FileImageToggleContainerProps,
} from './types';

/**
 * @desc Renders a file image
 */
export const FileImage: React.FC<FileImageProps> = ({
  file,
  expandable,
  toggle,
  toggled,
  className,
}) => (
  <ThumbnailContainer
    file={file.image_annotation ? file.image_annotation : file}
  >
    {({ src, status, onError }) => (
      <>
        <div
          onClick={
            expandable && status === 'loaded' ? toggle : undefined
          }
          className={styles.imageContainer}
        >
          {status === 'loading' ? (
            <Placeholder>
              <Placeholder.Image className={styles.placeholder} />
            </Placeholder>
          ) : (
            <Image
              id={`image-${file.id}`}
              className={cx(styles.image, className, 'lr-hide-this')}
              src={src}
              onError={onError}
              loading="lazy"
            />
          )}
        </div>
        {toggled && toggle && (
          <FileLightbox
            file={file}
            toggle={toggle}
            onError={onError}
          />
        )}
      </>
    )}
  </ThumbnailContainer>
);

FileImage.defaultProps = {
  expandable: true,
};

export const FileImageToggleContainer: React.FC<
  FileImageToggleContainerProps
> = (props) => (
  <ToggleContainer>
    {(containerProps) => <FileImage {...props} {...containerProps} />}
  </ToggleContainer>
);

export default FileImageToggleContainer;
