/**
 * Labstep
 *
 * @module components/Layout/FullScreenPage
 * @desc Layout for a full screen page
 */

import React from 'react';
import styles from './styles.module.scss';
import { FullScreenPageProps } from './types';

const FullScreenPage: React.FC<FullScreenPageProps> = ({
  children,
}) => (
  <div className={styles.outerContainer}>
    <div className={styles.innerContainer}>
      <div className={styles.children}>{children}</div>
    </div>
  </div>
);

export default FullScreenPage;
