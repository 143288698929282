/**
 * Labstep
 *
 * @module core/Form/Create
 * @desc Core Create Form. Reusable for entities.
 */

import { EntityCreateContainer } from 'labstep-web/containers/Entity/Create';
import ReusableForm from 'labstep-web/core/Form/Reusable';
import { getFieldsWithValidationRules } from 'labstep-web/services/validation';
import React from 'react';
import {
  CoreFormCreateProps,
  IFormCreateContainerProps,
} from './types';

export const FormCreate: React.FC<CoreFormCreateProps> = ({
  options,
  fields,
  status,
  entityName,
  ...rest
}) => (
  <ReusableForm
    submitButtonText="Create"
    fields={getFieldsWithValidationRules(fields, entityName)}
    status={status}
    {...rest}
  />
);

const FormCreateContainer: React.FC<IFormCreateContainerProps> = (
  props,
) => (
  <EntityCreateContainer
    entityName={props.entityName}
    parentName={props.parentName}
    parentId={props.parentId}
    childKeyName={props.childKeyName}
    showLoaderWhileCreating={props.showLoaderWhileCreating}
  >
    {({ create, status }): React.ReactElement => {
      const onSubmit = (values: Record<string, unknown>): void => {
        create({ ...values, ...props.body }, props.options);
      };
      return (
        <FormCreate status={status} onSubmit={onSubmit} {...props} />
      );
    }}
  </EntityCreateContainer>
);

export default FormCreateContainer;
