/**
 * Labstep
 *
 * @module containers/Entity/Toggle
 * @desc Container for adding/removing children entities
 */

import { APICallOptions, APICallStatus } from 'labstep-web/models';
import {
  addEntity,
  removeEntity,
  setEntity,
} from 'labstep-web/state/actions/entity';
import { selectToggleStatus } from 'labstep-web/state/selectors/entity';
import { LabstepReduxState } from 'labstep-web/state/types';
import { connect } from 'react-redux';

export interface IEntityToggleContainerProps {
  entityName: string;
  entityIds?: string | (string | number)[] | number;
  parentName: string;
  parentId: string | (string | number)[] | number;
  action?: string;
  childKeyName?: string;
  single?: boolean;
}

export type IEntityToggleContainerChildrenProps = ReturnType<
  typeof mapDispatchToProps
> &
  ReturnType<typeof mapStateToProps>;

const Container = ({
  children,
  ...rest
}: {
  children: (
    props: IEntityToggleContainerChildrenProps,
  ) => React.ReactElement | null;
} & IEntityToggleContainerChildrenProps) => children(rest);

const mapStateToProps = (
  state: LabstepReduxState,
  ownProps: IEntityToggleContainerProps,
): {
  status: APICallStatus;
} => ({
  status: ownProps.entityIds
    ? selectToggleStatus(
        state,
        ownProps.entityName,
        ownProps.entityIds,
        ownProps.parentName,
        ownProps.parentId,
      )
    : false,
});

const mapDispatchToProps = (
  dispatch: (...args: any[]) => void,
  ownProps: IEntityToggleContainerProps,
): {
  toggle: (
    action?: string,
    ids?: number[],
    options?: APICallOptions,
  ) => void;
} => ({
  /**
   * Add or Remove a child Entity to/from parentEntity
   * @param  {string} action - 'add'/'remove'
   * @param  {array|number} ids - EntityIds
   * @param  {object} options - Additional options
   */
  toggle(action, ids, options) {
    // Ownprops get priority
    const actionType = ownProps.action || action;
    const entityIds = ownProps.entityIds || ids;

    if (!entityIds) {
      return;
    }

    if (actionType === 'add') {
      dispatch(
        addEntity(
          ownProps.entityName,
          entityIds,
          ownProps.parentName,
          ownProps.parentId,
          {
            ...options,
            childKeyName: ownProps.childKeyName,
          },
        ),
      );
    } else if (actionType === 'remove') {
      dispatch(
        removeEntity(
          ownProps.entityName,
          entityIds,
          ownProps.parentName,
          ownProps.parentId,
          {
            ...options,
            childKeyName: ownProps.childKeyName,
            single: ownProps.single,
          },
        ),
      );
    } else if (actionType === 'set') {
      dispatch(
        setEntity(
          ownProps.entityName,
          entityIds,
          ownProps.parentName,
          ownProps.parentId,
          {
            ...options,
            childKeyName: ownProps.childKeyName,
            single: ownProps.single,
          },
        ),
      );
    }
  },
});

/**
 * Toggle Cotainer connecting a component with state
 * Returns orginal component with
 * toggle function and status of action
 */
export const EntityToggleContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(Container);
