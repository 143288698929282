/**
 * Labstep
 *
 * @module components/ExperimentWorkflow/Filter/Signed/Select
 * @desc Select filter for extensions
 */

import { isActive } from 'labstep-web/components/Filter/List/utils';
import ActionComponent from 'labstep-web/core/Action/Component';
import Modal from 'labstep-web/core/Modal/Default';
import ReactSelect from 'labstep-web/core/Select';
import { SignedTypes } from 'labstep-web/models/experiment-workflow.model';
import React from 'react';
import { placeholder } from './strings';
import { ExperimentWorkflowFilterSignedSelectProps } from './types';

const ExperimentWorkflowFilterSignedSelect: React.FC<
  ExperimentWorkflowFilterSignedSelectProps
> = ({ setParams, searchParams }) => {
  const activeSignedType = Object.values(SignedTypes).find((value) =>
    isActive(searchParams, value.params),
  );

  return (
    <Modal
      header={'Signatures'}
      content={({ toggleModal }) => (
        <ReactSelect
          value={
            activeSignedType
              ? {
                  label: activeSignedType.text,
                  value: activeSignedType.params,
                }
              : null
          }
          options={Object.values(SignedTypes).map((value) => ({
            label: value.text,
            value: value.params,
          }))}
          isClearable
          onChange={(selectedOption) => {
            setParams(
              selectedOption
                ? selectedOption.value
                : Object.keys(
                    SignedTypes.request_pending.params,
                  ).reduce(
                    (result, key) => ({
                      ...result,
                      [key]: undefined,
                    }),
                    {},
                  ),
            );
            toggleModal();
          }}
          placeholder={placeholder}
        />
      )}
      viewComponent={({ toggleModal }) => (
        <ActionComponent
          type="option"
          icon={SignedTypes.signed.icon}
          text={'Signatures'}
          onClick={toggleModal}
        />
      )}
    />
  );
};

export default ExperimentWorkflowFilterSignedSelect;
