/**
 * Labstep
 *
 * @module grid/Index/components/ToolPanel/Columns/Section
 * @desc Entity DataGrid column options section for shown/hidden fields
 */

import Header from 'labstep-web/core/Header';
import Sortable from 'labstep-web/core/Sortable';
import React, { useMemo } from 'react';
import GridIndexToolPanelColumnsSectionItem from './Item';
import {
  ColumnWithId,
  GridIndexToolPanelColumnsSectionProps,
  SectionType,
} from './types';
import { columnsToItems, onSortEndColumns } from './utils';

export const GridIndexToolPanelColumnsSection: React.FC<
  GridIndexToolPanelColumnsSectionProps
> = ({ columns, section, onToggleColumnVisible, onSortColumns }) => {
  if (!columns.length) {
    return null;
  }
  const items: { id: string; column: ColumnWithId }[] = useMemo(
    () => columnsToItems(columns),
    [columns],
  );

  const onSortEnd: Parameters<typeof Sortable>[0]['onSortEnd'] =
    useMemo(
      () => onSortEndColumns(items, onSortColumns),
      [items, onSortColumns],
    );

  return (
    <div>
      <Header size="mini">{`${
        section === SectionType.hidden ? 'Hidden' : 'Shown'
      } fields`}</Header>
      <Sortable
        /** dnd-kit transform does not work with overlay in this case */
        hideOverlay
        items={items}
        onSortEnd={onSortEnd}
        renderItem={({ column }): JSX.Element => (
          <GridIndexToolPanelColumnsSectionItem
            column={column}
            section={section}
            onToggleColumnVisible={onToggleColumnVisible}
          />
        )}
      />
    </div>
  );
};

export default GridIndexToolPanelColumnsSection;
