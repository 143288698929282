/**
 * Labstep
 *
 * @module services/react
 * @desc Helper methods for react
 */

import { Entity } from 'labstep-web/models/entity.model';

export const objectOrFunction: any = (
  component: React.ReactNode,
  props: Record<string, unknown>,
) => {
  if (typeof component === 'function') {
    return component(props);
  }
  return component;
};

/** Call a function if defined */
export const callIf = (
  f: any,
  ...args: (string | Record<string, unknown> | Entity)[]
) => {
  if (f && typeof f === 'function') {
    return f(...args);
  }
  return null;
};
