/**
 * Labstep
 *
 * @module components/EntityStateWorkflow/Card/EmptyState
 * @desc Empty state for EntityStateWorkflow
 */

import EntityStateWorkflowActionCreate from 'labstep-web/components/EntityStateWorkflow/Action/Create';
import EmptyState from 'labstep-web/core/Card/EmptyState';
import { EntityStateWorkflow } from 'labstep-web/models/entity-state-workflow.model';
import { ExperimentWorkflow } from 'labstep-web/models/experiment-workflow.model';
import React from 'react';
import { EntityStateWorkflowCardEmptyStateProps } from './types';

const explanation = `
Define the different states your ${ExperimentWorkflow.getHumanReadableEntityName(
  true,
)} must pass through before completion. Define roles to be assigned at each stage and signature requirements that must be met to progress.
`;

export const EntityStateWorkflowCardEmptyState: React.FC<
  EntityStateWorkflowCardEmptyStateProps
> = ({ group }) => (
  <EmptyState
    title={`No ${EntityStateWorkflow.getHumanReadableEntityName(
      true,
      true,
    )}`}
    explanation={explanation}
    src="/img/empty-state/state-workflow.svg"
    action={<EntityStateWorkflowActionCreate group={group} />}
  />
);

export default EntityStateWorkflowCardEmptyState;
