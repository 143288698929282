/**
 * Labstep
 */

import { PostFilterMetadatas } from 'labstep-web/services/postFilter/metadatas';
import { PostFilterComparison } from 'labstep-web/services/query-parameter.service';

export const getPostFilterMetadatasHasValue = (path: string) =>
  new PostFilterMetadatas(
    [
      ['label', PostFilterComparison.eq],
      ['value', PostFilterComparison.not_null],
    ],
    path,
  );

export const getPostFilterMetadatasDefault = (path: string) =>
  new PostFilterMetadatas(
    [
      ['label', PostFilterComparison.eq],
      ['value', PostFilterComparison.eq],
    ],
    path,
  );

export const getPostFilterMetadatasNumeric = (path: string) =>
  new PostFilterMetadatas(
    [
      ['label', PostFilterComparison.eq],
      ['number', PostFilterComparison.eq],
    ],
    path,
  );

export const getPostFilterMetadatasDate = (path: string) =>
  new PostFilterMetadatas(
    [
      ['label', PostFilterComparison.eq],
      ['date', PostFilterComparison.eq],
    ],
    path,
  );

export const getPostFilterMetadatasOptions = (path: string) =>
  new PostFilterMetadatas(
    [
      ['label', PostFilterComparison.eq],
      ['options', PostFilterComparison.contains],
    ],
    path,
  );
