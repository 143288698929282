/**
 * Labstep
 *
 * @module components/ProtocolCollection/Action/Share
 * @desc Share a protocolCollection with all users on the platform
 */

import EntityActionShare from 'labstep-web/components/Entity/Action/Share';
import React from 'react';
import { IProtocolCollectionActionShareProps } from './types';

const ProtocolCollectionActionShare: React.FC<
  IProtocolCollectionActionShareProps
> = ({ protocolCollection }) => (
  <EntityActionShare entity={protocolCollection} />
);

export default ProtocolCollectionActionShare;
