/**
 * Labstep
 *
 * @module components/DataTable/Item
 * @desc Display Editable DataTable
 */

import TableFormEdit from 'labstep-web/components/Table/Form/Edit';
import TableFormShowEditName from 'labstep-web/components/Table/Form/ShowEdit/Name';
import Modal from 'labstep-web/core/Modal/Default';
import { APICallStatus } from 'labstep-web/models';
import React from 'react';
import { ITableModalProps } from './types';

export const TableModal: React.FC<ITableModalProps> = ({
  table,
  onDone,
  readOnly,
  allowLocking,
  ...rest
}) => {
  const [debouncingStatus, setDebouncingStatus] =
    React.useState<APICallStatus>();

  const handleStatusChange = React.useCallback(
    (status: APICallStatus) => {
      setDebouncingStatus(status);
    },
    [],
  );

  return (
    <Modal
      size="fullscreen"
      header={({ toggleModal }) => (
        <TableFormShowEditName
          table={table}
          onDone={() => {
            if (onDone) {
              onDone();
            }
            toggleModal();
          }}
          status={debouncingStatus}
        />
      )}
      closeIcon={false}
      content={() => (
        <TableFormEdit
          table={table}
          readOnly={readOnly}
          allowLocking={allowLocking}
          onStatusChange={handleStatusChange}
        />
      )}
      {...rest}
    />
  );
};

export default TableModal;
