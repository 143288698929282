/**
 * Labstep
 *
 * @module components/Layout/Menu/TopBar/Mobile
 * @desc Mobile top bar
 */

import GroupItem from 'labstep-web/components/Group/Item';
import OrganizationItem from 'labstep-web/components/Organization/Item';
import { withActiveGroup } from 'labstep-web/containers/ActiveGroup';
import { withAuthenticatedUserEntity } from 'labstep-web/containers/AuthenticatedUser';
import { withSidebar } from 'labstep-web/containers/Ui/Sidebar';
import Icon from 'labstep-web/core/Icon';
import React from 'react';
import Title from '../Title';
import styles from './styles.module.scss';
import { LayoutMenuBreadcrumbMobileProps } from './types';

export const BreadcrumbMobile: React.FC<
  LayoutMenuBreadcrumbMobileProps
> = ({
  breadcrumb,
  activeGroup,
  authenticatedUserEntity,
  setSidebar,
}) => {
  const { group, title } = breadcrumb;

  let component: React.ReactNode;

  if (title) {
    component = <Title title={title} />;
  } else if (group) {
    component = <GroupItem group={activeGroup} />;
  } else {
    component = authenticatedUserEntity.userOrganization && (
      <OrganizationItem
        organization={
          authenticatedUserEntity.userOrganization.organization
        }
      />
    );
  }

  return (
    <div
      className={styles.sidebar}
      onClick={() => {
        setSidebar(true, 'left');
      }}
    >
      <Icon className={styles.sidebarIcon} name="bars" size="large" />
      {component}
    </div>
  );
};

const BreadcrumbMobileWrapper = withAuthenticatedUserEntity(
  withActiveGroup(withSidebar(BreadcrumbMobile)),
);

export default BreadcrumbMobileWrapper;
