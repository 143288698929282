/**
 * Labstep
 *
 * @module state/new/client
 * @desc Selectors/Reducers for Client
 */

import { Action } from 'labstep-web/models/action.model';
import { combineReducers } from 'redux';
import { createSelector } from 'reselect';
import { v4 } from 'uuid';
import { LabstepReduxState } from '../types';

/**
 * Select client uuid
 *
 * @function
 */
export const selectClientUuid = createSelector(
  (state: LabstepReduxState) => (state.client as any).uuid,
  (uuid: string) => uuid,
);

/**
 * Generate client uuid at login
 *
 * @function
 * @param  {object} state - Redux state (only the portion for this reducer)
 * @param  {string} action - Redux action
 * @return {object}
 */
export const reducerUuid = (
  state = null,
  action: Action,
): string | null => {
  switch (action.type) {
    case 'SUCCESS_INTERNAL_LOGIN':
      return v4();
    case 'SUCCESS_LOGOUT':
    case 'WEBSOCKET_DESTROY':
      return null;
    default:
      if (state === null) {
        return v4();
      }
      return state;
  }
};

export const clientReducers = combineReducers({
  uuid: reducerUuid,
});
