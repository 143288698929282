/**
 * Labstep
 *
 * @module components/Group/Card/About/Members
 * @desc List of members
 */

import { GroupActionManageMembers } from 'labstep-web/components/Group/Action/ManageMembers';
import { GroupActionShare } from 'labstep-web/components/Group/Action/Share';
import UserList from 'labstep-web/components/User/List/Cap';
import Flex from 'labstep-web/core/Flex';
import Header from 'labstep-web/core/Header';
import Popup from 'labstep-web/core/Popup';
import React from 'react';
import styles from './styles.module.scss';
import { GroupCardAboutMembersProps } from './types';

export const GroupCardAboutMembers: React.FC<
  GroupCardAboutMembersProps
> = ({ group, manageMembers = true }) => (
  <Flex shrink={0} vAlignContent="bottom">
    <Flex wrap vAlignContent="center" shrink={0}>
      <Header size="mini" className={styles.membersHeader}>
        Members
      </Header>
      <Flex
        style={{
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Flex>
          <UserList params={{ group_id: group.id }} />
          {manageMembers && (
            <Popup
              trigger={
                <GroupActionManageMembers group={group} iconOnly />
              }
              content="Manage Members"
              size="mini"
            />
          )}
        </Flex>
        <GroupActionShare
          group={group}
          actionComponentProps={{
            type: 'button',
          }}
        />
      </Flex>
    </Flex>
  </Flex>
);

export default GroupCardAboutMembers;
