/**
 * Labstep
 *
 * @module components/EntityView/Filter/Pane
 * @desc Filter pane for EntityView
 */

import { Filter } from 'labstep-web/components/Filter/Menu/types';
import FilterPane from 'labstep-web/components/Filter/Pane';
import { ICONS } from 'labstep-web/constants/icons';
import { EntityView } from 'labstep-web/models/entity-view.model';
import React from 'react';

const EntityViewFilterPane: React.FC = () => (
  <FilterPane
    entityName={EntityView.entityName}
    filters={[Filter.created_by, Filter.tag]}
    icon={ICONS.entity_view.primary}
    text="Filters"
  />
);

export default EntityViewFilterPane;
