/**
 * Labstep
 */

import ExperimentLive from 'labstep-web/components/ExperimentWorkflow/Live';
import Dropdown from 'labstep-web/core/Dropdown';
import Icon from 'labstep-web/core/Icon';
import Mobile from 'labstep-web/core/MediaQuery/Mobile';
import { intercomService } from 'labstep-web/services/intercom.service';
import React from 'react';

export const LayoutMenuTopBarProfileMobile: React.FC = () => (
  <Mobile>
    <Dropdown.Item
      as="a"
      className="custom-intercom-launcher"
      onClick={intercomService.open}
      href="#"
    >
      <Icon name="chat" />
      Chat to us
    </Dropdown.Item>
    <Dropdown.Item
      as="a"
      target="_blank"
      href="https://help.labstep.com/"
    >
      <Icon name="info circle" />
      Help center
    </Dropdown.Item>
    <ExperimentLive
      portal
      viewComponent={({ toggleSideMenu }): React.ReactElement => (
        <Dropdown.Item
          onClick={(e): void => {
            toggleSideMenu();
            e.stopPropagation();
          }}
        >
          <Icon name="check circle" />
          Live Experiments
        </Dropdown.Item>
      )}
    />
  </Mobile>
);
export default LayoutMenuTopBarProfileMobile;
