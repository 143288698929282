/**
 * Labstep
 */

import {
  FieldType,
  FieldWithoutActionProps,
} from 'labstep-web/core/Form/Reusable/types';
import rules from 'labstep-web/services/validation/rules';

export const fieldName: FieldWithoutActionProps = {
  fieldType: FieldType.Text,
  fieldLabel: 'IUPAC Name',
  name: 'name',
  validation: rules.pub_chem_metadata.name,
  placeholder: 'Enter IUPAC Name',
};

export const fieldMolecularWeight: FieldWithoutActionProps = {
  fieldType: FieldType.Text,
  fieldLabel: 'Molecular Weight',
  name: 'molecular_weight',
  validation: rules.pub_chem_metadata.molecular_weight,
  placeholder: 'Enter Molecular Weight',
};

export const fieldDensity: FieldWithoutActionProps = {
  fieldType: FieldType.Text,
  fieldLabel: 'Density',
  name: 'density',
  validation: rules.pub_chem_metadata.density,
  placeholder: 'Enter  Density',
};

export const fieldMolecularFormula: FieldWithoutActionProps = {
  fieldType: FieldType.Text,
  fieldLabel: 'Molecular Formula',
  name: 'molecular_formula',
  validation: rules.pub_chem_metadata.molecular_formula,
  placeholder: 'Enter  Molecular Formula',
};

export const fieldCAS: FieldWithoutActionProps = {
  fieldType: FieldType.Text,
  fieldLabel: 'CAS Number',
  name: 'cas_number',
  validation: rules.pub_chem_metadata.cas_number,
  placeholder: 'Enter CAS Number',
};

export const NAME_MAPPPINGS = {
  [fieldName.name]: 'IUPACName',
  [fieldMolecularWeight.name]: 'MolecularWeight',
  [fieldDensity.name]: 'Density',
  [fieldMolecularFormula.name]: 'MolecularFormula',
  [fieldCAS.name]: 'CAS',
} as const;
