/**
 * Labstep
 *
 * @module prosemirror/components/Menu/Text
 * @desc Menu to display when selecting a single text node
 */

import MenuTextActionList from 'labstep-web/prosemirror/components/Menu/Text/ActionList';
import { computeNodePosition } from 'labstep-web/prosemirror/cursor';
import React, { useEffect, useState } from 'react';
import styles from './styles.module.scss';
import { IMenuTextProps } from './types';
import { getShouldHide } from './utils';

/** Number of pixels to display menu above selection */
export const LINE_HEIGHT = -30;

export const MenuText: React.FC<IMenuTextProps> = ({
  entity,
  view,
}) => {
  const [shouldHide, setShouldHide] = useState(true);

  useEffect(() => {
    setShouldHide(getShouldHide(view.state));
  }, [view.state]);

  if (shouldHide) {
    return null;
  }

  const cursorPosition = computeNodePosition(
    view.state.selection.from,
    view,
  );

  return (
    <div
      className={styles.container}
      style={{
        position: 'fixed',
        top: `${cursorPosition.top - LINE_HEIGHT}px`,
        left: `${cursorPosition.left}px`,
      }}
    >
      <MenuTextActionList entity={entity} view={view} />
    </div>
  );
};

export default MenuText;
