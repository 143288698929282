/**
 * Labstep
 *
 * @module grid/IndexResourceItem/coldefs
 * @desc Resource Item Grid Column Definitions
 */

import { ColDef } from 'labstep-web/core/DataGrid/types';
import colDefBulkSelect from 'labstep-web/grid/Index/coldefs/Entity/bulkSelect';
import colDefEntityUser from 'labstep-web/grid/Index/coldefs/Entity/entityUser';
import { colDefResourceItemActionMenu } from 'labstep-web/grid/Index/coldefs/ResourceItem/actionMenu';
import { colDefResourceItemAmount } from 'labstep-web/grid/Index/coldefs/ResourceItem/amount';
import { getResourceItemMetadataColDefs } from 'labstep-web/grid/Index/coldefs/ResourceItem/metadata';
import { getResourceItemResourceMetadataColDefs } from 'labstep-web/grid/Index/coldefs/ResourceItem/metadata/resource';
import { colDefResourceItemName } from 'labstep-web/grid/Index/coldefs/ResourceItem/name';
import { colDefResourceItemResource } from 'labstep-web/grid/Index/coldefs/ResourceItem/resource';
import colDefResourceLocation from 'labstep-web/grid/Index/coldefs/ResourceItem/resourceLocation';
import { colDefStatusHidden } from 'labstep-web/grid/Index/coldefs/ResourceItem/status/hidden';
import { ResourceItem } from 'labstep-web/models/resource-item.model';
import { Resource } from 'labstep-web/models/resource.model';

export default (
  resourceTemplate?: Resource | null,
): (ColDef<ResourceItem> | ColDef<Resource>)[] => {
  const colDefs = resourceTemplate
    ? [
        colDefResourceItemActionMenu,
        colDefBulkSelect,
        colDefResourceItemName,
        colDefResourceLocation,
        colDefResourceItemAmount,
        colDefResourceItemResource,
        ...getResourceItemMetadataColDefs(resourceTemplate),
        ...getResourceItemResourceMetadataColDefs(resourceTemplate),
        colDefEntityUser,
        colDefStatusHidden,
      ]
    : [
        colDefResourceItemActionMenu,
        colDefBulkSelect,
        colDefResourceItemName,
        colDefResourceLocation,
        colDefResourceItemAmount,
        colDefResourceItemResource,
        colDefEntityUser,
        colDefStatusHidden,
      ];
  return colDefs;
};
