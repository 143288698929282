/**
 * Labstep
 *
 * @module components/OrderRequest/Status
 * @desc Order Request status + option to change
 */

import classnames from 'classnames';
import EntityLinkComponent from 'labstep-web/components/Entity/Link/Component';
import Icon from 'labstep-web/core/Icon';
import { OrderRequestStatusValues } from 'labstep-web/models/order-request.model';
import { dateOnly } from 'labstep-web/services/date.service';
import React from 'react';
import styles from './styles.module.scss';
import { OrderRequestStatusProps } from './types';

export const OrderRequestStatus: React.FC<
  OrderRequestStatusProps
> = ({
  status,
  user,
  date,
  withAdditionalInfo = true,
  alignAsRow,
  canEdit,
}) => {
  return (
    <div
      className={classnames(styles.container, {
        [styles.row]: alignAsRow,
      })}
    >
      <span className={classnames(styles[status])}>
        {OrderRequestStatusValues[status]}{' '}
        {canEdit && <Icon name="chevron down" />}
      </span>
      {withAdditionalInfo && date && user && (
        <>
          <div>on {dateOnly(date)}</div>
          <div>
            by{' '}
            <EntityLinkComponent entity={user} secondary disabled />
          </div>
        </>
      )}
    </div>
  );
};

export default OrderRequestStatus;
