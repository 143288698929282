/**
 * Labstep
 */

import MetadataManager from 'labstep-web/components/Metadata/Manager';
import { ICONS } from 'labstep-web/constants/icons';
import colDefEntityLink from 'labstep-web/grid/Index/coldefs/Resource/entityLink';
import { ResourceItem } from 'labstep-web/models/resource-item.model';
import { Resource } from 'labstep-web/models/resource.model';
import { objectOrFunction } from 'labstep-web/services/react.service';

const colDefResourceItemResourceEntityLinkNoLink =
  colDefEntityLink<ResourceItem>({
    getNestedEntity: (params) => params.data?.resource,
    getEntityPreviewProps: (resource) => ({
      secondaryInfo: <MetadataManager entity={resource} />,
    }),
    noLink: true,
  });

export const colDefResourceItemResourceNoLink = {
  ...colDefResourceItemResourceEntityLinkNoLink,
  colId: 'resource_name',
  headerComponentParams: (params) => ({
    ...objectOrFunction(
      colDefResourceItemResourceEntityLinkNoLink.headerComponentParams,
      params,
    ),
    icon: ICONS.resource.primary,
  }),
  headerName: Resource.getHumanReadableEntityName(false, true),
};
