/**
 * Labstep
 *
 * @module components/Entity/Form/ShowEdit/InputOutput
 * @desc Entity field type input/output
 */

import EntityActionUpdateDropdownPill from 'labstep-web/components/Entity/Action/Update/DropdownPill';
import {
  INPUT_OUTPUT_TYPES,
  INPUT_OUTPUT_TYPE_INPUT,
} from 'labstep-web/models/protocol-value.model';
import React from 'react';
import { EntityFormShowEditInputOutputProps } from './types';

const items = INPUT_OUTPUT_TYPES.map((type) => {
  return {
    label: type.label,
    body:
      type.value === INPUT_OUTPUT_TYPE_INPUT
        ? { is_input: true, is_output: false }
        : { is_input: false, is_output: true },
  };
});

export const EntityFormShowEditInputOutput: React.FC<
  EntityFormShowEditInputOutputProps
> = ({ entity }) => (
  <EntityActionUpdateDropdownPill
    entity={entity}
    items={items}
    currentValue={INPUT_OUTPUT_TYPES[entity.is_output ? 1 : 0].label}
  />
);

export default EntityFormShowEditInputOutput;
