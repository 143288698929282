/**
 * Labstep
 *
 * @module grid/Index/components/ColumnHeader
 * @desc DataGrid Column Header
 */

import { useEntityViewContext } from 'labstep-web/components/EntityView/Context';
import DataGridColumnHeader from 'labstep-web/core/DataGrid/ColumnHeader';
import GridIndexContextMenu from 'labstep-web/grid/Index/components/ContextMenu';
import React from 'react';
import { GridIndexColumnHeaderProps } from './types';

export const GridIndexColumnHeader: React.FC<
  GridIndexColumnHeaderProps
> = ({ sort, filter, ...rest }) => {
  const {
    onClick,
    displayName,
    mergeAs,
    icon,
    cornerIcon,
    action,
    ...params
  } = rest;
  const context = useEntityViewContext();
  const trigger = <DataGridColumnHeader {...rest} />;
  if (!context) {
    return trigger;
  }
  return (
    <GridIndexContextMenu
      trigger={trigger}
      sort={sort}
      filter={filter}
      {...params}
    />
  );
};

export default GridIndexColumnHeader;
