/**
 * Labstep
 *
 * @module components/Organization/Settings/AllowMemberCreateGroup
 * @desc Organization set allow member create group
 */

import EntityActionUpdate from 'labstep-web/components/Entity/Action/Update';
import React from 'react';
import { OrganizationSettingsAllowMemberCreateGroupProps } from './types';

const OrganizationSettingsAllowMemberCreateGroup: React.FC<
  OrganizationSettingsAllowMemberCreateGroupProps
> = ({ organization }) => (
  <div>
    <EntityActionUpdate
      entityName={organization.entityName}
      id={organization.id}
      body={{
        is_allow_member_create_group:
          !organization.is_allow_member_create_group,
      }}
      optimistic
      actionComponentProps={{
        type: 'checkbox',
        elementProps: {
          checked: organization.is_allow_member_create_group,
        },
      }}
    />
    <p>
      Enable to allow members of the organization to create new
      workspaces
    </p>
  </div>
);

export default OrganizationSettingsAllowMemberCreateGroup;
