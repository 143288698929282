/**
 * Labstep
 *
 * @module components/Resource/Preview
 * @desc Preview for resource
 */

import MetadataPreview from 'labstep-web/components/Metadata/Preview';
import ResourcePreview from 'labstep-web/components/Resource/Preview';
import Card from 'labstep-web/core/Card/Card';
import Flex from 'labstep-web/core/Flex';
import React from 'react';
import styles from './styles.module.scss';
import { IResourcePreviewCardProps } from './types';

export const ResourcePreviewCard: React.FC<
  IResourcePreviewCardProps
> = ({ resource, cardProps }) => (
  <Card {...cardProps}>
    <Flex>
      <ResourcePreview resource={resource} showIcon />
      <div className={styles.availableItems}>
        {`${resource.available_resource_item_count} item(s)`}
      </div>
    </Flex>
    <MetadataPreview entity={resource} />
  </Card>
);

export default ResourcePreviewCard;
