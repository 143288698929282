/**
 * Labstep
 *
 * @module components/Layout/GlobalSearch/Content/GroupSelector
 * @desc Component to select workspace
 */

import GroupSearchSelect from 'labstep-web/components/Group/SearchSelect';
import { ShowEditToggleComponent } from 'labstep-web/core/Form/ShowEdit';
import Icon from 'labstep-web/core/Icon';
import { Group } from 'labstep-web/models/group.model';
import React from 'react';
import styles from './styles.module.scss';
import { LayoutGlobalSearchGroupsSelectorProps } from './types';

const LayoutGlobalSearchGroupsSelector: React.FC<
  LayoutGlobalSearchGroupsSelectorProps
> = ({ searchParams, setParams }) => (
  <div className={styles.container}>
    <ShowEditToggleComponent
      canEdit
      hasValue
      name="Workspace"
      toggledComponent={({ toggle }) => (
        <GroupSearchSelect
          setOptions={(options) => [
            { name: 'All Workspaces', id: 0 },
            ...options,
          ]}
          autoFocus
          value={searchParams.group}
          onBlur={toggle}
          onChange={(group) => {
            setParams({
              group: !group || group.id === 0 ? undefined : group,
            });
            toggle();
          }}
        />
      )}
    >
      <span className={styles.view}>
        {searchParams.group
          ? (searchParams.group as Group).name
          : 'All Workspaces'}{' '}
        <Icon name="chevron down" />
      </span>
    </ShowEditToggleComponent>
  </div>
);

export default LayoutGlobalSearchGroupsSelector;
