/**
 * Labstep
 *
 * @module screens/ExperimentWorkflow/Index/LeftPane
 * @desc ExperimentWorkflow Index LeftPane
 */

import ExperimentWorkflowFilterPane from 'labstep-web/components/ExperimentWorkflow/Filter/Pane';
import FolderPane from 'labstep-web/components/Folder/Pane';
import LayoutLeftPane from 'labstep-web/components/Layout/LeftPane';
import { FolderType } from 'labstep-web/models/folder.model';
import React from 'react';

export const ExperimentWorkflowIndexLeftPane: React.FC = () => (
  <LayoutLeftPane>
    <FolderPane folderType={FolderType.experiment_workflow} />
    <ExperimentWorkflowFilterPane />
  </LayoutLeftPane>
);

export default ExperimentWorkflowIndexLeftPane;
