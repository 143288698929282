/**
 * Labstep
 *
 * @module screens/Error/VerifyEmail
 * @desc Displays error to user when his email is not confirmed
 */

import FullPageMessage from 'labstep-web/components/Layout/FullPageMessage';
import SignoutAction from 'labstep-web/components/User/Action/Signout';
import { ResendVerificationEmailContainer } from 'labstep-web/containers/ResendVerificationEmail';
import Button from 'labstep-web/core/Button';
import styles from './styles.module.scss';

export const VerifyEmail = ({ resendVerificationEmail, status }) => {
  if (status && !status.isFetching && status.error === null) {
    return (
      <FullPageMessage
        src="/img/email-verify/rocket.svg"
        title="Verification email resent"
        explanation="Check your email and click verify my account"
      />
    );
  }

  return (
    <FullPageMessage
      src="/img/error/locked.svg"
      title="Your account is locked"
      explanation="We need to verify your email address to unlock your account"
      action={
        <>
          <Button
            status={status}
            primary
            onClick={resendVerificationEmail}
          >
            Send verification email
          </Button>
          <SignoutAction
            actionComponentProps={{
              type: 'button',
              elementProps: { basic: true, color: 'blue' },
            }}
          />
        </>
      }
      additional={
        <div className={styles.or}>
          or contact{' '}
          <a href="mailto:info@labstep.com">info@labstep.com</a> for
          further assistance
        </div>
      }
    />
  );
};

const VerifyEmailWithContainer = () => (
  <ResendVerificationEmailContainer>
    {(containerProps) => <VerifyEmail {...containerProps} />}
  </ResendVerificationEmailContainer>
);

export default VerifyEmailWithContainer;
