/**
 * Labstep
 *
 * @module models/signature-request
 * @desc Typescript export class for SignatureRequest
 */

import { Type } from 'class-transformer';
import { Entity } from 'labstep-web/models/entity.model';
import { ExperimentWorkflow } from 'labstep-web/models/experiment-workflow.model';
import { User } from 'labstep-web/models/user.model';
import { SignatureRequirement } from './signature-requirement.model';

export class SignatureRequest extends Entity {
  static readonly entityName = 'signature_request';

  get entityName(): string {
    return SignatureRequest.entityName;
  }

  constructor(data: Partial<SignatureRequest> = {}) {
    super();
    Object.assign(this, data);
  }

  id!: number;

  signature_requirement_id!: number;

  @Type(() => ExperimentWorkflow)
  experimentWorkflow!: ExperimentWorkflow;

  @Type(() => User)
  author!: User;

  @Type(() => User)
  user!: User;

  @Type(() => SignatureRequirement)
  signature_requirement!: SignatureRequirement;
}
