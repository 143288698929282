/**
 * Labstep
 */

import ExperimentCompletionChecklistContent from 'labstep-web/components/Experiment/CompletionChecklist/Content';
import Flex from 'labstep-web/core/Flex';
import Icon from 'labstep-web/core/Icon';
import Message from 'labstep-web/core/Message';
import ModalWizard from 'labstep-web/core/Modal/Wizard';
import { ReadOnMountHOC } from 'labstep-web/hoc/ReadOnMount';
import {
  EntityState,
  EntityStateType,
} from 'labstep-web/models/entity-state.model';
import { ProtocolValue } from 'labstep-web/models/protocol-value.model';
import { isEqual } from 'lodash';
import React from 'react';
import { IconProps } from 'semantic-ui-react';
import styles from './styles.module.scss';
import { ExperimentWorkflowActionUpdateEntityStateModalWizardStepOverviewProps } from './types';

export const UPDATE_ENTITY_STATE_MODAL_ROUTE_ID =
  'update-entity-state';

const ExperimentWorkflowActionUpdateEntityStateModalWizardStepOverview: React.FC<
  ExperimentWorkflowActionUpdateEntityStateModalWizardStepOverviewProps
> = ({
  experimentWorkflow,
  activeGroup,
  entityState,
  nextEntityState,
}) => {
  const experiment = experimentWorkflow.root_experiment;
  const experimentId = experiment.id;
  const isCompletionChecklistEnabled =
    activeGroup?.is_completion_checklist_enabled &&
    nextEntityState?.type === EntityStateType.completed.toLowerCase();

  if (!nextEntityState) {
    return null;
  }
  return (
    <ModalWizard.Step
      isContinueButtonDisabled={
        (isCompletionChecklistEnabled &&
          experiment.metadataRequiredWithValueMissingCount > 0) ||
        false
      }
      description="Check any requirements and changes below before proceeding."
      index={0}
    >
      <h3>Status Progression</h3>
      <div className={styles.transition}>
        <Icon name={entityState.icon as IconProps['name']} />
        <span>{entityState.name}</span>
        <Icon name="arrow right" />
        <Icon
          name={
            (nextEntityState as EntityState).icon as IconProps['name']
          }
        />
        <span>{(nextEntityState as EntityState).name}</span>
      </div>
      {!isEqual(
        entityState.target_allowed_actions_lock,
        nextEntityState.target_allowed_actions_lock,
      ) &&
        !!nextEntityState.target_allowed_actions_lock && (
          <Message warning>
            <Flex vAlignContent="center">
              <Icon size="large" name="lock" />
              <span>{`When progressing to ${nextEntityState?.name} the permitted actions will change to '${nextEntityState.printPermissions}'`}</span>
            </Flex>
          </Message>
        )}
      <>
        <h3>Requirements</h3>
        {isCompletionChecklistEnabled ? (
          <ReadOnMountHOC
            type="entities"
            entityName={experiment.entityName}
            params={{ id: experiment.id, get_single: 1 }}
            loading={{ loader: 'spinner' }}
            children={(): React.ReactElement => (
              <div>
                <ReadOnMountHOC
                  type="count"
                  entityName={ProtocolValue.entityName}
                  loading={{ loader: 'placeholder', multiplier: 1 }}
                  params={{
                    experiment_id: experimentId,
                    has_resource_item_any: false,
                    is_variable: false,
                  }}
                  children={({
                    total: protocolValuesWithoutResourceItemCount,
                  }): React.ReactElement => (
                    <ExperimentCompletionChecklistContent
                      experimentWorkflow={experimentWorkflow}
                      experiment={experimentWorkflow.root_experiment}
                      onLinkNavigation={() => {}}
                      protocolValuesWithoutResourceItemCount={
                        protocolValuesWithoutResourceItemCount
                      }
                    />
                  )}
                />
              </div>
            )}
          />
        ) : (
          <i>None</i>
        )}
      </>
    </ModalWizard.Step>
  );
};

export default ExperimentWorkflowActionUpdateEntityStateModalWizardStepOverview;
