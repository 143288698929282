/**
 * Labstep
 */

import { BulkSelectContainerChildrenProps } from 'labstep-web/contexts/bulk-select/types';
import React from 'react';

export const defaultBulkSelectContext: BulkSelectContainerChildrenProps =
  {
    isSelected: () => false,
    toggleSelection: () => {
      // Do nothing
    },
    selectAll: () => {
      // Do nothing
    },
    unselectAll: () => {
      // Do nothing
    },
    selectAllVisible: () => {
      // Do nothing
    },
    canBulkSelect: false,
    isAtLeastOneSelected: false,
    selectedCount: 0,
    selected: [],
    selectedAll: false,
    selectedAllVisible: false,
    unselected: [],
    entities: [],
    isSelectComplete: false,
    requiredCount: 0,
    persistSelection: false,
  };

const BulkSelectContext =
  React.createContext<BulkSelectContainerChildrenProps>(
    defaultBulkSelectContext,
  );

export default BulkSelectContext;
