/**
 * Labstep
 *
 * @module components/OrderRequest/Status/Log
 * @desc Order Request status log
 */

import OrderRequestStatus from 'labstep-web/components/OrderRequest/Status';
import List from 'labstep-web/core/List';
import { OrderRequestStatus as OrderRequestStatusEnum } from 'labstep-web/models/order-request.model';
import React from 'react';
import { OrderRequestStatusLogProps } from './types';

export const OrderRequestStatusLog: React.FC<
  OrderRequestStatusLogProps
> = ({ orderRequest }) => (
  <List separation doubleSpaced>
    <OrderRequestStatus
      status={OrderRequestStatusEnum.new}
      user={orderRequest.author}
      date={orderRequest.created_at}
      alignAsRow
    />
    {orderRequest.approved_log && (
      <OrderRequestStatus
        status={OrderRequestStatusEnum.approved}
        user={orderRequest.approved_log.author}
        date={orderRequest.approved_log.created_at}
        alignAsRow
      />
    )}
    {orderRequest.back_ordered_log && (
      <OrderRequestStatus
        status={OrderRequestStatusEnum.back_ordered}
        user={orderRequest.back_ordered_log.author}
        date={orderRequest.back_ordered_log.created_at}
        alignAsRow
      />
    )}
    {orderRequest.ordered_log && (
      <OrderRequestStatus
        status={OrderRequestStatusEnum.ordered}
        user={orderRequest.ordered_log.author}
        date={orderRequest.ordered_log.created_at}
        alignAsRow
      />
    )}
    {orderRequest.cancelled_log && (
      <OrderRequestStatus
        status={OrderRequestStatusEnum.cancelled}
        user={orderRequest.cancelled_log.author}
        date={orderRequest.cancelled_log.created_at}
        alignAsRow
      />
    )}
    {orderRequest.partially_received_log && (
      <OrderRequestStatus
        status={OrderRequestStatusEnum.partially_received}
        user={orderRequest.partially_received_log.author}
        date={orderRequest.partially_received_log.created_at}
        alignAsRow
      />
    )}
    {orderRequest.received_log && (
      <OrderRequestStatus
        status={OrderRequestStatusEnum.received}
        user={orderRequest.received_log.author}
        date={orderRequest.received_log.created_at}
        alignAsRow
      />
    )}
  </List>
);

export default OrderRequestStatusLog;
