/**
 * Labstep
 *
 * @module core/FullCalendar
 * @desc Imports (lazy) fullCalendar in error boundary
 */

import { ErrorBoundaryContainer } from 'labstep-web/containers/ErrorBoundary';
import React, { Suspense, forwardRef, lazy } from 'react';
import { IFullCalendarProps } from './types';

const FullCalendarComponent = lazy(() => import('./component'));

const FullCalendar: React.FC<IFullCalendarProps> = forwardRef(
  (props, ref) => (
    <ErrorBoundaryContainer FallbackComponent={<div />}>
      <Suspense fallback={<div />}>
        <FullCalendarComponent ref={ref} {...props} />
      </Suspense>
    </ErrorBoundaryContainer>
  ),
);

export default FullCalendar;
