/**
 * Labstep
 *
 * @module components/ResourceLocation/MapModal
 * @desc Modal to show grid
 */

import { useHasAccess } from 'labstep-web/components/Entity/Can';
import { Action } from 'labstep-web/components/Entity/Can/types';
import ResourceLocationBreadcrumb from 'labstep-web/components/ResourceLocation/Breadcrumb';
import ChildComponent from 'labstep-web/components/ResourceLocation/MapModal/Content/ChildComponent';
import ActionComponent from 'labstep-web/core/Action/Component';
import GridLayoutDefault from 'labstep-web/core/GridLayout/Default';
import { transformLayoutToReadOnly } from 'labstep-web/core/GridLayout/Default/utils';
import ModalDefault from 'labstep-web/core/Modal/Default';
import React, { useState } from 'react';
import ResourceLocationMapModalContent from './Content';
import styles from './styles.module.scss';
import { IResourceLocationMapModalProps } from './types';

const ResourceLocationMapModal: React.FC<
  IResourceLocationMapModalProps
> = ({
  resourceLocation,
  viewComponent,
  initialSelectedState,
  initialModalState = false,
  initialSelectionInfo,
}) => {
  const canEdit = useHasAccess(
    resourceLocation.entityName,
    resourceLocation.guid,
    Action.edit,
  );
  const [count, setCount] = useState(1);
  return (
    <ModalDefault
      initialModalState={initialModalState}
      size="fullscreen"
      header={
        <ResourceLocationBreadcrumb
          resourceLocation={resourceLocation}
          includeSelf
        />
      }
      onClose={({ toggleModal }) => {
        setCount(count + 1);
        toggleModal();
      }}
      content={
        <ResourceLocationMapModalContent
          // Re-render when dimensions change
          key={`${resourceLocation.map_data.rowCount},${resourceLocation.map_data.columnCount}`}
          initialSelectedState={initialSelectedState}
          resourceLocation={resourceLocation}
          initialSelectionInfo={initialSelectionInfo}
        />
      }
      viewComponent={
        viewComponent ||
        (({ toggleModal }) => (
          <div
            key={count}
            className={styles.container}
            onDoubleClick={toggleModal}
          >
            <ActionComponent
              type="icon"
              icon={canEdit ? 'pencil' : 'external'}
              onClick={toggleModal}
              elementProps={{
                color: 'blue',
                popup: {
                  content: canEdit ? 'Edit Map' : 'View Map',
                },
              }}
            />
            <GridLayoutDefault
              resourceLocation={resourceLocation}
              rows={resourceLocation.map_data.rowCount}
              columns={resourceLocation.map_data.columnCount}
              addItem={() => null}
              removeItem={() => null}
              numberOfChanges={0}
              layout={transformLayoutToReadOnly(
                resourceLocation.initialLayout,
              )}
              selected={null}
              setSelected={() => null}
              readOnly
              childComponent={(props) => (
                <ChildComponent {...props} />
              )}
              onChange={() => null}
            />
          </div>
        ))
      }
    />
  );
};

export default ResourceLocationMapModal;
