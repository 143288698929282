/**
 * Labstep
 *
 * @module models/share-link-invitation
 * @desc Typescript export class for ShareLinkInvitation
 */

import { Type } from 'class-transformer';
import { Entity } from 'labstep-web/models/entity.model';
import { ShareLink } from 'labstep-web/models/share-link.model';

export class ShareLinkInvitation extends Entity {
  static readonly entityName = 'share_link_invitation';

  get entityName(): string {
    return ShareLinkInvitation.entityName;
  }

  constructor(data: Partial<ShareLinkInvitation> = {}) {
    super();
    Object.assign(this, data);
  }

  id!: number;

  email!: string;

  @Type(() => ShareLink)
  share_link!: ShareLink;
}
