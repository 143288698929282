/**
 * Labstep
 *
 * @module components/Filter/ResourceItemStatus/Active
 * @desc Filter Resource Item Status Active
 */

import RemoveLabel from 'labstep-web/core/Label/Remove';
import React from 'react';
import { FilterResourceItemStatusActiveProps } from './types';
import { getNameAndIcon } from './utils';

export const FilterResourceItemStatusActive: React.FC<
  FilterResourceItemStatusActiveProps
> = ({ clearParams, searchParams }) => {
  if (!('status' in searchParams)) {
    return null;
  }

  return (
    <RemoveLabel
      {...getNameAndIcon(searchParams.status as string)}
      onRemoveClick={
        clearParams && ((): void => clearParams(['status']))
      }
    />
  );
};
export default FilterResourceItemStatusActive;
