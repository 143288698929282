/**
 * Labstep
 *
 * @module components/Group/Action/View
 * @desc Action linking to group show
 */

import EntityActionView from 'labstep-web/components/Entity/Action/View';
import React from 'react';
import { GroupActionViewProps } from './types';

const GroupActionView: React.FC<GroupActionViewProps> = ({
  group,
}) => (
  <EntityActionView
    entityName="group"
    actionComponentProps={{
      type: 'option',
      text: 'Enter Workspace',
      icon: 'sign-in',
    }}
    params={{ id: group.id }}
  />
);

export default GroupActionView;
