/**
 * Labstep
 *
 * @desc Global Search modal content
 */

import { ParamsContext } from 'labstep-web/contexts/params';
import { ParamsHOC } from 'labstep-web/hoc/Params';
import React from 'react';
import { withRouter } from 'react-router';
import EntityNameSelector from './EntityNameSelector';
import GroupSelector from './GroupSelector';
import SearchResults from './SearchResults';
import styles from './styles.module.scss';
import { LayoutGlobalSearchContentProps } from './types';
import { getInitialEntityName } from './utils';

export const LayoutGlobalSearchContent: React.FC<
  LayoutGlobalSearchContentProps
> = ({ location }) => (
  <ParamsHOC
    initialParams={{
      entityName: getInitialEntityName(location),
      group: null,
    }}
  >
    <ParamsContext.Consumer>
      {({ setParams, searchParams }) => (
        <div className={styles.container}>
          <div>
            <EntityNameSelector
              setParams={setParams}
              searchParams={searchParams}
            />
          </div>

          <div className={styles.bottomContainer}>
            <GroupSelector
              setParams={setParams}
              searchParams={searchParams}
            />
            <br />
            <SearchResults searchParams={searchParams} />
          </div>
        </div>
      )}
    </ParamsContext.Consumer>
  </ParamsHOC>
);

export default withRouter(LayoutGlobalSearchContent);
