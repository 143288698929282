/**
 * Labstep
 *
 * @module components/ProtocolCondition/Action/ViewVariablesManager
 * @desc ViewVariablesManager Action for ProtocolCondition
 */

import ActionComponent from 'labstep-web/core/Action/Component';
import React from 'react';
import { useProtocolConditionModalContext } from '../../Modal/context';
import { IProtocolConditionActionViewVariableManagerProps } from './types';

export const ProtocolConditionActionViewVariableManager: React.FC<
  IProtocolConditionActionViewVariableManagerProps
> = ({ protocol, type }) => {
  const { setVariableManager, setIsOpen } =
    useProtocolConditionModalContext();

  return (
    <ActionComponent
      dataTestId={`action-view-variable-manager-${type}`}
      type="icon"
      icon="cog"
      onClick={(): void => {
        setIsOpen(protocol);
        setVariableManager(type);
      }}
    />
  );
};

export default ProtocolConditionActionViewVariableManager;
