/**
 * Labstep
 *
 * @module core/DangerousDiv
 * @desc Div with sanitized html
 */

import dompurify from 'dompurify';
import React from 'react';
import { IDangerousDiv } from './types';

/**
 * Core DangerousDiv component
 */
const DangerousDiv: React.FC<IDangerousDiv> = ({
  html,
  className,
  style = {},
  domPurifyParams = {},
  id,
  ...rest
}) => (
  <div
    id={id}
    className={className}
    style={style}
    // Important!: Disabling eslint as one off here because we use dompurify to sanitize html
    // eslint-disable-next-line react/no-danger
    dangerouslySetInnerHTML={{
      __html: dompurify.sanitize(
        html,
        domPurifyParams,
      ) as unknown as string,
    }}
    {...rest}
  />
);

export default DangerousDiv;
