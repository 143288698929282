import bugsnagServiceInstance from 'labstep-web/services/bugsnag.service';
import ArraySchema, * as ArrayUtils from './schemas/Array';
import EntitySchema from './schemas/Entity';
import * as ImmutableUtils from './schemas/ImmutableUtils';
import ObjectSchema, * as ObjectUtils from './schemas/Object';
import UnionSchema from './schemas/Union';
import ValuesSchema from './schemas/Values';

const visit = (
  value,
  parent,
  key,
  schema,
  addEntity,
  visitedEntities,
) => {
  if (typeof value !== 'object' || !value) {
    return value;
  }

  if (
    typeof schema === 'object' &&
    (!schema.normalize || typeof schema.normalize !== 'function')
  ) {
    const method = Array.isArray(schema)
      ? ArrayUtils.normalize
      : ObjectUtils.normalize;
    return method(
      schema,
      value,
      parent,
      key,
      visit,
      addEntity,
      visitedEntities,
    );
  }

  return schema.normalize(
    value,
    parent,
    key,
    visit,
    addEntity,
    visitedEntities,
  );
};

const addEntities =
  (entities) => (schema, processedEntity, value, parent, key) => {
    const schemaKey = schema.key;
    const id = schema.getId(value, parent, key);
    if (!(schemaKey in entities)) {
      entities[schemaKey] = {};
    }

    const existingEntity = entities[schemaKey][id];
    if (existingEntity) {
      entities[schemaKey][id] = schema.merge(
        existingEntity,
        processedEntity,
      );
    } else {
      entities[schemaKey][id] = processedEntity;
    }
  };

export const schema = {
  Array: ArraySchema,
  Entity: EntitySchema,
  Object: ObjectSchema,
  Union: UnionSchema,
  Values: ValuesSchema,
};

export const normalize = (input, schema) => {
  if (!input || typeof input !== 'object') {
    throw new Error(
      `Unexpected input given to normalize. Expected type to be "object", found "${typeof input}".`,
    );
  }

  const entities = {};
  const addEntity = addEntities(entities);
  const visitedEntities = {};

  const result = visit(
    input,
    input,
    null,
    schema,
    addEntity,
    visitedEntities,
  );
  return { entities, result };
};

const unvisitEntity = (
  id,
  schema,
  unvisit,
  getEntity,
  cache,
  entitySlice,
) => {
  const entity = getEntity(id, schema);

  if (typeof entity !== 'object' || entity === null) {
    return entity;
  }

  if (!cache[schema.key]) {
    cache[schema.key] = {};
  }

  if (!cache[schema.key][id]) {
    // Ensure we don't mutate it non-immutable objects
    const entityCopy = ImmutableUtils.isImmutable(entity)
      ? entity
      : { ...entity };

    // Need to set this first so that if it is referenced further within the
    // denormalization the reference will already exist.
    cache[schema.key][id] = entityCopy;

    entitySlice[schema.key] = entitySlice[schema.key] || {};
    // Why is input id.id ??
    entitySlice[schema.key][
      typeof id === 'object' ? id[schema.idAttribute] : id
    ] = entity;

    const denormalizedEntity = schema.denormalize(
      entityCopy,
      unvisit,
    );
    // Labstep specific: Cast class to object;
    cache[schema.key][id] = schema.EntityClass
      ? new schema.EntityClass(denormalizedEntity)
      : denormalizedEntity;
  }

  // Labstep specific: Making sure the class is cast
  const result =
    cache[schema.key][id].constructor.name === 'Object' &&
    schema.EntityClass
      ? new schema.EntityClass(cache[schema.key][id])
      : cache[schema.key][id];

  return result;
};

const getUnvisit = (entities, entitySlice) => {
  const cache = {};
  const getEntity = getEntities(entities);

  return function unvisit(input, schema) {
    if (
      typeof schema === 'object' &&
      (!schema.denormalize ||
        typeof schema.denormalize !== 'function')
    ) {
      const method = Array.isArray(schema)
        ? ArrayUtils.denormalize
        : ObjectUtils.denormalize;
      return method(schema, input, unvisit);
    }

    if (input === undefined || input === null) {
      return input;
    }

    if (schema instanceof EntitySchema) {
      return unvisitEntity(
        input,
        schema,
        unvisit,
        getEntity,
        cache,
        entitySlice,
      );
    }

    return schema.denormalize(input, unvisit);
  };
};

const getEntities = (entities) => {
  const isImmutable = ImmutableUtils.isImmutable(entities);

  return (entityOrId, schema) => {
    const schemaKey = schema.key;

    if (typeof entityOrId === 'object') {
      return entityOrId;
    }

    if (isImmutable) {
      return entities.getIn([schemaKey, entityOrId.toString()]);
    }

    return entities[schemaKey] && entities[schemaKey][entityOrId];
  };
};

const memoize = (fn) => {
  const cache: any = {};
  return (input, schema, entities) => {
    if (typeof input !== 'undefined') {
      try {
        if (Array.isArray(input)) {
          const key = schema.key || schema?.schema.key;
          const n = `${JSON.stringify(input)}-${key}`;
          // In case we messed up and n is undefined
          if (n.includes('undefined')) {
            console.log('n=', n);
            bugsnagServiceInstance.notify('n is undefined');
            return fn(input, schema, entities);
          }
          if (n in cache) {
            const { entitiesSlice } = cache[n];
            const isSame = Object.keys(entitiesSlice).reduce(
              (r, entityName) => {
                const entityNameSlice = entitiesSlice[entityName];
                return (
                  r &&
                  Object.keys(entityNameSlice).reduce((r2, id) => {
                    const isTrue =
                      r2 &&
                      entities[entityName][id] ===
                        entityNameSlice[id];
                    return isTrue;
                  }, true)
                );
              },
              true,
            );
            if (isSame) {
              return cache[n].result;
            }
          }
          const { result, entitiesSlice } = fn(
            input,
            schema,
            entities,
          );
          // Saving the denormalzied result and the entitiesSlice
          cache[n] = { result, entitiesSlice };
          return result;
        }

        const n = `${
          typeof input === 'object'
            ? input[schema.idAttribute]
            : input
        }-${schema.key}`;

        // In case we messed up and n is undefined
        if (n.includes('undefined')) {
          bugsnagServiceInstance.notify('n is undefined');
          return fn(input, schema, entities);
        }

        if (n in cache) {
          const { entitiesSlice } = cache[n];
          const isSame = Object.keys(entitiesSlice).reduce(
            (r, entityName) => {
              const entityNameSlice = entitiesSlice[entityName];
              return (
                r &&
                Object.keys(entityNameSlice).reduce((r2, id) => {
                  const isTrue =
                    r2 &&
                    entities[entityName][id] === entityNameSlice[id];
                  return isTrue;
                }, true)
              );
            },
            true,
          );
          if (isSame) {
            return cache[n].result;
          }
        }
        const { result, entitiesSlice } = fn(input, schema, entities);

        // Saving the denormalzied result and the entitiesSlice
        cache[n] = { result, entitiesSlice };
        return result;
      } catch (e) {
        bugsnagServiceInstance.notify(e);
        return fn(input, schema, entities);
      }
    }
  };
};

// eslint-disable-next-line consistent-return
export const denormalize = memoize((input, schema, entities) => {
  /**
   * This will be the subset of the entities used to do the
   * denormalization as calculated by the unvisitEntity function.
   * This is the same as cache but instead of using a copy of the entity
   * it's using the actual entity which will help us to shallow
   * comparison equality
   */
  const entitiesSlice = {};
  if (typeof input !== 'undefined') {
    return {
      result: getUnvisit(entities, entitiesSlice)(input, schema),
      entitiesSlice,
    };
  }
});
