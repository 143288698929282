/**
 * Labstep
 *
 * @module components/Folder/Action/Remove/Bulk
 * @desc Action to remove parentEntity to folder
 */

import { useHasAccessMultiple } from 'labstep-web/components/Entity/Can/hooks';
import { Action } from 'labstep-web/components/Entity/Can/types';
import BulkActionComponent from 'labstep-web/core/Action/Component/Bulk';
import { Folder } from 'labstep-web/models/folder.model';
import React from 'react';
import FolderActionRemove from '..';
import { FolderActionRemoveBulkProps } from './types';

export const FolderActionRemoveBulk: React.FC<
  FolderActionRemoveBulkProps
> = ({ entityName, parentEntities, options = {} }) => {
  const parentEntityIdsAccessObject = useHasAccessMultiple(
    entityName,
    parentEntities.map((e) => e.id),
    Action.folder_add_remove,
  );

  if (parentEntities.length === 0) {
    return null;
  }

  const parentEntitiesWithPermission = parentEntities.filter(
    (e) => parentEntityIdsAccessObject[e.id],
  );

  return (
    <FolderActionRemove
      parentName={entityName}
      parentId={parentEntitiesWithPermission.map((p) => p.id)}
      onSuccess={options.onSuccess}
      actionComponentProps={{
        type: 'children',
        children: ({ onClick, status }) => (
          <BulkActionComponent
            entitiesWithPermissionCount={
              parentEntitiesWithPermission.length
            }
            entitiesSelectedCount={parentEntities.length}
            disabled={(status && status.isFetching) || false}
            onClick={onClick}
            content={`Remove from ${Folder.getHumanReadableEntityName()}`}
            icon="remove"
          />
        ),
      }}
    />
  );
};

export default FolderActionRemoveBulk;
