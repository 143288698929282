/**
 * Labstep
 *
 * @module screens/ShareLink/Show
 * @desc Displays a ShareLink experiment/protocol/resource
 */

import LayoutFullScreenPage from 'labstep-web/components/Layout/FullScreenPage';
import ShareLinkAccept from 'labstep-web/components/ShareLink/Accept';
import { withAuthenticatedUser } from 'labstep-web/containers/AuthenticatedUser';
import Button from 'labstep-web/core/Button';
import Header from 'labstep-web/core/Header';
import Redirect from 'labstep-web/core/Redirect';
import { ReadOnMountHOC } from 'labstep-web/hoc/ReadOnMount';
import { APICallOptions } from 'labstep-web/models';
import React from 'react';
import { ChildrenProps, IShareLinkShowScreenProps } from './types';

export const ShareLinkShowScreen: React.FC<
  IShareLinkShowScreenProps
> = (props) => {
  const { token } = props.match.params;
  const { authenticatedUser } = props;
  const isNotAuthenticated = !(
    authenticatedUser && authenticatedUser.enabled
  );
  const options = {
    isPublic: isNotAuthenticated,
  } as APICallOptions;

  return (
    <LayoutFullScreenPage>
      <ReadOnMountHOC
        type="entities"
        loading={{ loader: 'main_content' }}
        key={isNotAuthenticated ? '0' : '1'}
        entityName="share_link"
        params={{ token, get_single: 1 }}
        options={options}
      >
        {({
          entity: shareLink,
        }: ChildrenProps): JSX.Element | null => {
          if (shareLink) {
            if (shareLink.deleted_at) {
              return (
                <div>
                  <Header>This invitation has expired</Header>
                  {!isNotAuthenticated && (
                    <Button
                      primary
                      route={{
                        to: 'app_homepage',
                      }}
                    >
                      Back to home
                    </Button>
                  )}
                </div>
              );
            }
            if (isNotAuthenticated) {
              return (
                <Redirect
                  to={shareLink.organization ? 'signup' : 'login'}
                />
              );
            }
            return <ShareLinkAccept shareLink={shareLink} />;
          }
          return null;
        }}
      </ReadOnMountHOC>
    </LayoutFullScreenPage>
  );
};

export default withAuthenticatedUser(ShareLinkShowScreen);
