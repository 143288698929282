/**
 * Labstep
 *
 * @module components/File/Lightbox
 * @desc Lightbox display for a file thumbnail
 */

import FileActionDownload from 'labstep-web/components/File/Action/Download';
import { ThumbnailContainer } from 'labstep-web/containers/File/Thumbnail';
import Icon from 'labstep-web/core/Icon';
import React from 'react';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import './styles.css';
import { FileLightboxProps } from './types';

export const FileLightbox: React.FC<FileLightboxProps> = ({
  file,
  toggle,
  onError,
}) => (
  <ThumbnailContainer
    file={file.image_annotation ? file.image_annotation : file}
  >
    {({ src }) => (
      <Lightbox
        mainSrc={src}
        imageCaption={file.name}
        onCloseRequest={toggle}
        onImageLoadError={onError}
        toolbarButtons={[
          <FileActionDownload key={1} unstyled file={file}>
            <Icon name="download" />
          </FileActionDownload>,
        ]}
      />
    )}
  </ThumbnailContainer>
);

export default FileLightbox;
