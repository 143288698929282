/**
 * Labstep
 *
 * @module core/Action/Component
 * @desc A generic component for action elements
 */

import ActionIcon from 'labstep-web/core/Action/Icon';
import ActionText from 'labstep-web/core/Action/Text';
import Button from 'labstep-web/core/Button';
import Checkbox from 'labstep-web/core/Checkbox';
import Dropdown from 'labstep-web/core/Dropdown';
import Icon from 'labstep-web/core/Icon';
import ModalConfirmation from 'labstep-web/core/Modal/Confirmation';
import PremiumFeatureLockIcon from 'labstep-web/core/PremiumFeature/LockIcon';
import PremiumFeatureModal from 'labstep-web/core/PremiumFeature/Modal';
import { useHasFeatureFlagEnabled } from 'labstep-web/hooks/feature-flag';
import React from 'react';
import ActionComponentLabel from './Label';
import { ActionComponentProps } from './types';

const DEFAULT_DATA_TEST_ID = 'action-component';

export const ActionComponent: React.FC<ActionComponentProps> = ({
  icon,
  cornerIcon,
  text,
  status,
  onClick,
  disabled,
  modalConfirmationProps,
  dataTestId,
  dataCy,
  premiumFeature,
  ...props
}) => {
  const sharedProps = {
    'data-cy': dataCy,
    'data-testid': dataTestId || DEFAULT_DATA_TEST_ID,
    disabled: !!(status && status.isFetching) || disabled,
    [props.type === 'checkbox' ? 'onChange' : 'onClick']: onClick,
  };

  // If the action is a button, we only show the icon if the showIcon prop is true
  const iconIfShow =
    props.type !== 'button' || props.showIcon ? icon : undefined;
  const sharedPropsWithLabel = {
    ...sharedProps,
    children: (
      <ActionComponentLabel
        icon={iconIfShow}
        cornerIcon={cornerIcon}
        text={text}
      />
    ),
  };

  switch (props.type) {
    case 'button':
      return (
        <Button
          {...sharedPropsWithLabel}
          color="blue"
          {...props.elementProps}
        />
      );

    case 'icon':
      return (
        <ActionIcon
          {...sharedProps}
          name={icon}
          {...props.elementProps}
        />
      );

    case 'checkbox':
      return (
        <Checkbox {...sharedProps} toggle {...props.elementProps} />
      );

    case 'option':
      return (
        <Dropdown.Item
          {...sharedProps}
          {...props.elementProps}
          data-public
        >
          {iconIfShow && (
            <Icon name={iconIfShow} cornerIcon={cornerIcon} />
          )}
          {text}
          {premiumFeature && (
            <PremiumFeatureLockIcon premiumFeature={premiumFeature} />
          )}
        </Dropdown.Item>
      );

    case 'text':
      return (
        <>
          <ActionText
            {...sharedPropsWithLabel}
            underline={!icon}
            {...props.elementProps}
            data-public
          />
          {premiumFeature && (
            <PremiumFeatureLockIcon premiumFeature={premiumFeature} />
          )}
        </>
      );

    case 'children':
      return props.children({ onClick, status });
    default:
      return null;
  }
};

ActionComponent.defaultProps = {
  type: 'option',
};

export const ActionComponentModalContainer: React.FC<
  ActionComponentProps
> = ({
  modalConfirmationProps,
  premiumFeature,
  onClick,
  ...rest
}) => {
  if (premiumFeature && !useHasFeatureFlagEnabled(premiumFeature)) {
    return (
      <PremiumFeatureModal
        premiumFeature={premiumFeature}
        viewComponent={({ toggleModal }) => (
          <ActionComponent
            {...rest}
            onClick={toggleModal}
            premiumFeature={premiumFeature}
          />
        )}
      />
    );
  }

  return modalConfirmationProps ? (
    <ModalConfirmation
      status={rest.status}
      onConfirm={({ toggleModal }) =>
        onClick?.({ onSuccess: toggleModal })
      }
      {...modalConfirmationProps}
    >
      {({ toggleModal }) => (
        <ActionComponent onClick={toggleModal} {...rest} />
      )}
    </ModalConfirmation>
  ) : (
    <ActionComponent onClick={onClick} {...rest} />
  );
};

export default ActionComponentModalContainer;
