/**
 * Labstep
 *
 * @module components/Layout/Container
 * @desc Simple container that adds margin below div
 */

import cx from 'classnames';
import React from 'react';
import styles from './styles.module.scss';
import { ContainerProps } from './types';

const Container: React.FC<ContainerProps> = ({
  children,
  className,
  id,
}) => (
  <div className={cx(styles.container, className)} id={id}>
    {children}
  </div>
);

export default Container;
