/**
 * Labstep
 *
 * @module components/CustomIdentifierSet/Action/Create
 * @desc Create Action for Custom Identifier Set
 */

import CustomIdentifierSetFormCreate from 'labstep-web/components/CustomIdentifierSet/Form/Create';
import ActionComponent from 'labstep-web/core/Action/Component';
import Modal from 'labstep-web/core/Modal/Default';
import PremiumFeatureScreenGuard from 'labstep-web/core/PremiumFeature/ScreenGuard';
import { APIResponse } from 'labstep-web/models';
import React from 'react';
import { CustomIdentifierSetActionCreateProps } from './types';

export const CustomIdentifierSetActionCreate: React.FC<
  CustomIdentifierSetActionCreateProps
> = ({ groupId, parentClass, onSuccess }) => (
  <Modal
    header="Custom Identifier"
    viewComponent={
      <ActionComponent
        type="text"
        text="Enable Custom Identifier"
        elementProps={{
          placeholder: true,
        }}
      />
    }
    content={
      <PremiumFeatureScreenGuard
        premiumFeature="custom_identifier"
        unstyled
      >
        <CustomIdentifierSetFormCreate
          groupId={groupId}
          parentClass={parentClass}
          options={
            onSuccess && {
              onSuccess: ({
                response,
              }: {
                response: APIResponse;
              }) => {
                const id = response.result;

                // FIXME Dirty hack changing onSuccess shape from response.result to object
                onSuccess({
                  group_id: groupId,
                  custom_identifier_set_id: id,
                });
              },
            }
          }
        />
      </PremiumFeatureScreenGuard>
    }
  />
);

export default CustomIdentifierSetActionCreate;
