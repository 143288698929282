/**
 * Labstep
 *
 * @module core/List
 * @desc Core List Item - Wrapper around Semantic List
 */

import cx from 'classnames';
import React from 'react';
import SemanticList from 'semantic-ui-react/dist/commonjs/elements/List';
import styles from './styles.module.scss';
import { ICoreListProps } from './types';

class List extends React.Component<ICoreListProps> {
  public static defaultProps = {
    separation: true,
  };

  public static Content = SemanticList.Content;

  public static Description = SemanticList.Description;

  public static Header = SemanticList.Header;

  public static Icon = SemanticList.Icon;

  public static Item = SemanticList.Item;

  public render(): React.ReactNode {
    const { separation, doubleSpaced, className, ...rest } =
      this.props;
    const classes = cx(className, {
      [styles.separation]: separation,
      [styles.doubleSpaced]: doubleSpaced,
    });
    return <SemanticList className={classes} {...rest} />;
  }
}

export default List;
