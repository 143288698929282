/**
 * Labstep
 *
 * @module components/Device/Breadcrumb
 * @desc Breadcrumb for Device Show page
 */

import EntityEntityTemplateBreadcrumbShow from 'labstep-web/components/Entity/Template/Breadcrumb/Show';
import React from 'react';
import { DeviceBreadcrumbProps } from './types';

export const DeviceBreadcrumb: React.FC<DeviceBreadcrumbProps> = ({
  device,
}) => <EntityEntityTemplateBreadcrumbShow entity={device} />;

export default DeviceBreadcrumb;
