/**
 * Labstep
 *
 * @module core/Divider
 * @desc Horizontal Line / Divider components
 */

import cx from 'classnames';
import React from 'react';
import SemanticDivider from 'semantic-ui-react/dist/commonjs/elements/Divider';
import styles from './styles.module.scss';
import { IDividerProps, IVerticalDividerProps } from './types';

export const HorizontalDivider = () => (
  <div className={styles.horizontalDivider}>
    <div />
  </div>
);

export const VerticalDivider: React.FC<IVerticalDividerProps> = ({
  white,
  stretch,
}) => (
  <div className={styles.verticalDividerContainer}>
    <div
      className={cx(styles.verticalDivider, {
        [styles.white]: white,
        [styles.stretch]: stretch,
      })}
    />
  </div>
);

export const Divider: React.FC<IDividerProps> = ({
  primary = true,
  className,
  ...rest
}) => (
  <SemanticDivider
    className={cx({ [styles.primary]: primary }, className)}
    {...rest}
  />
);

export default Divider;
