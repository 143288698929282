/**
 * Labstep
 *
 * @module screens/ProtocolCollection/Index
 * @desc ProtocolCollection index
 */

import { ProtocolCollectionActionCreate } from 'labstep-web/components/ProtocolCollection/Action/Create';
import { ProtocolCollectionEmptyState } from 'labstep-web/components/ProtocolCollection/Card/EmptyState';
import ProtocolCollectionListOrTable from 'labstep-web/components/ProtocolCollection/ListOrTable';
import { ProtocolCollection } from 'labstep-web/models/protocol-collection.model';
import MasterIndex from 'labstep-web/screens/Master/Index';
import React, { useCallback, useMemo } from 'react';
import ProtocolCollectionIndexBreadcrumb from './Breadcrumb';
import ProtocolCollectionIndexLeftPane from './LeftPane';
import { bulkActions, filters } from './constants';
import { IProtocolCollectionIndexProps } from './types';

export const ProtocolCollectionIndex: React.FC<
  IProtocolCollectionIndexProps
> = ({ group }) => {
  const breadcrumb = useMemo(
    () => <ProtocolCollectionIndexBreadcrumb />,
    [],
  );
  const leftPane = useMemo(
    () => <ProtocolCollectionIndexLeftPane />,
    [],
  );
  const action = useCallback(({ searchParams }) => {
    const folderId = searchParams?.folder_id;
    return (
      <ProtocolCollectionActionCreate
        body={folderId ? { folder_id: folderId } : undefined}
      />
    );
  }, []);
  const noResultsMessage = useCallback(
    ({ searchParams }) => (
      <ProtocolCollectionEmptyState
        action={action({ searchParams })}
      />
    ),
    [],
  );
  const params = useMemo(
    () => ({
      group_id: group.id,
    }),
    [group],
  );
  return (
    <MasterIndex
      entityName={ProtocolCollection.entityName}
      breadcrumb={breadcrumb}
      leftPane={leftPane}
      noResultsMessage={noResultsMessage}
      filters={filters}
      params={params}
      action={action}
      bulkActions={bulkActions}
    >
      {({
        entities,
        ...rest
      }: { entities: ProtocolCollection[] }): React.ReactElement => (
        <ProtocolCollectionListOrTable
          protocolCollections={entities}
          {...rest}
        />
      )}
    </MasterIndex>
  );
};

export default ProtocolCollectionIndex;
