/**
 * Labstep
 *
 * @module components/Experiment/CompletionChecklist/Content/Message
 * @desc Message inside completion checklist (showed in different colors)
 */

import Icon from 'labstep-web/core/Icon';
import Link from 'labstep-web/core/Link';
import React from 'react';
import { ExperimentCompletionChecklistContentMessageProps } from './types';

const ExperimentCompletionChecklistContentMessage: React.FC<
  ExperimentCompletionChecklistContentMessageProps
> = ({ level = 'yellow', children, route, onClick }) => (
  <Link unstyled {...route} onClick={onClick}>
    <p>
      <Icon
        name={
          level === 'green' ? 'check circle' : 'exclamation triangle'
        }
        color={level}
      />
      {children}
    </p>
  </Link>
);

export default ExperimentCompletionChecklistContentMessage;
