/**
 * Labstep
 *
 * @module screens/Authentication/SigninSAML
 * @desc Signin SAML Screen
 */

import DocumentHead from 'labstep-web/core/DocumentHead';
import Image from 'labstep-web/core/Image';
import Columns from 'labstep-web/screens/Authentication/Columns';
import GetSAMLLoginUrl from 'labstep-web/screens/Authentication/SAML/GetLoginUrl';
import { SAMLService } from 'labstep-web/services/saml.service';
import React from 'react';
import styles from './styles.module.scss';
import { SigninSAMLProps } from './types';

export const SigninSAML: React.FC<SigninSAMLProps> = ({ match }) => {
  const { organizationIdentifier } = match.params;

  let organization = SAMLService.getOneBySamlIdentifier(
    organizationIdentifier,
  );

  if (!organization) {
    organization = {
      identifier: organizationIdentifier,
      displayName: null, // could remove the header altogether
    };
  }

  const header = organization.displayName;
  const explainer = 'Please wait...';

  return (
    <>
      <DocumentHead title="Signin" />
      <Columns
        header={header}
        explainer={explainer}
        left={
          <div className={styles.imageContainer}>
            <Image src="/img/landing/robot_main.svg" fluid />
          </div>
        }
        right={
          <div>
            <GetSAMLLoginUrl organization={organization} />
          </div>
        }
      />
    </>
  );
};

export default SigninSAML;
