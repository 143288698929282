/**
 * Labstep
 *
 * @module components/Entity/Search/Children
 * @desc Encapsulate SearchHOC children into MasterIndex
 */

import EntityEmptyState from 'labstep-web/components/Entity/Card/EmptyState';
import { useParamsContext } from 'labstep-web/contexts/params/hook';
import Placeholder from 'labstep-web/core/Placeholder';
import { APICallStatus } from 'labstep-web/models';
import React from 'react';
import { EntitySearchChildrenProps } from './types';

/**
 * Of the given statuses, returns the first one that is fetching
 * @param statuses Statuses
 * @returns
 */
export const getStatusIsFetching = (
  statuses: Record<number, APICallStatus> | undefined,
) => {
  if (!statuses) {
    return undefined;
  }
  return Object.values(statuses).find((s) => s && s.isFetching);
};

export const EntitySearchChildren: React.FC<
  EntitySearchChildrenProps
> = ({
  showEmptyStateOnSearchOnly,
  tableFormat,
  status,
  entities,
  entityName,
  noResultsMessage,
  noResultsMessageOnSearch,
  initialSearchParams,
  children,
  read,
  useAllPages,
  statusAllPages,
  readNextPage,
  errorComponent,
}) => {
  const { searchParams, setParams, addPostFilter } =
    useParamsContext();

  if (
    [400, 500].includes(status?.error?.status as number) &&
    errorComponent
  ) {
    return errorComponent;
  }

  const notFetching =
    tableFormat || !(status && status.isFetching && !status.cached);
  if (notFetching) {
    const noEmptyState =
      showEmptyStateOnSearchOnly && !('search_query' in searchParams);
    const isEmptyState =
      entities.length === 0 && !tableFormat && !noEmptyState;
    const emptyState = (
      <EntityEmptyState
        entityName={entityName}
        noResultsMessage={noResultsMessage}
        noResultsMessageOnSearch={noResultsMessageOnSearch}
        searchParams={searchParams}
        initialSearchParams={initialSearchParams}
      />
    );
    if (isEmptyState) {
      return emptyState;
    }
    return children({
      read,
      entities,
      status: useAllPages
        ? getStatusIsFetching(statusAllPages)
        : status,
      searchParams,
      setParams,
      addPostFilter,
      readNextPage,
      emptyState,
    });
  }

  return <Placeholder />;
};

export default EntitySearchChildren;
