/**
 * Labstep
 *
 * @module components/User/Form/Signup
 * @desc Signup form
 */

import { UserRegisterInternalContainer } from 'labstep-web/containers/User/Register/Internal';
import ContinueButton from 'labstep-web/core/Button/ContinueButton';
import ReusableForm from 'labstep-web/core/Form/Reusable';
import { IFieldProps } from 'labstep-web/core/Form/Reusable/types';
import Icon from 'labstep-web/core/Icon';
import Link from 'labstep-web/core/Link';
import { APICallStatus } from 'labstep-web/models';
import React from 'react';
import {
  fieldUserEmail,
  fieldUserFirstName,
  fieldUserLastName,
  fieldUserSignupPassword,
} from '../fields';
import styles from './styles.module.scss';
import { IUserFormSignupProps } from './types';

export const printStatusError = (status: APICallStatus) => {
  if (status?.error?.status === 429) {
    return 'Too many requests, please try again later';
  }
  if (
    (status?.error?.data as Record<string, unknown>)?.error_code ===
    'academic_email_domain_invalid'
  ) {
    return (
      <div className={styles.errorContainer}>
        <Icon name="exclamation triangle" color="red" size="large" />
        <div>
          <p>
            You can only sign up for a Labstep Academic account using
            an academic email address.
          </p>
          <p>
            Please contact{' '}
            <a href="mailto:info@labstep.com">info@labstep.com</a> if
            you believe your institution should be added to our list
            of approved email addresses.
          </p>

          <p>
            Alternatively, please{' '}
            <Link to="signup_enterprise">sign up</Link> for Labstep
            Industry instead.
          </p>
        </div>
      </div>
    );
  }
  if ((status?.error?.data as Record<string, unknown>)?.message) {
    const { message } = status?.error?.data as Record<
      string,
      unknown
    >;
    if (typeof message === 'string') {
      return message;
    }
    if (Array.isArray(message)) {
      return message[0]?.message;
    }
    return null;
  }
  return null;
};

export const UserFormSignup: React.FC<IUserFormSignupProps> = ({
  options = {},
  body = {},
}) => {
  const fields: IFieldProps[] = [
    fieldUserFirstName,
    fieldUserLastName,
    fieldUserEmail,
    fieldUserSignupPassword,
  ];

  return (
    <UserRegisterInternalContainer>
      {({ registerInternal, status }) => (
        <ReusableForm
          onSubmit={(values) => {
            registerInternal({ ...body, ...values }, options);
          }}
          autoFocus
          fields={fields}
          error={printStatusError(status)}
          submitButton={
            <ContinueButton fluid onClick={undefined} status={status}>
              Sign Up
            </ContinueButton>
          }
        />
      )}
    </UserRegisterInternalContainer>
  );
};

export default UserFormSignup;
