/**
 * Labstep
 *
 * @module core/DataGrid/CellRenderer/RowNumber
 * @desc CellRenderer RowNumber
 */

import DropdownPortal from 'labstep-web/core/Dropdown/Portal';
import Icon from 'labstep-web/core/Icon';
import { Entity } from 'labstep-web/models/entity.model';
import React, { useEffect, useState } from 'react';
import styles from './styles.module.scss';
import { ICellRendererRowNumberProps } from './types';

export const DataGridCellRendererRowNumber: React.FC<
  ICellRendererRowNumberProps<Entity>
> = React.memo((params) => {
  const startIndex = params.context?.firstRowIndex || 0;
  const numberComponent = (
    <div>{params.node?.rowIndex + startIndex + 1}</div>
  );
  const menuComponent = <Icon name="ellipsis vertical" />;
  const [component, setComponent] = useState(numberComponent);

  useEffect(() => {
    setComponent(numberComponent);
  }, [params.node?.rowIndex]);

  return params.props?.action ? (
    <div
      className={styles.container}
      onMouseEnter={(): void => setComponent(menuComponent)}
      onMouseLeave={(): void => setComponent(numberComponent)}
    >
      <DropdownPortal trigger={component}>
        {params.props.action}
      </DropdownPortal>
    </div>
  ) : (
    component
  );
});

export default DataGridCellRendererRowNumber;
