/**
 * Labstep
 *
 * @module components/Metadata/Form/ShowEdit/Value
 * @desc Edit a Metadata value inline
 */

import {
  useHasAccess,
  useHasAccessCreate,
} from 'labstep-web/components/Entity/Can';
import { Action } from 'labstep-web/components/Entity/Can/types';
import MetadataActionCreateSequence from 'labstep-web/components/Metadata/Action/Create/Sequence';
import MetadataFormShowEditDeviceData from 'labstep-web/components/Metadata/Form/ShowEdit/DeviceData';
import MetadataShowEditFile from 'labstep-web/components/Metadata/Form/ShowEdit/File';
import MetadataFormShowEditNumeric from 'labstep-web/components/Metadata/Form/ShowEdit/Numeric';
import {
  getFieldsDate,
  getFieldsDatetime,
  getFieldsOptions,
  getFieldsValue,
} from 'labstep-web/components/Metadata/Form/fields';
import MetadataValue from 'labstep-web/components/Metadata/Value';
import NotificationAlertActionSet from 'labstep-web/components/NotificationAlert/Action/Set';
import ShowEditDate from 'labstep-web/core/Form/ShowEdit/Date';
import ShowEditSelectOptions from 'labstep-web/core/Form/ShowEdit/SelectOptions';
import ShowEditText from 'labstep-web/core/Form/ShowEdit/Text';
import Hoverable from 'labstep-web/core/Hoverable';
import { MetadataType } from 'labstep-web/models/metadata/types';
import { getValueFields } from 'labstep-web/models/metadata/utils';
import React from 'react';
import { MetadataFormShowEditValueProps } from './types';

const MetadataFormShowEditValue: React.FC<
  MetadataFormShowEditValueProps
> = ({ metadata, entity, isDropzone, componentProps = {} }) => {
  const canEdit = useHasAccess(
    metadata.entityName,
    metadata.id,
    Action.edit,
    getValueFields(metadata),
  );

  const canCreate = entity.metadata_thread
    ? useHasAccessCreate(
        'metadata',
        entity.metadata_thread.entityName,
        entity.metadata_thread.id,
      )
    : false;

  if (metadata.protocol_device) {
    return (
      <MetadataFormShowEditDeviceData
        metadata={metadata}
        entity={entity}
      />
    );
  }

  const createProps = metadata.getCreateProps(entity);

  const canCreateOrEdit = createProps ? canCreate : canEdit;

  const value = (
    <MetadataValue canEdit={canCreateOrEdit} metadata={metadata} />
  );

  const isTemplate = !createProps && metadata.is_template;

  let component;
  switch (metadata.type) {
    case MetadataType.molecule:
      component = (
        <MetadataValue
          createProps={createProps}
          canEdit={canCreateOrEdit}
          metadata={metadata}
        />
      );
      break;
    case MetadataType.sequence:
      component =
        canCreateOrEdit && (createProps || !metadata.sequence) ? (
          <MetadataActionCreateSequence
            createProps={createProps}
            metadata={metadata}
          />
        ) : (
          <MetadataValue
            createProps={createProps}
            canEdit={canCreateOrEdit}
            metadata={metadata}
          />
        );
      break;
    case MetadataType.file:
      component = (
        <Hoverable
          hoverable={
            <>
              <MetadataValue
                createProps={createProps}
                canEdit={canCreateOrEdit}
                metadata={metadata}
              />
              {(metadata.files.length === 0 || createProps) &&
                canCreateOrEdit && (
                  <MetadataShowEditFile
                    metadata={metadata}
                    entity={entity}
                    createProps={createProps}
                    isDropzone={isDropzone}
                  />
                )}
            </>
          }
          component={
            metadata.files.length !== 0 && canCreateOrEdit ? (
              <MetadataShowEditFile
                metadata={metadata}
                entity={entity}
                createProps={createProps}
                actionComponentType="icon"
              />
            ) : undefined
          }
        />
      );
      break;
    case MetadataType.options:
      component = (
        <ShowEditSelectOptions
          entity={metadata}
          entityName={metadata.entityName}
          canEdit={canCreateOrEdit}
          field={getFieldsOptions(metadata, true, isTemplate)[0]}
          createProps={createProps}
        >
          {value}
        </ShowEditSelectOptions>
      );
      break;
    case MetadataType.numeric:
      component = (
        <MetadataFormShowEditNumeric
          metadata={metadata}
          isTemplate={isTemplate}
          createProps={createProps}
          canCreate={canCreate}
          {...componentProps[MetadataType.numeric]}
        />
      );
      break;
    case MetadataType.default:
      component = canCreateOrEdit ? (
        <ShowEditText
          entity={metadata}
          entityName={metadata.entityName}
          field={getFieldsValue(isTemplate)[0]}
          createProps={createProps}
        >
          {value}
        </ShowEditText>
      ) : (
        value
      );
      break;
    case MetadataType.date:
    case MetadataType.datetime: {
      const additionaProps =
        metadata.type === MetadataType.date
          ? {
              placeholder: 'Specify date',
              dateOnly: true,
              field: getFieldsDate(isTemplate)[0]
                .name as keyof typeof metadata,
            }
          : {
              placeholder: 'Specify date and time',
              dateOnly: false,
              field: getFieldsDatetime(isTemplate)[0]
                .name as keyof typeof metadata,
            };
      component = canCreateOrEdit ? (
        <Hoverable
          hoverable={
            <ShowEditDate
              {...additionaProps}
              entity={metadata}
              label={null}
              dateOnly={metadata.type === MetadataType.date}
              createProps={createProps}
            />
          }
          component={
            <NotificationAlertActionSet metadata={metadata} />
          }
          show={metadata.hasActiveAlert}
        />
      ) : (
        value
      );

      break;
    }
    default:
      break;
  }
  return <div>{component}</div>;
};

export default MetadataFormShowEditValue;
