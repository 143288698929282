/**
 * Labstep
 *
 * @module components/Molecule/Show
 * @desc Molecule show component
 */

import { EntityUpdateContainer } from 'labstep-web/containers/Entity/Update';
import CoreChemDraw from 'labstep-web/core/ChemDraw';
import { Molecule } from 'labstep-web/models/molecule.model';
import React from 'react';
import {
  MoleculeShowContainerProps,
  MoleculeShowProps,
} from './types';

export const MoleculeShow: React.FC<MoleculeShowProps> = ({
  molecule,
  status,
  canEdit,
  ...rest
}) => (
  <CoreChemDraw
    molecule={molecule}
    disableSave={status!.isFetching}
    readOnly={!canEdit}
    {...rest}
  />
);

export const MoleculeShowContainer: React.FC<
  MoleculeShowContainerProps
> = ({ id, ...rest }) => (
  <EntityUpdateContainer entityName={Molecule.entityName} id={id}>
    {({ entity: molecule, ...containerProps }) => (
      <MoleculeShow
        molecule={molecule}
        {...containerProps}
        {...rest}
      />
    )}
  </EntityUpdateContainer>
);

export default MoleculeShowContainer;
