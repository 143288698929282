/**
 * Labstep
 *
 * @module components/Entity/Action/Create/Modal
 * @desc Modal to create entity
 */

import { CanCreate } from 'labstep-web/components/Entity/Can';
import ActionComponent from 'labstep-web/core/Action/Component';
import ModalDefault from 'labstep-web/core/Modal/Default';
import { getHumanReadableEntityName } from 'labstep-web/services/i18n.service';
import React from 'react';
import { EntityActionCreateModalProps } from './types';

export const EntityActionCreateModal: React.FC<
  EntityActionCreateModalProps
> = ({
  parent,
  entityName,
  viewComponent,
  actionComponentProps,
  ...rest
}) => (
  <ModalDefault
    viewComponent={({ toggleModal }) => (
      <CanCreate
        parentName={parent.entityName}
        parentId={parent.idAttr}
        entityName={entityName}
      >
        {viewComponent ? (
          viewComponent({ toggleModal })
        ) : (
          <ActionComponent
            type="option"
            text={`New ${getHumanReadableEntityName(entityName)}`}
            icon="plus"
            onClick={toggleModal}
            {...actionComponentProps}
          />
        )}
      </CanCreate>
    )}
    {...rest}
  />
);

export default EntityActionCreateModal;
