/**
 * Labstep
 */

import { Action } from 'labstep-web/models/action.model';
import { nameOfChildKey } from 'labstep-web/services/schema/helpers';
import { mergeWithArray } from '../utils';

/**
 * Append entity id to any items array that its key holds the parent id
 *
 * @function
 * @param  {object} state - Redux state (only the portion for this reducer)
 * @param  {string} action - Redux action
 * @return {object}
 */
export const addManyToMany = (
  state: any,
  action: Action,
  entityName: string,
) => {
  if (Array.isArray(action.meta.parentId)) {
    return state;
  }

  const childKey = nameOfChildKey(entityName, action.meta.entityName);
  const childrenArray = state[action.meta.parentId][childKey] || [];

  return {
    ...state,
    [action.meta.parentId]: {
      ...state[action.meta.parentId],
      [childKey]: mergeWithArray(
        childrenArray,
        action.meta.entityIds,
      ),
    },
  };
};

/**
 * Set entity id to any items array that its key holds the parent id
 *
 * @function
 * @param  {object} state - Redux state (only the portion for this reducer)
 * @param  {string} action - Redux action
 * @return {object}
 */
export const setManyToMany = (
  state: any,
  action: Action,
  entityName: string,
) => {
  const childKey = nameOfChildKey(entityName, action.meta.entityName);

  return {
    ...state,
    [action.meta.parentId]: {
      ...state[action.meta.parentId],
      [childKey]: action.meta.entityIds,
    },
  };
};

/**
 * Remove entity id from any items array that its key holds the parent id
 *
 * @function
 * @param  {object} state - Redux state (only the portion for this reducer)
 * @param  {string} action - Redux action
 * @return {object}
 */
export const removeManyToMany = (
  state: any,
  action: Action,
  entityName: string,
) => {
  const childKey = nameOfChildKey(entityName, action.meta.entityName);

  let newState = { ...state };

  const parent = state[action.meta.parentId];
  const children =
    Boolean(parent) && state[action.meta.parentId][childKey];

  if (parent && children) {
    const filteredChildren = children.filter(
      (id: number) => id !== action.meta.entityIds,
    );

    newState = {
      ...state,
      [action.meta.parentId]: {
        ...state[action.meta.parentId],
        // FIXME Update to handle entityIds of type array if we want batch delete to work
        [childKey]: filteredChildren,
      },
    };
  }

  return newState;
};
