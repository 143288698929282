/**
 * Labstep
 *
 * @module containers/Entity/Delete
 * @desc Container for deleting entities
 */

import { APICallOptions } from 'labstep-web/models';
import { Action } from 'labstep-web/models/action.model';
import { deleteEntity as deleteEntityAction } from 'labstep-web/state/actions/entity';
import { selectDeleteEntityStatus } from 'labstep-web/state/selectors/entity';
import { LabstepReduxState } from 'labstep-web/state/types';
import { connect } from 'react-redux';
import {
  IEntityDeleteContainerChildrenProps,
  IEntityDeleteContainerProps,
} from './types';

const Container = ({
  children,
  ...rest
}: {
  children: (
    props: IEntityDeleteContainerChildrenProps,
  ) => React.ReactElement | null;
} & IEntityDeleteContainerChildrenProps): React.ReactElement | null =>
  children(rest);

const mapStateToProps = (
  state: LabstepReduxState,
  ownProps: IEntityDeleteContainerProps,
) => ({
  status: selectDeleteEntityStatus(
    state,
    ownProps.entityName,
    ownProps.id,
  ),
});

const mapDispatchToProps = (
  dispatch: (action: Action) => void,
  ownProps: IEntityDeleteContainerProps,
) => ({
  deleteEntity(options?: APICallOptions, body?: any) {
    dispatch(
      deleteEntityAction(
        ownProps.entityName,
        ownProps.id,
        options,
        body,
      ),
    );
  },
});

/**
 * Delete Cotainer connecting a component with state
 * Returns orginal component with
 * delete function and status of action
 */
export const EntityDeleteContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(Container);
