/**
 * Labstep
 *
 * @module components/EntityUser/Form/fields
 * @desc   Form Fields for EntityUser
 */

import {
  FieldType,
  FieldWithoutActionProps,
} from 'labstep-web/core/Form/Reusable/types';
import { EntityUserRole } from 'labstep-web/models/entity-user-role.model';
import rules from 'labstep-web/services/validation/rules';

export const fieldEntityUserEntityUserRole = (
  entityUserRoles: EntityUserRole[],
): FieldWithoutActionProps => ({
  name: 'entity_user_role_id',
  fieldType: FieldType.ReactSelect,
  validation: rules.entity_user.entity_user_role,
  elementProps: {
    options:
      entityUserRoles?.map((entityUserRole) => ({
        value: entityUserRole.id,
        label: entityUserRole.name,
      })) || [],
  },
});
