/**
 * Labstep
 *
 * @module components/EntityStateWorkflow/Action/Delete
 * @desc Action to delete experiment workflow
 */

import DeleteAction from 'labstep-web/components/Entity/Action/Delete';
import React from 'react';
import { EntityStateWorkflowActionDeleteProps } from './types';

export const EntityStateWorkflowActionDelete: React.FC<
  EntityStateWorkflowActionDeleteProps
> = ({ entityStateWorkflow }) => (
  <DeleteAction entity={entityStateWorkflow} />
);

export default EntityStateWorkflowActionDelete;
