/**
 * Labstep
 *
 * @module components/OrganizationPlan/Card/Text/Count
 * @desc Count text for organization plan card
 */

import Flex from 'labstep-web/core/Flex';
import Header from 'labstep-web/core/Header';
import SubHeader from 'labstep-web/core/SubHeader';
import React from 'react';
import { OrganizationPlanCardTextCountProps } from './types';

export const OrganizationPlanCardTextCount: React.FC<
  OrganizationPlanCardTextCountProps
> = ({ count, text, shiny }) => (
  <Flex vAlignContent="center">
    <Header noMargin color={shiny ? 'white' : 'inherit'} size="small">
      {count}
    </Header>
    <SubHeader
      style={{ marginLeft: 10, color: shiny ? 'white' : 'grey' }}
    >
      {text}
    </SubHeader>
  </Flex>
);

export default OrganizationPlanCardTextCount;
