/**
 * Labstep
 *
 * @module components/Protocol/Form/fields
 * @desc Protocol fields
 */

import {
  FieldType,
  FieldWithoutActionProps,
} from 'labstep-web/core/Form/Reusable/types';
import {
  USER_GROUP_TYPE_VIEW,
  UserGroup,
} from 'labstep-web/models/user-group.model';
import rules from 'labstep-web/services/validation/rules';

export const fieldProtocolName: FieldWithoutActionProps = {
  fieldType: FieldType.Text,
  fieldLabel: 'Name',
  name: 'name',
  validation: rules.protocol.name,
  placeholder: 'Give the new protocol a name',
};

export const fieldGroup: FieldWithoutActionProps = {
  fieldLabel: 'Add to workspace',
  name: 'group',
  validation: rules.protocol.group,
  placeholder: 'Select a workspace',
  fieldType: FieldType.SearchSelect,
  elementProps: {
    searchKey: 'search_query',
    entityName: 'user_group',
    params: { not_type: USER_GROUP_TYPE_VIEW },
    setOptions: (userGroups: UserGroup[]) => {
      return userGroups.map((userGroup: UserGroup) => {
        return userGroup.group;
      });
    },
  },
};

export const fieldProtocolChanges: FieldWithoutActionProps = {
  name: 'changes',
  fieldType: FieldType.TextArea,
  placeholder: 'Short description of the new version (optional)',
  validation: rules.protocol.changes,
};
