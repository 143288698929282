/**
 * Labstep
 */

import { getMetadataColDefs } from 'labstep-web/grid/Index/coldefs/Resource/metadatas';
import { OrderRequest } from 'labstep-web/models/order-request.model';

/**
 * For all metadatas on orderRequest item template, return a column
 * @param orderRequestTemplate OrderRequest template
 * @returns Column definitions
 */
export const getOrderRequestMetadataColDefs = (
  orderRequestTemplate: OrderRequest,
) =>
  getMetadataColDefs(OrderRequest.entityName, orderRequestTemplate);
