/**
 * Labstep
 *
 * @module components/Filter/Tag/Select
 * @desc Tag filter
 */

import FilterEntity from 'labstep-web/components/Filter/Entity';
import FilterTagActive from 'labstep-web/components/Filter/Tag/Active';
import { onTagChange } from 'labstep-web/components/Filter/Tag/utils';
import TagSearchSelect from 'labstep-web/components/Tag/SearchSelect';
import { ICONS } from 'labstep-web/constants/icons';
import { withActiveGroup } from 'labstep-web/containers/ActiveGroup';
import ActionComponent from 'labstep-web/core/Action/Component';
import Modal from 'labstep-web/core/Modal/Default';
import { Tag } from 'labstep-web/models/tag.model';
import React from 'react';
import styles from './styles.module.scss';
import {
  FilterTagSelectProps,
  FilterTagSelectReadContainerProps,
} from './types';

export const FilterTagSelect: React.FC<FilterTagSelectProps> = ({
  setParams,
  searchParams,
  activeGroup,
  type,
}) => (
  <Modal
    header="Filter by Tag"
    showDone
    content={() => (
      <div>
        <TagSearchSelect
          params={
            searchParams.tag_id
              ? {
                  not_id: searchParams.tag_id,
                  group_id: activeGroup?.id,
                  type,
                }
              : { group_id: activeGroup?.id, type }
          }
          onChange={(selectedOption) =>
            onTagChange(selectedOption, setParams, searchParams)
          }
          placeholder="Select tag(s) from the list below"
          creatable={false}
          noEntitiesMessage="Add tags to your content to filter by tags"
        />
        <div className={styles.tags}>
          <FilterTagActive
            searchParams={searchParams}
            setParams={setParams}
          />
        </div>
      </div>
    )}
    viewComponent={({ toggleModal }) => (
      <ActionComponent
        type="option"
        icon={ICONS.tag.primary}
        text={'Tags'}
        onClick={toggleModal}
      />
    )}
  />
);

export const FilterTagSelectWithUi = withActiveGroup(FilterTagSelect);

const FilterTagSelectReadContainer: React.FC<
  FilterTagSelectReadContainerProps
> = (props) => (
  <FilterEntity
    entityName={Tag.entityName}
    searchParamKey="tag_id"
    {...props}
  >
    {() => <FilterTagSelectWithUi {...props} />}
  </FilterEntity>
);

export default FilterTagSelectReadContainer;
