/**
 * Labstep
 *
 * @module screens/Group/Master/Center
 * @desc Master Center screen for group
 */

import EntitySearch from 'labstep-web/components/Entity/Search';
import { Filter } from 'labstep-web/components/Filter/Menu/types';
import { GroupEmptyState } from 'labstep-web/components/Group/EmptyState';
import UserGroupList from 'labstep-web/components/UserGroup/List';
import { withUiPersistent } from 'labstep-web/containers/UiPersistent';
import { UserGroup } from 'labstep-web/models/user-group.model';
import React from 'react';
import { withRouter } from 'react-router';
import { IUserGroupMasterCenterProps } from './types';

export const GroupMasterCenter: React.FC<
  IUserGroupMasterCenterProps
> = ({
  params,
  searchKey,
  uiPersistent,
  location,
  initialSearchParams,
  ...rest
}) => (
  <EntitySearch
    noResultsMessage={<GroupEmptyState />}
    entityName={UserGroup.entityName}
    searchKey={searchKey}
    params={{ ...params, is_group_deleted: 0 }}
    filters={[Filter.is_group_deleted]}
    historyAction="replace"
    initialSearchParams={
      (uiPersistent.search_params[location.pathname] ||
        initialSearchParams) as any
    }
    {...rest}
  >
    {(props) =>
      rest.children ? (
        rest.children(props)
      ) : (
        <UserGroupList userGroups={props.entities} />
      )
    }
  </EntitySearch>
);

export default withRouter(withUiPersistent(GroupMasterCenter));
