/**
 * Labstep
 *
 * @module components/Notification/SideMenu
 * @desc Notification side menu
 */

import NotificationsPreviewList from 'labstep-web/components/Notification/List/Preview';
import SideMenu from 'labstep-web/core/SideMenu';
import React from 'react';
import styles from './styles.module.scss';
import { NotificationSideMenuProps } from './types';

export const NotificationSideMenu: React.FC<
  NotificationSideMenuProps
> = ({ viewComponent }) => (
  <SideMenu viewComponent={viewComponent} name="notification">
    <div className={styles.container}>
      <div className={styles.notificationsContainer}>
        <NotificationsPreviewList />
      </div>
    </div>
  </SideMenu>
);

export default NotificationSideMenu;
