/**
 * Labstep
 *
 * @module grid/CreateUsers/coldefs
 * @desc   coldefs for Grid to create users
 */

import {
  CellClassParams,
  ColDef,
  EditableCallbackParams,
} from 'ag-grid-community';
import DataGridPlaceholder from 'labstep-web/core/DataGrid/Placeholder';
import { NewUser } from 'labstep-web/grid/CreateUsers/models/new-user';
import { validateEmail } from 'labstep-web/state/selectors/helpers';

export const ADDING_ROW_CTA = 'Add row';

export const getCellStyle = (params: CellClassParams) => {
  const { email, firstName, lastName } = params.data as NewUser;

  if (params.colDef.field === 'firstName') {
    if (!params.value && (lastName || email)) {
      return { backgroundColor: '#F5B1B1' };
    }
  } else if (params.colDef.field === 'lastName') {
    if (
      !params.value &&
      ((firstName && firstName !== ADDING_ROW_CTA) || email)
    ) {
      return { backgroundColor: '#F5B1B1' };
    }
  } else if (params.colDef.field === 'email') {
    if (!params.value) {
      if ((firstName && firstName !== ADDING_ROW_CTA) || lastName) {
        return { backgroundColor: '#F5B1B1' };
      }
    } else if (!validateEmail(params.value)) {
      return { backgroundColor: '#F5B1B1' };
    }
  }

  return null;
};

export const getEditable = (params: EditableCallbackParams) => {
  if (
    params.data.firstName === ADDING_ROW_CTA &&
    params.column.getColId() !== 'firstName'
  ) {
    return false;
  }
  return true;
};

export const cellRenderer = (params: any) => {
  const data = params.data as NewUser;
  let children = 'Enter';
  let editable = true;

  if (data.firstName === ADDING_ROW_CTA) {
    if (params.column.colId === 'firstName') {
      children = ADDING_ROW_CTA;
    } else {
      children = ' ';
      editable = false;
    }
  }
  if (params.value === '' || params.value === ADDING_ROW_CTA) {
    return (
      <DataGridPlaceholder
        params={params}
        editable={editable}
        children={children}
        placeholder={' '}
      />
    );
  }
  return params.value;
};

export const columnDefs: ColDef[] = [
  {
    headerName: 'First Name',
    field: 'firstName',
    editable: getEditable,
    cellStyle: getCellStyle,
    cellRenderer,
    flex: 1,
  },
  {
    headerName: 'Last Name',
    field: 'lastName',
    editable: getEditable,
    cellStyle: getCellStyle,
    cellRenderer,
  },
  {
    headerName: 'Email Address',
    field: 'email',
    editable: getEditable,
    cellStyle: getCellStyle,
    cellRenderer,
  },
];
