/**
 * Labstep
 *
 * @module components/OrganizationPlan/HubspotForm
 * @desc Register for Professional & Ultimate
 */

import { withAuthenticatedUserEntity } from 'labstep-web/containers/AuthenticatedUser';
import ModalDefault from 'labstep-web/core/Modal/Default';
import CoreToast from 'labstep-web/core/Toast';
import useExternalScript from 'labstep-web/hooks/external-script';
import React, { useEffect } from 'react';
import hotToast from 'react-hot-toast';
import styles from './styles.module.scss';
import { OrganizationPlanHubspotFormProps } from './types';

const OrganizationPlanHubspotForm: React.FC<
  OrganizationPlanHubspotFormProps
> = ({ onDone, authenticatedUserEntity }) => {
  const status = useExternalScript(
    'https://js.hsforms.net/forms/v2.js',
  );

  useEffect(() => {
    if (status === 'ready') {
      const form = (window as any).hbspt.forms.create({
        region: 'na1',
        portalId: '1437620',
        formId: '1769f486-d169-433f-8d97-e2b748539a6e',
        sfdcCampaignId: '70169000003sGbzAAE',
        target: '#hubspot-form',
        onFormSubmit() {},
        // onFormReady($form) {
        //   console.log($form.find('input[name="email"]'));
        //   $form
        //     .find('input[name="email"]')
        //     .val(authenticatedUserEntity.email)
        //     .change();
        //   $form
        //     .find('input[name="firstname"]')
        //     .val(authenticatedUserEntity.first_name)
        //     .change();
        //   $form
        //     .find('input[name="lastname"]')
        //     .val(authenticatedUserEntity.last_name)
        //     .change();
        // },
        onFormSubmitted() {
          const id = 'hubspot-form';
          hotToast.success(
            () => (
              <CoreToast
                message="Thank you for registering for the Professional & Ultimate Plan. We will be in touch shortly."
                options={{ header: 'Success' }}
                close={(): void => hotToast.dismiss(id)}
              />
            ),
            {
              duration: 5000,
              position: 'top-right',
              id,
            },
          );
          onDone();
        },
      });
    }
  }, [status]);
  return (
    <ModalDefault
      header="Register for Professional & Ultimate Plan"
      size="large"
      initialModalState
      onClose={onDone}
      viewComponent
      content={
        <div className={styles.container}>
          <div id="hubspot-form" />
        </div>
      }
    />
  );
};

export default withAuthenticatedUserEntity(
  OrganizationPlanHubspotForm,
);
