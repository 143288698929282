/**
 * Labstep
 *
 * @module components/ProtocolValue/Form/ShowEdit/Resource
 * @desc Search and select a Resource for a ProtocolValue
 */

import { filters } from 'labstep-web/components/ProtocolValue/Form/ShowEdit/Resource/constants';
import ResourceInfoPreview from 'labstep-web/components/Resource/Info/Preview';
import FormShowEditSearchSelectModal from 'labstep-web/core/Form/ShowEdit/SearchSelect/Modal';
import columnDefs from 'labstep-web/grid/SearchModal/coldefs/resource';
import { Resource } from 'labstep-web/models/resource.model';
import React from 'react';
import { IProtocolValueShowEditResourceProps } from './types';

export const ProtocolValueShowEditResource: React.FC<
  IProtocolValueShowEditResourceProps
> = ({ protocolValue, ...rest }) => (
  <FormShowEditSearchSelectModal
    childEntityName={Resource.entityName}
    parentEntity={protocolValue}
    optimistic={false} // This is because resource_item_id can become null
    children={
      protocolValue.resource ? (
        <ResourceInfoPreview
          resource={protocolValue.resource}
          {...rest}
        />
      ) : undefined
    }
    createBody={
      protocolValue.resource_template
        ? {
            template_id: protocolValue.resource_template.id,
          }
        : undefined
    }
    creatable
    searchProps={{
      filters,
      params: protocolValue.resource_template
        ? {
            template_id: protocolValue.resource_template.id,
          }
        : undefined,
      entityView: {
        entityName: Resource.entityName,
        context: 'resource_index',
        parameters: protocolValue.resource_template
          ? {
              resource_template_guid:
                protocolValue.resource_template.guid,
            }
          : undefined,
        allowSave: false,
      },
    }}
    actionComponentProps={{
      type: 'text',
      text: `Restrict to specific ${Resource.getHumanReadableEntityName()}`,
      elementProps: {
        placeholder: true,
      },
    }}
    columnDefs={columnDefs(protocolValue.resource_template)}
  />
);

export default ProtocolValueShowEditResource;
