/**
 * Labstep
 *
 * @module core/TableSimple
 * @desc Component that provides simplifed API for rendering tables by providng row and column functions
 */

import Table from 'labstep-web/core/Table';
import React from 'react';
import TableSimpleBody from './Body';
import TableSimpleHeader from './Header';
import { TableSimpleProps } from './types';

export const TableSimple: React.FC<TableSimpleProps> = ({
  columns,
  rows,
  hideHeader,
  footer,
  selectedRowI,
  dataCy,
  ...rest
}) => (
  <Table fixed data-cy={dataCy} {...rest}>
    {!hideHeader && <TableSimpleHeader columns={columns} />}
    <TableSimpleBody
      columns={columns}
      rows={rows}
      footer={footer}
      selectedRowI={selectedRowI}
    />
  </Table>
);

export default TableSimple;
