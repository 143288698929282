/**
 * Labstep
 *
 * @module components/Filter/List
 * @desc A list of filter items
 */

import FilterItem from 'labstep-web/components/Filter/Item';
import { ErrorBoundaryContainer } from 'labstep-web/containers/ErrorBoundary';
import { withUiPersistent } from 'labstep-web/containers/UiPersistent';
import { ParamsContext } from 'labstep-web/contexts/params';
import { ParamsHOC } from 'labstep-web/hoc/Params';
import React from 'react';
import FilterSectionContent from './Content';
import FilterSectionHeader from './Header';
import { FilterListProps, FilterSectionProps } from './types';
import { isActive } from './utils';

export const FilterList: React.FC<FilterListProps> = ({
  filters,
  action,
}) => (
  <>
    <ParamsHOC historyAction="replace">
      <ParamsContext.Consumer>
        {({
          setParams,
          searchParams,
          clearParams,
          addPostFilter,
        }) => (
          <>
            {filters.map((filter, index) => {
              const active = isActive(
                searchParams,
                filter.params,
                filter.postFilterValues,
              );
              return (
                <FilterItem
                  // eslint-disable-next-line react/no-array-index-key
                  key={index}
                  onClick={() => {
                    if (filter.postFilterValues) {
                      const [postFilter, values] =
                        filter.postFilterValues;
                      return addPostFilter(
                        postFilter.getNode(
                          active
                            ? (values as (string | undefined)[]).map(
                                () => undefined,
                              )
                            : (values as (string | undefined)[]),
                        ),
                        true,
                      );
                    }
                    return active
                      ? clearParams(Object.keys(filter.params))
                      : setParams(filter.params);
                  }}
                  active={active}
                  text={filter.text}
                  icon={filter.icon}
                  component={filter.component}
                  actionMenu={filter.actionMenu}
                  subFilters={filter.subFilters}
                />
              );
            })}
          </>
        )}
      </ParamsContext.Consumer>
    </ParamsHOC>
    {action}
  </>
);

export const FilterSectionComponent: React.FC<FilterSectionProps> = ({
  filters,
  header,
  extraFilters,
  route,
  children,
  filter,
}) => (
  <ErrorBoundaryContainer>
    <div id={`filter-list-${filter.name}`}>
      <FilterSectionHeader
        header={header}
        route={route}
        filter={filter}
      />
      <FilterSectionContent
        filters={filters}
        filter={filter}
        extraFilters={extraFilters}
        children={children}
      />
    </div>
  </ErrorBoundaryContainer>
);

export const FilterSection = withUiPersistent(FilterSectionComponent);
