/**
 * Labstep
 *
 * @module components/ResourceLocationMap/Action/UpdateMapDimensions
 * @desc Action to update resource location map  dimensions
 */

import EntityActionEdit from 'labstep-web/components/Entity/Action/Edit';
import ResourceLocationFormEditMapDimensions from 'labstep-web/components/ResourceLocation/Form/Edit/MapDimensions';
import ModalDefault from 'labstep-web/core/Modal/Default';
import React from 'react';
import { IResourceLocationActionUpdateMapDimensionsProps } from './types';

export const ResourceLocationActionUpdateMapDimensions: React.FC<
  IResourceLocationActionUpdateMapDimensionsProps
> = ({ resourceLocation, icon }) => (
  <ModalDefault
    header="Resize Grid"
    content={({ toggleModal }) => (
      <ResourceLocationFormEditMapDimensions
        resourceLocation={resourceLocation}
        options={{ onSuccess: toggleModal }}
      />
    )}
    viewComponent={({ toggleModal }) => (
      <EntityActionEdit
        entity={resourceLocation}
        actionComponentProps={{
          type: 'icon',
          icon: icon || 'expand arrows alternate',
          elementProps: { popup: { content: 'Resize Grid' } },
          onClick: toggleModal,
        }}
      />
    )}
  />
);

export default ResourceLocationActionUpdateMapDimensions;
