/**
 * Labstep
 *
 * @module constants/charmap
 * @desc List of characters and their corresponding codes
 */

export const charmap = [
  {
    name: 'Currency',
    characters: [
      { code: 36, name: 'dollar sign' },
      { code: 162, name: 'cent sign' },
      { code: 8364, name: 'euro sign' },
      { code: 163, name: 'pound sign' },
      { code: 165, name: 'yen sign' },
      { code: 164, name: 'currency sign' },
      { code: 8352, name: 'euro-currency sign' },
      { code: 8353, name: 'colon sign' },
      { code: 8354, name: 'cruzeiro sign' },
      { code: 8355, name: 'french franc sign' },
      { code: 8356, name: 'lira sign' },
      { code: 8357, name: 'mill sign' },
      { code: 8358, name: 'naira sign' },
      { code: 8359, name: 'peseta sign' },
      { code: 8360, name: 'rupee sign' },
      { code: 8361, name: 'won sign' },
      { code: 8362, name: 'new sheqel sign' },
      { code: 8363, name: 'dong sign' },
      { code: 8365, name: 'kip sign' },
      { code: 8366, name: 'tugrik sign' },
      { code: 8367, name: 'drachma sign' },
      { code: 8368, name: 'german penny symbol' },
      { code: 8369, name: 'peso sign' },
      { code: 8370, name: 'guarani sign' },
      { code: 8371, name: 'austral sign' },
      { code: 8372, name: 'hryvnia sign' },
      { code: 8373, name: 'cedi sign' },
      { code: 8374, name: 'livre tournois sign' },
      { code: 8375, name: 'spesmilo sign' },
      { code: 8376, name: 'tenge sign' },
      { code: 8377, name: 'indian rupee sign' },
      { code: 8378, name: 'turkish lira sign' },
      { code: 8379, name: 'nordic mark sign' },
      { code: 8380, name: 'manat sign' },
      { code: 8381, name: 'ruble sign' },
      { code: 20870, name: 'yen character' },
      { code: 20803, name: 'yuan character' },
      {
        code: 22291,
        name: 'yuan character, in hong kong and taiwan',
      },
      { code: 22278, name: 'yen/yuan character variant one' },
    ],
  },
  {
    name: 'Text',
    characters: [
      { code: 169, name: 'copyright sign' },
      { code: 174, name: 'registered sign' },
      { code: 8482, name: 'trade mark sign' },
      { code: 8240, name: 'per mille sign' },
      { code: 181, name: 'micro sign' },
      { code: 183, name: 'middle dot' },
      { code: 8226, name: 'bullet' },
      { code: 8230, name: 'three dot leader' },
      { code: 8242, name: 'minutes / feet' },
      { code: 8243, name: 'seconds / inches' },
      { code: 167, name: 'section sign' },
      { code: 182, name: 'paragraph sign' },
      { code: 223, name: 'sharp s / ess-zed' },
    ],
  },
  {
    name: 'Quotations',
    characters: [
      {
        code: 8249,
        name: 'single left-pointing angle quotation mark',
      },
      {
        code: 8250,
        name: 'single right-pointing angle quotation mark',
      },
      { code: 171, name: 'left pointing guillemet' },
      { code: 187, name: 'right pointing guillemet' },
      { code: 8216, name: 'left single quotation mark' },
      { code: 8217, name: 'right single quotation mark' },
      { code: 8220, name: 'left double quotation mark' },
      { code: 8221, name: 'right double quotation mark' },
      { code: 8218, name: 'single low-9 quotation mark' },
      { code: 8222, name: 'double low-9 quotation mark' },
      { code: 60, name: 'less-than sign' },
      { code: 62, name: 'greater-than sign' },
      { code: 8804, name: 'less-than or equal to' },
      { code: 8805, name: 'greater-than or equal to' },
      { code: 8211, name: 'en dash' },
      { code: 8212, name: 'em dash' },
      { code: 175, name: 'macron' },
      { code: 8254, name: 'overline' },
      { code: 164, name: 'currency sign' },
      { code: 166, name: 'broken bar' },
      { code: 168, name: 'diaeresis' },
      { code: 161, name: 'inverted exclamation mark' },
      { code: 191, name: 'turned question mark' },
      { code: 710, name: 'circumflex accent' },
      { code: 732, name: 'small tilde' },
      { code: 176, name: 'degree sign' },
      { code: 8722, name: 'minus sign' },
      { code: 177, name: 'plus-minus sign' },
      { code: 247, name: 'division sign' },
      { code: 8260, name: 'fraction slash' },
      { code: 215, name: 'multiplication sign' },
      { code: 185, name: 'superscript one' },
      { code: 178, name: 'superscript two' },
      { code: 179, name: 'superscript three' },
      { code: 188, name: 'fraction one quarter' },
      { code: 189, name: 'fraction one half' },
      { code: 190, name: 'fraction three quarters' },
    ],
  },
  {
    name: 'Mathematical',
    characters: [
      { code: 402, name: 'function / florin' },
      { code: 8747, name: 'integral' },
      { code: 8721, name: 'n-ary sumation' },
      { code: 8734, name: 'infinity' },
      { code: 8730, name: 'square root' },
      { code: 8764, name: 'similar to' },
      { code: 8773, name: 'approximately equal to' },
      { code: 8776, name: 'almost equal to' },
      { code: 8800, name: 'not equal to' },
      { code: 8801, name: 'identical to' },
      { code: 8712, name: 'element of' },
      { code: 8713, name: 'not an element of' },
      { code: 8715, name: 'contains as member' },
      { code: 8719, name: 'n-ary product' },
      { code: 8743, name: 'logical and' },
      { code: 8744, name: 'logical or' },
      { code: 172, name: 'not sign' },
      { code: 8745, name: 'intersection' },
      { code: 8746, name: 'union' },
      { code: 8706, name: 'partial differential' },
      { code: 8704, name: 'for all' },
      { code: 8707, name: 'there exists' },
      { code: 8709, name: 'diameter' },
      { code: 8711, name: 'backward difference' },
      { code: 8727, name: 'asterisk operator' },
      { code: 8733, name: 'proportional to' },
      { code: 8736, name: 'angle' },
    ],
  },
  {
    name: 'Extended Latin',
    characters: [
      { code: 192, name: 'A - grave' },
      { code: 193, name: 'A - acute' },
      { code: 194, name: 'A - circumflex' },
      { code: 195, name: 'A - tilde' },
      { code: 196, name: 'A - diaeresis' },
      { code: 197, name: 'A - ring above' },
      { code: 256, name: 'A - macron' },
      { code: 198, name: 'ligature AE' },
      { code: 199, name: 'C - cedilla' },
      { code: 200, name: 'E - grave' },
      { code: 201, name: 'E - acute' },
      { code: 202, name: 'E - circumflex' },
      { code: 203, name: 'E - diaeresis' },
      { code: 274, name: 'E - macron' },
      { code: 204, name: 'I - grave' },
      { code: 205, name: 'I - acute' },
      { code: 206, name: 'I - circumflex' },
      { code: 207, name: 'I - diaeresis' },
      { code: 298, name: 'I - macron' },
      { code: 208, name: 'ETH' },
      { code: 209, name: 'N - tilde' },
      { code: 210, name: 'O - grave' },
      { code: 211, name: 'O - acute' },
      { code: 212, name: 'O - circumflex' },
      { code: 213, name: 'O - tilde' },
      { code: 214, name: 'O - diaeresis' },
      { code: 216, name: 'O - slash' },
      { code: 332, name: 'O - macron' },
      { code: 338, name: 'ligature OE' },
      { code: 352, name: 'S - caron' },
      { code: 217, name: 'U - grave' },
      { code: 218, name: 'U - acute' },
      { code: 219, name: 'U - circumflex' },
      { code: 220, name: 'U - diaeresis' },
      { code: 362, name: 'U - macron' },
      { code: 221, name: 'Y - acute' },
      { code: 376, name: 'Y - diaeresis' },
      { code: 562, name: 'Y - macron' },
      { code: 222, name: 'THORN' },
      { code: 224, name: 'a - grave' },
      { code: 225, name: 'a - acute' },
      { code: 226, name: 'a - circumflex' },
      { code: 227, name: 'a - tilde' },
      { code: 228, name: 'a - diaeresis' },
      { code: 229, name: 'a - ring above' },
      { code: 257, name: 'a - macron' },
      { code: 230, name: 'ligature ae' },
      { code: 231, name: 'c - cedilla' },
      { code: 232, name: 'e - grave' },
      { code: 233, name: 'e - acute' },
      { code: 234, name: 'e - circumflex' },
      { code: 235, name: 'e - diaeresis' },
      { code: 275, name: 'e - macron' },
      { code: 236, name: 'i - grave' },
      { code: 237, name: 'i - acute' },
      { code: 238, name: 'i - circumflex' },
      { code: 239, name: 'i - diaeresis' },
      { code: 299, name: 'i - macron' },
      { code: 240, name: 'eth' },
      { code: 241, name: 'n - tilde' },
      { code: 242, name: 'o - grave' },
      { code: 243, name: 'o - acute' },
      { code: 244, name: 'o - circumflex' },
      { code: 245, name: 'o - tilde' },
      { code: 246, name: 'o - diaeresis' },
      { code: 248, name: 'o slash' },
      { code: 333, name: 'o macron' },
      { code: 339, name: 'ligature oe' },
      { code: 353, name: 's - caron' },
      { code: 249, name: 'u - grave' },
      { code: 250, name: 'u - acute' },
      { code: 251, name: 'u - circumflex' },
      { code: 252, name: 'u - diaeresis' },
      { code: 363, name: 'u - macron' },
      { code: 253, name: 'y - acute' },
      { code: 254, name: 'thorn' },
      { code: 255, name: 'y - diaeresis' },
      { code: 563, name: 'y - macron' },
      { code: 913, name: 'Alpha' },
      { code: 914, name: 'Beta' },
      { code: 915, name: 'Gamma' },
      { code: 916, name: 'Delta' },
      { code: 917, name: 'Epsilon' },
      { code: 918, name: 'Zeta' },
      { code: 919, name: 'Eta' },
      { code: 920, name: 'Theta' },
      { code: 921, name: 'Iota' },
      { code: 922, name: 'Kappa' },
      { code: 923, name: 'Lambda' },
      { code: 924, name: 'Mu' },
      { code: 925, name: 'Nu' },
      { code: 926, name: 'Xi' },
      { code: 927, name: 'Omicron' },
      { code: 928, name: 'Pi' },
      { code: 929, name: 'Rho' },
      { code: 931, name: 'Sigma' },
      { code: 932, name: 'Tau' },
      { code: 933, name: 'Upsilon' },
      { code: 934, name: 'Phi' },
      { code: 935, name: 'Chi' },
      { code: 936, name: 'Psi' },
      { code: 937, name: 'Omega' },
      { code: 945, name: 'alpha' },
      { code: 946, name: 'beta' },
      { code: 947, name: 'gamma' },
      { code: 948, name: 'delta' },
      { code: 949, name: 'epsilon' },
      { code: 950, name: 'zeta' },
      { code: 951, name: 'eta' },
      { code: 952, name: 'theta' },
      { code: 953, name: 'iota' },
      { code: 954, name: 'kappa' },
      { code: 955, name: 'lambda' },
      { code: 956, name: 'mu' },
      { code: 957, name: 'nu' },
      { code: 958, name: 'xi' },
      { code: 959, name: 'omicron' },
      { code: 960, name: 'pi' },
      { code: 961, name: 'rho' },
      { code: 962, name: 'final sigma' },
      { code: 963, name: 'sigma' },
      { code: 964, name: 'tau' },
      { code: 965, name: 'upsilon' },
      { code: 966, name: 'phi' },
      { code: 967, name: 'chi' },
      { code: 968, name: 'psi' },
      { code: 969, name: 'omega' },
    ],
  },
  {
    name: 'Symbols',
    characters: [
      { code: 8501, name: 'alef symbol' },
      { code: 982, name: 'pi symbol' },
      { code: 8476, name: 'real part symbol' },
      { code: 978, name: 'upsilon - hook symbol' },
      { code: 8472, name: 'Weierstrass p' },
      { code: 8465, name: 'imaginary part' },
    ],
  },
  {
    name: 'Arrows',
    characters: [
      { code: 8592, name: 'leftwards arrow' },
      { code: 8593, name: 'upwards arrow' },
      { code: 8594, name: 'rightwards arrow' },
      { code: 8595, name: 'downwards arrow' },
      { code: 8596, name: 'left right arrow' },
      { code: 8629, name: 'carriage return' },
      { code: 8656, name: 'leftwards double arrow' },
      { code: 8657, name: 'upwards double arrow' },
      { code: 8658, name: 'rightwards double arrow' },
      { code: 8659, name: 'downwards double arrow' },
      { code: 8660, name: 'left right double arrow' },
      { code: 8756, name: 'therefore' },
      { code: 8834, name: 'subset of' },
      { code: 8835, name: 'superset of' },
      { code: 8836, name: 'not a subset of' },
      { code: 8838, name: 'subset of or equal to' },
      { code: 8839, name: 'superset of or equal to' },
      { code: 8853, name: 'circled plus' },
      { code: 8855, name: 'circled times' },
      { code: 8869, name: 'perpendicular' },
      { code: 8901, name: 'dot operator' },
      { code: 8968, name: 'left ceiling' },
      { code: 8969, name: 'right ceiling' },
      { code: 8970, name: 'left floor' },
      { code: 8971, name: 'right floor' },
      { code: 9001, name: 'left-pointing angle bracket' },
      { code: 9002, name: 'right-pointing angle bracket' },
      { code: 9674, name: 'lozenge' },
      { code: 9824, name: 'black spade suit' },
      { code: 9827, name: 'black club suit' },
      { code: 9829, name: 'black heart suit' },
      { code: 9830, name: 'black diamond suit' },
      { code: 8194, name: 'en space' },
      { code: 8195, name: 'em space' },
      { code: 8201, name: 'thin space' },
      { code: 8204, name: 'zero width non-joiner' },
      { code: 8205, name: 'zero width joiner' },
      { code: 8206, name: 'left-to-right mark' },
      { code: 8207, name: 'right-to-left mark' },
    ],
  },
];
