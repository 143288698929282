/**
 * Labstep
 *
 * @module constants/permissions
 * @desc Constants related to the permission layer
 */

export const TOP_LEVEL_PERMISSION_ENTITIES_BY_ENTITY_NAME = [
  'device',
  'device_template',
  'entity_state_workflow',
  'experiment_workflow',
  'experiment_workflow_template',
  'folder',
  'order_request',
  'order_request_template',
  'organization',
  'protocol_collection',
  'purchase_order',
  'purchase_order_template',
  'resource',
  'resource_location',
  'resource_template',
  'tag',
  'user',
];

interface IPermissions {
  experiment_workflow_create: boolean;
  experiment_workflow_edit: boolean;
  entity_user_experiment_workflow: boolean;
  experiment_workflow_delete: boolean;
  permission_experiment_workflow: boolean;
  experiment_workflow_lock: boolean;
  experiment_workflow_unlock: boolean;
  comment_experiment_workflow: boolean;
  experiment_workflow_template_create: boolean;
  experiment_workflow_template_edit: boolean;
  experiment_workflow_template_delete: boolean;
  signature_create_create: boolean;
  protocol_collection_create: boolean;
  protocol_collection_edit: boolean;
  entity_user_protocol_collection: boolean;
  protocol_collection_delete: boolean;
  permission_protocol_collection: boolean;
  comment_protocol_collection: boolean;
  resource_template_create: boolean;
  resource_template_edit: boolean;
  entity_user_resource_template: boolean;
  resource_template_delete: boolean;
  permission_resource_template: boolean;
  resource_create: boolean;
  resource_edit: boolean;
  entity_user_resource: boolean;
  resource_delete: boolean;
  permission_resource: boolean;
  comment_resource: boolean;
  resource_item_create: boolean;
  resource_item_edit: boolean;
  entity_user_resource_item: boolean;
  resource_item_delete: boolean;
  comment_resource_item: boolean;
  resource_location_create: boolean;
  resource_location_edit: boolean;
  entity_user_resource_location: boolean;
  resource_location_delete: boolean;
  comment_resource_location: boolean;
  order_request_create: boolean;
  order_request_edit: boolean;
  entity_user_order_request: boolean;
  order_request_delete: boolean;
  permission_order_request: boolean;
  comment_order_request: boolean;
  purchase_order_create: boolean;
  purchase_order_edit: boolean;
  entity_user_purchase_order: boolean;
  purchase_order_delete: boolean;
  comment_purchase_order: boolean;
  device_create: boolean;
  device_edit: boolean;
  entity_user_device: boolean;
  device_delete: boolean;
  permission_device: boolean;
  comment_device: boolean;
  device_data_create: boolean;
  device_booking_create: boolean;
  tag_create: boolean;
  tag_edit: boolean;
  tag_delete: boolean;
  folder_create: boolean;
  folder_edit: boolean;
  folder_delete: boolean;
  comment_group: boolean;
  group_create: boolean;
  group_edit: boolean;
  entity_export_create: boolean;
  metadata_create: boolean;
  metadata_edit: boolean;
  protocol_value_create: boolean;
  protocol_device_create: boolean;
  experiment_workflow_folder: boolean;
  experiment_workflow_tag: boolean;
  protocol_collection_folder: boolean;
  protocol_collection_tag: boolean;
  device_folder: boolean;
  device_tag: boolean;
  resource_template_tag: boolean;
  resource_tag: boolean;
  order_request_tag: boolean;
  purchase_order_tag: boolean;
  [key: string]: boolean;
}

const PERMISSIONS_VIEWER: IPermissions = {
  experiment_workflow_create: false,
  experiment_workflow_edit: false,
  entity_user_experiment_workflow: false,
  experiment_workflow_delete: false,
  permission_experiment_workflow: false,
  experiment_workflow_lock: false,
  experiment_workflow_unlock: false,
  comment_experiment_workflow: false,
  experiment_workflow_template_create: false,
  experiment_workflow_template_edit: false,
  experiment_workflow_template_delete: false,
  signature_create_create: false,
  protocol_collection_create: false,
  protocol_collection_edit: false,
  entity_user_protocol_collection: false,
  protocol_collection_delete: false,
  permission_protocol_collection: false,
  comment_protocol_collection: false,
  resource_template_create: false,
  resource_template_edit: false,
  entity_user_resource_template: false,
  resource_template_delete: false,
  permission_resource_template: false,
  resource_create: false,
  resource_edit: false,
  entity_user_resource: false,
  resource_delete: false,
  permission_resource: false,
  comment_resource: false,
  resource_item_create: false,
  resource_item_edit: false,
  entity_user_resource_item: false,
  resource_item_delete: false,
  comment_resource_item: false,
  resource_location_create: false,
  resource_location_edit: false,
  entity_user_resource_location: false,
  resource_location_delete: false,
  comment_resource_location: false,
  order_request_create: false,
  order_request_edit: false,
  entity_user_order_request: false,
  order_request_delete: false,
  permission_order_request: false,
  comment_order_request: false,
  purchase_order_create: false,
  purchase_order_edit: false,
  entity_user_purchase_order: false,
  purchase_order_delete: false,
  comment_purchase_order: false,
  device_create: false,
  device_edit: false,
  entity_user_device: false,
  device_delete: false,
  permission_device: false,
  comment_device: false,
  device_data_create: false,
  device_booking_create: false,
  tag_create: false,
  tag_edit: false,
  tag_delete: false,
  folder_create: false,
  folder_edit: false,
  folder_delete: false,
  comment_group: false,
  group_create: false,
  group_edit: false,
  entity_export_create: true,
  metadata_create: false,
  metadata_edit: false,
  protocol_value_create: false,
  protocol_device_create: false,
  experiment_workflow_folder: false,
  experiment_workflow_tag: false,
  protocol_collection_folder: false,
  protocol_collection_tag: false,
  device_folder: false,
  device_tag: false,
  resource_template_tag: false,
  resource_tag: false,
  order_request_tag: false,
  purchase_order_tag: false,
};

const PERMISSIONS_EDITOR: IPermissions = {
  experiment_workflow_create: true,
  experiment_workflow_edit: true,
  entity_user_experiment_workflow: true,
  experiment_workflow_delete: true,
  permission_experiment_workflow: true,
  experiment_workflow_lock: true,
  experiment_workflow_unlock: true,
  comment_experiment_workflow: true,
  experiment_workflow_template_create: true,
  experiment_workflow_template_edit: true,
  experiment_workflow_template_delete: true,
  signature_create_create: true,
  protocol_collection_create: true,
  protocol_collection_edit: true,
  entity_user_protocol_collection: true,
  protocol_collection_delete: true,
  permission_protocol_collection: true,
  comment_protocol_collection: true,
  resource_template_create: true,
  resource_template_edit: true,
  entity_user_resource_template: true,
  resource_template_delete: true,
  permission_resource_template: true,
  resource_create: true,
  resource_edit: true,
  entity_user_resource: true,
  resource_delete: true,
  permission_resource: true,
  comment_resource: true,
  resource_item_create: true,
  resource_item_edit: true,
  entity_user_resource_item: true,
  resource_item_delete: true,
  comment_resource_item: true,
  resource_location_create: true,
  resource_location_edit: true,
  entity_user_resource_location: true,
  resource_location_delete: true,
  comment_resource_location: true,
  order_request_create: true,
  order_request_edit: true,
  entity_user_order_request: true,
  order_request_delete: true,
  permission_order_request: true,
  comment_order_request: true,
  purchase_order_create: true,
  purchase_order_edit: true,
  entity_user_purchase_order: true,
  purchase_order_delete: true,
  comment_purchase_order: true,
  device_create: true,
  device_edit: true,
  entity_user_device: true,
  device_delete: true,
  permission_device: true,
  comment_device: true,
  device_data_create: true,
  device_booking_create: true,
  tag_create: true,
  tag_edit: true,
  tag_delete: true,
  folder_create: true,
  folder_edit: true,
  folder_delete: true,
  comment_group: true,
  group_create: false,
  group_edit: false,
  entity_export_create: true,
  metadata_create: true,
  metadata_edit: true,
  protocol_value_create: true,
  protocol_device_create: true,
  experiment_workflow_folder: true,
  experiment_workflow_tag: true,
  protocol_collection_folder: true,
  protocol_collection_tag: true,
  device_folder: true,
  device_tag: true,
  resource_template_tag: true,
  resource_tag: true,
  order_request_tag: true,
  purchase_order_tag: true,
};

const PERMISSIONS_OWNER: IPermissions = {
  experiment_workflow_create: true,
  experiment_workflow_edit: true,
  entity_user_experiment_workflow: true,
  experiment_workflow_delete: true,
  permission_experiment_workflow: true,
  experiment_workflow_lock: true,
  experiment_workflow_unlock: true,
  comment_experiment_workflow: true,
  experiment_workflow_template_create: true,
  experiment_workflow_template_edit: true,
  experiment_workflow_template_delete: true,
  signature_create_create: true,
  protocol_collection_create: true,
  protocol_collection_edit: true,
  entity_user_protocol_collection: true,
  protocol_collection_delete: true,
  permission_protocol_collection: true,
  comment_protocol_collection: true,
  resource_template_create: true,
  resource_template_edit: true,
  entity_user_resource_template: true,
  resource_template_delete: true,
  permission_resource_template: true,
  resource_create: true,
  resource_edit: true,
  entity_user_resource: true,
  resource_delete: true,
  permission_resource: true,
  comment_resource: true,
  resource_item_create: true,
  resource_item_edit: true,
  entity_user_resource_item: true,
  resource_item_delete: true,
  comment_resource_item: true,
  resource_location_create: true,
  resource_location_edit: true,
  entity_user_resource_location: true,
  resource_location_delete: true,
  comment_resource_location: true,
  order_request_create: true,
  order_request_edit: true,
  entity_user_order_request: true,
  order_request_delete: true,
  permission_order_request: true,
  comment_order_request: true,
  purchase_order_create: true,
  purchase_order_edit: true,
  entity_user_purchase_order: true,
  purchase_order_delete: true,
  comment_purchase_order: true,
  device_create: true,
  device_edit: true,
  entity_user_device: true,
  device_delete: true,
  permission_device: true,
  comment_device: true,
  device_data_create: true,
  device_booking_create: true,
  tag_create: true,
  tag_edit: true,
  tag_delete: true,
  folder_create: true,
  folder_edit: true,
  folder_delete: true,
  comment_group: true,
  group_create: true,
  group_edit: true,
  entity_export_create: true,
  metadata_create: true,
  metadata_edit: true,
  protocol_value_create: true,
  protocol_device_create: true,
  experiment_workflow_folder: true,
  experiment_workflow_tag: true,
  protocol_collection_folder: true,
  protocol_collection_tag: true,
  device_folder: true,
  device_tag: true,
  resource_template_tag: true,
  resource_tag: true,
  order_request_tag: true,
  purchase_order_tag: true,
};

interface IStaticPermissions {
  viewer: IPermissions;
  editor: IPermissions;
  owner: IPermissions;
}

export const PERMISSIONS: IStaticPermissions = {
  viewer: PERMISSIONS_VIEWER,
  editor: PERMISSIONS_EDITOR,
  owner: PERMISSIONS_OWNER,
};
