/**
 * Labstep
 *
 * @module prosemirror/nodes/hard-break/commands
 * @desc Commands for Hard break node
 */

import { chainCommands, exitCode } from 'prosemirror-commands';
import { NodeType } from 'prosemirror-model';

export const getHardBreakCmd = (type: NodeType) => {
  const br = type;
  const cmd = chainCommands(exitCode, (state, dispatch) => {
    if (dispatch) {
      dispatch(
        state.tr.replaceSelectionWith(br.create()).scrollIntoView(),
      );
      return true;
    }
    return false;
  });
  return cmd;
};
