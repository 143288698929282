/**
 * Labstep
 *
 * @module core/Modal/Expandable
 * @desc Component to expand a modal into fullscreen
 */

import Icon from 'labstep-web/core/Icon';
import Modal from 'labstep-web/core/Modal/Default';
import React from 'react';
import styles from './styles.module.scss';
import { IModalExpandableProps } from './types';

export const ModalExpandable: React.FC<IModalExpandableProps> = ({
  icon,
  name,
  actions,
  content,
}) => {
  const header = (
    <span className={styles.name}>
      {icon && <Icon name={icon} />}
      {name}
    </span>
  );
  return (
    <Modal
      viewComponent={({ toggleModal, open }) => (
        <div>
          <div className={styles.info}>
            <div className={styles.infoContainer}>{header}</div>
            <div className={styles.actionContainer}>
              {actions}
              <Icon
                name="external alternate"
                color="blue"
                onClick={toggleModal}
              />
            </div>
          </div>
          <div className={styles.contentContainer}>
            {
              // hide viewComponent in fullscreen and rerender on close
              // prevents interfering behaviour between content in viewComponent/modal
              !open && content({ expanded: false })
            }
          </div>
        </div>
      )}
      size="fullscreen"
      header={header}
      content={content({ expanded: true })}
    />
  );
};

export default ModalExpandable;
