/**
 * Labstep
 *
 * @module components/ShareLink/Accept/Entity/GroupOrOrganization/LimitReachedContent
 * @desc Component for when sharelink for entities limits of type group or user groups have been reached
 */

import AcademicActionUpgrade from 'labstep-web/components/Academic/Action/Upgrade';
import { ICONS } from 'labstep-web/constants/icons';
import Button from 'labstep-web/core/Button';
import Flex from 'labstep-web/core/Flex';
import Header from 'labstep-web/core/Header';
import Image from 'labstep-web/core/Image';
import { useHasOrganization } from 'labstep-web/hooks/limits/limits';
import React from 'react';
import styles from './styles.module.scss';
import { IShareLinkLimitReachedContentProps } from './types';

export const ShareLinkLimitReached: React.FC<
  IShareLinkLimitReachedContentProps
> = ({ onClick, heading, description, showUpgrade }) => {
  const hasOrganizations = useHasOrganization();

  return (
    <Flex column hAlignContent="center" className={styles.container}>
      <Image
        src="/img/empty-state/compliance.svg"
        data-public
        size="large"
      />
      <Header size="small" className={styles.heading}>
        {heading}
      </Header>
      <p className={styles.description}>{description}</p>
      <Flex hAlignContent="center" spacing="gap">
        <Button
          primary
          basic
          onClick={onClick}
          route={{ to: 'app_homepage' }}
          size="large"
          className={styles.button}
        >
          Go to your home workspace
        </Button>
        {showUpgrade && !hasOrganizations && (
          <AcademicActionUpgrade
            actionComponentProps={{
              type: 'button',
              icon: ICONS.upgrade.primary,
              text: 'Upgrade',
              showIcon: true,
              elementProps: {
                color: 'green',
                size: 'large',
                className: styles.shiny,
              },
              onClick: (): void => {},
            }}
          />
        )}
      </Flex>
    </Flex>
  );
};
