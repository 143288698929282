/**
 * Labstep
 *
 * @module components/Folder/Action/Edit
 * @desc Edit a folder table
 */

import EntityActionEdit from 'labstep-web/components/Entity/Action/Edit';
import FolderFormEdit from 'labstep-web/components/Folder/Form/Edit';
import ModalDefault from 'labstep-web/core/Modal/Default';
import React from 'react';
import { FolderActionEditProps } from './types';

export const FolderActionEdit: React.FC<FolderActionEditProps> = ({
  folder,
  actionComponentProps = {},
}) => (
  <ModalDefault
    header="Editing"
    viewComponent={({ toggleModal }) => (
      <EntityActionEdit
        entity={folder}
        actionComponentProps={{
          type: 'option',
          onClick: toggleModal,
          icon: 'pencil',
          text: 'Edit',
          ...actionComponentProps,
        }}
      />
    )}
    content={({ toggleModal }) => (
      <FolderFormEdit
        folder={folder}
        options={{ onSuccess: toggleModal }}
      />
    )}
  />
);

export default FolderActionEdit;
