/**
 * Labstep
 *
 * @module components/Resource/Action/Duplicate
 * @desc Duplicate action for resource
 */

import EntityActionDuplicate from 'labstep-web/components/Entity/Action/Duplicate';
import React from 'react';
import { IResourceActionDuplicateProps } from './types';

export const ResourceActionDuplicate: React.FC<
  IResourceActionDuplicateProps
> = ({ resource }) => <EntityActionDuplicate entity={resource} />;

export default ResourceActionDuplicate;
