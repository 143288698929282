/**
 * Labstep
 *
 * @module components/EntityStateWorkflow/Form/ShowEdit/Name
 * @desc EntityStateWorkflow update name
 */

import EntityPrimaryInfo from 'labstep-web/components/Entity/PrimaryInfo';
import { ICONS } from 'labstep-web/constants/icons';
import React from 'react';
import { EntityStateWorkflowFormShowEditNameProps } from './types';

const EntityStateWorkflowFormShowEditName: React.FC<
  EntityStateWorkflowFormShowEditNameProps
> = ({ entityStateWorkflow }) => (
  <EntityPrimaryInfo
    entity={entityStateWorkflow}
    icon={ICONS.entity_state_workflow.primary}
    name={entityStateWorkflow.name}
  />
);

export default EntityStateWorkflowFormShowEditName;
