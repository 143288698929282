/**
 * Labstep
 *
 * @module components/EntityView/Action/Create
 * @desc Action to create EntityView
 */

import { CanCreate } from 'labstep-web/components/Entity/Can';
import EntityViewFormCreate from 'labstep-web/components/EntityView/Form/Create';
import { useParamsContext } from 'labstep-web/contexts/params/hook';
import { ActionComponent } from 'labstep-web/core/Action/Component';
import ModalDefault from 'labstep-web/core/Modal/Default';
import { EntityView } from 'labstep-web/models/entity-view.model';
import React from 'react';
import { EntityViewActionCreateProps } from './types';

export const EntityViewActionCreate: React.FC<
  EntityViewActionCreateProps
> = ({ group }) => {
  const { searchParams } = useParamsContext();

  return (
    <ModalDefault
      size="large"
      header="New Report"
      content={({ toggleModal }): React.ReactElement => (
        <EntityViewFormCreate
          group={group}
          onSuccess={toggleModal}
          folderId={searchParams.folder_id as number}
        />
      )}
      viewComponent={({ toggleModal }): React.ReactElement => (
        <CanCreate entityName={EntityView.entityName}>
          <ActionComponent
            type="button"
            text="New Report"
            onClick={toggleModal}
          />
        </CanCreate>
      )}
    />
  );
};

export default EntityViewActionCreate;
