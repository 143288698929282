/**
 * Labstep
 *
 * @module prosemirror/components/NodeView/Experiment
 * @desc Renders an Experiment node
 */

import { ICONS } from 'labstep-web/constants/icons';
import { Entity } from 'labstep-web/models/entity.model';
import { Experiment } from 'labstep-web/models/experiment.model';
import ViewInline from 'labstep-web/prosemirror/components/Inline/View';
import Read from 'labstep-web/prosemirror/components/NodeView/Read';
import { useReactNodeView } from 'labstep-web/prosemirror/components/ReactNodeView';
import React from 'react';
import { withRouter } from 'react-router';
import { IExperimentNodeProps } from './types';
import { getExperimentPath } from './utils';

export class ExperimentNode extends React.Component<IExperimentNodeProps> {
  public shouldComponentUpdate(): boolean {
    return Boolean(!this);
  }

  public render(): React.ReactElement {
    const { id, timestamp, history } = this.props;

    return (
      <div contentEditable={false}>
        <Read
          entityName={Experiment.entityName}
          id={id}
          timestamp={timestamp}
        >
          {({ entity }: { entity: Entity }): React.ReactElement => (
            <ViewInline
              entity={entity}
              icon={ICONS.protocol.primary}
              onClick={(): void => {
                history.push(getExperimentPath(entity as Experiment));
              }}
            >
              {(entity as Experiment).name}
            </ViewInline>
          )}
        </Read>
      </div>
    );
  }
}

export const ExperimentNodeView: React.FC<any> = ({
  entity,
  timestamp,
  ...rest
}) => {
  const context = useReactNodeView();
  if (!context?.node) {
    return null;
  }
  const { id } = context.node.attrs;

  return (
    <ExperimentNode
      entity={entity}
      id={id}
      {...rest}
      timestamp={timestamp}
    />
  );
};

export default withRouter(ExperimentNodeView);
