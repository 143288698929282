/**
 * Labstep
 *
 * @module components/ProtocolCollection/Action/View
 * @desc Action linking to protocol collection
 */

import EntityActionView from 'labstep-web/components/Entity/Action/View';
import { ProtocolCollection } from 'labstep-web/models/protocol-collection.model';
import React from 'react';
import { IProtocolCollectionActionViewProps } from './types';

export const ProtocolCollectionViewAction: React.FC<
  IProtocolCollectionActionViewProps
> = ({ protocolCollection, actionComponentProps }) => (
  <EntityActionView
    entityName={ProtocolCollection.entityName}
    params={{ id: protocolCollection.id }}
    actionComponentProps={actionComponentProps}
  />
);

export default ProtocolCollectionViewAction;
