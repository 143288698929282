/**
 * Labstep
 *
 * @module components/EntityStateWorkflow/Index
 * @desc EntityStateWorkflow Index
 */

import EntitySearch from 'labstep-web/components/Entity/Search';
import { BulkAction } from 'labstep-web/components/Entity/Search/BulkActions/types';
import { EntityTable } from 'labstep-web/components/Entity/Table';
import EntityStateWorkflowActionCreate from 'labstep-web/components/EntityStateWorkflow/Action/Create';
import EntityStateWorkflowCardEmptyState from 'labstep-web/components/EntityStateWorkflow/Card/EmptyState';
import { withUiPersistent } from 'labstep-web/containers/UiPersistent';
import { EntityStateWorkflow } from 'labstep-web/models/entity-state-workflow.model';
import MasterIndexCenterListOrTable from 'labstep-web/screens/Master/Index/Center/ListOrTable';
import React, { useMemo } from 'react';
import EntityStateWorkflowActionMenu from '../Action/Menu';
import { Action } from '../Action/Menu/types';
import EntityStateWorkflowList from '../List';
import { filters } from './constants';
import { EntityStateWorkflowIndexProps } from './types';

export const EntityStateWorkflowIndex: React.FC<
  EntityStateWorkflowIndexProps
> = ({ group }) => {
  const params = useMemo(() => ({ group_id: group.id }), [group.id]);

  const actions = [
    Action.assign,
    Action.duplicate,
    Action.share,
    Action.transfer_ownership,
    Action.delete,
  ];

  return (
    <EntitySearch
      params={params}
      noResultsMessage={
        <EntityStateWorkflowCardEmptyState group={group} />
      }
      filters={filters}
      action={<EntityStateWorkflowActionCreate group={group} />}
      entityName={EntityStateWorkflow.entityName}
      bulkActions={[
        BulkAction.assign,
        BulkAction.delete,
        BulkAction.share,
        BulkAction.transfer_ownership,
      ]}
    >
      {({ entities, ...rest }): React.ReactElement => (
        <MasterIndexCenterListOrTable
          tableComponent={
            <EntityTable
              id="entity-state-workflow-table"
              entities={entities}
              editableName
              showCollaborators
              actionMenu={({ entity }) => (
                <EntityStateWorkflowActionMenu
                  entityStateWorkflow={entity}
                  actions={actions}
                />
              )}
              {...rest}
            />
          }
          listComponent={
            <EntityStateWorkflowList
              entityStateWorkflows={entities}
              actions={actions}
              {...rest}
            />
          }
        />
      )}
    </EntitySearch>
  );
};

export default withUiPersistent(EntityStateWorkflowIndex);
