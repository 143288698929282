/**
 * Labstep
 *
 * @module components/EntityImageUploadable
 * @desc Upload an image for an entity
 */

import { useHasAccess } from 'labstep-web/components/Entity/Can/hooks';
import { Action } from 'labstep-web/components/Entity/Can/types';
import FileActionUpload from 'labstep-web/components/File/Action/Upload';
import { ThumbnailContainer } from 'labstep-web/containers/File/Thumbnail';
import Button from 'labstep-web/core/Button';
import Popup from 'labstep-web/core/Popup';
import React from 'react';
import { EntityImageUploadableProps } from './types';

const EntityImageUploadable: React.FC<EntityImageUploadableProps> = ({
  entity,
  mobile,
}) => {
  const canEdit = useHasAccess(
    entity.entityName,
    entity.idAttr,
    Action.edit,
  );
  const dimension = mobile ? 70 : 130;
  const image = entity.image && (
    <ThumbnailContainer file={entity.image}>
      {({ src, onError }) => (
        <img
          src={src}
          width={dimension}
          height={dimension}
          alt={entity.name || undefined}
          onError={onError}
        />
      )}
    </ThumbnailContainer>
  );

  if (canEdit && !mobile) {
    return (
      <FileActionUpload
        entityId={entity.id}
        entityType={entity.entityName}
        type="image"
        field="image"
      >
        <Popup
          trigger={
            image || <Button size="small" icon="camera" circular />
          }
          content="Upload Image"
          size="mini"
        />
      </FileActionUpload>
    );
  }

  return image || null;
};

export default EntityImageUploadable;
