/**
 * Labstep
 *
 * @module core/Dropdown
 * @desc Core dropdown component. Wrapper around semantic ui dropdown.
 */

import classnames from 'classnames';
import { ToggleContainer } from 'labstep-web/containers/Toggle';
import Mobile from 'labstep-web/core/MediaQuery/Mobile';
import React from 'react';
import SemanticDropdown from 'semantic-ui-react/dist/commonjs/modules/Dropdown';
import Ref from '../Ref';
import styles from './styles.module.scss';
import { IDropdownProps } from './types';

class Dropdown extends React.Component<
  IDropdownProps,
  { openedOnce: boolean }
> {
  public static Dropdown = SemanticDropdown;

  public static Item = SemanticDropdown.Item;

  public static Menu = SemanticDropdown.Menu;

  public static Header = SemanticDropdown.Header;

  public static Divider = SemanticDropdown.Divider;

  public constructor(props: IDropdownProps) {
    super(props);
    this.state = { openedOnce: false };
    this.handleOpen = this.handleOpen.bind(this);
  }

  public handleOpen(toggle: VoidFunction) {
    toggle();
    if (!this.state.openedOnce) {
      this.setState({ openedOnce: true });
    }
  }

  public render() {
    const {
      children,
      pointing,
      upward,
      isOpen,
      onClick,
      menuRef,
      ...rest
    } = this.props;

    return (
      <ToggleContainer>
        {({ toggle, toggled }) => (
          <Mobile>
            {({ match }) => (
              <div
                className={classnames({
                  [styles.mobile]: match,
                })}
              >
                {toggled && match && (
                  <div
                    onClick={(evt) => {
                      evt.preventDefault();
                      evt.stopPropagation();
                      toggle();
                    }}
                    className={styles.backdrop}
                  />
                )}
                <SemanticDropdown
                  {...rest}
                  upward={match ? true : upward}
                  open={isOpen || toggled}
                  onOpen={(): void => {
                    this.handleOpen(toggle);
                  }}
                  onClose={toggle}
                  pointing={match ? false : pointing}
                  onClick={onClick}
                >
                  <Ref innerRef={menuRef}>
                    <SemanticDropdown.Menu>
                      {/* Lazy Loading */}
                      {this.state.openedOnce && children}
                    </SemanticDropdown.Menu>
                  </Ref>
                </SemanticDropdown>
              </div>
            )}
          </Mobile>
        )}
      </ToggleContainer>
    );
  }
}

export default Dropdown;
