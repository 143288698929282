/**
 * Labstep
 *
 * @module components/Experiment/Form/fields
 * @desc Experiment fields
 */

import {
  FieldType,
  FieldWithoutActionProps,
} from 'labstep-web/core/Form/Reusable/types';
import rules from 'labstep-web/services/validation/rules';

export const fieldExperimentName: FieldWithoutActionProps = {
  fieldType: FieldType.Text,
  name: 'name',
  validation: rules.experiment.name,
  placeholder: 'Give your experiment a name',
  header: true,
};
