/**
 * Labstep
 *
 * @module components/Group/Action/SetHome
 * @desc Set home group
 */

import { EntityUpdateContainer } from 'labstep-web/containers/Entity/Update';
import ActionComponent from 'labstep-web/core/Action/Component';
import { UserGroup } from 'labstep-web/models/user-group.model';
import React from 'react';
import { GroupActionSetHomeProps } from './types';

export const GroupActionSetHome: React.FC<
  GroupActionSetHomeProps
> = ({ userGroup, actionComponentProps }) =>
  !userGroup.is_home && !userGroup.group.deleted_at ? (
    <EntityUpdateContainer
      entityName={UserGroup.entityName}
      id={userGroup.id}
    >
      {({ update, status }) => (
        <ActionComponent
          {...actionComponentProps}
          status={status}
          text="Set as Home"
          icon="home"
          onClick={() => update({ is_home: true })}
        />
      )}
    </EntityUpdateContainer>
  ) : null;

export default GroupActionSetHome;
