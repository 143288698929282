/**
 * Labstep
 *
 * @module components/ProtocolValue/Show/PermaLinkReader
 * @desc PermaLink Reader in Experiment Value Show
 */

import PermaLinkReader from 'labstep-web/components/PermaLink/Reader';
import ActionComponent from 'labstep-web/core/Action/Component';
import Placeholder from 'labstep-web/core/Placeholder';
import { ReadOnMountHOC } from 'labstep-web/hoc/ReadOnMount';
import { ResourceItem } from 'labstep-web/models/resource-item.model';
import React, { useEffect, useState } from 'react';
import styles from './styles.module.scss';
import { IProtocolValuePermaLinkReaderProps } from './types';

export const ProtocolValuePermaLinkReader: React.FC<
  IProtocolValuePermaLinkReaderProps
> = ({ update, toggleReader }) => {
  const [activeEntity, setActiveEntity] = useState<any>(null);
  const [error, setError] = useState(false);

  useEffect(() => {
    if (activeEntity) {
      let body = {};
      if (activeEntity instanceof ResourceItem) {
        body = {
          resource_item_id: activeEntity.id,
          resource_id: activeEntity.resource.id,
        };
      } else {
        setError(true);
        setActiveEntity(null);
        return;
      }
      update(body, {
        onSuccess: toggleReader as any,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeEntity]);

  return !activeEntity ? (
    <div className={styles.permalinkReader}>
      <PermaLinkReader
        customError={
          error &&
          ({
            reset: () => setError(false),
          } as any)
        }
      >
        {({ id, entityName, reset }) => (
          <ReadOnMountHOC
            type="entities"
            entityName={entityName}
            params={{ id, get_single: 1 }}
            children={({ entity }) => {
              setActiveEntity(entity);
              reset();
              return null;
            }}
          />
        )}
      </PermaLinkReader>
      <ActionComponent
        type="button"
        text="Done"
        elementProps={{
          fluid: true,
        }}
        onClick={toggleReader}
      />
    </div>
  ) : (
    <Placeholder />
  );
};

export default ProtocolValuePermaLinkReader;
