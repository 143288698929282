/**
 * Labstep
 */

import {
  FieldType,
  FieldWithoutActionProps,
} from 'labstep-web/core/Form/Reusable/types';
import rules from 'labstep-web/services/validation/rules';

export const fieldDescription: FieldWithoutActionProps = {
  fieldLabel: 'Description',
  name: 'description',
  fieldType: FieldType.TextArea,
  validation: rules.device_booking.description,
};
