/**
 * Labstep
 *
 * @module components/Folder/Form/CreateSub
 * @desc Folder Form Create Sub
 */

import { fieldNameMultiple } from 'labstep-web/components/Folder/Form/fields';
import { withActiveGroup } from 'labstep-web/containers/ActiveGroup';
import { EntityCreateContainer } from 'labstep-web/containers/Entity/Create';
import ReusableForm from 'labstep-web/core/Form/Reusable';
import { Folder } from 'labstep-web/models/folder.model';
import React from 'react';
import { FolderFormCreateSubProps } from './types';

export const FolderFormCreateSub: React.FC<
  FolderFormCreateSubProps
> = ({ body, options, activeGroup, ...rest }) => (
  <EntityCreateContainer batch entityName={Folder.entityName}>
    {({ create, status }): React.ReactElement => (
      <ReusableForm
        status={status}
        fields={[fieldNameMultiple]}
        onSubmit={(values): void => {
          create(
            (values as { names: string[] }).names.map(
              (name: string) => ({
                name,
                group_id: activeGroup?.id,
                ...body,
              }),
            ),
            options,
          );
        }}
        {...rest}
      />
    )}
  </EntityCreateContainer>
);

export default withActiveGroup(FolderFormCreateSub);
