import isEqual from 'lodash/isEqual';
import React, { useCallback, useContext, useState } from 'react';
import {
  IStepsOrderProviderProps,
  StepsOrderContextType,
} from './types';
import { getStepsOrder } from './utils';

export const StepsOrderContext =
  React.createContext<StepsOrderContextType>({
    stepsOrder: [],
    setStepsOrder: (): null => null,
    editable: false,
  });

export const useStepsOrderContext = (): StepsOrderContextType => {
  const context = useContext(StepsOrderContext);
  if (!context) {
    throw new Error(
      'useStepsOrder must be used within a StepsOrderContextProvider',
    );
  }
  return context;
};

export const StepsOrderContextProvider: React.FC<
  IStepsOrderProviderProps
> = ({ initialState, timestamp, children, editable = false }) => {
  const [stepsOrder, setStepsOrder] = useState<(number | string)[]>(
    initialState ? getStepsOrder(initialState, timestamp) : [],
  );

  const setStepsOrderIfChanged = useCallback(
    (state) => {
      const newStepsOrder = getStepsOrder(state, timestamp);
      // Only update if the order has changed
      // This prevents unnecessary re-renders
      if (!isEqual(stepsOrder, newStepsOrder)) {
        setStepsOrder(newStepsOrder);
      }
    },
    [stepsOrder],
  );

  const value = React.useMemo(
    () => ({
      stepsOrder,
      setStepsOrder: setStepsOrderIfChanged,
      editable,
    }),
    [stepsOrder, setStepsOrderIfChanged, editable],
  );
  return (
    <StepsOrderContext.Provider value={value} children={children} />
  );
};
