/**
 * Labstep
 *
 * @module grid/services/grid
 * @desc AG Grid business logic
 */

import {
  ColDef,
  ColumnState,
  ProcessDataFromClipboardParams,
  RowNode,
} from 'ag-grid-community';
import type { AgGridReact } from 'ag-grid-react';
import { getHeightOfRows } from 'labstep-web/core/DataGrid/utils';
import { GridIndexProps } from 'labstep-web/grid/Index/types';
import {
  EntityImportColDef,
  EntityImportDataRowData,
} from 'labstep-web/models/entity-import.model';
import { uniqBy } from 'lodash';

const SCROLL_LOAD_HEIGHT_RATIO = 0.5;

export class GridService {
  protected _agGrid: AgGridReact;

  public constructor(agGrid: AgGridReact) {
    this._agGrid = agGrid;
  }

  public get agGrid(): AgGridReact {
    return this._agGrid;
  }

  public set agGrid(agGrid: AgGridReact) {
    this._agGrid = agGrid;
  }

  /**
   * Trigger validation for all cells.
   */
  public refreshData(): void {
    const columnDefs = this.getColumnDefs();

    columnDefs.forEach((colDef: EntityImportColDef) => {
      const { colId } = colDef;
      if (colId) {
        this.refreshColumnData(colId);
      }
    });
  }

  /**
   * Trigger validation for all cells in a column.
   *
   * @param colId - The column id.
   */
  public refreshColumnData(colId: ColDef['colId']): void {
    const { api } = this.agGrid;

    if (colId) {
      api.forEachNode((rowNode: RowNode) => {
        if (rowNode.data[colId]) {
          rowNode.setDataValue(colId, rowNode.data[colId].value);
        }
      });
    }
  }

  /**
   * Change the column definition
   * @param colId Old column id.
   * @param newColId New column id.
   */
  public changeColDef(
    colId: ColDef['colId'],
    newColDef: ColDef,
  ): void {
    const newColId = newColDef.colId;
    if (!colId || !newColId) {
      return;
    }

    // replace data
    if (colId !== newColId) {
      this.agGrid.api.forEachNode((rowNode: RowNode) => {
        // eslint-disable-next-line no-param-reassign
        rowNode.data[newColId] = rowNode.data[colId];
      });
    }

    // replace columns and state
    const { colDefs, state } =
      this.getColDefsAndStateWithReplacedColumn(colId, newColDef);
    this.setColumnDefs(colDefs);
    this.agGrid.columnApi.applyColumnState({
      state,
      applyOrder: true,
    });
  }

  /**
   * Get column definitions and state with a column replaced.
   * @param colId Column id to replace.
   * @param newColDef New column definition.
   * @returns Column definitions and state.
   */
  public getColDefsAndStateWithReplacedColumn(
    colId: ColDef['colId'],
    newColDef: ColDef,
  ): {
    state: ColumnState[];
    colDefs: ColDef[];
  } {
    const newColId = newColDef.colId;
    const state = this.agGrid.columnApi.getColumnState();
    const columns = this.agGrid.columnApi.getAllColumns();
    const colDefs = columns?.map((c) => c.getColDef()) || [];
    const oldColDefIdx = colDefs.findIndex((c) => c.colId === colId);

    if (!colId || !newColId || oldColDefIdx === -1) {
      return { state, colDefs };
    }

    // Create a new array of column definitions with the new column
    const newColDefs = [
      ...colDefs.slice(0, oldColDefIdx),
      newColDef,
      ...colDefs.slice(oldColDefIdx + 1),
    ];

    // Replace the old column with the new column in the grid state
    if (colId === newColId) {
      return { state, colDefs: newColDefs };
    }
    const newState = state.map((colState) =>
      colState.colId === colId
        ? { ...colState, colId: newColId }
        : colState,
    );

    return { state: newState, colDefs: newColDefs };
  }

  public addRowData(rowData: EntityImportDataRowData): void {
    const { api } = this.agGrid;

    api.applyTransaction({
      add: rowData,
    });
  }

  public addNewRow(data = {}): void {
    const { api } = this.agGrid;

    api.applyTransaction({
      add: [data],
    });
    api.refreshHeader();
  }

  public getColumnDefs(): EntityImportColDef[] {
    const { columnApi } = this.agGrid;

    const columns = columnApi.getAllColumns();
    if (!columns) {
      return [];
    }

    return columns
      .filter((column) => column.getColDef())
      .map((column) => column.getColDef() as EntityImportColDef);
  }

  public setColumnDefs(colDefs: EntityImportColDef[]): void {
    const { api } = this.agGrid;

    api.setColumnDefs(uniqBy(Object.values(colDefs), 'colId'));

    this.refreshColumnDefs();
  }

  public getState(): ColumnState[] {
    const { columnApi } = this.agGrid;

    return columnApi.getColumnState();
  }

  public refreshColumnDefs(): void {
    const { api, columnApi } = this.agGrid;

    const state = columnApi.getColumnState();

    const columns = columnApi.getAllColumns();
    if (columns) {
      const colDefs = columns.map((column) => column.getColDef());

      api.setColumnDefs(colDefs);

      if (state) {
        columnApi.applyColumnState({
          state,
          applyOrder: true,
        });
      }
    }
  }

  /**
   * Remove a column from the grid.
   * @param colId - The column id.
   */
  public removeColumn(colId: ColDef['colId']): void {
    if (!colId) {
      return;
    }
    const { api, columnApi } = this.agGrid;

    // remove data from rows
    api.forEachNode((rowNode: RowNode) => {
      if (rowNode.data[colId]) {
        const { [colId]: _, ...rest } = rowNode.data;
        rowNode.setData(rest);
      }
    });

    const columns = columnApi.getAllColumns();
    if (columns) {
      const colDefs = columns
        .filter((column) => column.getColId() !== colId)
        .map((column) => column.getColDef());

      api.setColumnDefs(colDefs);
    }
  }

  /**
   * Get all unique values in a column.
   * @param colId - The column id.
   * @returns An array of unique values.
   */
  public getUniqueValues(colId: ColDef['colId']): string[] {
    if (!colId) {
      return [];
    }
    const { api } = this.agGrid;

    const values: string[] = [];

    api.forEachNode((rowNode: RowNode) => {
      if (rowNode.data[colId]) {
        values.push(rowNode.data[colId].value);
      }
    });

    const uniqueValues = Array.from(new Set(values));

    return uniqueValues;
  }

  public sortColumn(
    colId: ColDef['colId'],
    sort: 'desc' | 'asc' | null | undefined,
  ) {
    const { columnApi } = this.agGrid;

    if (columnApi && colId) {
      columnApi.applyColumnState({
        state: [{ colId, sort }],
        defaultState: { sort: null },
      });
    }
  }

  public setColumnHide(colId: ColDef['colId'], hide: boolean) {
    const { columnApi } = this.agGrid;

    if (columnApi && colId) {
      columnApi.applyColumnState({
        state: [{ colId, hide }],
      });
    }
  }

  /**
   * Adds new rows on paste.
   * @param params - The parameters for processing clipboard data.
   * @returns An array of pasted data as a two-dimensional string array.
   */
  public static addNewRowsOnPaste(
    params: ProcessDataFromClipboardParams,
  ): string[][] {
    const focusedCell = params.api!.getFocusedCell();
    const focusedRowIndex = focusedCell!.rowIndex;
    const rowsToAdd =
      params.data.length -
      (params.api!.getDisplayedRowCount() - focusedRowIndex);

    if (rowsToAdd > 0) {
      const newRows = Array.from(
        {
          length: rowsToAdd,
        },
        () => ({}),
      );
      params.api?.applyTransaction({
        add: newRows,
        addIndex: focusedRowIndex,
      });
      params.api!.redrawRows();
    }

    return params.data;
  }

  /**
   * Call load more fn when reaching end of grid
   * @param loadMore Load more fn
   */
  static loadMoreOnScroll =
    (loadMore: NonNullable<GridIndexProps['loadMore']>) =>
    (
      params: Parameters<
        NonNullable<GridIndexProps['onGridReady']>
      >[0],
    ): void => {
      const verticalPixelRange = params.api.getVerticalPixelRange();
      if (!verticalPixelRange) {
        return;
      }
      const { bottom } = verticalPixelRange;
      const heightOfRows = getHeightOfRows(params);
      if (heightOfRows <= bottom / SCROLL_LOAD_HEIGHT_RATIO) {
        loadMore({
          onSuccess: () =>
            GridService.loadMoreOnScroll(loadMore)(params),
        });
      }
    };
}
