/**
 * Labstep
 *
 * @module components/Group/Action/Menu
 * @desc Action Menu for group
 */

import DeleteGroupAction from 'labstep-web/components/Group/Action/Delete';
import ActionMenu from 'labstep-web/core/Action/Menu';
import React from 'react';
import { GroupActionManageMembers } from '../ManageMembers';
import { GroupActionMenuProps } from './types';

const GroupActionMenu: React.FC<GroupActionMenuProps> = ({
  group,
  actions,
}) => (
  <ActionMenu type="icon">
    {actions.includes('manage_members') && (
      <GroupActionManageMembers group={group} />
    )}
    {actions.includes('delete') && (
      <DeleteGroupAction group={group} />
    )}
  </ActionMenu>
);

GroupActionMenu.defaultProps = {
  actions: [],
};

export default GroupActionMenu;
