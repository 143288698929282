/**
 * Labstep
 *
 * @module components/Group/Action/AddUser
 * @desc Action to add user to group (from a list of the organization's users)
 */

import { useHasAccessGroup } from 'labstep-web/components/Entity/Can';
import { withAuthenticatedUserEntity } from 'labstep-web/containers/AuthenticatedUser';
import { EntityCreateContainer } from 'labstep-web/containers/Entity/Create';
import FloatingSearchSelect from 'labstep-web/core/Select/FloatingSearch';
import { Group } from 'labstep-web/models/group.model';
import { UserGroup } from 'labstep-web/models/user-group.model';
import { User } from 'labstep-web/models/user.model';
import React from 'react';
import { GroupActionAddUserProps } from './types';

export const GroupActionAddUser: React.FC<
  GroupActionAddUserProps
> = ({ group, authenticatedUserEntity }) => {
  return useHasAccessGroup('owner', group.id) ||
    authenticatedUserEntity.isOrganizationAdmin ? (
    <EntityCreateContainer
      entityName={UserGroup.entityName}
      parentName={Group.entityName}
      parentId={group.id}
    >
      {({ create, status }) => (
        <FloatingSearchSelect
          entityName={User.entityName}
          params={{
            count: 5,
            organization_id: group.organization.id,
            group_not_id: group.id,
          }}
          placeholder="Search organization users..."
          onChange={(option) => {
            create({
              user_id: option.id,
              group_id: group.id,
            });
          }}
          isClearable={false}
          isOptionDisabled={() => status && status.isFetching}
          actionComponentProps={{
            type: 'button',
            text: 'Add Members',
          }}
          alignRight
          keepMenuOpen
          removeSelectedOption
        />
      )}
    </EntityCreateContainer>
  ) : null;
};

export default withAuthenticatedUserEntity(GroupActionAddUser);
