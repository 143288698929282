/**
 * Labstep
 *
 * @module components/Sequence/Action/Update
 */

import SequenceActionEdit from 'labstep-web/components/Sequence/Action/Edit';
import { ActionComponentProps } from 'labstep-web/core/Action/Component/types';
import SequenceEditor from 'labstep-web/core/SequenceEditor';
import React from 'react';
import styles from './styles.module.scss';
import { ISequencePreviewProps } from './types';

const actionComponentProps = {
  type: 'icon',
  icon: 'external',
  elementProps: { color: 'blue' },
  text: '',
} as ActionComponentProps;

const SequencePreview: React.FC<ISequencePreviewProps> = ({
  sequence,
  expandIconComponent,
}) => (
  <div className={styles.container}>
    <div className={styles.fullscreenAction}>
      {expandIconComponent ? (
        expandIconComponent({ actionComponentProps })
      ) : (
        <SequenceActionEdit
          sequence={sequence}
          actionComponentProps={actionComponentProps}
        />
      )}
    </div>
    <SequenceEditor
      withPreviewMode
      sequenceData={sequence.sequenceData}
      readOnly
    />
  </div>
);

export default SequencePreview;
