/**
 * Labstep
 *
 * @module core/Status
 * @desc A component for displaying a status in a workflow
 */

import classNames from 'classnames';
import Flex from 'labstep-web/core/Flex';
import Icon from 'labstep-web/core/Icon';
import React from 'react';
import { SemanticICONS } from 'semantic-ui-react';
import styles from './styles.module.scss';
import { APICallStatusProps, StatusPosition } from './types';

const Status: React.FC<APICallStatusProps> = ({
  icon,
  name,
  color,
  position = StatusPosition.middle,
  small = false,
  nextColor,
  activeIndexes,
  index,
  changeIndex,
  onClick,
}: APICallStatusProps) => {
  const isActive = () => {
    return activeIndexes.includes(index);
  };

  const sectionClasses = classNames({
    [styles.status]: true,
    [styles.startSection]: position === 'start',
    [styles.endSection]: position === 'end',
    [styles.deactivatedSection]: !isActive(),
    [styles.smallSection]: small && !isActive(),
  });

  const onMouseEnter = () => {
    changeIndex([activeIndexes[0], index]);
  };

  const onMouseLeave = () => {
    changeIndex([activeIndexes[0]]);
  };

  let pathStyle = {};
  if (isActive()) {
    pathStyle = { stroke: color, fill: color };
  } else if (activeIndexes.includes(index + 1)) {
    pathStyle = { stroke: nextColor };
  }

  return (
    <Flex
      data-testid={isActive() ? 'status-active' : 'status'}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onClick={onClick}
    >
      <Flex
        className={sectionClasses}
        vAlignContent="center"
        hAlignContent="center"
        style={isActive() ? { backgroundColor: color } : {}}
      >
        <Flex hAlignContent="center" className={styles.statusContent}>
          <Icon name={icon as SemanticICONS} size="large" />
          {!small || isActive() || index - 1 === activeIndexes[0] ? (
            <span>{name}</span>
          ) : null}
        </Flex>
      </Flex>
      {position !== 'end' ? (
        <Flex className={styles.dividerComponent}>
          <svg
            className={styles.chevron}
            xmlns="http://www.w3.org/2000/svg"
            width="5"
            height="28"
            viewBox="0 0 5 28"
            style={
              activeIndexes.includes(index + 1)
                ? { backgroundColor: nextColor }
                : {}
            }
          >
            <path
              d="M0 28V0L4.5 14L0 28Z"
              style={pathStyle}
              className={styles.path}
              data-testid="path"
            />
          </svg>
        </Flex>
      ) : null}
    </Flex>
  );
};

export default Status;
