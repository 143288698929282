/**
 * Labstep
 *
 * @module components/ProtocolTimer/Item
 * @desc ProtocolTimer
 */

import ProtocolTimerActionEdit from 'labstep-web/components/ProtocolTimer/Action/Edit';
import ProtocolTimerCard from 'labstep-web/components/ProtocolTimer/Card';
import ProtocolTimerModal from 'labstep-web/components/ProtocolTimer/Modal';
import { ToggleModalType } from 'labstep-web/hoc/Modal/types';
import React from 'react';
import { IProtocolTimerItemProps } from './types';

const ProtocolTimerItem: React.FC<IProtocolTimerItemProps> = ({
  protocolTimer,
  protocol,
}) => {
  const childrenProps = {
    protocolTimer,
    viewComponent: ({
      toggleModal,
    }: {
      // eslint-disable-next-line react/require-default-props
      toggleModal?: ToggleModalType;
    }) => (
      <ProtocolTimerCard
        protocolTimer={protocolTimer}
        onClick={toggleModal}
      />
    ),
  };

  return protocolTimer.is_experiment_child ? (
    <ProtocolTimerModal
      {...childrenProps}
      protocolTimer={protocolTimer}
      protocol={protocol}
    />
  ) : (
    <ProtocolTimerActionEdit {...childrenProps} />
  );
};

export default ProtocolTimerItem;
