/**
 * Labstep
 */

import {
  FieldType,
  FieldWithoutActionProps,
} from 'labstep-web/core/Form/Reusable/types';
import rules from 'labstep-web/services/validation/rules';

export const fieldEntityName: FieldWithoutActionProps = {
  fieldType: FieldType.Text,
  name: 'name',
  validation: rules.entity.name,
  placeholder: 'Enter a name',
  header: true,
};
