/**
 * Labstep
 *
 * @module grid/SmartTableMetadataVariable
 * @desc Metadata Variable Grid
 */

import ProtocolConditionActionCreateBulk from 'labstep-web/components/ProtocolCondition/Action/Create/Bulk';
import GridSmartTable from 'labstep-web/grid/SmartTable';
import { getColumnDefs } from 'labstep-web/grid/SmartTableMetadataVariable/coldefs';
import React from 'react';
import { GridSmartTableMetadataVariableProps } from './types';

export const GridSmartTableMetadataVariable: React.FC<
  GridSmartTableMetadataVariableProps
> = ({ protocol, variableTemplate }) => (
  <GridSmartTable
    isCursor
    columnDefs={getColumnDefs(protocol, variableTemplate)}
    protocol={protocol}
    resetColumnsOn={[variableTemplate.displayName]}
    statusBarChildren={
      <ProtocolConditionActionCreateBulk
        key="create-bulk"
        protocol={protocol}
        actionComponentProps={{ type: 'text', icon: 'plus' }}
      />
    }
    emptyState={undefined}
  />
);

export default GridSmartTableMetadataVariable;
