/**
 * Labstep
 *
 * @module screens/Organization/Create
 * @desc A screen to create an organization
 */

import AppRoutes from 'labstep-web/components/App/Routes';
import LayoutFullScreenPage from 'labstep-web/components/Layout/FullScreenPage';
import OrganizationFormCreate from 'labstep-web/components/Organization/Form/Create';
import { OrganizationLogoDefault } from 'labstep-web/components/Organization/Logo';
import Header from 'labstep-web/core/Header';
import Route from 'labstep-web/core/Route';
import { navigation } from 'labstep-web/services/navigation';
import React from 'react';
import { withRouter } from 'react-router';
import {
  Route as ReactRouterDomRoute,
  Switch,
} from 'react-router-dom';
import styles from './styles.module.scss';
import {
  IScreensOrganizationCreateGuardProps,
  IScreensOrganizationCreateProps,
} from './types';

export const ScreensOrganizationCreate: React.FC<
  IScreensOrganizationCreateProps
> = ({ history, read }) => (
  <LayoutFullScreenPage>
    <div className={styles.centered}>
      <OrganizationLogoDefault />
      <br />
      <Header size="large">Welcome!</Header>
      <Header size="small">First set up your organization</Header>
      <p>
        To join an existing organization, request an invite link from
        the Admin.
      </p>
    </div>
    <OrganizationFormCreate
      options={{
        onSuccess: ({ response }) =>
          read({
            onSuccess: () =>
              history.push(
                navigation.get('organization_show', {
                  identifier: response!.result,
                }),
              ),
          }),
      }}
    />
  </LayoutFullScreenPage>
);

export const ScreensOrganizationCreateWithRouter = withRouter(
  ScreensOrganizationCreate,
);

export const routes = ['verify_email'] as const;

const ScreensOrganizationCreateGuard: React.FC<
  IScreensOrganizationCreateGuardProps
> = ({ read }) => (
  <Switch>
    {routes.map((route) => (
      <Route
        key={route}
        exact
        path={navigation.get(route)}
        component={AppRoutes}
      />
    ))}
    <ReactRouterDomRoute
      render={() => (
        <ScreensOrganizationCreateWithRouter read={read} />
      )}
    />
  </Switch>
);

export default ScreensOrganizationCreateGuard;
