/**
 * Labstep
 *
 * @module components/OrderRequest/Action/Create
 * @desc Create Action for order request workflow
 */

import React from 'react';
import OrderRequestActionCreateWizardTrigger from './Wizard/Trigger';
import { OrderRequestActionCreateProps } from './types';

export const OrderRequestActionCreate: React.FC<
  OrderRequestActionCreateProps
> = ({ resource, purchaseOrder, actionComponentProps }) => {
  return (
    <OrderRequestActionCreateWizardTrigger
      actionComponentProps={actionComponentProps}
      resource={resource}
      purchaseOrder={purchaseOrder}
    />
  );
};

export default OrderRequestActionCreate;
