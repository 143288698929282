/**
 * Labstep
 */

import { IModalConfirmationContainerProps } from 'labstep-web/core/Modal/Confirmation/types';
import { ProtocolValue } from 'labstep-web/models/protocol-value.model';
import {
  canUpdateAmount,
  checkUnitBase,
} from 'labstep-web/services/amount-unit.service';

export const getHeaderAndMessage = (
  protocolValue: ProtocolValue,
): {
  header: IModalConfirmationContainerProps['header'];
  message: IModalConfirmationContainerProps['message'];
} => {
  let header = '';
  let message = '';

  if (
    protocolValue.resourceItem?.amount === null ||
    protocolValue.amount === null
  ) {
    header = 'Some values are missing';
    message =
      'Please ensure you set both the current Amount Remaining of the item and the Amount Used to enable auto-deduction';
  } else if (
    !checkUnitBase(
      protocolValue.unit || '',
      protocolValue.resourceItem?.unit || '',
    )
  ) {
    header = 'Units mismatch';
    message =
      'The units for amount used are not the same as the units for this resource item. To deduct the amount please ensure the units match.';
  } else if (!canUpdateAmount(protocolValue)) {
    header = 'Insufficient Amount Remaining';
    message =
      'The amount remaining of the selected item is less than the amount used. Please select a different item or adjust the amounts.';
  }

  return { header, message };
};
