/**
 * Labstep
 */

import { WithAuthenticatedUserEntityInjectedProps } from 'labstep-web/containers/AuthenticatedUser/types';
import { EntityUserRole } from 'labstep-web/models/entity-user-role.model';
import { ExperimentWorkflow } from 'labstep-web/models/experiment-workflow.model';
import { Signature } from 'labstep-web/models/signature.model';

export interface ISignatureCardProps
  extends WithAuthenticatedUserEntityInjectedProps {
  signature: Signature;
  entityUserRole: EntityUserRole;
  experimentWorkflow: ExperimentWorkflow;
}

// eslint-disable-next-line no-shadow
export enum SignatureStatus {
  rejected = 'Rejected',
  signed = 'Signed',
  revoked = 'Revoked',
}
