/**
 * Labstep
 *
 * @module core/Form/ShowEdit/Text/Multiple
 * @desc A component that displays any number of ShowEditText components.
 * Just like the ShowEditText component, any field will toggle onBlur and perform
 * an optimistic update. If any of the fields are left empty, no api
 * requests are sent thereby leaving the last saved value to be restored.
 */

import React from 'react';
import ShowEditText from '..';
import styles from './styles.module.scss';
import { IFormShowEditTextMultipleProps } from './types';

const FormShowEditTextMultiple: React.FC<
  IFormShowEditTextMultipleProps
> = ({
  entity,
  entityName,
  fields,
  customChildren,
  id,
  createProps,
}) => {
  return (
    <div className={styles.container}>
      {fields.map((field, idx) => {
        return (
          <ShowEditText
            id={id}
            // eslint-disable-next-line react/no-array-index-key
            key={idx}
            entity={entity}
            entityName={entityName}
            field={field}
            createProps={createProps}
          >
            {customChildren ? (
              customChildren(entity[field.name])
            ) : (
              <div>{entity[field.name]}</div>
            )}
          </ShowEditText>
        );
      })}
    </div>
  );
};

export default FormShowEditTextMultiple;
