/**
 * Labstep
 */

import React from 'react';
import { ModalWizardContext } from '.';

export const useModalWizardContext = () => {
  const context = React.useContext(ModalWizardContext);
  return context;
};
