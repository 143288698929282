/**
 * Labstep
 *
 * @module components/AccessKey/Form/Create
 * @desc Form to create AccessKey
 */

import {
  accessKeyFieldExpiresAt,
  accessKeyFieldName,
} from 'labstep-web/components/AccessKey/Form/fields';
import FormCreate from 'labstep-web/core/Form/Create';
import { AccessKey } from 'labstep-web/models/access-key.model';
import React from 'react';
import { AccessKeyFormCreateProps } from './types';

const AccessKeyFormCreate: React.FC<AccessKeyFormCreateProps> = ({
  options,
}) => (
  <FormCreate
    entityName={AccessKey.entityName}
    fields={[accessKeyFieldName, accessKeyFieldExpiresAt]}
    options={options}
    autoFocus
  />
);

export default AccessKeyFormCreate;
