/**
 * Labstep
 */

import type { Column, ColumnState } from 'ag-grid-community';
import type { AgGridReact } from 'ag-grid-react';
import React, { createContext, useRef } from 'react';
import { DataGridContextType } from './types';

export const DataGridContext = createContext<DataGridContextType>({
  agGrid: undefined,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setColumnState: () => {
    // Do nothing
  },
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setColumns: () => {
    // Do nothing
  },
  columns: [],
  columnState: [],
  ref: { current: undefined },
});

export const DataGridContextProvider: React.FC = ({ children }) => {
  const ref = useRef<AgGridReact | undefined>();
  const [columns, setColumns] = React.useState<Column[] | null>([]);
  const [columnState, setColumnState] = React.useState<ColumnState[]>(
    [],
  );
  const value = React.useMemo(
    () => ({
      ref,
      agGrid: ref?.current,
      columns,
      setColumns,
      columnState,
      setColumnState,
    }),
    [ref, columns, setColumns, columnState, setColumnState],
  );
  return (
    <DataGridContext.Provider value={value} children={children} />
  );
};
