/**
 * Labstep
 *
 * @module components/Entity/List/Toggle
 * @desc Entity List Toggle
 */

import classnames from 'classnames';
import EntitySearchToggleAction from 'labstep-web/components/Entity/Action/SearchToggle';
import { useHasAccess } from 'labstep-web/components/Entity/Can';
import { Action } from 'labstep-web/components/Entity/Can/types';
import { ToggleContainer } from 'labstep-web/containers/Toggle';
import ActionComponent from 'labstep-web/core/Action/Component';
import Icon from 'labstep-web/core/Icon';
import { ReadOnMountHOC } from 'labstep-web/hoc/ReadOnMount';
import { getHumanReadableEntityName } from 'labstep-web/services/i18n.service';
import React from 'react';
import styles from './styles.module.scss';
import {
  IEntityListToggleProps,
  ToggleAddContainerProps,
  ToggleAddProps,
} from './types';

export const ToggleAdd: React.FC<ToggleAddProps> = ({
  toggle,
  toggled,
  text,
  select,
  entities,
  entityName,
  childKeyName,
  list,
  actionMessageIcon,
  additionalActions,
  editPermission,
  prepend,
}) => (
  <div
    className={classnames(styles.toggleContainer, {
      active: entities.length > 0,
    })}
  >
    {actionMessageIcon && (
      <div>
        <Icon name={actionMessageIcon} />
      </div>
    )}
    {prepend}
    <div className={styles.listContainer}>
      {list && entities.length !== 0
        ? list({ entities })
        : list &&
          !editPermission && (
            <div>{`No ${getHumanReadableEntityName(
              entityName,
              true,
            )}`}</div>
          )}
    </div>
    {editPermission && (
      <div className={styles.actionContainer}>
        {additionalActions}
        {!toggled ? (
          <ActionComponent
            dataCy="components/Entity/List/Toggle"
            text={
              text ||
              `Add ${getHumanReadableEntityName(
                childKeyName || entityName,
              )}(s)`
            }
            onClick={toggle}
            {...(entities.length
              ? {
                  type: 'icon',
                  icon: 'plus',
                  elementProps: {
                    popup: { content: 'Add another' },
                  },
                }
              : {
                  type: 'text',
                  elementProps: {
                    placeholder: true,
                  },
                })}
          />
        ) : (
          select({ toggle })
        )}
      </div>
    )}
  </div>
);

export const ToggleAddContainer: React.FC<
  ToggleAddContainerProps
> = ({ initialEditingState, ...rest }) => (
  <div className="toggle-add">
    <ToggleContainer initialEditingState={initialEditingState}>
      {({ toggle, toggled }) => (
        <ToggleAdd toggle={toggle} toggled={toggled} {...rest} />
      )}
    </ToggleContainer>
  </div>
);

const EntityListToggle: React.FC<IEntityListToggleProps> = ({
  parent,
  extraParams = {},
  entityName,
  initialEditingState,
  text,
  list,
  actionMessageIcon,
  additionalActions,
  extraSearchParams = {},
  ...rest
}) => {
  const editPermission = useHasAccess(
    parent.entityName,
    parent.idAttr,
    `${entityName}:${Action.add_remove}` as Action,
  );

  return (
    <ReadOnMountHOC
      type="cursor"
      loading={{ loader: false }}
      entityName={entityName}
      params={{
        [`${parent.entityName}_id`]: parent.idAttr,
        count: 20,
        ...extraParams,
      }}
      children={({ entities }) => (
        <ToggleAddContainer
          initialEditingState={initialEditingState}
          entities={entities}
          entityName={entityName}
          text={text}
          list={list}
          actionMessageIcon={actionMessageIcon}
          additionalActions={additionalActions}
          editPermission={editPermission}
          select={({ toggle }) => (
            <EntitySearchToggleAction
              onChange={toggle}
              onBlur={toggle}
              parent={parent}
              entityName={entityName}
              extraParams={{ ...extraParams, ...extraSearchParams }}
              {...rest}
            />
          )}
        />
      )}
    />
  );
};

export default EntityListToggle;
