/**
 * Labstep
 *
 * @module screens/ResourceLocation/Index
 * @desc ResourceLocation manager page
 */

import EntitySearch from 'labstep-web/components/Entity/Search';
import ResourceLocationActionCreate from 'labstep-web/components/ResourceLocation/Action/Create';
import { Action } from 'labstep-web/components/ResourceLocation/Action/Menu/types';
import ResourceLocationEmptyState from 'labstep-web/components/ResourceLocation/Card/EmptyState';
import ResourceLocationTable from 'labstep-web/components/ResourceLocation/Table';
import { ResourceLocation } from 'labstep-web/models/resource-location.model';
import React, { useMemo } from 'react';
import { filters } from './constants';
import { IScreensResourceLocationIndexProps } from './types';

const ScreensResourceLocationIndex: React.FC<
  IScreensResourceLocationIndexProps
> = ({ group }) => {
  const params = useMemo(() => ({ group_id: group.id }), [group.id]);
  const action = useMemo(() => <ResourceLocationActionCreate />, []);
  const noResultsMessage = useMemo(
    () => <ResourceLocationEmptyState />,
    [],
  );
  return (
    <EntitySearch
      params={params}
      entityName={ResourceLocation.entityName}
      action={action}
      noResultsMessage={noResultsMessage}
      filters={filters}
      tableFormat
    >
      {({ entities, ...rest }): React.ReactElement => (
        <ResourceLocationTable
          actions={[
            Action.delete,
            Action.update_outer,
            Action.assign,
          ]}
          resourceLocations={entities}
          showCollaborators
          {...rest}
        />
      )}
    </EntitySearch>
  );
};

export default ScreensResourceLocationIndex;
