/**
 * Labstep
 *
 * @module components/File/UploadToast
 * @desc Display file upload progress
 */

import { FileProgressContainer } from 'labstep-web/containers/File/Progress';
import Modal from 'labstep-web/core/Modal/Default';
import Progress from 'labstep-web/core/Progress';
import React from 'react';
import { FileUploadToastProps } from './types';

export const FileUploadToast: React.FC<FileUploadToastProps> = ({
  files,
}) => (
  <Modal
    viewComponent={null}
    id="file-upload-modal"
    header="Uploading..."
    closeIcon={false}
    open={files.length > 0}
    content={() => (
      <div>
        {files.map((file) => (
          <div key={file.id}>
            <div>{(file as any).body.name}</div>
            <Progress
              color="blue"
              percent={(file as any).progress}
              progress
            />
          </div>
        ))}
      </div>
    )}
  />
);

export const FileUploadToastContainer: React.FC = () => (
  <FileProgressContainer>
    {({ files }) => <FileUploadToast files={files} />}
  </FileProgressContainer>
);

export default FileUploadToastContainer;
