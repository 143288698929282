/**
 * Labstep
 *
 * @module components/Filter/Metadata/Active/Post
 * @desc Active node component for metadata in postFilter
 */

import FilterMetadata from 'labstep-web/components/Filter/Metadata';
import { getComparisonLabelDict } from 'labstep-web/components/Filter/Metadata/Form/fields';
import { FilterMetadataType } from 'labstep-web/components/Filter/Metadata/Form/types';
import { FilterPostActiveLabelProps } from 'labstep-web/components/Filter/Post/Active/Label/types';
import { ICONS } from 'labstep-web/constants/icons';
import { useParamsContext } from 'labstep-web/contexts/params/hook';
import RemoveLabel from 'labstep-web/core/Label/Remove';
import {
  MetadataType,
  MetadataTypeValues,
} from 'labstep-web/models/metadata/types';
import { ResourceItem } from 'labstep-web/models/resource-item.model';
import { Resource } from 'labstep-web/models/resource.model';
import {
  getPostFilterMetadatasDate,
  getPostFilterMetadatasDefault,
  getPostFilterMetadatasNumeric,
  getPostFilterMetadatasOptions,
} from 'labstep-web/services/postFilter/filters/metadatas';
import { PostFilterComparison } from 'labstep-web/services/query-parameter.service';
import React from 'react';

const PATHS = ['metadatas', 'resource.metadatas'];

const FILTERS = PATHS.flatMap((path) => [
  getPostFilterMetadatasDate(path),
  getPostFilterMetadatasDefault(path),
  getPostFilterMetadatasNumeric(path),
  getPostFilterMetadatasOptions(path),
]);

const FilterMetadataActivePost: React.FC<
  FilterPostActiveLabelProps
> = ({ node, onRemoveClick, index }) => {
  const { addPostFilter } = useParamsContext();
  const filterActive = FILTERS.find((filter) =>
    filter.isNodeActive(node),
  );
  if (!filterActive) {
    return null;
  }
  const attributes = filterActive.getAttributeValues(node);

  let type: FilterMetadataType;
  let comparison: PostFilterComparison;
  let value: string;
  if (attributes.options) {
    type = MetadataType.options;
    comparison = attributes.options.comparison;
    value = attributes.options.value;
  } else if (attributes.date) {
    type = MetadataType.date;
    comparison = attributes.date.comparison;
    value = attributes.date.value;
  } else if (attributes.number) {
    type = MetadataType.numeric;
    comparison = attributes.number.comparison;
    value = attributes.number.value;
  } else {
    type = MetadataType.default;
    comparison = attributes.value.comparison;
    value = attributes.value.value;
  }

  const defaultValues = {
    label: attributes.label.value,
    type: {
      value: type,
      label:
        MetadataTypeValues[type as keyof typeof MetadataTypeValues],
    },
    comparison: {
      value: comparison,
      label: getComparisonLabelDict(type)[comparison],
    },
    value,
  };

  return (
    <FilterMetadata
      entityName={ResourceItem.entityName}
      childEntityName={
        filterActive.getPath()?.startsWith('resource.')
          ? Resource.entityName
          : undefined
      }
      replace={index}
      addPostFilter={addPostFilter}
      defaultValues={defaultValues}
      viewComponent={({ toggleModal }) => (
        <RemoveLabel
          icon={ICONS.metadata.secondary}
          name={filterActive.getHumanReadableString(node)}
          onNameClick={toggleModal}
          onRemoveClick={onRemoveClick}
        />
      )}
      addAnother={false}
    />
  );
};

export default FilterMetadataActivePost;
