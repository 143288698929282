/**
 * Labstep
 */

import {
  IStateDispatchProps,
  ProseMirrorCommand,
} from 'labstep-web/prosemirror/marks/types';
import {
  createTable as createTableNode,
  safeInsert,
} from 'prosemirror-utils';

export const createTable: ProseMirrorCommand = (state, dispatch) => {
  const table = createTableNode(state.schema, 3, 3, false);

  if (dispatch) {
    dispatch(safeInsert(table)(state.tr).scrollIntoView());
  }
  return true;
};

export const replaceWithTable = (
  state: IStateDispatchProps['state'],
  dispatch: IStateDispatchProps['dispatch'],
  from: number,
  to: number,
  rowsCount: number,
  columnsCount: number,
) => {
  const { tr, schema } = state;
  const table = createTableNode(
    schema,
    rowsCount,
    columnsCount,
    false,
  );
  tr.replaceRangeWith(from, to, table);
  if (dispatch) {
    dispatch(tr);
  }
  return true;
};
