/**
 * Labstep
 *
 * @module components/OrderRequest/Action/UpdateStatus
 * @desc Order Request status + option to change +
 * show ResourceItemActionImport modal when changing status to "Partial Received" or "Received"
 * with remaining items to add to the order.
 */

import {
  useHasAccess,
  useHasAccessCreate,
} from 'labstep-web/components/Entity/Can';
import { Action } from 'labstep-web/components/Entity/Can/types';
import ResourceItemActionImport from 'labstep-web/components/ResourceItem/Action/Import';
import { EntityUpdateContainer } from 'labstep-web/containers/Entity/Update';
import ActionComponent from 'labstep-web/core/Action/Component';
import Dropdown from 'labstep-web/core/Dropdown';
import { useToggle } from 'labstep-web/hooks/toggle';
import {
  OrderRequest,
  OrderRequestStatusValues,
} from 'labstep-web/models/order-request.model';
import { ResourceItem } from 'labstep-web/models/resource-item.model';
import React from 'react';
import OrderRequestStatus from '../../Status';
import {
  OrderRequestActionUpdateStatusContainerProps,
  OrderRequestActionUpdateStatusProps,
} from './types';
import {
  isDifferentStatusClicked,
  isReceivedOrPartiallyReceived,
  shouldShowConfirmationModal,
} from './utils';

export const OrderRequestActionUpdateStatus: React.FC<
  OrderRequestActionUpdateStatusProps
> = ({ trigger, disabled, ...rest }) => {
  const [importModal, toggleImportModal] = useToggle(false);
  const canCreateResourceItems = useHasAccessCreate(
    'resource_item',
    'orderRequest' in rest ? rest.orderRequest.entityName : undefined,
    'orderRequest' in rest ? rest.orderRequest.id : undefined,
  );

  return (
    <>
      {'orderRequest' in rest && importModal && (
        <ResourceItemActionImport
          resource={rest.orderRequest.resource}
          orderRequest={rest.orderRequest}
          defaultOpen
          showViewComponent={false}
          onReset={toggleImportModal}
        />
      )}
      <EntityUpdateContainer
        entityName={OrderRequest.entityName}
        id={
          'orderRequestIds' in rest
            ? rest.orderRequestIds
            : rest.orderRequest.id
        }
      >
        {({ status, update }) => (
          <Dropdown.Dropdown
            disabled={disabled}
            trigger={trigger}
            icon={null}
          >
            <Dropdown.Menu>
              {(
                Object.keys(
                  OrderRequestStatusValues,
                ) as (keyof typeof OrderRequestStatusValues)[]
              ).map((key) => {
                const modalConfirmationProps =
                  'orderRequest' in rest &&
                  canCreateResourceItems &&
                  shouldShowConfirmationModal(rest.orderRequest, key)
                    ? {
                        confirmButtonContent: 'Add now',
                        cancelButtonContent: 'Add later',
                        positive: true,
                        header: `Add ${ResourceItem.getHumanReadableEntityName(
                          true,
                          true,
                        )}`,
                        message: (
                          <div>
                            <p>
                              You have{' '}
                              {rest.orderRequest.remainingItemsCount}{' '}
                              items left to add to the inventory.
                            </p>
                            <p>
                              Do you want to add these received items
                              to the inventory?
                            </p>
                          </div>
                        ),
                        onCancel: () => {
                          update({ status: key });
                        },
                      }
                    : undefined;
                return (
                  <ActionComponent
                    key={key}
                    type="option"
                    status={status}
                    text={OrderRequestStatusValues[key]}
                    onClick={(options = {}) => {
                      if ('orderRequest' in rest) {
                        if (
                          isReceivedOrPartiallyReceived(key) &&
                          isDifferentStatusClicked(
                            rest.orderRequest,
                            key,
                          ) &&
                          rest.orderRequest.resource_item_count <
                            rest.orderRequest.quantity
                        ) {
                          toggleImportModal();
                        }
                      }
                      update({ status: key }, options);
                    }}
                    modalConfirmationProps={modalConfirmationProps}
                  />
                );
              })}
            </Dropdown.Menu>
          </Dropdown.Dropdown>
        )}
      </EntityUpdateContainer>
    </>
  );
};

export const OrderRequestActionUpdateStatusContainer: React.FC<
  OrderRequestActionUpdateStatusContainerProps
> = ({ orderRequest, ...rest }) => {
  const canEdit = useHasAccess(
    orderRequest.entityName,
    orderRequest.id,
    Action.edit,
  );
  const trigger = (
    <OrderRequestStatus
      status={orderRequest.status}
      date={
        orderRequest.lastDateAndUser
          ? orderRequest.lastDateAndUser.date
          : undefined
      }
      user={
        orderRequest.lastDateAndUser
          ? orderRequest.lastDateAndUser.user
          : undefined
      }
      canEdit={canEdit}
      {...rest}
    />
  );
  return canEdit ? (
    <OrderRequestActionUpdateStatus
      orderRequest={orderRequest}
      trigger={trigger}
      {...rest}
    />
  ) : (
    <>{trigger}</>
  );
};

export default OrderRequestActionUpdateStatusContainer;
