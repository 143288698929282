/**
 * Labstep
 *
 * @module components/UserGroup/Action/Update
 * @desc Updates group user entry
 */

import PermissionRoleActionUpdateDropdownPill from 'labstep-web/components/PermissionRole/Action/UpdateDropdownPill';
import {
  PERMISSION_ROLE_EDITOR,
  PERMISSION_ROLE_OWNER,
  PERMISSION_ROLE_VIEWER,
} from 'labstep-web/constants/roles';
import { PermissionType } from 'labstep-web/models/permission.model';
import React from 'react';
import {
  APIResponseBodyProps,
  IUserGroupActionUpdateProps,
} from './types';

export const UserGroupActionUpdate: React.FC<
  IUserGroupActionUpdateProps
> = ({ userGroup }) => (
  <PermissionRoleActionUpdateDropdownPill
    entity={userGroup}
    body={(permissionRole) => {
      const body: APIResponseBodyProps = {
        type: PermissionType.edit,
      };
      if (permissionRole.name === PERMISSION_ROLE_OWNER.name) {
        body.type = PermissionType.owner;
        body.permission_role_guid = null;
      } else if (
        permissionRole.name === PERMISSION_ROLE_VIEWER.name
      ) {
        body.type = PermissionType.view;
        body.permission_role_guid = null;
      } else if (
        permissionRole.name === PERMISSION_ROLE_EDITOR.name
      ) {
        body.permission_role_guid = null;
      } else if (
        permissionRole.name !== PERMISSION_ROLE_EDITOR.name
      ) {
        body.permission_role_guid = permissionRole.guid;
      }
      return body;
    }}
    currentValue={userGroup.permissionRoleName}
  />
);

export default UserGroupActionUpdate;
