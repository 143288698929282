/**
 * Labstep
 *
 * @module services/report
 * @desc Helper methods for Report/EntityView/ProtocolCondition
 */

import { EntityViewParameters } from 'labstep-web/models/entity-view.model';
import { cloneDeep } from 'lodash';
import {
  PostFilterComparison,
  PostFilterOperator,
} from './query-parameter.service';

const FILTER_OUT_EXPERIMENT_WORKFLOW_TEMPLATE = {
  path: 'experiment.experimentWorkflow',
  type: PostFilterOperator.and,
  predicates: [
    {
      value: '0',
      attribute: 'is_template',
      comparison: PostFilterComparison.eq,
    },
  ],
};

export class ReportService {
  public static injectFilterOutExperimentWorkflowTemplateParam = (
    parameters?: EntityViewParameters | null,
  ) => {
    if (
      parameters &&
      parameters.filter &&
      parameters.filter.length === 2 &&
      parameters.filter[1].predicates.length === 0
    ) {
      const newParameters = cloneDeep(parameters);
      if (
        newParameters &&
        newParameters.filter &&
        newParameters.filter.length === 2 &&
        newParameters.filter[1].predicates.length === 0
      ) {
        newParameters.filter[1].predicates.push(
          FILTER_OUT_EXPERIMENT_WORKFLOW_TEMPLATE,
        );
      }
      return newParameters;
    }
    return parameters;
  };

  public static removeFilterOutExperimentWorkflowTemplateParam = (
    parameters?: EntityViewParameters | null,
  ) => {
    if (
      parameters &&
      parameters.filter &&
      parameters.filter.length === 2 &&
      parameters.filter[1].predicates.length === 1
    ) {
      const newParameters = cloneDeep(parameters);
      parameters.filter[1].predicates = [];
      return newParameters;
    }
    return parameters;
  };
}
