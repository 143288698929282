/**
 * Labstep
 *
 * @module components/ProtocolTimer/List
 * @desc Protocol Timer list
 */

import EntityDraggable from 'labstep-web/components/Entity/Draggable';
import ProtocolTimerItem from 'labstep-web/components/ProtocolTimer/Item';
import List from 'labstep-web/core/List';
import React from 'react';
import { IProtocolTimerListProps } from './types';

export const ProtocolTimerList: React.FC<IProtocolTimerListProps> = ({
  protocolTimers,
  protocol,
}) => (
  <List id="protocol-timer-list">
    {protocolTimers.map((timer) => (
      <EntityDraggable
        key={timer.guid}
        entityGuid={timer.guid}
        entityName={timer.entityName}
      >
        <ProtocolTimerItem
          protocolTimer={timer}
          protocol={protocol}
        />
      </EntityDraggable>
    ))}
  </List>
);

export default ProtocolTimerList;
