/**
 * Labstep
 *
 * @module screens/Resource/Index
 * @desc Resource Index
 */

import EntityActionCreateMenu from 'labstep-web/components/Entity/Action/Create/Menu';
import { EntitySearchContainerChildrenProps } from 'labstep-web/components/Entity/Search/Children/types';
import EntityImportModal from 'labstep-web/components/EntityImport/Modal/Trigger';
import { ResourceEmptyState } from 'labstep-web/components/Resource/Card/EmptyState';
import ResourceListOrTable from 'labstep-web/components/Resource/ListOrTable';
import { ParamsStateContextProvider } from 'labstep-web/contexts/params-state';
import { useParamsContext } from 'labstep-web/contexts/params/hook';
import { Resource } from 'labstep-web/models/resource.model';
import MasterIndex from 'labstep-web/screens/Master/Index';
import { getActiveTemplateGuid } from 'labstep-web/screens/ResourceItem/Index/utils';
import { PostFilterTemplate } from 'labstep-web/services/postFilter/filters/template';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import ResourceIndexBreadcrumb from './Breadcrumb';
import ResourceIndexLeftPane from './LeftPane';
import { bulkActions, filters, sortOptions } from './constants';
import {
  IResourceIndexListOrTableProps,
  IResourceIndexProps,
} from './types';

export const ResourceIndexListOrTable: React.FC<
  IResourceIndexListOrTableProps
> = ({
  entities,
  initialTemplateGuid,
  setInitialTemplateGuid,
  ...rest
}) => {
  const { searchParams, addPostFilter } = useParamsContext();
  useEffect(() => {
    if (!initialTemplateGuid) {
      return;
    }

    addPostFilter(
      PostFilterTemplate.getNode([initialTemplateGuid]),
      true,
      1,
    );
    setInitialTemplateGuid(undefined);
  }, []);

  return (
    <ResourceListOrTable
      resources={entities}
      templateId={getActiveTemplateGuid(searchParams)}
      {...rest}
    />
  );
};

export const ResourceIndex: React.FC<IResourceIndexProps> = ({
  group,
  links,
  location,
}) => {
  const [initialTemplateGuid, setInitialTemplateGuid] = useState<
    string | undefined
  >(() => {
    const searchParams = Object.fromEntries(
      new URLSearchParams(location.search),
    );
    return searchParams.template_id;
  });
  const breadcrumb = useMemo(() => <ResourceIndexBreadcrumb />, []);
  const leftPane = useMemo(
    () => <ResourceIndexLeftPane links={links} />,
    [links],
  );
  const params = useMemo(
    () => ({ group_id: group.id, skip_total: 1, is_template: 0 }),
    [group.id],
  );
  const noResultsMessage = useCallback(
    ({ searchParams }) => (
      <ResourceEmptyState
        resourceTemplateId={getActiveTemplateGuid(searchParams)}
      />
    ),
    [],
  );
  const action = useCallback(
    ({ searchParams }) => (
      <EntityActionCreateMenu
        entityName={Resource.entityName}
        extraOptions={
          <EntityImportModal
            targetEntityName={Resource.entityName}
            templateId={getActiveTemplateGuid(searchParams)}
          />
        }
        templateId={getActiveTemplateGuid(searchParams)}
      />
    ),
    [],
  );

  const entityView = useCallback((searchParams) => {
    const guid = getActiveTemplateGuid(searchParams);

    return {
      context: 'resource_index',
      entityName: Resource.entityName,
      parameters: guid
        ? {
            template_guid: guid,
            resource_template_guid: guid,
          }
        : undefined,
    };
  }, []);

  const paramsStateContext = useMemo(
    () => ({ persistEntity: Resource.entityName }),
    [],
  );

  return (
    <ParamsStateContextProvider value={paramsStateContext}>
      <MasterIndex
        entityName={Resource.entityName}
        breadcrumb={breadcrumb}
        leftPane={leftPane}
        filters={filters}
        params={params}
        noResultsMessage={noResultsMessage}
        action={action}
        tableFormat={false}
        bulkActions={bulkActions}
        sortOptions={sortOptions}
        useAllPages
        entityView={entityView}
        usePostFilter
      >
        {({
          entities,
          readNextPage,
          status,
        }: EntitySearchContainerChildrenProps) => (
          <ResourceIndexListOrTable
            entities={entities}
            loadMore={readNextPage}
            initialTemplateGuid={initialTemplateGuid}
            setInitialTemplateGuid={setInitialTemplateGuid}
            status={status}
          />
        )}
      </MasterIndex>
    </ParamsStateContextProvider>
  );
};

export default ResourceIndex;
