/**
 * Labstep
 *
 * @module core/Button/Link
 * @desc Button Link
 */

import classnames from 'classnames';
import { navigation } from 'labstep-web/services/navigation';
import React from 'react';
import { withRouter } from 'react-router';
import styles from './styles.module.scss';
import { LinkButtonProps } from './types';

export const LinkButton: React.FC<LinkButtonProps> = ({
  children,
  history,
  route,
  className,
}) => (
  <div
    className={classnames(styles.link, className)}
    onClick={() =>
      history.push(
        navigation.get(route.to, route.params, false, route.query),
      )
    }
  >
    {children}
  </div>
);

export default withRouter(LinkButton);
