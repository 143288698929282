/**
 * Labstep
 */

export interface APICallStatusProps {
  icon: string;
  name: string;
  color: string;
  position: StatusPosition;
  small: boolean;
  nextColor?: string;
  activeIndexes: number[];
  index: number;
  changeIndex: React.Dispatch<React.SetStateAction<number[]>>;
  onClick: VoidFunction;
}

// eslint-disable-next-line no-shadow
export enum StatusPosition {
  start = 'start',
  middle = 'middle',
  end = 'end',
}
