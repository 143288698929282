/**
 * Labstep
 *
 * @module core/ReactPortal
 * @desc Render React components in parent elements
 */

import React from 'react';
import ReactDOM from 'react-dom';
import { IPortalProps } from './types';

export class Portal extends React.Component<IPortalProps> {
  el: HTMLDivElement;

  parent: Element | null = null;

  constructor(props: IPortalProps) {
    super(props);
    this.el = document.createElement('div');
  }

  componentDidMount() {
    this.props.parent.appendChild(this.el);
  }

  componentWillUnmount() {
    this.props.parent.removeChild(this.el);
  }

  render() {
    return ReactDOM.createPortal(this.props.children, this.el);
  }
}

export default Portal;
