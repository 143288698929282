/**
 * Labstep
 *
 * @module components/JupyterInsance/Modal/List
 * @desc JupyterNotebook List
 */

import JupyterInstanceList from 'labstep-web/components/JupyterInstance/List';
import { ActionComponent } from 'labstep-web/core/Action/Component';
import ModalDefault from 'labstep-web/core/Modal/Default';
import { humanReadableDate } from 'labstep-web/services/date.service';
import React from 'react';
import { JupyterInstanceModalListProps } from './types';

export const JupyterInstanceModalList: React.FC<
  JupyterInstanceModalListProps
> = ({ jupyterNotebook, endedAt }) => (
  <ModalDefault
    size="fullscreen"
    header="List of runs"
    content={
      <JupyterInstanceList jupyterNotebook={jupyterNotebook} />
    }
    viewComponent={({ toggleModal }): React.ReactElement => (
      <ActionComponent
        type="icon"
        icon="list"
        onClick={toggleModal}
        elementProps={{
          popup: {
            content: `List of runs - Last run: ${humanReadableDate(
              endedAt,
            )}`,
          },
        }}
      />
    )}
  />
);

export default JupyterInstanceModalList;
