/**
 * Labstep
 *
 * @module core/Dropzone
 * @desc Dropzone for files
 */

import Icon from 'labstep-web/core/Icon';
import React from 'react';
import styles from './styles.module.scss';

export const Dropzone: React.FC = () => (
  <div data-testid="dropzone" className={styles.dropzone}>
    <span className={styles.text}>
      <Icon name="file alternate" /> Drop file(s) here or{' '}
      <u>click to choose</u>
    </span>
  </div>
);

export default Dropzone;
