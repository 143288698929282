/**
 * Labstep
 *
 * @module state/middleware/file-download-group-id
 * @desc Inject active group id in file download action
 */

export const fileDownloadGroupId =
  (store: any) => (next: any) => (action: any) => {
    const state = store.getState();
    if (action?.type === 'REQUEST_FILE_DOWNLOAD') {
      return next({
        type: 'REQUEST_FILE_DOWNLOAD',
        meta: {
          ...action.meta,
          body: {
            group_id: state.activeGroupId,
          },
        },
      });
    }

    return next(action);
  };
