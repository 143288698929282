/**
 * Labstep
 *
 * @module components/ProtocolCollection/SearchSelect
 * @desc Protocol Search Select
 */

import SearchSelect from 'labstep-web/core/Select/Search';
import { ProtocolCollection } from 'labstep-web/models/protocol-collection.model';
import { Protocol } from 'labstep-web/models/protocol.model';
import React from 'react';
import { IProtocolSearchSelectProps } from './types';

export const ProtocolSearchSelect: React.FC<
  IProtocolSearchSelectProps
> = ({ params, returnProtocolCollection, ...rest }) => (
  <SearchSelect
    labelKey="label"
    isClearable={false}
    setOptions={(protocolCollections: ProtocolCollection[]) =>
      protocolCollections.map(
        (protocolCollection: ProtocolCollection) => {
          if (returnProtocolCollection) {
            return {
              guid: protocolCollection.guid,
              label: protocolCollection.name,
            };
          }
          const lastVersion =
            protocolCollection.last_version as Protocol;
          const { name: label } = protocolCollection;
          return { id: lastVersion.id, label };
        },
      )
    }
    entityName="protocol_collection"
    params={{ has_last_version: true, ...params }}
    {...rest}
  />
);

export default ProtocolSearchSelect;
