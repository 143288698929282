/**
 * Labstep
 *
 * @module core/Form/Reusable/Actions
 * @desc Actions in reusable form
 */

import ActionContainer from 'labstep-web/components/Layout/Container/ActionButtons';
import Button from 'labstep-web/core/Button';
import { IButtonProps } from 'labstep-web/core/Button/types';
import { objectOrFunction } from 'labstep-web/services/react.service';
import React from 'react';
import { IFormReusableActionsProps } from './types';

const FormReusableActions: React.FC<IFormReusableActionsProps> = ({
  cancelButton: customCancelButton,
  submitButton: customSubmitButton,
  status,
  onCancel,
  submitForm,
  values,
  color = 'blue' as IButtonProps['color'],
  submitButtonText = 'Submit',
  setValue,
}) => {
  let submitButton: React.ReactNode;
  if (customSubmitButton) {
    submitButton = objectOrFunction(customSubmitButton, {
      submitForm,
      status,
      values,
      setValue,
    });
  } else if (customSubmitButton !== false) {
    submitButton = (
      <Button
        color={color}
        type="submit"
        status={status}
        loader
        children={submitButtonText}
      />
    );
  }

  let cancelButton: React.ReactNode;
  if (customCancelButton) {
    cancelButton = customCancelButton;
  } else if (onCancel) {
    cancelButton = (
      <Button
        color={color}
        type="button"
        status={status}
        basic
        children="Cancel"
        onClick={onCancel}
      />
    );
  }
  return (
    <ActionContainer>
      {cancelButton}
      {submitButton}
    </ActionContainer>
  );
};

export default FormReusableActions;
