/**
 * Labstep
 *
 * @module components/EntityStateWorkflow/Card
 * @desc EntityStateWorkflow Card
 */

import EntityStateWorkflowActionMenu from 'labstep-web/components/EntityStateWorkflow/Action/Menu';
import GenericCard from 'labstep-web/core/Card/Generic';
import React from 'react';
import { EntityStateWorkflowCardProps } from './types';

const EntityStateWorkflowCard: React.FC<
  EntityStateWorkflowCardProps
> = ({ entityStateWorkflow, actions }) => (
  <GenericCard
    headerTitle={{
      to: 'entity_state_workflow_show',
      params: { id: entityStateWorkflow.id },
      title: entityStateWorkflow.displayName,
    }}
    actionMenu={
      actions && (
        <EntityStateWorkflowActionMenu
          entityStateWorkflow={entityStateWorkflow}
          actions={actions}
        />
      )
    }
  />
);

export default EntityStateWorkflowCard;
