/**
 * Labstep
 *
 * @module core/Form/ReactForm/Checkbox
 * @desc Wrapper around semantic-ui-react Checkbox
 */

import CoreCheckbox from 'labstep-web/core/Checkbox';
import React, { useEffect } from 'react';
import { Controller } from 'react-hook-form';
import { ICheckboxProps } from './types';

export const Checkbox: React.FC<ICheckboxProps> = ({
  elementProps,
  control,
  name,
  autoFocus,
  onKeyPress,
  ...rest
}) => (
  <Controller
    name={name}
    control={control}
    render={({ field }): React.ReactElement => {
      const { onChange } = field;
      const { value } = field;
      useEffect(() => {
        if (elementProps?.defaultValue) {
          field.onChange(elementProps.defaultValue);
        }
      }, []);

      return (
        <CoreCheckbox
          {...field}
          checked={value}
          {...elementProps}
          {...rest}
          onChange={(e, data): void => {
            onChange(data.checked as any);
          }}
        />
      );
    }}
  />
);

export default Checkbox;
