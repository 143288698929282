/**
 * Labstep
 *
 * @module prosemirror/marks
 * @desc ProseMirror Marks Definitions
 */

import { keymap } from 'prosemirror-keymap';
import { Plugin } from 'prosemirror-state';
import MarkBackgroundColor from './background-color';
import MarkCode from './code';
import MarkColor from './color';
import MarkItalic from './italic';
import MarkLink from './link';
import MarkStrike from './strike';
import MarkStrong from './strong';
import MarkSub from './sub';
import MarkSup from './sup';
import { LabstepMark } from './types';
import MarkUnderline from './underline';

export const marks: LabstepMark[] = [
  MarkStrong,
  MarkItalic,
  MarkUnderline,
  MarkSub,
  MarkSup,
  MarkStrike,
  MarkBackgroundColor,
  MarkCode,
  MarkColor,
  MarkLink,
];

export const spec = marks.reduce(
  (result, mark) => ({ ...result, [mark.key]: mark.spec }),
  {},
);

const marksKeymap = marks.reduce(
  (result, mark) =>
    mark.keymap ? [...result, keymap(mark.keymap)] : result,
  [] as Plugin[],
);

export const plugins = marksKeymap;
