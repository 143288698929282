/**
 * Labstep
 *
 * @module components/EntityView/Form/Create
 * @desc Create EntityView form
 */

import EntityViewFormCreateMetadata from 'labstep-web/components/EntityView/Form/Create/Metadata';
import EntityViewFormCreateProtocolCollection from 'labstep-web/components/EntityView/Form/Create/ProtocolCollection';
import { ICONS } from 'labstep-web/constants/icons';
import Button, { ButtonGroup } from 'labstep-web/core/Button';
import { VerticalDivider } from 'labstep-web/core/Divider';
import Flex from 'labstep-web/core/Flex';
import Header from 'labstep-web/core/Header';
import Icon from 'labstep-web/core/Icon';
import React, { useState } from 'react';
import styles from './styles.module.scss';
import { EntityViewFormCreateProps } from './types';

export const EntityViewFormCreate: React.FC<
  EntityViewFormCreateProps
> = ({ group, folderId, onSuccess }) => {
  const [type, setType] = useState('protocol_collection');

  return (
    <Flex grow spacing="gap">
      <Flex column style={{ minWidth: '250px' }}>
        <Header size="tiny">Report on</Header>
        <ButtonGroup className={styles.buttonGroup} vertical>
          <Button
            active={type === 'protocol_collection'}
            onClick={() => {
              setType('protocol_collection');
            }}
            className={`${styles.bigRadioButton} ${
              styles.protocolButton
            } ${type === 'protocol_collection' ? styles.active : ''}`}
          >
            <Icon name={ICONS.protocol_collection.primary} />
            Protocol Runs
          </Button>
          <Button
            active={type === 'metadata'}
            onClick={() => {
              setType('metadata');
            }}
            className={`${styles.bigRadioButton} ${
              styles.metadataButton
            } ${type === 'metadata' ? styles.active : ''}`}
          >
            <Icon name={ICONS.metadata.primary} />
            Data Fields
          </Button>
        </ButtonGroup>
      </Flex>
      <VerticalDivider stretch />
      <Flex grow column style={{ padding: '0 20px' }}>
        {type === 'metadata' && (
          <>
            <p>
              Select a field to create a report showing every value of
              that data field across all experiments in the workspace.
              You can then add additional columns to compare to other
              data.
            </p>
            <EntityViewFormCreateMetadata
              group={group}
              onSuccess={onSuccess}
              folderId={folderId}
            />
          </>
        )}
        {type === 'protocol_collection' && (
          <>
            <p>
              Select a protocol to create a report showing the values
              of all inventory and data fields for the protocol across
              runs of the protocol in this workspace.
            </p>
            <EntityViewFormCreateProtocolCollection
              group={group}
              onSuccess={onSuccess}
              folderId={folderId}
            />
          </>
        )}
      </Flex>
    </Flex>
  );
};

export default EntityViewFormCreate;
