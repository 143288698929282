/**
 * Labstep
 *
 * @module components/EntityExport/Manager
 * @desc Manager for EntityExport
 */

import EntitySearch from 'labstep-web/components/Entity/Search';
import EntityExportTable from 'labstep-web/components/EntityExport/Table';
import { EntityExport } from 'labstep-web/models/entity-export.model';
import React from 'react';
import { EntityExportManagerProps } from './types';

const EntityExportManager: React.FC<EntityExportManagerProps> = ({
  organization,
}) => (
  <EntitySearch
    entityName={EntityExport.entityName}
    params={{
      organization_id: organization.id,
      count: 10,
    }}
    hideFilters
  >
    {({ entities }) => <EntityExportTable entityExports={entities} />}
  </EntitySearch>
);

export default EntityExportManager;
