/**
 * Labstep
 *
 * @module grid/IndexDevice
 * @desc Device Index Grid
 * @url  /workspace/:workspaceId/devices
 */

import { useParamsContext } from 'labstep-web/contexts/params/hook';
import GridIndex from 'labstep-web/grid/Index';
import MetadataDataGridColumnOptions from 'labstep-web/grid/Index/coldefs/Metadata/ColumnOptions';
import { generateDeviceGridcoldefs } from 'labstep-web/grid/IndexDevice/coldefs';
import { ReadOnMountHOC } from 'labstep-web/hoc/ReadOnMount';
import { Device } from 'labstep-web/models/device.model';
import React from 'react';
import { GridDeviceProps } from './types';

export const GridDevice: React.FC<GridDeviceProps> = ({
  templateId,
  devices,
  ...rest
}) => {
  const { setParams } = useParamsContext();

  if (templateId) {
    return (
      <ReadOnMountHOC
        type="entities"
        entityName={Device.entityName}
        params={{
          id: templateId,
          get_single: 1,
        }}
        loading={{ loader: 'placeholder' }}
        children={({ entity: deviceTemplate }) => (
          <GridIndex
            entities={devices}
            columnDefs={generateDeviceGridcoldefs(
              setParams,
              deviceTemplate,
            )}
            toolPanelParams={{
              extraColumnOptions: (
                <MetadataDataGridColumnOptions
                  entity={deviceTemplate}
                />
              ),
            }}
            {...rest}
          />
        )}
      />
    );
  }
  return (
    <GridIndex
      entities={devices}
      columnDefs={generateDeviceGridcoldefs(setParams)}
      {...rest}
    />
  );
};

export default GridDevice;
