/**
 * Labstep
 *
 * @module core/Pagination/CountPerPage
 * @desc Select count per page
 */

import SelectNoIndicator from 'labstep-web/core/Select/NoIndicator';
import { ReactSelectOption } from 'labstep-web/core/Select/types';
import React from 'react';
import styles from './styles.module.scss';
import { IPaginationCountPerPageProps } from './types';

export const AVAILABLE_COUNTS = [10, 20, 50];
export const AVAILABLE_COUNTS_FILTER = [10];

export const optionFromCount = (
  count: number,
): ReactSelectOption => ({
  label: count,
  value: String(count),
});

export const PaginationCountPerPage: React.FC<
  IPaginationCountPerPageProps
> = ({
  countPerPage,
  setCountPerPage,
  availableCounts = AVAILABLE_COUNTS,
}) => (
  <div className={styles.container}>
    <span>Results per page:</span>
    <SelectNoIndicator
      className={styles.selectContainer}
      value={optionFromCount(countPerPage)}
      options={availableCounts.map((count) => optionFromCount(count))}
      onChange={(option: ReactSelectOption): void =>
        setCountPerPage(Number(option.value))
      }
      menuPlacement="top"
      isSearchable={false}
    />
  </div>
);

export default PaginationCountPerPage;
