/**
 * Labstep
 *
 * @module core/Table/Simple/Body
 * @desc Simple table body component
 */

import Table from 'labstep-web/core/Table';
import { isEqual } from 'lodash';
import React from 'react';
import styles from './styles.module.scss';
import { ITableSimpleBodyProps } from './types';

export class TableSimpleBody extends React.Component<ITableSimpleBodyProps> {
  shouldComponentUpdate(prevProps: ITableSimpleBodyProps) {
    const shouldUpdate =
      !isEqual(this.props.columns, prevProps.columns) ||
      !isEqual(this.props.rows, prevProps.rows) ||
      !!this.props.footer;

    return shouldUpdate;
  }

  render() {
    const { columns, rows, footer, selectedRowI } = this.props;
    return (
      <Table.Body className={styles.body}>
        {rows.map((row, i) => (
          // If the passed row is an entity with id
          // It's important to pass key as the entity id
          // Rather than the index
          <Table.Row key={row.id || i} active={selectedRowI === i}>
            {columns.map((column, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <Table.Cell key={index} {...column.cellProps}>
                {column.content(row) || ''}
              </Table.Cell>
            ))}
          </Table.Row>
        ))}
        {footer}
      </Table.Body>
    );
  }
}

export default TableSimpleBody;
