/**
 * Labstep
 *
 * @module state/epics/optimistic
 * @desc Optimistic update
 */

import { Action } from 'labstep-web/models/action.model';
import pick from 'lodash/pick';
import { StateObservable } from 'redux-observable';
import { Observable, concat, of } from 'rxjs';
import { catchError, filter, map } from 'rxjs/operators';

const OPTIMISTIC_KEY_IGNORE_LIST = ['idp_entity_id', 'saml_id'];

export const getOptimisticKey = (key: string): string => {
  if (
    key.endsWith('_id') &&
    !OPTIMISTIC_KEY_IGNORE_LIST.includes(key)
  ) {
    return key.slice(0, -3);
  }
  if (
    key.endsWith('_guid') &&
    !OPTIMISTIC_KEY_IGNORE_LIST.includes(key)
  ) {
    return key.slice(0, -5);
  }
  return key;
};

/**
 * We are extracting meta body keys and removing '_id' from keys.
 * This will allow us to fetch inner entities for fallback.
 *
 * @param action
 */
export const getKeys = (action: Action): string[] =>
  Object.keys((action.meta as Record<string, string>).body).map(
    (key) => {
      return getOptimisticKey(key);
    },
  );

/**
 * When an optimistic request fails, dispatch a fallback action.
 *
 * @function
 * @param  {Observable<Action>} action$
 * @param  {StateObservable<LabstepReduxState>} state$
 * @return {Observable<Action>}
 */
export const onFailUpdateActionEpic = (
  action$: Observable<Action>,
  state$: StateObservable<any>,
): Observable<Action> =>
  action$.pipe(
    filter(
      (action: Action) =>
        (action.type &&
          action.meta &&
          action.type.startsWith('FAIL_UPDATE') &&
          (action.meta.optimistic as boolean)) ||
        false,
    ),
    map((action: Action) => {
      const keys = getKeys(action);

      return {
        type: action.type.replace('FAIL', 'FALLBACK'),
        meta: action.meta,
        fallbackBody: pick(
          state$.value.optimistic[action.meta!.entityName as string][
            (action.meta as Record<string, string>).identifier
          ],
          keys,
        ),
      };
    }),
    catchError((err, source$: Observable<Action>) =>
      concat(
        of({
          type: 'EPIC_FAIL_OPTIMISTIC_FAIL_UPDATE',
          payload: err,
        }),
        source$,
      ),
    ),
  );
