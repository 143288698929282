/**
 * Labstep
 *
 * @module prosemirror/state/input-rules
 * @desc ProseMirror build input rules based on schema
 */

import { nodes } from 'labstep-web/prosemirror/nodes';
import {
  ellipsis,
  emDash,
  inputRules,
  smartQuotes,
} from 'prosemirror-inputrules';
import { Schema } from 'prosemirror-model';

// : (Schema) → Plugin
// A set of input rules for creating the basic block quotes, lists,
// code blocks, and heading.
export function buildInputRules(schema: Schema) {
  const rules = smartQuotes.concat(ellipsis, emDash);

  nodes
    .filter((node) => !!node.inputRules)
    .forEach((node) => {
      if (node.inputRules) {
        const inputRules = node.inputRules({ schema });
        if (inputRules) {
          rules.push(...inputRules);
        }
      }
    });

  return inputRules({ rules });
}
