/**
 * Labstep
 */

import { PostFilter } from 'labstep-web/services/postFilter';
import { PostFilterComparison } from 'labstep-web/services/query-parameter.service';

export const PostFilterMolecule = new PostFilter(
  [['inchis', PostFilterComparison.contains]],
  'metadatas.molecule',
);
