/**
 * Labstep
 *
 * @module screens/Orders
 * @desc Order Screen (order requests + purchase orders)
 */

import LayoutLinksMobile from 'labstep-web/components/Layout/Links/Mobile';
import { EntityCardLink } from 'labstep-web/components/Layout/Links/types';
import { ICONS } from 'labstep-web/constants/icons';
import Icon from 'labstep-web/core/Icon';
import ScreensOrderRequestIndex from 'labstep-web/screens/OrderRequest/Index';
import ScreensPurchaseOrderIndex from 'labstep-web/screens/PurchaseOrder/Index';
import React from 'react';
import { IScreensOrdersProps } from './types';

const ScreensOrders: React.FC<IScreensOrdersProps> = ({ group }) => {
  const links: EntityCardLink[] = [
    {
      children: (
        <span>
          <Icon name={ICONS.order_request.primary} /> Request View
        </span>
      ),
      route: {
        to: 'group_order_requests',
        params: { id: group.id },
      },
    },
    {
      children: (
        <span>
          <Icon name={ICONS.purchase_order.primary} /> Order View
        </span>
      ),
      route: {
        to: 'group_purchase_orders',
        params: { id: group.id },
      },
    },
  ];

  const screens = [
    () => <ScreensOrderRequestIndex group={group} links={links} />,
    () => <ScreensPurchaseOrderIndex group={group} links={links} />,
  ];

  return <LayoutLinksMobile links={links} screens={screens} />;
};

export default ScreensOrders;
