/**
 * Labstep
 *
 * @module core/MediaQuery
 * @desc Core media query component
 */

import { breakpoints } from 'labstep-web/constants/breakpoints';
import React from 'react';
import Media, { MediaQueryValue } from 'react-media';
import { IMediaQueryProps } from './types';

const phabletBreakPoint = `${breakpoints.phablet}px`;
const mobileBreakPoint = `${breakpoints.mobile}px`;
const tabletBreakPoint = `${breakpoints.tablet}px`;
const largeDesktopBreakPoint = `${breakpoints.large_desktop}px`;

export const getQueryForSize = (size: string): MediaQueryValue => {
  switch (size) {
    case 'phablet':
      return { maxWidth: phabletBreakPoint };
    case 'mobile':
      return { maxWidth: mobileBreakPoint };
    case 'tablet':
      return {
        minWidth: `${breakpoints.mobile + 1}px`,
        maxWidth: tabletBreakPoint,
      };
    case 'desktop':
      return { minWidth: `${breakpoints.tablet + 1}px` };
    case 'small_desktop desktop':
      return { minWidth: `${breakpoints.small_desktop + 1}px` };
    case 'large_desktop':
      return { minWidth: largeDesktopBreakPoint };
    case 'mobile tablet':
      return { maxWidth: tabletBreakPoint };
    case 'tablet desktop':
      return { minWidth: `${breakpoints.mobile + 1}px` };
    default:
      return '(min-width: 1px)';
  }
};

const MediaQuery: React.FC<IMediaQueryProps> = ({
  size,
  children,
}) => (
  <Media query={getQueryForSize(size)}>
    {(match: boolean) => {
      if (typeof children === 'function') {
        return children({ match });
      }
      if (match) {
        return children;
      }
      return null;
    }}
  </Media>
);

export default MediaQuery;
