/**
 * Labstep
 */

import React from 'react';
import { IModalWizardContextProps } from './types';

export const defaultModalWizardContext = {
  activeStepIndex: 0,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setActiveStepIndex: () => {
    // Do nothing
  },
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  goBack: () => {
    // Do nothing
  },
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  goForward: () => {
    // Do nothing
  },
  startIndex: 0,
  isLastStep: false,
  status: undefined,
  errorMessages: [],
  steps: [],
  setErrorMessages: () => {},
};

export const ModalWizardContext =
  React.createContext<IModalWizardContextProps>(
    defaultModalWizardContext,
  );
