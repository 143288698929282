/**
 * Labstep
 *
 * @module components/Metadata/Form/ShowEdit/Numeric
 * @desc Edit a Metadata numeric value
 */

import MetadataFormShowEditUnit from 'labstep-web/components/Metadata/Form/ShowEdit/Unit';
import { getFieldsNumeric } from 'labstep-web/components/Metadata/Form/fields';
import ShowEditText from 'labstep-web/core/Form/ShowEdit/Text';
import Hoverable from 'labstep-web/core/Hoverable';
import React from 'react';
import { MetadataFormShowEditNumericProps } from './types';

const MetadataFormShowEditNumeric: React.FC<
  MetadataFormShowEditNumericProps
> = ({
  metadata,
  isTemplate,
  createProps,
  canCreate,
  type = 'both',
}) => {
  let editComponent: React.ReactElement;
  let viewComponent: React.ReactElement;

  switch (type) {
    case 'both':
      editComponent = (
        <Hoverable
          component={
            <MetadataFormShowEditUnit
              createProps={createProps}
              metadata={metadata}
            />
          }
          show={!!metadata.unit}
          dataTestId="numeric-unit"
          hoverable={
            <ShowEditText
              entity={metadata}
              entityName={metadata.entityName}
              field={getFieldsNumeric(isTemplate)[0]}
              createProps={createProps}
              children={metadata.number}
            />
          }
        />
      );
      viewComponent = <div>{metadata.printValue}</div>;
      break;
    case 'number':
      editComponent = (
        <ShowEditText
          entity={metadata}
          entityName={metadata.entityName}
          field={getFieldsNumeric(isTemplate)[0]}
          createProps={createProps}
        >
          {metadata.number}
        </ShowEditText>
      );
      viewComponent = <div>{metadata.number}</div>;
      break;
    case 'unit':
      editComponent = (
        <ShowEditText
          entity={metadata}
          entityName={metadata.entityName}
          field={getFieldsNumeric(isTemplate)[1]}
          createProps={createProps}
        >
          {metadata.unit}
        </ShowEditText>
      );
      viewComponent = <div>{metadata.unit}</div>;
      break;
    default:
      break;
  }
  return createProps && !canCreate ? viewComponent : editComponent;
};

export default MetadataFormShowEditNumeric;
