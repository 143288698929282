/**
 * Labstep
 *
 * @module screens/Orders
 * @desc Order Screen (order requests + purchase orders)
 */

import LayoutFullScreenPage from 'labstep-web/components/Layout/FullScreenPage';
import LayoutLinks from 'labstep-web/components/Layout/Links';
import { EntityCardLink } from 'labstep-web/components/Layout/Links/types';
import OrganizationGroupManager from 'labstep-web/components/Organization/GroupManager';
import { withAuthenticatedUserEntity } from 'labstep-web/containers/AuthenticatedUser';
import Header from 'labstep-web/core/Header';
import ScreensUserGroupIndex from 'labstep-web/screens/UserGroup/Index';
import React from 'react';
import { IScreensGroupIndexProps } from './types';

export const ScreensGroupIndex: React.FC<IScreensGroupIndexProps> = ({
  authenticatedUserEntity,
}) => {
  let routes: EntityCardLink[] = [
    {
      children: 'My Workspaces',
      route: {
        to: 'group_index',
      },
      render: () => <ScreensUserGroupIndex />,
    },
  ];

  const { userOrganization } = authenticatedUserEntity;
  if (userOrganization) {
    routes = [
      ...routes,
      {
        children: `${userOrganization.organization.name} Workspaces`,
        route: {
          to: 'group_index_organization',
        },
        render: () => (
          <OrganizationGroupManager
            organization={userOrganization.organization}
          />
        ),
      },
    ];
  }

  return (
    <LayoutFullScreenPage>
      <Header>All Workspaces</Header>
      <LayoutLinks links={routes} />
    </LayoutFullScreenPage>
  );
};

export default withAuthenticatedUserEntity(ScreensGroupIndex);
