/**
 * Labstep
 *
 * @module components/ResourceLocation/MapModal/Content/ChildComponent
 * @desc Component to be rendered in GridLayout for each item
 */

import classnames from 'classnames';
import { ICONS } from 'labstep-web/constants/icons';
import { EntityReadEntityContainer } from 'labstep-web/containers/Entity/Read/Entity';
import Icon from 'labstep-web/core/Icon';
import Popup from 'labstep-web/core/Popup';
import { navigation } from 'labstep-web/services/navigation';
import React from 'react';
import { withRouter } from 'react-router';
import styles from './styles.module.scss';
import {
  IChildComponentContainerProps,
  IChildComponentProps,
} from './types';

export const ChildComponent: React.FC<IChildComponentProps> = ({
  entity,
  onDoubleClick,
  showText,
  entityName,
  name,
}) => {
  return (
    <div
      className={classnames(styles.childComponent)}
      onDoubleClick={onDoubleClick}
    >
      <Popup
        inverted={false}
        trigger={
          <div className={styles.trigger}>
            <Icon color="blue" name={ICONS[entityName].primary} />
            {showText && <p>{entity ? entity.name : name}</p>}
          </div>
        }
        content={
          <div>
            <p>{entity ? entity.name : name}</p>
          </div>
        }
      />
    </div>
  );
};

export const ChildComponentContainer: React.FC<
  IChildComponentContainerProps
> = ({ history, name, item }) => {
  if (!item.i) {
    return null;
  }
  const [entityName, entityId] = item.i.split('-');
  // Preventing rendering whn dragging
  if (!entityName.startsWith('resource')) {
    return null;
  }
  return (
    <EntityReadEntityContainer entityName={entityName} id={entityId}>
      {({ entity }) => (
        <ChildComponent
          entity={entity}
          entityId={entityId}
          entityName={entityName}
          name={name}
          showText={item.w > 1}
          onDoubleClick={() =>
            history.push(
              navigation.get(
                `${entityName as 'resource' | 'resource_item'}_show`,
                {
                  id: entityId,
                },
              ),
            )
          }
        />
      )}
    </EntityReadEntityContainer>
  );
};

export default withRouter(ChildComponentContainer);
