/**
 * Labstep
 *
 * @module components/OrderRequest/Action/Delete
 * @desc Action to delete
 */

import EntityActionDelete from 'labstep-web/components/Entity/Action/Delete';
import React from 'react';
import { OrderRequestDeleteActionProps } from './types';

export const OrderRequestDeleteAction: React.FC<
  OrderRequestDeleteActionProps
> = ({ orderRequest }) => (
  <EntityActionDelete entity={orderRequest} />
);

export default OrderRequestDeleteAction;
