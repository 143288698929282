/**
 * Labstep
 *
 * @module components/Device/Action/Create
 * @desc Create Action for Device
 */

import EntityActionCreate from 'labstep-web/components/Entity/Action/Create';
import { Device } from 'labstep-web/models/device.model';
import React from 'react';
import { DeviceActionCreateProps } from './types';

export const DeviceActionCreate: React.FC<
  DeviceActionCreateProps
> = ({ isTemplate }) => (
  <EntityActionCreate
    entityName={Device.entityName}
    body={isTemplate ? { is_template: true } : undefined}
    redirectRoute={({ response }) => ({
      to: 'device_show',
      params: { id: response.result },
    })}
    actionComponentProps={{
      type: 'button',
    }}
  />
);

export default DeviceActionCreate;
