/**
 * Labstep
 *
 * @module core/DataGrid/CellRenderer/File
 * @desc CellRenderer File
 */

import FileActionRemove from 'labstep-web/components/File/Action/Remove';
import FilePreview from 'labstep-web/components/File/Preview';
import Label from 'labstep-web/core/Label';
import { Entity } from 'labstep-web/models/entity.model';
import React from 'react';
import DataGridPlaceholder from '../../Placeholder';
import styles from './styles.module.scss';
import { ICellRendererFileProps } from './types';

export const DataGridCellRendererFile: React.FC<
  ICellRendererFileProps<Entity>
> = React.memo((params) => (
  <div className={styles.container}>
    {params.props.files.length ? (
      params.props.files.map((file) => (
        <Label key={file.id}>
          <FilePreview
            file={file}
            text={file.name}
            noDiv
            withDefault
          />
          <FileActionRemove
            file={file}
            actionComponentProps={{
              type: 'icon',
              icon: 'close',
              elementProps: { noDiv: true },
            }}
            options={{
              onSuccess: (): void => {
                if (!params.node) {
                  return;
                }
                params.api.refreshCells({
                  rowNodes: [params.node],
                  columns: [params.column],
                  force: true,
                });
              },
            }}
          />
        </Label>
      ))
    ) : (
      <DataGridPlaceholder
        children="Select"
        params={params}
        editable={params.props.editable}
      />
    )}
  </div>
));

export default DataGridCellRendererFile;
