/**
 * Labstep
 *
 * @module components/ProtocolCondition/Modal/Content
 * @desc Protocol Condition Modal Content
 */

import ProtocolConditionSmartTable from 'labstep-web/grid/SmartTableProtocolCondition';
import ScreensMasterShow from 'labstep-web/screens/Master/Show';
import { IScreensMasterShowProps } from 'labstep-web/screens/Master/Show/types';
import React from 'react';
import { useProtocolConditionModalContext } from '../context';
import ProtocolConditionModalContentRightActiveVariable from './Right/ActiveVariable';
import ProtocolConditionModalContentRightVariableManager from './Right/VariableManager';
import { IProtocolConditionModalContentProps } from './types';

export const ProtocolConditionModalContent: React.FC<
  IProtocolConditionModalContentProps
> = ({ protocol }) => {
  const { activeVariable, variableManager } =
    useProtocolConditionModalContext();

  let right: IScreensMasterShowProps['right'];
  if (activeVariable) {
    right = (
      <ProtocolConditionModalContentRightActiveVariable
        protocol={protocol}
      />
    );
  } else if (variableManager) {
    right = (
      <ProtocolConditionModalContentRightVariableManager
        protocol={protocol}
      />
    );
  }

  return (
    <ScreensMasterShow
      center={<ProtocolConditionSmartTable protocol={protocol} />}
      entity={protocol}
      right={right}
    />
  );
};

export default ProtocolConditionModalContent;
