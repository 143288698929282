/**
 * Labstep
 *
 * @module models/groupAutoSharing
 * @desc Typescript export class for GroupAutoSharing
 */

import { Type } from 'class-transformer';
import { Entity } from 'labstep-web/models/entity.model';
import { Group } from 'labstep-web/models/group.model';
import { getHumanReadableEntityName } from 'labstep-web/services/i18n.service';

export class GroupAutoSharing extends Entity {
  public static readonly idAttr = 'guid' as const;

  static readonly entityName = 'group_auto_sharing';

  get entityName(): string {
    return GroupAutoSharing.entityName;
  }

  constructor(data: Partial<GroupAutoSharing>) {
    super();
    Object.assign(this, data);
  }

  id!: number;

  entity_type!: string;

  permission_type!: string;

  is_propagating!: boolean;

  @Type(() => Group)
  source_group!: Group;

  @Type(() => Group)
  target_group!: Group;

  static getHumanReadableEntityName(
    plural?: boolean,
    capitalized?: boolean,
  ): string {
    return getHumanReadableEntityName(
      this.entityName,
      plural,
      capitalized,
    );
  }
}
