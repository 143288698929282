/**
 * Labstep
 *
 * @module components/EntityStateWorkflow/List
 * @desc EntityStateWorkflow List
 */

import EntityStateWorkflowCard from 'labstep-web/components/EntityStateWorkflow/Card';
import List from 'labstep-web/core/List';
import React from 'react';
import styles from './styles.module.scss';
import { EntityStateWorkflowListProps } from './types';

const EntityStateWorkflowList: React.FC<
  EntityStateWorkflowListProps
> = ({ entityStateWorkflows, actions, mainActionType }) => (
  <List className={styles.feed} id="entity-state-workflow-list">
    {entityStateWorkflows.map((entityStateWorkflow) => (
      <EntityStateWorkflowCard
        key={`entity-state-workflow-${entityStateWorkflow.id}`}
        entityStateWorkflow={entityStateWorkflow}
        actions={actions}
        mainActionType={mainActionType}
      />
    ))}
  </List>
);

export default EntityStateWorkflowList;
