/**
 * Labstep
 *
 * @module components/Entity/Table
 * @desc Table listing all entities
 */

import EntityLink from 'labstep-web/components/Entity/Link';
import EntityTableBulkSelect from 'labstep-web/components/Entity/Table/BulkSelect';
import EntityTableSortableHeader from 'labstep-web/components/Entity/Table/SortableHeader';
import { ParamsContext } from 'labstep-web/contexts/params';
import Placeholder from 'labstep-web/core/Placeholder';
import { TableColumnType } from 'labstep-web/core/Table/Simple/types';
import { ParamsHOC } from 'labstep-web/hoc/Params';
import { Entity } from 'labstep-web/models/entity.model';
import styles from './styles.module.scss';
import {
  EntityTableProps,
  IEntityTableParamsContainerProps,
} from './types';
import { getLastColumn, getLastColumnHeader } from './utils';

export const EntityTable = <TEntityType extends Entity>({
  entities,
  actionMenu,
  showCollaborators,
  extraColumns,
  primaryColumn,
  searchParams,
  setParams,
  sortOptions,
  id,
  dataCy,
  status,
  emptyState,
  withCheckbox = true,
  editableName,
  footer,
  primaryColumnHeader,
  matchSearchResultColumn,
  tableProps,
  linkParams,
}: EntityTableProps<TEntityType>) => {
  const isLoading = status && status.isFetching;

  const isCached =
    status &&
    status.cached &&
    Math.floor((status.cached - Date.now()) / 1000 / 60) < 5;

  const showFullTableSpinner =
    isLoading && (!isCached || entities.length === 0);

  let columns: TableColumnType<any>[] = [
    primaryColumn || {
      header: primaryColumnHeader || '',
      content: (entity: any) => (
        <EntityLink
          editableName={editableName}
          entity={entity}
          linkParams={linkParams}
        />
      ),
      cellProps: { style: { flex: 3 } },
    },
  ];

  if (matchSearchResultColumn) {
    columns.splice(0, 0, matchSearchResultColumn);
  }

  if (extraColumns) {
    columns = columns.concat(
      extraColumns.map((column) => ({
        ...column,
        header: column.sortKey ? (
          <EntityTableSortableHeader
            sortKey={column.sortKey}
            title={column.header}
            searchParams={searchParams}
            setParams={setParams}
          />
        ) : (
          column.header
        ),
      })),
    );
  }

  if (showCollaborators || actionMenu) {
    columns.push(
      getLastColumn({
        showCollaborators,
        actionMenu,
      }),
    );
  }

  const lastHeader = getLastColumnHeader({
    isLoading,
    sortOptions,
  });

  if (showFullTableSpinner) {
    return <Placeholder />;
  }

  return entities.length === 0 && !isLoading ? (
    <>{emptyState}</>
  ) : (
    <EntityTableBulkSelect
      footer={footer}
      id={id}
      dataCy={dataCy}
      entities={entities}
      columns={columns}
      withCheckbox={withCheckbox}
      lastHeader={lastHeader}
      {...tableProps}
    />
  );
};

export const EntityTableParamsContainer = (
  props: IEntityTableParamsContainerProps<any>,
) => (
  <ParamsHOC historyAction="replace">
    <ParamsContext.Consumer>
      {({ searchParams, setParams }) => (
        <div className={styles.container}>
          <EntityTable
            {...props}
            searchParams={searchParams}
            setParams={setParams}
          />
        </div>
      )}
    </ParamsContext.Consumer>
  </ParamsHOC>
);

export default EntityTableParamsContainer;
