/**
 * Labstep
 *
 * @module prosemirror/components/Toolbar/ClearFormatting
 * @desc Button to clear formatting
 */

import ToolbarButton from 'labstep-web/prosemirror/components/Toolbar/Button';
import { marks } from 'labstep-web/prosemirror/marks';
import { LabstepMark } from 'labstep-web/prosemirror/marks/types';
import bugsnagService from 'labstep-web/services/bugsnag.service';
import { EditorState, Transaction } from 'prosemirror-state';
import React from 'react';
import { IClearFormattingToolbarProps } from './types';

const MARK_KEYS: string[] = marks.map(
  (mark: LabstepMark) => mark.key,
);

export const clearFormatting = (
  state: EditorState,
  dispatch?: (tr: Transaction) => void,
): boolean => {
  let canClearFormatting = false;
  let { tr } = state;
  try {
    MARK_KEYS.forEach((markKey: string) => {
      const { from, to } = tr.selection;
      const markType = state.schema.marks[markKey];
      if (markType && state.doc.rangeHasMark(from, to, markType)) {
        canClearFormatting = true;
        if (dispatch) {
          tr = tr.removeMark(from, to, markType);
        }
      }
    });

    if (dispatch) {
      tr.setStoredMarks([]);
      dispatch(tr);
    }
  } catch (e) {
    bugsnagService.notify(e);
    return false;
  }

  return canClearFormatting;
};

const ClearFormattingToolbar: React.FC<
  IClearFormattingToolbarProps
> = ({ state, dispatch }) => (
  <div
    style={{ display: 'flex' }}
    onClick={(e) => e.preventDefault()}
    onMouseDown={(e) => e.preventDefault()}
  >
    <ToolbarButton
      icon="eraser"
      popup="Clear formatting"
      disabled={!clearFormatting(state)}
      onClick={() => {
        clearFormatting(state, dispatch);
      }}
    />
  </div>
);

export default ClearFormattingToolbar;
