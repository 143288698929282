/**
 * Labstep
 *
 * @module containers/UpdateOrCreate
 * @desc Uses either UpdateEntity or CreateEntity container
 * dependeing on props passed
 */

import { EntityCreateContainer } from 'labstep-web/containers/Entity/Create';
import { EntityUpdateContainer } from 'labstep-web/containers/Entity/Update';
import React from 'react';
import { UpdateOrCreateProps } from './types';

const UpdateOrCreate: React.FC<UpdateOrCreateProps> = ({
  children,
  createProps,
  updateProps,
  entityName,
  optimistic = true,
}) =>
  createProps ? (
    <EntityCreateContainer
      entityName={entityName}
      parentName={createProps && createProps.parentName}
      parentId={createProps && createProps.parentId}
    >
      {({ create, status }) =>
        children({
          action: (body, options) =>
            create({ ...createProps.createBody, ...body }, options),
          status,
        })
      }
    </EntityCreateContainer>
  ) : (
    <EntityUpdateContainer
      entityName={entityName}
      id={updateProps.id}
      optimistic={optimistic}
    >
      {({ update, status }) => children({ action: update, status })}
    </EntityUpdateContainer>
  );

export default UpdateOrCreate;
