/**
 * Labstep
 *
 * @module components/Folder/Action/Add
 * @desc Action to add parentEntity to folder
 */

import EntityActionAddSearch from 'labstep-web/components/Entity/Action/AddSearch';
import { ICONS } from 'labstep-web/constants/icons';
import { Folder } from 'labstep-web/models/folder.model';
import React from 'react';
import { FolderActionAddProps } from './types';

const FolderActionAdd: React.FC<FolderActionAddProps> = ({
  parentEntity,
}) => (
  <EntityActionAddSearch
    entityName={Folder.entityName}
    parentEntity={parentEntity}
    actionComponentProps={{
      type: 'option',
      icon: ICONS.folder.primary,
    }}
    options={{ toast: true }}
    searchSelectProps={{
      labelKey: 'folder_path',
      extraParams: { count: 50 },
    }}
  />
);

export default FolderActionAdd;
