/**
 * Labstep
 */

import { CanProps } from 'labstep-web/components/Entity/Can/types';
import { TOP_LEVEL_PERMISSION_ENTITIES_BY_ENTITY_NAME } from 'labstep-web/constants/permissions';
import {
  PERMISSION_ROLE_EDITOR,
  PERMISSION_ROLE_OWNER,
  PERMISSION_ROLE_VIEWER,
} from 'labstep-web/constants/roles';
import { PermissionRole } from 'labstep-web/models/permission-role.model';
import bugsnagService from 'labstep-web/services/bugsnag.service';
import { getIdAttribute } from 'labstep-web/services/schema/helpers';

export const selectLoggedUserActiveGroupPermissionRole = (
  state: any,
): PermissionRole | null => {
  if (!state.activeGroupId) {
    return null;
  }
  const group = state.entities.group.byId[state.activeGroupId];
  if (!group) {
    return null;
  }
  const userGroup =
    state.entities.user_group.byId[group.logged_user_user_group];
  // Admin of org that doesn't belong to a group
  if (!group.logged_user_user_group) {
    return null;
  }
  let role = PERMISSION_ROLE_EDITOR;
  const permissionRole =
    state.entities.permission_role.byId[userGroup.permission_role];

  if (userGroup.type === 'owner') {
    role = PERMISSION_ROLE_OWNER;
  } else if (userGroup.type === 'view') {
    role = PERMISSION_ROLE_VIEWER;
  } else if (permissionRole) {
    role = permissionRole;
  }

  return new PermissionRole(role);
};

export const selectPermissionEntity = (
  state: any,
  entityName: CanProps['entityName'],
  id: CanProps['id'] | CanProps['id'][],
) => {
  const permissionEntitySelector = (entityId: string | number) => {
    try {
      const isTopLevelPermissionEntity =
        TOP_LEVEL_PERMISSION_ENTITIES_BY_ENTITY_NAME.includes(
          entityName,
        );
      let permissionEntityInfo;
      let lockEntityInfo;
      if (isTopLevelPermissionEntity) {
        permissionEntityInfo = {
          entityName,
          [getIdAttribute(entityName)]: entityId,
        };
      } else if (state.entities[entityName].byId[entityId]) {
        permissionEntityInfo =
          state.entities[entityName].byId[entityId]
            .permission_entity_info;
        lockEntityInfo =
          state.entities[entityName].byId[entityId].lock_entity_info;
      }

      if (!permissionEntityInfo) {
        return {
          ...state.entities.group.byId[state.activeGroupId],
          entityName: 'group',
        };
      }

      if (Array.isArray(permissionEntityInfo)) {
        return permissionEntityInfo.map((permissionEntity) => {
          return {
            ...state.entities[permissionEntity.entityName].byId[
              permissionEntity[
                getIdAttribute(permissionEntity.entityName)
              ]
            ],
            entityName: permissionEntity.entityName,
          };
        });
      }

      const entity = {
        ...state.entities[permissionEntityInfo.entityName].byId[
          permissionEntityInfo[
            getIdAttribute(permissionEntityInfo.entityName)
          ]
        ],
        entityName: permissionEntityInfo.entityName,
      };

      // Special case. Experiment\ExperimentWorklfow is the only case
      // that locked info and permissions info are held by different entities
      if (
        lockEntityInfo &&
        lockEntityInfo.entityName === 'experiment'
      ) {
        entity.allowed_actions_lock =
          state.entities.experiment.byId[
            lockEntityInfo.id
          ].allowed_actions_lock;
      }
      return entity;
    } catch (e) {
      bugsnagService.notify(e, {
        entityName,
        entityId,
      });
      return null;
    }
  };

  if (Array.isArray(id)) {
    return id.reduce(
      (result, i) => ({
        ...result,
        [i]: permissionEntitySelector(i),
      }),
      {},
    );
  }
  return permissionEntitySelector(id);
};

export const selectActiveGroup = (
  state: any,
): Record<string, unknown> | null => {
  if (!state.activeGroupId) {
    return null;
  }
  return state.entities.group.byId[state.activeGroupId];
};

export const selectIsLocked = (
  state: any,
  entityName: any,
  id: any,
) => {
  try {
    let entity = state.entities[entityName].byId[id];

    if (entityName === 'resource' && !entity) {
      entity = state.entities.resource_template.byId[id];
    }

    // Special case to prevent non authors from editing
    if (
      entityName === 'comment'
      // || entityName === 'device_booking'
    ) {
      if (entity.author !== state.authenticatedUser.byId.id) {
        return true;
      }
    }

    if (!entity || !entity.lock_entity_info) {
      return false;
    }

    const lockEntityInfo = entity.lock_entity_info;
    if (!lockEntityInfo) {
      return false;
    }
    const lockEntity =
      state.entities[lockEntityInfo.entityName].byId[
        lockEntityInfo.id
      ];

    if (!lockEntity) {
      return false;
    }
    return !!lockEntity.locked_at;
  } catch (e) {
    bugsnagService.notify(e, {
      entityName,
      id,
    });
    return false;
  }
};
