/**
 * Labstep
 *
 * @module components/Protocol/Form/ShowEdit/State/ToolbarRight
 * @desc ToolbarRight
 */

import { withProseMirror } from 'labstep-web/containers/ProseMirror';
import { ProseMirrorStatus } from 'labstep-web/containers/ProseMirror/types';
import ActionComponent from 'labstep-web/core/Action/Component';
import AutosaveSpinnerProseMirror from 'labstep-web/core/AutoSave/Spinner/ProseMirror';
import React from 'react';
import { IProtocolFormShowEditStateToolbarRightProps } from './types';

export const ProtocolFormShowEditStateToolbarRight: React.FC<
  IProtocolFormShowEditStateToolbarRightProps
> = ({ proseMirrorStatus, setViewMode }) => (
  <>
    <AutosaveSpinnerProseMirror />

    <ActionComponent
      dataCy="components/Protocol/Form/ShowEdit/State/ToolbarRight:Done"
      type="text"
      text="Done"
      icon="check"
      disabled={proseMirrorStatus === ProseMirrorStatus.debouncing}
      onClick={setViewMode}
    />
  </>
);

export default withProseMirror(ProtocolFormShowEditStateToolbarRight);
