/**
 * Labstep
 *
 * @module prosemirror/cursor
 * @desc Method to return ProseMirror cursor position on the screen
 */

import bugsnagService from 'labstep-web/services/bugsnag.service';
import { isEqual } from 'lodash';
import { EditorState } from 'prosemirror-state';
import { EditorView } from 'prosemirror-view';

export interface IProseMirrorCursorPosition {
  top: number;
  left: number;
}

/**
 *
 * @param from Postion
 * @param view Prosemirror view
 * @returns  object with top and left coords
 */
export const computeNodePosition = (
  from: number,
  view: EditorView | null,
): IProseMirrorCursorPosition => {
  if (!view) {
    return {
      top: 0,
      left: 0,
    };
  }
  try {
    const coords = view.coordsAtPos(from);
    return {
      top: coords.top,
      left: coords.left,
    };
  } catch (e) {
    bugsnagService.notify(e, {}, 'info');
    return {
      top: 0,
      left: 0,
    };
  }
};

/**
 *
 * @param state Prosemirror state
 * @param view Prosemirror view
 * @returns object with top and left coords
 */
export const computeCursorPosition = (
  state: EditorState | null,
  view: EditorView | null,
): IProseMirrorCursorPosition => {
  if (!state || !isEqual(view?.state, state)) {
    return {
      top: 0,
      left: 0,
    };
  }

  const { from } = state.selection;

  return computeNodePosition(from, view);
};
