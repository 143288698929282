/**
 * Labstep
 *
 * @module components/Metadata/Search/EmptyState
 * @desc Metadata Search EmptyState
 */

import ExperimentWorkflowActionCreate from 'labstep-web/components/ExperimentWorkflow/Action/Create';
import EmptyState from 'labstep-web/core/Card/EmptyState';
import { ProtocolValue } from 'labstep-web/models/protocol-value.model';

export default () => (
  <EmptyState
    title="No data"
    explanation={`Link your data to ${ProtocolValue.getHumanReadableEntityName(
      true,
    )} in your protocols and experiments to have it appear here automatically.`}
    src="/img/empty-state/data.svg"
    action={<ExperimentWorkflowActionCreate />}
  />
);
