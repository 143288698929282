/**
 * Labstep
 *
 * @module components/Entity/QRCode
 * @desc QRCode for entity with a perma link
 */

import QRCode from 'labstep-web/core/QRCode';
import React from 'react';
import { EntityQRCodeProps } from './types';

const EntityQRCode: React.FC<EntityQRCodeProps> = ({ entity }) => (
  <QRCode
    name={entity.name || undefined}
    value={entity.perma_link.url}
  />
);

export default EntityQRCode;
