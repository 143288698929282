/**
 * Labstep
 *
 * @module components/DeviceData/Manager
 * @desc DeviceData manager
 */

import DeviceDataEmptyState from 'labstep-web/components/DeviceData/EmptyState';
import DeviceDataPreview from 'labstep-web/components/DeviceData/Preview';
import EntitySearch from 'labstep-web/components/Entity/Search';
import List from 'labstep-web/core/List';
import { DeviceData } from 'labstep-web/models/device-data.model';
import React from 'react';
import styles from './styles.module.scss';
import { DeviceDataManagerProps } from './types';

const DeviceDataManager: React.FC<DeviceDataManagerProps> = ({
  device,
}) => (
  <div className={styles.container}>
    <EntitySearch
      entityName={DeviceData.entityName}
      params={{ device_id: device.id }}
      searchBarProps={{ placeholder: 'Search collected data' }}
      noResultsMessage={<DeviceDataEmptyState />}
    >
      {({ entities: deviceDatas }) => (
        <List>
          {deviceDatas.map((deviceData: DeviceData) => (
            <DeviceDataPreview
              key={deviceData.id}
              deviceData={deviceData}
            />
          ))}
        </List>
      )}
    </EntitySearch>
  </div>
);

export default DeviceDataManager;
