/**
 * Labstep
 *
 * @module components/Entity/Action/Share/Bulk
 * @desc Bulk Share
 */

import { useHasAccessMultiple } from 'labstep-web/components/Entity/Can/hooks';
import { Action } from 'labstep-web/components/Entity/Can/types';
import { EntityCreateContainer } from 'labstep-web/containers/Entity/Create';
import ActionComponentBulk from 'labstep-web/core/Action/Component/Bulk';
import FormReusable from 'labstep-web/core/Form/Reusable';
import {
  FieldType,
  FieldWithoutActionProps,
  IFieldProps,
} from 'labstep-web/core/Form/Reusable/types';
import Modal from 'labstep-web/core/Modal/Default';
import { PermissionMenu } from 'labstep-web/core/Permission';
import { TopLevelPermissionEntity } from 'labstep-web/models';
import { Group } from 'labstep-web/models/group.model';
import {
  Permission,
  PermissionType,
} from 'labstep-web/models/permission.model';
import React, { useState } from 'react';
import { mixed } from 'yup';
import { EntityActionShareBulkProps } from './types';

export const groupField: FieldWithoutActionProps = {
  name: 'group',
  elementProps: {
    entityName: 'group',
  },
  fieldType: FieldType.SearchSelect,
  fieldLabel: `Select ${Group.getHumanReadableEntityName()}`,
  validation: mixed().required(),
};

export const EntityActionShareBulk: React.FC<
  EntityActionShareBulkProps
> = ({ entities, entityName }) => {
  const [groupPermission, setGroupPermission] = useState(
    PermissionType.view,
  );

  const entityIdsAccessObject = useHasAccessMultiple(
    entityName,
    entities ? entities.map((e) => e.id) : [],
    Action.share,
  );

  if (!entities || entities.length === 0) {
    return null;
  }

  const entitiesWithPermission = entities.filter(
    (e) => entityIdsAccessObject[e.id],
  );

  const fields: IFieldProps[] = [
    groupField,
    {
      fieldType: FieldType.action,
      component: () => (
        <div
          style={{
            width: '100%',
            marginBottom: '10px',
          }}
        >
          <label>Permission level</label>
          <PermissionMenu
            type={groupPermission}
            onClick={({ type }) => setGroupPermission(type)}
          />
        </div>
      ),
    },
  ];

  return (
    <Modal
      size="small"
      header="Share"
      viewComponent={({ toggleModal }) => (
        <ActionComponentBulk
          entitiesWithPermissionCount={entitiesWithPermission.length}
          entitiesSelectedCount={entities.length}
          onClick={toggleModal}
          content="Share"
          icon="share alternate"
        />
      )}
      content={({ toggleModal }) => (
        <EntityCreateContainer
          entityName={Permission.entityName}
          batch
        >
          {({ create, status }) => (
            <div>
              <FormReusable
                submitButtonText="Share"
                onCancel={toggleModal}
                status={status}
                onSubmit={(values: Record<string, unknown>) => {
                  const entityGuidField = `${entityName}_id`;
                  create(
                    entitiesWithPermission.map(
                      (e: TopLevelPermissionEntity) => ({
                        type: groupPermission,
                        [entityGuidField]: e.guid,
                        group_id: values.group_id,
                      }),
                    ),
                    {
                      onSuccess: toggleModal,
                      toast: true,
                      meta: {
                        bulkShare: true,
                      },
                    },
                  );
                }}
                fields={fields}
              />
            </div>
          )}
        </EntityCreateContainer>
      )}
    />
  );
};

export default EntityActionShareBulk;
