/**
 * Labstep
 *
 * @module components/Protocol/Action/Delete
 * @desc Delete resource location
 */

import EntityActionDelete from 'labstep-web/components/Entity/Action/Delete';
import { navigation } from 'labstep-web/services/navigation';
import React from 'react';
import { IProtocolActionDeleteProps } from './types';

const ProtocolActionDelete: React.FC<IProtocolActionDeleteProps> = ({
  protocol,
}) =>
  protocol.versionNumber > 1 ? (
    <EntityActionDelete
      entity={protocol}
      options={{
        onSuccess: () => {
          window.location.href = navigation.get(
            'protocol_collection_show',
            {
              id: protocol.collection.id,
            },
            true,
          );
        },
      }}
      skipConfirmation
      actionComponentProps={{
        type: 'text',
        icon: 'trash',
        text: 'Abandon Draft',
        modalConfirmationProps: {
          header: 'Abandon Draft',
          message:
            'Are you sure you want to abandon this draft? Abandoned drafts can be restored from the version history.',
        },
      }}
    />
  ) : null;

export default ProtocolActionDelete;
