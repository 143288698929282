/**
 * Labstep
 *
 * @module components/ExperimentWorkflow/Card
 * @desc ExperimentWorkflow Card
 */

import ExperimentWorkflowActionMenu from 'labstep-web/components/ExperimentWorkflow/Action/Menu';
import GenericCard from 'labstep-web/core/Card/Generic';
import React from 'react';
import SubTitle from './SubTitle';
import { ExperimentWorkflowCardProps } from './types';

const ExperimentWorkflowCard: React.FC<
  ExperimentWorkflowCardProps
> = ({ experimentWorkflow, actions, createdBy, mainActionType }) => (
  <GenericCard
    headerTitle={{
      to: 'experiment_workflow_show',
      params: { id: experimentWorkflow.id },
      title: experimentWorkflow.displayName,
      disabled: mainActionType === 'delete',
    }}
    customSubtitle={
      <SubTitle
        createdBy={createdBy}
        experimentWorkflow={experimentWorkflow}
      />
    }
    actionMenu={
      actions && (
        <ExperimentWorkflowActionMenu
          experimentWorkflow={experimentWorkflow}
          actions={actions}
        />
      )
    }
  />
);

export default ExperimentWorkflowCard;
