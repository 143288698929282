/**
 * Labstep
 */

import { useContext } from 'react';
import BulkSelectContext from '.';
import { BulkSelectContainerChildrenProps } from './types';

export const useBulkSelectContext =
  (): BulkSelectContainerChildrenProps => {
    const context = useContext(BulkSelectContext);
    if (!context) {
      throw new Error(
        'useBulkSelectContext must be used within a BulkSelectContextProvider',
      );
    }
    return context;
  };
