/**
 * Labstep
 *
 * @module components/ExperimentWorkflow/Action/Lock/Bulk
 * @desc Action to update is_urgent field
 */

import { EntityActionLock } from 'labstep-web/components/Entity/Action/Lock';
import { useHasAccessMultiple } from 'labstep-web/components/Entity/Can/hooks';
import { Action } from 'labstep-web/components/Entity/Can/types';
import { ICONS } from 'labstep-web/constants/icons';
import BulkActionComponent from 'labstep-web/core/Action/Component/Bulk';
import Dropdown from 'labstep-web/core/Dropdown';
import { ExperimentWorkflow } from 'labstep-web/models/experiment-workflow.model';
import React from 'react';
import { ExperimentWorkflowActionLockBulkProps } from './types';

const ExperimentWorkflowActionLockBulk: React.FC<
  ExperimentWorkflowActionLockBulkProps
> = ({ entityName, entities, options }) => {
  const entityIdsForLockAccessObject = useHasAccessMultiple(
    entityName,
    entities
      ? entities.filter((e) => !e.locked_at).map((e) => e.id)
      : [],
    Action.lock,
  );

  const entityIdsForUnlockAccessObject = useHasAccessMultiple(
    entityName,
    entities
      ? entities.filter((e) => !!e.locked_at).map((e) => e.id)
      : [],
    Action.unlock,
  );

  if (!entities || entities.length === 0) {
    return null;
  }

  const entitiesForLockWithPermission = entities.filter(
    (e) => entityIdsForLockAccessObject[e.id],
  );

  const experimentWorkflowForLockId =
    entitiesForLockWithPermission.map((e) => e.id);

  const entitiesForUnlockWithPermission = entities.filter(
    (e) => entityIdsForUnlockAccessObject[e.id],
  );

  const experimentWorkflowForUnlockId =
    entitiesForUnlockWithPermission.map((e) => e.id);

  return (
    <Dropdown.Dropdown
      disabled
      trigger={
        <BulkActionComponent
          entitiesSelectedCount={entities.length}
          entitiesWithPermissionCount={Math.max(
            experimentWorkflowForLockId.length,
            experimentWorkflowForUnlockId.length,
          )}
          onClick={undefined}
          content="Lock/Unlock"
          icon={ICONS.experiment_workflow.actions?.lock}
        />
      }
      icon={null}
    >
      <Dropdown.Menu>
        <EntityActionLock
          entityName={ExperimentWorkflow.entityName}
          id={experimentWorkflowForLockId}
          options={options}
          locked={false}
        />
        <EntityActionLock
          entityName={ExperimentWorkflow.entityName}
          id={experimentWorkflowForUnlockId}
          locked
          options={options}
        />
      </Dropdown.Menu>
    </Dropdown.Dropdown>
  );
};

export default ExperimentWorkflowActionLockBulk;
