/**
 * Labstep
 *
 * @module components/Protocol/Action/Run
 * @desc Run a protocol
 */

import ExperimentWorkflowActionCreate from 'labstep-web/components/ExperimentWorkflow/Action/Create';
import { ActionComponentProps } from 'labstep-web/core/Action/Component/types';
import React from 'react';
import { IProtocolActionRunProps } from './types';

const ProtocolActionRun: React.FC<IProtocolActionRunProps> = ({
  protocol,
}) => {
  if (protocol.is_draft) {
    return null;
  }
  let actionComponentProps: ActionComponentProps = {
    type: 'button',
    icon: 'play',
    showIcon: true,
    elementProps: {
      size: 'small',
      basic: true,
      primary: true,
    },
    text: 'Run',
  };
  if (!protocol.is_last_version) {
    actionComponentProps = {
      ...actionComponentProps,
      modalConfirmationProps: {
        header: 'Superseded Protocol',
        message:
          'There is a more recent version of this protocol. Are you sure you want to run this version?',
        positive: false,
        confirmButtonContent: 'Run Superseded Protocol',
      },
    };
  }
  return (
    <ExperimentWorkflowActionCreate
      actionComponentProps={actionComponentProps}
      protocol={protocol}
    />
  );
};

export default ProtocolActionRun;
