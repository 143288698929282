/**
 * Labstep
 *
 * @module grid/Report/components/Filter/Date/Active/Post
 * @desc Active node component for date
 */

import { FilterPostActiveLabelProps } from 'labstep-web/components/Filter/Post/Active/Label/types';
import { useParamsContext } from 'labstep-web/contexts/params/hook';
import RemoveLabel from 'labstep-web/core/Label/Remove';
import GridReportFilterDate from 'labstep-web/grid/Report/components/Filter/Date';
import { PostFilterComparison } from 'labstep-web/services/query-parameter.service';
import React from 'react';
import { getPostFilterDate } from '../../Form';
import { COMPARISON_OPTIONS } from '../../Form/fields';

// eslint-disable-next-line no-shadow
export enum PostFilterComparisonHumanReadable {
  lt = 'Before',
  gt = 'After',
  not_null = 'Has value',
}

const FilterDateActivePost: React.FC<FilterPostActiveLabelProps> = ({
  node,
  onRemoveClick,
  index,
}) => {
  const DATE_FILTERS = [
    getPostFilterDate(
      'experiment.experimentWorkflow',
      'created_at',
      PostFilterComparison.not_null,
    ),
    getPostFilterDate(
      'experiment.experimentWorkflow',
      'started_at',
      PostFilterComparison.not_null,
    ),
    getPostFilterDate(
      'experiment.experimentWorkflow',
      'ended_at',
      PostFilterComparison.not_null,
    ),
    getPostFilterDate(
      'experiment',
      'started_at',
      PostFilterComparison.not_null,
    ),
    getPostFilterDate(
      'experiment',
      'ended_at',
      PostFilterComparison.not_null,
    ),
  ];
  const { addPostFilter } = useParamsContext();
  const filterActive = DATE_FILTERS.find((filter) =>
    filter.isNodeActive(node),
  );
  if (!filterActive) {
    return null;
  }

  const path = filterActive.getPath() || '';
  const attributes = filterActive.getAttributeValues(node);

  const label = filterActive.getHumanReadableString(node) || '';
  const field = Object.keys(attributes)[0];
  const { comparison } = Object.values(attributes)[0];
  const value =
    comparison === PostFilterComparison.not_null
      ? ''
      : Object.values(attributes)[0].value;

  const defaultValues = {
    comparison: COMPARISON_OPTIONS.find(
      (option) => option.value === comparison,
    ),
    value,
  };

  const postFilterParameters = {
    label,
    path,
    field,
  };

  return (
    <GridReportFilterDate
      postFilterParameters={postFilterParameters}
      addPostFilter={addPostFilter}
      defaultValues={defaultValues}
      viewComponent={({ toggleModal }) => (
        <RemoveLabel
          name={filterActive.getHumanReadableStringWithValue(node)}
          onNameClick={toggleModal}
          onRemoveClick={onRemoveClick}
        />
      )}
      replace={index}
    />
  );
};

export default FilterDateActivePost;
