/**
 * Labstep
 *
 * @module components/Organization/Activity
 * @desc Organization Activity
 */

import React from 'react';
import { OrganizationActivityProps } from './types';

export const OrganizationActivity: React.FC<
  OrganizationActivityProps
> = ({ organization }) => (
  <iframe
    title="Organization activity"
    src={organization.metabase_url}
    frameBorder="0"
    width="100%"
    height="1000"
  />
);

export default OrganizationActivity;
