/**
 * Labstep
 *
 * @module grid/Report/components/ToolPanel/ProtocolValue
 * @desc Hardcoded list of columns for experiment fields
 */

import EntitySearchMulti from 'labstep-web/components/Entity/Search/Multi';
import { ICONS } from 'labstep-web/constants/icons';
import { ActionComponent } from 'labstep-web/core/Action/Component';
import Flex from 'labstep-web/core/Flex';
import Header, { SectionHeader } from 'labstep-web/core/Header';
import {
  getMetadataResourceColDef,
  getMetadataResourceItemColDef,
  getProtocolValueFieldColumnDef,
  getProtocolValueResourceItemNameColumnDef,
  getProtocolValueResourceLocationPathColumnDef,
  getProtocolValueResourceNameColumnDef,
} from 'labstep-web/grid/Report/coldefs';
import { ToolPanelState } from 'labstep-web/grid/Report/components/ToolPanel';
import EntityDataGridToolPanelListItem from 'labstep-web/grid/Report/components/ToolPanel/ListItem';
import { GridReportService } from 'labstep-web/grid/Report/services/grid-report.service';
import { Metadata } from 'labstep-web/models/metadata';
import { ResourceItem } from 'labstep-web/models/resource-item.model';
import { Resource } from 'labstep-web/models/resource.model';
import React from 'react';
import { IEntityDataGridToolPanelProtocolValueProps } from './types';

export const EntityDataGridToolPanelProtocolValue: React.FC<
  IEntityDataGridToolPanelProtocolValueProps
> = ({
  entityView,
  gridReportService,
  setToolPanelState,
  protocolValue,
  colIds,
}) => {
  if (!protocolValue.name) {
    return null;
  }

  const protocolValueAmountColDef = getProtocolValueFieldColumnDef(
    protocolValue.is_input,
    protocolValue.name,
    'amount',
  );
  const protocolValueResourceNameColDef =
    getProtocolValueResourceNameColumnDef(
      protocolValue.is_input,
      protocolValue.name,
    );
  const protocolValueResourceItemNameColDef =
    getProtocolValueResourceItemNameColumnDef(
      protocolValue.is_input,
      protocolValue.name,
    );
  const protocolValueResourceLocationPathColumnDef =
    getProtocolValueResourceLocationPathColumnDef(
      protocolValue.is_input,
      protocolValue.name,
    );

  return (
    <div>
      <Flex vAlignContent="center" spacing="gap" shrink={0}>
        <ActionComponent
          type="icon"
          icon="arrow left"
          text="Go Back"
          onClick={() => setToolPanelState(ToolPanelState.add_column)}
        />
        <Header size="tiny" noMargin>
          Add Column for {protocolValue.name}
        </Header>
      </Flex>
      <small>
        Now you must select which information you wish to display
      </small>
      <EntitySearchMulti
        staticLists={(searchQuery) => [
          <Flex column key="static">
            {GridReportService.showListItem(
              'amount',
              searchQuery,
            ) && (
              <EntityDataGridToolPanelListItem
                icon={ICONS.protocol_value.info.amount_used}
                actionIcon="eye"
                text="Amount"
                onClick={() => {
                  gridReportService.addColumnDef(
                    protocolValueAmountColDef,
                  );
                }}
                disabled={
                  protocolValueAmountColDef.colId
                    ? colIds.includes(protocolValueAmountColDef.colId)
                    : false
                }
              />
            )}
            {GridReportService.showListItem(
              'resource',
              searchQuery,
            ) && (
              <EntityDataGridToolPanelListItem
                icon={ICONS.resource.primary}
                actionIcon="eye"
                text="Resource"
                onClick={() => {
                  gridReportService.addColumnDef(
                    protocolValueResourceNameColDef,
                  );
                }}
                disabled={
                  protocolValueResourceNameColDef.colId
                    ? colIds.includes(
                        protocolValueResourceNameColDef.colId,
                      )
                    : false
                }
              />
            )}
            {GridReportService.showListItem('item', searchQuery) && (
              <EntityDataGridToolPanelListItem
                icon={ICONS.resource_item.primary}
                actionIcon="eye"
                text="Item"
                onClick={() => {
                  gridReportService.addColumnDef(
                    protocolValueResourceItemNameColDef,
                  );
                }}
                disabled={
                  protocolValueResourceItemNameColDef.colId
                    ? colIds.includes(
                        protocolValueResourceItemNameColDef.colId,
                      )
                    : false
                }
              />
            )}
            {GridReportService.showListItem(
              'location',
              searchQuery,
            ) && (
              <EntityDataGridToolPanelListItem
                icon={ICONS.resource_location.primary}
                actionIcon="eye"
                text="Location"
                onClick={() => {
                  gridReportService.addColumnDef(
                    protocolValueResourceLocationPathColumnDef,
                  );
                }}
                disabled={
                  protocolValueResourceLocationPathColumnDef.colId
                    ? colIds.includes(
                        protocolValueResourceLocationPathColumnDef.colId,
                      )
                    : false
                }
              />
            )}
          </Flex>,
        ]}
        lists={[
          {
            key: ResourceItem.entityName,
            entityName: Metadata.entityName,
            searchField: 'label_like',
            params: {
              permission_class: ResourceItem.entityName,
              group_id: entityView.group.id,
              group_by_label: 1,
              sort: 'label',
              count: 3,
              serializerGroups:
                'protocol_condition_reporting,paginator',
            },
            header: <SectionHeader label="Item Metadata Fields" />,
            listItem: (entity: object) => {
              if (!protocolValue.name) {
                return <p></p>;
              }
              const colDef = getMetadataResourceItemColDef(
                protocolValue.name,
                (entity as Metadata).type,
                (entity as Metadata).label || '',
              );
              return (
                <EntityDataGridToolPanelListItem
                  icon={ICONS.metadata.secondary}
                  actionIcon="eye"
                  text={
                    (entity as Metadata).label ||
                    'Untitled data field'
                  }
                  onClick={() => {
                    gridReportService.addColumnDef(colDef);
                  }}
                  disabled={colIds.includes(colDef.colId)}
                />
              );
            },
          },
          {
            key: Resource.entityName,
            entityName: Metadata.entityName,
            searchField: 'label_like',
            params: {
              permission_class: Resource.entityName,
              group_id: entityView.group.id,
              group_by_label: 1,
              sort: 'label',
              count: 3,
              serializerGroups:
                'protocol_condition_reporting,paginator',
            },
            header: (
              <SectionHeader label="Resource Metadata Fields" />
            ),
            listItem: (entity: object) => {
              if (!protocolValue.name) {
                return <p></p>;
              }
              const colDef = getMetadataResourceColDef(
                protocolValue.name,
                (entity as Metadata).type,
                (entity as Metadata).label || '',
              );
              return (
                <EntityDataGridToolPanelListItem
                  icon={ICONS.metadata.secondary}
                  actionIcon="eye"
                  text={
                    (entity as Metadata).label ||
                    'Untitled data field'
                  }
                  onClick={() => {
                    gridReportService.addColumnDef(colDef);
                  }}
                  disabled={colIds.includes(colDef.colId)}
                />
              );
            },
          },
        ]}
      />
    </div>
  );
};

export default EntityDataGridToolPanelProtocolValue;
