/**
 * Labstep
 *
 * @module screens/ProtocolCollection/Show/Center
 * @desc Center Component of Protocol Show
 * Shows alert if the user cannot edit this version
 */

import EntityPrimaryInfo from 'labstep-web/components/Entity/PrimaryInfo';
import EntityUserCard from 'labstep-web/components/EntityUser/Card';
import FolderManager from 'labstep-web/components/Folder/Manager';
import ProtocolActionCreate from 'labstep-web/components/Protocol/Action/Create';
import ProtocolActionDelete from 'labstep-web/components/Protocol/Action/Delete';
import ProtocolActionRun from 'labstep-web/components/Protocol/Action/Run';
import ProtocolActionUpdateIsDraft from 'labstep-web/components/Protocol/Action/UpdateIsDraft';
import ProtocolChangelog from 'labstep-web/components/Protocol/Changelog';
import ProtocolFormShowEditState from 'labstep-web/components/Protocol/Form/ShowEdit/State/Container';
import ProtocolLabelStatus from 'labstep-web/components/Protocol/Label/Status';
import ProtocolCollectionActionMenu from 'labstep-web/components/ProtocolCollection/Action/Menu';
import { Action } from 'labstep-web/components/ProtocolCollection/Action/Menu/types';
import TagManager from 'labstep-web/components/Tag/Manager';
import { ICONS } from 'labstep-web/constants/icons';
import InformationCard from 'labstep-web/core/Card/Information';
import Flex from 'labstep-web/core/Flex';
import Link from 'labstep-web/core/Link';
import Message from 'labstep-web/core/Message';
import React from 'react';
import styles from './styles.module.scss';
import { ICenterProps } from './types';

const ProtocolCollectionShowCenter: React.FC<ICenterProps> = ({
  protocolCollection,
}) => {
  let banner;
  const protocol = protocolCollection.last_or_draft_version;
  if (protocol.is_draft) {
    banner = (
      <Message
        children={
          <div className={styles.banner}>
            <span>
              You are viewing a draft version of this protocol
            </span>
            {protocolCollection.last_version && (
              <Link
                to="protocol_show"
                params={{ id: protocolCollection.last_version?.id }}
              >
                View Live Version
              </Link>
            )}
          </div>
        }
      />
    );
  }
  return (
    <div className={styles.container}>
      <InformationCard
        additionalActions={
          <>
            <EntityUserCard entity={protocolCollection} />
            <FolderManager parentEntity={protocolCollection} />
            <TagManager parentEntity={protocolCollection} />
          </>
        }
        actionMenu={
          <ProtocolCollectionActionMenu
            protocolCollection={protocolCollection}
            actions={[
              Action.add_to_folder,
              Action.tag,
              Action.add_to_experiment,
              Action.duplicate,
              Action.print,
              Action.delete,
              Action.share,
              Action.transfer_ownership,
              Action.allowed_actions_lock_settings,
              Action.reporting,
            ]}
          />
        }
        primaryInfo={
          <EntityPrimaryInfo
            entity={protocolCollection}
            icon={ICONS.protocol_collection.primary}
          />
        }
        secondaryInfo={
          <Flex vAlignContent="center" wrap spacing="gap">
            <ProtocolChangelog
              protocol={protocol}
              protocolCollection={protocolCollection}
            />
            {protocol.is_draft ? (
              <Flex vAlignContent="center" spacing="gap">
                <ProtocolLabelStatus protocol={protocol} />
                <ProtocolActionUpdateIsDraft
                  protocol={protocol}
                  protocolCollection={protocolCollection}
                />
                <ProtocolActionDelete protocol={protocol} />
              </Flex>
            ) : (
              <>
                <ProtocolLabelStatus protocol={protocol} />
                <ProtocolActionCreate
                  parentName={protocolCollection.entityName}
                  parentId={protocolCollection.id}
                  actionComponentProps={{
                    type: 'text',
                    icon: 'edit',
                    text: 'Draft New Version',
                  }}
                />
              </>
            )}
          </Flex>
        }
        bottom={
          <>
            {banner}
            <Flex hAlignContent="right">
              <ProtocolActionRun protocol={protocol} />
            </Flex>
            <ProtocolFormShowEditState
              protocolCollection={protocolCollection}
              protocol={protocol}
            />
          </>
        }
      />
    </div>
  );
};

export default ProtocolCollectionShowCenter;
