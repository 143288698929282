/**
 * Labstep
 */

import { ActionMenuProps } from 'labstep-web/core/Action/Menu/types';
import { EntityUserRoleRequirement } from 'labstep-web/models/entity-user-role-requirement.model';

// eslint-disable-next-line no-shadow
export enum Action {
  delete = 'delete',
}

export interface EntityUserRoleRequirementActionMenuProps
  extends Pick<
    ActionMenuProps,
    Exclude<keyof ActionMenuProps, 'children'>
  > {
  entityUserRoleRequirement: EntityUserRoleRequirement;
  actions?: Action[];
}
