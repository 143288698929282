/**
 * Labstep
 *
 * @module prosemirror/components/Toolbar/ProtocolValue
 * @desc Button to insert an ProtocolValue
 */

import ProtocolValueActionCreate from 'labstep-web/components/ProtocolValue/Action/Create';
import { ICONS } from 'labstep-web/constants/icons';
import NodeProtocolValue from 'labstep-web/prosemirror/nodes/protocol-value';
import { canAddElement } from 'labstep-web/prosemirror/utils';
import React from 'react';
import { IValueToolbarProps } from './types';

const ValueToolbar: React.FC<IValueToolbarProps> = ({
  protocol,
  view,
}) => (
  <ProtocolValueActionCreate
    protocol={protocol}
    options={{
      onSuccess: ({ response }) => {
        const { tr } = view.state;
        const { key } = NodeProtocolValue;
        const node = view.state.schema.nodes[key].create({
          id: response!.entities[key][response!.result as any].id,
          guid: response!.entities[key][response!.result as any].guid,
        });
        tr.setStoredMarks([]);
        tr.insert(view.state.selection.$to.pos, node);
        view.dispatch(tr);
        view.focus();
      },
    }}
    actionComponentProps={{
      text: 'Inventory',
      type: 'option',
      disabled: !canAddElement(view.state),
      icon: ICONS.protocol.value,
    }}
  />
);

export default ValueToolbar;
