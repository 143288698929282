/**
 * Labstep
 *
 * @module
 * @desc Toggle SAML for a user
 */

import { EntityUpdateContainer } from 'labstep-web/containers/Entity/Update';
import ActionComponent from 'labstep-web/core/Action/Component';
import { ActionComponentProps } from 'labstep-web/core/Action/Component/types';
import React from 'react';
import { IUserActionToggleSamlProps } from './types';

const UserActionToggleSaml: React.FC<IUserActionToggleSamlProps> = ({
  user,
  organization,
  actionComponentProps = {} as ActionComponentProps,
}) => {
  if (
    !organization.organization_saml ||
    organization.organization_saml?.is_enabled
  ) {
    return null;
  }
  const body = user.saml_id
    ? { saml_id: null, saml_provider: null }
    : {
        saml_id: user.username,
        saml_provider: organization.identifier,
      };

  return (
    <EntityUpdateContainer
      entityName={user.entityName}
      id={user.id}
      optimistic
    >
      {({ status, update }): React.ReactElement => {
        return (
          <ActionComponent
            status={status}
            onClick={(): void => update(body)}
            {...actionComponentProps}
            text={user.saml_id ? 'Disable SAML' : 'Enable SAML'}
            icon="sign in alternate"
          />
        );
      }}
    </EntityUpdateContainer>
  );
};

export default UserActionToggleSaml;
