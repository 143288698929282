/**
 * Labstep
 *
 * @module components/Folder/Breadcrumb/Item
 * @desc Breadcrumb Item for Folder
 */

import FolderFormShowEditName from 'labstep-web/components/Folder/Form/ShowEdit/Name';
import { EntityReadEntityContainer } from 'labstep-web/containers/Entity/Read/Entity';
import Desktop from 'labstep-web/core/MediaQuery/Desktop';
import { Folder } from 'labstep-web/models/folder.model';
import React from 'react';
import { FolderBreadcrumbItemProps } from './types';

export const FolderBreadcrumbItem: React.FC<
  FolderBreadcrumbItemProps
> = ({ searchParams }) => (
  <>
    {searchParams.folder_group_not_id && (
      <Desktop>
        {({ match }) =>
          match ? (
            <div style={{ minWidth: '200px' }}>
              <span>Unsorted</span>
            </div>
          ) : (
            <span>Unsorted</span>
          )
        }
      </Desktop>
    )}
    {searchParams.folder_id && (
      <EntityReadEntityContainer
        entityName={Folder.entityName}
        id={searchParams.folder_id as number}
      >
        {({ entity }) => (
          <div>
            {entity && (
              <Desktop>
                {({ match }) =>
                  match ? (
                    <div style={{ minWidth: '200px' }}>
                      <FolderFormShowEditName folder={entity} />
                    </div>
                  ) : (
                    <span>{entity.name}</span>
                  )
                }
              </Desktop>
            )}
          </div>
        )}
      </EntityReadEntityContainer>
    )}
  </>
);

export default FolderBreadcrumbItem;
