/**
 * Labstep
 *
 * @module components/ProtocolCondition/Action/Reporting/Metadata
 * @desc Open modal to compare data
 */

import ProtocolConditionExplorer from 'labstep-web/components/ProtocolCondition/Explorer';
import { ICONS } from 'labstep-web/constants/icons';
import { ActionComponent } from 'labstep-web/core/Action/Component';
import ModalDefault from 'labstep-web/core/Modal/Default';
import React from 'react';
import { ProtocolConditionActionCompareDataMetadataProps } from './types';

export const ProtocolConditionActionCompareDataMetadata: React.FC<
  ProtocolConditionActionCompareDataMetadataProps
> = ({ group, metadata, actionComponentType }) => {
  return (
    <ModalDefault
      header="Data Explorer"
      size="fullscreen"
      viewComponent={({ toggleModal }) => (
        <ActionComponent
          onClick={toggleModal}
          text="Compare Data"
          type={actionComponentType}
          icon={ICONS.protocol_condition.secondary}
          showIcon
          elementProps={{
            border: 'fancy',
          }}
        />
      )}
      content={() => (
        <ProtocolConditionExplorer
          group={group}
          metadata={metadata}
        />
      )}
    />
  );
};

export default ProtocolConditionActionCompareDataMetadata;
