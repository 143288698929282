/**
 * Labstep
 *
 * @module components/Resource/Form/Edit
 * @desc Resource Edit Form
 */

import ResourceLocationFormShowEditSubOuter from 'labstep-web/components/ResourceLocation/Form/ShowEdit/SubOuter';
import ResourceLocationLabel from 'labstep-web/components/ResourceLocation/Label';
import { ICONS } from 'labstep-web/constants/icons';
import { withActiveGroup } from 'labstep-web/containers/ActiveGroup';
import { EntityUpdateContainer } from 'labstep-web/containers/Entity/Update';
import ActionComponent from 'labstep-web/core/Action/Component';
import FormShowEditSearchSelect from 'labstep-web/core/Form/ShowEdit/SearchSelect';
import { ResourceLocation } from 'labstep-web/models/resource-location.model';
import React from 'react';
import styles from './styles.module.scss';
import {
  IFormShowEditResourceLocationProps,
  IResourceItemFormShowEditResourceLocationProps,
} from './types';

export const resourceLocationSearchSelectProps = {
  creatable: true,
  getOptionLabel: (option?: ResourceLocation) => {
    if (!option) {
      return '';
    }
    // New option
    if (typeof option.id === 'string') {
      return option.name;
    }
    return option.location_path;
  },
};

export const FormShowEditResourceLocation: React.FC<
  IFormShowEditResourceLocationProps
> = ({
  parentEntity,
  activeGroup,
  childKeyName,
  label,
  initialEditingState,
  onSelect,
  selected,
}) => {
  const resourceLocation = parentEntity.resource_location;
  return (
    <FormShowEditSearchSelect
      parentEntity={parentEntity}
      childEntityName={ResourceLocation.entityName}
      childKeyName={childKeyName}
      searchSelectProps={{
        params: { group_id: activeGroup?.id },
        ...resourceLocationSearchSelectProps,
      }}
      label={label}
      initialEditingState={initialEditingState}
      optimistic={false}
      customShow={({ toggle }) => {
        if (!resourceLocation) {
          return null;
        }
        return (
          <div className={styles.container}>
            <ActionComponent
              type="icon"
              icon={ICONS.resource_location.primary}
              onClick={toggle}
              elementProps={{
                popup: { content: 'Search Locations' },
              }}
            />
            <EntityUpdateContainer
              entityName={parentEntity.entityName}
              id={parentEntity.id}
            >
              {({ update }) => (
                <ResourceLocationLabel
                  resourceLocation={resourceLocation}
                  selected={selected}
                  onSelect={onSelect}
                  onRemove={() =>
                    update({
                      resource_location_id:
                        resourceLocation.outer_location
                          ? resourceLocation.outer_location.id
                          : null,
                    })
                  }
                />
              )}
            </EntityUpdateContainer>

            <ResourceLocationFormShowEditSubOuter
              parentEntity={parentEntity}
              outerLocation={resourceLocation}
              childKeyName={childKeyName}
            />
          </div>
        );
      }}
    />
  );
};

export const FormShowEditResourceLocationWithActiveGroup =
  withActiveGroup(FormShowEditResourceLocation);

export const ResourceItemFormShowEditResourceLocation: React.FC<
  IResourceItemFormShowEditResourceLocationProps
> = ({ resourceItem, ...rest }) => (
  <FormShowEditResourceLocationWithActiveGroup
    parentEntity={resourceItem}
    childKeyName={ResourceLocation.entityName}
    {...rest}
  />
);

export default ResourceItemFormShowEditResourceLocation;
