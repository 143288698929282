/**
 * Labstep
 *
 * @module core/Table/Simple/Header
 * @desc Simple table header component
 */

import Table from 'labstep-web/core/Table';
import React from 'react';
import styles from './styles.module.scss';
import { ITableSimpleHeaderProps } from './types';

export const TableSimpleHeader: React.FC<ITableSimpleHeaderProps> = ({
  columns,
}) => (
  <Table.Header>
    <Table.Row>
      {columns.map((column, index) => (
        <Table.HeaderCell
          // eslint-disable-next-line react/no-array-index-key
          key={index}
          className={styles.header}
          {...column.cellProps}
        >
          {column.header}
        </Table.HeaderCell>
      ))}
    </Table.Row>
  </Table.Header>
);

export default TableSimpleHeader;
