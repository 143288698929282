/**
 * Labstep
 *
 * @module components/Notification/Card/Preview/Desktop
 * @desc Desktop version of Preview Card for single notification
 */

import cx from 'classnames';
import NotificationActionMenu from 'labstep-web/components/Notification/Action/Menu';
import {
  getIcon,
  getRoute,
} from 'labstep-web/components/Notification/Card/Preview/utils';
import GenericCard from 'labstep-web/core/Card/Generic';
import Icon from 'labstep-web/core/Icon';
import Link from 'labstep-web/core/Link';
import { humanReadableDate } from 'labstep-web/services/date.service';
import React from 'react';
import styles from './styles.module.scss';
import {
  ISubtitleProps,
  NotificationCardPreviewDesktopProps,
} from './types';

export const Subtitle: React.FC<ISubtitleProps> = ({
  notification,
}) => (
  <div>
    <span className={styles.date}>
      {humanReadableDate(notification.created_at)}
    </span>
    {notification.read_at && <b> • Read</b>}
  </div>
);

const NotificationCardPreviewDesktop: React.FC<
  NotificationCardPreviewDesktopProps
> = ({ notification }) => {
  const route = getRoute(notification);
  const icon = getIcon(notification);
  const { message } = notification;
  return (
    <GenericCard
      className={cx(styles.card, { read: notification.read_at })}
      headerImage={<Icon color={icon.color} name={icon.name} />}
      headerTitle={
        <div>
          {notification.entity_name && notification.entity_type && (
            <>
              <Link to={route.to} params={route.params}>
                <b>{`${notification.entity_type}: ${notification.entity_name}`}</b>
              </Link>
            </>
          )}
          <div>{message}</div>
        </div>
      }
      customSubtitle={<Subtitle notification={notification} />}
      actionMenu={
        <NotificationActionMenu
          actions={['disable']}
          notification={notification}
        />
      }
    />
  );
};

export default NotificationCardPreviewDesktop;
