/**
 * Labstep
 *
 * @module screens/OrderRequest/Index
 * @desc OrderRequest index
 */

import { EntitySearchChildrenProps } from 'labstep-web/components/Entity/Search/Children/types';
import OrderRequestActionCreate from 'labstep-web/components/OrderRequest/Action/Create';
import { OrderRequestEmptyState } from 'labstep-web/components/OrderRequest/Card/EmptyState';
import OrderRequestListOrTable from 'labstep-web/components/OrderRequest/ListOrTable';
import OrderRequestStatusModalAddItems from 'labstep-web/components/OrderRequest/Status/Modal/AddItems';
import { ParamsStateContextProvider } from 'labstep-web/contexts/params-state';
import { getIndexOrderRequestColDefs } from 'labstep-web/grid/IndexOrderRequest/coldefs';
import { ReadOnMountHOC } from 'labstep-web/hoc/ReadOnMount';
import { OrderRequest } from 'labstep-web/models/order-request.model';
import MasterIndex from 'labstep-web/screens/Master/Index';
import React, { useCallback, useMemo } from 'react';
import OrderRequestIndexBreadcrumb from './Breadcrumb';
import OrderRequestIndexLeftPane from './LeftPane';
import { bulkActions, filters, sortOptions } from './constants';
import { IScreensOrderRequestIndexProps } from './types';

export const ScreensOrderRequestIndex: React.FC<
  IScreensOrderRequestIndexProps
> = ({ group, links }) => {
  const [orderRequestAddItems, setOrderRequestAddItems] =
    React.useState<OrderRequest | null>(null);

  const onChangeStatus: Parameters<
    typeof getIndexOrderRequestColDefs
  >[0] = useCallback((orderRequest, status) => {
    if (orderRequest.shouldImportResourceItems(status)) {
      setOrderRequestAddItems(orderRequest);
    }
  }, []);

  const closeModal = useCallback(() => {
    setOrderRequestAddItems(null);
  }, []);

  const params = useMemo(
    () => ({
      group_id: group.id,
      is_template: false,
    }),
    [],
  );
  const breadcrumb = useMemo(
    () => <OrderRequestIndexBreadcrumb />,
    [],
  );
  const leftPane = useMemo(
    () => <OrderRequestIndexLeftPane links={links} />,
    [links],
  );
  const noResultsMessage = useMemo(
    () => <OrderRequestEmptyState />,
    [],
  );
  const action = useMemo(() => <OrderRequestActionCreate />, []);

  const entityView = useMemo(
    () => ({
      context: 'order_request_index',
      entityName: OrderRequest.entityName,
    }),
    [],
  );

  const paramsStateContext = useMemo(
    () => ({ persistEntity: OrderRequest.entityName }),
    [],
  );

  return (
    <ParamsStateContextProvider value={paramsStateContext}>
      {orderRequestAddItems && (
        <OrderRequestStatusModalAddItems
          orderRequest={orderRequestAddItems}
          open
          onCancel={closeModal}
          onClose={closeModal}
          onImport={closeModal}
        />
      )}
      <MasterIndex
        breadcrumb={breadcrumb}
        leftPane={leftPane}
        params={params}
        noResultsMessage={noResultsMessage}
        entityName={OrderRequest.entityName}
        action={action}
        bulkActions={bulkActions}
        filters={filters}
        tableFormat={false}
        useAllPages
        entityView={entityView}
        sortOptions={sortOptions}
      >
        {({
          entities,
          readNextPage,
          ...rest
        }: EntitySearchChildrenProps) => {
          const component = (template?: OrderRequest) => (
            <OrderRequestListOrTable
              orderRequests={entities}
              columnDefs={getIndexOrderRequestColDefs(
                onChangeStatus,
                template,
              )}
              loadMore={readNextPage}
              {...rest}
            />
          );
          if (group.default_order_request_template) {
            return (
              <ReadOnMountHOC
                type="entities"
                params={{
                  id: group.default_order_request_template.idAttr,
                  get_single: 1,
                }}
                entityName={OrderRequest.entityName}
                loading={{ loader: false }}
                children={({ entity: template }) =>
                  component(template)
                }
              />
            );
          }
          return component();
        }}
      </MasterIndex>
    </ParamsStateContextProvider>
  );
};

export default ScreensOrderRequestIndex;
