/**
 * Labstep
 */

import classnames from 'classnames';
import ResourceLocationActionDeleteMap from 'labstep-web/components/ResourceLocation/Action/DeleteMap';
import ResourceLocationActionUpdateMapDimensions from 'labstep-web/components/ResourceLocation/Action/UpdateMapDimensions';
import React, { useEffect } from 'react';
import RGL, {
  ReactGridLayoutProps,
  WidthProvider,
} from 'react-grid-layout';
import TableGrid from './Table';
import './styles.css';
import styles from './styles.module.scss';
import { GridLayoutDefaultProps } from './types';
import { getFilledCells } from './utils';

export const ReactGridLayout = WidthProvider(RGL);

export const SIZE_PX = 50;

const GridLayoutDefault: React.FC<GridLayoutDefaultProps> = ({
  rows,
  columns,
  layout,
  childComponent,
  selected,
  setSelected,
  onChange,
  readOnly = false,
  removeItem,
  addItem,
  numberOfChanges,
  resourceLocation,
}) => {
  const onDrop: ReactGridLayoutProps['onDrop'] = (
    l,
    layoutItem,
    event,
  ) => {
    const e = event as DragEvent;
    if (!e || !e.dataTransfer) {
      return;
    }
    const data = e.dataTransfer.getData('text');
    const { i, name, resource_name } = JSON.parse(data);
    const newLayout = l.reduce((result, item) => {
      if (item.i === '__dropping-elem__') {
        return {
          ...result,
          [i]: {
            name,
            resource_name,
            item: { ...item, i },
          },
        };
      }
      return {
        ...result,
        [item.i]: {
          ...layout[item.i],
          item,
        },
      };
    }, {});
    onChange(newLayout);
  };

  // Add event listeners
  useEffect(() => {
    const downHandler = (e: KeyboardEvent) => {
      if (selected) {
        e.preventDefault();
        if (['Delete', 'Backspace'].indexOf(e.key) > -1 && selected) {
          removeItem(selected);
          setSelected(null);
        }
        if (['Escape'].indexOf(e.key) > -1 && selected) {
          setSelected(null);
        }
      }
    };
    window.addEventListener('keydown', downHandler);
    // Remove event listeners on cleanup
    return () => {
      window.removeEventListener('keydown', downHandler);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected]);

  const gridLayout = Object.keys(layout).map(
    (key) => layout[key].item,
  );
  const selectionMode = selected && !layout[selected];

  const children = React.useMemo(
    () =>
      gridLayout.map((item) => {
        const isSelected = selected === item.i;
        return (
          <div
            key={item.i}
            className={classnames('text', {
              [styles.selected]: isSelected,
            })}
            data-grid={item}
            onClick={() => setSelected(isSelected ? null : item.i)}
            style={{
              zIndex: selectionMode ? 2 : undefined,
            }}
          >
            {childComponent(layout[item.i])}
          </div>
        );
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [numberOfChanges, selected, resourceLocation],
  );

  return (
    <div
      className={classnames(styles.background, {
        [styles.extended]: !readOnly,
      })}
    >
      {!readOnly && (
        <>
          <div
            style={{
              position: 'absolute',
              left: 10,
              top: 10,
              zIndex: 2,
            }}
          >
            <ResourceLocationActionDeleteMap
              resourceLocation={resourceLocation}
            />
          </div>
          <div style={{ position: 'absolute', right: 10, top: 10 }}>
            <ResourceLocationActionUpdateMapDimensions
              resourceLocation={resourceLocation}
              icon="arrows alternate horizontal"
            />
          </div>
          <div style={{ position: 'absolute', left: 10, bottom: 10 }}>
            <ResourceLocationActionUpdateMapDimensions
              resourceLocation={resourceLocation}
              icon="arrows alternate vertical"
            />
          </div>
          <div
            style={{ position: 'absolute', right: 10, bottom: 10 }}
          >
            <ResourceLocationActionUpdateMapDimensions
              resourceLocation={resourceLocation}
            />
          </div>
        </>
      )}
      <div
        style={{
          width: SIZE_PX * columns + 40,
        }}
        // className={styles.container}
      >
        <div style={{ position: 'relative' }}>
          <div
            style={{
              paddingTop: 40,
              paddingLeft: 40,
              zIndex: selectionMode ? 2 : undefined,
            }}
            id={readOnly ? undefined : 'grid-layout'}
          >
            <ReactGridLayout
              style={{
                minHeight: SIZE_PX * rows,
                zIndex: selectionMode ? undefined : 1,
              }}
              className="layout"
              cols={columns}
              rowHeight={SIZE_PX}
              margin={[0, 0]}
              maxRows={rows}
              layout={gridLayout}
              onDrop={onDrop}
              // WidthProvider option
              measureBeforeMount
              onDragStop={(l) => {
                onChange(
                  l.reduce(
                    (result, item) => ({
                      ...result,
                      [item.i]: { ...layout[item.i], item },
                    }),
                    {},
                  ),
                );
              }}
              onResizeStop={(l) => {
                onChange(
                  l.reduce(
                    (result, item) => ({
                      ...result,
                      [item.i]: { ...layout[item.i], item },
                    }),
                    {},
                  ),
                );
              }}
              // I like to have it animate on mount. If you don't, delete `useCSSTransforms` (it's default `true`)
              // and set `measureBeforeMount={true}`.
              useCSSTransforms
              // This turns off compaction so you can place items wherever.
              compactType={null}
              isDroppable={!readOnly}
              // This turns off rearrangement so items will not be pushed arround.
              preventCollision
              isBounded
              isDraggable={!readOnly}
              isResizable={!readOnly}
            >
              {children}
            </ReactGridLayout>
          </div>
          <div
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              zIndex: selectionMode ? 1 : 0,
            }}
          >
            <TableGrid
              onClick={
                selectionMode && !readOnly ? addItem : () => {}
              }
              filledCells={getFilledCells(gridLayout)}
              rows={rows}
              columns={columns}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default GridLayoutDefault;
