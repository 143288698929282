/**
 * Labstep
 *
 * @module components/JupyterNotebook/PreviousRun
 * @desc Displays previous run if any
 */

import JupyterInstanceModalList from 'labstep-web/components/JupyterInstance/Modal/List';
import { withJupyterInstance } from 'labstep-web/containers/JupyterInstance';
import React, { useEffect } from 'react';
import { JupyterNotebookPreviousRunProps } from './types';

export const JupyterNotebookPreviousRun: React.FC<
  JupyterNotebookPreviousRunProps
> = ({ jupyterNotebook, jupyterInstance, getJupyterInstance }) => {
  if (!jupyterNotebook.data) {
    return null;
  }

  useEffect(() => {
    getJupyterInstance('run', jupyterNotebook.id, true);
  }, []);

  let endedAt = null;
  if (jupyterInstance?.run[jupyterNotebook.id]?.ended_at) {
    endedAt = jupyterInstance?.run[jupyterNotebook.id]?.ended_at;
  }

  if (endedAt) {
    return (
      <JupyterInstanceModalList
        jupyterNotebook={jupyterNotebook}
        endedAt={endedAt}
      />
    );
  }

  return null;
};

export default withJupyterInstance(JupyterNotebookPreviousRun);
