/**
 * Labstep
 *
 * @module grid/Index/components/ShowSideBar
 * @desc Entity DataGrid Action Show Side Bar
 */

import { useDataGridContext } from 'labstep-web/contexts/grid/hook';
import Button from 'labstep-web/core/Button';
import Icon from 'labstep-web/core/Icon';
import React from 'react';
import { GridIndexActionShowSideBarProps } from './types';

export const ENTITY_DATE_GRID_ACTION_SHOW_SIDE_BAR_DATA_TEST_ID =
  'entity-data-grid-action-show-side-bar';

export const GridIndexActionShowSideBar: React.FC<
  GridIndexActionShowSideBarProps
> = ({ className }) => {
  const { agGrid } = useDataGridContext();
  if (!agGrid) {
    return null;
  }
  return (
    <div>
      <Button
        data-testid={
          ENTITY_DATE_GRID_ACTION_SHOW_SIDE_BAR_DATA_TEST_ID
        }
        className={className}
        onClick={() => {
          agGrid.api.setSideBarVisible(
            !agGrid.api.isSideBarVisible(),
          );
        }}
      >
        <Icon name="th list" />
        Columns
      </Button>
    </div>
  );
};

export default GridIndexActionShowSideBar;
