/**
 * Labstep
 *
 * @module components/Group/Template/OrderRequest
 * @desc Group Order Request Template
 */

import { EntityUpdateContainer } from 'labstep-web/containers/Entity/Update';
import { OrderRequest } from 'labstep-web/models/order-request.model';
import React from 'react';
import GroupTemplate from '..';
import { GroupTemplateOrderRequestProps } from './types';

export const GroupTemplateOrderRequest: React.FC<
  GroupTemplateOrderRequestProps
> = ({ group }) => (
  <EntityUpdateContainer
    entityName={group.entityName}
    id={group.idAttr}
  >
    {({ update }) => (
      <GroupTemplate
        group={group}
        entityName={OrderRequest.entityName}
        field="default_order_request_template_id"
        extraBody={{ quantity: 1 }}
        options={{
          onSuccess: ({ response }) => {
            update({
              default_order_request_template_id: response!.result,
            });
          },
        }}
      />
    )}
  </EntityUpdateContainer>
);

export default GroupTemplateOrderRequest;
