/**
 * Labstep
 *
 * @module components/ResourceTemplate/Card/EmptyState
 * @desc Empty state for group
 */

import ResourceTemplateCreateAction from 'labstep-web/components/ResourceTemplate/Action/Create';
import EmptyState from 'labstep-web/core/Card/EmptyState';
import { Resource } from 'labstep-web/models/resource.model';
import React from 'react';
import { IResourceTemplateEmptyStateProps } from './types';

export const ResourceTemplateEmptyState: React.FC<
  IResourceTemplateEmptyStateProps
> = (props) => (
  <EmptyState
    title={`No ${Resource.getHumanReadableEntityName(
      true,
      true,
      true,
    )}`}
    explanation={`${Resource.getHumanReadableEntityName(
      true,
      true,
      true,
    )} are customisable metadata templates to apply to resources. This makes it easy to upload and manage resources with the same descriptive information.`}
    src="/img/empty-state/inventory.svg"
    action={<ResourceTemplateCreateAction />}
    {...props}
  />
);

export default ResourceTemplateEmptyState;
