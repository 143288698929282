/**
 * Labstep
 *
 * @module core/GooglePicker
 * @desc GooglePicker component
 */

import Picker from 'labstep-web/components/File/Action/Create/Picker';
import { EntityCreateContainer } from 'labstep-web/containers/Entity/Create';
import { configService } from 'labstep-web/services/config.service';
import React from 'react';
import { IGooglePickerProps, IGooglePickerState } from './types';

export class GoogleDrivePicker extends React.Component<
  IGooglePickerProps,
  IGooglePickerState
> {
  static defaultProps = {
    scope: 'https://www.googleapis.com/auth/drive.readonly',
    clientId: configService.googleClientId,
    developerKey: configService.googleDeveloperKey,
    multiple: false,
  };

  public oauthToken: string | null;

  public apiLoaded!: boolean;

  public oAuthLoaded!: boolean;

  public tokenClient: any;

  constructor(props: IGooglePickerProps) {
    super(props);
    this.oauthToken = null;
    this.state = {
      apiLoaded: false,
      oAuthLoaded: false,
    };
    this.onOAuthLoaded = this.onOAuthLoaded.bind(this);
    this.createPicker = this.createPicker.bind(this);
    this.onPickerApiLoad = this.onPickerApiLoad.bind(this);
    this.loadGapi = this.loadGapi.bind(this);
  }

  componentDidMount() {
    const loaded = this.loadGapi();
    if (!loaded) {
      setTimeout(() => {
        this.loadGapi();
      }, 2000);
    }
  }

  onPickerApiLoad() {
    this.setState({ apiLoaded: true });
  }

  onOAuthLoaded() {
    this.setState({ oAuthLoaded: true });
  }

  createPicker() {
    const { create, options, onSelect } = this.props;
    const onSuccess = (body: any) => {
      create(body, options);
    };

    const showPicker = () => {
      const pickerBuilder = new google.picker.PickerBuilder()
        .addView(google.picker.ViewId.DOCS)
        .setOAuthToken(this.oauthToken as any)
        .setDeveloperKey(configService.googleDeveloperKey)
        .setCallback((data) => {
          if (!data) {
            return;
          }
          const documents = data[google.picker.Response.DOCUMENTS];
          if (!documents || documents.length === 0) {
            return;
          }
          const filesToUpload = documents.map((document) => ({
            path: document.url,
            name: document.name,
            size: 1,
            mime_type: document.mimeType,
            link_source: 'googledrive',
          }));

          if (onSelect) {
            onSelect(filesToUpload);
          }

          filesToUpload.forEach((file) => {
            onSuccess(file);
          });
        });

      if (this.props.multiple) {
        pickerBuilder.enableFeature(
          google.picker.Feature.MULTISELECT_ENABLED,
        );
      }
      const picker = pickerBuilder.build();
      picker.setVisible(true);
    };

    // Request an access token.
    this.tokenClient.callback = async (response: any) => {
      if (response.error !== undefined) {
        throw response;
      }
      this.oauthToken = response.access_token;
      showPicker();
    };

    if (this.oauthToken === null) {
      // Prompt the user to select a Google Account and ask for consent to share their data
      // when establishing a new session.
      this.tokenClient.requestAccessToken({ prompt: 'consent' });
    } else {
      // Skip display of account chooser and consent dialog for an existing session.
      this.tokenClient.requestAccessToken({ prompt: '' });
    }
  }

  loadGapi() {
    if (window.gapi && configService.googleClientId) {
      this.tokenClient = (
        google as any
      ).accounts.oauth2.initTokenClient({
        client_id: configService.googleClientId,
        scope: 'https://www.googleapis.com/auth/drive.readonly',
      });
      this.onOAuthLoaded();
      window.gapi.load('picker', this.onPickerApiLoad);
      return true;
    }
    return false;
  }

  render() {
    const { oAuthLoaded, apiLoaded } = this.state;
    return (
      <Picker
        onClick={this.createPicker}
        text="Google Drive"
        icon="google drive"
        disabled={!(oAuthLoaded && apiLoaded)}
      />
    );
  }
}

const GoogleDrivePickerFileContainer = ({
  parentName,
  parentId,
  ...rest
}: any) => (
  <EntityCreateContainer
    entityName="file"
    parentName={parentName}
    parentId={parentId}
  >
    {({ create }) => <GoogleDrivePicker create={create} {...rest} />}
  </EntityCreateContainer>
);

export default GoogleDrivePickerFileContainer;
