/**
 * Labstep
 *
 * @module screens/Authentication/Signup/Right
 * @desc Signup Screen Right
 */

import SignupForm from 'labstep-web/components/User/Form/Signup';
import UserSigninSocialMedia from 'labstep-web/components/User/Signin/SocialMedia';
import Divider from 'labstep-web/core/Divider';
import Link from 'labstep-web/core/Link';
import AuthenticationComponent from 'labstep-web/screens/Authentication/Component';
import {
  AlreadyHaveAnAccount,
  SharedBody,
  SharedFooter,
  TermsAndConditions,
} from 'labstep-web/screens/Authentication/Shared';
import { configService } from 'labstep-web/services/config.service';
import React from 'react';
import { Message } from 'semantic-ui-react';
import ShareLinkHeader from '../../Signin/ShareLinkHeader';
import ShareLinkOrganizationNoSeatsLeft from '../NoSeatsLeft';
import { ScreensAuthenticationSignupRightProps } from './types';

export const ScreensAuthenticationSignupRight: React.FC<
  ScreensAuthenticationSignupRightProps
> = ({ activeShareLink }) => {
  if (activeShareLink?.organization?.noSeatsLeft) {
    return (
      <ShareLinkOrganizationNoSeatsLeft
        organization={activeShareLink.organization}
      />
    );
  }
  const body = activeShareLink
    ? { share_link_token: activeShareLink.token }
    : undefined;
  return (
    <AuthenticationComponent>
      {activeShareLink && (
        <ShareLinkHeader sign="up" shareLink={activeShareLink} />
      )}
      <div>
        {!configService.isUsAwsRegion && (
          <>
            <UserSigninSocialMedia body={body} mode="signup" />

            <Divider horizontal>Or</Divider>
          </>
        )}

        <SharedBody>
          <SignupForm body={body} />
        </SharedBody>

        <SharedFooter>
          <AlreadyHaveAnAccount />
          <TermsAndConditions />
          <Message>
            <p>
              <b>Looking for Labstep Industry?</b>
            </p>
            <p>
              Labstep Industry offers additional features for
              security, permission control and compliance to better
              enable commercial companies to protect their IP as they
              innovate.
            </p>
            <Link to="signup_enterprise">
              Sign up for Labstep Industry
            </Link>
          </Message>
        </SharedFooter>
      </div>
    </AuthenticationComponent>
  );
};

export default ScreensAuthenticationSignupRight;
