/**
 * Labstep
 *
 * @module components/Organization/Action/UpdateUserRolesBulk
 * @desc UpdateUserRolesBulk
 */

import { EntityUpdateContainer } from 'labstep-web/containers/Entity/Update';
import { ActionComponent } from 'labstep-web/core/Action/Component';
import Dropdown from 'labstep-web/core/Dropdown';
import {
  UserOrganization,
  UserOrganizationType,
} from 'labstep-web/models/user-organization.model';
import { capitalize } from 'lodash';
import React from 'react';
import { OrganizationActionUpdateUserRoleBulkProps } from './types';

export const OrganizationActionUpdateUserRoleBulk: React.FC<
  OrganizationActionUpdateUserRoleBulkProps
> = ({ entities, options }) => {
  if (!entities || entities.length === 0) {
    return null;
  }

  return (
    <Dropdown
      direction="left"
      icon={null}
      trigger={
        <ActionComponent
          icon="user circle"
          text="Set Organization Role"
          type="text"
        />
      }
    >
      {Object.keys(UserOrganizationType)
        .filter((type) => type !== UserOrganizationType.viewer)
        .map((type) => (
          <EntityUpdateContainer
            key={type}
            entityName={UserOrganization.entityName}
            id={entities.map((e: any) => {
              return e.id;
            })}
          >
            {({ update }) => (
              <Dropdown.Item
                onClick={() => {
                  update(
                    {
                      type,
                    },
                    options,
                  );
                }}
              >
                {capitalize(type)}
              </Dropdown.Item>
            )}
          </EntityUpdateContainer>
        ))}
    </Dropdown>
  );
};
