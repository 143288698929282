/**
 * Labstep
 *
 * @module prosemirror/components/Menu/File/Action/ViewInline
 * @desc Convert file to a full-width component
 */

import Button from 'labstep-web/core/Button';
import Icon from 'labstep-web/core/Icon';
import { expandedPluginKey } from 'labstep-web/prosemirror/extensions/expanded/plugin';
import React from 'react';
import { IMenuFileActionViewInlineProps } from './types';

const MenuFileActionViewInline: React.FC<
  IMenuFileActionViewInlineProps
> = ({ state, dispatch, fileNode }) => (
  <Button
    onClick={() => {
      dispatch(
        state.tr
          .setNodeMarkup(fileNode.pos, null, {
            ...fileNode.node.attrs,
            fullWidth: true,
          })
          .setMeta(expandedPluginKey, {
            file: fileNode.node.attrs.id,
          }),
      );
    }}
  >
    <Icon name="expand arrows alternate" />
    <span>View Inline</span>
  </Button>
);

export default MenuFileActionViewInline;
