/**
 * Labstep
 *
 * @module prosemirror/components/Menu/Link
 * @desc Menu to display when selecting a node with link mark
 */

import MenuLinkActionEdit from 'labstep-web/prosemirror/components/Menu/Link/Action/Edit';
import MenuLinkActionOpen from 'labstep-web/prosemirror/components/Menu/Link/Action/Open';
import MenuLinkActionUnlink from 'labstep-web/prosemirror/components/Menu/Link/Action/Unlink';
import { getSelectionAttributes } from 'labstep-web/prosemirror/marks/utils';
import { Attrs } from 'prosemirror-model';
import React, { useEffect, useState } from 'react';
import styles from './styles.module.scss';
import { IMenuLinkProps } from './types';

/** Number of pixels to display menu above selection */
export const LINE_HEIGHT = 40;

const MenuLink: React.FC<IMenuLinkProps> = ({
  state,
  dispatch,
  cursorPosition,
}) => {
  const [attrs, setAttrs] = useState<Attrs[]>([]);

  useEffect(() => {
    setAttrs(getSelectionAttributes(state, state.schema.marks.link));
  }, [state]);

  if (attrs.length === 0) {
    return null;
  }

  return (
    <div
      className={styles.container}
      style={{
        position: 'fixed',
        top: `${cursorPosition.top - LINE_HEIGHT}px`,
        left: `${cursorPosition.left}px`,
      }}
    >
      <MenuLinkActionOpen attrs={attrs[0]} />
      <MenuLinkActionEdit
        state={state}
        dispatch={dispatch}
        attrs={attrs[0]}
      />
      <MenuLinkActionUnlink state={state} dispatch={dispatch} />
    </div>
  );
};

export default MenuLink;
