/**
 * Labstep
 */

import { ActionMenuProps } from 'labstep-web/core/Action/Menu/types';
import {
  EntityUser,
  EntityUserEntities,
} from 'labstep-web/models/entity-user.model';

// eslint-disable-next-line no-shadow
export enum Action {
  toggle_assign = 'toggle_assign',
}

export interface IEntityUserActionMenuProps
  extends Pick<
    ActionMenuProps,
    Exclude<keyof ActionMenuProps, 'children'>
  > {
  entityUser: EntityUser;
  actions: Action[];
  entity: EntityUserEntities;
}
