/**
 * Labstep
 *
 * @module components/Group/Template/PurchaseOrder
 * @desc Will create purchase order template if null / shows metadata manager
 */

import PurchaseOrderFormShowEditCurrency from 'labstep-web/components/PurchaseOrder/Form/ShowEdit/Currency';
import TextWithCount from 'labstep-web/core/Text/WithCount';
import { PurchaseOrder } from 'labstep-web/models/purchase-order.model';
import React from 'react';
import GroupTemplate from '..';
import { GroupTemplatePurchaseOrderProps } from './types';

export const GroupTemplatePurchaseOrder: React.FC<
  GroupTemplatePurchaseOrderProps
> = ({ group }) => (
  <GroupTemplate
    entityName={PurchaseOrder.entityName}
    group={group}
    field="purchase_order_template_id"
    extraRows={[
      {
        header: (
          <TextWithCount
            header
            text="Default Currency"
            icon="dollar"
          />
        ),
        content: (purchaseOrder: PurchaseOrder) => (
          <PurchaseOrderFormShowEditCurrency
            purchaseOrder={purchaseOrder}
          />
        ),
      },
    ]}
  />
);

export default GroupTemplatePurchaseOrder;
