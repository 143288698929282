/**
 * Labstep
 *
 * @module screens/UserGroup/Index
 * @desc Group Index
 */

import { DefaultSort } from 'labstep-web/components/Entity/Table/SortOptions/types';
import GroupActionCreate from 'labstep-web/components/Group/Action/Create';
import DocumentHead from 'labstep-web/core/DocumentHead';
import { Group } from 'labstep-web/models/group.model';
import Center from 'labstep-web/screens/UserGroup/Master/Center';
import { getHumanReadableEntityName } from 'labstep-web/services/i18n.service';
import React from 'react';

const title = `My ${getHumanReadableEntityName(
  Group.entityName,
  true,
  true,
)}`;

export const ScreensUserGroupIndex: React.FC = () => (
  <>
    <DocumentHead title={title} />
    <Center
      params={{ sort: '-is_home' }}
      searchKey="search_query"
      action={<GroupActionCreate />}
      sortOptions={{
        group_name: {
          label: 'Name',
          default_sort: DefaultSort.asc,
        },
      }}
      defaultSortOptions={{
        group_created_at: {
          label: 'Date Created',
          default_sort: DefaultSort.desc,
        },
      }}
    />
  </>
);

export default ScreensUserGroupIndex;
