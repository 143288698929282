/**
 * Labstep
 *
 * @module components/Layout/Menu/TopBar/DropdownPortal
 * @desc DropdownPortal
 */

import React from 'react';
import styles from './styles.module.scss';
import { IDropdownPortalHoverProps } from './types';

export const DropdownPortalHover: React.FC<
  IDropdownPortalHoverProps
> = ({ trigger, content }) => (
  <div className={styles.container}>
    {trigger}
    <div className={styles.dropdown}>{content}</div>
  </div>
);

export default DropdownPortalHover;
