/**
 * Labstep
 *
 * @module prosemirror/marks/utils
 * @desc Methods to manipulate marks in state
 */

import Color from 'color';
import { uniq } from 'lodash';
import { Mark, MarkType, Node, NodeType } from 'prosemirror-model';
import { EditorState } from 'prosemirror-state';
import { findTextNodes } from 'prosemirror-utils';

const RGBA_PATTERN = /^rgba/i;
export const RGBA_TRANSPARENT = 'rgba(0,0,0,0)';

export const ColorMaping = {
  transparent: RGBA_TRANSPARENT,
  inherit: '',
};

export const toCSSColor = (source: string): string => {
  if (!source) {
    return '';
  }
  if (source in ColorMaping) {
    return ColorMaping[source];
  }

  if (source && RGBA_PATTERN.test(source)) {
    const color = Color(source);
    if (color.valpha === 0) {
      ColorMaping[source] = RGBA_TRANSPARENT;
      return RGBA_TRANSPARENT;
    }
    const rgba = color.toString();
    ColorMaping[source] = rgba.toString();
    return rgba;
  }

  let hex = '';
  try {
    hex = Color(source).hex().toLowerCase();
    ColorMaping[source] = hex;
  } catch (ex) {
    // eslint-disable-next-line no-console
    console.warn('unable to convert to hex', source);
    ColorMaping[source] = '';
  }
  return hex;
};

export const getAllNodesAttribute = (
  state: EditorState,
  attribute: string,
): string[] => {
  const textNodes = findTextNodes(state.doc);

  const attributes = [];
  textNodes.forEach((textNode) => {
    textNode.node.marks.forEach((mark) => {
      if (mark.type.name === attribute) {
        attributes.push(mark.attrs[attribute]);
      }
    });
  });

  return uniq(attributes);
};

export const getSelectionAttributes = (
  state: EditorState,
  type: MarkType,
): Mark['attrs'][] => {
  const { empty, $from } = state.selection;
  let { from, to } = state.selection;

  if (empty) {
    from -= 1;
    if ($from.parentOffset === 0) {
      to += 1;
    }
  }

  let selectionAttributes = [];
  state.doc.nodesBetween(from, to, (node: Node) => {
    selectionAttributes = selectionAttributes.concat(
      node.marks
        .filter((mark: Mark) => mark.type === type)
        .map((mark: Mark) => mark.attrs),
    );
  });

  return selectionAttributes;
};

export const getSelectionAttribute = (
  state: EditorState,
  type: MarkType,
): any[] => {
  const { empty, $from } = state.selection;
  let { from, to } = state.selection;

  if (empty && state.storedMarks) {
    const storedMark = state.storedMarks.find(
      (mark: Mark) => mark.type === type,
    );
    if (storedMark) {
      return [storedMark.attrs[type.name]];
    }
  }

  if (empty) {
    from -= 1;
    if ($from.parentOffset === 0) {
      to += 1;
    }
  }

  let selectionAttributes = [];
  state.doc.nodesBetween(from, to, (node: Node) => {
    selectionAttributes = selectionAttributes.concat(
      node.marks
        .filter((mark: Mark) => mark.type === type)
        .map((mark: Mark) => mark.attrs[type.name]),
    );
  });

  return uniq(selectionAttributes);
};

export const isMarkActive = (
  state: EditorState,
  type: MarkType,
): boolean => {
  const { from, $from, to, empty } = state.selection;

  if (empty) {
    return !!type.isInSet(state.storedMarks || $from.marks());
  }

  return state.doc.rangeHasMark(from, to, type);
};

export const hasBlockType = (
  state: EditorState,
  type: NodeType,
  attrs: Mark['attrs'],
): boolean => {
  const { $from, to } = state.selection;

  return to <= $from.end() && $from.parent.hasMarkup(type, attrs);
};

// This is from prosemirror-commands we need it here because it's not exported
export function markApplies(doc, ranges, type) {
  for (let i = 0; i < ranges.length; i += 1) {
    const { $from, $to } = ranges[i];
    let can =
      $from.depth === 0 ? doc.type.allowsMarkType(type) : false;
    doc.nodesBetween($from.pos, $to.pos, (node) => {
      if (can) {
        return false;
      }
      can = node.inlineContent && node.type.allowsMarkType(type);
      return true;
    });
    if (can) {
      return true;
    }
  }
  return false;
}

// This is from prosemirror-commands toggleMark but only remove mark.
export const removeMark = (markType) => {
  return (state, dispatch) => {
    const { empty, $cursor, ranges } = state.selection;

    if (
      (empty && !$cursor) ||
      !markApplies(state.doc, ranges, markType)
    ) {
      return false;
    }

    if (dispatch) {
      if ($cursor) {
        if (markType.isInSet(state.storedMarks || $cursor.marks())) {
          dispatch(state.tr.removeStoredMark(markType));
        }
      } else {
        let has = false;
        const { tr } = state;
        for (let i = 0; !has && i < ranges.length; i += 1) {
          const { $from, $to } = ranges[i];
          has = state.doc.rangeHasMark($from.pos, $to.pos, markType);
        }
        for (let i = 0; i < ranges.length; i += 1) {
          const { $from, $to } = ranges[i];
          if (has) {
            tr.removeMark($from.pos, $to.pos, markType);
          }
        }
        dispatch(tr.scrollIntoView());
      }
    }
    return true;
  };
};

// This is from prosemirror-commands toggleMark but only add mark.
export function setMark(markType, attrs) {
  return (state, dispatch) => {
    const { empty, $cursor, ranges } = state.selection;

    if (
      (empty && !$cursor) ||
      !markApplies(state.doc, ranges, markType)
    ) {
      return false;
    }

    if (dispatch) {
      if ($cursor) {
        if (markType.isInSet(state.storedMarks || $cursor.marks())) {
          dispatch(state.tr.removeStoredMark(markType));
        }
        dispatch(state.tr.addStoredMark(markType.create(attrs)));
      } else {
        let has = false;
        const { tr } = state;
        for (let i = 0; !has && i < ranges.length; i += 1) {
          const { $from, $to } = ranges[i];
          has = state.doc.rangeHasMark($from.pos, $to.pos, markType);
        }
        for (let i = 0; i < ranges.length; i += 1) {
          const { $from, $to } = ranges[i];
          if (has) {
            tr.removeMark($from.pos, $to.pos, markType);
          }
          tr.addMark($from.pos, $to.pos, markType.create(attrs));
        }
        dispatch(tr.scrollIntoView());
      }
    }
    return true;
  };
}
