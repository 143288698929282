/**
 * Labstep
 *
 * @module constants/search-params
 * @desc Constants for search params
 */

/**
 * Omit these search params when showing the empty state
 */
export const EMPTY_STATE_OMIT_SEARCH_PARAMS = [
  'folder_id',
  'folder_group_not_id',
  'template_id',
];
