/**
 * Labstep
 *
 * @module components/Experiment/Action/SaveAs
 * @desc A dropdown menu to save as a new protocol or a new protocol collection
 */

import { CanCreate } from 'labstep-web/components/Entity/Can';
import ProtocolActionCreate from 'labstep-web/components/Protocol/Action/Create';
import ProtocolFormCreate from 'labstep-web/components/Protocol/Form/Create';
import { ICONS } from 'labstep-web/constants/icons';
import { withActiveGroup } from 'labstep-web/containers/ActiveGroup';
import { EntityCreateContainer } from 'labstep-web/containers/Entity/Create';
import ActionComponent from 'labstep-web/core/Action/Component';
import Button from 'labstep-web/core/Button';
import Card from 'labstep-web/core/Card/Card';
import Dropdown from 'labstep-web/core/Dropdown';
import Icon from 'labstep-web/core/Icon';
import ModalDefault from 'labstep-web/core/Modal/Default';
import { Protocol } from 'labstep-web/models/protocol.model';
import { humanReadableDate } from 'labstep-web/services/date.service';
import React from 'react';
import styles from './styles.module.scss';
import {
  ExperimentActionSaveAsProps,
  ExperimentActionSaveAsWithProtocolProps,
  ExperimentActionSaveAsWithoutProtocolProps,
} from './types';

export const ExperimentActionSaveAsWithoutProtocol: React.FC<
  ExperimentActionSaveAsWithoutProtocolProps
> = ({ experiment, activeGroup }) => (
  <CanCreate entityName="protocol_collection">
    <EntityCreateContainer
      entityName={Protocol.entityName}
      showLoaderWhileCreating
    >
      {({ status, create }) => (
        <ActionComponent
          type="text"
          icon="save"
          text="Save to Protocol Library"
          disabled={(status && status.isFetching) || false}
          onClick={() =>
            create(
              {
                experiment_id: experiment.id,
                group_id: activeGroup?.id,
              },
              {
                toast: true,
                additionalMeta: {
                  addToProtocolLibrary: true,
                },
              },
            )
          }
        />
      )}
    </EntityCreateContainer>
  </CanCreate>
);

export const ExperimentActionSaveAsWithProtocol: React.FC<
  ExperimentActionSaveAsWithProtocolProps
> = ({ experiment, disabled, activeGroup }) => (
  <Dropdown
    upward
    icon={null}
    disabled={disabled}
    trigger={
      <ActionComponent
        dataCy="components/Experiment/Action/SaveAs"
        type="text"
        icon="save"
        text="Save As"
      />
    }
  >
    <ProtocolActionCreate
      parentName={experiment.protocol.collection.entityName}
      parentId={experiment.protocol.collection.id}
      actionComponentProps={{
        type: 'option',
        text: 'New Version',
        icon: undefined,
        modalConfirmationProps: experiment.protocol.collection
          .draft_version
          ? {
              header: 'Protocol Draft Already Exists',
              positive: true,
              confirmButtonContent: false,
              cancelButtonContent: 'Close',
              message: (
                <div>
                  <p>
                    There is already a draft Version of this Protocol{' '}
                    <b>
                      {
                        experiment.protocol.collection.draft_version
                          .printVersion
                      }
                    </b>
                  </p>
                  <Card className={styles.card}>
                    <Icon name={ICONS.protocol.primary} />
                    <div>
                      <div>
                        <b>
                          {
                            experiment.protocol.collection
                              .draft_version.printVersion
                          }
                        </b>
                      </div>
                      <div>
                        Created by{' '}
                        {
                          experiment.protocol.collection.draft_version
                            .author.first_name
                        }{' '}
                        {
                          experiment.protocol.collection.draft_version
                            .author.last_name
                        }{' '}
                      </div>
                      <div>
                        Last Edited:{' '}
                        {humanReadableDate(
                          experiment.protocol.collection.draft_version
                            .updated_at,
                        )}
                      </div>
                    </div>
                    <Button
                      primary
                      basic
                      route={{
                        to: 'protocol_show',
                        params: {
                          id: experiment.protocol.collection
                            .draft_version.id,
                        },
                      }}
                    >
                      View
                    </Button>
                  </Card>
                  <br />
                  <p>
                    Please abandon or finalise this draft Version
                    before creating a new one
                  </p>
                </div>
              ),
            }
          : undefined,
      }}
      body={{
        experiment_id: experiment.id,
      }}
      options={{ toast: true }}
    />
    <CanCreate entityName="protocol_collection">
      <ModalDefault
        header="New Protocol"
        viewComponent={({ toggleModal }) => (
          <ActionComponent
            type="option"
            text="New Protocol"
            onClick={toggleModal}
          />
        )}
        content={({ toggleModal }) => (
          <ProtocolFormCreate
            body={{
              experiment_id: experiment.id,
              group_id: activeGroup?.id,
            }}
            options={{
              onSuccess: toggleModal,
              toast: true,
            }}
          />
        )}
      />
    </CanCreate>
  </Dropdown>
);

export const ExperimentActionSaveAs: React.FC<
  ExperimentActionSaveAsProps
> = ({ experiment, disabled, ...rest }) => {
  if (experiment.protocol) {
    return (
      <ExperimentActionSaveAsWithProtocol
        experiment={experiment}
        disabled={disabled}
        {...rest}
      />
    );
  }

  return (
    <ExperimentActionSaveAsWithoutProtocol
      experiment={experiment}
      {...rest}
    />
  );
};

export default withActiveGroup(ExperimentActionSaveAs);
