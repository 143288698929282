/**
 * Labstep
 *
 * @module prosemirror/nodes/list
 * @desc Ordered list and bullet list nodes
 */

import { LabstepNode } from 'labstep-web/prosemirror/nodes/types';
import { wrappingInputRule } from 'prosemirror-inputrules';
import { NodeType } from 'prosemirror-model';
import {
  bulletList,
  liftListItem,
  listItem,
  orderedList,
  sinkListItem,
  splitListItem,
  wrapInList,
} from 'prosemirror-schema-list';

// : (NodeType) → InputRule
// Given a list node type, returns an input rule that turns a number
// followed by a dot at the start of a textblock into an ordered list.
export function orderedListRule(nodeType: NodeType) {
  return wrappingInputRule(
    /^(\d+)\.\s$/,
    nodeType,
    (match) => ({ order: +match[1] }),
    (match, node) => node.childCount + node.attrs.order === +match[1],
  );
}

// : (NodeType) → InputRule
// Given a list node type, returns an input rule that turns a bullet
// (dash, plush, or asterisk) at the start of a textblock into a
// bullet list.
export function bulletListRule(nodeType: NodeType) {
  return wrappingInputRule(/^\s*([-+*])\s$/, nodeType);
}

const NodeListItem: LabstepNode = {
  key: 'list_item',
  spec: { ...listItem, content: 'paragraph block*' },
  keymap: {
    Enter: (state, dispatch) =>
      splitListItem(state.schema.nodes[NodeListItem.key])(
        state,
        dispatch,
      ),
    'Mod-[': (state, dispatch) =>
      liftListItem(state.schema.nodes[NodeListItem.key])(
        state,
        dispatch,
      ),
    'Shift-Tab': (state, dispatch) =>
      liftListItem(state.schema.nodes[NodeListItem.key])(
        state,
        dispatch,
      ),
    'Mod-]': (state, dispatch) =>
      sinkListItem(state.schema.nodes[NodeListItem.key])(
        state,
        dispatch,
      ),
    Tab: (state, dispatch) =>
      sinkListItem(state.schema.nodes[NodeListItem.key])(
        state,
        dispatch,
      ),
  },
};

const NodeOrderedList: LabstepNode = {
  key: 'ordered_list',
  spec: {
    ...orderedList,
    content: 'list_item+',
    group: 'block',
  },
  keymap: {
    'Mod-Shift-7': (state, dispatch) =>
      wrapInList(state.schema.nodes[NodeOrderedList.key])(
        state,
        dispatch,
      ),
  },
  inputRules({ schema }) {
    return [orderedListRule(schema.nodes[NodeOrderedList.key])];
  },
};

const NodeBulletList: LabstepNode = {
  key: 'bullet_list',
  spec: {
    ...bulletList,
    content: 'list_item+',
    group: 'block',
  },
  keymap: {
    'Mod-Shift-8': (state, dispatch) =>
      wrapInList(state.schema.nodes[NodeBulletList.key])(
        state,
        dispatch,
      ),
  },
  inputRules({ schema }) {
    return [bulletListRule(schema.nodes[NodeBulletList.key])];
  },
};

export { NodeListItem, NodeOrderedList, NodeBulletList };
