/**
 * Labstep
 *
 * @module components/ShareLink/Accept/Entity/GroupOrOrganization/Content
 * @desc Components to accept sharelink for entities of type group or organization
 */

import Button from 'labstep-web/core/Button';
import { getHumanReadableEntityName } from 'labstep-web/services/i18n.service';
import React from 'react';
import { IShareLinkGroupOrOrganizationContentProps } from './types';

export const ShareLinkGroupOrOrganizationContent: React.FC<
  IShareLinkGroupOrOrganizationContentProps
> = ({ shareLink, onClick, status }) => (
  <>
    {shareLink.organization && (
      <p>
        {`*by accepting you transfer the ownership of your account,
      workspaces and the content within them to the ${
        shareLink.entity.displayName
      }
    ${getHumanReadableEntityName(shareLink.entity.entityName)}.`}
      </p>
    )}
    <div>
      <Button primary basic route={{ to: 'app_homepage' }}>
        Reject
      </Button>
      <Button primary onClick={onClick} status={status}>
        Accept
      </Button>
    </div>
  </>
);

export default ShareLinkGroupOrOrganizationContent;
