/**
 * Labstep
 */

export const getSaveMessage = ({
  saving,
  error,
  saved,
}: {
  saving: boolean;
  error: boolean;
  saved: boolean;
}): string | null => {
  if (saving) {
    return 'Saving...';
  }
  if (error) {
    return 'Failed to save';
  }

  if (saved) {
    return 'Saved';
  }

  return null;
};

export const getStatusMessage = (status: any): string | null =>
  getSaveMessage({
    saving: status.isFetching,
    error: status.error,
    saved: status && status.error === null,
  });
