/**
 * Labstep
 *
 * @module components/ResourceLocation/Label
 * @desc Resource Location Label
 */

import Icon from 'labstep-web/core/Icon';
import RemoveLabel from 'labstep-web/core/Label/Remove';
import Popup from 'labstep-web/core/Popup';
import React from 'react';
import ResourceLocationLabelBasic from './Basic';
import ResourceLocationLabelLink from './Link';
import styles from './styles.module.scss';
import { IResourceLocationLabelProps } from './types';

export const ResourceLocationLabel: React.FC<
  IResourceLocationLabelProps
> = ({
  resourceLocation,
  selected,
  onSelect,
  onRemove,
  depth = 0,
  maxDepth = 2,
}) => {
  const name =
    selected === resourceLocation.id ? (
      <ResourceLocationLabelLink
        resourceLocation={resourceLocation}
      />
    ) : (
      resourceLocation.name
    );
  return (
    <div className={styles.container}>
      {resourceLocation.outer_location &&
        (depth <= maxDepth ? (
          <>
            <ResourceLocationLabel
              resourceLocation={resourceLocation.outer_location}
              onSelect={onSelect}
              selected={selected}
              depth={depth + 1}
            />
            <Icon name="chevron right" className={styles.chevron} />
          </>
        ) : (
          <Popup
            inverted
            on="hover"
            content={resourceLocation.outer_location.path}
            trigger={
              <div className={styles.container}>
                <Icon name="ellipsis horizontal" />
                <Icon
                  name="chevron right"
                  className={styles.chevron}
                />
              </div>
            }
          />
        ))}
      {depth === 0 ? (
        <RemoveLabel
          data-testid="resource-location-label-remove"
          name={name}
          onNameClick={
            onSelect && (() => onSelect(resourceLocation.id))
          }
          onRemoveClick={onRemove}
        />
      ) : (
        <ResourceLocationLabelBasic
          resourceLocation={resourceLocation}
          onClick={onSelect && (() => onSelect(resourceLocation.id))}
          customName={name}
        />
      )}
    </div>
  );
};

export default ResourceLocationLabel;
