/**
 * Labstep
 *
 * @module screens/Resource/Show
 * @desc Resource Show Screen
 */

import DocumentHead from 'labstep-web/core/DocumentHead';
import Redirect from 'labstep-web/core/Redirect';
import { ReadOnMountHOC } from 'labstep-web/hoc/ReadOnMount';
import MasterShow from 'labstep-web/screens/Master/Show';
import React from 'react';
import Center from './Center';
import Right from './Right';
import {
  IResourceShowScreenContainerProps,
  IResourceShowScreenProps,
} from './types';

export const ResourceShowScreen: React.FC<
  IResourceShowScreenProps
> = ({ resource }) => (
  <>
    <DocumentHead title={resource.name || ''} />
    <MasterShow
      entity={resource}
      center={<Center resource={resource} />}
      right={<Right resource={resource} />}
    />
  </>
);

const ResourceShowScreenContainer: React.FC<
  IResourceShowScreenContainerProps
> = ({ match }) => (
  <ReadOnMountHOC
    type="entities"
    entityName="resource"
    params={{
      get_single: 1,
      is_deleted: 'both',
      id: match.params.id,
    }}
    loading={{ loader: 'show', cached: true }}
    children={({ entity }) =>
      entity.is_template ? (
        <Redirect
          to="resource_template_show"
          params={{ id: entity.id }}
        />
      ) : (
        <ResourceShowScreen resource={entity} />
      )
    }
  />
);

export default ResourceShowScreenContainer;
