/**
 * Labstep
 *
 * @module state/actions/params
 * @desc Redux actions for params
 */

export const setParams = (params: any) => ({
  type: 'SET_PARAMS',
  params,
});

export const clearParams = () => ({
  type: 'CLEAR_PARAMS',
});
