/**
 * Labstep
 */

import { useContext } from 'react';
import { ParamsContext } from '.';
import { ParamsContextType } from './types';

export const useParamsContext = (): ParamsContextType => {
  const context = useContext(ParamsContext);
  return context;
};
