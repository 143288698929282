/**
 * Labstep
 *
 * @module constants/breakpoints
 * @desc Static configuration parameters
 */

// Handle responsiveness
const phabletBreakPoint = 560;
const mobileBreakPoint = 768;
const tabletBreakPoint = 1200;
const smallDesktopBreakPoint = 980;
const largeDesktopBreakPoint = 1280;

export const breakpoints = {
  phablet: phabletBreakPoint,
  mobile: mobileBreakPoint,
  tablet: tabletBreakPoint,
  large_desktop: largeDesktopBreakPoint,
  small_desktop: smallDesktopBreakPoint,
};
