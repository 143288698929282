/**
 * Labstep
 *
 * @module components/Filter/Search/Active
 * @desc Active filter component for search query
 */

import RemoveLabel from 'labstep-web/core/Label/Remove';
import React from 'react';
import { FilterSearchActiveProps } from './types';

const FilterSearchActive: React.FC<FilterSearchActiveProps> = ({
  clearParams,
  searchParams,
}) => {
  return 'search_query' in searchParams ? (
    <RemoveLabel
      name={searchParams.search_query}
      onRemoveClick={
        clearParams && (() => clearParams(['search_query']))
      }
    />
  ) : null;
};

export default FilterSearchActive;
