/**
 * Labstep
 *
 * @module components/Entity/Search/ActionBar
 * @desc Bar with bulk selection and pagination action
 */

import { useBulkSelectContext } from 'labstep-web/contexts/bulk-select/hook';
import React from 'react';
import BulkActions from '../BulkActions';
import styles from './styles.module.scss';
import { EntitySearchActionBarProps } from './types';

const EntitySearchActionBar: React.FC<EntitySearchActionBarProps> = ({
  bulkActions,
  entityName,
}) => {
  const { isAtLeastOneSelected } = useBulkSelectContext();
  if (!isAtLeastOneSelected) {
    return null;
  }
  return (
    <div className={styles.actionsBar}>
      <BulkActions
        entityName={entityName}
        bulkActions={bulkActions}
      />
    </div>
  );
};

export default EntitySearchActionBar;
