/**
 * Labstep
 */

import { CoreFormCreateProps } from 'labstep-web/core/Form/Create/types';
import { APICallOptions, APICreateAction } from 'labstep-web/models';
import { MetadataThread } from 'labstep-web/models/metadata-thread.model';
import { Resource } from 'labstep-web/models/resource.model';

export const getOnSubmit =
  ({
    createResourceItemTemplate,
    createMetadata,
    resource,
    options,
  }: {
    createResourceItemTemplate: APICreateAction;
    createMetadata: APICreateAction;
    resource: Resource;
    options?: APICallOptions;
  }): CoreFormCreateProps['onSubmit'] =>
  (body): void =>
    createResourceItemTemplate(
      {
        resource_id: resource.id,
        is_template: true,
      },
      {
        onSuccess: ({ response }) => {
          if (!response) {
            return;
          }
          const metadataThreadId: number = (
            response.entities.resource_item[
              response.result as any
            ] as any
          ).metadata_thread;
          createMetadata(
            body,
            options,
            MetadataThread.entityName,
            metadataThreadId,
          );
        },
      },
    );
