/**
 * Labstep
 *
 * @module components/OrganizationPlan/Manager/Price/Select/Interval
 * @desc Select interval
 */

import {
  intervalLabels,
  supportedIntervals,
} from 'labstep-web/constants/organization-plan-price';
import Label from 'labstep-web/core/Label';
import LabelToggle from 'labstep-web/core/Label/Toggle';
import { PricingService } from 'labstep-web/services/pricing.service';
import React from 'react';
import { OrganizationPlanManagerPriceSelectIntervalProps } from './types';

export const OrganizationPlanManagerPriceSelectInterval: React.FC<
  OrganizationPlanManagerPriceSelectIntervalProps
> = ({ activeInterval, setActiveInterval }) => (
  <LabelToggle
    items={supportedIntervals.map((interval) => {
      const discount = PricingService.intervalDiscount[interval];
      return {
        id: interval,
        text: intervalLabels[interval],
        action: discount ? (
          <Label size="mini" color="green">
            Save {discount * 100}%
          </Label>
        ) : null,
      };
    })}
    onClick={(item) =>
      setActiveInterval(
        item.id as (typeof supportedIntervals)[number],
      )
    }
    activeItemId={activeInterval}
  />
);

export default OrganizationPlanManagerPriceSelectInterval;
