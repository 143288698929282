/**
 * Labstep
 *
 * @module components/OrderRequest/Action/RemovePurchaseOrder
 * @desc Action to set purchase_order_id for orderRequest to null
 */

import EntityActionUpdate from 'labstep-web/components/Entity/Action/Update';
import React from 'react';
import { OrderRequestActionRemovePurchaseOrderProps } from './types';

const OrderRequestActionRemovePurchaseOrder: React.FC<
  OrderRequestActionRemovePurchaseOrderProps
> = ({ orderRequest }) => (
  <EntityActionUpdate
    id={orderRequest.id}
    entityName={orderRequest.entityName}
    body={{ purchase_order_id: null }}
    actionComponentProps={{
      type: 'option',
      text: 'Remove',
    }}
  />
);

export default OrderRequestActionRemovePurchaseOrder;
