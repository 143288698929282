/**
 * Labstep
 *
 * @module grid/Index/components/ToolPanel/Columns/Section/Item
 * @desc Draggable item for Entity DataGrid column options section
 */

import { HeaderComponentParamsProps } from 'labstep-web/core/DataGrid/types';
import Flex from 'labstep-web/core/Flex';
import Icon from 'labstep-web/core/Icon';
import SortableItemDragHandle from 'labstep-web/core/Sortable/Item/DragHandle';
import SortableItemRef from 'labstep-web/core/Sortable/Item/Ref';
import { objectOrFunction } from 'labstep-web/services/react.service';
import React from 'react';
import { SectionType } from '../types';
import styles from './styles.module.scss';
import { GridIndexToolPanelColumnsSectionItemProps } from './types';

export const GridIndexToolPanelColumnsSectionItem: React.FC<
  GridIndexToolPanelColumnsSectionItemProps
> = ({ column, section, onToggleColumnVisible }) => {
  const { headerComponentParams, headerName } = column.getColDef();
  const colId = column.getId();
  const isVisible = section === SectionType.visible;
  const params: HeaderComponentParamsProps = {
    column,
  };
  return (
    <SortableItemRef id={colId} key={colId}>
      {({ style }): React.ReactElement => (
        <Flex data-testid="column-option" style={style}>
          {isVisible && <SortableItemDragHandle />}
          <Flex
            grow
            className={styles.item}
            onClick={(): void => onToggleColumnVisible(colId)}
          >
            <div className={styles.name}>
              <Icon
                name={
                  objectOrFunction(headerComponentParams, params)
                    ?.icon
                }
                cornerIcon={
                  objectOrFunction(headerComponentParams, params)
                    ?.cornerIcon
                }
              />
              {headerName}
            </div>
            <Icon
              className={styles.icon}
              color="grey"
              name={isVisible ? 'eye' : 'eye slash'}
            />
          </Flex>
        </Flex>
      )}
    </SortableItemRef>
  );
};

export default GridIndexToolPanelColumnsSectionItem;
