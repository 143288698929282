/**
 * Labstep
 *
 * @module components/ProtocolCondition/Form/Create/Bulk
 * @desc Bulk Create Form for ProtocolCondition
 */

import { EntityCreateContainer } from 'labstep-web/containers/Entity/Create';
import ReusableForm from 'labstep-web/core/Form/Reusable';
import { ProtocolCondition } from 'labstep-web/models/protocol-condition.model';
import React from 'react';
import { fieldQuantity, fieldQuantityName } from './fields';
import { IProtocolConditionFormCreateBulkProps } from './types';

export const ProtocolConditionFormCreateBulk: React.FC<
  IProtocolConditionFormCreateBulkProps
> = ({ protocol, options }) => (
  <EntityCreateContainer
    batch
    entityName={ProtocolCondition.entityName}
  >
    {({ create, status }): React.ReactElement => (
      <ReusableForm
        autoFocus
        status={status}
        defaultValues={{ [fieldQuantityName]: 1 }}
        fields={[fieldQuantity]}
        onSubmit={(values): void => {
          create(
            new Array(values[fieldQuantityName]).fill({
              [`${protocol.entityName}_id`]: protocol.guid,
            }),
            options,
          );
        }}
      />
    )}
  </EntityCreateContainer>
);

export default ProtocolConditionFormCreateBulk;
