/**
 * Labstep
 *
 * @module core/Form/ShowEdit/SelectOptions
 * @desc A component to toggle between Show and Edit mode
 * for options fields. The intention is to toggle onBlur and do
 * an optimistic update. If the field is left empty no api
 * request will be send and the last saved value will be restored.
 */

import { mapArrayOfStringToOptionValues } from 'labstep-web/components/Metadata/Form/utils';
import UpdateOrCreateContainer from 'labstep-web/containers/UpdateOrCreate';
import { callIf } from 'labstep-web/services/react.service';
import { enhanceOptions } from 'labstep-web/services/utils.service';
import { getFieldsWithValidationRules } from 'labstep-web/services/validation';
import { isEqual, omit, replace } from 'lodash';
import React from 'react';
import { ShowEditToggleComponent } from '..';
import ReusableForm from '../../Reusable';
import { FieldWithoutActionProps } from '../../Reusable/types';
import { IFormShowEditSelectOptionsProps } from './types';

const FormShowEditSelectOptions: React.FC<
  IFormShowEditSelectOptionsProps
> = ({
  field,
  entity,
  entityName,
  options = {},
  children,
  canEdit,
  label,
  createProps,
  onToggle,
  optimistic = false,
  ...rest
}) => {
  const name = field.fieldLabel || replace(field.name, /_/g, ' ');

  const { hasValue } = entity;

  const metadataOptions = entity.optionsTemplate
    ? entity.optionsTemplate.options
    : entity.options;

  const defaultValues = {
    [field.name]: {
      keys: metadataOptions
        ? Object.keys(metadataOptions.values)
        : [],
      values: createProps ? [] : entity.optionsSelectedValues,
    },
  };

  const passedFields = getFieldsWithValidationRules(
    [{ ...omit(field, ['fieldLabel']) }] as FieldWithoutActionProps[],
    entityName,
  );

  return (
    <UpdateOrCreateContainer
      createProps={createProps}
      entityName={entity.entityName}
      updateProps={{ id: entity.id }}
      // So that we wait for the template to get back
      optimistic={optimistic}
    >
      {({ action, status }): React.ReactElement => (
        <ShowEditToggleComponent
          canEdit={canEdit}
          children={children}
          name={name}
          hasValue={!createProps && hasValue}
          label={label}
          explainer={field.fieldExplainer}
          toggledComponent={({ toggle }): React.ReactElement => {
            const enhancedOptions = enhanceOptions({
              options,
              onFail: createProps && toggle,
            });
            const toggleFn = (): void => {
              callIf(onToggle);
              if (!createProps) {
                toggle();
              }
            };
            return (
              <div>
                <ReusableForm
                  fields={passedFields}
                  defaultValues={defaultValues}
                  submitButton={false}
                  blurToggle={toggleFn}
                  autoFocus
                  status={createProps ? status : undefined}
                  onSubmit={(submittedValues: any): void => {
                    const {
                      [field.name]: { values, keys },
                    } = submittedValues;
                    const existingOptions =
                      entity.options ||
                      // fall back on template
                      entity.optionsTemplate?.options;
                    if (!existingOptions) {
                      return;
                    }
                    const submittedValue = {
                      ...existingOptions,
                      values: mapArrayOfStringToOptionValues(
                        values,
                        keys,
                      ),
                    };

                    if (
                      isEqual(submittedValue, entity.options) &&
                      !createProps
                    ) {
                      toggleFn();
                      return;
                    }
                    action(
                      { options: submittedValue },
                      enhancedOptions,
                    );
                    toggleFn();
                  }}
                />
              </div>
            );
          }}
          {...rest}
        />
      )}
    </UpdateOrCreateContainer>
  );
};

export default FormShowEditSelectOptions;
