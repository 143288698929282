/**
 * Labstep
 *
 * @module components/Molecule/Hazards
 * @desc Showing molecule hazards
 */

import { ghsHazardStatements } from 'labstep-web/components/Molecule/Safety/constants';
import React from 'react';
import { MoleculeSafetyCodeList } from '..';
import { MoleculeSafetyHazardsProps } from './types';

export const MoleculeSafetyHazards: React.FC<
  MoleculeSafetyHazardsProps
> = ({ parent, pubChemMetadata }) => {
  const hazards = pubChemMetadata.Safety.ghs_hazard_statements;
  return (
    <MoleculeSafetyCodeList
      label="GHS Hazards"
      parent={parent}
      type="ghs_hazard_statements"
      pubChemMetadata={pubChemMetadata}
      codes={(hazards || []).map((h) => ({
        code: h,
        info:
          ghsHazardStatements[h] &&
          ghsHazardStatements[h].hazard_statements,
      }))}
    />
  );
};

export default MoleculeSafetyHazards;
