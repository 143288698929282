/**
 * Labstep
 *
 * @module components/Notification/Card/EmptyState
 * @desc Empty state for notification
 */

import EmptyState from 'labstep-web/core/Card/EmptyState';
import React from 'react';
import { NotificationCardEmptyStateProps } from './types';

export const NotificationCardEmptyState: React.FC<
  NotificationCardEmptyStateProps
> = ({ img }) => (
  <EmptyState
    src={img ? '/img/empty-state/notifications.svg' : undefined}
    title="No news is good news"
    explanation="You have no notifications"
  />
);

NotificationCardEmptyState.defaultProps = {
  img: true,
};

export default NotificationCardEmptyState;
