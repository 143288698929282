/**
 * Labstep
 *
 * @module components/Layout/Menu/TopBar/OrganizationContext
 * @desc OrganizationContext
 */

import OrganizationItem from 'labstep-web/components/Organization/Item';
import Link from 'labstep-web/core/Link';
import React from 'react';
import { OrganizationContextProps } from './types';

export const OrganizationContext: React.FC<
  OrganizationContextProps
> = ({ organization }) =>
  organization && (
    <Link
      to="organization_show"
      params={{
        identifier: organization.identifier,
      }}
    >
      <OrganizationItem organization={organization} />
    </Link>
  );

export default OrganizationContext;
