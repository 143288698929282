/**
 * Labstep
 *
 * @module components/OrderRequest/Filter/Urgent/Active
 * @desc Active filter component for urgent order request
 */

import { ICONS } from 'labstep-web/constants/icons';
import RemoveLabel from 'labstep-web/core/Label/Remove';
import React from 'react';
import { OrderRequestFilterUrgentActiveProps } from './types';

const OrderRequestFilterUrgentActive: React.FC<
  OrderRequestFilterUrgentActiveProps
> = ({ clearParams, searchParams }) =>
  'is_urgent' in searchParams ? (
    <RemoveLabel
      icon={ICONS.order_request.info.urgent}
      name={'Urgent'}
      onRemoveClick={
        clearParams && (() => clearParams(['is_urgent']))
      }
    />
  ) : null;

export default OrderRequestFilterUrgentActive;
