/**
 * Labstep
 *
 * @module prosemirror/marks/background-color
 * @desc Background color mark
 */

import { LabstepMark } from 'labstep-web/prosemirror/marks/types';
import { toCSSColor } from 'labstep-web/prosemirror/marks/utils';

const MarkBackgroundColor: LabstepMark = {
  key: 'backgroundColor',
  spec: {
    attrs: {
      backgroundColor: { default: null },
    },
    inline: true,
    group: 'inline',
    parseDOM: [
      {
        style: 'background',
        getAttrs: (color) => {
          if (color instanceof Node) {
            return false;
          }
          if (color === 'transparent') {
            return false;
          }
          return {
            backgroundColor: toCSSColor(color),
          };
        },
      },
      {
        style: 'background-color',
        getAttrs: (color) => {
          if (color instanceof Node) {
            return false;
          }
          if (color === 'transparent') {
            return false;
          }
          return {
            backgroundColor: toCSSColor(color),
          };
        },
      },
    ],
    toDOM(node) {
      const { backgroundColor } = node.attrs;
      let style = '';
      if (backgroundColor) {
        style += `background-color: ${backgroundColor};`;
      }
      return ['span', { style }, 0];
    },
  },
};

export default MarkBackgroundColor;
