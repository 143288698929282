/**
 * Labstep
 *
 * @module components/DeviceBooking/Modal
 * @desc Device Booking Modal
 */

import DeviceBookingDetail from 'labstep-web/components/DeviceBooking/Detail';
import Modal from 'labstep-web/core/Modal/Default';
import { DeviceBooking } from 'labstep-web/models/device-booking.model';
import React from 'react';
import { DeviceBookingModalProps } from './types';

export const DeviceBookingModal: React.FC<
  DeviceBookingModalProps
> = ({ deviceBookingId, onClose }) => (
  <Modal
    header={DeviceBooking.getHumanReadableEntityName(false, true)}
    open
    showDone
    viewComponent={null}
    closeOnDimmerClick
    content={
      <DeviceBookingDetail deviceBookingId={deviceBookingId} />
    }
    onClose={onClose}
  />
);

export default DeviceBookingModal;
