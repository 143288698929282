/**
 * Labstep
 *
 * @module components/User/Form/ForgotPassword
 * @desc ForgotPassword form
 */

import Button from 'labstep-web/core/Button';
import ReusableForm from 'labstep-web/core/Form/Reusable';
import { FieldType } from 'labstep-web/core/Form/Reusable/types';
import React from 'react';
import { string } from 'yup';
import { IUserFormForgotPasswordProps } from './types';

export const UserFormForgotPassword: React.FC<
  IUserFormForgotPasswordProps
> = ({ forgotPassword, status }) => (
  <div>
    <ReusableForm
      onSubmit={({ email }) => {
        forgotPassword({ email });
      }}
      fields={[
        {
          fieldType: FieldType.Text,
          name: 'email',
          placeholder: 'Email address',
          validation: string().required().email(),
        },
      ]}
      error={
        status &&
        status.error &&
        status.error.data &&
        (status.error.data as any).message
      }
      submitButton={
        <Button fluid primary type="submit" status={status}>
          Reset
        </Button>
      }
    />
  </div>
);

export default UserFormForgotPassword;
