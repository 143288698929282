/**
 * Labstep
 *
 * @module components/Filter/Post/Active
 * @desc Active Filter for Post
 */

import { PostFilterType } from 'labstep-web/services/query-parameter.service';
import React from 'react';
import { FilterPostActiveLabel } from './Label';
import FilterPostActiveOperator from './Operator';
import { FilterPostActiveProps } from './types';

export const FilterPostActive: React.FC<FilterPostActiveProps> = ({
  searchParams,
  setPostFilterType,
  removePostFilter,
  isReporting,
}) => {
  const filter = searchParams.filter as PostFilterType | undefined;
  if (!filter) {
    return null;
  }

  const labels: React.ReactNode[] = [];

  // We only support one level of filter nesting for now
  // filter[0] is the user-controlled filter
  const { type, predicates } = filter[0];
  predicates.forEach((predicate, i) => {
    labels.push(
      <FilterPostActiveLabel
        // eslint-disable-next-line react/no-array-index-key
        key={`label-${i}`}
        node={predicate}
        onRemoveClick={(): void => removePostFilter(i)}
        index={i}
        isReporting={isReporting}
      />,
    );
    const isNotLastItem = i !== predicates.length - 1;
    if (isNotLastItem) {
      labels.push(
        <FilterPostActiveOperator
          // eslint-disable-next-line react/no-array-index-key
          key={`operator-${i}`}
          type={type}
          setPostFilterType={setPostFilterType}
        />,
      );
    }
  });

  return <>{labels}</>;
};

export default FilterPostActive;
