/**
 * Labstep
 *
 * @module components/Resource/Form/ShowEdit/AlertThreshold
 * @desc Edit Resource alert threshold
 */

import EntityActionEditModal from 'labstep-web/components/Entity/Action/Edit/Modal';
import ResourceFormShowEditAlertThreshold from 'labstep-web/components/Resource/Form/ShowEdit/AlertThreshold';
import ActionComponent from 'labstep-web/core/Action/Component';
import React from 'react';
import { IResourceActionSetAlertThresholdProps } from './types';

const ResourceActionSetAlertThreshold: React.FC<
  IResourceActionSetAlertThresholdProps
> = ({ resource }) => (
  <EntityActionEditModal
    entity={resource}
    header="Set Alert Threshold"
    viewComponent={({ toggleModal }) => (
      <ActionComponent
        type="text"
        icon="bell outline"
        text="Set Alert Threshold"
        onClick={toggleModal}
      />
    )}
    closeOnDimmerClick
    content={
      <div>
        <p>
          When the number of available items falls below this
          threshold it will trigger a notification
        </p>
        <ResourceFormShowEditAlertThreshold resource={resource} />
      </div>
    }
  />
);

export default ResourceActionSetAlertThreshold;
