/**
 * Labstep
 *
 * @module components/EntityState/Form/fields
 * @desc Form Fields for EntityState
 */

import {
  FieldType,
  FieldWithoutActionProps,
} from 'labstep-web/core/Form/Reusable/types';
import { TARGET_ALLOWED_ACTIONS_LOCK_OPTIONS } from 'labstep-web/models/entity-state.model';
import rules from 'labstep-web/services/validation/rules';

export const fieldDescription: FieldWithoutActionProps = {
  fieldLabel: 'Description',
  name: 'description',
  fieldType: FieldType.TextArea,
  validation: rules.entity_state.description,
  placeholder: `Enter a description`,
};

export type LOCK_SETTINGS_KEYS =
  keyof typeof TARGET_ALLOWED_ACTIONS_LOCK_OPTIONS;

export const fieldEntityStateTargetAllowedActionsLock: FieldWithoutActionProps =
  {
    fieldType: FieldType.ReactSelect,
    name: 'target_allowed_actions_lock',
    fieldLabel: 'Lock Mode',
    placeholder: 'Enter Lock Mode',
    validation: rules.entity_state.target_allowed_actions_lock,
    elementProps: {
      openMenuOnFocus: true,
      options: Object.keys(TARGET_ALLOWED_ACTIONS_LOCK_OPTIONS).map(
        (key) => ({
          label: key as LOCK_SETTINGS_KEYS,
          value: key as LOCK_SETTINGS_KEYS,
        }),
      ),
    },
  };
