/**
 * Labstep
 *
 * @module components/JupyterNotebook/Content
 * @desc JupyterNotebook Content
 */

import LayoutLoadingContent from 'labstep-web/components/Layout/LoadingContent';
import { withAuthenticatedUser } from 'labstep-web/containers/AuthenticatedUser';
import { withJupyterInstance } from 'labstep-web/containers/JupyterInstance';
import { configService } from 'labstep-web/services/config.service';
import React, { useEffect } from 'react';
import { IJupyterNotebookContentProps } from './types';

export const JupyterNotebookContent: React.FC<
  IJupyterNotebookContentProps
> = ({
  authenticatedUser,
  jupyterNotebook,
  getJupyterInstance,
  postJupyterInstance,
  jupyterInstance,
}) => {
  useEffect(() => {
    if (!jupyterInstance?.edit[jupyterNotebook.id]?.started_at) {
      postJupyterInstance('edit', jupyterNotebook.id);
    }
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      if (!jupyterInstance?.edit[jupyterNotebook.id]?.started_at) {
        getJupyterInstance('edit', jupyterNotebook.id);
      } else {
        clearInterval(interval);
      }
    }, 3000);

    return () => clearInterval(interval);
  }, [jupyterInstance, getJupyterInstance]);

  return (
    <LayoutLoadingContent
      status={
        !jupyterInstance?.edit[jupyterNotebook.id]?.started_at
          ? {
              isFetching: true,
              error: null,
            }
          : {
              isFetching: false,
              error: null,
            }
      }
      message="Launching Jupyter Notebook Server, this may take up to 30 seconds..."
      loader="main_content"
    >
      {jupyterInstance?.edit[jupyterNotebook.id]?.started_at && (
        <iframe
          style={{ flexGrow: 1 }}
          title="jupyter"
          src={`${configService.jupyterUrl}/user/${
            authenticatedUser.guid
          }/${
            jupyterInstance.edit[jupyterNotebook.id]?.id
          }/lab/workspaces/auto-r/tree/${jupyterNotebook.id}.ipynb`}
        />
      )}
    </LayoutLoadingContent>
  );
};

export default withAuthenticatedUser(
  withJupyterInstance(JupyterNotebookContent),
);
