/**
 * Labstep
 *
 * @module core/Form/ReactForm/ReactSelect
 * @desc Select component for react-form using ReactSelect component
 */

import Select from 'labstep-web/core/Select';
import React, { useEffect } from 'react';
import { Controller } from 'react-hook-form';
import Creatable from 'react-select/creatable';
import { IReactSelectFieldProps } from './types';

/**
 * We are spreading props in Field to make it aware of changes and force it to
 * update otherwisde it doesn't update #https://github.com/react-tools/react-form/issues/237
 */
export const ReactSelectField: React.FC<IReactSelectFieldProps> = (
  props,
) => (
  <Controller
    name={props.name}
    control={props.control}
    render={({ field: reactHookFormField }): React.ReactElement => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const {
        elementProps,
        disabled,
        placeholder,
        name,
        autoFocus,
        onBlur,
        onKeyPress,
      } = props;

      const { creatable, ...rest } = elementProps;

      const { value, onChange } = reactHookFormField;

      const Component = creatable ? Creatable : Select;

      useEffect(() => {
        if (elementProps?.defaultValue) {
          onChange(elementProps.defaultValue as any);
        }
      }, []);

      return (
        <Component
          {...rest}
          name={name}
          data-testid={`react-select-${name}`}
          autoFocus={autoFocus}
          onBlur={onBlur}
          onKeyPress={onKeyPress}
          isDisabled={disabled}
          placeholder={placeholder}
          onChange={(val) => {
            onChange(val as any);
          }}
          value={value}
        />
      );
    }}
  />
);

export default ReactSelectField;
