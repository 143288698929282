/**
 * Labstep
 *
 * @module components/OrderRequestTemplate/Action/Delete
 * @desc Action to delete
 */

import EntityActionDelete from 'labstep-web/components/Entity/Action/Delete';
import React from 'react';
import { IResourceDeleteActionProps } from './types';

export const ResourceDeleteAction: React.FC<
  IResourceDeleteActionProps
> = ({ orderRequestTemplate }) => (
  <EntityActionDelete
    entity={orderRequestTemplate}
    actionComponentProps={{
      type: 'button',
      text: orderRequestTemplate.deleted_at
        ? 'Enable Template'
        : 'Disable Template',
    }}
    skipConfirmation
  />
);

export default ResourceDeleteAction;
