/**
 * Labstep
 *
 * @module components/Device/Action/Delete
 * @desc Action to delete
 */

import EntityActionDelete from 'labstep-web/components/Entity/Action/Delete';
import React from 'react';
import { DeviceActionDeleteProps } from './types';

export const DeviceActionDelete: React.FC<
  DeviceActionDeleteProps
> = ({ device }) => <EntityActionDelete entity={device} />;

export default DeviceActionDelete;
