/**
 * Labstep
 *
 * @module screens/ProtocolCollection/Edit/Right/Values
 * @desc Protocol Value Screens
 */

import ProtocolValueActionCreate from 'labstep-web/components/ProtocolValue/Action/Create';
import ProtocolValueList from 'labstep-web/components/ProtocolValue/List';
import { ProtocolValueTable } from 'labstep-web/components/ProtocolValue/Table';
import EmptyState from 'labstep-web/core/Card/EmptyState';
import PremiumFeatureScreenGuard from 'labstep-web/core/PremiumFeature/ScreenGuard';
import { ProtocolValue } from 'labstep-web/models/protocol-value.model';
import React from 'react';
import { IProtocolValueManagerProps } from './types';

export const ProtocolValueManager: React.FC<
  IProtocolValueManagerProps
> = ({ protocol, tableFormat }) => {
  const action = <ProtocolValueActionCreate protocol={protocol} />;

  const protocolValues = protocol.protocol_values.filter(
    (p) => !p.deleted_at && !p.variable_template_guid,
  );

  return (
    <PremiumFeatureScreenGuard premiumFeature="inventory" unstyled>
      {protocolValues.length === 0 ? (
        <EmptyState
          src="/img/empty-state/inventory.svg"
          action={action}
          explanation={`Add ${ProtocolValue.getHumanReadableEntityName(
            true,
          )} to keep track of the resources, amounts and specific batches / aliquots used.`}
        />
      ) : (
        <div>
          {action}
          {tableFormat ? (
            <ProtocolValueTable protocolValues={protocolValues} />
          ) : (
            <ProtocolValueList protocolValues={protocolValues} />
          )}
        </div>
      )}
    </PremiumFeatureScreenGuard>
  );
};

export default ProtocolValueManager;
