/**
 * Labstep
 *
 * @module grid/Report/components/Filter/ProtocolCollection/Active/Post
 * @desc Active node component for metadata
 */

import { FilterPostActiveLabelProps } from 'labstep-web/components/Filter/Post/Active/Label/types';
import { ICONS } from 'labstep-web/constants/icons';
import { useParamsContext } from 'labstep-web/contexts/params/hook';
import RemoveLabel from 'labstep-web/core/Label/Remove';
import GridReportFilterProtocolCollection from 'labstep-web/grid/Report/components/Filter/ProtocolCollection';
import { ReadOnMountHOC } from 'labstep-web/hoc/ReadOnMount';
import { ProtocolCollection } from 'labstep-web/models/protocol-collection.model';
import { PostFilter } from 'labstep-web/services/postFilter';
import { PostFilterComparison } from 'labstep-web/services/query-parameter.service';
import React from 'react';

const FilterProtocolCollectionActivePost: React.FC<
  FilterPostActiveLabelProps
> = ({ node, onRemoveClick }) => {
  const { addPostFilter } = useParamsContext();
  const filter = new PostFilter(
    [['guid', PostFilterComparison.eq]],
    'experiment.protocol.collection',
  );
  const filterActive = filter.isNodeActive(node);
  if (!filterActive) {
    return null;
  }
  const attributes = filter.getAttributeValues(node);

  return (
    <GridReportFilterProtocolCollection
      addPostFilter={addPostFilter}
      viewComponent={({ toggleModal }) => (
        <ReadOnMountHOC
          entityName={ProtocolCollection.entityName}
          type="entities"
          params={{
            id: attributes.guid.value,
            get_single: 1,
          }}
          loading={false}
          children={({ entity }) => {
            if (!entity) {
              return null;
            }
            return (
              <RemoveLabel
                icon={ICONS.protocol_collection.primary}
                name={entity.name}
                onNameClick={toggleModal}
                onRemoveClick={onRemoveClick}
              />
            );
          }}
        />
      )}
    />
  );
};

export default FilterProtocolCollectionActivePost;
