/**
 * Labstep
 *
 * @module components/User/Settings/Mfa
 * @desc Multi Factor Authentication
 */

import UserActionDisableMfa from 'labstep-web/components/User/Action/DisableMfa';
import UserActionEnableMfa from 'labstep-web/components/User/Action/EnableMfa';
import React from 'react';
import { IUserSettingsMfaProps } from './types';

const UserSettingsMfa: React.FC<IUserSettingsMfaProps> = ({
  user,
}) => {
  if (!user.userOrganization) {
    return null;
  }
  return (
    <div>
      <div>
        <span>Two Factor authentication</span>{' '}
      </div>
      <div>
        {user.is_two_factor_enabled ? (
          <UserActionDisableMfa />
        ) : (
          user.userOrganization.organization.hasFeatureEnabled(
            '2fa',
          ) && <UserActionEnableMfa />
        )}
      </div>
    </div>
  );
};

export default UserSettingsMfa;
