/**
 * Labstep
 *
 * @module components/Notification/Card/Preview
 * @desc Preview Card for single notification
 */

import CommentItem from 'labstep-web/components/Comment/Item';
import SideMenu from 'labstep-web/components/Layout/Menu/Side';
import ThreadModal from 'labstep-web/components/Thread/Modal';
import { EntityUpdateContainer } from 'labstep-web/containers/Entity/Update';
import { ReadOnMountHOC } from 'labstep-web/hoc/ReadOnMount';
import { Comment } from 'labstep-web/models/comment.model';
import { Notification as NotificationModel } from 'labstep-web/models/notification.model';
import { generateNewDateString } from 'labstep-web/services/date.service';
import React from 'react';
import DesktopPreview from './Desktop';
import styles from './styles.module.scss';
import {
  ICommentSideMenuProps,
  NotificationPreviewCardContainerProps,
  NotificationPreviewCardProps,
} from './types';

export const CommentSideMenu: React.FC<ICommentSideMenuProps> = ({
  notification,
  markAsRead,
  children,
}) => {
  if (!notification.metadata?.parent_thread_id) {
    return null;
  }
  return (
    <SideMenu
      header="Thread"
      name="sub-comment-thread"
      viewComponent={({ toggleSideMenu }) => (
        <div
          className={styles.viewContainer}
          onClick={() => {
            toggleSideMenu();
            markAsRead();
          }}
        >
          {children}
        </div>
      )}
    >
      <ThreadModal
        showCreateComment={false}
        threadId={notification.metadata.parent_thread_id}
        top={
          <ReadOnMountHOC
            type="entities"
            entityName={Comment.entityName}
            loading={{ loader: 'placeholder', multiplier: 1 }}
            params={{
              get_single: 1,
              is_deleted: 'both',
              id: notification.metadata.parent_thread_comment_id,
            }}
            children={({ entity }) => (
              <CommentItem readOnly comment={entity} />
            )}
          />
        }
      />
    </SideMenu>
  );
};

export const NotificationPreviewCard: React.FC<
  NotificationPreviewCardProps
> = ({ notification, update }) => {
  const markAsRead = () => {
    update({ read_at: generateNewDateString() });
  };

  const preview = <DesktopPreview notification={notification} />;

  if (
    notification.metadata &&
    notification.metadata.parent_thread_id
  ) {
    return (
      <CommentSideMenu
        children={preview}
        markAsRead={markAsRead}
        notification={notification}
      />
    );
  }

  return <div onClick={markAsRead}>{preview}</div>;
};

const NotificationPreviewCardContainer = (
  props: NotificationPreviewCardContainerProps,
) => (
  <EntityUpdateContainer
    entityName={NotificationModel.entityName}
    id={props.notification.id}
  >
    {(containerProps) => (
      <NotificationPreviewCard {...props} {...containerProps} />
    )}
  </EntityUpdateContainer>
);

export default NotificationPreviewCardContainer;
