/**
 * Labstep
 *
 * @module core/Action/Icon
 * @desc Icon component that prompts an action
 */

import Icon from 'labstep-web/core/Icon';
import React from 'react';
import styles from './styles.module.scss';
import { IActionPreviewProps } from './types';

export const ActionPreview: React.FC<IActionPreviewProps> = ({
  onClick,
}) => {
  return (
    <div className={styles.preview} onClick={onClick}>
      <Icon name="window restore" />
      <small className={styles.label}>Preview</small>
    </div>
  );
};

export default ActionPreview;
