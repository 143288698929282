/**
 * Labstep
 *
 * @module components/Tag/Item
 * @desc Tag label
 */

import RemoveTagAction from 'labstep-web/components/Tag/Action/Remove';
import Icon from 'labstep-web/core/Icon';
import Label from 'labstep-web/core/Label';
import React from 'react';
import styles from './styles.module.scss';
import { ITagItemProps } from './types';

const TagItem: React.FC<ITagItemProps> = ({
  tag,
  parentEntity,
  showRemoveAction = true,
}) => (
  <Label size="large" circular className={styles.tagItem}>
    <span className={styles.tagName}>{tag.name}</span>
    {showRemoveAction && (
      <span className={styles.removeAction}>
        <RemoveTagAction
          parentEntity={parentEntity}
          tag={tag}
          actionComponentProps={{
            type: 'children', // This is needed because default icon type action components have square background on hover effect.
            children: ({ onClick, status }) => (
              <Icon
                name="times"
                onClick={onClick}
                disabled={(status && status.isFetching) || false}
              />
            ),
          }}
        />
      </span>
    )}
  </Label>
);

export default TagItem;
