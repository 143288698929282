/**
 * Labstep
 *
 * @module components/User/Preview
 * @desc A component showing user profile and link
 */

import EntityLinkComponent from 'labstep-web/components/Entity/Link/Component';
import ProfilePicture from 'labstep-web/components/Profile/Picture';
import { Size } from 'labstep-web/core/Initials/types';
import React from 'react';
import UserShowProfile from '../Show/Profile';
import styles from './styles.module.scss';
import { IUserPreviewProps } from './types';

export const UserPreview: React.FC<IUserPreviewProps> = ({
  user,
}) => (
  <div className={styles.container}>
    <ProfilePicture size={Size.mini} entity={user} />
    <UserShowProfile
      user={user}
      viewComponent={<EntityLinkComponent entity={user} />}
    />
  </div>
);

export default UserPreview;
