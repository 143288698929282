/**
 * Labstep
 *
 * @module components/EntityStateWorkflow/Preview
 * @desc EntityStateWorkflow Preview
 */

import Flex from 'labstep-web/core/Flex';
import Icon from 'labstep-web/core/Icon';
import PopupContainer from 'labstep-web/core/Popup';
import StatusCard from 'labstep-web/core/StatusCard';
import React from 'react';
import EntityStateWorkflowPreviewDetails from './Details';
import { EntityStateWorkflowPreviewProps } from './types';

const EntityStateWorkflowPreview: React.FC<
  EntityStateWorkflowPreviewProps
> = ({ entityStateWorkflow }) => (
  <>
    {entityStateWorkflow.entity_states.map((entityState, index) => (
      <Flex
        column
        spacing="gap"
        key={entityState.guid}
        data-testid={entityState.guid}
      >
        {index > 0 && (
          <Flex spacing="gap" marginTop={10} vAlignContent="center">
            <Icon name="arrow down" size="big" />
            <EntityStateWorkflowPreviewDetails
              previousEntityState={
                entityStateWorkflow.entity_states[index - 1]
              }
              currentEntityState={entityState}
            />
          </Flex>
        )}
        <Flex spacing="gap">
          <StatusCard
            text={entityState.name}
            color={entityState.color}
            icon={entityState.icon}
            active={false}
            hoverable={false}
            onHoverComponent={
              entityState.printPermissions !== 'Unlocked' ? (
                <PopupContainer
                  trigger={<Icon name="lock" size="large" />}
                  position="right center"
                  content={`Lock Mode: ${entityState.printPermissions}`}
                />
              ) : null
            }
          />
        </Flex>
      </Flex>
    ))}
  </>
);

export default EntityStateWorkflowPreview;
