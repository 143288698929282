/**
 * Labstep
 *
 * @module components/Metadata/Action/Create/Modal
 * @desc Modal to create a metadata
 */

import { CanCreate } from 'labstep-web/components/Entity/Can';
import MetadataFormCreate from 'labstep-web/components/Metadata/Form/Create';
import ActionComponent from 'labstep-web/core/Action/Component';
import { useResetForm } from 'labstep-web/core/Form/Reset/utils';
import ModalDefault from 'labstep-web/core/Modal/Default';
import { Metadata } from 'labstep-web/models/metadata';
import { enhanceOptions } from 'labstep-web/services/utils.service';
import React from 'react';
import MetadataActionCreateModalMessage from './Message';
import {
  MetadataActionCreateContainerProps,
  MetadataActionCreateProps,
} from './types';

export const MetadataActionCreateModal: React.FC<
  MetadataActionCreateProps
> = ({
  entity,
  actionComponentProps,
  options,
  modalProps,
  isTemplate,
  isHeaderPlural = false,
  entityName,
  disableAddAnother,
  ...rest
}) => {
  const { key, submitButton, onSuccess } =
    useResetForm(disableAddAnother);
  const getActionLabel = (isPlural?: boolean): string =>
    `Add ${Metadata.getHumanReadableEntityName(
      isPlural,
      true,
      entity,
    )}`;

  return (
    <ModalDefault
      size="tiny"
      header={getActionLabel(isHeaderPlural)}
      viewComponent={({ toggleModal }): React.ReactElement => (
        <ActionComponent
          dataTestId="metadata-action-create"
          onClick={toggleModal}
          type="button"
          text={getActionLabel(false)}
          {...actionComponentProps}
        />
      )}
      content={({ toggleModal }): React.ReactElement => (
        <div>
          {!!isTemplate && (
            <MetadataActionCreateModalMessage
              entityName={entityName}
            />
          )}
          <MetadataFormCreate
            key={key}
            isTemplate={isTemplate}
            entity={entity}
            options={enhanceOptions({
              options,
              toast: true,
              onSuccess: () => onSuccess(toggleModal),
            })}
            submitButton={submitButton}
            {...rest}
          />
        </div>
      )}
      {...modalProps}
    />
  );
};

export const MetadataActionCreateModalContainer: React.FC<
  MetadataActionCreateContainerProps
> = ({ entity, ...rest }) => (
  <CanCreate
    parentName={entity.metadata_thread.entityName}
    parentId={entity.metadata_thread.idAttr}
    entityName="metadata"
  >
    <MetadataActionCreateModal {...rest} entity={entity} />
  </CanCreate>
);

export default MetadataActionCreateModalContainer;
