/**
 * Labstep
 */

import SignatureListEntityState from 'labstep-web/components/Signature/List/EntityState';
import React from 'react';
import { ExperimentWorkflowActionUpdateEntityStateModalWizardStepSignaturesProps } from './types';

const ExperimentWorkflowActionUpdateEntityStateModalWizardStepSignatures: React.FC<
  ExperimentWorkflowActionUpdateEntityStateModalWizardStepSignaturesProps
> = ({ experimentWorkflow, entityState }) => {
  return (
    <SignatureListEntityState
      entityState={entityState}
      experimentWorkflow={experimentWorkflow}
      round={experimentWorkflow.entity_state_round}
      showState={false}
    />
  );
};

export default ExperimentWorkflowActionUpdateEntityStateModalWizardStepSignatures;
