/**
 * Labstep
 *
 * @module components/Permission/Table
 * @desc Display a list of permissions as table
 */

import GroupInitials from 'labstep-web/components/Group/Initials';
import PermissionActionRevoke from 'labstep-web/components/Permission/Action/Revoke';
import PermissionFormShowEditType from 'labstep-web/components/Permission/Form/ShowEdit/Type';
import { Size } from 'labstep-web/core/Initials/types';
import Table from 'labstep-web/core/Table';
import { Group } from 'labstep-web/models/group.model';
import { getHumanReadableEntityName } from 'labstep-web/services/i18n.service';
import React from 'react';
import styles from './styles.module.scss';
import { PermissionTableProps } from './types';

const PermissionTable: React.FC<PermissionTableProps> = ({
  parentEntity,
  permissions,
}) => (
  <div>
    <br />
    <span>{`${getHumanReadableEntityName(
      Group.entityName,
      true,
      true,
    )} with access`}</span>
    <div className={styles.tableContainer}>
      <Table striped unstackable id="permissions">
        <Table.Body>
          <Table.Row key="owner">
            <Table.Cell width={2}>
              <GroupInitials
                group={parentEntity.owner}
                size={Size.mini}
              />
            </Table.Cell>
            <Table.Cell width={10} textAlign="left">
              {parentEntity.owner.name}
            </Table.Cell>
            <Table.Cell width={2}>Owner</Table.Cell>
          </Table.Row>
          {permissions.map((permission) => (
            <Table.Row key={permission.group.id}>
              <Table.Cell width={2}>
                <GroupInitials
                  group={permission.group}
                  size={Size.mini}
                />
              </Table.Cell>
              <Table.Cell width={10} textAlign="left">
                {permission.group.name}
              </Table.Cell>

              <Table.Cell width={2}>
                {permission.type !== 'owner' && (
                  <PermissionFormShowEditType
                    permission={permission}
                  />
                )}
              </Table.Cell>
              <Table.Cell textAlign="right" width={2}>
                {parentEntity.owner.id !== permission.group.id && (
                  <PermissionActionRevoke permission={permission} />
                )}
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </div>
  </div>
);

export default PermissionTable;
