/**
 * Labstep
 *
 * @module screens/ResourceTemplate/Show
 * @desc ResourceTemplate Show Screen
 */

import DocumentHead from 'labstep-web/core/DocumentHead';
import Redirect from 'labstep-web/core/Redirect';
import { ReadOnMountHOC } from 'labstep-web/hoc/ReadOnMount';
import { Resource } from 'labstep-web/models/resource.model';
import MasterShow from 'labstep-web/screens/Master/Show';
import React from 'react';
import Center from './Center';
import Right from './Right';
import {
  IResourceTemplateShowScreenContainerProps,
  IResourceTemplateShowScreenProps,
} from './types';

export const ResourceTemplateShowScreen: React.FC<
  IResourceTemplateShowScreenProps
> = ({ resourceTemplate }) => (
  <>
    <DocumentHead title={resourceTemplate.name || ''} />
    <MasterShow
      entity={resourceTemplate}
      center={<Center resourceTemplate={resourceTemplate} />}
      right={<Right resourceTemplate={resourceTemplate} />}
    />
  </>
);

const ResourceTemplateShowScreenContainer: React.FC<
  IResourceTemplateShowScreenContainerProps
> = ({ match }) => (
  <ReadOnMountHOC
    type="entities"
    entityName={Resource.entityName}
    params={{
      get_single: 1,
      is_deleted: 'both',
      id: match.params.id,
    }}
    loading={{ loader: 'show', cached: true }}
    children={({ entity }) =>
      !entity.is_template ? (
        <Redirect to="resource_show" params={{ id: entity.id }} />
      ) : (
        <ResourceTemplateShowScreen resourceTemplate={entity} />
      )
    }
  />
);

export default ResourceTemplateShowScreenContainer;
