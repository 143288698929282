/**
 * Labstep
 *
 * @module state/epics/log
 * @desc Redux epic for log fields
 */

import { Action } from 'labstep-web/models/action.model';
import { selectAuthenticatedUser } from 'labstep-web/state/selectors/authenticated-user';
import { StateObservable } from 'redux-observable';
import { Observable, concat, of } from 'rxjs';
import { catchError, filter, map } from 'rxjs/operators';
import { LabstepReduxState } from '../types';

interface CONFIG_RECORD {
  bodyField: string;
  message: (payload: Record<string, unknown>) => string;
  logName: (payload: Record<string, unknown>) => string;
}

type LOG_CONFIG_TYPE = Record<string, CONFIG_RECORD[]>;

const LOG_TYPES: LOG_CONFIG_TYPE = {
  SUCCESS_UPDATE_EXPERIMENT_WORKFLOW: [
    {
      bodyField: 'locked_at',
      message: (): string => 'locked this experiment',
      logName: (): string => 'locked_log',
    },
  ],
  SUCCESS_UPDATE_EXPERIMENT: [
    {
      bodyField: 'locked_at',
      message: (): string => 'locked this experiment',
      logName: (): string => 'locked_log',
    },
  ],
  SUCCESS_UPDATE_PURCHASE_ORDER: [
    {
      bodyField: 'locked_at',
      message: (): string => 'locked this order',
      logName: (): string => 'locked_log',
    },
    {
      bodyField: 'status',
      message: (payload): string => {
        let message = '';
        if (payload.status === 'open') {
          message = 'reopened the order';
        }
        if (payload.status === 'pending') {
          message = 'finalised the order';
        }
        if (payload.status === 'completed') {
          message = 'marked the order as complete';
        }
        return message;
      },
      logName: (payload): string => `${payload.status}_log`,
    },
  ],
  SUCCESS_UPDATE_PROTOCOL_VALUE: [
    {
      bodyField: 'amount_deducted_at',
      message: (payload): string =>
        `deducted ${payload.value}${
          payload.units ? ` ${payload.units}` : ''
        }`,

      logName: (): string => 'amount_deducted_log',
    },
  ],
  SUCCESS_UPDATE_ORDER_REQUEST: [
    {
      bodyField: 'status',
      logName: (payload): string => `${payload.status}_log`,
      message: (payload): string =>
        `changed the status to ${(payload.status as string).replace(
          '_',
          ' ',
        )}`,
    },
  ],
};

/**
 * Inject log (as this is generate async on backend)
 *
 * @function
 * @param  {Observable<Action>} action$
 * @param  {StateObservable<LabstepReduxState>} state$
 * @return {Observable<Action>}
 */
export const injectLogEpic = (
  action$: Observable<Action>,
  state$: StateObservable<LabstepReduxState>,
): Observable<Action> =>
  action$.pipe(
    filter(
      (action: Action) =>
        !!LOG_TYPES[action.type]?.find(
          (type) => !!(action.meta!.body as any)[type.bodyField],
        ) && !action.meta!.injected_log_fields,
    ),
    map((action: Action) => {
      const logType = LOG_TYPES[action.type].find(
        (type) => !!(action.meta!.body as any)[type.bodyField],
      );
      const authenticatedUser = selectAuthenticatedUser(state$.value);
      const denormalizedPayload = action.meta!.denormalized_payload;
      const logId = (action.payload as any).result + 100000000000;
      const log = {
        id: logId,
        created_at: (denormalizedPayload as any).updated_at as string,
        message: logType!.message(denormalizedPayload as any),
        author: authenticatedUser.id,
      };
      // We don't want onSuccess and onFail to be called twice.
      const { onSuccess, onFail, ...meta }: any = action.meta;
      return {
        type: action.type,
        meta: {
          ...meta,
          injected_log_fields: true,
        },
        payload: {
          ...(action.payload as any),
          entities: {
            [action.meta!.normalize as string]: {
              [action.meta!.identifier as string]: {
                ...((action.payload as any).entities as any)[
                  action.meta!.normalize as string
                ][action.meta!.identifier as string],
                [logType!.logName(denormalizedPayload as any)]: logId,
              },
            },
            log: {
              [logId]: log,
            },
          },
        },
      };
    }),
    catchError((err, source$: Observable<Action>) =>
      concat(
        of({
          type: 'EPIC_FAIL_INJECT_LOG',
          payload: err,
        }),
        source$,
      ),
    ),
  );
