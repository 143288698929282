/**
 * Labstep
 *
 * @module components/Tag/Action/Menu
 * @desc Action Menu for protocol
 */

import DeleteTagAction from 'labstep-web/components/Tag/Action/Delete';
import EditTagAction from 'labstep-web/components/Tag/Action/Edit';
import TagActionViewAll from 'labstep-web/components/Tag/Action/ViewAll';
import ActionMenu from 'labstep-web/core/Action/Menu';
import React from 'react';
import { ITagActionMenuProps } from './types';

const TagActionMenu: React.FC<ITagActionMenuProps> = ({
  tag,
  actions = [],
}) => (
  <ActionMenu>
    {actions.includes('edit') && <EditTagAction tag={tag} />}
    {actions.includes('delete') && <DeleteTagAction tag={tag} />}
    {actions.includes('view_all') && <TagActionViewAll />}
  </ActionMenu>
);

TagActionMenu.defaultProps = {
  actions: [],
};

export default TagActionMenu;
