/**
 * Labstep
 *
 * @module components/Protocol/Action/Duplicate
 * @desc Duplicate Button. On clicking it show a confirmation dialog.
 * If confrimed it creates a new protocol collection with a copy of
 * this protocol as its child and then redirects the user to the
 * newly created protocol
 */

import { CanCreate } from 'labstep-web/components/Entity/Can';
import ProtocolFormDuplicate from 'labstep-web/components/Protocol/Form/Duplicate';
import ActionComponent from 'labstep-web/core/Action/Component';
import DefaultModal from 'labstep-web/core/Modal/Default';
import { Protocol } from 'labstep-web/models/protocol.model';
import { navigation } from 'labstep-web/services/navigation';
import React from 'react';
import { withRouter } from 'react-router';
import { IProtocolActionDuplicateProps } from './types';

export const ProtocolActionDuplicate: React.FC<
  IProtocolActionDuplicateProps
> = ({ protocol: entity, history, actionComponentProps }) => (
  <CanCreate entityName="protocol_collection">
    <DefaultModal
      header="Duplicate Protocol"
      viewComponent={({ toggleModal }) => (
        <ActionComponent
          type="option"
          {...actionComponentProps}
          icon="clone"
          text="Duplicate"
          onClick={toggleModal}
        />
      )}
      content={() => (
        <ProtocolFormDuplicate
          parent={entity}
          options={{
            onSuccess: ({ response }) => {
              if (!response) {
                return;
              }
              const protocol =
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                response.entities.protocol[response.result as any];
              const protocolCollectionId = (protocol as Protocol)
                .collection;
              history.push(
                navigation.get('protocol_collection_show', {
                  id: protocolCollectionId,
                }),
              );
            },
          }}
        />
      )}
    />
  </CanCreate>
);

export default withRouter(ProtocolActionDuplicate);
