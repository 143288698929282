/**
 * Labstep
 *
 * @module state/selectors/helpers
 * @desc Helper methods for all selectors
 * FIXME Refactor most of these helpers into models
 */

import { Protocol } from 'labstep-web/models/protocol.model';
import { User } from 'labstep-web/models/user.model';
import { isEqual } from 'lodash';
import { createSelectorCreator, defaultMemoize } from 'reselect';

/**
 * Create a "selector creator" that uses lodash.isequal instead of ===
 */
export const createDeepEqualSelector = createSelectorCreator(
  defaultMemoize,
  isEqual,
);

/**
 * FIXME Missing documentation
 */
export const isEmpty = (obj: any): boolean =>
  typeof obj === 'object' &&
  Object.keys(obj).length === 0 &&
  obj.constructor === Object;

/**
 * FIXME Missing documentation
 */
export const hasKey = (obj: any, key: string): boolean => key in obj;

/**
 * FIXME Missing documentation
 */
export const validateEmail = (email: string): boolean => {
  const reg = /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$/;

  return reg.test(email);
};

/**
 * Returns an object with all the nested entities needed for the denormalizr
 */
export const getNestedEntitiesObject = (state: any): any => {
  const entities = Object.keys(state.entities).reduce(
    (result: any, current: any) => ({
      ...result,
      [current]: state.entities[current].byId,
    }),
    {},
  );
  return entities;
};

/**
 * Merge multiple statuses
 */
export const mergeStatuses = (statuses: any[]): any =>
  statuses.reduce((result: any, current: any) => {
    if (!current) {
      return result;
    }
    const isFetching = result.isFetching || current.isFetching;
    const error = result.error || current.error;
    return { isFetching, error };
  }, {});

/**
 * Returns a status with isFetching is true
 * This can be used for components that fire an action in componentDidMount and therefore
 * the status will be false/undefined briefly when the component first mounts and we
 * want to show a loading state instead
 */
export const defaultFetchingStatus = (status: any): any => {
  const defaultStatus: any = { isFetching: true, error: null };
  return !status || status == null || isEmpty(status)
    ? defaultStatus
    : status;
};

/**
 * Returns a status with isFetching is false
 * This can be used for components that might need the status
 * at some point but initally the status doesn't exist (usually when editing)
 */
export const defaultNotFetchingStatus = (status: any): any => {
  const defaultStatus: any = { isFetching: false, error: null };
  return !status || status == null || isEmpty(status)
    ? defaultStatus
    : status;
};

/**
 * FIXME Missing documentation
 */
export const fetchingStatusWhileEmpty = (status?: any): any =>
  !status || isEmpty(status)
    ? { isFetching: true, error: null }
    : { isFetching: false, error: null };

/**
 * FIXME Missing documentation
 */
export const computeCanCompleteProtocol = (
  protocol: Protocol,
): boolean => protocol.protocol_step_count + protocol.file_count > 0;

/**
 * FIXME Missing documentation
 */
export const computeIsStepProtocol = (protocol: Protocol): boolean =>
  protocol.protocol_step_count > 0;

/**
 * FIXME Missing documentation
 */
export const computeIsPdfProtocol = (protocol: Protocol): boolean =>
  protocol.file_count === 1 && protocol.protocol_step_count === 0;

/**
 * Returns true if user is enabled
 * @param  {Object} Authenticated User
 * @return {Bool}
 */
export const isAuthenticatedUserEnabled = (
  authenticatedUser: User,
): boolean => !!(authenticatedUser && authenticatedUser.enabled);
