/**
 * Labstep
 *
 * @module components/Protocol/Action/Create
 * @desc Create Action for protocol
 */

import EntityActionCreate from 'labstep-web/components/Entity/Action/Create';
import React from 'react';
import { IProtocolActionCreateProps } from './types';

export const ProtocolActionCreate: React.FC<
  IProtocolActionCreateProps
> = ({ body = {}, options, ...rest }) => (
  <EntityActionCreate
    entityName="protocol"
    body={body}
    options={options}
    {...rest}
  />
);

export default ProtocolActionCreate;
