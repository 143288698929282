/**
 * Labstep
 *
 * @module Signature/Form/CreateSignReject
 * @desc Signature Create Form
 */

import {
  fieldSignatureStatement,
  getFieldSignatureRequirementEntityStateOnReject,
} from 'labstep-web/components/Signature/Form/fields';
import { fieldUserCode } from 'labstep-web/components/User/Form/fields';
import { withActiveGroup } from 'labstep-web/containers/ActiveGroup';
import Button from 'labstep-web/core/Button';
import Flex from 'labstep-web/core/Flex';
import FormCreate from 'labstep-web/core/Form/Create';
import { IFieldProps } from 'labstep-web/core/Form/Reusable/types';
import { ExperimentWorkflow } from 'labstep-web/models/experiment-workflow.model';
import { Signature } from 'labstep-web/models/signature.model';
import React from 'react';
import { getSignatureRequirementForUser } from '../../Modal/utils';
import { SignatureTypeAction } from '../../SignAndRejectButtons/types';
import { ISignatureSignRejectFormCreateProps } from './types';
import { getDefaultStatement } from './utils';

export const SignatureSignRejectFormCreate: React.FC<
  ISignatureSignRejectFormCreateProps
> = ({
  experimentWorkflow,
  activeGroup,
  signatureTypeAction,
  toggleModal,
}) => {
  const signatureRequirement = getSignatureRequirementForUser(
    experimentWorkflow,
  );
  const entityUserRoleRequirement =
    experimentWorkflow.entity_state.entity_user_role_requirements[0];
  let fields: IFieldProps[] = [
    {
      ...fieldSignatureStatement,
      fieldLabel: `${signatureTypeAction} comment`,
    } as IFieldProps,
  ];

  if (experimentWorkflow.owner.is_signature_two_factor_required) {
    fields = [...fields, fieldUserCode];
  }
  const rejectEntityStateOptions =
    ExperimentWorkflow.getStatesBeforeCurrentState(
      experimentWorkflow,
      true,
    ).map((entityState) => ({
      value: entityState.id,
      label: entityState.name,
    }));
  if (signatureTypeAction === SignatureTypeAction.reject) {
    fields.push(
      getFieldSignatureRequirementEntityStateOnReject(
        rejectEntityStateOptions,
      ),
    );
  }

  let rejectEntityStateId = null;
  if (signatureTypeAction === SignatureTypeAction.reject) {
    if (signatureRequirement.reject_entity_state) {
      rejectEntityStateId = rejectEntityStateOptions.find(
        (o) =>
          o.value === signatureRequirement?.reject_entity_state?.id,
      );
    } else {
      rejectEntityStateId =
        rejectEntityStateOptions[rejectEntityStateOptions.length - 1];
    }
  }

  return (
    <div data-testid="create-sign-reject-form">
      <FormCreate
        key={signatureTypeAction}
        autoFocus
        fields={fields}
        entityName={Signature.entityName}
        parentName={ExperimentWorkflow.entityName}
        defaultValues={{
          reject_entity_state: rejectEntityStateId,
          is_lock: false,
          [fieldSignatureStatement.name]: getDefaultStatement(
            signatureTypeAction!,
            signatureRequirement,
            activeGroup,
          ),
        }}
        preSubmit={(form) => {
          const values: any = {
            statement: form.statement,
            is_lock: false,
            is_reject:
              signatureTypeAction === SignatureTypeAction.reject,
            signature_requirement_id: signatureRequirement?.id,
            entity_user_role_requirement_id:
              entityUserRoleRequirement.id,
          };
          if ((form.reject_entity_state as any)?.value) {
            values.reject_entity_state_id = (
              form.reject_entity_state as any
            ).value;
          }
          if (form[fieldUserCode.name]) {
            values[fieldUserCode.name] = form[fieldUserCode.name];
          }
          return values;
        }}
        parentId={experimentWorkflow.id}
        options={{ onSuccess: toggleModal }}
        submitButton={({ submitForm, status }) => (
          <Flex spacing="gap" data-testid="submit-buttons">
            <Button
              type="button"
              primary
              basic
              status={status}
              onClick={() => {
                toggleModal();
              }}
            >
              Cancel
            </Button>
            <Button
              status={status}
              type="button"
              primary
              onClick={() => {
                submitForm();
              }}
            >
              {signatureTypeAction}
            </Button>
          </Flex>
        )}
      />
    </div>
  );
};

export default withActiveGroup(SignatureSignRejectFormCreate);
