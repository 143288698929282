/**
 * Labstep
 *
 * @module components/NotificationAlert/Form
 * @desc Form Fields for NotificationAlert
 */

import {
  FieldType,
  FieldWithoutActionProps,
} from 'labstep-web/core/Form/Reusable/types';
import rules from 'labstep-web/services/validation/rules';

export const fieldNotificationAlertMessage: FieldWithoutActionProps =
  {
    fieldLabel: 'Message',
    name: 'message',
    fieldType: FieldType.TextArea,
    validation: rules.notification_alert.message,
    placeholder: 'Enter Message',
  };

export const fieldNotificationAlertMinutesBefore: FieldWithoutActionProps =
  {
    name: 'minutes_before',
    fieldType: FieldType.Text,
    validation: rules.notification_alert.minutes_before,
    placeholder: 'Enter minutes',
  };
