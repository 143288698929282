/**
 * Labstep
 *
 * @module components/EntityImport/Table/Container
 * @desc EntityImport table read container
 */

import { Action } from 'labstep-web/components/EntityImport/Action/Menu/types';
import EntityImportTable from 'labstep-web/components/EntityImport/Table';
import { ReadOnMountHOC } from 'labstep-web/hoc/ReadOnMount';
import { useActiveGroup } from 'labstep-web/hooks/activeGroup';
import { EntityImport } from 'labstep-web/models/entity-import.model';
import React from 'react';
import { EntityImportTableContainerProps } from './types';

const EntityImportTableContainer: React.FC<
  EntityImportTableContainerProps
> = ({ targetEntityName, params, actions = [Action.revert] }) => {
  const { activeGroup } = useActiveGroup();
  if (!activeGroup) {
    return null;
  }
  return (
    <ReadOnMountHOC
      type="cursor"
      params={{
        group_id: activeGroup.id,
        target_entity_name: targetEntityName,
        ...params,
      }}
      entityName={EntityImport.entityClass}
      children={({ entities }): React.ReactElement => (
        <EntityImportTable
          entityImports={entities}
          actions={actions}
        />
      )}
    />
  );
};

export default EntityImportTableContainer;
