/**
 * Labstep
 *
 * @module containers/Entity/Read/Count
 * @desc Container for reading entities count
 */

import { APICallOptions } from 'labstep-web/models';
import { Action } from 'labstep-web/models/action.model';
import { readEntitiesCount } from 'labstep-web/state/actions/entity';
import { readNotificationsCount } from 'labstep-web/state/actions/notification';
import {
  selectCursorTotal,
  selectReadCursorStatus,
} from 'labstep-web/state/selectors/entity';
import { LabstepReduxState } from 'labstep-web/state/types';
import { connect } from 'react-redux';
import {
  IEntityReadCountContainerChildrenProps,
  IEntityReadCountContainerProps,
} from './types';

const Container = ({
  children,
  ...rest
}: {
  children: (
    props: IEntityReadCountContainerChildrenProps,
  ) => React.ReactElement | null;
} & IEntityReadCountContainerChildrenProps): React.ReactElement | null =>
  children(rest);

const mapStateToProps = (
  state: LabstepReduxState,
  ownProps: IEntityReadCountContainerProps,
) => ({
  status: selectReadCursorStatus(state, ownProps.entityName, {
    ...ownProps.params,
    get_count: 1,
  }),
  total: selectCursorTotal(state, ownProps.entityName, {
    ...ownProps.params,
    get_count: 1,
  }),
});

const mapDispatchToProps = (
  dispatch: (args: Action) => void,
  ownProps: IEntityReadCountContainerProps,
) => ({
  /**
   * Read entities count
   * @param  {object} options - Additional options (onSucces, onFail e.t.c)
   */
  read(options?: APICallOptions) {
    if (ownProps.entityName === 'notification') {
      dispatch(readNotificationsCount());
    } else {
      dispatch(
        readEntitiesCount(
          ownProps.entityName,
          ownProps.params,
          options,
        ),
      );
    }
  },
});

/**
 * Read Cursor Container connecting a component with state
 * Returns orginal component with
 * read function, status of action, nextCursor and entities
 */
export const EntityReadCountContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(Container);
