/**
 * Labstep
 *
 * @module state/epics/index
 * @desc Redux epics
 * @see https://github.com/erikras/ducks-modular-redux
 */

import { Epic, combineEpics } from 'redux-observable';
import * as bugsnag from './bugsnag';
import * as chemistry from './chemistry';
import * as entity from './entity';
import * as jupyter from './jupyter';
import * as jwt from './jwt';
import * as log from './log';
import * as metadata from './metadata';
import * as multiplexing from './multiplexing';
import * as normalize from './normalize';
import * as notification from './notification';
import * as optimistic from './optimistic';
import * as print from './print';
import * as prosemirror from './prosemirror';
import * as resource from './resource';
import * as sfApi from './sf-api';
import * as thumbnail from './thumbnail';
import * as user from './user';
import * as websocket from './websocket';
import * as websocketMessage from './websocket-message';
import * as websocketRoom from './websocket-room';

const rootEpic: Epic = combineEpics(
  bugsnag.onLoginSuccessActionEpic,
  bugsnag.onFailActionEpic,
  bugsnag.onEpicFailActionEpic,
  bugsnag.onWebsocketDisconnectActionEpic,
  bugsnag.onWebsocketErrorActionEpic,
  bugsnag.onPrintProgressActionEpic,
  chemistry.recalcStoichiometryTable,
  entity.createEntityIfNotCreatingActionEpic,
  entity.readCursorActionEpic,
  entity.readNextPageActionEpic,
  jupyter.onJupyterAuthorizeRequest,
  jupyter.onJupyterAuthorizeSuccess,
  jupyter.onJupyterGetJupyterInstance,
  jupyter.onJupyterPostJupyterInstance,
  jwt.onFailRefreshTokenActionEpic,
  jwt.onFailInvalidJwtActionEpic,
  log.injectLogEpic,
  metadata.reloadParentEpic,
  multiplexing.protocolConditionCascadeCreateEpic,
  multiplexing.protocolConditionCascadeDeleteEpic,
  normalize.normalizeActionEpic,
  notification.onReloadCountActionEpic,
  notification.onReadMoreActionEpic,
  notification.onAnnouncementToastActionEpic,
  notification.onSignupAnnouncementToastActionEpic,
  optimistic.onFailUpdateActionEpic,
  print.onPrintStartActionEpic,
  print.onPrintProgressActionEpic,
  prosemirror.requestFreshDataActionSuccessEpic,
  prosemirror.forceRefreshActionSuccessEpic,
  prosemirror.conflictToastActionSuccessEpic,
  resource.reloadAfterCreateResourceItemTemplateActionEpic,
  sfApi.onMaintenanceActionEpic,
  sfApi.onRequestActionEpic,
  sfApi.onSuccessActionEpic,
  sfApi.onFailActionEpic,
  thumbnail.onThumbnailFetchSrcValid,
  thumbnail.onThumbnailCheckUrlValid,
  thumbnail.onSuccessActionEpic,
  thumbnail.refreshStatuses,
  user.onDisabledAccountActionEpic,
  user.onSocialMediaLoginActionEpic,
  user.onLogoutStartActionEpic,
  user.onLogoutGoogleActionEpic,
  user.onLogoutSuccessActionEpic,
  user.onInternalRegisterActionEpic,
  user.onInternalRegisterSuccessActionEpic,
  user.onReadAuthenticatedUserActionEpic,
  user.onNewUserActionEpic,
  user.onSAMLLoginSuccessActionEpic,
  user.onSuccessGetSAMLLoginUrlActionEpic,
  user.onLoginSamlUserActionEpic,
  user.onStartLogoutEpic,
  user.onSuccessCreateOrganizationEpic,
  user.onLeaveWorkspaceEpic,
  websocket.onTriggerInitActionEpic,
  websocket.onReconnectActionEpic,
  websocket.onTriggerDestroyActionEpic,
  websocket.onInitActionEpic,
  websocket.onDestroyActionEpic,
  websocket.onCheckConnectedActionEpic,
  websocketMessage.onMessagePrintActionEpic,
  websocketMessage.onMessageNotificationActionEpic,
  websocketMessage.onMessageLiveExperimentActionEpic,
  websocketMessage.onMessageDataChangeActionEpic,
  websocketRoom.onLocationChangeUpdateRoomActionEpic,
  websocketRoom.onJoinRoomActionEpic,
  websocketRoom.onWebsocketConnectJoinRoomActionEpic,
);

export default rootEpic;
