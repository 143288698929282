/**
 * Labstep
 *
 * @module components/OrderRequest/Action/UpdateUrgent
 * @desc Action to update is_urgent field
 */

import EntityActionUpdateCheckbox from 'labstep-web/components/Entity/Action/Update/Checkbox';
import React from 'react';
import { OrderRequestActionUpdateUrgentProps } from './types';

const OrderRequestActionUpdateUrgent: React.FC<
  OrderRequestActionUpdateUrgentProps
> = ({ orderRequest }) => (
  <EntityActionUpdateCheckbox
    entity={orderRequest}
    field={'is_urgent' as const}
    optimistic
  />
);

export default OrderRequestActionUpdateUrgent;
