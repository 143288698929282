/**
 * Labstep
 *
 * @module components/App/Routes/Modals
 * @desc Modals for the main app
 */

import EntityImportModal from 'labstep-web/components/EntityImport/Modal';
import { EntityStateWorkflowActionConfigureWorkflow } from 'labstep-web/components/EntityStateWorkflow/Action/ConfigureWorkflow';
import ExperimentWorkflowActionUpdateEntityStateModalWizard from 'labstep-web/components/ExperimentWorkflow/Action/UpdateEntityState/ModalWizard';
import OrderRequestActionCreateWizard from 'labstep-web/components/OrderRequest/Action/Create/Wizard';

const AppRoutesModals = () => (
  <>
    <OrderRequestActionCreateWizard />
    <EntityImportModal />
    <ExperimentWorkflowActionUpdateEntityStateModalWizard />
    <EntityStateWorkflowActionConfigureWorkflow />
  </>
);

export default AppRoutesModals;
