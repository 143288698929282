/**
 * Labstep
 *
 * @module components/PurchaseOrder/Action/UpdateStatus
 * @desc An action to update purchase order status (sequentially)
 */

import EntityActionUpdate from 'labstep-web/components/Entity/Action/Update';
import { generateNewDateString } from 'labstep-web/services/date.service';
import React from 'react';
import { IPurchaseOrderActionUpdateStatusProps } from './types';

const PurchaseOrderActionUpdateStatus: React.FC<
  IPurchaseOrderActionUpdateStatusProps
> = ({ purchaseOrder, actionComponentProps = {}, options = {} }) => {
  let text;
  let body: Record<string, unknown> = {};
  if (
    purchaseOrder.status === 'completed' ||
    purchaseOrder.nonAttachedOrderRequests.length === 0
  ) {
    return null;
  }

  if (purchaseOrder.status === 'open') {
    text = 'Finalise Order';
    body = { status: 'pending' };
  }

  if (purchaseOrder.status === 'pending') {
    text = 'Mark as Complete';
    body = {
      status: 'completed',
      locked_at: generateNewDateString(),
    };
  }

  return (
    <EntityActionUpdate
      entityName={purchaseOrder.entityName}
      id={purchaseOrder.id}
      body={body}
      options={options}
      actionComponentProps={{
        type: 'button',
        text,
        modalConfirmationProps: {
          header: 'Warning',
          message: (
            <div>
              <p>
                {purchaseOrder.status === 'open'
                  ? 'Once you finalise an order you will no longer be able to add / remove order requests from it.'
                  : 'Once marking the order as complete, the order requests are no longer editable and can only be unlocked by the workspace owner.'}
              </p>
              <p>Are you sure you wish to proceed?</p>
            </div>
          ),
        },
        ...actionComponentProps,
      }}
    />
  );
};

export default PurchaseOrderActionUpdateStatus;
