/**
 * Labstep
 *
 * @module components/ProtocolCollection/Card/Overview
 * @desc Overview Card for protocolCollections
 */

import EntityCardOverview from 'labstep-web/components/Entity/Card/Overview';
import ExperimentWorkflowActionCreate from 'labstep-web/components/ExperimentWorkflow/Action/Create';
import { ProtocolCollectionActionCreate } from 'labstep-web/components/ProtocolCollection/Action/Create';
import ProtocolCollectionActionMenu from 'labstep-web/components/ProtocolCollection/Action/Menu';
import { ProtocolCollectionEmptyState } from 'labstep-web/components/ProtocolCollection/Card/EmptyState';
import { actions } from 'labstep-web/components/ProtocolCollection/ListOrTable/constants';
import { ICONS } from 'labstep-web/constants/icons';
import { ProtocolCollection } from 'labstep-web/models/protocol-collection.model';
import { Protocol } from 'labstep-web/models/protocol.model';
import React from 'react';
import styles from './styles.module.scss';
import { IProtocolCollectionCardOverviewProps } from './types';

export const ProtocolCollectionCardOverview: React.FC<
  IProtocolCollectionCardOverviewProps
> = (props) => (
  <EntityCardOverview
    {...props}
    emptyState={<ProtocolCollectionEmptyState title={undefined} />}
    entityName={ProtocolCollection.entityName}
    icon={ICONS.protocol_collection.primary}
    subAction={({ entity }) =>
      (entity as ProtocolCollection).last_version && (
        <ExperimentWorkflowActionCreate
          actionComponentProps={{
            type: 'button',
            elementProps: {
              basic: true,
              primary: true,
            },
          }}
          protocol={
            (entity as ProtocolCollection).last_version as Protocol
          }
        />
      )
    }
    actionMenu={({ entity }) => (
      <div className={styles.actions}>
        <ProtocolCollectionActionMenu
          protocolCollection={entity as ProtocolCollection}
          actions={actions}
        />
      </div>
    )}
    action={<ProtocolCollectionActionCreate />}
    title="Most Used Protocols"
    params={{ ...props.params, sort: '-experiment_count' }}
  />
);

export default ProtocolCollectionCardOverview;
