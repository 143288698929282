/**
 * Labstep
 *
 * @module components/ResourceItem/Action/UpdateStockCheck
 * @desc Button to update stock check
 */

import { ICONS } from 'labstep-web/constants/icons';
import { EntityUpdateContainer } from 'labstep-web/containers/Entity/Update';
import ActionComponent from 'labstep-web/core/Action/Component';
import { ResourceItem } from 'labstep-web/models/resource-item.model';
import { generateNewDateString } from 'labstep-web/services/date.service';
import React from 'react';
import { IResourceItemActionUpdateStockCheckProps } from './types';

const ResourceItemActionUpdateStockCheck: React.FC<
  IResourceItemActionUpdateStockCheckProps
> = ({ resourceItem }) => {
  return (
    <EntityUpdateContainer
      entityName={ResourceItem.entityName}
      id={resourceItem.id}
    >
      {({ update, status }) => (
        <ActionComponent
          type="icon"
          icon={ICONS.resource_item.stock_check}
          onClick={() => {
            update({ stock_checked_at: generateNewDateString() });
          }}
          elementProps={{
            color: status!.isFetching ? 'green' : 'yellow',
            size: 'big',
            noBackground: true,
          }}
        />
      )}
    </EntityUpdateContainer>
  );
};

export default ResourceItemActionUpdateStockCheck;
