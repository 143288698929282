/**
 * Labstep
 */

import { ColDef, ColGroupDef } from 'labstep-web/core/DataGrid/types';
import { ProtocolCondition } from 'labstep-web/models/protocol-condition.model';
import { DataGridColumnHeaderMergeAsTypes } from './types';

/** Custom fn to merge a parent and a child header */
export const getDoubleHeader = (
  children: ColDef<ProtocolCondition>,
): ColGroupDef<ProtocolCondition> => ({
  headerClass: 'parent-header',
  headerName: children.headerName,
  headerGroupComponentParams: {
    ...children.headerComponentParams,
    mergeAs: DataGridColumnHeaderMergeAsTypes.parent,
  },
  children: [
    {
      ...children,
      headerClass: 'child-header',
      headerComponentParams: {
        ...children.headerComponentParams,
        mergeAs: DataGridColumnHeaderMergeAsTypes.child,
      },
    },
  ],
});
