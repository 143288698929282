/**
 * Labstep
 *
 * @module core/SearchBar/Toggle
 * @desc Search bar with toggle
 */

import ActionComponent from 'labstep-web/core/Action/Component';
import Icon from 'labstep-web/core/Icon';
import SearchBar from 'labstep-web/core/SearchBar';
import { useToggle } from 'labstep-web/hooks/toggle';
import React, { Ref, useEffect, useRef } from 'react';
import { Input } from 'semantic-ui-react';
import styles from './styles.module.scss';
import { ISearchBarToggleProps } from './types';

const SearchBarToggle: React.FC<ISearchBarToggleProps> = ({
  viewComponent,
  onClose,
  ...rest
}) => {
  const [isSearchActive, toggleIsSearchActive] = useToggle();

  const inputRef: Ref<Input> | undefined = useRef(null);
  useEffect(() => {
    if (inputRef?.current) {
      inputRef.current.focus();
    }
  }, [isSearchActive]);

  const handleOnClose = () => {
    toggleIsSearchActive();
    if (onClose) {
      onClose();
    }
  };

  return isSearchActive ? (
    <div className={styles.searchContainer}>
      <SearchBar
        {...rest}
        inputRef={inputRef}
        icon={<Icon name="search" />}
        action={
          <Icon
            className={styles.close}
            name="times"
            onClick={handleOnClose}
            link
            data-testid="close-icon"
          />
        }
      />
    </div>
  ) : (
    <div className={styles.viewContainer}>
      <div>{viewComponent}</div>
      <ActionComponent
        dataTestId="search-icon"
        type="icon"
        icon="search"
        elementProps={{ popup: { content: 'Search' } }}
        onClick={toggleIsSearchActive}
      />
    </div>
  );
};

export default SearchBarToggle;
