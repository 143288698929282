/**
 * Labstep
 *
 * @module screens/File/Index
 * @desc File index
 */

import FileActionCreate from 'labstep-web/components/File/Action/Create';
import FileCardEmptyState from 'labstep-web/components/File/Card/EmptyState';
import FileListOrTable from 'labstep-web/components/File/ListOrTable';
import GuardGroupCreate from 'labstep-web/components/Guard/GroupCreate';
import { File } from 'labstep-web/models/file.model';
import MasterIndex from 'labstep-web/screens/Master/Index';
import React, { useMemo } from 'react';
import FileIndexLeftPane from './LeftPane';
import { filters } from './constants';
import { IFileIndexProps } from './types';

export const FileIndex: React.FC<IFileIndexProps> = ({ group }) => {
  const params = useMemo(
    () => ({
      group_id: group.id,
      parent_type_not: 'profile', // We don't want to show profile pictures
    }),
    [group.id],
  );
  const action = useMemo(
    () => (
      <GuardGroupCreate>
        <FileActionCreate multiple />
      </GuardGroupCreate>
    ),
    [],
  );
  const leftPane = useMemo(() => <FileIndexLeftPane />, []);
  const noResultsMessage = useMemo(() => <FileCardEmptyState />, []);
  return (
    <MasterIndex
      entityName={File.entityName}
      leftPane={leftPane}
      params={params}
      noResultsMessage={noResultsMessage}
      action={action}
      filters={filters}
    >
      {({
        entities,
        ...rest
      }: { entities: File[] }): React.ReactElement => (
        <FileListOrTable files={entities} {...rest} />
      )}
    </MasterIndex>
  );
};

export default FileIndex;
