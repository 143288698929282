/**
 * Labstep
 */

import { ProtocolConditionModalContextType } from 'labstep-web/components/ProtocolCondition/Modal/types';
import { ICONS } from 'labstep-web/constants/icons';
import { ColDef } from 'labstep-web/core/DataGrid/types';
import colDefValue from 'labstep-web/grid/Index/coldefs/Metadata/value';
import {
  ProtocolCondition,
  ProtocolConditionVariableType,
} from 'labstep-web/models/protocol-condition.model';
import { getDisplayName, getNestedEntity } from '../utils';

const colDefMetadata: (
  variable: ProtocolConditionVariableType,
  context: ProtocolConditionModalContextType,
) => ColDef<ProtocolCondition> = (variable, context) =>
  colDefValue({
    getNestedEntity: (params) =>
      getNestedEntity(params, 'metadatas', variable),
    columnProps: {
      headerComponentParams: {
        icon: ICONS.metadata.primary,
        onClick: context.isOpen
          ? (): void => context.setActiveVariable(variable)
          : undefined,
      },
      headerName: getDisplayName(variable),
    },
  });

export default colDefMetadata;
