/**
 * Labstep
 *
 * @module components/DeviceBooking/Action/Delete
 * @desc Action to delete
 */

import EntityActionDelete from 'labstep-web/components/Entity/Action/Delete';
import React from 'react';
import { DeviceBookingActionDeleteProps } from './types';

export const DeviceBookingActionDelete: React.FC<
  DeviceBookingActionDeleteProps
> = ({ deviceBooking, ...rest }) => (
  <EntityActionDelete {...rest} entity={deviceBooking} />
);

export default DeviceBookingActionDelete;
