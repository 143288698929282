/**
 * Labstep
 *
 * @module screens/Resource/Show/Right
 * @desc Resource Show Right
 */

import EntityQRCode from 'labstep-web/components/Entity/QRCode';
import ExperimentWorkflowLinkedList from 'labstep-web/components/ExperimentWorkflow/LinkedList';
import RightPane from 'labstep-web/components/Layout/RightPane';
import LogList from 'labstep-web/components/Log/List';
import MetadataSearch from 'labstep-web/components/Metadata/Search';
import ThreadForm from 'labstep-web/components/Thread/Form';
import { ICONS } from 'labstep-web/constants/icons';
import React from 'react';
import ScreensResourceShowRightOrderRequests from './OrderRequests';
import { IScreensResourceShowRightProps } from './types';

const ScreensResourceShowRight: React.FC<
  IScreensResourceShowRightProps
> = ({ resource }) => (
  <RightPane
    route={{
      to: 'resource_show',
      params: { id: resource.id },
    }}
    subPages={[
      {
        name: 'QR Code',
        icon: 'qrcode',
        route: {
          to: 'resource_show_permalink',
          params: { id: resource.id },
        },
        component: <EntityQRCode entity={resource} />,
      },
      {
        name: 'Order Requests',
        icon: ICONS.order_request.primary,
        route: {
          to: 'resource_show_order_requests',
          params: { id: resource.id },
        },
        component: (
          <ScreensResourceShowRightOrderRequests
            resource={resource}
          />
        ),
        premiumFeature: 'order_management',
      },
      {
        name: 'Linked Experiments',
        icon: ICONS.experiment_workflow.primary,
        route: {
          to: 'resource_show_experiment_workflows',
          params: { id: resource.id },
        },
        component: (
          <ExperimentWorkflowLinkedList
            params={{ resource_id: resource.id }}
          />
        ),
      },
      {
        name: 'Data',
        icon: ICONS.metadata.primary,
        route: {
          to: 'resource_show_metadata',
          params: { id: resource.id },
        },
        component: <MetadataSearch entity={resource} />,
      },
      {
        name: 'Notes',
        icon: ICONS.thread.primary,
        count: resource.thread!.comment_count,
        route: {
          to: 'resource_show_thread',
          params: { id: resource.id },
        },
        component: (
          <ThreadForm
            createThreadId={resource.thread!.id}
            readThreadIds={resource.thread!.id}
            entityNameLabel="notes"
          />
        ),
      },
      {
        name: 'Activity',
        icon: 'history',
        route: {
          to: 'resource_show_activity',
          params: { id: resource.id },
        },
        component: <LogList entity={resource} />,
      },
    ]}
  />
);

export default ScreensResourceShowRight;
