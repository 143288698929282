/**
 * Labstep
 *
 * @module components/User/Signin/SocialMedia/Google
 * @desc Wrapper class around Google component
 */

import {
  CredentialResponse,
  GoogleLogin,
  GoogleOAuthProvider,
} from '@react-oauth/google';
import { configService } from 'labstep-web/services/config.service';
import React from 'react';
import { IGoogleProps } from './types';

const provider = 'google';

class Google extends React.Component<IGoogleProps> {
  public constructor(props: IGoogleProps) {
    super(props);
    this.responseGoogle = this.responseGoogle.bind(this);
  }

  public responseGoogle = (response?: CredentialResponse): void => {
    const { loginGoogle, body = {}, options = {} } = this.props;
    const isValid = !!response?.credential;
    const sdkResponse = {
      ...body,
      provider,
      error: !isValid
        ? { data: { message: 'Cancelled Google authentication' } }
        : undefined,
      id_token: response?.credential,
    };
    loginGoogle({ sdkResponse, ...options });
  };

  public render(): React.ReactElement {
    const { text } = this.props;
    return (
      <GoogleOAuthProvider clientId={configService.googleClientId}>
        <GoogleLogin
          key={text}
          width="400px" // Maximum allowed by google
          onSuccess={this.responseGoogle}
          onError={this.responseGoogle}
          text="continue_with"
        />
      </GoogleOAuthProvider>
    );
  }
}

export default Google;
