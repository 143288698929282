/**
 * Labstep
 *
 * @module screens/Experiment/Edit/Right/Values
 * @desc Experiment Value Screens
 */

import Container from 'labstep-web/components/Layout/Container';
import ProtocolValueActionCreate from 'labstep-web/components/ProtocolValue/Action/Create';
import { ProtocolValueListContainer } from 'labstep-web/components/ProtocolValue/List';
import ProtocolValueTable from 'labstep-web/components/ProtocolValue/Table';
import { ICONS } from 'labstep-web/constants/icons';
import EmptyState from 'labstep-web/core/Card/EmptyState';
import ListCollapsible from 'labstep-web/core/List/Collapsible';
import PremiumFeatureScreenGuard from 'labstep-web/core/PremiumFeature/ScreenGuard';
import { ReadOnMountHOC } from 'labstep-web/hoc/ReadOnMount';
import { Experiment } from 'labstep-web/models/experiment.model';
import { ProtocolCollection } from 'labstep-web/models/protocol-collection.model';
import { ProtocolValue } from 'labstep-web/models/protocol-value.model';
import { Resource } from 'labstep-web/models/resource.model';
import { getHumanReadableEntityName } from 'labstep-web/services/i18n.service';
import React from 'react';
import { IExperimentValuesProps } from './types';

export const ExperimentValues: React.FC<IExperimentValuesProps> = ({
  experimentWorkflow,
  tableFormat,
}) => {
  const rootExperiment = experimentWorkflow.root_experiment;
  const action = (
    <ProtocolValueActionCreate protocol={rootExperiment} />
  );

  return (
    <PremiumFeatureScreenGuard
      premiumFeature="inventory"
      unstyled
      children={
        <>
          {experimentWorkflow.protocol_value_count === 0 ? (
            <EmptyState
              action={action}
              src="/img/empty-state/inventory.svg"
              explanation={`Add ${getHumanReadableEntityName(
                ProtocolValue.entityName,
                true,
              )} to your ${getHumanReadableEntityName(
                ProtocolCollection.entityName,
              )} to access extra information on the ${getHumanReadableEntityName(
                Resource.entityName,
                true,
              )} required. Track which specific item was used along with the amount.`}
            />
          ) : (
            <div>
              <Container>{action}</Container>
              {tableFormat ? (
                <ProtocolValueTable protocol={rootExperiment} />
              ) : (
                <ProtocolValueListContainer
                  protocol={rootExperiment}
                />
              )}
              <ReadOnMountHOC
                type="cursor"
                entityName={Experiment.entityName}
                params={{
                  is_root: false,
                  experiment_workflow_id: experimentWorkflow.id,
                }}
                children={({ entities }) => {
                  const experiments = entities.filter(
                    (e) => !e.deleted_at,
                  );

                  return (
                    <ListCollapsible
                      sections={experiments.map((experiment) => ({
                        icon: ICONS.experiment.primary,
                        text: experiment.name,
                        count: experiment.protocol_value_count,
                        component: tableFormat ? (
                          <ProtocolValueTable protocol={experiment} />
                        ) : (
                          <ProtocolValueListContainer
                            protocol={experiment}
                          />
                        ),
                      }))}
                    />
                  );
                }}
              />
            </div>
          )}
        </>
      }
    />
  );
};

export default ExperimentValues;
