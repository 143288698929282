/**
 * Labstep
 *
 * @module core/TextWithHelp
 * @desc Text with help on hover
 */

import classnames from 'classnames';
import Icon from 'labstep-web/core/Icon';
import Popup from 'labstep-web/core/Popup';
import React from 'react';
import styles from './styles.module.scss';
import { ITextWithHelpProps } from './types';

export const TextWithHelp: React.FC<ITextWithHelpProps> = ({
  text,
  icon,
  helperText,
  header,
  iconProps,
  inverted,
}) => {
  return (
    <span
      className={classnames({
        [styles.inverted]: inverted,
        [styles.header]: header,
      })}
    >
      {icon && (
        <span>
          <Icon name={icon} {...iconProps} />
        </span>
      )}
      <span className={styles.text}>{text}</span>
      {helperText && (
        <Popup
          inverted
          content={helperText}
          trigger={<Icon name="question circle" />}
          position="right center"
        />
      )}
    </span>
  );
};

export default TextWithHelp;
