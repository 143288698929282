/**
 * Labstep
 *
 * @desc ShowEdit for PubChemMetadata
 */

import { useHasAccess } from 'labstep-web/components/Entity/Can';
import { Action } from 'labstep-web/components/Entity/Can/types';
import { EntityUpdateContainer } from 'labstep-web/containers/Entity/Update';
import ReusableForm from 'labstep-web/core/Form/Reusable';
import { ShowEditToggleComponent } from 'labstep-web/core/Form/ShowEdit';
import { omit } from 'lodash';
import React from 'react';
import { IPubChemMetadataFormShowEditGenericShowEditProps } from './types';

const PubChemMetadataFormShowEditGenericShowEdit: React.FC<
  IPubChemMetadataFormShowEditGenericShowEditProps
> = ({
  field,
  value,
  entity,
  children,
  label,
  defaultValues,
  preSubmit,
}) => {
  const name = field.fieldLabel;

  const hasValue = !!value;

  const { entityName } = entity;
  const fields: any = [{ ...omit(field, ['fieldLabel']) }];
  const canEdit = useHasAccess(
    entityName,
    entity.idAttr,
    Action.edit,
  ) as boolean;

  return (
    <EntityUpdateContainer
      entityName={entity.entityName}
      id={entity.idAttr}
      optimistic
    >
      {({ update }): React.ReactElement => (
        <ShowEditToggleComponent
          canEdit={canEdit}
          children={children}
          name={name as any}
          label={label}
          explainer={field.fieldExplainer}
          hasValue={hasValue}
          toggledComponent={({ toggle }): React.ReactElement => (
            <ReusableForm
              fields={fields}
              defaultValues={defaultValues}
              submitButton={false}
              blurToggle={toggle}
              autoFocus
              preSubmit={preSubmit}
              onSubmit={(values): void => {
                update(values);
                toggle();
              }}
            />
          )}
        />
      )}
    </EntityUpdateContainer>
  );
};

export default PubChemMetadataFormShowEditGenericShowEdit;
