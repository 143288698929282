/**
 * Labstep
 *
 * @module components/Group/Form/ShowEdit/Name
 * @desc Edit Group name
 */

import { fieldName } from 'labstep-web/components/Group/Form/fields';
import ShowEditText from 'labstep-web/core/Form/ShowEdit/Text';
import React from 'react';
import { GroupFormShowEditNameProps } from './types';

export const GroupFormShowEditName: React.FC<
  GroupFormShowEditNameProps
> = ({ group }) => (
  <ShowEditText
    entityName={group.entityName}
    entity={group}
    field={fieldName}
  >
    {group.name}
  </ShowEditText>
);

export default GroupFormShowEditName;
