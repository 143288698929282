/**
 * Labstep
 */

import { premiumFeaturesInfo } from 'labstep-web/constants/premium-features';
import { useHasFeatureFlagEnabled } from 'labstep-web/hooks/feature-flag';
import React from 'react';
import styles from './styles.module.scss';
import { IPremiumFeatureLockIconProps } from './types';

export const PremiumFeatureLockIcon: React.FC<
  IPremiumFeatureLockIconProps
> = ({ premiumFeature }) => {
  const info = premiumFeaturesInfo[premiumFeature];
  return useHasFeatureFlagEnabled(premiumFeature) ? null : (
    <div className={styles.container}>
      <img
        src={
          info.beta ? '/img/icon/lock-alt.svg' : '/img/icon/lock.svg'
        }
        alt="lock icon"
      />
    </div>
  );
};

export default PremiumFeatureLockIcon;
