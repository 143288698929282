/**
 * Labstep
 *
 * @module components/GroupAutoSharing/Form/fields
 * @desc Fields for groupAutoSharing
 */

import {
  FieldType,
  FieldWithoutActionProps,
} from 'labstep-web/core/Form/Reusable/types';
import { Group } from 'labstep-web/models/group.model';
import { getHumanReadableEntityName } from 'labstep-web/services/i18n.service';
import rules from 'labstep-web/services/validation/rules';

export const entityTypeOptionExperimentWorkflow = {
  label: getHumanReadableEntityName(
    'experiment_workflow',
    true,
    true,
  ),
  value: 'experiment_workflow',
};

export const entityTypeOptionProtocolCollection = {
  label: getHumanReadableEntityName(
    'protocol_collection',
    true,
    true,
  ),
  value: 'protocol_collection',
};

export const entityTypeOptionResource = {
  label: getHumanReadableEntityName('resource', true, true),
  value: 'resource',
};

export const entityTypeOptionResourceLocation = {
  label: getHumanReadableEntityName('resource_location', true, true),
  value: 'resource_location',
};

export const entityTypeOptionTag = {
  label: getHumanReadableEntityName('tag', true, true),
  value: 'tag',
};

export const entityTypeOptionFolder = {
  label: getHumanReadableEntityName('folder', true, true),
  value: 'folder',
};

export const entityTypeOptionOrderRequest = {
  label: getHumanReadableEntityName('order_request', true, true),
  value: 'order_request',
};

export const entityTypeOptionDevice = {
  label: getHumanReadableEntityName('device', true, true),
  value: 'device',
};

export const fieldEntityType: FieldWithoutActionProps = {
  fieldLabel: 'What do you want to auto-share from this workspace?',
  name: 'entity_type',
  validation: rules.group_auto_sharing.entity_type,
  placeholder: 'Select type',
  fieldType: FieldType.ReactSelect,
  elementProps: {
    options: [
      entityTypeOptionExperimentWorkflow,
      entityTypeOptionProtocolCollection,
      entityTypeOptionResource,
      entityTypeOptionResourceLocation,
      entityTypeOptionTag,
      entityTypeOptionFolder,
      entityTypeOptionOrderRequest,
      entityTypeOptionDevice,
    ],
  },
};

export const getFieldTargetGroup = (
  group: Group,
): FieldWithoutActionProps => ({
  fieldLabel: 'Which workspace do you want to share with?',
  name: 'target_group',
  validation: rules.group_auto_sharing.target_group,
  placeholder: 'Search for a workspace you own',
  fieldType: FieldType.SearchSelect,
  elementProps: {
    entityName: 'group',
    valueKey: 'guid',
    params: {
      not_guid: group.guid,
    },
  },
});

export const permissionTypeOptionView = {
  label: 'View Only',
  value: 'view',
};

export const permissionTypeOptionEdit = {
  label: 'View & Edit',
  value: 'edit',
};

export const fieldPermissionType: FieldWithoutActionProps = {
  fieldLabel: 'What permission level do you want to share with?',
  name: 'permission_type',
  validation: rules.group_auto_sharing.permission_type,
  placeholder: 'Select permission level',
  fieldType: FieldType.ReactSelect,
  elementProps: {
    options: [permissionTypeOptionView, permissionTypeOptionEdit],
  },
};

export const fieldPropagate: FieldWithoutActionProps = {
  fieldLabel: 'Share all existing contents? (may take a while)',
  name: 'propagate',
  validation: rules.group_auto_sharing.propagate,
  fieldType: FieldType.Checkbox,
  elementProps: {
    toggle: true,
  },
};

export const fieldDeletePropagate: FieldWithoutActionProps = {
  fieldLabel: 'Unshare already shared contents? (may take a while)',
  name: 'propagate',
  validation: rules.group_auto_sharing.propagate,
  fieldType: FieldType.Checkbox,
  elementProps: {
    toggle: true,
  },
};
