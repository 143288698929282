import Label from 'labstep-web/core/Label';
import Link from 'labstep-web/core/Link';
import React from 'react';
import styles from './styles.module.scss';
import { IProtocolLabelStatusProps } from './types';

const ProtocolLabelStatus: React.FC<IProtocolLabelStatusProps> = ({
  protocol,
  showVersionLabel,
}) => {
  let label;
  if (protocol.version === false) {
    label = (
      <Label
        size="small"
        className={styles.abandoned}
        icon="trash"
        content="Abandoned Draft"
      />
    );
  } else if (protocol.is_draft) {
    label = (
      <Label
        size="small"
        className={styles.draft}
        icon="edit"
        content={
          showVersionLabel
            ? `v${protocol.versionNumber}: Draft`
            : 'Draft'
        }
      />
    );
  } else if (protocol.is_last_version) {
    label = (
      <Label
        size="small"
        className={styles.live}
        icon="check"
        content={
          showVersionLabel
            ? `v${protocol.versionNumber}: Live`
            : 'Live'
        }
      />
    );
  } else {
    label = (
      <Label
        size="small"
        className={styles.superseded}
        icon="archive"
        content="Superseded"
      />
    );
  }
  if (!showVersionLabel) {
    return label;
  }
  return (
    <div className={styles.container}>
      <Link to="protocol_show" params={{ id: protocol.id }}>
        {label}
      </Link>
    </div>
  );
};

export default ProtocolLabelStatus;
