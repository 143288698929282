/**
 * Labstep
 *
 * @module components/Group/Card/EmptyState
 * @desc Empty state for group
 */

import GroupEmptyState from 'labstep-web/components/Group/EmptyState';
import Card from 'labstep-web/core/Card/Card';
import { Group } from 'labstep-web/models/group.model';
import React from 'react';
import { GroupCardEmptyStateProps } from './types';

export const GroupCardEmptyState: React.FC<
  GroupCardEmptyStateProps
> = ({ isActionAllowed = true }) => (
  <Card header={Group.getHumanReadableEntityName(true, true)}>
    <GroupEmptyState isActionAllowed={isActionAllowed} />
  </Card>
);

export default GroupCardEmptyState;
