/**
 * Labstep
 *
 * @module components/AccessKey/Form/fields
 * @desc Form Fields for AccessKey
 */

import {
  FieldType,
  FieldWithoutActionProps,
} from 'labstep-web/core/Form/Reusable/types';
import rules from 'labstep-web/services/validation/rules';

export const accessKeyFieldName: FieldWithoutActionProps = {
  fieldType: FieldType.Text,
  name: 'name',
  placeholder: 'Enter name',
  fieldLabel: 'Name',
  fieldExplainer: 'Explain what this API key will be used for.',
  validation: rules.access_key.name,
};

export const accessKeyFieldExpiresAt: FieldWithoutActionProps = {
  name: 'expires_at',
  fieldType: FieldType.DateTimePicker,
  placeholder: 'Enter epxiry date',
  fieldLabel: 'Expiry Date',
  fieldExplainer: '(Optional) The date this API key will expire.',
  validation: rules.access_key.expires_at,
  elementProps: {
    enableTime: false,
    placeholder: 'Enter expiry date',
  },
};
