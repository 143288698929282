/**
 * Labstep
 *
 * @module components/Metadata/Action/Menu
 * @desc Action Menu for metadata
 */

import MetadataActionDelete from 'labstep-web/components/Metadata/Action/Delete';
import MetadataActionEdit from 'labstep-web/components/Metadata/Action/Edit';
import ProtocolConditionActionCompareData from 'labstep-web/components/ProtocolCondition/Action/CompareData';
import ActionMenu from 'labstep-web/core/Action/Menu';
import React from 'react';
import { MetadataActionMenuProps } from './types';

export const MetadataActionMenu: React.FC<
  MetadataActionMenuProps
> = ({ metadata, type, actions = [], entity, ...rest }) => (
  <ActionMenu
    type={type}
    {...rest}
    dataCy="components/Metadata/Action/Menu"
  >
    {actions.includes('edit') && (
      <MetadataActionEdit
        actionComponentProps={{
          type: 'option',
          text: 'Edit',
          icon: 'pencil',
        }}
        entity={entity}
        metadata={metadata}
      />
    )}
    {actions.includes('delete') && (
      <MetadataActionDelete entity={entity} metadata={metadata} />
    )}
    {actions.includes('reporting') && (
      <ProtocolConditionActionCompareData
        actionComponentType="option"
        metadata={metadata}
      />
    )}
  </ActionMenu>
);

MetadataActionMenu.defaultProps = {
  actions: [],
  type: 'icon',
};

export default MetadataActionMenu;
