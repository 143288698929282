/**
 * Labstep
 *
 * @module core/Form/ReactForm/Error/Message
 * @desc Displays an error message in the form
 */

import React from 'react';
import styles from './styles.module.scss';
import { IFormErrorMessageProps } from './types';

/**
 * Error message
 */
const FormErrorMessage: React.FC<IFormErrorMessageProps> = ({
  message,
}) =>
  typeof message === 'string' ? (
    <div className={styles.message}>
      <small>{message}</small>
    </div>
  ) : (
    message
  );

export default FormErrorMessage;
