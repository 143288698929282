/**
 * Labstep
 *
 * @module epics/websocket
 * @desc Redux epic for websocket
 */

import { Action } from 'labstep-web/models/action.model';
import { selectPrintProgress } from 'labstep-web/state/new/print';
import { StateObservable } from 'redux-observable';
import { Observable, concat, of } from 'rxjs';
import {
  catchError,
  debounceTime,
  filter,
  mergeMap,
} from 'rxjs/operators';
import { LabstepReduxState } from '../types';

/**
 * Start timer user when user requests print.
 *
 * @function
 * @param  {Observable<Action>} action$
 * @return {Observable<Action>}
 */
export const onPrintStartActionEpic = (
  action$: Observable<Action>,
): Observable<Action> =>
  action$.pipe(
    filter(
      (action: Action) =>
        (action.type === 'SUCCESS_CREATE_ENTITY_EXPORT_RAW_OUTPUT' &&
          (action.meta as any) &&
          (action.meta as any).body &&
          (action.meta as any).body.type === 'print') ||
        false,
    ),
    mergeMap(() => {
      return of({
        type: 'UPDATE_PRINT_PROGRESS',
        meta: {
          progress: 0,
        },
      });
    }),
    catchError((err, source$: Observable<Action>) =>
      concat(
        of({
          type: 'EPIC_FAIL_PRINT_START',
          payload: err,
        }),
        source$,
      ),
    ),
  );

/**
 * Timer to update progress when user is waiting for print.
 *
 * @function
 * @param  {Observable<Action>} action$
 * @param  {StateObservable<LabstepReduxState>} state$
 * @return {Observable<Action>}
 */
export const onPrintProgressActionEpic = (
  action$: Observable<Action>,
  state$: StateObservable<LabstepReduxState>,
): Observable<Action> =>
  action$.pipe(
    filter(
      (action: Action) => action.type === 'UPDATE_PRINT_PROGRESS',
    ),
    mergeMap(() => {
      const progress = selectPrintProgress(state$.value);

      if (progress === -1) {
        return of({
          type: 'PRINT_PROGRESS_STOP',
        });
      }

      if (progress === 7) {
        return of({
          type: 'PRINT_TIMEOUT',
        });
      }

      return of({
        type: 'UPDATE_PRINT_PROGRESS',
        meta: {
          progress: progress + 1,
        },
      });
    }),
    debounceTime(30000),
    catchError((err, source$: Observable<Action>) =>
      concat(
        of({
          type: 'EPIC_FAIL_PRINT_PROGRESS',
          payload: err,
        }),
        source$,
      ),
    ),
  );
