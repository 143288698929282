/**
 * Labstep
 *
 * @module prosemirror/components/Menu/Action/Link
 * @desc Add a link mark to selected text
 */

import Button from 'labstep-web/core/Button';
import FormReusable from 'labstep-web/core/Form/Reusable';
import Icon from 'labstep-web/core/Icon';
import ModalDefault from 'labstep-web/core/Modal/Default';
import { fields } from 'labstep-web/prosemirror/marks/link';
import { getSelectionTextCut } from 'labstep-web/prosemirror/utils/selection';
import React from 'react';
import { IMenuTextActionLinkProps } from './types';

const MenuTextActionLink: React.FC<IMenuTextActionLinkProps> = ({
  view,
}) => (
  <ModalDefault
    header="Convert to link"
    content={({ toggleModal }) => (
      <FormReusable
        defaultValues={{
          text: getSelectionTextCut(view.state),
        }}
        autoFocus
        onSubmit={({ url, text }) => {
          toggleModal();
          const link = view.state.schema.marks.link.create({
            href: url,
            title: text,
          });
          const node = view.state.schema
            .text((text as string) || (url as string))
            .mark([link]);
          view.dispatch(
            view.state.tr.replaceSelectionWith(node, false),
          );
          view.focus();
        }}
        fields={fields}
      />
    )}
    viewComponent={({ toggleModal }) => (
      <Button onClick={toggleModal}>
        <Icon name="linkify" />
        <span>Link</span>
      </Button>
    )}
  />
);

export default MenuTextActionLink;
