/**
 * Labstep
 *
 * @module grid/Index/components/ContextMenu
 * @desc Entity DataGrid Context Menu
 */

import { setSortOption } from 'labstep-web/components/Entity/Table/SortOptions/utils';
import { useEntitySearchContext } from 'labstep-web/contexts/entity-search/hook';
import { useParamsContext } from 'labstep-web/contexts/params/hook';
import { ActionComponentProps } from 'labstep-web/core/Action/Component/types';
import Dropdown from 'labstep-web/core/Dropdown';
import DropdownPortal from 'labstep-web/core/Dropdown/Portal';
import Icon from 'labstep-web/core/Icon';
import React from 'react';
import { GridIndexContextMenuProps } from './types';
import { isHideable } from './utils';

export const filterActionComponentProps = {
  type: 'option',
  icon: 'filter',
  text: 'Filter by this field',
  cornerIcon: undefined,
} as ActionComponentProps;

export const GridIndexContextMenu: React.FC<
  GridIndexContextMenuProps
> = ({
  sort,
  filter,
  columnApi,
  column,
  firstItems,
  lastItems,
  columnOptions,
  trigger,
}) => {
  const { sortOptions, usePostFilter } = useEntitySearchContext();
  const paramsContext = useParamsContext();
  const { searchParams, setParams, addPostFilter } = paramsContext;
  const dropdownItems: React.ReactNode[] = firstItems
    ? [firstItems]
    : [];
  if (sortOptions && sort) {
    dropdownItems.push(
      <Dropdown.Item
        onClick={(): void =>
          setSortOption(setParams, sortOptions, sort)
        }
      >
        <Icon name="sort amount up" /> Sort by this field
      </Dropdown.Item>,
    );
  }
  if (filter) {
    dropdownItems.push(
      filter({
        actionComponentProps: filterActionComponentProps,
        searchParams,
        setParams,
        addPostFilter: usePostFilter ? addPostFilter : undefined,
      }),
    );
  }
  const afterDividerItems = [];
  if (isHideable(column)) {
    afterDividerItems.push(
      <Dropdown.Item
        onClick={(): void =>
          columnApi.setColumnVisible(column, false)
        }
      >
        <Icon name="eye slash" /> Hide from View
      </Dropdown.Item>,
    );
  }
  if (lastItems) {
    afterDividerItems.push(lastItems);
  }
  if (dropdownItems.length && afterDividerItems.length) {
    dropdownItems.push(<Dropdown.Divider />);
  }
  dropdownItems.push(...afterDividerItems);
  if (columnOptions) {
    dropdownItems.push(<Dropdown.Divider />);
    dropdownItems.push(columnOptions);
  }
  return dropdownItems.length ? (
    <DropdownPortal trigger={trigger}>{dropdownItems}</DropdownPortal>
  ) : (
    trigger
  );
};

export default GridIndexContextMenu;
