/**
 * Labstep
 *
 * @module components/ExperimentWorkflow/Action/CreateTemplate
 * @desc Create Template Action for experiment workflow
 */

import EntityActionCreate from 'labstep-web/components/Entity/Action/Create';
import React from 'react';

export const ExperimentWorkflowActionCreateTemplate: React.FC =
  () => (
    <EntityActionCreate
      customActionName="New Template"
      body={{ is_template: true }}
      entityName="experiment_workflow"
      redirectRoute={({ response }) => ({
        to: 'experiment_workflow_show',
        params: { id: response.result },
      })}
      actionComponentProps={{
        type: 'button',
      }}
    />
  );

export default ExperimentWorkflowActionCreateTemplate;
