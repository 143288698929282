/**
 * Labstep
 */

import React from 'react';
import { EntitySearchContextType } from './types';

export const defaultEntitySearchContext = {
  sortOptions: undefined,
  usePostFilter: false,
};

const EntitySearchContext =
  React.createContext<EntitySearchContextType>(
    defaultEntitySearchContext,
  );

export default EntitySearchContext;
