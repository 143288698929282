/**
 * Labstep
 */

import { ICONS } from 'labstep-web/constants/icons';
import colDefAmount from 'labstep-web/grid/Index/coldefs/Resource/amount';

export const colDefResourceItemAmount = colDefAmount({
  columnProps: {
    colId: 'quantity',
    headerName: 'Amount Remaining',
    headerComponentParams: {
      icon: ICONS.resource_item.info.quantity,
    },
  },
  updateUnit: true,
});
