/**
 * Labstep
 *
 * @module components/Folder/List
 * @desc List of folders
 */

import FolderItem from 'labstep-web/components/Folder/Item';
import Link from 'labstep-web/core/Link';
import React from 'react';
import styles from './styles.module.scss';
import { FolderListProps } from './types';

export const FolderList: React.FC<FolderListProps> = ({
  folders,
  parentEntity,
  ...rest
}) => (
  <div id="folder-list" className={styles.folderList}>
    {folders.map((folder) => (
      <Link
        key={folder.id}
        to={`${parentEntity.entityName}_index`}
        query={{ folder_id: folder.id }}
      >
        <FolderItem
          folder={folder}
          parentEntity={parentEntity}
          {...rest}
        />
      </Link>
    ))}
  </div>
);

export default FolderList;
