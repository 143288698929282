/**
 * Labstep
 *
 * @module components/RichText/DangerousDiv
 * @desc DangerousDivupdate comment post
 */

import DangerousDiv from 'labstep-web/core/DangerousDiv';
import React from 'react';
import styles from './styles.module.scss';
import { IRichTextDangerousDivProps } from './types';

const RichTextDangerousDiv: React.FC<IRichTextDangerousDivProps> = ({
  richText,
}) => (
  <div className={styles.container}>
    <DangerousDiv html={richText.description} />
  </div>
);

export default RichTextDangerousDiv;
