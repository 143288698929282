/**
 * Labstep
 */

import SimpleTableCollapsible from 'labstep-web/core/Table/Simple/Collapsible';
import { TableSimpleCollapsibleProps } from 'labstep-web/core/Table/Simple/Collapsible/types';
import { arrayify } from 'labstep-web/state/reducers/helpers';
import React from 'react';
import EntityActionUpdate from '../..';
import { AllowedActionsLockSections } from './constants';
import { AllowedActionsLockTableProps } from './types';

export const AllowedActionsLockTable: React.FC<
  AllowedActionsLockTableProps
> = ({ entity }) => {
  const sections = Object.keys(AllowedActionsLockSections).map(
    (section, index) => ({
      isInitiallyUncollapsed: index === 0,
      collapsible: true,
      name: section,
      rows: AllowedActionsLockSections[section].map((action) => {
        return {
          name: action.label,
          section,
          actions: action.value,
        };
      }),
    }),
  );
  const columns: TableSimpleCollapsibleProps['columns'] = [
    {
      header: 'Action',
      content: ({ name }) => (
        <span style={{ marginLeft: '50px', fontWeight: 500 }}>
          {name}
        </span>
      ),
      cellProps: { width: 10 },
    },
    {
      header: 'Allowed',
      content: ({ name, actions }) => {
        const allowedActionsLock =
          entity.default_experiment_allowed_actions_lock || [];
        const isAllowed = arrayify(actions).every((action) =>
          allowedActionsLock.includes(action),
        );
        return (
          <div
            style={{
              display: 'flex',
              gap: '10px',
              fontWeight: 500,
            }}
          >
            <EntityActionUpdate
              entityName={entity.entityName}
              id={entity.id}
              renderIfNoEditPermissions
              body={{
                default_experiment_allowed_actions_lock: isAllowed
                  ? allowedActionsLock.filter(
                      (action) => !arrayify(actions).includes(action),
                    )
                  : [...allowedActionsLock, ...arrayify(actions)],
              }}
              actionComponentProps={{
                dataCy: `components/Entity/Action/Update/AllowedActionsLock/Table:${name}`,
                type: 'checkbox',
                elementProps: {
                  toggle: false,
                  checked: isAllowed,
                },
              }}
            />
          </div>
        );
      },
    },
  ];

  return (
    <SimpleTableCollapsible
      colSpan={1}
      columns={columns}
      sections={sections}
      fixed={false}
    />
  );
};

export default AllowedActionsLockTable;
