/**
 * Labstep
 *
 * @module components/Layout/GlobalSearch/Content/SearchResults
 * @desc Display search results in global search
 */

import EntityLink from 'labstep-web/components/Entity/Link';
import EntitySearchCursor from 'labstep-web/components/Entity/Search/Cursor';
import FileActionDownload from 'labstep-web/components/File/Action/Download';
import { FileNamePreview } from 'labstep-web/components/File/Table';
import { Filter } from 'labstep-web/components/Filter/Menu/types';
import { GroupInitials } from 'labstep-web/components/Group/Initials';
import ProfilePicture from 'labstep-web/components/Profile/Picture';
import EmptyState from 'labstep-web/core/Card/EmptyState';
import { Size } from 'labstep-web/core/Initials/types';
import TableSimple from 'labstep-web/core/Table/Simple';
import { TableSimpleProps } from 'labstep-web/core/Table/Simple/types';
import { File as FileModel } from 'labstep-web/models/file.model';
import { Group } from 'labstep-web/models/group.model';
import { ResourceItem } from 'labstep-web/models/resource-item.model';
import React from 'react';
import { GlobalSearchableEntity } from '../EntityNameSelector/constants';
import { LayoutGlobalSearchSearchResultsProps } from './types';

interface SearchParams {
  group_id?: number;
  group?: Group;
  [key: string]: unknown;
}

/**
 * @description Function to get columns for table
 */
export const getColumns = (
  entityName: string,
): TableSimpleProps['columns'] => {
  let columns = [
    {
      header: 'Owner',
      content: (entity: GlobalSearchableEntity) => {
        let group;
        if (entity instanceof ResourceItem) {
          group = entity.resource.owner;
        } else {
          group = entity.owner;
        }

        return group ? <GroupInitials group={group} /> : null;
      },
      cellProps: { style: { width: 100 } },
    },
    {
      header: 'Name',
      content: (entity: GlobalSearchableEntity) =>
        entity instanceof FileModel ? (
          entity.name
        ) : (
          <EntityLink entity={entity} />
        ),
      cellProps: { style: { flex: 3 } },
    },
    {
      header: 'Creator',
      content: (entity: GlobalSearchableEntity) =>
        entity.author && (
          <ProfilePicture size={Size.mini} entity={entity.author} />
        ),
      cellProps: { style: { width: 100 } },
    },
  ];
  if (entityName === FileModel.entityName) {
    columns = [
      {
        header: 'Name',
        content: (entity: GlobalSearchableEntity) => (
          <FileNamePreview file={entity as FileModel} />
        ),
        cellProps: { style: { flex: 3 } },
      },
      {
        header: '',
        content: (entity: GlobalSearchableEntity) => (
          <FileActionDownload
            file={entity as FileModel}
            actionComponentProps={{ type: 'icon' }}
          />
        ),
        cellProps: { style: { width: 100 } },
      },
    ];
  }
  return columns;
};

/**
 * @description Function to transform group to group_id in searchParams
 */
export const getParams = (
  searchParams: SearchParams,
): SearchParams => {
  const params: SearchParams = {};
  if (searchParams.group) {
    params.group_id = searchParams.group.id;
  }
  return params;
};

export const SearchResults: React.FC<
  LayoutGlobalSearchSearchResultsProps
> = ({ searchParams }) => (
  <EntitySearchCursor
    entityName={searchParams.entityName as string}
    params={{
      ...getParams(searchParams),
      workspace_is_deleted: false,
    }}
    filters={[Filter.created_by, Filter.date_range, Filter.deleted]}
    noResultsMessage={
      <EmptyState explanation="Add a search query to search for experiments, protocols, resources, items and much more across different workspaces" />
    }
    key={searchParams.entityName as string}
  >
    {({ entities }) => (
      <TableSimple
        hideHeader
        rows={entities}
        columns={getColumns(searchParams.entityName as string)}
      />
    )}
  </EntitySearchCursor>
);

export default SearchResults;
