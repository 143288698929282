/**
 * Labstep
 *
 * @module prosemirror/components/Toolbar/Left
 * @desc Button to display a list of actions on the left
 */

import ProtocolStepToolbar from 'labstep-web/prosemirror/components/Toolbar/ProtocolStep';
import { computeNodePosition } from 'labstep-web/prosemirror/cursor';
import { getListNode } from 'labstep-web/prosemirror/nodes/list/utils';
import {
  getIsSingleListSelected,
  getIsStepSelected,
} from 'labstep-web/prosemirror/utils/selection';
import React from 'react';
import styles from './styles.module.scss';
import { IToolbarLeftProps } from './types';

const ToolbarLeft: React.FC<IToolbarLeftProps> = ({
  view,
  entity,
}) => {
  if (getIsStepSelected(view.state)) {
    return null;
  }

  let startPosition: number;
  let endPosition: number;
  let left: number;
  if (getIsSingleListSelected(view.state)) {
    const listNode = getListNode(view.state);
    const from = listNode.pos;
    const to = listNode.pos + listNode.node.nodeSize;
    startPosition = computeNodePosition(from, view).top + 5;
    endPosition = computeNodePosition(to, view).top;
    left = computeNodePosition(from, view).left;
  } else {
    const { from, to } = view.state.selection;
    startPosition = computeNodePosition(from, view).top + 5;
    endPosition = computeNodePosition(to, view).top + 20;
    left = computeNodePosition(0, view).left;
  }

  return (
    <div
      className={styles.container}
      style={{
        position: 'fixed',
        left: `${left - 40}px`,
        top: `${startPosition - 7}px`,
        height: `${endPosition - startPosition}px`,
      }}
    >
      <ProtocolStepToolbar
        state={view.state}
        dispatch={view.dispatch}
        protocol={entity}
      />
    </div>
  );
};

export default ToolbarLeft;
