/**
 * Labstep
 *
 * @module core/Action/Component/Bulk
 * @desc Component to show bulk action
 */

import ActionComponent from 'labstep-web/core/Action/Component';
import Popup from 'labstep-web/core/Popup';
import React from 'react';
import { IActionComponentBulkProps } from './types';

export const ActionComponentBulk: React.FC<
  IActionComponentBulkProps
> = ({
  onClick,
  icon,
  content,
  entitiesSelectedCount,
  entitiesWithPermissionCount,
  disabled,
  disabledReason,
}) => {
  let popupContent = '';
  let disabledProp = disabled !== undefined ? disabled : false;
  if (entitiesWithPermissionCount === 0) {
    disabledProp = true;
    popupContent = `You don't have the right permissions to perform this action on any of the selected rows.`;
  } else if (entitiesWithPermissionCount < entitiesSelectedCount) {
    popupContent = `this will affect ${entitiesWithPermissionCount} rows for which you have the right permissions.`;
  }

  if (disabled && disabledReason) {
    popupContent = disabledReason;
  }

  const trigger = (
    <div>
      <ActionComponent
        onClick={!disabledProp ? onClick : undefined}
        disabled={disabledProp}
        icon={icon}
        type="text"
        text={content}
      />
    </div>
  );

  if (!popupContent) {
    return trigger;
  }

  return (
    <Popup
      content={popupContent}
      trigger={trigger}
      on="hover"
      inverted
    />
  );
};

export default ActionComponentBulk;
