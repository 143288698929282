/**
 * Labstep
 *
 * @module components/Metadata/Table
 * @desc Table listing all metadatas
 */

import { useHasAccess } from 'labstep-web/components/Entity/Can';
import { Action } from 'labstep-web/components/Entity/Can/types';
import { entityTableDragSortableBody } from 'labstep-web/components/Entity/Table/DragSortable';
import MetadataActionCreateModal from 'labstep-web/components/Metadata/Action/Create/Modal';
import MetadataActionMenu from 'labstep-web/components/Metadata/Action/Menu';
import MetadataDragIcon from 'labstep-web/components/Metadata/DragIcon';
import MetadataFormShowEditValueWithClear from 'labstep-web/components/Metadata/Form/ShowEdit/Value/WithClear';
import MetadataRequired from 'labstep-web/components/Metadata/Required';
import Flex from 'labstep-web/core/Flex';
import Loadable from 'labstep-web/core/Loadable';
import { TableColumnType } from 'labstep-web/core/Table/Simple/types';
import { Metadata } from 'labstep-web/models/metadata';
import { MetadataType } from 'labstep-web/models/metadata/types';
import { ResourceItem } from 'labstep-web/models/resource-item.model';
import { Resource } from 'labstep-web/models/resource.model';
import React from 'react';
import MetadataTableExtraRows from './ExtraRows';
import styles from './styles.module.scss';
import { MetadataTableProps } from './types';

export const MetadataTableMoleculeAdditionalRows = React.lazy(
  () => import('./MoleculeAdditionalRows'),
);

/**
 * function to select correct metadata object
 */
const selectMetadata = (
  replacementMetadatas: Metadata[],
  metadata: Metadata,
): Metadata => {
  return (
    replacementMetadatas.find(
      (met) => met.template_id === metadata.id,
    ) || metadata
  );
};

const MetadataTable: React.FC<MetadataTableProps> = ({
  replacementMetadatas,
  entity,
  actions,
  isTemplate,
  disabled,
  extraRows = [],
  onCreateSuccess,
  baseMetadatas,
}) => {
  const canEdit = useHasAccess(
    entity.entityName,
    entity.idAttr,
    Action.edit,
  );
  let templateEntity = entity;
  if (entity instanceof Resource || entity instanceof ResourceItem) {
    if (entity.nonDeletedTemplate) {
      templateEntity = entity.nonDeletedTemplate;
    }
  }

  const columns: TableColumnType<Metadata>[] = [
    {
      header: 'Field',
      content: (metadata): React.ReactNode => {
        const parentEntity = metadata.is_template
          ? templateEntity
          : entity;
        return (
          <Flex className={styles.fieldName}>
            <div className={styles.insertBelow}>
              <MetadataActionCreateModal
                body={{ position_after_id: metadata.id }}
                entity={parentEntity}
                entityName={parentEntity.entityName}
                isTemplate={metadata.is_template}
                actionComponentProps={{
                  type: 'icon',
                  icon: 'plus',
                  elementProps: {
                    popup: { content: 'Insert Below' },
                  },
                }}
                options={{ onSuccess: onCreateSuccess }}
              />
            </div>
            <MetadataDragIcon metadata={metadata} />
            <MetadataRequired
              metadata={selectMetadata(
                replacementMetadatas,
                metadata,
              )}
            />
            <div className={styles.toggleHiddenVisible}>
              <MetadataActionMenu
                entity={entity}
                metadata={metadata}
                actions={actions}
                pointing={false}
              />
            </div>
          </Flex>
        );
      },
      cellProps: { width: 4 },
    },
    {
      header: isTemplate ? 'Default Value' : 'Value',
      content: (metadata): React.ReactNode => {
        const selectedMetadata = selectMetadata(
          replacementMetadatas,
          metadata,
        );

        return (
          <MetadataFormShowEditValueWithClear
            metadata={selectedMetadata}
            entity={entity}
          />
        );
      },
      cellProps: { style: { flex: 1 } },
    },
  ];

  return (
    <>
      {extraRows.length > 0 && (
        <MetadataTableExtraRows rows={extraRows} entity={entity} />
      )}
      {baseMetadatas.length > 0 && (
        <div id="metadata-table">
          {entityTableDragSortableBody(
            Metadata.entityName,
            disabled || !canEdit,
            (value: Metadata) => {
              const selectedMetadata = selectMetadata(
                replacementMetadatas,
                value,
              );
              if (value.type !== MetadataType.molecule) {
                return null;
              }
              return (
                <Loadable>
                  <MetadataTableMoleculeAdditionalRows
                    parent={selectedMetadata}
                    createProps={selectedMetadata.getCreateProps(
                      entity,
                    )}
                  />
                </Loadable>
              );
            },
          )({ rows: baseMetadatas, columns })}
        </div>
      )}
    </>
  );
};

export default MetadataTable;
