/**
 * Labstep
 *
 * @module core/DataGrid/ColumnHeader/EntityImport/Metadata/Action/Match
 * @desc DataGrid Header for EntityImport
 */

import { useGridServiceFromContext } from 'labstep-web/contexts/grid/hook';
import ActionComponent from 'labstep-web/core/Action/Component';
import Dropdown from 'labstep-web/core/Dropdown';
import { GridEntityImportService } from 'labstep-web/grid/EntityImport/services/grid-entity-import.service';
import React from 'react';
import { IDataGridColumnHeaderEntityImportMetadataActionMatchProps } from './types';

export const DataGridColumnHeaderEntityImportMetadataActionMatch: React.FC<
  IDataGridColumnHeaderEntityImportMetadataActionMatchProps
> = ({ column, template, targetEntityName }) => {
  const gridService = useGridServiceFromContext();
  if (!gridService) {
    return null;
  }
  const colIds = gridService.getColumnDefs().map((c) => c.colId);
  const columnDefs = GridEntityImportService.getColumnDefsTemplate(
    targetEntityName,
    template,
  ).filter((colDef) => !colIds.includes(colDef.colId));
  if (!columnDefs.length) {
    return null;
  }
  return (
    <>
      <Dropdown.Header>Match to Template Field</Dropdown.Header>
      {columnDefs.map((colDef) => (
        <ActionComponent
          key={colDef.colId}
          type="option"
          text={colDef.headerName}
          icon={colDef.headerComponentParams?.icon}
          cornerIcon={colDef.headerComponentParams?.cornerIcon}
          onClick={(): void => {
            const colId = column.getColId();
            gridService.changeColDef(colId, colDef);
            gridService.refreshData();
          }}
        />
      ))}
    </>
  );
};

export default DataGridColumnHeaderEntityImportMetadataActionMatch;
