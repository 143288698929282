import cloneDeep from 'lodash/cloneDeep';
import omit from 'lodash/omit';

interface Node {
  type: string;
  text?: string;
  attrs?: Record<string, unknown>;
  marks?: Record<string, unknown>[];
}

export interface NodeWithContent extends Node {
  content: NodeWithOptionalContent[];
}

interface NodeWithOptionalContent extends Node {
  content?: NodeWithOptionalContent[];
}

export const sanitizeEmptyTextNodes = (
  content: NodeWithOptionalContent[],
): NodeWithOptionalContent[] => {
  return content.reduce((result, node): Node[] => {
    if (node.type === 'text' && node.text === '') {
      return result;
    }
    if (node.content) {
      return [
        ...result,
        { ...node, content: sanitizeEmptyTextNodes(node.content) },
      ];
    }
    return [...result, node];
  }, [] as NodeWithOptionalContent[]);
};

export const sanitizeRow = (
  row: NodeWithContent,
): NodeWithContent => {
  return {
    ...row,
    content: row.content.map((cell: NodeWithOptionalContent) => {
      if (cell.type !== 'table_cell') {
        return cell;
      }
      const temp = omit(cell, ['marks']);
      return {
        ...temp,
        content:
          temp.content !== undefined
            ? temp.content
            : [
                {
                  type: 'paragraph',
                  attrs: {
                    align: null,
                  },
                },
              ],
      };
    }),
  };
};

/**
 *
 * @description Fixes known schema violations
 */
export const sanitizeInitialState = (
  initialState: NodeWithContent,
): NodeWithContent => {
  if (!initialState) {
    return initialState;
  }
  let cloneState = cloneDeep(initialState);
  try {
    cloneState = {
      ...cloneState,
      content: initialState.content.map(
        (node: NodeWithOptionalContent) => {
          if (node.type === 'table') {
            return {
              ...node,
              content: (node as NodeWithContent).content.map(
                (row) => {
                  if (row.type !== 'table_row') {
                    return row;
                  }
                  return sanitizeRow(row as NodeWithContent);
                },
              ),
            };
          }
          return node;
        },
      ),
    };
    cloneState.content = sanitizeEmptyTextNodes(cloneState.content);
    return cloneState;
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log('prosemirror sanitizeInitialState', e);
  }
  return initialState;
};
