/**
 * Labstep
 *
 * @module components/ShareLink/Redirect
 * @desc Redirects to a sharelink experiment/protocol/resource
 */

import Redirect from 'labstep-web/core/Redirect';
import { navigation } from 'labstep-web/services/navigation';
import React, { useEffect } from 'react';
import { IShareLinkShowProps } from './types';

export const ShareLinkRedirect: React.FC<IShareLinkShowProps> = ({
  shareLink,
}) => {
  useEffect(() => {
    if (shareLink.organization) {
      window.location.href = navigation.get(
        'organization_show',
        { identifier: shareLink.organization.identifier },
        true,
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  if (
    shareLink.experiment_workflow &&
    shareLink.experiment_workflow.id
  ) {
    return (
      <Redirect
        to="experiment_workflow_show"
        params={{ id: shareLink.experiment_workflow.id }}
      />
    );
  }
  if (shareLink.group && shareLink.group.id) {
    return (
      <Redirect to="group_show" params={{ id: shareLink.group.id }} />
    );
  }
  if (
    shareLink.protocol_collection &&
    shareLink.protocol_collection.id
  ) {
    return (
      <Redirect
        to="protocol_collection_show"
        params={{ id: shareLink.protocol_collection.id }}
      />
    );
  }
  if (shareLink.resource && shareLink.resource.id) {
    return (
      <Redirect
        to="resource_show"
        params={{ id: shareLink.resource.id }}
      />
    );
  }

  if (shareLink.order_request && shareLink.order_request.id) {
    return (
      <Redirect
        to="order_request_show"
        params={{ id: shareLink.order_request.id }}
      />
    );
  }

  if (shareLink.device && shareLink.device.id) {
    return (
      <Redirect
        to="device_show"
        params={{ id: shareLink.device.id }}
      />
    );
  }

  if (shareLink.organization && shareLink.organization.id) {
    return <div>Redirecting...</div>;
  }

  return <Redirect to="app_homepage" />;
};

export default ShareLinkRedirect;
