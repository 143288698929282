/**
 * Labstep
 */

import { ActionComponentProps } from './types';

// temp fix for merging elementProps
// TODO: merge by type
export const addActionComponentPropsToDefault = (
  defaultActionComponentProps: ActionComponentProps,
  extraActionComponentProps,
) => {
  const { elementProps, ...rest } = defaultActionComponentProps;

  return {
    ...rest,
    ...extraActionComponentProps,
    elementProps: {
      ...elementProps,
      ...extraActionComponentProps?.elementProps,
    },
  };
};
