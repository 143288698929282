/**
 * Labstep
 *
 * @module components/ExperimentWorkflow/Card/MainAction
 * @desc Experiment Card Footer
 */

import CreateComment from 'labstep-web/components/Comment/Form/Create';
import CommentList from 'labstep-web/components/Comment/List';
import Divider from 'labstep-web/core/Divider';
import React from 'react';
import styles from './styles.module.scss';
import {
  ICommentListContainerProps,
  IThreadModalProps,
} from './types';

export const scrollToBottom = (id: number) => {
  const threadElement = document.getElementById(`thread-${id}`);

  // Check is needed in case the element has been unmounted
  if (!threadElement) {
    return;
  }

  setTimeout(() => {
    threadElement.scrollTop = threadElement.scrollHeight;
  }, 0);
};

export class CommentListContainer extends React.Component<ICommentListContainerProps> {
  componentDidMount() {
    const { threadId } = this.props;
    setTimeout(() => {
      scrollToBottom(threadId);
    }, 600);
  }

  render() {
    const { header, noCommentsMessage, threadId, readThreadIds } =
      this.props;

    return (
      <div
        id={`thread-${threadId}`}
        className={styles.commentListContainer}
      >
        <CommentList
          threadId={readThreadIds}
          header={header}
          noCommentsMessage={noCommentsMessage}
        />
      </div>
    );
  }
}

export const ThreadModal: React.FC<IThreadModalProps> = ({
  readThreadIds,
  threadId,
  header,
  dividerChildren,
  noCommentsMessage,
  showCreateComment = true,
  top,
}) => (
  <div data-testid="thread-modal">
    {top && (
      <div className={styles.top} data-testid="thread-modal-top">
        {top}
        <Divider horizontal>{dividerChildren}</Divider>
      </div>
    )}
    <CommentListContainer
      threadId={threadId}
      readThreadIds={readThreadIds || threadId}
      header={header}
      noCommentsMessage={noCommentsMessage}
    />
    {showCreateComment && (
      <div className={styles.commentCreateContainer}>
        <CreateComment
          onSuccess={() => scrollToBottom(threadId)}
          threadId={threadId}
        />
      </div>
    )}
  </div>
);

export default ThreadModal;
