/**
 * Labstep
 *
 * @module screens/Master/Index/LeftPane
 * @desc Left pane for index screens
 */

import classNames from 'classnames';
import Desktop from 'labstep-web/core/MediaQuery/Desktop';
import React, { useState } from 'react';
import styles from './styles.module.scss';
import { IMasterIndexLeftPaneProps } from './types';

export const MasterIndexLeftPane: React.FC<
  IMasterIndexLeftPaneProps
> = ({ leftPane }) => {
  const [collapsed, setCollapsed] = useState(false);
  return (
    <Desktop>
      {!collapsed && leftPane}
      <div
        id="divider"
        className={classNames(styles.divider, {
          [styles.collapsed]: collapsed,
        })}
        onClick={(): void => setCollapsed((value) => !value)}
      />
    </Desktop>
  );
};

export default MasterIndexLeftPane;
