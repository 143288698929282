/**
 * Labstep
 */

import ResourceItemFormShowEditAmount from 'labstep-web/components/ResourceItem/Form/ShowEdit/Amount';
import ResourceItemFormStatus from 'labstep-web/components/ResourceItem/Form/Status';
import ResourceItemResourceLocationModal from 'labstep-web/components/ResourceItem/ResourceLocationModal';
import { ICONS } from 'labstep-web/constants/icons';
import { IElement } from 'labstep-web/core/List/LabelValue/types';
import { ResourceItem } from 'labstep-web/models/resource-item.model';

export const getItemInfo = (
  resourceItem: ResourceItem,
): IElement[] => [
  {
    icon: 'info circle',
    label: 'Status',
    value: <ResourceItemFormStatus resourceItem={resourceItem} />,
  },
  {
    icon: ICONS.resource_location.primary,
    label: 'Location',
    value: (
      <ResourceItemResourceLocationModal
        resourceItem={resourceItem}
      />
    ),
  },
  {
    icon: ICONS.resource_item.info.quantity,
    label: 'Amount Remaining',
    value: (
      <ResourceItemFormShowEditAmount resourceItem={resourceItem} />
    ),
  },
];

export const getResourceInfo = (
  resourceItem: ResourceItem,
): IElement[] => [
  {
    icon: ICONS.resource.primary,
    label: 'Resource',
    value: <span>{resourceItem.resource.name}</span>,
  },
];
