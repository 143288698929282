/**
 * Labstep
 *
 * @module components/Molecule/Pictograms/ActionAdd
 * @desc Action to add code
 */

import Can from 'labstep-web/components/Entity/Can';
import { Action } from 'labstep-web/components/Entity/Can/types';
import {
  ghsHazardStatements,
  hazardClassPictograms,
  precautionaryStatements,
} from 'labstep-web/components/Molecule/Safety/constants';
import { EntityUpdateContainer } from 'labstep-web/containers/Entity/Update';
import ActionComponent from 'labstep-web/core/Action/Component';
import Select from 'labstep-web/core/Select';
import { useToggle } from 'labstep-web/hooks/toggle';
import React from 'react';
import { PRECAUTIONARY_CODE_TYPES } from '../Precautionary';
import styles from './styles.module.scss';
import { MoleculeSafetyActionAddProps } from './types';
import { getUpdatedPubChemBody } from './utils';

export const MoleculeSafetyActionAdd: React.FC<
  MoleculeSafetyActionAddProps
> = ({ parent, type, subtype, pubChemMetadata }) => {
  const [isToggled, toggle] = useToggle();
  let options;
  let actionText;
  let labelGetter;
  // eslint-disable-next-line default-case
  switch (type) {
    case 'pictograms':
      actionText = 'Add Pictograms';
      options = hazardClassPictograms;
      labelGetter = (p): string =>
        `${p} - ${options[p].additional[0]}`;
      break;
    case 'ghs_hazard_statements':
      actionText = 'Add Hazard Code';
      options = ghsHazardStatements;
      labelGetter = (p): string =>
        `${p} - ${options[p].hazard_statements}`;
      break;
    case 'precautionary':
      actionText = `Add ${subtype} Code`;
      options = Object.keys(precautionaryStatements)
        .filter((p) =>
          p.startsWith(
            `P${PRECAUTIONARY_CODE_TYPES.indexOf(subtype) + 1}`,
          ),
        )
        .reduce((result, key) => ({ ...result, [key]: key }), {});
      labelGetter = (p): string =>
        `${p} - ${precautionaryStatements[p]}`;
      break;
    default:
      break;
  }

  if (isToggled) {
    const selected =
      type === 'precautionary'
        ? parent.properties.Safety[type][subtype]
        : parent.properties.Safety[type];
    return (
      <EntityUpdateContainer
        entityName={parent.entityName}
        id={parent.idAttr}
      >
        {({ update }): React.ReactElement => (
          <Select
            openMenuOnFocus
            autoFocus
            options={Object.keys(options)
              .filter((p) => selected && selected.indexOf(p) === -1)
              .map((p) => ({
                label: labelGetter(p),
                value: p,
              }))}
            onChange={(option): void => {
              const body = getUpdatedPubChemBody(
                parent,
                pubChemMetadata,
                type,
                option.value,
                'add',
                subtype,
              );
              update(body);
              toggle();
            }}
            onBlur={toggle}
          />
        )}
      </EntityUpdateContainer>
    );
  }
  return (
    <Can
      action={Action.edit}
      entityName={parent.entityName}
      id={parent.idAttr}
    >
      <div className={styles.container} onClick={toggle}>
        <ActionComponent type="text" text={actionText} icon="plus" />
      </div>
    </Can>
  );
};

export default MoleculeSafetyActionAdd;
