/**
 * Labstep
 *
 * @module components/File/Action/Create/Picker
 * @desc Action to create file
 */

import classnames from 'classnames';
import Icon from 'labstep-web/core/Icon';
import React from 'react';
import styles from './styles.module.scss';
import { FileActionCreatePickerProps } from './types';

export const FileActionCreatePicker: React.FC<
  FileActionCreatePickerProps
> = ({ icon, text, onClick, disabled }) => (
  <div
    onClick={disabled ? undefined : onClick}
    className={classnames(styles.picker, {
      [styles.disabled]: disabled,
    })}
  >
    <div>
      <Icon name={icon} />
    </div>
    <div>{text}</div>
  </div>
);

export default FileActionCreatePicker;
