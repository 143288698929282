/**
 * Labstep
 *
 * @module screens/Organization/FreeTrialEnded
 * @desc Free Trial Ended screen
 */

import LayoutFullScreenPage from 'labstep-web/components/Layout/FullScreenPage';
import OrganizationPlanActionUpgrade from 'labstep-web/components/OrganizationPlan/Action/Upgrade';
import Header from 'labstep-web/core/Header';
import Image from 'labstep-web/core/Image';
import MailTag from 'labstep-web/core/MailTag';
import React from 'react';
import styles from './styles.module.scss';
import { IScreensOrganizationFreeTrialEndedProps } from './types';

const ScreensOrganizationFreeTrialEnded: React.FC<
  IScreensOrganizationFreeTrialEndedProps
> = ({ userOrganization }) => (
  <LayoutFullScreenPage>
    <div className={styles.center}>
      <Image centered src="/img/error/locked.svg" size="large" />
      <Header>Your trial of Labstep has ended</Header>
      {userOrganization.type === 'admin' ? (
        <>
          <p>
            To regain access to your workspaces click &apos;Upgrade
            Now&apos; or contact <MailTag department="info" />
          </p>
          <OrganizationPlanActionUpgrade
            organization={userOrganization.organization}
          />
        </>
      ) : (
        <p>
          Please contact your organization&apos;s admin to upgrade
        </p>
      )}
    </div>
  </LayoutFullScreenPage>
);

export default ScreensOrganizationFreeTrialEnded;
