/**
 * Labstep
 *
 * @module grid/Report/components/ColumnHeader
 * @desc Protocol Condition DataGrid Header
 */

import { useEntityViewContext } from 'labstep-web/components/EntityView/Context';
import GridReportColumnHeaderContextMenu from 'labstep-web/grid/Report/components/ColumnHeader/ContextMenu';
import GridReportColumnHeaderTrigger from 'labstep-web/grid/Report/components/ColumnHeader/Trigger';
import React from 'react';
import { GridReportColumnHeaderProps } from './types';

export const GridReportColumnHeader: React.FC<
  GridReportColumnHeaderProps
> = ({ sort, filter, ...rest }) => {
  const { column, displayName, icon, cornerIcon, action, ...params } =
    rest;
  const context = useEntityViewContext();
  const trigger = <GridReportColumnHeaderTrigger {...rest} />;
  if (!context) {
    return trigger;
  }
  return (
    <GridReportColumnHeaderContextMenu
      trigger={trigger}
      sort={sort}
      filter={filter}
      {...params}
      {...rest}
    />
  );
};

export default GridReportColumnHeader;
