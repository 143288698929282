/**
 * Labstep
 *
 * @module prosemirror/nodes/hard-break
 * @desc Hard break node
 */

import { getHardBreakCmd } from 'labstep-web/prosemirror/nodes/hard-break/commands';
import { LabstepNode } from 'labstep-web/prosemirror/nodes/types';
import { navigatorService } from 'labstep-web/services/navigator.service';

const NodeHardBreak: LabstepNode = {
  key: 'hard_break',
  spec: {
    inline: true,
    group: 'inline',
    selectable: false,
    parseDOM: [{ tag: 'br' }],
    toDOM() {
      return ['br'];
    },
  },
  keymap: {
    'Mod-Enter': (state, dispatch) =>
      getHardBreakCmd(state.schema.nodes[NodeHardBreak.key])(
        state,
        dispatch,
      ),
    'Shift-Enter': (state, dispatch) =>
      getHardBreakCmd(state.schema.nodes[NodeHardBreak.key])(
        state,
        dispatch,
      ),
    'Ctrl-Enter': (state, dispatch) => {
      if (navigatorService.isMac) {
        return getHardBreakCmd(state.schema.nodes[NodeHardBreak.key])(
          state,
          dispatch,
        );
      }
      return false;
    },
  },
};

export default NodeHardBreak;
