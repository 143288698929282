/**
 * Labstep
 *
 * @module components/Comment/Form/Create
 * @desc Reply bar for comments
 */

import CommentActionCreateFile from 'labstep-web/components/Comment/Action/CreateFile';
import DataTableActionCreate from 'labstep-web/components/DataTable/Action/Create';
import FileActionAttach from 'labstep-web/components/File/Action/Attach';
import RichTextActionCreate from 'labstep-web/components/RichText/Action/Create';
import Button from 'labstep-web/core/Button';
import CodeMirrorModal from 'labstep-web/core/CodeMirror/Modal';
import Dropdown from 'labstep-web/core/Dropdown';
import Icon from 'labstep-web/core/Icon';
import isEqual from 'lodash/isEqual';
import React from 'react';
import { IAttachmentMenuProps } from './types';

export class AttachmentMenu extends React.Component<IAttachmentMenuProps> {
  shouldComponentUpdate(prevProps: IAttachmentMenuProps) {
    return !isEqual(prevProps.status, this.props.status);
  }

  render() {
    const { create, status } = this.props;

    return (
      <Dropdown
        trigger={
          <Button basic primary icon>
            <Icon name="plus" />
          </Button>
        }
        pointing="top left"
        icon={null}
        upward
      >
        <Dropdown.Header content="Files" />

        <CommentActionCreateFile create={create} showOnly="device" />
        <CommentActionCreateFile create={create} showOnly="cloud" />
        <FileActionAttach
          status={status}
          onSubmit={(values, options) => {
            create({ file_id: [values.file.id] }, options);
          }}
        />
        <Dropdown.Divider />

        <Dropdown.Header content="Add" />

        <RichTextActionCreate create={create} status={status} />
        <DataTableActionCreate
          modal
          create={create}
          status={status}
        />

        <CodeMirrorModal
          onSubmit={(body, toggleModal) =>
            create(
              { body: ' ', rich_text: body },
              { onSuccess: toggleModal },
            )
          }
          status={status}
        />
      </Dropdown>
    );
  }
}
export default AttachmentMenu;
