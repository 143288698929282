/**
 * Labstep
 */

import { ProseMirrorConflict } from 'labstep-web/state/new/prosemirror';

// eslint-disable-next-line no-shadow
export enum ProseMirrorStatus {
  ready = 'ready',
  debouncing = 'debouncing',
  error = 'error',
  conflict = 'conflict',
  saved = 'saved',
}

export interface WithProseMirrorStatusMapDispatchToProps {
  proseMirrorKey: number;
  proseMirrorStatus: ProseMirrorStatus;
  conflict: ProseMirrorConflict;
}

export interface WithProseMirrorStatusMapStateToProps {
  setProseMirrorStatus: (
    proseMirrorStatus: ProseMirrorStatus,
  ) => void;
  forceRefresh: VoidFunction;
}

export type WithProseMirrorInjectedProps =
  WithProseMirrorStatusMapDispatchToProps &
    WithProseMirrorStatusMapStateToProps;
