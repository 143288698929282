/**
 * Labstep
 *
 * @module components/Organization/Settings/WhitelistIp
 * @desc Organization settings
 */

import EntityActionUpdate from 'labstep-web/components/Entity/Action/Update';
import OrganizationFormShowEditWhitelistIp from 'labstep-web/components/Organization/Form/ShowEdit/WhitelistIp';
import PremiumFeatureToggle from 'labstep-web/components/Organization/PremiumFeatureToggle';
import Card from 'labstep-web/core/Card/Card';
import Checkbox from 'labstep-web/core/Checkbox';
import { useToggle } from 'labstep-web/hooks/toggle';
import React from 'react';
import { OrganizationSettingsWhitelistIpProps } from './types';

export const OrganizationSettingsWhitelistIp: React.FC<
  OrganizationSettingsWhitelistIpProps
> = ({ organization }) => {
  const [isToggled, toggle] = useToggle();
  if (!organization.hasFeatureEnabled('ip_whitelist')) {
    return <PremiumFeatureToggle premiumFeature="ip_whitelist" />;
  }

  let component = null;
  let sliderComponent;

  if (isToggled || organization.whitelist_ip) {
    component = (
      <Card>
        <OrganizationFormShowEditWhitelistIp
          organization={organization}
        />
      </Card>
    );
  }
  if (organization.whitelist_ip) {
    sliderComponent = (
      <EntityActionUpdate
        entityName={organization.entityName}
        id={organization.id}
        body={{ whitelist_ip: null }}
        actionComponentProps={{
          type: 'checkbox',
          elementProps: { checked: true },
        }}
        options={{ onSuccess: toggle }}
      />
    );
  } else {
    sliderComponent = (
      <div>
        <Checkbox onChange={toggle} toggle />
        {!isToggled && (
          <p>
            Enable to restrict access to organisation workspaces to
            specific IP addresses
          </p>
        )}
      </div>
    );
  }
  return (
    <div>
      {sliderComponent}
      <div>{component}</div>
    </div>
  );
};

export default OrganizationSettingsWhitelistIp;
