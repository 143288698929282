/**
 * Labstep
 *
 * @module prosemirror/components/Menu/Code
 * @desc Menu to display when selecting a node with code mark
 */

import MenuCodeActionDelete from 'labstep-web/prosemirror/components/Menu/Code/Action/Delete';
import MenuCodeActionEdit from 'labstep-web/prosemirror/components/Menu/Code/Action/Edit';
import NodeCode from 'labstep-web/prosemirror/nodes/code';
import {
  SelectionNode,
  getSelectionNodeType,
} from 'labstep-web/prosemirror/utils/selection';
import React, { useEffect, useState } from 'react';
import styles from './styles.module.scss';
import { IMenuCodeProps } from './types';

/** Number of pixels to display menu above selection */
export const LINE_HEIGHT = 40;

const MenuCode: React.FC<IMenuCodeProps> = ({
  state,
  dispatch,
  cursorPosition,
}) => {
  const [codeNode, setCodeNode] = useState<SelectionNode | null>(
    null,
  );

  useEffect(() => {
    setCodeNode(getSelectionNodeType(state, NodeCode.key));
  }, [state]);

  if (!codeNode) {
    return null;
  }

  return (
    <div
      className={styles.container}
      style={{
        position: 'fixed',
        top: `${cursorPosition.top - LINE_HEIGHT}px`,
        left: `${cursorPosition.left}px`,
      }}
    >
      <MenuCodeActionEdit
        state={state}
        dispatch={dispatch}
        codeNode={codeNode}
      />
      <MenuCodeActionDelete state={state} dispatch={dispatch} />
    </div>
  );
};

export default MenuCode;
