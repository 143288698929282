/**
 * Labstep
 *
 * @module screens/EntityView/Index/LeftPane
 * @desc EntityView Index LeftPane
 */

import EntityViewFilterPane from 'labstep-web/components/EntityView/Filter/Pane';
import FolderPane from 'labstep-web/components/Folder/Pane';
import LayoutLeftPane from 'labstep-web/components/Layout/LeftPane';
import { FolderType } from 'labstep-web/models/folder.model';
import React from 'react';
import { EntityViewIndexLeftPaneProps } from './types';

export const EntityViewIndexLeftPane: React.FC<
  EntityViewIndexLeftPaneProps
> = () => (
  <LayoutLeftPane>
    <FolderPane folderType={FolderType.entity_view} />
    <EntityViewFilterPane />
  </LayoutLeftPane>
);

export default EntityViewIndexLeftPane;
