/**
 * Labstep
 *
 * @module components/ProtocolCollection/Item
 * @desc ProtocolCollection Item
 */

import ProfilePicture from 'labstep-web/components/Profile/Picture';
import ActionMenu from 'labstep-web/components/ProtocolCollection/Action/Menu';
import ProtocolConditionActionCompareData from 'labstep-web/components/ProtocolCondition/Action/CompareData';
import Card from 'labstep-web/core/Card/Card';
import { CardHeader } from 'labstep-web/core/Card/Generic';
import React from 'react';
import ProtocolCollectionActionRun from '../Action/Run';
import styles from './styles.module.scss';
import { IProtocolCollectionItemProps } from './types';

const ProtocolCollectionItem: React.FC<
  IProtocolCollectionItemProps
> = ({ protocolCollection, actions }) => {
  const { last_or_draft_version: protocol } = protocolCollection;

  const actionMenu = (
    <ActionMenu
      protocolCollection={protocolCollection}
      actions={actions}
    />
  );

  const image = <ProfilePicture entity={protocolCollection.author} />;

  return (
    <Card className={styles.card}>
      <CardHeader
        image={image}
        title={{
          to: 'protocol_collection_show',
          params: { id: protocolCollection.id },
          title: protocolCollection.name,
        }}
        subtitle={{ date: protocol.created_at }}
        actionMenu={actionMenu}
        mainAction={
          <>
            <ProtocolCollectionActionRun
              protocolCollection={protocolCollection}
            />
            <ProtocolConditionActionCompareData
              actionComponentType="button"
              protocolCollection={protocolCollection}
            />
          </>
        }
      />
    </Card>
  );
};

export default ProtocolCollectionItem;
