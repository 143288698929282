/**
 * Labstep
 *
 * @module components/ProtocolValue/Form/ShowEdit/LineageTracking
 * @desc ProtocolValue LineageTracking
 */

import EntityActionUpdateCheckbox from 'labstep-web/components/Entity/Action/Update/Checkbox';
import React from 'react';
import { IProtocolValueShowEditLineageTrackingProps } from './types';

export const ProtocolValueShowEditLineageTracking: React.FC<
  IProtocolValueShowEditLineageTrackingProps
> = ({ protocolValue }) => (
  <EntityActionUpdateCheckbox
    entity={protocolValue}
    field={'is_lineage' as const}
    toggle
  />
);

export default ProtocolValueShowEditLineageTracking;
