/**
 * Labstep
 */

import { PostFilter } from 'labstep-web/services/postFilter';
import { PostFilterComparison } from 'labstep-web/services/query-parameter.service';

export const PostFilterTemplate = new PostFilter(
  [['guid', PostFilterComparison.eq]],
  'template',
);

export const PostFilterTemplateNull = new PostFilter(
  [['guid', PostFilterComparison.null]],
  'template',
);
