/**
 * Labstep
 *
 * @module components/Entity/Preview
 * @desc Entity Preview
 */

import EntityLink from 'labstep-web/components/Entity/Link';
import EntityLinkComponent from 'labstep-web/components/Entity/Link/Component';
import { EntityLinkEntity } from 'labstep-web/components/Entity/Link/types';
import EntityPrimaryInfo from 'labstep-web/components/Entity/PrimaryInfo';
import ActionPreview from 'labstep-web/core/Action/Preview';
import { Information } from 'labstep-web/core/Card/Information';
import Flex from 'labstep-web/core/Flex';
import Icon from 'labstep-web/core/Icon';
import Modal from 'labstep-web/core/Modal/Default';
import React from 'react';
import {
  EntityPreviewContentProps,
  EntityPreviewProps,
} from './types';

export const EntityPreviewContent: React.FC<
  EntityPreviewContentProps
> = ({ entity, nameEditable = true, prefix, ...rest }) => (
  <Information
    primaryInfo={
      <Flex
        style={{
          justifyContent: 'space-between',
        }}
      >
        <EntityPrimaryInfo
          entity={entity}
          prefix={prefix}
          withPermaLink
          disabled={!nameEditable}
        />
        <EntityLink entity={entity as EntityLinkEntity}>
          <b>Open Full Page</b> <Icon name="external" />
        </EntityLink>
      </Flex>
    }
    {...rest}
  />
);

export const EntityPreview: React.FC<EntityPreviewProps> = ({
  entity,
  showIcon,
  secondary,
  modalProps,
  displayName,
  type = 'name',
  ...rest
}) => (
  <Modal
    data-testid="entity-preview-modal"
    size="large"
    content={<EntityPreviewContent entity={entity} {...rest} />}
    viewComponent={
      type === 'icon' ? (
        ({ toggleModal }) => <ActionPreview onClick={toggleModal} />
      ) : (
        <EntityLinkComponent
          entity={entity as EntityLinkEntity}
          showIcon={showIcon}
          secondary={secondary}
          displayName={displayName}
        />
      )
    }
    {...modalProps}
  />
);

export default EntityPreview;
