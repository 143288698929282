/**
 * Labstep
 *
 * @module screens/Error/NoRouteMatch
 * @desc Error when no route matches
 */

import FullPageMessage from 'labstep-web/components/Layout/FullPageMessage';
import Button from 'labstep-web/core/Button';
import DocumentHead from 'labstep-web/core/DocumentHead';
import React from 'react';
import { Redirect, withRouter } from 'react-router';
import { INoRouteMatchProps } from './types';

export const NoRouteMatch: React.FC<INoRouteMatchProps> = ({
  location,
  to = 'app_homepage',
}) => {
  // Backward compatibility layer for renaming project to workspace in url
  // TODO: Remove in January 2021
  if (location.pathname.includes('project/')) {
    return (
      <Redirect
        to={location.pathname.replace('project/', 'workspace/')}
      />
    );
  }
  return (
    <>
      <DocumentHead title="404 Not Found" />
      <FullPageMessage
        src="/img/error/404.svg"
        title="Oops!"
        explanation="Looks like the page you’re looking for doesn’t exist"
        action={
          <Button primary route={{ to }}>
            Back to home
          </Button>
        }
      />
    </>
  );
};

export default withRouter(NoRouteMatch);
