/**
 * Labstep
 *
 * @module components/Profile/Picture
 * @desc Profile Picture. Allows for upload new on Click.
 */

import { useHasAccess } from 'labstep-web/components/Entity/Can';
import { Action } from 'labstep-web/components/Entity/Can/types';
import FileActionUpload from 'labstep-web/components/File/Action/Upload';
import Popup from 'labstep-web/core/Popup';
import React from 'react';
import ProfileImage from './Image';
import {
  IProfilePictureProps,
  IUpdatableProfilePhotoProps,
} from './types';

export const UpdatableProfilePhoto: React.FC<
  IUpdatableProfilePhotoProps
> = ({ ...rest }) => (
  <FileActionUpload
    entityId={rest.entity.profile.id}
    entityType="profile"
    type="image"
  >
    <Popup
      trigger={
        <div style={{ cursor: 'pointer' }}>
          <ProfileImage {...rest} linkable={false} />
        </div>
      }
      content="Upload image"
      size="mini"
    />
  </FileActionUpload>
);

export const ProfilePicture: React.FC<IProfilePictureProps> = ({
  uploadable,
  ...rest
}) => {
  const canEdit = useHasAccess(
    rest.entity.entityName,
    rest.entity.id,
    Action.edit,
  );
  if (uploadable && canEdit) {
    return <UpdatableProfilePhoto {...rest} />;
  }

  return <ProfileImage {...rest} />;
};

ProfilePicture.defaultProps = {
  uploadable: false,
};

export default ProfilePicture;
