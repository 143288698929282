/**
 * Labstep
 *
 * @module ProtocolTimer/Form/Edit
 * @desc Protocol Timer Edit Form
 */

import { protocolTimerFormFields } from 'labstep-web/components/ProtocolTimer/Form/fields';
import { preSubmit } from 'labstep-web/components/ProtocolTimer/Form/utils';
import EditForm from 'labstep-web/core/Form/Edit';
import React from 'react';
import { IProtocolTimerFormEditProps } from './types';

export const ProtocolTimerFormEdit: React.FC<
  IProtocolTimerFormEditProps
> = ({ protocolTimer, options }) => (
  <div>
    <EditForm
      autoFocus
      entity={protocolTimer}
      entityName={protocolTimer.entityName}
      fields={protocolTimerFormFields}
      options={options}
      preSubmit={preSubmit}
    />
  </div>
);

export default ProtocolTimerFormEdit;
