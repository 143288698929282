/**
 * Labstep
 */

import MetadataActionCreateSequence from 'labstep-web/components/Metadata/Action/Create/Sequence';
import MetadataActionSetLatestDeviceData from 'labstep-web/components/Metadata/Action/SetLatestDeviceData';
import {
  DeviceDataOptionComponent,
  deviceDataGetOptionLabel,
} from 'labstep-web/components/Metadata/Form/ShowEdit/DeviceData/utils';
import {
  getFieldsDate,
  getFieldsOptions,
} from 'labstep-web/components/Metadata/Form/fields';
import SequenceActionUpdate from 'labstep-web/components/Sequence/Action/Update';
import { EntityReadEntityContainer } from 'labstep-web/containers/Entity/Read/Entity';
import DataGrid from 'labstep-web/core/DataGrid';
import DataGridPlaceholder from 'labstep-web/core/DataGrid/Placeholder';
import {
  CellRendererParams,
  ColDef,
  ColDefParams,
  EditableCallback,
} from 'labstep-web/core/DataGrid/types';
import {
  getEntityDefault,
  setValue,
  startEditingActiveCell,
  stopEditingAndFocus,
} from 'labstep-web/core/DataGrid/utils';
import colDefStructure from 'labstep-web/grid/Index/coldefs/Molecule/structure';
import { DeviceData } from 'labstep-web/models/device-data.model';
import { Metadata } from 'labstep-web/models/metadata';
import {
  MetadataParent,
  MetadataType,
} from 'labstep-web/models/metadata/types';
import { getMetadataFieldName } from 'labstep-web/models/metadata/utils';
import { ProtocolCondition } from 'labstep-web/models/protocol-condition.model';
import { validateNumeric } from 'labstep-web/services/amount-unit.service';
import { isValid } from 'labstep-web/services/validation';
import rules from 'labstep-web/services/validation/rules';
import React from 'react';
import { Schema } from 'yup';
import { getPropTypeMetadata } from './types';

/**
 * These metadata types are not yet supported
 * Requires creation of file/sequence pre-update
 */
const METADATA_TYPES_COPY_UNSUPPORTED = [
  MetadataType.file,
  MetadataType.sequence,
  MetadataType.molecule,
];

/**
 * These metadata types need to be parsed for the clipboard
 */
const METADATA_TYPES_JSON = [MetadataType.options];

/** Get validation rule based on type */
export const getRule = (type: Metadata['type']): Schema => {
  let rule: any = '';
  switch (type) {
    case MetadataType.default:
      rule = rules.metadata.value;
      break;
    case MetadataType.date:
      rule = rules.metadata.date;
      break;
    case MetadataType.datetime:
      rule = rules.metadata.datetime;
      break;
    case MetadataType.numeric:
      rule = rules.metadata.number;
      break;
    default:
      break;
  }
  return rule;
};

export const getCellRenderer =
  <T extends Metadata | MetadataParent | ProtocolCondition>(
    getProp: getPropTypeMetadata,
    editable: EditableCallback,
    getNestedParentEntity?: (
      params: ColDefParams<T>,
    ) => Metadata | MetadataParent | ProtocolCondition,
  ): ColDef<T>['cellRenderer'] =>
  (params: CellRendererParams<T>) =>
    getProp(params, (entity: Metadata) => {
      const getParentEntity =
        getNestedParentEntity || getEntityDefault;
      const parentEntity = getParentEntity(params);
      const createProps =
        parentEntity instanceof ProtocolCondition ||
        parentEntity instanceof Metadata
          ? undefined
          : entity.getCreateProps(parentEntity);
      if (entity.protocol_device) {
        if (entity.device_data) {
          if (entity.type === MetadataType.file) {
            return (
              <DataGrid.CellRenderer.File
                {...params}
                props={{ files: entity.device_data.files, editable }}
              />
            );
          }
          return entity.device_data.printValue;
        }
        return editable(params) ? (
          <EntityReadEntityContainer
            entityName={entity.entityName}
            id={entity.id}
          >
            {({ entity: metadata }) => (
              <MetadataActionSetLatestDeviceData
                metadata={metadata}
                deviceId={metadata.protocol_device.device.id}
                options={{
                  onSuccess: (): void =>
                    params.api.refreshCells({
                      rowNodes: [params.node],
                      columns: [params.column],
                      force: true,
                    }),
                }}
              />
            )}
          </EntityReadEntityContainer>
        ) : null;
      }
      switch (entity.type) {
        case MetadataType.file: {
          return (
            <DataGrid.CellRenderer.File
              {...params}
              props={{ files: entity.files, editable }}
            />
          );
        }
        case MetadataType.options: {
          return (
            <DataGrid.CellRenderer.MultiSelect
              {...params}
              props={{
                values: entity.optionsSelectedValues,
                editable,
              }}
            />
          );
        }
        case MetadataType.sequence: {
          if (entity.sequence && !createProps) {
            return (
              entity.printValue || (
                <DataGridPlaceholder
                  params={params}
                  children={getPlaceholderText(entity.type)}
                  editable={editable}
                />
              )
            );
          }
          return editable(params) ? (
            <MetadataActionCreateSequence
              metadata={entity}
              createProps={createProps}
              actionComponentProps={{
                elementProps: { placeholder: true, gridCell: true },
              }}
              options={{
                onSuccess: () => startEditingActiveCell(params),
              }}
            />
          ) : null;
        }
        case MetadataType.molecule: {
          const colDef = colDefStructure({
            getNestedEntity: () => entity.molecule,
            getCreateProps: () => createProps,
          });
          return colDef?.cellRenderer
            ? colDef.cellRenderer(params)
            : null;
        }
        default:
          break;
      }
      if (entity.hasValue && !entity.is_template) {
        return entity.printValue;
      }
      return (
        <DataGridPlaceholder
          params={params}
          children={getPlaceholderText(entity.type)}
          editable={editable}
        />
      );
    });

export const getCellEditorSelector =
  <T extends Metadata | MetadataParent | ProtocolCondition>(
    getProp: getPropTypeMetadata,
    getNestedParentEntity?: (
      params: ColDefParams<T>,
    ) => Metadata | MetadataParent | ProtocolCondition,
  ): ColDef<T>['cellEditorSelector'] =>
  (params: any): ReturnType<any> =>
    getProp(params, (entity: Metadata) => {
      const getParentEntity =
        getNestedParentEntity || getEntityDefault;
      const parentEntity = getParentEntity(params);
      const createProps =
        parentEntity instanceof ProtocolCondition ||
        parentEntity instanceof Metadata
          ? undefined
          : entity.getCreateProps(parentEntity);
      // not supported with templating for now
      if (entity.protocol_device) {
        return {
          component: DataGrid.CellEditor.SearchSelect,
          params: {
            props: {
              entityName: DeviceData.entityName,
              entity,
              getBody: (value: any): Record<string, unknown> => ({
                device_data_id: value?.id || null,
              }),
              params: {
                type: entity.type,
                device_id: entity.protocol_device.device.id,
              },
              components: { Option: DeviceDataOptionComponent },
              getOptionLabel: deviceDataGetOptionLabel,
              options: {
                onSuccess: (): void =>
                  params.api.refreshCells({
                    rowNodes: [params.node],
                    columns: [params.column],
                    force: true,
                  }),
              },
            },
          },
          popup: true,
        };
      }
      switch (entity.type) {
        case MetadataType.file: {
          return {
            component: DataGrid.CellEditor.File,
            params: {
              props: {
                entity,
                createProps,
              },
            },
            popup: true,
          };
        }
        case MetadataType.options: {
          return {
            component: DataGrid.CellEditor.ShowEditOptions,
            params: {
              props: {
                entity,
                entityName: Metadata.entityName,
                field: getFieldsOptions(
                  entity,
                  true,
                  entity.is_template,
                  true,
                )[0],
                createProps,
              },
            },
            popup: true,
          };
        }
        case MetadataType.sequence: {
          return {
            component: (): React.ReactNode => (
              <SequenceActionUpdate
                sequenceId={(entity as Metadata).sequence!.id}
                onClose={(): void => stopEditingAndFocus(params)}
              />
            ),
            popup: true,
          };
        }
        case MetadataType.datetime:
        case MetadataType.date: {
          return {
            component: DataGrid.CellEditor.Date,
            params: {
              props: {
                field: getFieldsDate(entity.is_template)[0].name,
                entity,
                dateOnly: entity.type === MetadataType.date,
                createProps,
              },
            },
            popup: true,
            popupPosition: 'under',
          };
        }
        case MetadataType.molecule: {
          const colDef = colDefStructure({
            getNestedEntity: () => entity.molecule,
            getCreateProps: () => createProps,
          });
          return {
            component: (): React.ReactNode =>
              colDef.cellEditor(params),
            popup: colDef.cellEditorPopup,
          };
        }
        default:
          break;
      }
      return {
        component: 'agTextCellEditor',
      };
    });

export const getValueGetter =
  (
    getProp: getPropTypeMetadata,
  ): ColDef<
    Metadata | MetadataParent | ProtocolCondition
  >['valueGetter'] =>
  (params): string | false | null =>
    getProp(
      params,
      (entity) => {
        // hide default value
        if (entity.is_template) {
          return null;
        }
        if (entity.type === MetadataType.numeric) {
          // detect unit change too
          return entity.protocol_device
            ? entity.device_data?.printValue
            : entity.printValue;
        }
        if (entity.protocol_device) {
          if (entity.device_data) {
            return (entity.device_data as any)[
              getMetadataFieldName(entity.type)
            ];
          }
          return null;
        }
        let value = (entity as any)[
          getMetadataFieldName(entity.type)
        ];
        if (METADATA_TYPES_JSON.includes(entity.type)) {
          value = JSON.stringify(value);
        }
        return value;
      },
      // we fall back on false instead of null so that when the entity is loaded
      // and the value is null, the grid detects the change and rerenders the cell
      false,
    );

/** Process values for specific metadata types */
export const preProcessValue = (
  entity: Metadata,
  value: unknown,
): { value: unknown; error: boolean } => {
  const invalid = { value: null, error: true };
  let newValue = value;
  if (entity.type === MetadataType.numeric) {
    if (
      typeof newValue === 'number' ||
      typeof newValue === 'string' ||
      newValue === null
    ) {
      newValue = validateNumeric(newValue);
      if (newValue === false) {
        return invalid;
      }
      newValue = newValue === null ? newValue : Number(newValue);
    } else {
      return invalid;
    }
  }
  if (METADATA_TYPES_JSON.includes(entity.type)) {
    if (typeof newValue === 'string') {
      try {
        newValue = JSON.parse(newValue);
      } catch {
        return invalid;
      }
    } else {
      return invalid;
    }
  }
  return { value: newValue, error: false };
};

export const getValueSetter =
  <T extends Metadata | MetadataParent | ProtocolCondition>(
    getProp: getPropTypeMetadata,
    getNestedParentEntity?: (
      params: ColDefParams<T>,
    ) => Metadata | MetadataParent | ProtocolCondition,
  ): ColDef<T>['valueSetter'] =>
  (params: any): boolean =>
    getProp(params, (entity) => {
      const getParentEntity =
        getNestedParentEntity || getEntityDefault;
      const parentEntity = getParentEntity(params);
      const createProps =
        parentEntity instanceof ProtocolCondition ||
        parentEntity instanceof Metadata
          ? undefined
          : entity.getCreateProps(parentEntity);
      return setValue(
        getMetadataFieldName(entity.type),
        params.newValue,
        {
          entityName: Metadata.entityName,
          id: entity.id,
          createProps,
        },
        (newValue) => {
          const { value, error } = preProcessValue(entity, newValue);
          return {
            value,
            error:
              error ||
              METADATA_TYPES_COPY_UNSUPPORTED.includes(entity.type) ||
              !!entity.protocol_device ||
              !isValid(value, getRule(entity.type)),
          };
        },
      );
    }) || false;

export const getPlaceholderText = (type: MetadataType): string => {
  if (type === MetadataType.date || type === MetadataType.datetime) {
    return 'Select';
  }
  if (type === MetadataType.molecule) {
    return 'Draw structure';
  }
  if (type === MetadataType.sequence) {
    return 'Edit sequence';
  }
  return 'Enter';
};

export const getEditable =
  (getProp: getPropTypeMetadata): EditableCallback =>
  (params: any) =>
    !!getProp(params, (entity) => {
      if (entity.type === MetadataType.molecule) {
        return !!entity.molecule;
      }
      if (entity.type === MetadataType.sequence) {
        return !!entity.sequence;
      }
      return true;
    });
