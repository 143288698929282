/**
 * Labstep
 *
 * @module components/EntityStateWorkflow/SearchSelect
 * @desc Search entity state workflows
 */

import EntityLink from 'labstep-web/components/Entity/Link';
import { withActiveGroup } from 'labstep-web/containers/ActiveGroup';
import { ShowEditToggleComponent } from 'labstep-web/core/Form/ShowEdit';
import { FormShowEditSearchSelectToggleViewComponent } from 'labstep-web/core/Form/ShowEdit/SearchSelect/Toggle';
import Select from 'labstep-web/core/Select';
import { ReadOnMountHOC } from 'labstep-web/hoc/ReadOnMount';
import { useModalRoute } from 'labstep-web/hooks/modalRoute';
import { EntityStateWorkflow } from 'labstep-web/models/entity-state-workflow.model';
import { getHumanReadableEntityName } from 'labstep-web/services/i18n.service';
import React, { useState } from 'react';
import { ENTITY_STATE_WORKFLOW_CONFIGURE_ROUTE_ID } from '../Action/ConfigureWorkflow';
import EntityStateWorkflowSearchSelectOption from './Option';
import styles from './styles.module.scss';
import {
  EntityStateWorkflowSearchSelectContainerProps,
  EntityStateWorkflowSearchSelectProps,
} from './types';
import { entityToOption, handleChange } from './utils';

export const EntityStateWorkflowSearchSelect: React.FC<
  EntityStateWorkflowSearchSelectProps
> = ({
  experimentWorkflow,
  updateExperimentWorkflow,
  createEntityStateWorkflow,
  creatable = false,
  isClearable = false,
  entityStateWorkflows,
}) => {
  const [searchText, setSearchText] = useState<string>('');
  const { toggleModal } = useModalRoute(
    ENTITY_STATE_WORKFLOW_CONFIGURE_ROUTE_ID,
  );
  const newWorkflowOption = {
    label:
      searchText === ''
        ? 'New Workflow'
        : `New Workflow: ${searchText}`,
    value: 'entity',
    description: '',
    icon: 'add',
  };

  return (
    <ShowEditToggleComponent
      className={styles.select}
      canEdit
      initialEditingState={!experimentWorkflow.entity_state_workflow}
      hasValue={!!experimentWorkflow.entity_state_workflow}
      children={<div />}
      customShow={({ toggle }) => (
        <FormShowEditSearchSelectToggleViewComponent
          onExpand={toggle}
          children={
            <EntityLink
              entity={experimentWorkflow.entity_state_workflow!}
            />
          }
        />
      )}
      toggledComponent={({ toggle }) => (
        <Select
          openMenuOnFocus
          autoFocus
          className={styles.select}
          isCreatable={creatable}
          isClearable={isClearable}
          onBlur={() => {
            toggle();
          }}
          onChange={(option: any) => {
            handleChange(
              option,
              createEntityStateWorkflow,
              updateExperimentWorkflow,
              searchText,
              experimentWorkflow,
              toggleModal,
            );
            toggle();
          }}
          onInputChange={(inputValue: string) =>
            setSearchText(inputValue)
          }
          value={
            experimentWorkflow.entity_state_workflow
              ? entityToOption(
                  experimentWorkflow.entity_state_workflow,
                )
              : null
          }
          placeholder="Select existing or create workflow"
          options={[
            ...entityStateWorkflows
              .filter(
                (entity: any) =>
                  entity.id !==
                  experimentWorkflow.entity_state_workflow?.id,
              )
              .map((entity: any) => ({
                label: entity.name,
                value: entity.id,
                description: entity.description ?? '',
                entity,
              })),
            newWorkflowOption,
          ]}
          formatOptionLabel={(option: any) => (
            <EntityStateWorkflowSearchSelectOption
              option={option}
              experimentWorkflow={experimentWorkflow}
            />
          )}
        />
      )}
      name={getHumanReadableEntityName(
        EntityStateWorkflow.entityName,
        false,
        true,
      )}
    />
  );
};

export const EntityStateWorkflowSearchSelectContainer: React.FC<
  EntityStateWorkflowSearchSelectContainerProps
> = ({
  experimentWorkflow,
  updateExperimentWorkflow,
  createEntityStateWorkflow,
  creatable = false,
  isClearable = false,
  params = {},
  activeGroup,
}) => (
  <div data-testid="search-select">
    <ReadOnMountHOC
      type="cursor"
      pagination={{ loader: 'placeholder' }}
      entityName={EntityStateWorkflow.entityName}
      params={{
        group_id: activeGroup?.id,
        ...params,
        count: 100,
      }}
    >
      {({ entities }) => (
        <EntityStateWorkflowSearchSelect
          experimentWorkflow={experimentWorkflow}
          updateExperimentWorkflow={updateExperimentWorkflow}
          createEntityStateWorkflow={createEntityStateWorkflow}
          creatable={creatable}
          isClearable={isClearable}
          entityStateWorkflows={entities}
        />
      )}
    </ReadOnMountHOC>
  </div>
);
export default withActiveGroup(
  EntityStateWorkflowSearchSelectContainer,
);
