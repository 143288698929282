/**
 * Labstep
 *
 * @module core/ChemDraw
 * @desc ChemDraw component
 */

import Loadable from 'labstep-web/core/Loadable';
import { useHasFeatureFlagEnabled } from 'labstep-web/hooks/feature-flag';
import React from 'react';
import { IChemDrawContainerProps } from './types';

export const ChemDrawComponent = React.lazy(
  () => import('./component'),
);
export const MarvinJSComponent = React.lazy(() => import('./marvin'));

const ChemDraw: React.FC<IChemDrawContainerProps> = (props) => {
  const hasFeatureEnabled = useHasFeatureFlagEnabled('marvinjs');
  return (
    <Loadable>
      {hasFeatureEnabled &&
      !(!!props.molecule.data && !props.molecule.smiles) ? (
        <MarvinJSComponent {...props} />
      ) : (
        <ChemDrawComponent
          {...props}
          hasMarvinJSEnabled={hasFeatureEnabled}
        />
      )}
    </Loadable>
  );
};

export default ChemDraw;
