/**
 * Labstep
 *
 * @module components/Protocol/Changelog
 * @desc A sidebar component to show protocol changelog
 */

import SideMenu from 'labstep-web/components/Layout/Menu/Side';
import ProtocolVersions from 'labstep-web/components/Protocol/List';
import ProtocolVersion from 'labstep-web/components/Protocol/Version';
import React from 'react';
import styles from './styles.module.scss';
import { IProtocolChangelogProps } from './types';

const ProtocolChangelog: React.FC<IProtocolChangelogProps> = ({
  protocol,
  protocolCollection,
}) => (
  <SideMenu
    header="Versions"
    name="changelog"
    viewComponent={({ toggleSideMenu }) => (
      <ProtocolVersion onClick={toggleSideMenu} protocol={protocol} />
    )}
  >
    <div className={styles.sideMenuContent}>
      <ProtocolVersions
        activeProtocol={protocol}
        protocolCollectionId={protocolCollection.id}
      />
    </div>
  </SideMenu>
);

export default ProtocolChangelog;
