/**
 * Labstep
 *
 * @module components/Permission/Select/Group
 * @desc Select a group to create a new permission
 */

import GroupSearchSelect from 'labstep-web/components/Group/SearchSelect';
import { EntityCreateContainer } from 'labstep-web/containers/Entity/Create';
import { Permission } from 'labstep-web/models/permission.model';
import React from 'react';
import {
  PermissionSelectGroupContainerProps,
  PermissionSelectGroupProps,
} from './types';

export class PermissionSelectGroup extends React.Component<PermissionSelectGroupProps> {
  constructor(props: PermissionSelectGroupProps) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(value) {
    if (!(value && value.guid)) {
      return;
    }
    const { parentEntity, create, type } = this.props;

    create(
      {
        [`${parentEntity.entityName}_id`]:
          parentEntity.entityName === 'entity_state_workflow'
            ? parentEntity.id
            : parentEntity.guid,
        group_id: value.guid,
        type,
      },
      {
        toast: true,
      },
    );
  }

  render() {
    const { parentEntity } = this.props;

    const params: any = {
      not_id: parentEntity.owner.guid,
    };

    return (
      <div className="group-sharing">
        <GroupSearchSelect
          value={null}
          openMenuOnFocus
          creatable
          params={params}
          onChange={this.handleChange}
        />
      </div>
    );
  }
}

export const PermissionSelectGroupContainer: React.FC<
  PermissionSelectGroupContainerProps
> = (props) => (
  <EntityCreateContainer entityName={Permission.entityName}>
    {({ create, status }) => (
      <PermissionSelectGroup
        create={create}
        status={status}
        {...props}
      />
    )}
  </EntityCreateContainer>
);

export default PermissionSelectGroupContainer;
