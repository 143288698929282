/**
 * Labstep
 *
 * @module components/UserOrganization/Action/ManagePermissions
 * @desc Update workspace permissions per user
 */

import EntitySearch from 'labstep-web/components/Entity/Search';
import { BulkAction } from 'labstep-web/components/Entity/Search/BulkActions/types';
import GroupCardEmptyState from 'labstep-web/components/Group/Card/EmptyState';
import UserGroupTable from 'labstep-web/components/UserGroup/Table';
import { withAuthenticatedUserEntity } from 'labstep-web/containers/AuthenticatedUser';
import { EntityCreateContainer } from 'labstep-web/containers/Entity/Create';
import ActionComponent from 'labstep-web/core/Action/Component';
import ModalDefault from 'labstep-web/core/Modal/Default';
import FloatingSearchSelect from 'labstep-web/core/Select/FloatingSearch';
import { Group } from 'labstep-web/models/group.model';
import { UserGroup } from 'labstep-web/models/user-group.model';
import { User } from 'labstep-web/models/user.model';
import { getHumanReadableEntityName } from 'labstep-web/services/i18n.service';
import React from 'react';
import { IUserOrganizationActionManageWorkspacesProps } from './types';

export const UserOrganizationActionManageWorkspaces: React.FC<
  IUserOrganizationActionManageWorkspacesProps
> = ({ userOrganization, authenticatedUserEntity }) => {
  if (authenticatedUserEntity.userOrganization?.type !== 'admin') {
    return null;
  }

  const confirmMessage = `Are you sure you want to remove ${
    userOrganization.user.name
  } from the ${getHumanReadableEntityName(
    UserGroup.entityName,
    true,
  )}?`;

  return (
    <ModalDefault
      header={`Assign Workspace Roles: ${userOrganization.user.name}`}
      size="small"
      content={() => (
        <EntitySearch
          params={{ user_id: userOrganization.user.id }}
          entityName={UserGroup.entityName}
          tableFormat={false}
          bulkActions={[
            {
              action: BulkAction.delete,
              props: {
                confirmMessage,
                actionComponentProps: {
                  type: 'button',
                  icon: 'ban',
                  text: 'Remove from Workspace',
                },
              },
            },
          ]}
          action={
            <EntityCreateContainer
              entityName={UserGroup.entityName}
              parentName={User.entityName}
              parentId={userOrganization.user.id}
            >
              {({ create, status }) => (
                <FloatingSearchSelect
                  entityName={Group.entityName}
                  params={{
                    organization_id: userOrganization.organization.id,
                    user_not_id: userOrganization.user.id,
                    count: 5,
                  }}
                  placeholder="Search workspaces ..."
                  onChange={(option) => {
                    create({
                      group_id: option.id,
                    });
                  }}
                  isClearable={false}
                  isOptionDisabled={() => status && status.isFetching}
                  actionComponentProps={{
                    type: 'button',
                    text: 'Add to Workspace(s)',
                  }}
                  alignRight
                  keepMenuOpen
                  removeSelectedOption
                />
              )}
            </EntityCreateContainer>
          }
          noResultsMessage={
            <GroupCardEmptyState isActionAllowed={false} />
          }
          searchBarProps={{
            placeholder: `Search ${userOrganization.user.name}'s workspaces`,
          }}
        >
          {({ entities }) => <UserGroupTable userGroups={entities} />}
        </EntitySearch>
      )}
      viewComponent={({ toggleModal }) => (
        <ActionComponent
          type="option"
          icon="users"
          text="Assign Workspace Roles"
          onClick={toggleModal}
        />
      )}
    />
  );
};

export default withAuthenticatedUserEntity(
  UserOrganizationActionManageWorkspaces,
);
