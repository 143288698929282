/**
 * Labstep
 *
 * @module components/Experiment/StartEnd
 * @desc Start or finish depending on status
 */

import ExperimentActionEndReverse from 'labstep-web/components/Experiment/Action/End/Reverse';
import ExperimentActionEndWithCheck from 'labstep-web/components/Experiment/Action/End/WithCheck';
import ExperimentActionStart from 'labstep-web/components/Experiment/Action/Start';
import { Experiment } from 'labstep-web/models/experiment.model';
import { navigation } from 'labstep-web/services/navigation';
import React from 'react';
import { withRouter } from 'react-router';
import { ExperimentActionStartEndProps } from './types';

export const ExperimentActionStartEnd: React.FC<
  ExperimentActionStartEndProps
> = ({
  experiment,
  history,
  actionType,
  redirect = true,
  showReverse = true,
}) => {
  if (experiment.started_at) {
    if (experiment.ended_at) {
      return showReverse ? (
        <ExperimentActionEndReverse experiment={experiment} />
      ) : null;
    }
    return (
      <ExperimentActionEndWithCheck
        actionType={actionType}
        options={{
          onSuccess: ({ response }) => {
            if (!response) {
              return;
            }
            const experimentInState = response.entities.experiment[
              response.result as string
            ] as Experiment | null;
            if (experimentInState) {
              history.push(
                navigation.get('experiment_workflow_show_workflow', {
                  id: experimentInState.experiment_workflow,
                }),
              );
            }
          },
        }}
        experiment={experiment}
      />
    );
  }

  return (
    <ExperimentActionStart
      options={
        redirect
          ? {
              onSuccess: ({ response }) =>
                history.push(
                  navigation.get('experiment_show', {
                    id: response!.result,
                  }),
                ),
            }
          : {}
      }
      experiment={experiment}
    />
  );
};

export default withRouter(ExperimentActionStartEnd);
