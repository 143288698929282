/**
 * Labstep
 *
 * @module models/user-group-notification
 * @desc Typescript export class for UserGroupNotification
 */

import { Type } from 'class-transformer';
import { Entity } from 'labstep-web/models/entity.model';
import { NotificationType } from 'labstep-web/models/notification.model';
import { UserGroup } from 'labstep-web/models/user-group.model';

// eslint-disable-next-line no-shadow
export enum UserGroupNotificationMode {
  always = 'always',
  collaborator = 'collaborator',
}

export const UserGroupNotificationModeValues = {
  always: 'All in workspace',
  collaborator: "Only if I'm a collaborator",
};

export class UserGroupNotification extends Entity {
  public static readonly entityName = 'user_group_notification';

  public get entityName(): string {
    return UserGroupNotification.entityName;
  }

  public constructor(data: Partial<UserGroupNotification> = {}) {
    super();
    Object.assign(this, data);
  }

  public id!: number;

  public notification_type!: NotificationType;

  public is_email!: boolean;

  public is_push!: boolean;

  public mode!: UserGroupNotificationMode;

  @Type(() => UserGroup)
  public user_group!: UserGroup;
}
