/**
 * Labstep
 *
 * @module components/Folder/Form/Edit
 * @desc Folder Form Edit
 */

import { fieldName } from 'labstep-web/components/Folder/Form/fields';
import EditForm from 'labstep-web/core/Form/Edit';
import React from 'react';
import { FolderFormEditProps } from './types';

export const FolderFormEdit: React.FC<FolderFormEditProps> = ({
  folder,
  ...rest
}) => (
  <EditForm
    autoFocus
    entityName={folder.entityName}
    defaultValues={{ name: folder.name }}
    entity={folder}
    fields={[fieldName]}
    {...rest}
  />
);

export default FolderFormEdit;
