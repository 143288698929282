/**
 * Labstep
 *
 * @module grid/Report/components/Filter/Metadata/Form
 * @desc Filter Metadata form
 */

import ReusableFormGroup from 'labstep-web/core/Form/Reusable/Group';
import {
  MetadataType,
  MetadataTypeValues,
} from 'labstep-web/models/metadata/types';
import {
  getPostFilterMetadatasDate,
  getPostFilterMetadatasDefault,
  getPostFilterMetadatasNumeric,
  getPostFilterMetadatasOptions,
} from 'labstep-web/services/postFilter/filters/metadatas';
import { PostFilterMetadatas } from 'labstep-web/services/postFilter/metadatas';
import { PostFilterComparison } from 'labstep-web/services/query-parameter.service';
import React, { useCallback, useMemo, useState } from 'react';
import { fieldsGeneric, fieldsPerMetadataType } from './fields';
import {
  FilterMetadataType,
  IFilterMetadataFormProps,
} from './types';

const getPostFilterByPath = (path: string) => ({
  [MetadataType.default]: getPostFilterMetadatasDefault(path),
  [MetadataType.numeric]: getPostFilterMetadatasNumeric(path),
  [MetadataType.date]: getPostFilterMetadatasDate(path),
  [MetadataType.options]: getPostFilterMetadatasOptions(path),
});

export const PostFilterMetadatasByPathAndType: Record<
  string,
  Record<string, PostFilterMetadatas>
> = {
  metadatas: getPostFilterByPath('metadatas'),
  'protocolValues.resourceItem.resource.metadatas':
    getPostFilterByPath(
      'protocolValues.resourceItem.resource.metadatas',
    ),
  'protocolValues.resourceItem.metadatas': getPostFilterByPath(
    'protocolValues.resourceItem.metadatas',
  ),
};

const FilterMetadataForm: React.FC<IFilterMetadataFormProps> = ({
  addPostFilter,
  onDone,
  defaultValues: defaultValuesProps,
  path,
  replace,
}) => {
  const [metadataType, setMetadataType] =
    useState<FilterMetadataType>(MetadataType.default);

  const defaultValues = useMemo(
    () => ({
      type: {
        value: MetadataType.default,
        label: MetadataTypeValues[MetadataType.default],
      },
      ...defaultValuesProps,
    }),
    [],
  );

  const fields = useMemo(
    () => [
      ...fieldsGeneric,
      ...fieldsPerMetadataType(!!defaultValuesProps)[metadataType]
        .fields,
    ],
    [metadataType],
  );

  const onSubmit = useCallback(
    (values): void => {
      const { type, label, value, comparison } = values;
      const typeValue = type.value as
        | MetadataType.default
        | MetadataType.numeric
        | MetadataType.date
        | MetadataType.options;
      const filter =
        PostFilterMetadatasByPathAndType[path][typeValue];
      addPostFilter(
        filter.getNode([
          label,
          [value, comparison?.value || PostFilterComparison.eq],
        ]),
        true,
      );
      onDone();
    },
    [path, addPostFilter],
  );

  const onFormChange = useCallback((values): void => {
    setMetadataType(values.type.value);
  }, []);

  return (
    <ReusableFormGroup
      fields={fields}
      defaultValues={defaultValues}
      onSubmit={onSubmit}
      onFormChange={onFormChange}
      onDone={onDone}
      addAnother={false}
    />
  );
};

export default FilterMetadataForm;
