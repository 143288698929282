/**
 * Labstep
 *
 * @module components/EntityImport/Action/Create/Trigger
 * @desc EntityImport create wizard trigger
 */

import { ENTITY_IMPORT_CREATE_ROUTE_ID } from 'labstep-web/components/EntityImport/Action/Create';
import EntityImportActionImportExcel from 'labstep-web/components/EntityImport/Action/ImportExcel';
import { ActionComponent } from 'labstep-web/core/Action/Component';
import { useModalRoute } from 'labstep-web/hooks/modalRoute';
import React from 'react';
import styles from './styles.module.scss';
import { EntityImportActionCreateTriggerProps } from './types';

export const EntityImportActionCreateTrigger: React.FC<
  EntityImportActionCreateTriggerProps
> = ({
  entityName,
  setImportData,
  dropzoneProps,
  allowImportOnly,
}) => {
  const { toggleModal } = useModalRoute(
    ENTITY_IMPORT_CREATE_ROUTE_ID,
  );

  return (
    <div className={styles.actions}>
      <EntityImportActionImportExcel
        onImport={(data): void => {
          setImportData(data);
          toggleModal();
        }}
        targetEntityName={entityName}
        {...dropzoneProps}
      />
      {!allowImportOnly && (
        <ActionComponent
          onClick={() => toggleModal()}
          type="button"
          text="Add via Table"
        />
      )}
    </div>
  );
};

export default EntityImportActionCreateTrigger;
