/**
 * Labstep
 *
 * @module core/List/Cap
 * @desc Show users with access to ShareLink in a list
 */

import classnames from 'classnames';
import React from 'react';
import styles from './styles.module.scss';
import { CoreListCapProps, PlusCountProps } from './types';

export const PlusCount: React.FC<PlusCountProps> = ({
  circular,
  count,
  onClick,
}) =>
  count > 0 ? (
    <div
      onClick={onClick}
      className={classnames(styles.count, {
        [styles.circular]: circular,
        [styles.pointer]: !!onClick,
      })}
    >
      {`+${count}`}
    </div>
  ) : null;

export const ListCap: React.FC<CoreListCapProps> = ({
  items,
  maxDisplay = 3,
  total,
  circular,
}) => (
  <div className={styles.container}>
    {items
      .filter((item, index) => index < maxDisplay)
      .map((item, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <React.Fragment key={index}>{item}</React.Fragment>
      ))}
    {items.length > maxDisplay && (
      <PlusCount circular={circular} count={total - maxDisplay} />
    )}
  </div>
);

export default ListCap;
