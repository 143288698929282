/**
 * Labstep
 *
 * @module components/OrderRequest/Action/Create/Wizard/Steps/SetDetails
 * @desc OrderRequest create wizard set details
 */

import EntityPrimaryInfo from 'labstep-web/components/Entity/PrimaryInfo';
import MetadataManager from 'labstep-web/components/Metadata/Manager';
import MetadataTableExtraRows from 'labstep-web/components/Metadata/Table/ExtraRows';
import { getOrderRequestDetails } from 'labstep-web/components/OrderRequest/Details';
import Segment from 'labstep-web/core/Segment';
import { ReadOnMountHOC } from 'labstep-web/hoc/ReadOnMount';
import { useActiveGroup } from 'labstep-web/hooks/activeGroup';
import { OrderRequest } from 'labstep-web/models/order-request.model';
import React from 'react';
import styles from './styles.module.scss';
import { OrderRequestActionCreateWizardStepsSetDetailsProps } from './types';

export const OrderRequestActionCreateWizardStepsSetDetails: React.FC<
  OrderRequestActionCreateWizardStepsSetDetailsProps
> = ({ orderRequestId }) => {
  const { activeGroup } = useActiveGroup();
  if (!orderRequestId || !activeGroup) {
    return null;
  }

  return (
    <ReadOnMountHOC
      type="entities"
      entityName={OrderRequest.entityName}
      params={{
        get_single: 1,
        id: orderRequestId,
      }}
    >
      {({ entity: orderRequest }) => (
        <Segment className={styles.segment}>
          <EntityPrimaryInfo
            entity={orderRequest}
            withPermaLink={false}
            disabled
          />
          <div>
            <MetadataTableExtraRows
              rows={getOrderRequestDetails()}
              entity={orderRequest}
            />
            <MetadataManager entity={orderRequest} />
          </div>
        </Segment>
      )}
    </ReadOnMountHOC>
  );
};

export default OrderRequestActionCreateWizardStepsSetDetails;
