/**
 * Labstep
 *
 * @module screens/VerifyEmail/Center
 * @desc Sends request to backend to verify email
 */

import FullPageMessage from 'labstep-web/components/Layout/FullPageMessage';
import { MAILTO_SUPPORT_LABSTEP } from 'labstep-web/constants/email';
import { ERRORS } from 'labstep-web/constants/error';
import { withAuthenticatedUser } from 'labstep-web/containers/AuthenticatedUser';
import { withVerifyEmail } from 'labstep-web/containers/VerifyEmail';
import Alert from 'labstep-web/core/Alert';
import Button from 'labstep-web/core/Button';
import Redirect from 'labstep-web/core/Redirect';
import { intercomService } from 'labstep-web/services/intercom.service';
import React from 'react';

export const Contact = () => (
  <div>
    <a
      className="custom-intercom-launcher"
      href="#"
      onClick={intercomService.open}
    >
      <Button primary>Contact us</Button>
    </a>
    <a href={MAILTO_SUPPORT_LABSTEP}>
      <Button>Email</Button>
    </a>
  </div>
);

export const ErrorMessage = () => (
  <FullPageMessage
    src="/img/error/404.svg"
    title="Oops!"
    explanation="Failed to verify email. This could be because this email has already been verified. If the problem persists please contact us using the button below."
    action={<Contact />}
  />
);

export class VerifyEmail extends React.Component<any, any> {
  componentDidMount() {
    const {
      match: {
        params: { token },
      },
    } = this.props;

    this.props.confirmToken(token, { toast: true });
  }

  render() {
    const { status } = this.props;
    if (!status || status.isFetching) {
      return <Alert>Verifying the confirmation token...</Alert>;
    }
    if (status.error !== null) {
      if (
        status.error.data.error_code ===
        ERRORS.TOKEN_ALREADY_CONFIRMED
      ) {
        return <Redirect to="login" />;
      }
      return <ErrorMessage />;
    }
    return <Redirect to="login" />;
  }
}

export default withVerifyEmail(withAuthenticatedUser(VerifyEmail));
