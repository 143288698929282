/**
 * Labstep
 *
 * @module components/Metadata/Form/ShowEdit/Type
 * @desc Metadata field type metadata type
 */

import EntityActionUpdateDropdownPill from 'labstep-web/components/Entity/Action/Update/DropdownPill';
import { usePremiumFeatureStatus } from 'labstep-web/hooks/premium-feature';
import { METADATA_TYPES_DATA } from 'labstep-web/models/metadata/constants';
import React from 'react';
import { MetadataShowEditTypeProps } from './types';

export const MetadataShowEditType: React.FC<
  MetadataShowEditTypeProps
> = ({ metadata }) => {
  const metadataOptions = usePremiumFeatureStatus(
    METADATA_TYPES_DATA,
    'value',
    'metadata',
  );

  return (
    <EntityActionUpdateDropdownPill
      entity={metadata}
      items={metadataOptions.map((metadataOption) => ({
        label: metadataOption.label,
        body: {
          type: metadataOption.value,
        },
        premiumItem: metadataOption.premiumFeature && {
          premiumFeature: metadataOption.premiumFeature,
          enabled: metadataOption.premiumFeatureEnabled,
        },
      }))}
      currentValue={
        metadataOptions.find((type) => type.value === metadata.type)!
          .label
      }
    />
  );
};

export default MetadataShowEditType;
