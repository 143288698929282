/**
 * Labstep
 *
 * @module components/File/Viewer
 * @desc Renders file with appropriate component depending on
 * file extension + Actions to download and preview.
 */

import FileActionAnnotate from 'labstep-web/components/File/Action/Annotate';
import FileActionDownload from 'labstep-web/components/File/Action/Download';
import FileActionRemove from 'labstep-web/components/File/Action/Remove';
import FileImage from 'labstep-web/components/File/Image';
import FilePreview from 'labstep-web/components/File/Preview';
import FileSnapgene from 'labstep-web/components/File/Snapgene';
import FileTypeImage from 'labstep-web/components/File/TypeImage';
import Icon from 'labstep-web/core/Icon';
import React from 'react';
import styles from './styles.module.scss';
import { FileViewerProps } from './types';

const FileViewer: React.FC<FileViewerProps> = ({
  file,
  showActions = true,
  canEdit,
  createProps,
}) => {
  let component = null;
  switch (true) {
    case file.isImage && !file.isLinked:
      component = <FileImage file={file} />;
      break;
    case file.is_snapgene && !file.isLinked:
      component = <FileSnapgene file={file} />;
      break;
    default:
      break;
  }

  return (
    <div data-testid="file-viewer" className={styles.container}>
      {component !== null && (
        <div className={styles.imageContainer}>
          <div className={styles.image}>{component}</div>
        </div>
      )}
      <div className={styles.topContainer}>
        <div className={styles.infoContainer}>
          <div>
            <FileTypeImage file={file} size="mini" />
          </div>
          <div>
            <div>
              <b>{file.name}</b>
            </div>
            <div className="subtitle">
              {file.isLinked ? file.printLinkSource : file.filesize}
            </div>
          </div>
        </div>
        {showActions && (
          <div className={styles.actions}>
            {!file.isLinked && (
              <FileActionDownload unstyled file={file}>
                <Icon popup={{}} name="cloud download" />
              </FileActionDownload>
            )}
            <FileActionAnnotate file={file} />
            <FilePreview file={file} />
            {canEdit && (
              <FileActionRemove
                file={file}
                createProps={createProps}
              />
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default FileViewer;
