/**
 * Labstep
 *
 * @module grid/SmartTable/EmptyState
 * @desc Empty state for ProtocolCondition
 */

import React from 'react';
import styles from './styles.module.scss';
import { GridSmartTableEmptyStateProps } from './types';

export const GridSmartTableEmptyState: React.FC<
  GridSmartTableEmptyStateProps
> = ({ emptyState }) => {
  if (!emptyState) {
    return null;
  }
  return <div className={styles.emptyState}>{emptyState}</div>;
};
