/**
 * Labstep
 *
 * @module models/protocol
 * @desc Typescript export class for Protocol
 */

import { Type } from 'class-transformer';
import { Entity } from 'labstep-web/models/entity.model';
import { File as FileModel } from 'labstep-web/models/file.model';
import { Metadata } from 'labstep-web/models/metadata';
import { MetadataThread } from 'labstep-web/models/metadata-thread.model';
import { ProtocolCollection } from 'labstep-web/models/protocol-collection.model';
import { ProtocolDevice } from 'labstep-web/models/protocol-device.model';
import { ProtocolStep } from 'labstep-web/models/protocol-step.model';
import { ProtocolTable } from 'labstep-web/models/protocol-table.model';
import { ProtocolTimer } from 'labstep-web/models/protocol-timer.model';
import { ProtocolValue } from 'labstep-web/models/protocol-value.model';
import { Thread } from 'labstep-web/models/thread.model';
import { User } from 'labstep-web/models/user.model';
import { Decorations } from './experiment.model';
import { Molecule } from './molecule.model';
import { PermaLink } from './perma-link.model';
import { ProtocolConditionVariableType } from './protocol-condition.model';

export class Protocol extends Entity {
  public static readonly entityName = 'protocol';

  public get entityName(): typeof Protocol.entityName {
    return Protocol.entityName;
  }

  public constructor(data: Partial<Protocol> = {}) {
    super();
    Object.assign(this, data);
  }

  public id!: number;

  public name!: string;

  // Virtual prop
  public version!: number | false;

  public changes!: string;

  public metadata_count!: number;

  public protocol_step_count!: number;

  public protocol_timer_count!: number;

  public protocol_value_count!: number;

  public protocol_table_count!: number;

  public protocol_device_count!: number;

  public file_count!: number;

  public step_order!: number[];

  public is_last_version!: boolean;

  public experiment_count!: number;

  public all_experiment_count!: number;

  public user_experiment_count!: number;

  public state!: Record<string, unknown>;

  public is_draft!: boolean;

  public decorations!: Decorations;

  public protocol_condition_variables!: ProtocolConditionVariableType[];

  public n_protocol_conditions!: number;

  @Type(() => User)
  public author!: User;

  @Type(() => ProtocolCollection)
  public collection!: ProtocolCollection;

  @Type(() => MetadataThread)
  public metadata_thread!: MetadataThread;

  @Type(() => Thread)
  public thread!: Thread;

  @Type(() => ProtocolStep)
  public protocol_steps!: ProtocolStep[];

  @Type(() => ProtocolTable)
  public protocol_tables!: ProtocolTable[];

  @Type(() => ProtocolTimer)
  public protocol_timers!: ProtocolTimer[];

  @Type(() => ProtocolValue)
  public protocol_values!: ProtocolValue[];

  @Type(() => ProtocolDevice)
  public protocol_devices!: ProtocolDevice[];

  @Type(() => FileModel)
  public files!: FileModel[];

  @Type(() => PermaLink)
  public perma_link!: PermaLink;

  @Type(() => Molecule)
  public molecules!: Molecule[];

  @Type(() => ProtocolValue)
  public protocol_values_without_variable_template!: ProtocolValue[];

  @Type(() => Metadata)
  public metadatas_without_variable_template!: Metadata[];

  public get canResetTimer(): boolean {
    return !this.is_template;
  }

  public get is_template(): boolean {
    return Boolean(this);
  }

  public get canEditTimer(): boolean {
    return !this.is_template;
  }

  /**
   * Public get protocol steps from content state
   */
  public get stepsContentState(): any {
    if (this.state === null) {
      return [];
    }
    return (this.state.content as any).filter(
      (node: any) =>
        node.type === 'protocol_step' ||
        node.type === 'experiment_step',
    );
  }

  public get versionNumber(): number {
    if (this.version === false) {
      return -1;
    }
    return this.version + 1;
  }

  public get printVersion(): string {
    if (this.versionNumber === -1) {
      return 'Abandoned';
    }
    return `Version #${this.versionNumber}`;
  }

  public get nameAndVersion(): string {
    return `${this.name} (${this.printVersion})`;
  }

  public get protocolStepsOrdered(): ProtocolStep[] {
    return this.stepsContentState.map((node: any) =>
      this.protocol_steps.find(
        (protocolStep: ProtocolStep) =>
          protocolStep.guid === node.attrs.guid,
      ),
    );
  }

  public get values(): ProtocolValue[] {
    return this.protocol_values;
  }

  public get metadatas(): Metadata[] {
    return this.metadata_thread.metadatas;
  }

  public get hasEmptyState(): boolean {
    return this.state === null;
  }
}
