/**
 * Labstep
 */

import {
  ValueGetterParams,
  ValueSetterParams,
} from 'ag-grid-community';
import { ICONS } from 'labstep-web/constants/icons';
import DataGridCellEditorSearchSelect from 'labstep-web/core/DataGrid/CellEditor/SearchSelect';
import {
  suppressKeyboardEventClearCells,
  suppressKeyboardEventDisableEnterOnEdit,
} from 'labstep-web/core/DataGrid/CellEditor/utils';
import {
  CellEditorParams,
  ColDef,
} from 'labstep-web/core/DataGrid/types';
import {
  getEditable,
  setValue,
} from 'labstep-web/core/DataGrid/utils';
import colDefEntityLink from 'labstep-web/grid/Index/coldefs/Resource/entityLink';
import { ExperimentWorkflow } from 'labstep-web/models/experiment-workflow.model';
import { Folder } from 'labstep-web/models/folder.model';
import { Metadata } from 'labstep-web/models/metadata';
import { OrderRequest } from 'labstep-web/models/order-request.model';
import { ProtocolValue } from 'labstep-web/models/protocol-value.model';
import {
  PurchaseOrder,
  PurchaseOrderStatus,
} from 'labstep-web/models/purchase-order.model';
import { Resource } from 'labstep-web/models/resource.model';
import { objectOrFunction } from 'labstep-web/services/react.service';

const colDefEntityLinkPurchaseOrder = colDefEntityLink<OrderRequest>({
  getNestedEntity: (params) => params.data?.purchase_order,
  placeholder: 'Select',
});

export const colDefPurchaseOrder: ColDef<OrderRequest> = {
  ...colDefEntityLinkPurchaseOrder,
  colId: 'purchase_order_name',
  headerComponentParams: (params) => ({
    ...objectOrFunction(
      colDefEntityLinkPurchaseOrder.headerComponentParams,
      params,
    ),
    icon: ICONS.purchase_order.primary,
  }),
  headerName: PurchaseOrder.getHumanReadableEntityName(false, true),
  cellEditor: (
    params: CellEditorParams<
      | Resource
      | Folder
      | ExperimentWorkflow
      | ProtocolValue
      | Metadata
    >,
  ) => (
    <DataGridCellEditorSearchSelect
      {...params}
      props={{
        entity: params.data,
        entityName: PurchaseOrder.entityName,
        params: {
          status: PurchaseOrderStatus.open,
        },
        getBody: (value) => ({
          purchase_order_id: value?.id || null,
        }),
      }}
    />
  ),
  cellEditorPopup: true,
  suppressKeyboardEvent: (params) =>
    suppressKeyboardEventDisableEnterOnEdit(params) ||
    suppressKeyboardEventClearCells(params),
  editable: getEditable(),
  valueGetter: (params: ValueGetterParams) =>
    params.data?.purchase_order?.idAttr || '',
  valueSetter: (params: ValueSetterParams) =>
    setValue('purchase_order_id', params.newValue, {
      entityName: params.data?.entityName,
      id: params.data?.id,
    }),
};
