/**
 * Labstep
 *
 * @module components/Permission/Icon
 * @desc Displays a permission as icon with text
 */

import Icon from 'labstep-web/core/Icon';
import { IconProps } from 'labstep-web/core/Icon/types';
import Popup from 'labstep-web/core/Popup';
import {
  Permission,
  PermissionType,
} from 'labstep-web/models/permission.model';
import { capitalize } from 'labstep-web/services/i18n.service';
import React from 'react';
import styles from './styles.module.scss';
import { IPermissionIconProps } from './types';

export const PermissionIcon: React.FC<IPermissionIconProps> = ({
  type,
  withText,
}) => {
  let icon: IconProps['name'] = 'eye';
  switch (type) {
    case PermissionType.edit:
      icon = 'pencil';
      break;
    case PermissionType.owner:
      icon = 'user';
      break;
    default:
      break;
  }

  const trigger = <Icon name={icon} />;

  if (withText) {
    return (
      <div className={styles.container}>
        {trigger}
        <div className="text">{Permission.permissionsText[type]}</div>
      </div>
    );
  }

  return (
    <Popup
      trigger={trigger}
      content={`${capitalize(type)} permissions`}
    />
  );
};

export default PermissionIcon;
