/**
 * Labstep
 */

import { Column } from 'ag-grid-community';
import { GridIndexToolPanelColumnsSectionItemProps } from './Item/types';

// eslint-disable-next-line no-shadow
export enum SectionType {
  visible = 'visible',
  hidden = 'hidden',
}
export interface GridIndexToolPanelColumnsSectionProps {
  columns: Column[];
  section: SectionType;
  onToggleColumnVisible: GridIndexToolPanelColumnsSectionItemProps['onToggleColumnVisible'];
  onSortColumns: (columns: Column[]) => void;
}

export type ColumnWithId = Column & {
  getId: () => string;
};

export type ItemWithColumn = {
  id: string;
  column: ColumnWithId;
};
