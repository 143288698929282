/**
 * Labstep
 *
 * @module core/TextWithIcon
 * @desc Text with icon
 */

import classNames from 'classnames';
import Icon from 'labstep-web/core/Icon';
import React from 'react';
import styles from './styles.module.scss';
import { ITextWithIconProps } from './types';

export const TextWithIcon: React.FC<ITextWithIconProps> = ({
  className,
  text,
  icon,
  iconProps,
  ...rest
}) => (
  <span className={classNames(styles.container, className)} {...rest}>
    <Icon name={icon} {...iconProps} />
    {text}
  </span>
);

export default TextWithIcon;
