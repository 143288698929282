/**
 * Labstep
 *
 * @module components/Entity/Action/Clear
 * @desc Clears a field on an Entity
 */

import EntityActionUpdate from 'labstep-web/components/Entity/Action/Update';
import React from 'react';
import { EntityActionClearProps } from './types';

export const EntityActionClear: React.FC<EntityActionClearProps> = ({
  entityName,
  id,
  field,
}) => (
  <EntityActionUpdate
    entityName={entityName}
    id={id}
    body={{ [field]: null }}
    optimistic
    actionComponentProps={{
      type: 'icon',
      icon: 'close',
    }}
  />
);

export default EntityActionClear;
