/**
 * Labstep
 */

import colDefResourceItem, {
  COL_ID_RESOURCE_ITEM,
} from 'labstep-web/grid/Index/coldefs/ProtocolValue';
import { ProtocolCondition } from 'labstep-web/models/protocol-condition.model';
import { getColId, getNestedEntity } from '../../utils';
import { ColDefProtocolValueResourceItemType } from './types';

/**
 * Get colDefs based on template
 * @param variable ProtocolCondition variable
 * @returns ColGroupDef
 */
const colDefProtocolValueResourceItem: ColDefProtocolValueResourceItemType<
  ProtocolCondition
> = (variable) =>
  colDefResourceItem({
    getNestedEntity: (params) =>
      getNestedEntity(params, 'protocol_values', variable) ||
      undefined,
    columnProps: {
      colId: getColId(COL_ID_RESOURCE_ITEM, variable.guid),
      suppressFillHandle: !variable.is_input,
    },
    isInput: variable.is_input,
  });

export default colDefProtocolValueResourceItem;
