/**
 * Labstep
 *
 * @module components/Experiment/Form/ShowEdit/State
 * @desc State showedit field
 */

import ProtocolFormShowEditStateContainerEdit from 'labstep-web/components/Protocol/Form/ShowEdit/State/Container/Edit';
import { EntityUpdateContainer } from 'labstep-web/containers/Entity/Update';
import ProseMirrorDebouncingContainer from 'labstep-web/containers/ProseMirror/Debouncing';
import Mobile from 'labstep-web/core/MediaQuery/Mobile';
import { Experiment } from 'labstep-web/models/experiment.model';
import React from 'react';
import ExperimentFormShowEditState from '..';
import { ExperimentFormShowEditStateContainerProps } from './types';

export const ExperimentFormShowEditStateContainer: React.FC<
  ExperimentFormShowEditStateContainerProps
> = ({ experiment, ...rest }) => (
  <EntityUpdateContainer
    entityName={Experiment.entityName}
    id={experiment.id}
    optimistic
  >
    {({ update, status }) => (
      <Mobile>
        {({ match }) => (
          <ProseMirrorDebouncingContainer
            entity={experiment}
            status={status}
            update={update}
          >
            {(debounceContainerProps) => (
              <ProtocolFormShowEditStateContainerEdit
                entity={experiment}
              >
                {(editContainerProps) => (
                  <ExperimentFormShowEditState
                    experiment={experiment}
                    match={match}
                    {...debounceContainerProps}
                    {...editContainerProps}
                    {...rest}
                  />
                )}
              </ProtocolFormShowEditStateContainerEdit>
            )}
          </ProseMirrorDebouncingContainer>
        )}
      </Mobile>
    )}
  </EntityUpdateContainer>
);

export default ExperimentFormShowEditStateContainer;
