/**
 * Labstep
 *
 * @module components/ResourceItemExport/Table/Column/Header
 * @desc Header for resource items export table
 */

import Icon from 'labstep-web/core/Icon';
import React from 'react';
import styles from './styles.module.scss';
import { IResourceItemExportTableColumnHeaderProps } from './types';

export const ResourceItemExportTableHeader: React.FC<
  IResourceItemExportTableColumnHeaderProps
> = ({ icon, label, action }) => {
  return (
    <div className={styles.container}>
      <div>
        <Icon name={icon} /> {label}
      </div>
      <div>{action}</div>
    </div>
  );
};

export default ResourceItemExportTableHeader;
