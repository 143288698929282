/**
 * Labstep
 *
 * @module core/Action/Icon
 * @desc Icon component that prompts an action
 */

import classnames from 'classnames';
import Icon from 'labstep-web/core/Icon';
import React from 'react';
import styles from './styles.module.scss';
import { IActionIconProps } from './types';

const ActionIcon: React.FC<IActionIconProps> = ({
  noBackground,
  noDiv,
  onClick,
  asLink,
  'data-testid': dataTestId,
  ...rest
}) =>
  noDiv ? (
    <Icon onClick={onClick} link {...rest} />
  ) : (
    <div
      className={classnames(styles.iconContainer, {
        [styles.noBackground]: noBackground || asLink,
        [styles.link]: asLink,
      })}
      data-testid={dataTestId}
      onClick={onClick}
    >
      <Icon link {...rest} />
    </div>
  );

export default ActionIcon;
