/**
 * Labstep
 *
 * @module components/Tag/Action/Create
 * @desc Create an tag
 */

import { CanCreate } from 'labstep-web/components/Entity/Can';
import TagFormCreate from 'labstep-web/components/Tag/Form/Create';
import ActionComponent from 'labstep-web/core/Action/Component';
import Modal from 'labstep-web/core/Modal/Default';
import React from 'react';
import { ITagActionCreateProps } from './types';

export const TagActionCreate: React.FC<ITagActionCreateProps> = ({
  defaultType,
  actionComponentProps,
}) => (
  <CanCreate entityName="tag">
    <Modal
      header="New Tag"
      viewComponent={({ toggleModal }) => (
        <ActionComponent
          text="New Tag"
          onClick={toggleModal}
          type="button"
          {...actionComponentProps}
        />
      )}
      content={({ toggleModal }) => (
        <TagFormCreate
          defaultType={defaultType}
          options={{ onSuccess: toggleModal }}
        />
      )}
    />
  </CanCreate>
);

export default TagActionCreate;
