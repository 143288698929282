/**
 * Labstep
 */

import React, { useEffect, useState } from 'react';
import {
  DataGridColumnHeaderMergeAsTypes,
  DataGridColumnHeaderProps,
  DataGridColumnHeaderStyleStateType,
} from './types';

export const useDoubleHeaderStyle = (
  ref: React.MutableRefObject<HTMLDivElement | null>,
  mergeAs: DataGridColumnHeaderProps['mergeAs'],
): DataGridColumnHeaderStyleStateType => {
  const [style, setStyle] =
    useState<DataGridColumnHeaderStyleStateType>({
      marginTop: 0,
      marginBottom: 0,
    });

  // hack for merging a parent header and a child header
  // both should pass the exact same headerComponentParams
  useEffect(() => {
    if (ref?.current?.parentElement) {
      const parentHeight = ref.current.parentElement.offsetHeight;
      if (mergeAs === DataGridColumnHeaderMergeAsTypes.parent) {
        if (style.marginTop !== parentHeight) {
          setStyle({ ...style, marginTop: parentHeight });
        }
      } else if (mergeAs === DataGridColumnHeaderMergeAsTypes.child) {
        if (style.marginBottom !== parentHeight) {
          setStyle({ ...style, marginBottom: parentHeight });
        }
      }
    }
  }, [ref]);

  return style;
};
