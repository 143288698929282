/**
 * Labstep
 *
 * @module models/log
 * @desc Typescript export class for Log
 */

import { Type } from 'class-transformer';
import { Entity } from 'labstep-web/models/entity.model';
import { User } from 'labstep-web/models/user.model';

export class Log extends Entity {
  static readonly entityName = 'log';

  get entityName(): string {
    return Log.entityName;
  }

  constructor(data: Partial<Log> = {}) {
    super();
    Object.assign(this, data);
  }

  id!: number;

  created_at!: string;

  ended_at!: string;

  message!: string;

  log_entry_object_class!: string;

  log_entry_object_id!: number;

  log_entry_client_user_agent!: string;

  log_entry_client_uuid!: string;

  @Type(() => User)
  author!: User;
}
