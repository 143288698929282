/**
 * Labstep
 *
 * @module components/EntityView/Form/Create/ListItem
 * @desc List item
 */

import FilterItem from 'labstep-web/components/Filter/Item';
import { Count } from 'labstep-web/core/Text/WithCount';
import React from 'react';
import { EntityViewFormCreateListItemProps } from './types';

export const EntityViewFormCreateListItem: React.FC<
  EntityViewFormCreateListItemProps
> = ({ icon, text, onClick, count }) => (
  <FilterItem
    icon={icon}
    text={text}
    onClick={onClick}
    active={false}
    actionShortcuts={<Count count={count} />}
  />
);

export default EntityViewFormCreateListItem;
