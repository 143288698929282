/**
 * Labstep
 *
 * @module core/Select/Search/Modal/EmptyState
 * @desc Search select modal empty state component
 */

import EmptyState from 'labstep-web/core/Card/EmptyState';
import { getHumanReadableEntityName } from 'labstep-web/services/i18n.service';
import { createEntity } from 'labstep-web/state/actions/entity';
import store from 'labstep-web/state/store';
import React, { useEffect } from 'react';
import { ISearchSelectModalEmptyStateSearchProps } from './types';

export const SearchSelectModalEmptyStateSearch: React.FC<
  ISearchSelectModalEmptyStateSearchProps
> = ({ entityName, creatable, body, options, name }) => {
  const handleKeyDown = (e: KeyboardEvent) => {
    if (e.key === 'Enter' && name?.length && creatable) {
      store.dispatch(
        createEntity(
          entityName,
          body,
          undefined,
          undefined,
          undefined,
          options as any,
        ),
      );
    }
  };

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown, true);

    return () => {
      window.removeEventListener('keydown', handleKeyDown, true);
    };
  });

  return (
    <EmptyState
      src="/img/empty-state/protocols.svg"
      title="No results found"
      explanation={
        name?.length
          ? creatable &&
            `Refine your search or hit Enter to create a new ${getHumanReadableEntityName(
              entityName,
            )} called "${name}"`
          : `We can't find any ${getHumanReadableEntityName(
              entityName,
              true,
            )} matching your criteria`
      }
    />
  );
};

export default SearchSelectModalEmptyStateSearch;
