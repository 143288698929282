/**
 * Labstep
 *
 * @module components/Chemical/Action/Delete
 * @desc Action to delete
 */

import EntityActionDelete from 'labstep-web/components/Entity/Action/Delete';
import React from 'react';
import { ChemicalActionDeleteProps } from './types';

export const ChemicalActionDelete: React.FC<
  ChemicalActionDeleteProps
> = ({ chemical, ...rest }) => (
  <EntityActionDelete {...rest} entity={chemical} isRemoveAction />
);

export default ChemicalActionDelete;
