/**
 * Labstep
 *
 * @module state/actions/file
 * @desc Redux actions for File
 */

import { APICallOptions } from 'labstep-web/models';
import * as sfApi from 'labstep-web/services/sf-api.service';
import { computeCreateKey } from 'labstep-web/state/actions/entity';
import pick from 'lodash/pick';

export interface IUploadFileParams {
  file?: any;
  entityType?: string;
  entityId?: number;
  additionalBody?: Record<string, unknown>;
  orphanUuid?: string;
  name?: string;
  hideProgress?: boolean;
  /** Specific field for that entity (e.g. 'image' for resource) */
  field?: string;
}

/**
 * Download a file
 *
 * @function
 * @param  {string} id - Id of the file to download
 * @param  {func} onSuccess - onSuccess callback
 */
export const downloadFile = ({ id, onSuccess }: any) =>
  sfApi.post({
    type: 'FILE_DOWNLOAD',
    route: {
      custom: 'app_api_file_downloadfile',
    },
    meta: {
      action_type: 'download_file',
      customReducer: 1,
    },
    params: { id },
    onSuccess,
  });

/**
 * Upload a File
 *
 * @function
 * @param  {object}   options.file - File to be uploaded (used on web)
 * @param  {string}   options.entityType - Parent entity type
 * @param  {integer}  options.entityId - Parent entity id
 * @param  {string}   options.orphanUuid - uuid to be used if entityType, entityId
 *                    are not passed (Orphan file)
 * @param  {string}   options.name - File name
 * @param  {string}   options.type - File type (used on mobile)
 * @param  {string}   options.uri - File uri (used on mobile)
 * @param  {object}   options - Additional options (onSuccess, onFail, ...)
 */
export const uploadFile = (
  {
    file,
    entityType,
    entityId,
    field,
    orphanUuid,
    hideProgress,
    additionalBody = {},
  }: IUploadFileParams,
  options?: APICallOptions,
) => {
  const body: FormData = new FormData();
  if (entityType && entityId) {
    const fieldPrepend = field ? `${field}_` : '';
    body.append(
      `${entityType}_${fieldPrepend}id`,
      entityId.toString(),
    );
  }

  body.append('file', file);

  Object.keys(additionalBody).forEach((key) => {
    body.append(key, (additionalBody as any)[key]);
  });

  const key = computeCreateKey(
    entityType as any,
    entityId as any,
    orphanUuid as any,
  );

  return sfApi.post({
    type: 'CREATE_FILE',
    route: {
      custom: 'oneup_uploader',
    },
    body,
    meta: {
      entityName: 'file',
      uuid: key,
      body,
      parentName: entityType,
      parentId: entityId,
      upload: {
        key,
        file: pick(file, ['name', 'preview', 'size', 'type']),
        entityType,
        entityId,
        orphanUuid,
        hideProgress,
      },
      normalize: 'files',
      ...additionalBody,
    },
    ...options,
  });
};

export const convertUrlToFile = (
  urls: string[],
  additional?: {
    parentName: string;
    parentId: number;
  },
  options?: APICallOptions,
) => {
  let parentName;
  let parentId;
  if (additional) {
    parentName = additional.parentName;
    parentId = additional.parentId;
  }

  const body: any = {
    urls,
  };

  if (parentName) {
    body[`${parentName}_id`] = parentId;
  }

  return sfApi.post({
    type: 'CONVERT_URL',
    route: {
      custom: 'app_api_file_converturl',
    },
    body,
    meta: {
      action_type: 'convert_url',
      customReducer: 1,
      entityName: 'file',
      parentName,
      parentId,
      body,
      normalize: 'files',
    },
    ...options,
  });
};
