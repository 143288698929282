/**
 * Labstep
 *
 * @module components/AccessKey/Table
 * @desc Table listing all access keys
 */

import AccessKeyActionMenu from 'labstep-web/components/AccessKey/Action/Menu';
import NameCell from 'labstep-web/components/AccessKey/Form/ShowEdit/Name';
import { ICONS } from 'labstep-web/constants/icons';
import ActionCopy from 'labstep-web/core/Action/Copy';
import Icon from 'labstep-web/core/Icon';
import TableSimple from 'labstep-web/core/Table/Simple';
import { ReadOnMountHOC } from 'labstep-web/hoc/ReadOnMount';
import { AccessKey } from 'labstep-web/models/access-key.model';
import { dateOnly } from 'labstep-web/services/date.service';
import React from 'react';
import { AccessKeyTableProps } from './types';

export const AccessKeyTable: React.FC<AccessKeyTableProps> = ({
  accessKeys,
}) => {
  if (accessKeys.length === 0) {
    return null;
  }
  const columns = [
    {
      header: '',
      content: (): React.ReactNode => (
        <Icon name={ICONS.access_key.primary} />
      ),
      cellProps: { width: 1 },
    },
    {
      header: 'Name',
      content: (accessKey: AccessKey): React.ReactNode => (
        <NameCell accessKeyItem={accessKey} />
      ),
      cellProps: { width: 3 },
    },
    {
      header: 'Key',
      content: (accessKey: AccessKey): React.ReactNode => (
        <ActionCopy
          text={accessKey.uuid}
          actionComponentProps={{
            type: 'text',
            text: accessKey.uuid,
          }}
        />
      ),
      cellProps: { width: 3 },
    },
    {
      header: 'Expiry Date',
      content: (accessKey: AccessKey): React.ReactNode =>
        accessKey.expires_at
          ? dateOnly(accessKey.expires_at)
          : 'No Expiry Date',
      cellProps: { width: 3 },
    },
    {
      header: '',
      content: (accessKey: AccessKey): React.ReactNode => (
        <AccessKeyActionMenu
          accessKeyItem={accessKey}
          actions={['delete']}
        />
      ),
      cellProps: { width: 1, textAlign: 'right' },
    },
  ];
  const nonDeletedAccessKeys: AccessKey[] = accessKeys.filter(
    (accessKey: AccessKey): React.ReactNode => !accessKey.deleted_at,
  );
  return (
    <TableSimple rows={nonDeletedAccessKeys} columns={columns} />
  );
};

export const AccessKeyTableContainer: React.FC = () => (
  <ReadOnMountHOC
    type="cursor"
    entityName={AccessKey.entityName}
    params={{}}
    children={({ entities: accessKeys }) => (
      <AccessKeyTable accessKeys={accessKeys} />
    )}
  />
);

export default AccessKeyTableContainer;
