/**
 * Labstep
 *
 * @module screens/Metadata/Show
 * @desc Metadata show
 */

import MetadataDetail from 'labstep-web/components/Metadata/Detail';
import MetadataFormShowEditLabel from 'labstep-web/components/Metadata/Form/ShowEdit/Label';
import { EntityReadEntityContainer } from 'labstep-web/containers/Entity/Read/Entity';
import Flex from 'labstep-web/core/Flex';
import { ReadOnMountHOC } from 'labstep-web/hoc/ReadOnMount';
import { Metadata } from 'labstep-web/models/metadata';
import React from 'react';
import { MetadataShowNameProps, MetadataShowProps } from './types';

export const MetadataShowName: React.FC<MetadataShowNameProps> = ({
  id,
}) => (
  <EntityReadEntityContainer entityName={Metadata.entityName} id={id}>
    {({ entity }) =>
      entity ? <MetadataFormShowEditLabel metadata={entity} /> : null
    }
  </EntityReadEntityContainer>
);

const MetadataShow: React.FC<MetadataShowProps> = ({
  id,
  parentEntityName,
}) => (
  <Flex
    id="metadata-show-screen"
    style={{ justifyContent: 'space-between', height: '100%' }}
    column
  >
    <ReadOnMountHOC
      type="entities"
      loading={{ loader: 'placeholder', cached: true }}
      entityName={Metadata.entityName}
      params={{ get_single: 1, id }}
      children={({ entity: metadata }) => (
        // parent is temp fix until permissions
        <MetadataDetail
          metadata={metadata}
          entity={metadata.metadata_thread[parentEntityName]}
        />
      )}
    />
  </Flex>
);

export default MetadataShow;
