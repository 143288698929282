/**
 * Labstep
 *
 * @module components/Metadata/Form/ShowEdit/Unit
 * @desc Edit metadata unit
 */

import ActionComponent from 'labstep-web/core/Action/Component';
import FormShowEditText from 'labstep-web/core/Form/ShowEdit/Text';
import React from 'react';
import { fieldUnit } from '../../fields';
import { MetadataFormShowEditUnitProps } from './types';

const MetadataFormShowEditUnit: React.FC<
  MetadataFormShowEditUnitProps
> = ({ metadata, createProps }) =>
  metadata.type === 'numeric' ? (
    <FormShowEditText
      createProps={createProps}
      entity={metadata}
      entityName={metadata.entityName}
      field={fieldUnit}
      hideValueIfCreateProps={false}
      customPlaceholder={({ toggle }): React.ReactElement => (
        <ActionComponent
          type="icon"
          icon="subscript"
          onClick={toggle}
          elementProps={{ popup: { content: 'Add unit(s)' } }}
        />
      )}
    >
      {metadata.unit}
    </FormShowEditText>
  ) : null;

export default MetadataFormShowEditUnit;
