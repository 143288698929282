/**
 * Labstep
 *
 * @module components/PurchaseOrder/Action/View
 * @desc Button linking to purchaseOrder show
 */

import EntityActionView from 'labstep-web/components/Entity/Action/View';
import { PurchaseOrder } from 'labstep-web/models/purchase-order.model';
import React from 'react';
import { IPurchaseOrderActionViewProps } from './types';

const PurchaseOrderActionView: React.FC<
  IPurchaseOrderActionViewProps
> = ({ purchaseOrder }) => (
  <EntityActionView
    entityName={PurchaseOrder.entityName}
    params={{ id: purchaseOrder.id }}
  />
);

export default PurchaseOrderActionView;
