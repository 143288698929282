/**
 * Labstep
 */

import Flex from 'labstep-web/core/Flex';
import Image from 'labstep-web/core/Image';
import React, { Fragment } from 'react';
import ModalWizardHeaderStep from './Step';
import { IModalWizardHeaderStepsProps } from './types';

export const ModalWizardHeaderSteps: React.FC<
  IModalWizardHeaderStepsProps
> = ({ steps, activeStepIndex }) => (
  <Flex hAlignContent="center">
    {steps.map((step, index) => (
      <Fragment key={step}>
        <ModalWizardHeaderStep
          step={step}
          number={index + 1}
          active={activeStepIndex === index}
          complete={activeStepIndex > index}
        />
        {index < steps.length - 1 && (
          <Image src="/img/icon/chevron.svg" />
        )}
      </Fragment>
    ))}
  </Flex>
);

export default ModalWizardHeaderSteps;
