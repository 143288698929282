/**
 * Labstep
 *
 * @module constants/plans
 * @desc Settings for organization plans
 */

import React from 'react';
import { SemanticICONS } from 'semantic-ui-react';

const iconUsers = 'user plus' as const;
const iconWorkspaces = 'sitemap' as const;
const iconUpload = 'cloud upload' as const;
const iconCheck = 'check' as const;
const iconPlus = 'plus' as const;

export interface PlanFeature {
  id: string;
  text: React.ReactNode;
  icon: SemanticICONS;
}
interface Plan {
  name: string;
  logoSrc: string;
  description: string;
  users: string;
  features: PlanFeature[];
  optionalFeatures?: PlanFeature[];
  priceColor: string;
  hidePricing?: boolean;
}

export interface Plans {
  [key: string]: Plan;
}

// eslint-disable-next-line no-shadow
export enum PlanTier {
  StartUp = 'startup',
  Basic = 'basic',
  Pro = 'pro',
  Enterprise = 'enterprise',
  Ultimate = 'ultimate',
  AcademicFree = 'academic_free',
  AcademicPlus = 'academic_plus',
  AcademicInstitute = 'academic_institute',
}

export const plans: Plans = {
  [PlanTier.StartUp]: {
    name: 'Startup',
    logoSrc: '/img/logo/tier/logo-basic.svg',
    description:
      'Great for small teams. Set your company up for success from day one.',
    users: '1-10',
    features: [
      {
        id: '1',
        text: (
          <>
            Up to <b>10 Users</b>
          </>
        ),
        icon: iconUsers,
      },

      {
        id: '2',
        text: (
          <>
            Up to <b>3 Workspaces</b>
          </>
        ),
        icon: iconWorkspaces,
      },
      {
        id: '3',
        text: (
          <>
            <b>Unlimited</b> File Uploads
          </>
        ),
        icon: iconUpload,
      },
      {
        id: '4',
        text: 'Notebook, Inventory and Order Management',
        icon: iconCheck,
      },
      {
        id: '5',
        text: 'Live Chat Support & Help Centre',
        icon: iconCheck,
      },
    ],
    priceColor: 'blue',
  },
  [PlanTier.Basic]: {
    name: 'Basic',
    logoSrc: '/img/logo/tier/logo-basic.svg',
    description:
      'Great for small teams. Set your company up for success from day one.',
    users: '1-10',
    features: [
      {
        id: '1',
        text: (
          <>
            Up to <b>5 Users</b>
          </>
        ),
        icon: iconUsers,
      },
      {
        id: '2',
        text: (
          <>
            <b>Unlimited</b> File Uploads
          </>
        ),
        icon: iconUpload,
      },
      {
        id: '3',
        text: 'Full-featured Notebook',
        icon: iconCheck,
      },
      {
        id: '4',
        text: 'Integrated Spreadsheets',
        icon: iconCheck,
      },
      {
        id: '5',
        text: 'Version Controlled Protocol Library',
        icon: iconCheck,
      },
      {
        id: '6',
        text: 'Interactive Protocol Runner',
        icon: iconCheck,
      },
      {
        id: '7',
        text: 'Digital Signatures',
        icon: iconCheck,
      },
      {
        id: '8',
        text: 'Full Audit Trail',
        icon: iconCheck,
      },
      {
        id: '9',
        text: 'In-app Support',
        icon: iconCheck,
      },
    ],
    priceColor: 'blue',
  },
  [PlanTier.Pro]: {
    name: 'Professional',
    logoSrc: '/img/logo/tier/logo-pro-ultimate.svg',
    description: 'Fully featured solution customised to your needs.',
    users: '11-50',
    features: [
      {
        id: '1',
        text: (
          <>
            <b>More than</b> 5 Users
          </>
        ),
        icon: iconUsers,
      },
      {
        id: '2',
        text: '2FA, IP Whitelisting and SSO',
        icon: iconCheck,
      },
      {
        id: '3',
        text: 'Custom Document Workflows',
        icon: iconCheck,
      },
      {
        id: '4',
        text: 'Custom Roles & Permissions',
        icon: iconCheck,
      },
      {
        id: '5',
        text: 'Tailored Onboarding & Setup',
        icon: iconCheck,
      },
      {
        id: '6',
        text: 'Dedicated Customer Success Manager',
        icon: iconCheck,
      },
      {
        id: '7',
        text: 'API and SDK Support',
        icon: iconCheck,
      },
    ],
    optionalFeatures: [
      {
        id: '1',
        text: 'Inventory and Sample Management',
        icon: iconPlus,
      },
      {
        id: '2',
        text: 'Device Management',
        icon: iconPlus,
      },
      {
        id: '3',
        text: 'Chemistry / Molecular Biology Tools',
        icon: iconPlus,
      },
      {
        id: '4',
        text: 'Code Capsules and Automations',
        icon: iconPlus,
      },
    ],
    priceColor: 'blue',
    hidePricing: true,
  },
  [PlanTier.Enterprise]: {
    name: 'Enterprise',
    logoSrc: '/img/logo/tier/logo-enterprise.svg',
    description:
      'A mature and robust solution for your entire organization. Customized to your needs.',
    users: '50+',
    features: [
      {
        id: '1',
        text: (
          <>
            <b>Unlimited</b> Users
          </>
        ),
        icon: iconUsers,
      },

      {
        id: '2',
        text: (
          <>
            <b>Unlimited</b> Workspaces
          </>
        ),
        icon: iconWorkspaces,
      },
      {
        id: '3',
        text: (
          <>
            <b>Unlimited</b> File Uploads
          </>
        ),
        icon: iconUpload,
      },
      {
        id: '4',
        text: 'Everything in Pro Plan',
        icon: iconCheck,
      },
      {
        id: '5',
        text: 'Custom Reporting Dashboard',
        icon: iconCheck,
      },
      {
        id: '6',
        text: 'Security Access Log',
        icon: iconCheck,
      },
      {
        id: '7',
        text: 'IP Whitelisting',
        icon: iconCheck,
      },
      {
        id: '8',
        text: 'SAML Single Sign On (SSO)',
        icon: iconCheck,
      },
      {
        id: '9',
        text: 'Dedicated Account Manager',
        icon: iconCheck,
      },
    ],
    priceColor: 'green',
  },
  [PlanTier.Ultimate]: {
    name: 'Ultimate',
    logoSrc: '/img/logo/tier/logo-enterprise.svg',
    description:
      'A mature and robust solution for your entire organization. Customized to your needs.',
    users: '50+',
    features: [
      {
        id: '1',
        text: (
          <>
            <b>Unlimited</b> Users
          </>
        ),
        icon: iconUsers,
      },

      {
        id: '2',
        text: (
          <>
            <b>Unlimited</b> Workspaces
          </>
        ),
        icon: iconWorkspaces,
      },
      {
        id: '3',
        text: (
          <>
            <b>Unlimited</b> File Uploads
          </>
        ),
        icon: iconUpload,
      },
      {
        id: '4',
        text: 'Everything in Pro Plan',
        icon: iconCheck,
      },
      {
        id: '5',
        text: 'Custom Reporting Dashboard',
        icon: iconCheck,
      },
      {
        id: '6',
        text: 'Security Access Log',
        icon: iconCheck,
      },
      {
        id: '7',
        text: 'IP Whitelisting',
        icon: iconCheck,
      },
      {
        id: '8',
        text: 'SAML Single Sign On (SSO)',
        icon: iconCheck,
      },
      {
        id: '9',
        text: 'Dedicated Account Manager',
        icon: iconCheck,
      },
    ],
    priceColor: 'green',
  },

  // Academic

  [PlanTier.AcademicFree]: {
    name: 'Academic Free',
    logoSrc: '/img/logo/tier/logo-academic-free.svg',
    description:
      'For small academic labs and individuals looking to document their work.',
    users: '5',
    features: [
      {
        id: '1',
        text: (
          <>
            Up to <b>5 users</b>
          </>
        ),
        icon: iconUsers,
      },

      {
        id: '2',
        text: (
          <>
            Up to <b>5 Workspaces</b>
          </>
        ),
        icon: iconWorkspaces,
      },
      {
        id: '3',
        text: (
          <>
            <b>Unlimited</b> File Uploads
          </>
        ),
        icon: iconUpload,
      },
      {
        id: '4',
        text: 'Notebook, Inventory and Order Management Modules',
        icon: iconCheck,
      },
      {
        id: '5',
        text: 'Live Chat Support & Help Centre',
        icon: iconCheck,
      },
    ],
    priceColor: 'green',
  },
  [PlanTier.AcademicPlus]: {
    name: 'Academic Plus',
    logoSrc: '/img/logo/tier/logo-academic-plus.svg',
    description:
      'For larger academic labs looking for more advanced features.',
    users: '10',
    features: [
      {
        id: '1',
        text: (
          <>
            Up to <b>50 users</b>
          </>
        ),
        icon: iconUsers,
      },

      {
        id: '2',
        text: (
          <>
            <b>Unlimited</b> Workspaces
          </>
        ),
        icon: iconWorkspaces,
      },
      {
        id: '3',
        text: (
          <>
            <b>Unlimited</b> File Uploads
          </>
        ),
        icon: iconUpload,
      },
      {
        id: '4',
        text: 'Everything in Academic Free',
        icon: iconCheck,
      },
      {
        id: '5',
        text: 'Custom Roles & Permissions',
        icon: iconCheck,
      },
      {
        id: '6',
        text: 'Full Document Revision History',
        icon: iconCheck,
      },
      {
        id: '7',
        text: 'Two Factor Authentication',
        icon: iconCheck,
      },
    ],
    priceColor: 'green',
  },
  [PlanTier.AcademicInstitute]: {
    name: 'Academic Institute',
    logoSrc: '/img/logo/tier/logo-academic-institute.svg',
    description:
      'For Universities and Research Institutes looking for a site wide deployment.',
    users: '10',
    features: [
      {
        id: '1',
        text: (
          <>
            <b>Unlimited</b> Users
          </>
        ),
        icon: iconUsers,
      },

      {
        id: '2',
        text: (
          <>
            <b>Unlimited</b> Workspaces
          </>
        ),
        icon: iconWorkspaces,
      },
      {
        id: '3',
        text: (
          <>
            <b>Unlimited</b> File Uploads
          </>
        ),
        icon: iconUpload,
      },
      {
        id: '4',
        text: 'Everything in Academic Plus',
        icon: iconCheck,
      },
      {
        id: '5',
        text: 'Custom Reporting Dashboard',
        icon: iconCheck,
      },
      {
        id: '6',
        text: 'Security Access Log',
        icon: iconCheck,
      },
      {
        id: '7',
        text: 'IP Whitelisting',
        icon: iconCheck,
      },
      {
        id: '8',
        text: 'SAML Single Sign On (SSO)',
        icon: iconCheck,
      },
      {
        id: '9',
        text: 'Dedicated Success Manager',
        icon: iconCheck,
      },
    ],
    priceColor: 'green',
  },
};
