/**
 * Labstep
 *
 * @module components/Protocol/Action/AddVariable/Group
 * @desc Add Variable action group for Protocol
 */

import ProtocolConditionActionToggleIsVariable from 'labstep-web/components/ProtocolCondition/Action/ToggleIsVariable';
import { useProtocolConditionModalContext } from 'labstep-web/components/ProtocolCondition/Modal/context';
import TableSimple from 'labstep-web/core/Table/Simple';
import TextWithHelp from 'labstep-web/core/Text/WithHelp';
import { Metadata } from 'labstep-web/models/metadata';
import { ProtocolValue } from 'labstep-web/models/protocol-value.model';
import { getIdAttribute } from 'labstep-web/services/schema/helpers';
import React from 'react';
import { IProtocolActionAddVariableGroupTableProps } from './types';

export const ProtocolActionAddVariableGroupTable: React.FC<
  IProtocolActionAddVariableGroupTableProps
> = ({ entities, entityName, protocol, name, icon, isInput }) => {
  const { setActiveVariable } = useProtocolConditionModalContext();

  return (
    <TableSimple
      basic="very"
      rows={entities}
      columns={[
        {
          header: name,
          content: (
            entity: Metadata | ProtocolValue,
          ): React.ReactElement => (
            <div
              style={{ cursor: 'pointer' }}
              onClick={(): void =>
                setActiveVariable({
                  field_type: entityName,
                  [getIdAttribute(entityName)]:
                    entity[
                      getIdAttribute(
                        entityName,
                      ) as keyof typeof entity
                    ],
                  name: entity.displayName,
                  is_input: isInput,
                })
              }
            >
              <TextWithHelp
                text={entity.displayName}
                icon={icon}
                header
              />
            </div>
          ),
        },
        {
          header: 'Variable?',
          content: (
            entity: Metadata | ProtocolValue,
          ): React.ReactElement => (
            <ProtocolConditionActionToggleIsVariable
              entity={entity}
              protocol={protocol}
              toggle={false}
            />
          ),
        },
      ]}
    />
  );
};

export default ProtocolActionAddVariableGroupTable;
