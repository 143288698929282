/**
 * Labstep
 *
 * @module components/Layout/FullScreen
 * @desc Labstep Layout
 */

import classnames from 'classnames';
import Alerts from 'labstep-web/components/Layout/Alert';
import FullPageError from 'labstep-web/components/Layout/FullPageError';
import MainContent from 'labstep-web/components/Layout/MainContent';
import LeftMenu from 'labstep-web/components/Layout/Menu/Left';
import { ErrorBoundaryContainer } from 'labstep-web/containers/ErrorBoundary';
import React from 'react';
import Links from './Links';
import styles from './styles.module.scss';
import { FullScreenLayoutProps, FullScreenProps } from './types';

export const FullScreenLayout: React.FC<FullScreenLayoutProps> = ({
  right,
}) => (
  <div id="parent-container" className={styles.parentContainer}>
    <div id="main-content" className={styles.mainContent}>
      <div id="right" className={styles.right}>
        <div className={styles.topbar} />
        {right}
      </div>
    </div>
  </div>
);

export const LeftDesktop = () => (
  <div id="left" className={classnames(styles.left)}>
    <div className={styles.leftMain}>
      <Links desktop />
    </div>
  </div>
);

export const FullScreen: React.FC<FullScreenProps> = ({
  children,
  ...rest
}) => (
  <div id="parent-container" className={styles.parentContainer}>
    <div>
      <Alerts />
    </div>
    <div id="main-content" className={styles.mainContent}>
      <LeftMenu />
      <div id="right" className={styles.right}>
        <MainContent center={children} {...rest} />
      </div>
    </div>
  </div>
);

export const FullScreenWithErrorBoundary: React.FC<
  FullScreenProps
> = (props) => (
  <ErrorBoundaryContainer
    FallbackComponent={<FullPageError showSignout />}
  >
    <FullScreen {...props} />
  </ErrorBoundaryContainer>
);

export default FullScreenWithErrorBoundary;
