/**
 * Labstep
 *
 * @module components/Filter/List/Search
 * @desc A searchable list of filter items
 */

import EntitySearchCursor from 'labstep-web/components/Entity/Search/Cursor';
import FilterListContent, {
  isFilterCollapsed,
} from 'labstep-web/components/Filter/List/Content';
import { withActiveGroup } from 'labstep-web/containers/ActiveGroup';
import { withAuthenticatedUserEntity } from 'labstep-web/containers/AuthenticatedUser';
import { withUiPersistent } from 'labstep-web/containers/UiPersistent';
import { User } from 'labstep-web/models/user.model';
import React from 'react';
import styles from './styles.module.scss';
import { FilterListSearchProps } from './types';

export const FilterListSearch: React.FC<FilterListSearchProps> = ({
  entityName,
  activeGroup,
  mapping,
  params,
  action,
  filter,
  searchBarProps,
  uiPersistent,
  toggleUiFilter,
  authenticatedUserEntity,
  dataCy,
}) => (
  <div className={styles.filterListSearch}>
    <EntitySearchCursor
      entityName={entityName}
      params={(searchParams) => {
        const commonParams = {
          count: 5,

          ...params,
        };
        if (
          typeof searchParams.search_query === 'string' &&
          searchParams.search_query?.length > 0 &&
          authenticatedUserEntity.userOrganization &&
          entityName === User.entityName
        ) {
          return {
            ...commonParams,
            is_deleted: 'both',
            organization_id:
              authenticatedUserEntity.userOrganization.organization
                .id,
          };
        }
        return {
          ...commonParams,
          group_id: activeGroup?.id,
        };
      }}
      emptyStateAction={action}
      showEmptyStateOnSearchOnly
      searchBarProps={searchBarProps}
      hideAction
    >
      {({ entities, searchParams, showMoreAction }) => {
        if (
          isFilterCollapsed(uiPersistent, filter) &&
          !!searchParams.search_query
        ) {
          toggleUiFilter(filter.entityName, filter.name);
        }
        return (
          <>
            <FilterListContent
              dataCy={dataCy}
              filters={entities.map(mapping) as any}
              filter={filter}
              action={
                <>
                  {!searchParams.search_query && action}
                  {showMoreAction}
                </>
              }
            />
          </>
        );
      }}
    </EntitySearchCursor>
  </div>
);

export default withUiPersistent(
  withActiveGroup(withAuthenticatedUserEntity(FilterListSearch)),
);
