/**
 * Labstep
 *
 * @module core/Icon
 * @desc Wrapper around semantic-ui-react Icon
 */

import cx from 'classnames';
import { iconPopups } from 'labstep-web/constants/icons';
import Link from 'labstep-web/core/Link';
import Popup from 'labstep-web/core/Popup';
import React from 'react';
import SemanticIcon from 'semantic-ui-react/dist/commonjs/elements/Icon';
import styles from './styles.module.scss';
import { IconProps } from './types';

const Icon: React.FC<IconProps> = ({
  className,
  color,
  route,
  popup,
  cornerIcon,
  ...rest
}) => {
  // White color not support by Semantic UI by default
  const classNames = cx(className, {
    [styles.white]: color === 'white',
    [styles.clickable]: !rest.disabled && rest.onClick,
  });
  const props = {
    color: color === 'white' ? undefined : color,
    'data-testid': `icon ${rest.name}`,
    ...rest,
  };
  let icon = cornerIcon ? (
    <SemanticIcon.Group className={cx(styles.group, classNames)}>
      <SemanticIcon {...props} data-public />
      <SemanticIcon
        corner="top right"
        name={cornerIcon}
        data-public
      />
    </SemanticIcon.Group>
  ) : (
    <SemanticIcon className={classNames} {...props} data-public />
  );
  if (route) {
    icon = (
      <Link {...route} unstyled data-public>
        {icon}
      </Link>
    );
  }
  const iconWithPopup = rest.name as keyof typeof iconPopups;
  if (popup && (popup.content || iconPopups[iconWithPopup])) {
    icon = (
      <Popup
        content={popup.content || iconPopups[iconWithPopup]}
        inverted
        size="mini"
        trigger={icon}
        {...popup}
      />
    );
  }
  return icon;
};

export default Icon;
