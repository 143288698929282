/**
 * Labstep
 *
 * @module components/Resource/Form/fields
 * @desc Resource fields
 */

import {
  FieldType,
  FieldWithoutActionProps,
} from 'labstep-web/core/Form/Reusable/types';
import { ExperimentWorkflow } from 'labstep-web/models/experiment-workflow.model';
import { OrderRequest } from 'labstep-web/models/order-request.model';
import { ProtocolCollection } from 'labstep-web/models/protocol-collection.model';
import { Resource } from 'labstep-web/models/resource.model';
import rules from 'labstep-web/services/validation/rules';

export const tagTypes = {
  [ExperimentWorkflow.entityName]:
    ExperimentWorkflow.getHumanReadableEntityName(false, true),
  [ProtocolCollection.entityName]:
    ProtocolCollection.getHumanReadableEntityName(false, true),
  [Resource.entityName]: Resource.getHumanReadableEntityName(
    false,
    true,
  ),
  [OrderRequest.entityName]: OrderRequest.getHumanReadableEntityName(
    false,
    true,
  ),
};

export const fieldTagName: FieldWithoutActionProps = {
  fieldType: FieldType.Text,
  name: 'name',
  fieldLabel: 'Name',
  placeholder: 'Enter name e.g. `Gel`',
  validation: rules.tag.name,
};

export const fieldTagType: FieldWithoutActionProps = {
  name: 'type',
  fieldLabel: 'Type',
  fieldType: FieldType.ReactSelect,
  elementProps: {
    options: (Object.keys(tagTypes) as (keyof typeof tagTypes)[]).map(
      (key) => ({
        label: tagTypes[key],
        value: key,
      }),
    ),
  },
  validation: rules.tag.type,
};
