/**
 * Labstep
 *
 * @module models/device
 * @desc Typescript export class for Device
 */

import { Type } from 'class-transformer';
import { PermissionActions } from 'labstep-web/models';
import { EntityUser } from 'labstep-web/models/entity-user.model';
import { Entity } from 'labstep-web/models/entity.model';
import { File as FileModel } from 'labstep-web/models/file.model';
import { Group } from 'labstep-web/models/group.model';
import { MetadataThread } from 'labstep-web/models/metadata-thread.model';
import { ShareLink } from 'labstep-web/models/share-link.model';
import { Tag } from 'labstep-web/models/tag.model';
import { Thread } from 'labstep-web/models/thread.model';
import { User } from 'labstep-web/models/user.model';
import { getHumanReadableEntityName } from 'labstep-web/services/i18n.service';
import { PermaLink } from './perma-link.model';

export type DeviceEntityNameType = 'device';

export class Device extends Entity {
  public static readonly entityName: DeviceEntityNameType = 'device';

  public readonly defaultImageSrc =
    '/img/resource-category/default.svg';

  public get entityName(): typeof Device.entityName {
    return Device.entityName;
  }

  public constructor(data: Partial<Device> = {}) {
    super();
    Object.assign(this, data);
  }

  public id!: number;

  public name!: string;

  public entity_users_count!: number;

  public is_template!: boolean;

  public allowed_actions!: PermissionActions[];

  @Type(() => Group)
  public owner!: Group;

  @Type(() => User)
  public author!: User;

  @Type(() => Tag)
  public tags!: Tag[];

  @Type(() => Thread)
  public thread!: Thread;

  @Type(() => ShareLink)
  public share_link!: ShareLink;

  @Type(() => PermaLink)
  public perma_link!: PermaLink;

  @Type(() => FileModel)
  public image?: FileModel;

  @Type(() => MetadataThread)
  public metadata_thread!: MetadataThread;

  @Type(() => EntityUser)
  public entity_users_preview!: EntityUser[];

  @Type(() => Device)
  public template!: Device | null;

  public get nonDeletedTemplate(): Device | null {
    if (this.template && !this.template.deleted_at) {
      return this.template;
    }
    return null;
  }

  public static getHumanReadableEntityName(
    plural?: boolean,
    capitalized?: boolean,
    isTemplate?: boolean,
  ): string {
    return getHumanReadableEntityName(
      this.entityName,
      plural,
      capitalized,
      isTemplate,
    );
  }
}
