/**
 * Labstep
 *
 * @module components/Filter/User/Active
 * @desc Active filter component for user
 */

import { EntityReadEntityContainer } from 'labstep-web/containers/Entity/Read/Entity';
import { User } from 'labstep-web/models/user.model';
import React from 'react';
import RemoveLabel from './Label';
import { FilterUserActiveProps } from './types';

const FilterUserActive: React.FC<FilterUserActiveProps> = ({
  clearParams,
  searchParams,
  type,
}) => {
  const param = type === 'author' ? 'author_id' : 'assignee_id';
  return param in searchParams ? (
    <EntityReadEntityContainer
      entityName={User.entityName}
      id={searchParams[param] as number}
    >
      {({ entity }): React.ReactElement | null =>
        entity ? (
          <RemoveLabel
            user={entity}
            type={type}
            onRemoveClick={
              clearParams && ((): void => clearParams([param]))
            }
          />
        ) : null
      }
    </EntityReadEntityContainer>
  ) : null;
};

export default FilterUserActive;
