/**
 * Labstep
 *
 * @module containers/BulkSelect
 * @desc A container to bulk select items
 */

import BulkSelectContext from 'labstep-web/contexts/bulk-select';
import { Entity } from 'labstep-web/models/entity.model';
import { callIf } from 'labstep-web/services/react.service';
import differenceBy from 'lodash/differenceBy';
import differenceWith from 'lodash/differenceWith';
import isEqual from 'lodash/isEqual';
import keyBy from 'lodash/keyBy';
import merge from 'lodash/merge';
import values from 'lodash/values';
import React from 'react';
import {
  IBulkSelectContainerProps,
  IBulkSelectContainerState,
} from './types';

export const initialState: IBulkSelectContainerState = {
  selected: [],
  unselected: [],
  selectedAll: false,
  entities: [],
};

export class BulkSelectContainer extends React.Component<
  IBulkSelectContainerProps,
  IBulkSelectContainerState
> {
  public static defaultProps: IBulkSelectContainerProps;

  public constructor(props: IBulkSelectContainerProps) {
    super(props);
    this.isSelected = this.isSelected.bind(this);
    this.toggleSelection = this.toggleSelection.bind(this);
    this.selectAll = this.selectAll.bind(this);
    this.unselectAll = this.unselectAll.bind(this);
    this.getIsAtLeastOneSelected =
      this.getIsAtLeastOneSelected.bind(this);
    this.getSelectedCount = this.getSelectedCount.bind(this);
    this.getSelectedAllVisible =
      this.getSelectedAllVisible.bind(this);
    this.canBulkSelect = this.canBulkSelect.bind(this);
    this.selectAllVisible = this.selectAllVisible.bind(this);
    this.isSelectComplete = this.isSelectComplete.bind(this);
    this.state = initialState;
  }

  public componentDidUpdate(
    prevProps: IBulkSelectContainerProps,
  ): void {
    if (!this.props.persistSelection) {
      const prevIds = prevProps.visibleEntities.map((e) => e.id);
      const nextIds = this.props.visibleEntities.map((e) => e.id);
      if (!isEqual(prevIds, nextIds)) {
        this.unselectAll();
      }
    }
  }

  public getIsAtLeastOneSelected(): boolean {
    const { selected, selectedAll } = this.state;
    return selected.length > 0 || selectedAll;
  }

  public getSelectedCount(): number {
    const { selectedAll, unselected, selected } = this.state;
    const { total } = this.props;
    if (!total) {
      return selected.length;
    }

    return selectedAll ? total - unselected.length : selected.length;
  }

  public getSelectedAllVisible(): boolean {
    const { visibleEntities } = this.props;
    const { selected } = this.state;
    const ids = selected.map((s) => s.id);
    return visibleEntities.every((e) => ids.includes(e.id));
  }

  public canBulkSelect(): boolean {
    const { requiredCount, visibleEntities } = this.props;
    return requiredCount
      ? visibleEntities.length <= requiredCount
      : true;
  }

  public isSelected(entity: Entity): boolean {
    const { selected, selectedAll, unselected } = this.state;
    if (selectedAll) {
      return !unselected.find(
        (unselectedEntity) => unselectedEntity.id === entity.id,
      );
    }
    return !!selected.find(
      (selectedEntity) => selectedEntity.id === entity.id,
    );
  }

  public isSelectComplete(): boolean {
    if (!this.props.requiredCount) {
      return this.getIsAtLeastOneSelected();
    }

    return this.getSelectedCount() === this.props.requiredCount;
  }

  public selectAll(): void {
    this.setState({
      unselected: [],
      selected: [],
      selectedAll: true,
    });
  }

  public selectAllVisible(): void {
    const { persistSelection, visibleEntities } = this.props;
    const { unselected, selected, selectedAll } = this.state;

    if (selectedAll) {
      this.setState({
        unselected: differenceWith(
          unselected,
          visibleEntities,
          (a, b) => a.id === b.id,
        ),
      });
    } else {
      this.setState({
        selected: persistSelection
          ? values(
              merge(
                keyBy(visibleEntities, 'id'),
                keyBy(selected, 'id'),
              ),
            )
          : visibleEntities,
      });
    }
  }

  public toggleSelection(entity: Entity): void {
    const { selected, unselected, selectedAll } = this.state;
    const { onSelect } = this.props;

    if (this.isSelected(entity)) {
      if (selectedAll) {
        this.setState({ unselected: [...unselected, entity] });
      } else {
        this.setState({
          selected: selected.filter((e) => e.id !== entity.id),
        });
      }
    } else if (selectedAll) {
      this.setState({
        unselected: unselected.filter((e) => e.id !== entity.id),
      });
    } else {
      this.setState({ selected: [...selected, entity] });
    }

    callIf(onSelect, entity);
  }

  public unselectAll(): void {
    this.setState(initialState);
  }

  public render(): React.ReactNode {
    const {
      children,
      visibleEntities,
      persistSelection,
      requiredCount,
    } = this.props;
    const { selected, selectedAll, unselected } = this.state;
    const entities = selectedAll
      ? differenceBy(visibleEntities, unselected, 'id')
      : selected;

    const value = {
      canBulkSelect: this.canBulkSelect(),
      isSelected: this.isSelected,
      toggleSelection: this.toggleSelection,
      selectAll: this.selectAll,
      selectedAll,
      unselectAll: this.unselectAll,
      isAtLeastOneSelected: this.getIsAtLeastOneSelected(),
      selectedAllVisible: this.getSelectedAllVisible(),
      selectedCount: this.getSelectedCount(),
      selectAllVisible: this.selectAllVisible,
      selected,
      unselected,
      entities,
      isSelectComplete: this.isSelectComplete(),
      requiredCount,
      persistSelection,
    };
    return (
      <BulkSelectContext.Provider value={value} children={children} />
    );
  }
}
