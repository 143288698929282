/**
 * Labstep
 *
 * @module components/Group/Settings/DefaultSignatureRequestMessage
 * @desc DefaultSignatureRequestMessage field update
 */

import { fieldGroupDefaultSignatureRequestMessage } from 'labstep-web/components/Group/Form/fields';
import ShowEditText from 'labstep-web/core/Form/ShowEdit/Text';
import React from 'react';
import { GroupFormShowEditDefaultSignatureRequestMessageProps } from './types';

export const GroupFormShowEditDefaultSignatureRequestMessage: React.FC<
  GroupFormShowEditDefaultSignatureRequestMessageProps
> = ({ group }) => (
  <ShowEditText
    entityName={group.entityName}
    entity={group}
    field={fieldGroupDefaultSignatureRequestMessage}
  >
    {group.default_signature_request_message}
  </ShowEditText>
);

export default GroupFormShowEditDefaultSignatureRequestMessage;
