/**
 * Labstep
 *
 * @module components/Organization/InviteMembers
 * @desc Invite members to organization by preselecting all workspaces members
 */

import ShareLinkInvitationFormCreateOrganization from 'labstep-web/components/ShareLinkInvitation/Form/Create/Organization';
import Button from 'labstep-web/core/Button';
import Message from 'labstep-web/core/Message';
import { ReadOnMountHOC } from 'labstep-web/hoc/ReadOnMount';
import { User } from 'labstep-web/models/user.model';
import React from 'react';
import { OrganizationInviteMembersProps } from './types';

export const OrganizationInviteMembers: React.FC<
  OrganizationInviteMembersProps
> = ({ organization, options }) => {
  if (!organization) {
    return null;
  }
  return (
    <>
      <Message color="yellow">
        Users who do not accept the invitation will not be able to
        access Workspaces within the Organization. You will only be
        billed for users who accept the invitation.
      </Message>
      <ReadOnMountHOC
        type="cursor"
        entityName={User.entityName}
        params={{
          count: 50,
          is_owner_group_member: 1,
        }}
        children={({ entities }) => (
          <ShareLinkInvitationFormCreateOrganization
            users={entities}
            organization={organization}
            options={options}
            cancelButton={
              <Button
                primary
                basic
                onClick={options.onSuccess as any}
              >
                Skip
              </Button>
            }
          />
        )}
      />
    </>
  );
};

export default OrganizationInviteMembers;
