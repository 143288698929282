/**
 * Labstep
 *
 * @module components/EntityStateWorkflow/Action/Share
 * @desc Share EntityStateWorkflow
 */

import EntityActionShare from 'labstep-web/components/Entity/Action/Share';
import React from 'react';
import { EntityStateWorkflowActionShareProps } from './types';

export const EntityStateWorkflowActionShare: React.FC<
  EntityStateWorkflowActionShareProps
> = ({ entityStateWorkflow }) => (
  <EntityActionShare entity={entityStateWorkflow} />
);

export default EntityStateWorkflowActionShare;
