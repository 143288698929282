/**
 * Labstep
 *
 * @module models/device-booking
 * @desc Typescript export class for Device Booking
 */

import { Type } from 'class-transformer';
import { Entity } from 'labstep-web/models/entity.model';
import { User } from 'labstep-web/models/user.model';
import { getHumanReadableEntityName } from 'labstep-web/services/i18n.service';
import { Device } from './device.model';

export class DeviceBooking extends Entity {
  static readonly entityName = 'device_booking';

  readonly defaultImageSrc = '/img/resource-category/default.svg';

  get entityName(): string {
    return DeviceBooking.entityName;
  }

  constructor(data: Partial<DeviceBooking> = {}) {
    super();
    Object.assign(this, data);
  }

  id!: number;

  description!: string;

  started_at!: string;

  ended_at!: string;

  @Type(() => User)
  author!: User;

  @Type(() => Device)
  device!: Device;

  static getHumanReadableEntityName(
    plural?: boolean,
    capitalized?: boolean,
  ): string {
    return getHumanReadableEntityName(
      this.entityName,
      plural,
      capitalized,
    );
  }

  public formatEvent(disabled?: boolean) {
    return {
      id: this.id.toString(),
      start: this.started_at,
      end: this.ended_at,
      extendedProps: { deviceBooking: this },
      editable: !disabled,
    };
  }
}
