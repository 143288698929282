/**
 * Labstep
 *
 * @module components/Tag/Action/Remove
 * @desc Button to remove tag from protocol collection
 */

import EntityActionRemove from 'labstep-web/components/Entity/Action/Remove';
import { Tag } from 'labstep-web/models/tag.model';
import React from 'react';
import { ITagActionRemoveProps } from './types';

export const TagActionRemove: React.FC<ITagActionRemoveProps> = ({
  parentEntity,
  tag,
  actionComponentProps,
  ...rest
}) => (
  <EntityActionRemove
    {...rest}
    actionComponentProps={{
      icon: 'times',
      ...actionComponentProps,
    }}
    noConfirmation
    parentName={parentEntity.entityName}
    parentId={parentEntity.id}
    entityName={Tag.entityName}
    entity={tag}
  />
);

export default TagActionRemove;
