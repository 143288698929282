/**
 * Labstep
 *
 * @module core/DateTimePicker
 * @desc Wrapper around flatpickr
 */

import React from 'react';
import 'flatpickr/dist/flatpickr.css';
import { ErrorBoundaryContainer } from 'labstep-web/containers/ErrorBoundary';
import Flatpickr from 'react-flatpickr';
import { IDateTimePickerProps } from './types';

export const DateTimePicker: React.FC<IDateTimePickerProps> = ({
  setRef,
  ...rest
}) => (
  <ErrorBoundaryContainer>
    <Flatpickr
      {...rest}
      ref={(ref) => {
        if (setRef) {
          setRef(ref);
        }
      }}
    />
  </ErrorBoundaryContainer>
);

const DateTimePickerContainer: React.FC<IDateTimePickerProps> = (
  props: any,
) => (
  <div className="ui input fluid">
    <DateTimePicker {...props} />
  </div>
);

export default DateTimePickerContainer;
