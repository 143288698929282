/**
 * Labstep
 *
 * @module prosemirror/components/Toolbar/Link
 * @desc Button to insert a text node with link mark
 */

import Dropdown from 'labstep-web/core/Dropdown';
import FormReusable from 'labstep-web/core/Form/Reusable';
import ModalDefault from 'labstep-web/core/Modal/Default';
import { fields } from 'labstep-web/prosemirror/marks/link';
import React from 'react';
import { LinkToolbarProps } from './types';

const LinkToolbar: React.FC<LinkToolbarProps> = ({
  state,
  dispatch,
}) => {
  return (
    <ModalDefault
      header="Add Link"
      content={({ toggleModal }) => (
        <FormReusable
          autoFocus
          onSubmit={({ url, text }) => {
            toggleModal();
            const link = state.schema.marks.link.create({
              href: url,
            });
            const node = state.schema
              .text((text as string) || (url as string))
              .mark([link]);
            dispatch?.(
              state.tr.insert(state.selection.$to.pos, node),
            );
          }}
          fields={fields}
        />
      )}
      viewComponent={({ toggleModal }) => (
        <Dropdown.Item
          icon="linkify"
          text="Link"
          onClick={toggleModal}
        />
      )}
    />
  );
};

export default LinkToolbar;
