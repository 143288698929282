/**
 * Labstep
 *
 * @module components/Group/Action/SetIsAutoMember
 * @desc Set isAutoMember for Group
 */

import EntityActionUpdateCheckbox from 'labstep-web/components/Entity/Action/Update/Checkbox';
import React from 'react';
import { GroupActionSetIsAutoMemberProps } from './types';

export const GroupActionSetIsAutoMember: React.FC<
  GroupActionSetIsAutoMemberProps
> = ({ group }) => (
  <EntityActionUpdateCheckbox
    entity={group}
    field={'is_auto_member' as const}
    toggle
    optimistic
  />
);

export default GroupActionSetIsAutoMember;
