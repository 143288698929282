/**
 * Labstep
 *
 * @module components/EntityUser/Form/ShowEdit/EntityUserRole
 * @desc   Update EntityUser entityUserRole
 */

import { withActiveGroup } from 'labstep-web/containers/ActiveGroup';
import FormShowEditSelect from 'labstep-web/core/Form/ShowEdit/Select';
import { EntityUserRole } from 'labstep-web/models/entity-user-role.model';
import React from 'react';
import { fieldEntityUserEntityUserRole } from '../../fields';
import { EntityUserFormShowEditEntityUserRoleProps } from './types';

export const EntityUserFormShowEditEntityUserRole: React.FC<
  EntityUserFormShowEditEntityUserRoleProps
> = ({ entityUser, activeGroup }) => {
  const field = fieldEntityUserEntityUserRole(
    activeGroup?.entity_user_roles as EntityUserRole[],
  );
  return (
    <FormShowEditSelect
      entity={entityUser}
      name="role"
      field={field}
      childKeyName="entity_user_role"
    >
      {entityUser.entity_user_role?.name}
    </FormShowEditSelect>
  );
};

export default withActiveGroup(EntityUserFormShowEditEntityUserRole);
