/**
 * Labstep
 */

import { WithAuthenticatedUserEntityInjectedProps } from 'labstep-web/containers/AuthenticatedUser/types';
import { ActionMenuProps } from 'labstep-web/core/Action/Menu/types';
import { Comment } from 'labstep-web/models/comment.model';

// eslint-disable-next-line no-shadow
export enum Action {
  pin = 'pin',
  comment = 'comment',
  edit = 'edit',
  delete = 'delete',
}

export interface ICommentActionMenuProps
  extends Pick<
      ActionMenuProps,
      Exclude<keyof ActionMenuProps, 'children'>
    >,
    WithAuthenticatedUserEntityInjectedProps {
  toggle: VoidFunction;
  comment: Comment;
  actions?: Action[];
}
