/**
 * Labstep
 *
 * @module components/PermaLinkReader
 * @desc PermaLink Reader
 */

import PermaLinkReaderEmptyState from 'labstep-web/components/PermaLink/Reader/EmptyState';
import QrReader from 'labstep-web/core/QrReader';
import { ReadOnMountHOC } from 'labstep-web/hoc/ReadOnMount';
import { PermaLink } from 'labstep-web/models/perma-link.model';
import React, { useState } from 'react';
import styles from './styles.module.scss';
import { PermaLinkReaderProps } from './types';

export const PermaLinkReader: React.FC<PermaLinkReaderProps> = ({
  children,
  description,
  customError,
}) => {
  const [activeToken, setActiveToken] = useState(null);
  const [error, setError] = useState<unknown | null>(null);

  const handleScan = (data: any) => {
    if (data) {
      try {
        const urlParts = data.split('/');
        const token = urlParts[urlParts.length - 1];
        setActiveToken(token);
      } catch (err) {
        setError(err);
      }
    }
  };

  const handleError = (err: any) => {
    setError(err);
  };

  const reset = () => {
    setActiveToken(null);
    setError(null);
  };

  if (customError) {
    const { errorMessage, reset: customReset } = customError;
    return (
      <PermaLinkReaderEmptyState
        onClick={customReset}
        explanation={errorMessage}
      />
    );
  }

  if (activeToken) {
    return (
      <ReadOnMountHOC
        type="entity"
        entityName={PermaLink.entityName}
        id={activeToken}
        loading={{
          customError: () => {
            return <PermaLinkReaderEmptyState onClick={reset} />;
          },
        }}
        children={({ entity: permaLink }) =>
          children({
            id: permaLink.parent_id,
            entityName: permaLink.parent_type,
            reset,
          })
        }
      />
    );
  }

  return (
    <div className={styles.qrContainer}>
      <QrReader
        delay={100}
        onError={handleError}
        onScan={handleScan}
        facingMode="environment"
        style={{ width: '100%' }}
      />
      <p>
        {error
          ? 'Oops! Something went wrong. Try scanning again.'
          : description}
      </p>
    </div>
  );
};

export default PermaLinkReader;
