/**
 * Labstep
 *
 * @module components/ResourceLocation/Action/CreateSub
 * @desc Create Sub Resource Location Action
 */

import EntityActionEdit from 'labstep-web/components/Entity/Action/Edit';
import { CanCreate } from 'labstep-web/components/Entity/Can';
import ResourceLocationFormCreate from 'labstep-web/components/ResourceLocation/Form/Create';
import ModalDefault from 'labstep-web/core/Modal/Default';
import React from 'react';
import { IResourceLocationActionCreateSubProps } from './types';

export const ResourceLocationActionCreateSub: React.FC<
  IResourceLocationActionCreateSubProps
> = ({ resourceLocation, actionComponentProps }) => (
  <CanCreate entityName="resource_location">
    <ModalDefault
      header="Add Sub-location(s)"
      viewComponent={({ toggleModal }) => (
        <EntityActionEdit
          entity={resourceLocation}
          actionComponentProps={{
            type: 'button',
            icon: 'plus',
            text: 'Add Sub-location(s)',
            onClick: toggleModal,
            ...actionComponentProps,
          }}
        />
      )}
      content={({ toggleModal }) => (
        <ResourceLocationFormCreate
          autoFocus
          body={{ outer_location_guid: resourceLocation.guid }}
          options={{ onSuccess: toggleModal }}
        />
      )}
    />
  </CanCreate>
);

export default ResourceLocationActionCreateSub;
