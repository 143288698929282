/**
 * Labstep
 *
 * @module screens/VerifyEmail
 * @desc Verify Email Screen
 */

import LayoutFullScreenPage from 'labstep-web/components/Layout/FullScreenPage';
import DocumentHead from 'labstep-web/core/DocumentHead';
import React from 'react';
import Center from './Center';
import { IVerifyEmailScreenProps } from './types';

const VerifyEmailScreen: React.FC<IVerifyEmailScreenProps> = ({
  match,
}) => (
  <>
    <DocumentHead title="Verify Email" />
    <LayoutFullScreenPage>
      <Center match={match} />
    </LayoutFullScreenPage>
  </>
);

export default VerifyEmailScreen;
