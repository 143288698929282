/**
 * Labstep
 *
 * @module components/OrganizationPlan/Card/Plan
 * @desc Organization plan card for plan
 */

import OrganizationPlanCard from 'labstep-web/components/OrganizationPlan/Card';
import { intervalLabels } from 'labstep-web/constants/organization-plan-price';
import { PlanTier, plans } from 'labstep-web/constants/plans';
import Button from 'labstep-web/core/Button';
import Header from 'labstep-web/core/Header';
import Icon from 'labstep-web/core/Icon';
import React from 'react';
import OrganizationPlanCardTextCount from '../Text/Count';
import styles from './styles.module.scss';
import { OrganizationPlanCardPlanProps } from './types';

export const OrganizationPlanCardPlan: React.FC<
  OrganizationPlanCardPlanProps
> = ({ organizationPlan, organization }) => {
  if (organization.tier === 'labstep') {
    return null;
  }

  const shiny =
    organization.tier === PlanTier.Pro ||
    organization.tier === PlanTier.Ultimate;

  return (
    <OrganizationPlanCard
      icon="clipboard list"
      header="Plan"
      shiny={shiny}
      action={
        organization.tier !== PlanTier.Ultimate && (
          <Button
            className={!shiny ? styles.shinyButton : undefined}
            basic={shiny}
            primary={!shiny}
            id="upgrade-now"
          >
            <Icon name="arrow alternate circle up" />
            Upgrade
          </Button>
        )
      }
      primary={
        <div
          className={shiny ? styles.lightHeader : styles.darkHeader}
        >
          <div className={styles.defaultHeader}>
            <Header
              noMargin
              className={
                shiny ? styles.lightHeader : styles.darkHeader
              }
              size="small"
            >
              {plans[organization.tier].name}
            </Header>
            <p>
              {
                intervalLabels[
                  organizationPlan.interval as keyof typeof intervalLabels
                ]
              }
            </p>
          </div>
          <OrganizationPlanCardTextCount
            count={`${organizationPlan.currencyDisplay}${organizationPlan.amountDisplay}`}
            text="/user"
            shiny={shiny}
          />
        </div>
      }
    />
  );
};

export default OrganizationPlanCardPlan;
