/**
 * Labstep
 *
 * @module components/User/Action/DisableMfa
 * @desc Action to disable Mfa
 */

import { MfaContainer } from 'labstep-web/containers/SetupMfa';
import ActionComponent from 'labstep-web/core/Action/Component';
import FormReusable from 'labstep-web/core/Form/Reusable';
import ModalDefault from 'labstep-web/core/Modal/Default';
import React from 'react';
import { fieldUserPassword } from '../../Form/fields';
import { IUserActionDisableMfaProps } from './types';

export const UserActionDisableMfaContainer: React.FC<
  IUserActionDisableMfaProps
> = () => (
  <ModalDefault
    header="Disable Two-factor Authentication"
    content={({ toggleModal }) => (
      <MfaContainer>
        {({ disableMfa, disableMfaStatus }) => (
          <FormReusable
            status={disableMfaStatus}
            onSubmit={(body) => {
              disableMfa(body, { onSuccess: toggleModal });
            }}
            autoFocus
            submitButtonText="Disable"
            fields={[
              { ...fieldUserPassword, fieldLabel: 'Password' },
            ]}
          />
        )}
      </MfaContainer>
    )}
    viewComponent={({ toggleModal }) => (
      <ActionComponent
        onClick={toggleModal}
        type="button"
        text="Disable"
      />
    )}
  />
);

export default UserActionDisableMfaContainer;
