/**
 * Labstep
 *
 * @module components/Group/List
 * @desc   List of groups
 */

import GroupActionMenu from 'labstep-web/components/Group/Action/Menu';
import GroupInitials from 'labstep-web/components/Group/Initials';
import UserActionAddGroup from 'labstep-web/components/User/Action/AddGroup';
import Flex from 'labstep-web/core/Flex';
import Header from 'labstep-web/core/Header';
import Icon from 'labstep-web/core/Icon';
import { Size } from 'labstep-web/core/Initials/types';
import LinkButton from 'labstep-web/core/Link/Button';
import List from 'labstep-web/core/List';
import Popup from 'labstep-web/core/Popup';
import React from 'react';
import styles from './styles.module.scss';
import { GroupListProps } from './types';

const GroupList: React.FC<GroupListProps> = ({
  groups,
  hideActions,
}) => (
  <List>
    {groups.map((group) => {
      const component = (
        <div className={styles.info}>
          <GroupInitials size={Size.tiny} group={group} />
          <div className={styles.name}>
            <Header size={Size.tiny}> {group.name}</Header>
            {group.is_invite_only && !hideActions && (
              <Popup
                trigger={<Icon name="lock" />}
                content="Invite Only"
                inverted
              />
            )}
          </div>
        </div>
      );

      if (hideActions) {
        return component;
      }
      return (
        <div className={styles.container} key={group.id}>
          {group.logged_user_user_group === null ? (
            <UserActionAddGroup
              group={group}
              viewComponent={component}
            />
          ) : (
            <LinkButton
              route={{ to: 'group_show', params: { id: group.id } }}
            >
              {component}
            </LinkButton>
          )}
          <Flex>
            <GroupActionMenu group={group} actions={['delete']} />
          </Flex>
        </div>
      );
    })}
  </List>
);

export default GroupList;
