/**
 * Labstep
 *
 * @module screens/Authentication/Signup/NoSeatsLeft
 * @desc Sign up page when no seats are left for the organization
 */

import OrganizationEmptyStateNoSeatsLeft from 'labstep-web/components/Organization/EmptyState/NoSeatsLeft';
import AuthenticationComponent from 'labstep-web/screens/Authentication/Component';
import { SharedBody } from 'labstep-web/screens/Authentication/Shared';
import React from 'react';
import { ShareLinkOrganizationNoSeatsLeftProps } from './types';

export const ShareLinkOrganizationNoSeatsLeft: React.FC<
  ShareLinkOrganizationNoSeatsLeftProps
> = ({ organization }) => (
  <AuthenticationComponent>
    <div>
      <SharedBody>
        <OrganizationEmptyStateNoSeatsLeft
          organization={organization}
        />
      </SharedBody>
    </div>
  </AuthenticationComponent>
);

export default ShareLinkOrganizationNoSeatsLeft;
