/**
 * Labstep
 *
 * @module core/Sortable/List
 * @desc Component for sorting lists
 */

import List from 'labstep-web/core/List';
import React from 'react';
import Sortable from '..';
import SortableComponent from '../Container';
import { SortableListProps } from './types';

const SortableList: React.FC<SortableListProps> = ({
  renderItem,
  disabled,
  ...rest
}) => (
  <SortableComponent
    {...rest}
    render={({ items, onSortEnd }): JSX.Element => (
      <List>
        <Sortable
          renderItem={renderItem}
          items={items}
          disabled={disabled}
          onSortEnd={onSortEnd}
        />
      </List>
    )}
  />
);

export default SortableList;
