/**
 * Labstep
 *
 * @module components/Metadata/List/Preview
 * @desc A preview list of metadata
 */

import { ICONS } from 'labstep-web/constants/icons';
import { IconProps } from 'labstep-web/core/Icon/types';
import ListLabelValue from 'labstep-web/core/List/LabelValue';
import { Metadata } from 'labstep-web/models/metadata';
import {
  MetadataParent,
  MetadataType,
} from 'labstep-web/models/metadata/types';
import React from 'react';
import { MetadataPreviewProps } from './types';

export function getPreviewLabelValues(
  metadata: Metadata,
  entity: MetadataParent,
  withIcon?: boolean,
  cornerIcon?: IconProps['name'],
): {
  label: string | null;
  value: string;
  icon?: IconProps['name'] | null;
  cornerIcon?: IconProps['name'];
}[] {
  if (
    metadata.type === MetadataType.molecule &&
    metadata.molecule &&
    metadata.molecule.pubchemReactants.length
  ) {
    const primary = metadata.molecule.pubchemReactants[0];
    const metadataPreview = [];
    if (primary.IUPACName) {
      metadataPreview.push({
        label: 'IUPAC Name',
        value: primary.IUPACName,
      });
    }
    if (primary.CAS) {
      metadataPreview.push({
        label: 'CAS Number',
        value: primary.CAS,
      });
    }
    return metadataPreview;
  }
  if (
    [
      MetadataType.date,
      MetadataType.datetime,
      MetadataType.numeric,
      MetadataType.default,
      MetadataType.options,
      MetadataType.molecule,
    ].indexOf(metadata.type) > -1 &&
    !metadata.getIsServingAsDefaultTemplate(entity) &&
    metadata.printValue
  ) {
    return [
      {
        icon: withIcon ? ICONS.metadata.secondary : undefined,
        label: metadata.label,
        value: metadata.printValue,
        cornerIcon,
      },
    ];
  }
  return [];
}

const MetadataPreview: React.FC<MetadataPreviewProps> = ({
  entity,
  withIcon,
  cornerIcon,
}) =>
  entity.metadata_thread.metadatas_preview.length > 0 ? (
    <div
      data-testid="metadata-preview"
      onContextMenu={(e) => e.stopPropagation()}
      style={{ userSelect: 'text' }}
    >
      <ListLabelValue
        array={entity.metadata_thread.metadatas_preview
          .reduce(
            (
              metadataPreview: {
                label: string | null;
                value: string;
                icon?: IconProps['name'] | null;
                cornerIcon?: IconProps['name'];
              }[],
              metadata,
            ) =>
              metadataPreview.concat(
                getPreviewLabelValues(
                  metadata,
                  entity,
                  withIcon,
                  cornerIcon,
                ),
              ),
            [],
          )
          .filter((m, index) => index < 2)}
      />
    </div>
  ) : null;

export default MetadataPreview;
