/**
 * Labstep
 *
 * @module components/Group/Settings/Protocol
 * @desc   Group settings for Protocol
 */

import EntityActionUpdateAllowedActionsLockContent from 'labstep-web/components/Entity/Action/Update/AllowedActionsLock/Content';
import LayoutLinksState from 'labstep-web/components/Layout/Links/State';
import { TagType } from 'labstep-web/models/tag.model';
import TagIndex from 'labstep-web/screens/Tag/Index';
import React from 'react';
import { GroupSettingsProtocolProps } from './types';

const GroupSettingsProtocol: React.FC<GroupSettingsProtocolProps> = ({
  group,
}) => {
  return (
    <div id="group-settings-protocols" style={{ flex: 1 }}>
      <LayoutLinksState
        inverted
        tabs={[
          {
            key: 'Tags',
            render: () => (
              <TagIndex
                group={group}
                type={TagType.protocol_collection}
              />
            ),
          },
          {
            key: 'Lock Settings',
            render: () => (
              <div>
                <EntityActionUpdateAllowedActionsLockContent
                  entity={group}
                />
              </div>
            ),
          },
        ]}
      />
    </div>
  );
};

export default GroupSettingsProtocol;
