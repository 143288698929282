/**
 * Labstep
 *
 * @module component/Metadata/Action/Create/DataModal
 * @desc Data modal component
 */

import MetadataActionCreate from 'labstep-web/components/Metadata/Action/Create';
import { withActiveEditModal } from 'labstep-web/containers/ActiveEditModal';
import Loadable from 'labstep-web/core/Loadable';
import ModalDefault from 'labstep-web/core/Modal/Default';
import { Metadata } from 'labstep-web/models/metadata';
import React from 'react';
import { MetadataActionCreateDataModalProps } from './types';

export const MetadataFormCreateBulk = React.lazy(
  () => import('labstep-web/components/Metadata/Form/Create/Bulk'),
);

export const MetadataActionCreateDataModal: React.FC<
  MetadataActionCreateDataModalProps
> = ({ entity, setActiveEditModal }) => (
  <ModalDefault
    size="small"
    header={`Add ${Metadata.getHumanReadableEntityName(
      true,
      true,
      entity,
    )}`}
    viewComponent={({ toggleModal }) => (
      <MetadataActionCreate
        entity={entity}
        body={{ type: 'file', is_output: true }}
        options={{
          onSuccess: ({ response }) => {
            setActiveEditModal({
              id: response!.result,
              entityName: Metadata.entityName,
            });
          },
        }}
        handleOnClickBulkOption={toggleModal}
      />
    )}
    content={({ toggleModal }) => (
      <Loadable>
        <MetadataFormCreateBulk
          entity={entity}
          isTemplate={entity.is_template}
          isProtocolOrExperiment
          options={{
            toast: true,
            onSuccess: () => {
              toggleModal();
            },
          }}
        />
      </Loadable>
    )}
  />
);

export default withActiveEditModal(MetadataActionCreateDataModal);
