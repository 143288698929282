/**
 * Labstep
 *
 * @module prosemirror/components/Toolbar/File
 * @desc Button to insert a File
 */

import FileActionCreate from 'labstep-web/components/File/Action/Create';
import NodeFile from 'labstep-web/prosemirror/nodes/file';
import { isSingleBlockSelectedAndAllowsContent } from 'labstep-web/prosemirror/utils';
import React from 'react';
import { Dropdown } from 'semantic-ui-react';
import { IFileToolbarProps } from './types';

const FileToolbar: React.FC<IFileToolbarProps> = ({
  entity,
  dispatch,
  state,
  isImage,
}) => (
  <FileActionCreate
    options={{
      onSuccess: ({ response }) => {
        const { result } = response;
        const id = Array.isArray(result) ? result[0] : result;
        const { tr } = state;
        const content = state.schema.nodes[NodeFile.key].create({
          id,
        });
        tr.setStoredMarks([]);
        tr.insert(state.selection.$from.pos, content);
        dispatch?.(tr);
      },
      toast: true,
    }}
    entityType={entity.entityName}
    entityId={entity.id}
    disabled={!isSingleBlockSelectedAndAllowsContent(state)}
  >
    {({ toggleModal }) => (
      <Dropdown.Item
        disabled={!isSingleBlockSelectedAndAllowsContent(state)}
        icon={isImage ? 'images' : 'paperclip'}
        text={isImage ? 'Image' : 'File'}
        onClick={toggleModal}
      />
    )}
  </FileActionCreate>
);

export default FileToolbar;
