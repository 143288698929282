/**
 * Labstep
 *
 * @module components/EntityView/Action/Menu
 * @desc List of actions for EntityView
 */

import EntityActionTag from 'labstep-web/components/Entity/Action/Tag';
import EntityViewActionDelete from 'labstep-web/components/EntityView/Action/Delete';
import FolderActionAdd from 'labstep-web/components/Folder/Action/Add';
import FolderActionRemove from 'labstep-web/components/Folder/Action/Remove';
import ActionMenu from 'labstep-web/core/Action/Menu';
import React from 'react';
import { Action, EntityViewActionMenuProps } from './types';

export const EntityViewActionMenu: React.FC<
  EntityViewActionMenuProps
> = ({ entityView, type, actions = [] }) => (
  <ActionMenu type={type}>
    {actions.includes(Action.add_to_folder) && (
      <FolderActionAdd parentEntity={entityView} />
    )}
    {actions.includes(Action.remove_from_folder) && (
      <FolderActionRemove
        parentId={entityView.id}
        parentName={entityView.entityName}
      />
    )}
    {actions.includes(Action.tag) && (
      <EntityActionTag parentEntity={entityView} />
    )}
    {actions.includes(Action.delete) && (
      <EntityViewActionDelete entityView={entityView} />
    )}
  </ActionMenu>
);

EntityViewActionMenu.defaultProps = {
  actions: [],
  type: 'icon',
};

export default EntityViewActionMenu;
