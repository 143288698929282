/**
 * Labstep
 *
 * @module components/EntityUser/Avatar
 * @desc   EntityUser Avatar
 */

import ProfilePictureImage from 'labstep-web/components/Profile/Picture/Image';
import { Size } from 'labstep-web/core/Initials/types';
import React from 'react';
import styles from './styles.module.scss';
import { EntityUserAvatarProps } from './types';

const EntityUserAvatar: React.FC<EntityUserAvatarProps> = ({
  entityUser,
  ...rest
}) => (
  <div data-testid="entity-user-avatar" className={styles.container}>
    <ProfilePictureImage
      size={Size.mini}
      entity={entityUser.user}
      {...rest}
    />
    {entityUser.is_assigned && (
      <div className={styles.dot}>
        <div />
      </div>
    )}
  </div>
);

export default EntityUserAvatar;
