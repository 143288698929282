/**
 * Labstep
 *
 * @module components/Metadata/Form/ShowEdit/InputOutput
 * @desc Metadata field type input/output
 */

import EntityActionUpdateDropdownPill from 'labstep-web/components/Entity/Form/ShowEdit/InputOutput';
import React from 'react';
import { MetadataShowEditInputOutputProps } from './types';

export const MetadataShowEditInputOutput: React.FC<
  MetadataShowEditInputOutputProps
> = ({ metadata }) => (
  <EntityActionUpdateDropdownPill entity={metadata} />
);

export default MetadataShowEditInputOutput;
