/**
 * Labstep
 */

import EntityActionDelete from 'labstep-web/components/Entity/Action/Delete';
import { ColDef } from 'labstep-web/core/DataGrid/types';
import rowNumber from 'labstep-web/grid/Index/coldefs/Resource/rowNumber';
import { ProtocolCondition } from 'labstep-web/models/protocol-condition.model';

const colDefRowNumber: ColDef<ProtocolCondition> = {
  ...rowNumber,
  cellRendererParams: (params) => ({
    props: {
      action: (
        <EntityActionDelete
          dataCy="grid/SmartTable/coldefs/rowNumber"
          entity={params.data}
          actionComponentProps={{ type: 'option' }}
          skipConfirmation
        />
      ),
    },
  }),
};

export default colDefRowNumber;
