/**
 * Labstep
 *
 * @module prosemirror/components/Menu/Action/Comment
 * @desc Add a link mark to selected text
 */

import CommentFormCreate from 'labstep-web/components/Comment/Form/Create';
import { CanCreate } from 'labstep-web/components/Entity/Can';
import Button from 'labstep-web/core/Button';
import Icon from 'labstep-web/core/Icon';
import ModalDefault from 'labstep-web/core/Modal/Default';
import { commentPluginKey } from 'labstep-web/prosemirror/extensions/comment';
import { addComment } from 'labstep-web/prosemirror/extensions/comment/commands';
import React from 'react';
import { IMenuTextActionCommentProps } from './types';

const MenuTextActionComment: React.FC<
  IMenuTextActionCommentProps
> = ({ view, entity }) => {
  const comments = (view.state.plugins as any)
    .find((plugin: any) => plugin.spec.key === commentPluginKey)
    .getState(view.state)
    .commentsAt(view.state.selection.from);
  // Preventing multiple comments
  if (comments.length) {
    return null;
  }
  return (
    <CanCreate
      parentId={entity.thread.id}
      parentName={entity.thread.entityName}
      entityName="comment"
    >
      <ModalDefault
        header="Comment"
        content={({ toggleModal }) => (
          <CommentFormCreate
            autoFocus
            threadId={entity.thread.id}
            onSuccess={({ response }) => {
              addComment(
                view.state,
                view.dispatch,
                response!.result as any,
              );
              toggleModal();
            }}
          />
        )}
        viewComponent={({ toggleModal }) => (
          <Button onClick={toggleModal}>
            <Icon name="comment" />
            <span>Comment</span>
          </Button>
        )}
      />
    </CanCreate>
  );
};

export default MenuTextActionComment;
